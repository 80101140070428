import { useState } from "react";
import useOptions from "./useOptions";
import api from "../../../../utils/api";
import { toast } from "react-toastify";

const usePagContaPerfil = () => {
  const { listaCpfs } = useOptions();
  const [valuesUser, setValuesUser] = useState({
    pessoaFisicaId: null,
    email: null,
    fotoPerfil: "",
    usuarioAtivo: true,
    clientes: [
      //   {
      //     nomeArq: ""
      //     clienteId: 0,
      //     usuarioTipoId: 2,
      //     permissoes: [],
      //   },
    ],
  });

  const [modulosSelecionadosDosClientes, setModulosSelecionadosDosClientes] =
    useState([
      // { clienteId: 0, moduloSelecionado: "" },
    ]);

  const cadastraNovoUsuario = () => {
    //Refatorando o valuesUser clientes ----------------------------------------
    let newArrayClientes = [];
    if (valuesUser.clientes.length > 0) {
      valuesUser.clientes.forEach((cliente) => {
        const { openedOptions, ...atributosCliente } = cliente;
        const arraySistemasAtivosDoCliente = atributosCliente.permissoes.filter(
          (permissao) => permissao.sistemaAtivo
        );

        console.log(arraySistemasAtivosDoCliente);

        const clienteRefatorado = {
          ...atributosCliente,
          permissoes: arraySistemasAtivosDoCliente.map((permissao) => ({
            ...permissao,
            menus: [...permissao.menus.filter((menu) => menu.menuAtivo)].map(
              (menu) => ({
                ...menu,
                acoes: [...menu.acoes.filter((acao) => acao.acaoAtiva)],
              })
            ),
          })),
        };

        newArrayClientes = [...newArrayClientes, clienteRefatorado];
      });
    }

    const valuesUserRefatorado = {
      ...valuesUser,
      clientes: newArrayClientes,
    };

    console.log(valuesUserRefatorado);
    // ------------------------------------------------------------------------

    api
      .post("usuario", valuesUserRefatorado)
      .then((resp) => {
        toast.success("Usuário cadastrado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu errado ao cadastrar o usuário.");
      });

    // if(valuesUser.pessoaFisicaId && valuesUser.email && valuesUser.clientes.length > 0 && valuesUser.clientes)
  };

  const handleAutocompleteChange = (newValue) => {
    if (newValue) {
      setValuesUser((prev) => ({
        ...prev,
        pessoaFisicaId: newValue.id,
      }));

      const objetoCpf = listaCpfs.find((item) => item.dados.id === newValue.id);

      if (objetoCpf && objetoCpf.contato.email) {
        setValuesUser((prev) => ({
          ...prev,
          email: objetoCpf.contato.email,
        }));
      }
    }
  };

  const handleImageChange = (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setValuesUser((prev) => ({
          ...prev,
          fotoPerfil: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpdate = async (e, userId) => {
    console.log("aqui");
    const file = e.target.files[0];
    let base64Image = "";
    if (file) {
      base64Image = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    }

    setValuesUser((prev) => ({
      ...prev,
      fotoPerfil: base64Image,
    }));

    if (base64Image && base64Image !== "") {
      try {
        api
          .put(`/usuario/atualiza-fotoPerfil/${userId}`, {
            fotoPerfil: base64Image,
          })
          .then((resp) => {
            toast.success("Foto de perfil atualizada com sucesso!");
          });
      } catch (error) {
        console.log(error);
        toast.error(
          "Algo deu errado ao atualizar a foto de perfil. Por favor, tente mais tarde"
        );
      }
    }
  };

  return {
    valuesUser,
    setValuesUser,
    modulosSelecionadosDosClientes,
    setModulosSelecionadosDosClientes,
    handleImageChange,
    handleImageUpdate,
    cadastraNovoUsuario,
    handleAutocompleteChange,
  };
};

export default usePagContaPerfil;
