// import * as RS from "../../../../../styles/StyledComponents/StylesRelatorios"
import { FormatCnpjPaste } from "../../../utils/FormatacaoDeDados/FormatCnpjPaste";
import { ReturnDataAtual } from "../../../utils/Gerais/ReturnDataAtual";
import * as RS from "./stylesVisualizacaoRelatorio";

export const RodapeRelatorio = ({ userAuthData, currentClient }) => {
  const dataAtual = new Date();
  const horas = String(dataAtual.getHours()).padStart(2, "0");
  const minutos = String(dataAtual.getMinutes()).padStart(2, "0");
  return (
    <RS.RodapeRelatorio>
      <RS.InfosDaImpressao>
        <p style={{ fontFamily: "Roboto" }}>
          Impresso por <span>{userAuthData.nome}</span> em {ReturnDataAtual()}{" "}
          ás {horas}:{minutos}
        </p>
      </RS.InfosDaImpressao>
      <RS.InfosGeraisRodape>
        <p style={{ fontFamily: "Roboto" }}>
          Fonte:{" "}
          <span style={{ fontFamily: "Roboto" }}>
            Save – Soluções Tecnológicas – www.savest.com.br
          </span>{" "}
          ,{`${currentClient.nomeFantasia} - ${currentClient.endereco.estado}`}{" "}
          CNPJ: {FormatCnpjPaste(currentClient.cnpj)}
        </p>

        {/* <p>
          Página {numeroDaPagina} de {totalDePaginas}
        </p> */}
        <p style={{ fontFamily: "Roboto" }}>Página 1 de 2</p>
      </RS.InfosGeraisRodape>
    </RS.RodapeRelatorio>
  );
};
