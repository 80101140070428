import React, { useContext, useState } from "react";
import { Row } from "react-bootstrap";
import { TituloAbaSolo } from "../../../../styles/StyledComponents/LayoutGerais";
import { especificacoes } from "../Riscos_Fiscais/Especificacoes";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import { Icon } from "@iconify/react/dist/iconify.js";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import { TestButton } from "../../../../utils/TestButton";

const Atuarial = ({
  arrayEspecificacoesNominal,
  setArrayEspecificacoesNominal,
  valueEspecificacao,
  setValueEspecificacao,
}) => {
  const { currentYear } = useContext(CurrentYearContext);
  const [values, setValues] = useState({});
  const [editMode, setEditMode] = useState([]);
  const [oldValues, setOldValues] = useState([]);

  const handleEditToggle = (rowIndex, edit, cancel = false) => {
    const key = Object.keys(values)[rowIndex];

    if (edit) {
      setOldValues((prevOldValues) => ({
        ...prevOldValues,
        [key]: { ...values[key] },
      }));
    } else if (cancel) {
      setValues((prevValues) => ({
        ...prevValues,
        [key]: { ...oldValues[key] },
      }));
    }

    setEditMode((prev) => {
      const newEditMode = [...prev];
      newEditMode[rowIndex] = edit;
      return newEditMode;
    });
  };

  const adicionarEspecificacao = () => {
    if (valueEspecificacao) {
      setValues((prevValues) => ({
        ...prevValues,
        [`${valueEspecificacao}`]: {
          [currentYear - 2]: "0",
          [currentYear - 3]: "1",
          [currentYear - 4]: "2",
        },
      }));
      setEditMode((prev) => [...prev, false]); // Aqui, apenas adicionamos um novo modo de edição falso
    }
  };

  const deleteEspecificacao = (index) => {
    setValues((prevValues) => {
      const newValues = { ...prevValues };
      delete newValues[Object.keys(newValues)[index]];
      return newValues;
    });
    setEditMode((prev) => {
      const newEditMode = [...prev];
      newEditMode.splice(index, 1);
      return newEditMode;
    });
  };

  const handleInputChange = (index, rowIndex, year, event) => {
    const newValue = event.target.value;

    setValues((prevValues) => {
      const key = Object.keys(prevValues)[rowIndex];
      return {
        ...prevValues,
        [key]: {
          ...prevValues[key],
          [year]: newValue,
        },
      };
    });
  };

  return (
    <>
      <Row className="row_form mt-1">
        <TituloAbaSolo>
          Avaliação da Situação Financeira e Atuarial
        </TituloAbaSolo>
      </Row>
      <Row
        className="row_form mt-1"
        style={
          arrayEspecificacoesNominal.length === 0
            ? { borderRadius: "0px 0px 5px 5px" }
            : {}
        }
      >
        <CCol>
          <FloatingLabelInput
            placeholder="Especificações"
            new={true}
            label={"Especificações"}
            tipo="input_select"
            options={[...especificacoes]}
            onSelect={(option) => setValueEspecificacao(option.value)}
            value={valueEspecificacao}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar onClick={adicionarEspecificacao} />
        </CColButtonsAcoes2Icons>
      </Row>
      <Row className={"row_form"}>
        {Object.entries(values || {}).map(([key, value], index) => (
          <React.Fragment key={key}>
            <RowAninhada style={{ marginTop: index !== 0 && "15px" }}>
              <CCol>
                <PTitulosInputs style={{ marginTop: "0px" }}>
                  Especificação
                </PTitulosInputs>
                <FloatingLabelInput
                  disabled
                  value={key}
                  style={{ border: "none" }}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                <Icon
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  onClick={() => deleteEspecificacao(index)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "2px",
                    marginTop: "17px",
                  }}
                />
              </CColButtonsAcoes2Icons>
            </RowAninhada>
            <RowAninhada style={{ marginTop: "15px" }}>
              <CCol>
                {index === 0 && (
                  <PTitulosInputs style={{ textAlign: "center" }}>
                    {Object.keys(value)[2]}
                  </PTitulosInputs>
                )}
                <FormControlListaDeInputs
                  value={value[`${currentYear - 2}`]}
                  onChange={(e) =>
                    handleInputChange(index, index, currentYear - 2, e)
                  }
                  height={"30px"}
                  disabled={!editMode[index]}
                />
              </CCol>
              <CCol>
                {index === 0 && (
                  <PTitulosInputs style={{ textAlign: "center" }}>
                    {Object.keys(value)[1]}
                  </PTitulosInputs>
                )}
                <FormControlListaDeInputs
                  value={value[`${currentYear - 3}`]}
                  onChange={(e) =>
                    handleInputChange(index, index, currentYear - 3, e)
                  }
                  height={"30px"}
                  disabled={!editMode[index]}
                />
              </CCol>
              <CCol>
                {index === 0 && (
                  <PTitulosInputs style={{ textAlign: "center" }}>
                    {Object.keys(value)[0]}
                  </PTitulosInputs>
                )}
                <FormControlListaDeInputs
                  value={value[`${currentYear - 4}`]}
                  onChange={(e) =>
                    handleInputChange(index, index, currentYear - 4, e)
                  }
                  height={"30px"}
                  disabled={!editMode[index]}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                {index === 0 && <PTitulosInputs>Ação</PTitulosInputs>}
                <Icon
                  icon={editMode[index] ? "fluent:save-24-filled" : "bx:edit"}
                  height="26"
                  color={editMode[index] ? "#008CFF" : "#5971C8"}
                  onClick={() => handleEditToggle(index, !editMode[index])}
                  style={{ cursor: "pointer", marginTop: "3px" }}
                />
                {editMode[index] && (
                  <Icon
                    icon="ic:baseline-cancel"
                    height="26"
                    color="#F24E1E"
                    onClick={() => handleEditToggle(index, false, true)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2px",
                      marginRight: "-5px",
                      marginTop: "3px",
                    }}
                  />
                )}
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          </React.Fragment>
        ))}
      </Row>
      <TestButton onClick={() => console.log(values)}>Console</TestButton>
    </>
  );
};

export default Atuarial;
