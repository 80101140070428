import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";

export const NotaFiscal = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Nota Fiscal</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1">
        <CCol>
          <FloatingLabelInput
            label="Número Nota Fiscal"
            placeholder="Número Nota Fiscal"
          />
        </CCol>
        <CCol>
          <FloatingLabelInput label="Série NF" placeholder="Série NF" />
        </CCol>
        <CCol md={8}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Tipo Nota Fiscal"
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }} $borderRadiusType={2}>
        <CCol>
          <InputData />
        </CCol>
        <CCol>
          <InputFormatRealBrasileiro iconReal />
        </CCol>
        <CCol>
          <FloatingLabelInput
            placeholder="Número da chave de acesso da NF-e"
            label="Número da chave de acesso da NF-e"
          />
        </CCol>
      </RowForm>
    </>
  );
};
