import { useCurrentYear } from "../../../../hooks/useCurrentYear";

export const ReturnArrayAnos = () => {
  const { currentYear } = useCurrentYear();

  let arrayYears = [];

  for (let i = -3; i < 3; i++) {
    arrayYears.push(currentYear + i);
  }

  return arrayYears;
};
