import React from "react";
import styled from "styled-components";

const NumberBox = styled.div`
  background-color: ${(props) =>
    props.percentage === 0
      ? "#b0b0b7"
      : props.percentage < 50
      ? "#f6bf4a"
      : props.percentage < 100
      ? "#f67f4a"
      : "#19cf70"};
  border-radius: 5px;
  padding: 7px 0;
  width: 55px;
  height: fit-content;
  text-align: center;
  color: #ffffff;
  font-size: 25px;
  font-weight: 600;
  margin-right: 15px;
`;

const DailyCard = ({ day, percentage }) => {
  return (
    <NumberBox percentage={percentage} style={{ margin: "0 7px" }}>
      {day < 10 ? "0" + day : day}
    </NumberBox>
  );
};

export default DailyCard;
