// import * as RS from "../../../../../styles/StyledComponents/StylesRelatorios"
import * as RS from "./stylesVisualizacaoRelatorio"

export const InfosRelatorio = ({infoRelatorio,styledCol}) => {
  return (
    <RS.DivInfosDoRelatorio
      style={styledCol}
    >
      <p>{infoRelatorio}</p>
    </RS.DivInfosDoRelatorio>

  )
}