import { Container, Row } from "react-bootstrap";
import styled from "styled-components";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowAninhada, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";

//style={{ height: "38px", minHeight: "38px" }}
export const RowInfosAcao = styled(Row)`
  align-content: baseline;
  height: 80px;
  min-height: 80px;
  margin: 0px;
  border-radius: 10px;
  background-color: #ebeaea;
  padding: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  resize: vertical;
  border-radius: 5px;
  border: 1px solid #ccc;
  position: relative;

  span {
    color: #73767e;
  }

  p {
    margin: 0px;
    margin-left: 10px;
    color: #515c70;
    font-weight: 500;
    font-size: 15.5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span + p {
    display: inline;
  }
`;

const mockData = [{}, {}, {}, {}];

const QDD = () => {
  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"QDD - Quadro Detalhado da Despesa"}
        PaginaSubtitulo={"Contabilidade"}
        PaginaConsulta={"/contabilidade/consulta/qdd-despesa"}
        BotaoSaveAtivo={false}
        BotaoNovoAtivo={false}
      />
      {/* <button
        onClick={() => {
          console.log(newValuesQDD);
        }}
      >
        console
      </button> */}
      <Row className="row_form_2 mt-1">
        <CCol>
          <FloatingLabelInput label={"Ação"} value={"--"} disabled />
        </CCol>
      </Row>

      <Row key="row1" className="row_form_2" style={{ marginTop: "-14px" }}>
        <CCol md={12}>
          <FloatingLabelInput
            as="textarea"
            disabled
            style={{
              height: "38px",
              minHeight: "38px",
              backgroundColor: "#ebeaea",
            }}
            tipo="text_area"
            placeholder="Objetivos"
            label="Objetivos"
            value="--"
          />
        </CCol>
      </Row>
      <Row key="row2" className="row_form_2" style={{ marginTop: "-21px" }}>
        <CCol md={12}>
          {/* <LabelStyled active={true} style={{ top: "33px" }}>
                Classificação Funcional
              </LabelStyled> */}
          <RowInfosAcao>
            <CCol md={7}>
              <span>Unidade Orçamentária: </span> <p>--</p>
            </CCol>
            <CCol md={5}>
              <span>Função: </span>
              <p>--</p>
            </CCol>
            <CCol md={7} style={{ marginTop: "5px" }}>
              <span>Programa: </span>
              <p>--</p>
            </CCol>
            <CCol md={5} style={{ marginTop: "5px" }}>
              <span>Subfunção: </span>
              <p>--</p>
            </CCol>
          </RowInfosAcao>
        </CCol>
      </Row>

      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Despesa Fixada
          </span>
        </CCol>
      </Row>

      <RowForm className="mt-1">
        <CCol>
          <PTitulosInputs>Fonte de Recursos</PTitulosInputs>
          <FormControlListaDeInputs value="--" disabled />
        </CCol>
      </RowForm>

      <RowForm $borderRadiusType={2}>
        <CCol>
          <PTitulosInputs>Ficha</PTitulosInputs>
          <FormControlListaDeInputs
            radiusborder={mockData.length > 0 ? "5px 5px 0px 0px" : "5px"}
            disabled
            value="--"
          />
        </CCol>
        <CCol>
          <PTitulosInputs>
            Natureza da Despesa - Elemento de Despesa
          </PTitulosInputs>
          <FormControlListaDeInputs
            radiusborder={mockData.length > 0 ? "5px 5px 0px 0px" : "5px"}
            disabled
            value="--"
          />
        </CCol>
        <CCol>
          <PTitulosInputs>Esfera</PTitulosInputs>
          <FormControlListaDeInputs
            radiusborder={mockData.length > 0 ? "5px 5px 0px 0px" : "5px"}
            disabled
            value="--"
          />
        </CCol>
        <CCol>
          <PTitulosInputs>Despesas Fixadas</PTitulosInputs>
          <FormControlListaDeInputs
            radiusborder={mockData.length > 0 ? "5px 5px 0px 0px" : "5px"}
            disabled
            value="--"
          />
        </CCol>
        {mockData.map((item, index, array) => (
          <RowAninhada key={index} style={{ marginTop: "-1px" }}>
            <CCol>
              <FormControlListaDeInputs
                disabled
                value="--"
                radiusborder={
                  array.length - 1 !== index ? "0px" : "0px 0px 5px 5px"
                }
              />
            </CCol>
            <CCol>
              <FormControlListaDeInputs
                disabled
                value="--"
                radiusborder={
                  array.length - 1 !== index ? "0px" : "0px 0px 5px 5px"
                }
              />
            </CCol>
            <CCol>
              <FormControlListaDeInputs
                disabled
                value="--"
                radiusborder={
                  array.length - 1 !== index ? "0px" : "0px 0px 5px 5px"
                }
              />
            </CCol>
            <CCol>
              <FormControlListaDeInputs
                disabled
                value="--"
                radiusborder={
                  array.length - 1 !== index ? "0px" : "0px 0px 5px 5px"
                }
              />
            </CCol>
          </RowAninhada>
        ))}
      </RowForm>

      <div className="p-3"></div>
    </Container>
  );
};

export default QDD;
