import * as XLSX from "sheetjs-style";

export const generateFileXls = (
  data,
  relatorioName,
  tablesNamesRelatorio = [],
  customHeaders = []
) => {
  const workbook = XLSX.utils.book_new();
  console.log(data);

  const createWorksheet = (dataTable, sheetName) => {
    let worksheet;
    const dataWithHeaders =
      customHeaders.length > 0
        ? [
            customHeaders,
            ...dataTable.map((row) =>
              customHeaders.map((header) => row[header] || "")
            ),
          ]
        : dataTable;

    // Converte o array para uma planilha
    worksheet =
      customHeaders.length > 0
        ? XLSX.utils.aoa_to_sheet(dataWithHeaders)
        : XLSX.utils.json_to_sheet(dataTable);

    // Calcula larguras das colunas
    let maxLengths = {};

    if (customHeaders.length > 0) {
      // Se estamos usando headers personalizados, calculamos a largura baseado nos dados com headers
      dataWithHeaders.forEach((row, rowIndex) => {
        row.forEach((value, colIndex) => {
          maxLengths[colIndex] = Math.max(
            maxLengths[colIndex] ||
              (customHeaders[colIndex] ? customHeaders[colIndex].length : 0),
            String(value).length
          );
        });
      });
    } else {
      // Se estamos usando headers padrão, calculamos a largura baseado nas chaves dos objetos
      dataTable.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          maxLengths[key] = Math.max(
            maxLengths[key] || key.length,
            String(value).length
          );
        });
      });

      // Ajusta colWidths para o índice das colunas no array
      const colHeaders = Object.keys(dataTable[0]);
      maxLengths = colHeaders.reduce((acc, key, index) => {
        acc[index] = maxLengths[key] || key.length;
        return acc;
      }, {});
    }

    const colWidths = Object.entries(maxLengths).map(([key, length]) => {
      return { wch: length + 2 };
    });
    worksheet["!cols"] = colWidths;

    // Aplica estilos
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      const fillColor = R % 2 === 0 ? "FFFFFF" : "E9ECEF";
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (!worksheet[cell_ref]) worksheet[cell_ref] = { t: "s", v: "" };

        worksheet[cell_ref].s = {
          font: {
            name: "Arial",
            sz: 12,
            color: { rgb: "515c70" },
            bold: R === range.s.r, // Bold only for headers
          },
          fill: {
            fgColor: { rgb: fillColor },
          },
          alignment: {
            vertical: "center",
            horizontal: "left",
          },
        };
      }
    }

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      sheetName.replace(/[:\\/?*[\]]/g, "")
    );
  };

  if (Array.isArray(data[0])) {
    data.forEach((dataTable, index) =>
      createWorksheet(
        dataTable,
        tablesNamesRelatorio.length
          ? tablesNamesRelatorio[index]
          : `${relatorioName + index}`
      )
    );
  } else {
    createWorksheet(data, relatorioName, workbook);
  }

  XLSX.writeFile(workbook, `${relatorioName}.xlsx`, { compression: true });
};
