import React, { useCallback, useEffect, useState } from "react";
import {
  CCol,
  ContainerToModal,
  RowAninhada,
  RowForm,
} from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import RowTitulo from "../../../../components/Grid/RowTitulo";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import api from "../../../../utils/api";
import { useParams } from "react-router-dom";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RowInfosAcao = styled(Row)`
  align-content: baseline;
  min-height: ${({ minHeight }) => minHeight || "80px"};
  margin: 0px;
  border-radius: 10px;
  background-color: #ebeaea;
  padding: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  resize: vertical;
  border-radius: 5px;
  border: 1px solid #ccc;
  position: relative;

  span {
    color: #73767e;
  }

  p {
    margin: 0px;
    margin-left: 10px;
    color: #515c70;
    font-weight: 500;
    font-size: 15.5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span + p {
    display: inline;
  }
`;

const QDR = () => {
  const { id: idParams } = useParams();
  const { currentYear } = useCurrentYear();

  const [values, setValues] = useState({
    unidadeOrcamentaria: "",
    naturezaDaReceita: "",
    funcao: "",
    fichas: [],
    saldos: {},
  });

  const fetchData = useCallback(async () => {
    const resp = await api.get(`qdr/por-id/${idParams}/${currentYear}`);
    const data = resp.data[0];
    console.log(data);

    setValues((prev) => ({
      ...prev,
      unidadeOrcamentaria: `${data.unidadeOrcamentariaId.codigo} - ${data.unidadeOrcamentariaId.titulo}`,
      naturezaDaReceita: `${data.naturezaDaReceita.codigo} - ${data.naturezaDaReceita.especificacao}`,
      funcao: data.naturezaDaReceita.descricao,
      fichas: [...data.fontesDeRecursos],
    }));
  }, [idParams, currentYear]);

  useEffect(() => {
    if (idParams) {
      fetchData();
    }
  }, [idParams, fetchData]);

  const returnBorderRadius = (tamanhoArray, index) => {
    if (index === 0 && tamanhoArray === 1) {
      return "5px";
    } else if (index === 0 && tamanhoArray > 1) {
      return "5px 5px 0px 0px";
    } else if (index > 0 && index === tamanhoArray - 1) {
      return "0px 0px 5px 5px";
    } else {
      return "0px";
    }
  };

  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"LOA"}
        PaginaSubtitulo={"QDR - Quadro Detalhado da Receita"}
        RowTitle={"QDR - Quadro Detalhado da Receita"}
        PaginaConsulta={"/contabilidade/cadastros/qdr-receita/consulta"}
        BotaoSaveAtivo={false}
        BotaoNovoAtivo={false}
      />

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol>
          <FloatingLabelInput
            label="Unidade Orçamentária"
            disabled
            value={values.unidadeOrcamentaria || "--"}
          />
        </CCol>
      </RowForm>

      <RowTitulo>Receita Prevista</RowTitulo>

      <RowForm className="mt-1">
        <CCol>
          <FloatingLabelInput
            label="Natureza da Receita"
            disabled
            value={values.naturezaDaReceita || "--"}
          />
        </CCol>
      </RowForm>

      <RowForm $borderRadiusType={2} style={{ marginTop: "-14px" }}>
        <CCol md={12}>
          <FloatingLabelInput
            as="textarea"
            tipo="text_area"
            readOnly
            disabled
            style={{ height: "38px", minHeight: "38px" }}
            label="Função"
            value={values.funcao || "--"}
          />
        </CCol>
      </RowForm>

      <RowForm $borderRadiusType={3} style={{ marginTop: "16px" }}>
        <CCol md={1}>
          <PTitulosInputs>Ficha</PTitulosInputs>
        </CCol>
        <CCol md={7}>
          <PTitulosInputs>Fonte</PTitulosInputs>
        </CCol>
        <CCol>
          <PTitulosInputs>Esfera</PTitulosInputs>
        </CCol>
        <CCol>
          <PTitulosInputs>Valor</PTitulosInputs>
        </CCol>
        {values.fichas.map((item, index, array) => (
          <RowAninhada key={index} style={{ marginTop: "-1px" }}>
            <CCol md={1}>
              <FormControlListaDeInputs
                disabled
                value={item.ficha}
                radiusborder={returnBorderRadius(array.length, index)}
              />
            </CCol>
            <CCol md={7}>
              <FormControlListaDeInputs
                disabled
                value={`${item.conta} - ${item.titulo}`}
                radiusborder={returnBorderRadius(array.length, index)}
              />
            </CCol>
            <CCol>
              <FormControlListaDeInputs
                disabled
                value={item.esfera}
                radiusborder={returnBorderRadius(array.length, index)}
              />
            </CCol>
            <CCol>
              <FormControlListaDeInputs
                disabled
                value={`R$ ${FormatValueToLocaleString(item.valorPrevisto)}`}
                radiusborder={returnBorderRadius(array.length, index)}
              />
            </CCol>
          </RowAninhada>
        ))}
      </RowForm>

      <RowTitulo>Saldos</RowTitulo>
      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol>
          <PTitulosInputs>Previsto</PTitulosInputs>
          <FormControlListaDeInputs disabled value={"R$ 20.000,00"} />
        </CCol>
        <CCol>
          <PTitulosInputs>Arrecadado</PTitulosInputs>
          <FormControlListaDeInputs disabled value={"R$ 20.000,00"} />
        </CCol>
        <CCol>
          <PTitulosInputs>Anulado</PTitulosInputs>
          <FormControlListaDeInputs disabled value={"R$ 20.000,00"} />
        </CCol>
        <CCol>
          <PTitulosInputs>Saldo Atualizado</PTitulosInputs>
          <FormControlListaDeInputs disabled value={"R$ 20.000,00"} />
        </CCol>
      </RowForm>

      <div className="p-3"></div>
    </ContainerToModal>
  );
};

export default QDR;
