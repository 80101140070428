import React from "react";
import { LabelCheckStyled } from "../../pages/PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import styled from "styled-components";

const ContainerCheckBox = styled.label`
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : "trasparente"};
  padding: 3px 8px;
  border-radius: 5px;
  display: block;
  opacity: ${({ $opacity }) => ($opacity ? $opacity : "1")};
  cursor: pointer;
`;

const CheckBoxSistemas = ({ $backgroundColor, $opacity, ...props }) => {
  return (
    <ContainerCheckBox
      $backgroundColor={$backgroundColor}
      $opacity={$opacity}
      style={props.style}
    >
      <LabelCheckStyled
        {...props}
        style={{
          color: $backgroundColor ? "#fff" : "#6c7585",
        }}
      />
    </ContainerCheckBox>
  );
};

export default CheckBoxSistemas;
