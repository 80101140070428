import React, { useState, useContext, useEffect } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { CurrentYearContext } from "../../../../../../../contexts/YearContext";
import {
  CCol,
  RowAninhada,
  CColButtonsAcoes2Icons,
} from "../../../../../../../components/Grid/CCol";
import { FormControlListaDeInputs } from "../../../../../../../styles/StyledComponents/ListaDeInputs";
import styled from "styled-components";
import { Row } from "react-bootstrap";

const CustomInput = ({ title, md = 2, size, Btop, Bbottom, ...props }) => {
  return (
    <CCol md={md} style={size && { width: size }}>
      {title && <Title>{title}</Title>}
      <FormControlListaDeInputs
        radiusborder={Btop ? "5px 5px 0 0" : Bbottom ? "0 0 5px 5px" : "0"}
        {...props}
      />
    </CCol>
  );
};

const PrepararQDR = () => {
  const { currentYear } = useContext(CurrentYearContext);

  const [values, setValues] = useState([]);
  const [oldValues, setOldValues] = useState([]);
  const [editMode, setEditMode] = useState([]);
  const [titles, setTitles] = useState([]);
  // const [entidades, setEntidades] = useState([]);

  const Icons = ({ marginTop = "2px", index, onClick }) => {
    const open = editMode[index];
    return (
      <>
        <Icon
          icon={editMode[index] ? "bx:edit" : "fluent:save-24-filled"}
          color={editMode[index] ? "#5971C8" : "#008CFF"}
          height="26"
          style={{
            cursor: "pointer",
            marginLeft: "5px",
            marginTop: marginTop,
          }}
          onClick={() => onClick(index, open)}
        />
        <Icon
          icon="ic:baseline-cancel"
          color={!editMode[index] ? "#F24E1E" : "transparent"}
          height="26"
          style={{
            cursor: !editMode[index] ? "pointer" : "auto",
            marginLeft: "2px",
            marginTop: marginTop,
          }}
          onClick={() => onClick(index, false, true)}
          // icon="fluent:save-24-filled"
          // color="#008CFF"
        />
      </>
    );
  };

  const updateTitles = () => {
    setTitles([
      "UG",
      "Natureza da Receita",
      "tipo",
      "Esfera",
      `${currentYear - 4} - Previsão`,
      `${currentYear - 4} - Arrecadada`,
      `${currentYear - 3} - Previsão`,
      `${currentYear - 3} - Arrecadada`,
      `${currentYear - 2} - Previsão`,
      `${currentYear - 2} - Arrecadada`,
      `${currentYear - 1} - Previsão`,
      `${currentYear - 1} - Arrecadada`,
      `${currentYear} - Previsão`,
      `${currentYear + 1} - Previsão`,
      `${currentYear + 1} - Percentual`,
      `${currentYear + 2} - Previsão`,
      `${currentYear + 2} - Percentual`,
    ]);
  };

  const updateValues = () => {
    const newValues = [0, 0].map((_, index) => {
      return {
        ug: `0${index}`,
        naturezaDaReceita: `1${index}`,
        tipo: `2${index}`,
        // esfera: `3${index}`,
        [`${currentYear - 4}Previsao`]: `4${index}`,
        [`${currentYear - 4}Arrecadada`]: `5${index}`,
        [`${currentYear - 3}Previsao`]: `6${index}`,
        [`${currentYear - 3}Arrecadada`]: `7${index}`,
        [`${currentYear - 2}Previsao`]: `8${index}`,
        [`${currentYear - 2}Arrecadada`]: `9${index}`,
        [`${currentYear - 1}Previsao`]: `10${index}`,
        [`${currentYear - 1}Arrecadada`]: `12${index}`,
        [`${currentYear}Previsao`]: `13${index}`,
        [`${currentYear + 1}Previsao`]: `14${index}`,
        [`${currentYear + 1}Percentual`]: `15${index}`,
        [`${currentYear + 2}Previsao`]: `16${index}`,
        [`${currentYear + 2}Percentual`]: `17${index}`,
      };
    });
    const editables = new Array(newValues.length).fill(true);
    setValues(newValues);
    setOldValues(newValues);
    setEditMode(editables);
  };

  const handleInputChange = (index, field, value) => {
    setValues((prevValues) =>
      prevValues.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  const handleEditMode = (index, open, cancel) => {
    setEditMode((prevEditMode) => {
      const newEditMode = [...prevEditMode];
      newEditMode[index] = !newEditMode[index];
      return newEditMode;
    });

    if (open) {
      setOldValues(() => {
        const thisOldValues = oldValues;
        thisOldValues[index] = values[index];
        return thisOldValues;
      });
    }

    if (cancel) {
      setValues(() => {
        const thisValues = values;
        thisValues[index] = oldValues[index];
        return thisValues;
      });
    }
  };

  useEffect(() => {
    updateValues();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateTitles();
    // eslint-disable-next-line
  }, [currentYear]);

  return (
    <>
      <Row className="row_form mt-1">
        <StyledCCol style={{ height: "100px" }}>
          {values?.map((_, index) => {
            const btop = index === 0;
            const bbottom = index === values.length - 1;
            return (
              <RowAninhada
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[0]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.ug || ""}
                  onChange={(e) =>
                    handleInputChange(index, "ug", e.target.value)
                  }
                  size={"170px"}
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[1]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.naturezaDaReceita || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "naturezaDaReceita",
                      e.target.value
                    )
                  }
                  md={3}
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[2]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.tipo || ""}
                  onChange={(e) =>
                    handleInputChange(index, "tipo", e.target.value)
                  }
                  size={"80px"}
                />
                {/* <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[3]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.esfera || ""}
                  onChange={(e) =>
                    handleInputChange(index, "esfera", e.target.value)
                  }
                /> */}
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[3]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 4}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 4}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[4]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 4}Arrecadada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 4}Arrecadada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[5]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 3}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 3}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[6]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 3}Arrecadada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 3}Arrecadada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[7]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 2}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 2}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[8]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 2}Arrecadada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 2}Arrecadada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[9]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 1}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 1}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[10]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear - 1}Arrecadada`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear - 1}Arrecadada`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[11]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[12]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear + 1}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear + 1}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[13]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear + 1}Percentual`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear + 1}Percentual`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[14]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear + 2}Previsao`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear + 2}Previsao`,
                      e.target.value
                    )
                  }
                />
                <CustomInput
                  disabled={editMode[index]}
                  title={index === 0 && titles[15]}
                  Btop={btop}
                  Bbottom={bbottom}
                  value={values[index]?.[`${currentYear + 2}Percentual`] || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      `${currentYear + 2}Percentual`,
                      e.target.value
                    )
                  }
                />
              </RowAninhada>
            );
          })}
        </StyledCCol>
        <CColButtonsAcoes2Icons md={2}>
          <Title style={{ textAlign: "left" }}>Ação</Title>
          {values.map((_, index) => {
            const margintop = index === 0 ? "2px" : "4px";
            return (
              <Icons
                marginTop={margintop}
                index={index}
                onClick={handleEditMode}
              />
            );
          })}
        </CColButtonsAcoes2Icons>
      </Row>
    </>
  );
};

export default PrepararQDR;

const Title = styled.p`
  text-align: center;
  margin-top: 0;
  padding: 0px;
  margin-bottom: 0px;
  color: #888a8e;
  font-weight: 600;
  font-size: 14px;
`;

const StyledCCol = styled(CCol)`
  overflow: auto;

  &::-webkit-scrollbar {
    height: 2px;
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
