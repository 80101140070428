
export const getEvolucaoPatrimonio = async (
  clienteId,
  entidadesGestorasDoCliente,
  ano = 2024
) => {
  
  return {
    contentHeader: getPreviousYears(ano),
  };
};

function getPreviousYears(year) {
  const parsedYear = parseInt(year, 10);
  const yearRange = [];

  for (let i = parsedYear - 1; i >= parsedYear - 3; i--) {
    yearRange.push(i.toString());
  }

  return yearRange;
}


// const getHeader = (year) => {
//   const years = getPreviousYears(year);

//   const headerContent = years.map((year, index) => ({
//     year,
//     content: dataHeader[index],
//   }));
//   return headerContent;
// };
