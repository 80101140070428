import React from "react";
import { CCol, RowForm } from "../Grid/CCol";
import { Icon } from "@iconify/react/dist/iconify.js";

export const CollapseRow = ({ title, collapse = false, ...props }) => {
  return (
    <>
      <RowForm
        $borderRadiusType={collapse ? 1 : 3}
        {...props}
        style={{ cursor: "pointer", ...props.style }}
      >
        <CCol style={{ display: "flex", alignItems: "center", gap: 10 }}>
          {collapse ? (
            <Icon
              icon="mdi-light:minus-box"
              style={{ color: "#515c70" }}
              fontSize={30}
            />
          ) : (
            <Icon
              icon="material-symbols-light:add-box-outline"
              style={{ color: "#515c70" }}
              fontSize={30}
            />
          )}
          <span style={{ fontWeight: 700, fontSize: 16, color: "#515c70" }}>
            {title}
          </span>
        </CCol>
      </RowForm>
    </>
  );
};
