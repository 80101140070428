import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";

export const OrdemCronologicaAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Ordem Cronológica</span>
        </div>
      </RowTituloAba>

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Classificação da Ordem Cronológica"
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            disabled
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Desobrigação da Ordem Cronológica"
          />
        </CCol>
      </RowForm>
    </>
  );
};
