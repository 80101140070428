import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import { CCol } from "./CCol";
import ReactInputMask from "react-input-mask";

const FormGroup = styled(InputGroup)`
  position: relative;
  color: #515c70;
  z-index: 0;

  .form-control:focus {
    border-color: #ccc;
    box-shadow: none;
  }

  label {
    position: absolute;
    top: 10px;
    background-color: #cccccc00;
    left: 2.5rem;
    color: #99999900;
    font-size: 0.8rem;
    transition: all 0.2s ease-out;
    pointer-events: none;
    z-index: 1;
  }

  &.active label {
    color: #515c70;
    backdrop-filter: blur(1.5px);
    top: -9px;
    font-size: 0.75rem;
  }
`;

export const InputMaskStyled = styled(ReactInputMask)`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 38px;
  padding-left: ${(props) => (props.paddingleft ? props.paddingleft : "40px")};
  color: #6c7585;
  background: transparent;

  &:focus {
    outline: none;
    background: transparent;
  }
`;

function InputSvg(props) {
  const [isActive, setIsActive] = useState(false);

  function handleFocus() {
    setIsActive(true);
  }

  function handleBlur(event) {
    if (event.target.value.trim() === "") {
      setIsActive(false);
    }
  }

  return (
    <CCol md={props.md}>
      <FormGroup
        className={`${isActive || props.value ? "active" : ""}`}
        $controlId={props.$controlId}
      >
        <InputGroup.Text
          style={{
            backgroundColor: "transparent",
            borderRight: "none",
            marginRight: "-15px",
          }}
        >
          <Icon
            icon={props.icon}
            width={props.width}
            height={props.height}
            color={props.color}
          />
        </InputGroup.Text>
        <Form.Control
          style={{
            backgroundColor: "transparent",
            borderLeft: "none",
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
            color: "#515c70",
          }}
          widthinput={props.widthinput}
          mask={props.mask}
          id={props.id}
          type={props.type}
          name={props.name}
          required={props.required}
          disabled={props.disabled}
          placeholder={props.placeholder}
          value={props.value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={props.onChange}
        />
        <Form.Label style={{ color: "#515c70" }}>{props.label}</Form.Label>
      </FormGroup>
    </CCol>
  );
}

export default InputSvg;
