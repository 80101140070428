import React from "react";
import { RowTituloAba } from "./StyledComponentsContaPerfil";
import { CCol } from "../../../components/Grid/CCol";
import { Row, Table } from "react-bootstrap";
import {
  TituloTable,
  TituloTableAction,
  BodyTable,
} from "../../../components/TableConsulta/TableConsulta";
import { Icon } from "@iconify/react";
import styled from "styled-components";
import SwitchButton from "../../../components/Inputs/SwitchButton";

const BoxSystem = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 5px;
  border-radius: 5px;
  background-color: ${({ $color }) => ($color ? $color : "#B1B0B6")};
`;

const TableClientes = styled(Table)`
  td,
  th {
    vertical-align: middle;
  }
`;

const AbaClientesDoUsuario = ({
  idDoUsuario,
  clientesDoUsuario,
  handleDisableClient,
}) => {
  return (
    <>
      <RowTituloAba>
        <CCol>
          <span>Clientes do Usuário</span>
        </CCol>
      </RowTituloAba>

      <Row className="row_form_2 mt-1">
        <CCol>
          {idDoUsuario ? (
            <TableClientes responsive>
              <thead>
                <tr>
                  <TituloTable>Entidade Gestora</TituloTable>
                  <TituloTable>Doc Autorizativo</TituloTable>
                  <TituloTable>Tipo de Usuário</TituloTable>
                  <TituloTable>Sistemas</TituloTable>
                  <TituloTableAction>Ação</TituloTableAction>
                </tr>
              </thead>
              <BodyTable>
                {clientesDoUsuario.map((cliente, indexCliente) => (
                  <tr key={indexCliente}>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <div>{cliente.nomeFantasia}</div>
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <div>
                        <Icon icon="vscode-icons:file-type-pdf2" height={26} />
                      </div>
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <div>{cliente.tipoDeUsuario}</div>
                    </td>
                    <td>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {cliente.sistemas.map((sistema) => {
                          if (sistema.sistema === "Planejamento") {
                            return <BoxSystem $color="#e79900" />;
                          } else if (sistema.sistema === "Gerenciador") {
                            return <BoxSystem $color="#515C70" />;
                          } else if (sistema.sistema === "Financeiro") {
                            return <BoxSystem $color="#2F7716" />;
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </td>

                    <td>
                      <SwitchButton
                        $checked={cliente.clienteAtivo}
                        onClick={() => handleDisableClient(cliente.clienteId)}
                      />
                    </td>
                  </tr>
                ))}
              </BodyTable>
            </TableClientes>
          ) : (
            ""
          )}
        </CCol>
      </Row>
    </>
  );
};

export default AbaClientesDoUsuario;
