import styled from "styled-components";

export const OptionsStyle = styled.span`
  color: #515c70;
  line-height: 35px;
  font-size: 0.8em;
  margin-left: 20px;
  cursor: pointer;

  ${(props) =>
    props.ativo
      ? `
      font-weight: 600;
    border-bottom: 2px solid #515c70;
  `
      : ""}
`;

export const SpanOptionMetasFiscais = styled.span`
  color: #515c70;
  font-weight: ${(props) => (props.ativo ? "600" : "500")};
  ${(props) => (props.ativo ? "border-bottom: 2px solid #515c70" : "")};
  line-height: 35px;
  font-size: 0.8em;
  margin-left: 45px;
  cursor: pointer;
`;
