import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { useFetchData } from "../../../../hooks/useFetchData";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol } from "../../../../components/Grid/CCol";
import Dashboard from "../../../../components/ComponentesDeDashboard/Dashboard";

export const DashboardTipoPrograma = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { data: dataTipoDePrograma } = useFetchData(
    `tipoDePrograma/por-regiao/${currentClient.endereco.municipio}`
  );
  const [quantidadeTipoDePrograma, setQuantidadeTipoDePrograma] = useState("0");
  const [box1, setBox1] = useState("0");
  const navigate = useNavigate();
  const options = [
    { label: "Quantidade", value: null },
    { label: "Quantidade", value: 1 },
  ];

  useEffect(() => {
    if (dataTipoDePrograma) {
      setQuantidadeTipoDePrograma(
        dataTipoDePrograma.length < 10
          ? "0" + dataTipoDePrograma.length
          : dataTipoDePrograma.length
      );
    }
  }, [dataTipoDePrograma]);

  useEffect(() => {
    if (quantidadeTipoDePrograma > 0) {
      setBox1({
        boxTitle: "Quantidade",
        boxSubtitle: "Tipos de Programa",
        boxValue: quantidadeTipoDePrograma,
      });
    }
  }, [quantidadeTipoDePrograma]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Tipo de Programa"}
        PaginaSubtitulo={"Dashboard"}
        PaginaConsulta={"/planejamento/cadastro/tipo-de-programa/consulta"}
        BotaoNovoFunction={() =>
          navigate("/planejamento/cadastro/tipo-de-programa")
        }
        BotaoSaveAtivo={false}
      />
      <Row className="row_form_2 mt-1" style={{ padding: "20px 10px" }}>
        <CCol>
          <Dashboard box1={box1} options={options} />
        </CCol>
        <CCol>
          <Dashboard />
        </CCol>
      </Row>
      <div className="p-3"></div>
    </Container>
  );
};
