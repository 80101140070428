import React, { useContext, useEffect, useState } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowForm,
  RowToModal,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";
import { OptionsStyle } from "../../../Paginas_Sistemas_Planejamento/Cadastros/FonteDeRecursos/FonteDeRecursos";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import api from "../../../../utils/api";
import { toast } from "react-toastify";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import ReactInputMask from "react-input-mask";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import OutrasInfos from "./OutrasInfos";
import { Icon } from "@iconify/react/dist/iconify.js";

const tiposDeVinculo = [
  { label: "00 - Demais", value: "00 - Demais" },
  { label: "10 - Saúde", value: "10 - Saúde" },
  { label: "12 - Educação", value: "12 - Educação" },
  { label: "08 - Assistência Social", value: "08 - Assistência Social" },
  { label: "09 - Previdência Social", value: "09 - Previdência Social" },
];

const tiposConta = [
  { label: "Conta Corrente", value: "1" },
  { label: "Conta Corrente - Fopag", value: "2" },
  { label: "Conta Investimento - Títulos Públicos", value: "3" },
  { label: "Conta Investimento - Poupança", value: "4" },
  { label: "Conta Investimento - Fundo de Investimento", value: "5" },
  { label: "Conta Investimento - CDB", value: "6" },
  {
    label:
      "Conta Investimento - Outras Aplicações Financeiras de Liquidez Imediata",
    value: "7",
  },
];

const ContaBancariaComponent = ({
  abaSecundariaSelecionada,
  setAbaSecundariaSelecionada,
  valuesContaBancaria,
  setValuesContaBancaria,
  paramsId,
  inputFonteValue,
  setInputFonteValue,
  inputAgencia,
  setInputAgencia,
  inputContaCorrente,
  setInputContaCorrente,
}) => {
  const { currentYear } = useCurrentYear();
  const [todasFontesDeRceursos, setTodasFontesDeRecursos] = useState([]);
  const [fontesDeRecursosFiltradas, setFontesDeRecursosFiltradas] = useState(
    []
  );
  const [optionsContasCorrentes, setOptionsContasCorrentes] = useState([]);
  const [todasAgencias, setTodasAgencias] = useState([]);
  const [agenciasFiltradas, setAgenciasFiltradas] = useState([]);
  const dataAtual = ReturnDataAtual()
    .replace(/\//g, "-")
    .split("-")
    .reverse()
    .join("-");
  const [fonteParaAdicionar, setFonteParaAdicionar] = useState({
    fonteId: null,
    valor: 0,
    data: dataAtual,
  });
  const [fonteToEditIndex, setFonteToEditIndex] = useState(-1);
  const [valuesFonteAntesDaEdicao, setValuesFonteAntesDaEdicao] = useState({
    data: "",
    valor: "",
  });

  //

  //contexts
  const { currentClient } = useContext(CurrentClientContext);

  const fetchContasCorrentes = async () => {
    try {
      const resp = await api.get(
        `contaBancaria/cliente/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;

      const mapData = data
        .filter((item) => item.tipoDeContaId === 1)
        .map((item) => ({
          id: item.id,
          conta: `${item.numeroConta}-${item.digito}`,
          nome: item.nome,
        }));

      setOptionsContasCorrentes([...mapData]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFontesDeRecursos = async () => {
    try {
      const resp = await api.get(`fonteDeRecurso/por-ano/${currentYear}`);
      const data = resp.data;

      setTodasFontesDeRecursos(
        data.map((item) => ({
          id: item.id,
          conta: item.conta,
          titulo: item.titulo,
          descricao: item.descricao,
          funcao: item.funcao,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeVinculo = (option) => {
    setValuesContaBancaria((prev) => ({
      ...prev,
      tipoDeVinculo: option.value,
    }));
    setValuesContaBancaria((prev) => ({
      ...prev,
      fontesDeRecursos: [],
    }));
  };

  const handleChangeTextInput = (chave, valor) => {
    setValuesContaBancaria((prev) => ({
      ...prev,
      [chave]: valor,
    }));
  };

  const adicionarFonteDeRecursos = () => {
    console.log(Object.values(fonteParaAdicionar));

    if (
      Object.values(fonteParaAdicionar).every(
        (item) => item !== "" && item !== null
      ) &&
      !valuesContaBancaria.fontesDeRecursos.some(
        (item) => item.fonteId === fonteParaAdicionar.fonteId
      )
    ) {
      setValuesContaBancaria((prev) => ({
        ...prev,
        fontesDeRecursos: [...prev.fontesDeRecursos, fonteParaAdicionar],
      }));

      setInputFonteValue("");
      setFonteParaAdicionar((prev) => ({
        ...prev,
        fonteId: null,
        valor: "0,00",
      }));
    } else if (
      valuesContaBancaria.fontesDeRecursos.some(
        (item) => item.fonteId === fonteParaAdicionar.fonteId
      )
    ) {
      toast.error("Essa fonte de recursos já foi adicionada na lista.");
    } else {
      toast.error(
        "Preencha todos os campos corretamente para adicionar uma fonte de recursos."
      );
    }
  };

  const deletarFonte = (indexFonte) => {
    setValuesContaBancaria((prev) => ({
      ...prev,
      fontesDeRecursos: prev.fontesDeRecursos.filter(
        (item, index) => index !== indexFonte
      ),
    }));
  };

  const handleSelectFonte = (option) => {
    setFonteParaAdicionar((prev) => ({
      ...prev,
      fonteId: option.id,
    }));
  };

  const fetchAgencias = async () => {
    try {
      const resp = await api.get(
        `agenciaBancaria/cliente/${currentClient.clienteId}`
      );
      const data = resp.data;

      setTodasAgencias(
        data.map((item) => ({
          id: item.id,
          agencia: `${item.agencia}-${item.digito}`,
          codigo: item.codigo,
          nome: `${item.nome} | ${item.cidade}-${item.estado} | ${item.logradouro}`,
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    fetchFontesDeRecursos();
    fetchAgencias();
    fetchContasCorrentes();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (todasFontesDeRceursos.length > 0) {
      const arrayFiltrado = todasFontesDeRceursos.filter((item) => {
        const tipoCleaned = item.funcao.replace(/[^0-9]/g, "");

        return (
          tipoCleaned ===
          valuesContaBancaria.tipoDeVinculo.replace(/[^0-9]/g, "")
        );
      });

      console.log(arrayFiltrado);

      setFontesDeRecursosFiltradas(arrayFiltrado);
    }
  }, [valuesContaBancaria.tipoDeVinculo, todasFontesDeRceursos]);

  useEffect(() => {
    if (
      valuesContaBancaria.tipoDeContaId === 1 ||
      valuesContaBancaria.tipoDeContaId === 2
    ) {
      setInputContaCorrente("");
      setValuesContaBancaria((prev) => ({
        ...prev,
        contaCorrenteId: null,
      }));
    }

    //eslint-disable-next-line
  }, [valuesContaBancaria.tipoDeContaId]);

  useEffect(() => {
    if (inputContaCorrente.length === 0) {
      setValuesContaBancaria((prev) => ({
        ...prev,
        contaCorrenteId: null,
      }));
    }

    //eslint-disable-next-line
  }, [inputContaCorrente]);

  const handleChangeTipoDeConta = (option) => {
    setValuesContaBancaria((prev) => ({
      ...prev,
      tipoDeContaId: parseInt(option.value),
    }));
  };

  const handleInputAgenciaChange = (e) => {
    const valor = e.target.value;
    console.log(valor);
    const filteredArray = todasAgencias.filter(
      (item) =>
        item.nome.toLowerCase().includes(valor.toLowerCase()) ||
        item.agencia.includes(valor)
    );
    setAgenciasFiltradas(filteredArray);
  };

  const handleSelectAgencia = (item) => {
    setValuesContaBancaria((prev) => ({
      ...prev,
      agenciaId: item.id,
    }));
  };

  const handleValorFonteEdit = (indexDaFonte, valor) => {
    const newArray = [...valuesContaBancaria.fontesDeRecursos];
    newArray[indexDaFonte].valor = valor;

    setValuesContaBancaria((prev) => ({
      ...prev,
      fontesDeRecursos: newArray,
    }));
  };

  const disabledValue = () => {
    if (fonteParaAdicionar.data.length === 10) {
      const dataArray = fonteParaAdicionar.data.split("-");
      const dia = dataArray[2];
      const mes = dataArray[1];

      return !(dia === "01" && mes === "01");
    }

    return true;
  };

  const disabledInputFonte = (indexFonte) => {
    const newArray = [...valuesContaBancaria.fontesDeRecursos];
    const fonte = newArray[indexFonte];
    const dataArray = fonte.data.split("-");
    const dia = dataArray[2];
    const mes = dataArray[1];

    return !(dia === "01" && mes === "01" && fonteToEditIndex === indexFonte);
  };

  const handleChangeDataFonte = (valor, indexFonte) => {
    const newArray = [...valuesContaBancaria.fontesDeRecursos];
    newArray[indexFonte].data = valor;

    setValuesContaBancaria((prev) => ({
      ...prev,
      fontesDeRecursos: [...newArray],
    }));
  };

  const handleEnableEditFonte = (indexFonte) => {
    const fonteSemEdicao = valuesContaBancaria.fontesDeRecursos[indexFonte];

    setValuesFonteAntesDaEdicao((prev) => ({
      ...prev,
      valor: fonteSemEdicao.valor,
      data: fonteSemEdicao.data,
    }));

    setFonteToEditIndex(indexFonte);
  };

  const handleSaveEditFonte = () => {
    setFonteToEditIndex(-1);
  };

  const handleCancelEditFonte = (indexFonte) => {
    const newArray = [...valuesContaBancaria.fontesDeRecursos];
    newArray[indexFonte].valor = valuesFonteAntesDaEdicao.valor;
    newArray[indexFonte].data = valuesFonteAntesDaEdicao.data;

    setFonteToEditIndex(-1);

    setValuesContaBancaria((prev) => ({
      ...prev,
      fontesDeRecursos: [...newArray],
    }));
  };

  // const debug = () => {
  //   console.log(valuesContaBancaria);
  //   console.log(inputAgencia);
  // };

  return (
    <>
      {/* <button onClick={debug}>console</button> */}
      <RowToModal className={"row_form mt-1"}>
        <CCol>
          {paramsId ? (
            <FloatingLabelInput
              disabled
              value={
                todasAgencias.find(
                  (agencia) => agencia.id === valuesContaBancaria.agenciaId
                )
                  ? `${
                      todasAgencias.find(
                        (agencia) =>
                          agencia.id === valuesContaBancaria.agenciaId
                      )?.agencia
                    } - ${
                      todasAgencias.find(
                        (agencia) =>
                          agencia.id === valuesContaBancaria.agenciaId
                      )?.nome
                    }`
                  : ""
              }
            />
          ) : (
            <MyAutoComplete
              labelInput="Agência"
              placeholder="Agência"
              inputValue={inputAgencia}
              setInputValue={setInputAgencia}
              onChange={handleInputAgenciaChange}
              onOptionSelect={handleSelectAgencia}
              options={agenciasFiltradas}
              labelFormat={(option) => `${option.agencia} - ${option.nome}`}
              style={{ height: "38px" }}
            />
          )}
        </CCol>
        <CCol md={2}>
          <InputData
            label="Data de Abertura"
            externalValue={valuesContaBancaria.data}
            onChange={(e) => handleChangeTextInput("data", e.target.value)}
          />
        </CCol>
      </RowToModal>
      <RowForm style={{ marginTop: "-15px" }}>
        <CCol md={2}>
          <ReactInputMask
            mask={"999999999999"}
            maskPlaceholder={null}
            onChange={(e) =>
              handleChangeTextInput("numeroConta", e.target.value)
            }
            value={valuesContaBancaria.numeroConta}
          >
            <FloatingLabelInput label="Nº Conta" placeholder="Nº Conta" />
          </ReactInputMask>
        </CCol>
        <CCol md={1}>
          <ReactInputMask
            mask={"9"}
            maskPlaceholder={null}
            onChange={(e) => handleChangeTextInput("digito", e.target.value)}
            value={valuesContaBancaria.digito}
          >
            <FloatingLabelInput label="Dígito" placeholder="Dígito" />
          </ReactInputMask>
        </CCol>
        <CCol>
          <FloatingLabelInput
            label="Nome"
            placeholder="Nome"
            onChange={(e) => handleChangeTextInput("nome", e.target.value)}
            value={valuesContaBancaria.nome}
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-15px" }}>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={tiposConta}
            value={valuesContaBancaria.tipoDeContaId.toString()}
            onSelect={handleChangeTipoDeConta}
            label={"Tipo de Conta"}
          />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={tiposDeVinculo}
            onSelect={handleChangeVinculo}
            value={valuesContaBancaria.tipoDeVinculo}
            label={"Tipo de Vinculo"}
          />
        </CCol>
      </RowForm>

      <RowToModal
        style={{ backgroundColor: "#fff", borderRadius: "0px 0px 5px 5px" }}
        className={"mt-1"}
      >
        <CCol style={{ display: "flex", alignItems: "center", padding: "0px" }}>
          <OptionsStyle
            selecionado={abaSecundariaSelecionada === 0}
            onClick={() => setAbaSecundariaSelecionada(0)}
          >
            Fonte de Recursos / CO
          </OptionsStyle>
          <OptionsStyle
            selecionado={abaSecundariaSelecionada === 1}
            onClick={() => setAbaSecundariaSelecionada(1)}
          >
            Outras Informações
          </OptionsStyle>
        </CCol>
      </RowToModal>

      {abaSecundariaSelecionada === 0 && (
        <>
          <RowToModal
            className={"row_form"}
            style={{
              marginTop: "12px",
              borderRadius: "5px 5px 0px 0px",
              padding: "8px 10px",
            }}
          >
            <CCol
              style={{
                fontSize: "16px",
                color: "#515c70",
                fontWeight: "700",
              }}
            >
              Fonte de Recursos / CO
            </CCol>
          </RowToModal>

          <RowForm $borderRadiusType={2} className={"mt-1"}>
            <CCol>
              <PTitulosInputs>
                Orientações para utilização Fontes ou Destinações de Recursos -
                Exclusivas da Conta Bancária
              </PTitulosInputs>
            </CCol>
            <CCol md={2}>
              <PTitulosInputs>Data</PTitulosInputs>
            </CCol>
            <CCol md={2}>
              <PTitulosInputs>Valor</PTitulosInputs>
            </CCol>
            <CColButtonsAcoes2Icons md={1}>
              <PTitulosInputs>Ação</PTitulosInputs>
            </CColButtonsAcoes2Icons>

            <RowAninhada>
              <CCol>
                <MyAutoComplete
                  radiusborder={
                    valuesContaBancaria.fontesDeRecursos.length > 0
                      ? "5px 5px 0px 0px "
                      : "5px"
                  }
                  inputValue={inputFonteValue}
                  setInputValue={setInputFonteValue}
                  options={fontesDeRecursosFiltradas}
                  labelFormat={(option) => `${option.conta} - ${option.titulo}`}
                  onOptionSelect={handleSelectFonte}
                />
              </CCol>
              <CCol md={2}>
                <InputData
                  style={{ height: "30px" }}
                  radiusborder={
                    valuesContaBancaria.fontesDeRecursos.length > 0
                      ? "5px 5px 0px 0px "
                      : "5px"
                  }
                  onChange={(e) =>
                    setFonteParaAdicionar((prev) => ({
                      ...prev,
                      data: e.target.value,
                    }))
                  }
                  externalValue={fonteParaAdicionar.data}
                />
              </CCol>
              <CCol md={2}>
                <InputFormatRealBrasileiro
                  radiusborder={
                    valuesContaBancaria.fontesDeRecursos.length > 0
                      ? "5px 5px 0px 0px "
                      : "5px"
                  }
                  disabled={disabledValue()}
                  listaDeInputs={true}
                  externalValue={fonteParaAdicionar.valor}
                  onChange={(e, valor) =>
                    setFonteParaAdicionar((prev) => ({
                      ...prev,
                      valor: valor,
                    }))
                  }
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                <IconAdicionar
                  onClick={adicionarFonteDeRecursos}
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes2Icons>
              {/* <button
                onClick={() =>
                  console.log(fontesDeRecursos, todasFontesDeRceursos)
                }
              >
                console
              </button> */}
            </RowAninhada>

            {valuesContaBancaria.fontesDeRecursos.map((fonte, index, array) => (
              <RowAninhada key={index} style={{ marginTop: "-1px" }}>
                <CCol>
                  <FormControlListaDeInputs
                    radiusborder={
                      array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                    }
                    value={`${
                      todasFontesDeRceursos.find(
                        (item) => item.id === fonte.fonteId
                      )?.conta
                    } - ${
                      todasFontesDeRceursos.find(
                        (item) => item.id === fonte.fonteId
                      )?.titulo
                    }`}
                    disabled
                  />
                </CCol>
                <CCol md={2}>
                  <InputData
                    style={{ height: "30px" }}
                    disabled={fonteToEditIndex !== index}
                    radiusborder={
                      array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                    }
                    onChange={(e) =>
                      handleChangeDataFonte(e.target.value, index)
                    }
                    externalValue={fonte.data}
                  />
                </CCol>
                <CCol md={2}>
                  <InputFormatRealBrasileiro
                    radiusborder={
                      array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                    }
                    disabled={disabledInputFonte(index)}
                    onChange={(_, valor) => handleValorFonteEdit(index, valor)}
                    listaDeInputs
                    externalValue={fonte.valor}
                  />
                </CCol>
                <CColButtonsAcoes2Icons md={1}>
                  {fonteToEditIndex !== index ? (
                    <>
                      <Icon
                        onClick={() => handleEnableEditFonte(index)}
                        icon="bx:edit"
                        color="#5971C8"
                        height="26"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "3px",
                        }}
                      />
                      <Icon
                        onClick={() => deletarFonte(index)}
                        icon="ic:baseline-delete"
                        color="#E79900"
                        height="26"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "1px",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Icon
                        onClick={() => handleSaveEditFonte()}
                        icon="fluent:save-24-filled"
                        color="#008CFF"
                        height="26"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "3px",
                        }}
                      />
                      <Icon
                        icon="ic:baseline-cancel"
                        onClick={() => handleCancelEditFonte(index)}
                        height="26"
                        color="#F24E1E"
                        style={{
                          display:
                            fonteToEditIndex !== index
                              ? "none"
                              : "inline-block",
                          pointerEvents:
                            fonteToEditIndex !== index ? "none" : "auto",
                          cursor:
                            fonteToEditIndex !== index
                              ? "not-allowed"
                              : "pointer",
                          marginLeft: "2px",
                          marginRight: "-5px",
                          marginTop: "3px",
                        }}
                      />
                    </>
                  )}
                </CColButtonsAcoes2Icons>
              </RowAninhada>
            ))}
          </RowForm>
        </>
      )}

      {abaSecundariaSelecionada === 1 && (
        <>
          <OutrasInfos
            setValuesContaBancaria={setValuesContaBancaria}
            valuesContaBancaria={valuesContaBancaria}
            optionsContasCorrentes={optionsContasCorrentes}
            inputContaCorrente={inputContaCorrente}
            setInputContaCorrente={setInputContaCorrente}
          />
        </>
      )}
    </>
  );
};

export default ContaBancariaComponent;
