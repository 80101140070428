import React, { useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  ButtonOptionsFirst,
  ButtonOptionsLast,
  ButtonOptionsPages,
  ColButtons,
} from "../../../../styles/StyledComponents/ButtonsSelectPage";
import ParametroCadastro from "./ParametroCadastro";
import IndicesCadastro from "./IndicesCadastro";
import { useParams, useSearchParams} from "react-router-dom";

const ParametrosIndices = () => {
  const { pag } = useParams();
  const [paginaAtiva, setPaginaAtiva] = useState(
    pag !== undefined ? parseInt(pag) : 0
  );
  const [searchParams] = useSearchParams()
 
  const queryParams = searchParams.get("query")
  const indicesCadastroRef = useRef();
  const parametrosCadastroRef = useRef()

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        PaginaSubtitulo={" Edição"/*pag !== undefined ? " Edição" : false*/}
        NomePaginaTitulo={"Parâmetro / Índices"/*pag !== undefined ? "Parâmetro / Índices" : false*/}
        //PaginaConsulta={pag !== undefined ? "/planejamento/cadastro/parametro-indices/consulta" : false} //antigo
        PaginaConsulta={"/planejamento/cadastro/parametro-indices/consulta"}
        // PaginaRelatorio={"/planejamento/cadastro/parametro-indices/relatorio"}
        BotaoSaveAtivo={!queryParams}
        ButtonSaveFunction={paginaAtiva ? () => indicesCadastroRef.current?.saveAllIndices() : () => parametrosCadastroRef.current?.saveAllParametros()}
        BotaoNovoAtivo={false}
        withRow={false}
      />
      <Row>
        <ColButtons>
          <ButtonOptionsPages>
            <ButtonOptionsFirst
              ativo={paginaAtiva === 0}
              onClick={() => setPaginaAtiva(0)}
            >
              Parâmetro
            </ButtonOptionsFirst>
            <ButtonOptionsLast
              ativo={paginaAtiva === 1}
              onClick={() => setPaginaAtiva(1)}
            >
              Índices
            </ButtonOptionsLast>
          </ButtonOptionsPages>
        </ColButtons>
      </Row>

      {paginaAtiva === 0 && <ParametroCadastro ref={parametrosCadastroRef}/>}
      {paginaAtiva === 1 && <IndicesCadastro ref={indicesCadastroRef}/>}
    </Container>
  );
};

export default ParametrosIndices;
