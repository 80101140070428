import { Page, View, Document } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import React from "react";

const ITEMS_PER_PAGE = 30;

const renderPageContent = (dados, colNames, styledCol, type, opcao) => {
  let textBarConst = false;
  const typePrint = () => {
    textBarConst = true;
    return type;
  };
  console.log(opcao);
  return (
    <>
      <View style={[stylesPdf.colunas, { marginTop: "8px" }]} fixed>
        {opcao?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames[index]}
                styledCol={styledCol[index]}
                textBar={textBarConst === false && typePrint()}
              />
            )
        )}
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View>
          {dados.map((item, index) => (
            <React.Fragment key={item.id || index}>
              <View style={stylesPdf.colunas}>
                {opcao?.checkBoxes[0].selected && (
                  <PdfInfos
                    pdfInfo={item.valor ? item.valor : ""}
                    styledCol={styledCol[0]}
                  />
                )}
                {opcao?.checkBoxes[1].selected && (
                  <PdfInfos
                    pdfInfo={item.nome ? item.nome : ""}
                    styledCol={styledCol[1]}
                  />
                )}
                {opcao?.checkBoxes[2].selected && (
                  <PdfInfos
                    pdfInfo={item.descricao ? item.descricao : ""}
                    styledCol={styledCol[2]}
                  />
                )}
              </View>
              <View
                style={{
                  height: "1px",
                  width: "100%",
                  backgroundColor: "#a6a6a6",
                }}
              ></View>
            </React.Fragment>
          ))}
          <View style={stylesPdf.divInferiorColuna}></View>
        </View>
      </View>
    </>
  );
};

const paginateData = (data, itemsPerPage) => {
  const paginatedData = [];
  for (let i = 0; i < data.length; i += itemsPerPage) {
    paginatedData.push(data.slice(i, i + itemsPerPage));
  }
  return paginatedData;
};

export const PdfFuncaoSubfuncao = ({ pdfContent }) => {
  const {
    dadosRelatorio,
    opcoes,
    relatorioName,
    userData: { currentClient, userAuthData, dataClientInfos },
    currentYear,
  } = pdfContent;

  const colNamesFuncao = ["Código", "Tipo de Vínculo", "Informação"];
  const colNamesSubfuncao = ["Código", "Função", "Informação"];
  const styledCol = [
    { flexBasis: "125px", flexGrow: 1 },
    { flexBasis: "250px", flexGrow: 1 },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
  ];

  if (!dadosRelatorio) return null;

  const funcaoPages = paginateData(
    dadosRelatorio?.funcao || [],
    ITEMS_PER_PAGE
  );
  const subfuncaoPages = paginateData(
    dadosRelatorio?.subfuncao || [],
    ITEMS_PER_PAGE
  );

  return (
    <Document title={relatorioName}>
      {funcaoPages.map((funcaoPage, pageIndex) => (
        <Page
          key={`funcao-page-${pageIndex}`}
          orientation="portrait"
          size={"A4"}
          style={stylesPdf.page}
        >
          <PdfHeader
            currentClient={currentClient}
            nameRelatorio={"Função/Subfunção"}
            anoRelatorio={currentYear}
            dataClientInfos={dataClientInfos}
          />
          <View style={stylesPdf.divRelatorio}>
            {renderPageContent(
              funcaoPage,
              colNamesFuncao,
              styledCol,
              "Função",
              opcoes[0]
            )}
          </View>
          {pageIndex === funcaoPages.length - 1 &&
            subfuncaoPages.length === 0 && (
              <PdfRodape
                currentClient={currentClient}
                userAuthData={userAuthData}
              />
            )}
        </Page>
      ))}

      {subfuncaoPages.map((subfuncaoPage, pageIndex) => (
        <Page
          key={`subfuncao-page-${pageIndex}`}
          orientation="portrait"
          size={"A4"}
          style={stylesPdf.page}
          break={true}
        >
          <PdfHeader
            currentClient={currentClient}
            nameRelatorio={"Função/Subfunção"}
            anoRelatorio={currentYear}
          />

          {renderPageContent(
            subfuncaoPage,
            colNamesSubfuncao,
            styledCol,
            "Subfunção",
            opcoes[1]
          )}
          {pageIndex === subfuncaoPages.length - 1 && (
            <PdfRodape
              currentClient={currentClient}
              userAuthData={userAuthData}
            />
          )}
        </Page>
      ))}
    </Document>
  );
};
