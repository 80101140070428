import React from "react";
import { Page, View, Document, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";

import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

import {
  calcularTotalReceitas,
  calcularTotalReceitasComDeducao,
} from "./calcularReceitas";

export const PdfQDR = ({ pdfContent }) => {
  const {
    dadosRelatorio,
    currentYear,
    userData: { currentClient, userAuthData, dataClientInfos },
    idsSelected: { idsFontesSelected, idsNatureza, esferaSelected },
    selectBoxesOptionsAbaOptions,
  } = pdfContent;

  const codigosSectionRelatorio = ["1.0.0.0.00.0.0", "2.0.0.0.00.0.0"];
  const fonteComDeducao = 3;
  const fonteSemDeducao = 1;

  const showOptionsSelected = (option) => {
    const optionSelected = selectBoxesOptionsAbaOptions[0].checkBoxes.some(
      (item) => item.value === option && item.selected
    );
    return optionSelected;
  };

  const showCodigo = showOptionsSelected("codigo");
  const showTitulo = showOptionsSelected("especificacao");
  const showValue = showOptionsSelected("valor");

  const styledCol = [
    { flexBasis: "105px", flexGrow: 1 },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "90px", flexGrow: 1 },
    { flexBasis: "115px", flexGrow: 1 },
  ];

  const renderInfoSectionRelatorio = (item, deducao) => {
    const naturezaSection = codigosSectionRelatorio.includes(item.nr);
    const naturezaSelected =
      idsNatureza.includes(item.id) && !idsFontesSelected.length;

    const filtroNaturezaEsfera =
      idsNatureza.length &&
      !idsFontesSelected.length &&
      esferaSelected.length &&
      idsNatureza.includes(item.id);

    return (
      <React.Fragment key={item.id}>
        <View style={[stylesPdf.colunas]} wrap={false}>
          {showCodigo === true && (
            <View
              style={[
                styledCol[0],
                {
                  fontWeight: naturezaSection
                    ? "bold"
                    : naturezaSelected
                    ? "bold"
                    : "normal",
                  backgroundColor: naturezaSection ? "#a6a6a6" : "transparent",
                },
              ]}
            >
              <Text
                style={[
                  stylesPdf.textInfosRelatorio,
                  { paddingHorizontal: 4, paddingVertical: 2, color: "#000" },
                ]}
              >
                {item.nr}
              </Text>
            </View>
          )}
          {showTitulo === true ? (
            <View
              style={[
                styledCol[1],
                {
                  fontWeight: naturezaSection
                    ? "bold"
                    : naturezaSelected
                    ? "bold"
                    : "normal",
                  backgroundColor: naturezaSection ? "#a6a6a6" : "transparent",
                },
              ]}
            >
              <Text
                style={[
                  stylesPdf.textInfosRelatorio,
                  { paddingHorizontal: 4, paddingVertical: 2, color: "#000" },
                ]}
              >
                {item.especificacao}
              </Text>
            </View>
          ) : (
            <View
              style={[
                styledCol[1],
                {
                  fontWeight: naturezaSection
                    ? "bold"
                    : naturezaSelected
                    ? "bold"
                    : "normal",
                  backgroundColor: naturezaSection ? "#a6a6a6" : "transparent",
                },
              ]}
            >
              <Text
                style={[
                  stylesPdf.textInfosRelatorio,
                  {
                    paddingHorizontal: 4,
                    paddingVertical: 2,
                    color: naturezaSection ? "#a6a6a6" : "#FFF",
                  },
                ]}
              >
                ""
              </Text>
            </View>
          )}
          {esferaSelected.length && (
            <View
              style={[
                styledCol[2],
                {
                  backgroundColor: naturezaSection ? "#a6a6a6" : "transparent",
                },
              ]}
            >
              <Text
                style={[
                  stylesPdf.textInfosRelatorio,
                  { paddingLeft: 4, paddingVertical: 2, color: "#000" },
                ]}
              >
                {filtroNaturezaEsfera ? esferaSelected[0] : ""}
              </Text>
              <Text
                style={[
                  stylesPdf.textInfosRelatorio,
                  { paddingLeft: 4, paddingVertical: 2, color: "#000" },
                ]}
              >
                {filtroNaturezaEsfera ? esferaSelected[1] : ""}
              </Text>
            </View>
          )}
          {showValue === true && (
            <View
              style={[
                styledCol[3],
                {
                  fontWeight: naturezaSection
                    ? "bold"
                    : naturezaSelected
                    ? "bold"
                    : "normal",
                  backgroundColor: naturezaSection ? "#a6a6a6" : "transparent",
                },
              ]}
            >
              <Text
                style={[
                  stylesPdf.textInfosRelatorio,
                  {
                    paddingHorizontal: 4,
                    textAlign: "right",
                    paddingVertical: 2,
                    color: "#000",
                  },
                ]}
              >
                {" "}
                {`${FormatValueToLocaleString(parseFloat(item.valor))}`}
              </Text>
            </View>
          )}
        </View>

        {item.qdr !== "vazio" &&
          idsFontesSelected.length > 0 &&
          item.qdr.fontesDeRecursos
            .filter((fonte) => fonte.tipo === deducao)
            .map((fonte) => renderInfosFonteDeRecurso(fonte))}
      </React.Fragment>
    );
  };
  // const renderInfoSectionRelatorio = (item, deducao) => {
  //   const naturezaSection = codigosSectionRelatorio.includes(item.nr);
  //   const naturezaSelected =
  //     idsNatureza.includes(item.id) && !idsFontesSelected.length;

  //   const filtroNaturezaEsfera =
  //     idsNatureza.length &&
  //     !idsFontesSelected.length &&
  //     esferaSelected.length &&
  //     idsNatureza.includes(item.id);

  //   return (
  //     <React.Fragment key={item.id}>
  //       <View
  //         style={[
  //           stylesPdf.barTitleInfos,
  //           {
  //             fontWeight: naturezaSection
  //               ? "bold"
  //               : naturezaSelected
  //               ? "bold"
  //               : "normal",
  //             backgroundColor: naturezaSection ? "#a6a6a6" : "transparent",
  //           },
  //         ]}
  //       >
  //         <View style={[stylesPdf.containerTitleInfos]}>
  //           {showCodigo && (
  //             <Text style={{ backgroundColor: "blue", width: "12%" }}>
  //               {item.nr}
  //             </Text>
  //           )}
  //           {showTitulo && (
  //             <Text style={{ width: "70%", backgroundColor: "red" }}>
  //               {item.especificacao}
  //             </Text>
  //           )}
  //           {filtroNaturezaEsfera && <Text>{esferaSelected.join(" - ")}</Text>}
  //         </View>
  //         {showValue && (
  //           <Text
  //             style={[
  //               stylesPdf.textTotalInfos,
  //               {
  //                 backgroundColor: "green",
  //               },
  //             ]}
  //           >
  //             {`${FormatValueToLocaleString(parseFloat(item.valor))}`}
  //           </Text>
  //         )}
  //       </View>
  //       {item.qdr !== "vazio" &&
  //         idsFontesSelected.length > 0 &&
  //         item.qdr.fontesDeRecursos
  //           .filter((fonte) => fonte.tipo === deducao)
  //           .map((fonte) => renderInfosFonteDeRecurso(fonte))}
  //     </React.Fragment>
  //   );
  // };

  const renderInfosFonteDeRecurso = (item) => {
    return (
      <React.Fragment key={item.id}>
        <View style={[stylesPdf.colunas, { fontWeight: "bold" }]} wrap={false}>
          {showCodigo && (
            <View
              style={[
                styledCol[0],
                {
                  paddingVertical: 2,
                  flexDirection: "row",
                  justifyContent: !showTitulo ? "flex-start" : "space-between",
                  gap: !showTitulo ? 16 : 0,
                },
              ]}
            >
              <Text
                style={[
                  stylesPdf.textInfosRelatorio,
                  { paddingHorizontal: 0, paddingLeft: 10, color: "#000" },
                ]}
              >
                {item.ficha}
              </Text>
              <Text
                style={[
                  stylesPdf.textInfosRelatorio,
                  { paddingHorizontal: 0, paddingRight: 24, color: "#000" },
                ]}
              >
                {item.conta}
              </Text>
            </View>
          )}
          {showTitulo ? (
            <View
              style={[
                styledCol[1],
                {
                  paddingVertical: 2,
                  color: "#000",
                },
              ]}
            >
              <Text
                style={[
                  stylesPdf.textInfosRelatorio,
                  { paddingHorizontal: 4, color: "#000" },
                ]}
              >
                {item.titulo}
              </Text>
            </View>
          ) : (
            <View
              style={[
                styledCol[1],
                {
                  paddingVertical: 2,
                },
              ]}
            >
              <Text
                style={[
                  stylesPdf.textInfosRelatorio,
                  { paddingHorizontal: 4, color: "#FFF" },
                ]}
              >
                ""
              </Text>
            </View>
          )}
          {esferaSelected.length > 0 && (
            <View
              style={[
                styledCol[2],
                {
                  paddingHorizontal: 4,
                  paddingVertical: 2,
                  color: "#000",
                },
              ]}
            >
              <Text style={[stylesPdf.textInfosRelatorio]}>{item.esfera}</Text>
            </View>
          )}
          {showValue && (
            <View
              style={[
                styledCol[3],
                {
                  paddingVertical: 2,
                },
              ]}
            >
              <Text
                style={[
                  stylesPdf.textInfosRelatorio,
                  { textAlign: "right", paddingHorizontal: 4, color: "#000" },
                ]}
              >
                {`${FormatValueToLocaleString(parseFloat(item.valorPrevisto))}`}
              </Text>
            </View>
          )}
        </View>
      </React.Fragment>
    );
  };
  // const renderInfosFonteDeRecurso = (item) => {
  //   console.log(item);
  //   return (
  //     <React.Fragment key={item.id}>
  //       <View style={[stylesPdf.barTitleInfos]}>
  //         <View style={[stylesPdf.containerTitleInfos]}>
  //           {showCodigo && (
  //             <View
  //               style={{
  //                 flexDirection: "row",
  //                 backgroundColor: "red",
  //                 width: "12%",
  //                 justifyContent: "space-between",
  //                 paddingLeft: 8,
  //               }}
  //             >
  //               <Text>{item.ficha}</Text>
  //               <Text>{item.conta}</Text>
  //             </View>
  //           )}
  //           {showTitulo && (
  //             <Text
  //               style={{
  //                 maxWidth: "70%",
  //                 width: "100%",
  //                 backgroundColor: "red",
  //               }}
  //             >
  //               {item.titulo}
  //             </Text>
  //           )}
  //           {esferaSelected.length > 0 && (
  //             <Text style={{ backgroundColor: "blue" }}>{item.esfera}</Text>
  //           )}
  //         </View>
  //         {showValue && (
  //           <Text
  //             style={[stylesPdf.textTotalInfos, { backgroundColor: "red" }]}
  //           >
  //             {`${FormatValueToLocaleString(parseFloat(item.valorPrevisto))}`}
  //           </Text>
  //         )}
  //       </View>
  //     </React.Fragment>
  //   );
  // };

  const cabecalhoGeral = () => {
    return (
      <View style={[stylesPdf.colunas]} fixed>
        {showCodigo === true && (
          <View style={[stylesPdf.coluna, styledCol[0]]}>
            <View style={stylesPdf.divTituloColuna}>
              <Text
                style={[stylesPdf.textTituloColuna, { paddingHorizontal: 4 }]}
              >
                NR
              </Text>
            </View>
          </View>
        )}
        {showTitulo === true ? (
          <View style={[stylesPdf.coluna, styledCol[1]]}>
            <View style={stylesPdf.divTituloColuna}>
              <Text
                style={[stylesPdf.textTituloColuna, { paddingHorizontal: 4 }]}
              >
                Especificações
              </Text>
            </View>
          </View>
        ) : (
          <View style={[stylesPdf.coluna, styledCol[1]]}>
            <View style={stylesPdf.divTituloColuna}>
              <Text
                style={[
                  stylesPdf.textTituloColuna,
                  { paddingHorizontal: 4, color: "#515c70" },
                ]}
              >
                ""
              </Text>
            </View>
          </View>
        )}
        {esferaSelected.length && (
          <View style={[stylesPdf.coluna, styledCol[2]]}>
            <View style={stylesPdf.divTituloColuna}>
              <Text
                style={[stylesPdf.textTituloColuna, { paddingHorizontal: 4 }]}
              >
                Esfera
              </Text>
            </View>
          </View>
        )}
        {showValue === true && (
          <View style={[stylesPdf.coluna, styledCol[3]]}>
            <View style={stylesPdf.divTituloColuna}>
              <Text
                style={[
                  stylesPdf.textTituloColuna,
                  { textAlign: "right", paddingRight: 4 },
                ]}
              >
                Valor R$
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  };
  // const cabecalhoGeral = () => {
  //   return (
  //     <View
  //       style={[
  //         stylesPdf.headerInfos,
  //         { justifyContent: "space-between", fontWeight: "bold" },
  //       ]}
  //       fixed
  //     >
  //       <View style={{ flexDirection: "row", flex: "1" }}>
  //         {showCodigo && (
  //           <Text
  //             style={[
  //               stylesPdf.textTituloColuna,
  //               {
  //                 maxWidth: "14%",
  //                 width: "100%",
  //                 backgroundColor: "blue",
  //                 textTransform: "uppercase",
  //               },
  //             ]}
  //           >
  //             nr
  //           </Text>
  //         )}
  //         {showTitulo && (
  //           <Text
  //             style={[
  //               stylesPdf.textTituloColuna,
  //               { width: "68%", backgroundColor: "red" },
  //             ]}
  //           >
  //             especificações
  //           </Text>
  //         )}
  //         {esferaSelected.length > 0 && (
  //           <Text
  //             style={[stylesPdf.textTituloColuna, { backgroundColor: "blue" }]}
  //           >
  //             esfera
  //           </Text>
  //         )}
  //       </View>
  //       {showValue && (
  //         <Text
  //           style={[stylesPdf.textTituloColuna, { backgroundColor: "red" }]}
  //         >
  //           valor R$
  //         </Text>
  //       )}
  //     </View>
  //   );
  // };

  const cabecalhoFonteDeRecurso = () => {
    return (
      <View
        style={[
          stylesPdf.headerInfos,
          { justifyContent: "space-between", fontWeight: "bold" },
        ]}
        fixed
      >
        <View
          style={{
            flexDirection: "row",
            flex: "1",
            gap: "16px",
            alignItems: "center",
          }}
        >
          {showCodigo && (
            <>
              <Text
                style={[
                  stylesPdf.textTituloColuna,
                  { textAlign: "left", width: "50px" },
                ]}
              >
                exercício
              </Text>
              <Text
                style={[
                  stylesPdf.textTituloColuna,
                  { textAlign: "left", width: "50px" },
                ]}
              >
                fonte de{"\n"}recurso
              </Text>
            </>
          )}
          {showTitulo && (
            <>
              <Text
                style={[
                  stylesPdf.textTituloColuna,
                  {
                    maxWidth: "250px",
                    width: "100%",
                    textAlign: "left",
                  },
                ]}
              >
                descrição fonte de recursos
              </Text>
            </>
          )}
          {esferaSelected.length && (
            <Text style={[stylesPdf.textTituloColuna]}>esfera</Text>
          )}
        </View>
        {showValue && (
          <Text style={[stylesPdf.textTituloColuna]}>valor R$</Text>
        )}
      </View>
    );
  };

  const cabecalhoEsfera = () => {
    return (
      <View
        style={[
          stylesPdf.headerInfos,
          { justifyContent: "space-between", fontWeight: "bold" },
        ]}
        fixed
      >
        <Text style={[stylesPdf.textTituloColuna]}>esfera</Text>

        <Text style={[stylesPdf.textTituloColuna]}>valor R$</Text>
      </View>
    );
  };

  const renderInfosGeral = (dadosRelatorio) => {
    return (
      <View>
        {dadosRelatorio?.naturezas?.map((item) =>
          renderInfoSectionRelatorio(item, fonteSemDeducao)
        )}

        {dadosRelatorio?.naturezasDeducao?.length > 0 && (
          <View style={[stylesPdf.barTitleInfos, { gap: 0 }]} wrap={false}>
            <View
              style={[
                stylesPdf.containerTitleInfos,
                { backgroundColor: "#a6a6a6" },
              ]}
            >
              <Text>Contribuição para Formação do FUNDEB - DEDUÇÃO</Text>
            </View>
            <Text
              style={[
                stylesPdf.textTotalInfos,
                {
                  backgroundColor: "#a6a6a6",
                  color: showValue ? "#000" : "#a6a6a6",
                },
              ]}
            >
              {showValue
                ? `${FormatValueToLocaleString(
                    parseFloat(
                      calcularTotalReceitasComDeducao(
                        dadosRelatorio,
                        codigosSectionRelatorio
                      )
                    )
                  )}`
                : "000"}
            </Text>
          </View>
        )}
        {dadosRelatorio?.naturezasDeducao?.map((item) =>
          renderInfoSectionRelatorio(item, fonteComDeducao)
        )}
      </View>
    );
  };

  const renderInfosFiltroFonteRecursos = (fonte) => {
    const filtroPorFiscal = esferaSelected.includes("Fiscal");
    const filtroPorSeguridade = esferaSelected.includes("Seguridade");

    return (
      <View
        key={fonte.id}
        style={[
          stylesPdf.barTitleInfos,
          {
            paddingHorizontal: 4,
            fontWeight: "normal",
            marginBottom: 5,
          },
        ]}
        wrap={false}
      >
        <View style={{ flexDirection: "row", alignItems: "center", gap: 16 }}>
          {showCodigo && (
            <>
              <Text style={{ width: 50 }}>{fonte.conta[0]}</Text>
              <Text style={{ width: 50 }}>{fonte.conta.split(".")[1]}</Text>
            </>
          )}
          {showTitulo && (
            <>
              <View style={[{ maxWidth: 250, width: "100%" }]}>
                <Text>{fonte.titulo}</Text>
              </View>
            </>
          )}
          <View
            style={{
              textTransform: "capitalize",
              gap: 2,
            }}
          >
            {filtroPorFiscal && <Text>fiscal</Text>}
            {filtroPorSeguridade && <Text>seguridade</Text>}
          </View>
        </View>

        {showValue && (
          <>
            <View style={[stylesPdf.textTotalInfos, { paddingRight: 0 }]}>
              {filtroPorFiscal && (
                <Text>{FormatValueToLocaleString(fonte.totalFiscal)}</Text>
              )}
              {filtroPorSeguridade && (
                <Text>{FormatValueToLocaleString(fonte.totalSeguridade)}</Text>
              )}
              {!filtroPorFiscal && !filtroPorSeguridade && (
                <Text>
                  {FormatValueToLocaleString(
                    fonte.totalFiscal + fonte.totalSeguridade
                  )}
                </Text>
              )}
            </View>
          </>
        )}
      </View>
    );
  };

  const renderInfosEsfera = (fonteTotais) => {
    const filtroPorFiscal = esferaSelected.includes("Fiscal");
    const filtroPorSeguridade = esferaSelected.includes("Seguridade");
    return (
      <View
        style={[
          stylesPdf.barTitleInfos,
          { paddingHorizontal: "4px", fontWeight: "normal" },
        ]}
      >
        <View
          style={[
            {
              textTransform: "capitalize",
              gap: "2px",
            },
          ]}
        >
          {filtroPorFiscal && <Text>fiscal</Text>}
          {filtroPorSeguridade && <Text>seguridade</Text>}
        </View>

        <View style={[stylesPdf.textTotalInfos]}>
          {filtroPorFiscal && (
            <Text>
              {FormatValueToLocaleString(Number(fonteTotais.totalGeralFiscal))}
            </Text>
          )}
          {filtroPorSeguridade && (
            <Text>
              {FormatValueToLocaleString(
                Number(fonteTotais.totalGeralSeguridade)
              )}
            </Text>
          )}
        </View>
      </View>
    );
  };

  const textEsfera =
    esferaSelected.length > 1
      ? "- Fiscal e Seguridade"
      : esferaSelected.length
      ? `- ${esferaSelected[0]}`
      : "";

  const filtros = {
    "Natureza da Receita": idsNatureza.length,
    "Fonte de Recurso": idsFontesSelected.length,
  };

  const nameFiler = Object.keys(filtros)
    .filter((key) => filtros[key])
    .join(" - ");

  const relatorioPorFonte =
    idsFontesSelected?.length > 0 && !idsNatureza?.length > 0;

  const relatorioPorEsfera =
    !idsFontesSelected.length > 0 &&
    !idsNatureza.length > 0 &&
    esferaSelected.length > 0;

  const relatorioGeral = !relatorioPorFonte && !relatorioPorEsfera;

  return (
    <Document>
      <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
        <PdfHeader
          currentClient={currentClient}
          nameRelatorio={"QDR - Quadro Detalhado da Receita"}
          anoRelatorio={currentYear}
          dataClientInfos={dataClientInfos}
        />
        <Text
          style={[stylesPdf.textFiltro]}
          fixed
        >{`${nameFiler} ${textEsfera}`}</Text>

        {relatorioGeral && cabecalhoGeral()}

        {relatorioPorFonte && cabecalhoFonteDeRecurso()}

        {relatorioPorEsfera && cabecalhoEsfera()}

        <View style={stylesPdf.divRelatorio}>
          {relatorioPorFonte && (
            <>
              <View style={stylesPdf.subDivColunas} />
              {dadosRelatorio.fontes.resultados.map((item) =>
                renderInfosFiltroFonteRecursos(item)
              )}
              {dadosRelatorio.fontesDeducao.resultados.length > 0 && (
                <>
                  <View
                    style={[stylesPdf.barTitleInfos, { gap: 0 }]}
                    wrap={false}
                  >
                    <View
                      style={[
                        stylesPdf.containerTitleInfos,
                        { backgroundColor: "#a6a6a6" },
                      ]}
                    >
                      <Text>
                        Contribuição para Formação do FUNDEB - DEDUÇÃO
                      </Text>
                    </View>
                    <Text
                      style={[
                        stylesPdf.textTotalInfos,
                        {
                          backgroundColor: "#a6a6a6",
                          color: showValue ? "#000" : "#a6a6a6",
                        },
                      ]}
                    >
                      {showValue
                        ? `${FormatValueToLocaleString(
                            parseFloat(
                              dadosRelatorio.fontesDeducao.totais.totalGeral
                            )
                          )}`
                        : "000"}
                    </Text>
                  </View>
                  {dadosRelatorio.fontesDeducao.resultados.map((item) =>
                    renderInfosFiltroFonteRecursos(item)
                  )}
                </>
              )}
            </>
          )}

          {relatorioGeral && renderInfosGeral(dadosRelatorio)}
          {relatorioPorEsfera && (
            <>
              <View style={stylesPdf.subDivColunas} />
              {renderInfosEsfera(dadosRelatorio.fontes.totais)}
              {dadosRelatorio.fontesDeducao.resultados.length > 0 && (
                <>
                  <View
                    style={[stylesPdf.barTitleInfos, { gap: 0 }]}
                    wrap={false}
                  >
                    <View
                      style={[
                        stylesPdf.containerTitleInfos,
                        { backgroundColor: "#a6a6a6" },
                      ]}
                    >
                      <Text>
                        Contribuição para Formação do FUNDEB - DEDUÇÃO
                      </Text>
                    </View>
                    <Text
                      style={[
                        stylesPdf.textTotalInfos,
                        {
                          backgroundColor: "#a6a6a6",
                          color: showValue ? "#000" : "#a6a6a6",
                        },
                      ]}
                    >
                      {showValue
                        ? `${FormatValueToLocaleString(
                            parseFloat(
                              dadosRelatorio.fontesDeducao.totais.totalGeral
                            )
                          )}`
                        : "000"}
                    </Text>
                  </View>
                  {renderInfosEsfera(dadosRelatorio.fontesDeducao.totais)}
                </>
              )}
            </>
          )}
        </View>
        <View style={stylesPdf.divInferiorColuna}></View>
        {dadosRelatorio && relatorioGeral && showValue && (
          <>
            <Text style={[stylesPdf.textTotalInfos, { fontWeight: "bold" }]}>
              {`Total R$ ${FormatValueToLocaleString(
                parseFloat(
                  calcularTotalReceitas(dadosRelatorio, codigosSectionRelatorio)
                )
              )}`}
            </Text>
          </>
        )}
        {dadosRelatorio &&
          (relatorioPorFonte || relatorioPorEsfera) &&
          showValue && (
            <>
              <Text style={[stylesPdf.textTotalInfos, { fontWeight: "bold" }]}>
                {`Total R$ ${FormatValueToLocaleString(
                  Number(dadosRelatorio.fontes.totais.totalGeral) -
                    Number(dadosRelatorio.fontesDeducao.totais.totalGeral)
                )}`}
              </Text>
            </>
          )}
        <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
      </Page>
    </Document>
  );
};
