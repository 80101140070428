import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import Modal from "react-modal";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import InputSvg from "../../../../components/Grid/InputSvg";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import { ToastContainer, toast } from "react-toastify";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import api from "../../../../utils/api";

function ConsultaCpfCnpj() {
  Modal.setAppElement("#root");

  const [modalOpened, setModalOpened] = useState(false);
  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itensFiltrados, setItensFiltrados] = useState([]);
  const [loadingLista, setLoadingLista] = useState(true);
  const [dataDelete, setDataDelete] = useState({});

  axios.defaults.headers.common["Authorization"] = null;

  const fetchData = () => {
    setLoadingLista(true);
    api
      .get("pessoas")
      .then((response) => {
        setItens(response.data.slice().reverse());
        setLoadingLista(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (event) => {
    console.log(event.target.value);
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = itens.filter((item) => {
      if (item.dados.nomeCompleto) {
        return item.dados.nomeCompleto
          .toLowerCase()
          .includes(value.toLowerCase());
      } else if (item.dados.nomeFantasia) {
        return item.dados.nomeFantasia
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return "";
      }
    });
    setItensFiltrados(filteredItems);
    setCurrentPage(0);
  };

  const deleteCpfCnpj = () => {
    const { value, id } = dataDelete;

    api
      .delete(value.length <= 11 ? `cpf/${id}` : `cnpj/${id}`)
      .then(() => {
        toast.success("Cadastro deletado com sucesso");
        fetchData();
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Algo deu errado. Por favor, tente mais tarde.");
        }

        console.log(error);
      });
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  function formatCpf(cpf) {
    const cpfFormatado =
      cpf.slice(0, 3) +
      "." +
      cpf.slice(3, 6) +
      "." +
      cpf.slice(6, 9) +
      "-" +
      cpf.slice(9);

    return cpfFormatado;
  }

  function formatCnpj(cnpj) {
    const cnpjFormatado = `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(
      5,
      8
    )}/${cnpj.slice(8, 12)}-${cnpj.slice(12)}`;
    return cnpjFormatado;
  }

  function openModal() {
    setModalOpened(true);
  }

  function closeModal() {
    setModalOpened(false);
  }

  const confirmDeleteData = (value, id) => {
    setDataDelete({ value: value, id: id });
    openModal();
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        deleteDataFunction={deleteCpfCnpj}
        setDataDelete={setDataDelete}
      />
      <ToastContainer />
      <HeaderOptions
        withRow={true}
        TituloPagina={"CPF/CNPJ"}
        SubTituloPagina={"Consulta"}
        ToPag={"/acesso-interno/cadastro/cadastros-cpf-cnpj"}
      />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loadingLista ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loadingLista ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Código</TituloTable>
              <TituloTable>Nome</TituloTable>
              <TituloTable>CPF/CNPJ</TituloTable>
              <TituloTable>Endereço</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.dados.id}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.dados.tipo === "Física"
                        ? item.dados.nomeCompleto
                        : item.dados.nomeFantasia}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.dados.tipo === "Física"
                        ? formatCpf(item.dados.cpf)
                        : formatCnpj(item.dados.cnpj)}
                    </td>
                    <td
                      title={`${item.endereco.municipio} - ${item.endereco.estado} - ${item.endereco.logradouro}`}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {`${item.endereco.municipio} - ${item.endereco.estado} - ${item.endereco.logradouro}`
                        .length > 35
                        ? `${item.endereco.municipio} - ${item.endereco.estado} - ${item.endereco.logradouro}`.substring(
                            0,
                            35
                          ) + "..."
                        : `${item.endereco.municipio} - ${item.endereco.estado} - ${item.endereco.logradouro}`}
                    </td>
                    <td>
                      <ButtonsAcoesListas
                        FuncaoIconDelete={
                          item.dados.tipo === "Física"
                            ? () =>
                                confirmDeleteData(item.dados.cpf, item.dados.id)
                            : () =>
                                confirmDeleteData(
                                  item.dados.cnpj,
                                  item.dados.id
                                )
                        }
                        CaminhoPagUpdate={
                          item.dados.tipo === "Física"
                            ? `/acesso-interno/cadastro/cadastros-cpf-cnpj/${item.dados.cpf}`
                            : `/acesso-interno/cadastro/cadastros-cpf-cnpj/${item.dados.cnpj}`
                        }
                      />
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.dados.id}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.dados.tipo === "Física"
                        ? item.dados.nomeCompleto
                        : item.dados.nomeFantasia}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.dados.tipo === "Física"
                        ? formatCpf(item.dados.cpf)
                        : formatCnpj(item.dados.cnpj)}
                    </td>
                    <td
                      title={`${item.endereco.municipio} - ${item.endereco.estado} - ${item.endereco.logradouro}`}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {`${item.endereco.municipio} - ${item.endereco.estado} - ${item.endereco.logradouro}`
                        .length > 35
                        ? `${item.endereco.municipio} - ${item.endereco.estado} - ${item.endereco.logradouro}`.substring(
                            0,
                            35
                          ) + "..."
                        : `${item.endereco.municipio} - ${item.endereco.estado} - ${item.endereco.logradouro}`}
                    </td>
                    <td>
                      <ButtonsAcoesListas
                        FuncaoIconDelete={
                          item.dados.tipo === "Física"
                            ? () =>
                                confirmDeleteData(item.dados.cpf, item.dados.id)
                            : () =>
                                confirmDeleteData(
                                  item.dados.cnpj,
                                  item.dados.id
                                )
                        }
                        CaminhoPagUpdate={
                          item.dados.tipo === "Física"
                            ? `/acesso-interno/cadastro/cadastros-cpf-cnpj/${item.dados.cpf}`
                            : `/acesso-interno/cadastro/cadastros-cpf-cnpj/${item.dados.cnpj}`
                        }
                      />
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConsultaCpfCnpj;
