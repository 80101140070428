import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../../components/Grid/InputSvg";
// import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import { Link } from "react-router-dom";
import api from "../../../../utils/api";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";

function ListaFontesDeRecursos() {
  const { currentYear } = useCurrentYear();
  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itensFiltrados, setItensFiltrados] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await api.get(`/fonteDeRecurso/por-ano/${currentYear}`);
      const data = resp.data;
      setItens(data.filter((item) => item.conta.length > 2));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [currentYear]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const reverseMask = (value) => {
    let noDots = value.replace(/\./g, "");
    return noDots;
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = itens.filter((item) => {
      return (
        item.titulo.toLowerCase().includes(value.toLowerCase()) ||
        reverseMask(item.conta.toString()).includes(value) ||
        item.conta.toString().includes(value)
      );
    });
    setItensFiltrados(filteredItems);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        withRow={true}
        TituloPagina={"Fonte de Recursos"}
        SubTituloPagina={"Consulta"}
        ToPag={"/contabilidade/cadastros/fonte-de-recursos"}
      />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loading ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Código</TituloTable>
              <TituloTable>Título</TituloTable>
              <TituloTable>Tipo de Vínculo</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.conta}</td>
                    <td style={{ whiteSpace: "nowrap" }} title={item.titulo}>
                      {item.titulo.length > 80
                        ? item.titulo.substring(0, 80) + "..."
                        : item.titulo}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>{item.funcao}</td>
                    <td>
                      <Link
                        to={`/planejamento/cadastro/fonte-de-recursos/visualizar/${item.conta}`}
                      >
                        <Icon
                          icon="bx:edit"
                          color="#5971C8"
                          height="22"
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.conta}</td>
                    <td style={{ whiteSpace: "nowrap" }} title={item.titulo}>
                      {item.titulo.length > 80
                        ? item.titulo.substring(0, 80) + "..."
                        : item.titulo}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>{item.funcao}</td>
                    <td>
                      <Link
                        to={`/planejamento/cadastro/fonte-de-recursos/visualizar/${item.conta}`}
                      >
                        <Icon
                          icon="bx:edit"
                          color="#5971C8"
                          height="22"
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        />
                      </Link>
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ListaFontesDeRecursos;
