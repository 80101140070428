import { createContext, useState } from "react";

//1 - Criando Contexto

export const OpenedModalsContext = createContext();

//2 - Criando Provider

export const OpenedModalsProvider = ({ children }) => {
  const [openedModals, setOpenedModals] = useState([
    // {tituloModal: "", requisicaoDoModalConcluida: false}
  ]);

  return (
    <OpenedModalsContext.Provider value={{ openedModals, setOpenedModals }}>
      {children}
    </OpenedModalsContext.Provider>
  );
};
