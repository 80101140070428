import React from "react";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";

const OutrasInfos = ({
  valuesContaBancaria,
  setValuesContaBancaria,
  optionsContasCorrentes,
  inputContaCorrente,
  setInputContaCorrente,
}) => {
  //   const debug = () => {
  //     console.log(valuesContaBancaria.dataFechamento);
  //     console.log(valuesContaBancaria.data, "data abertura");
  //   };

  const handleSelectContaCorrente = (item) => {
    setValuesContaBancaria((prev) => ({
      ...prev,
      contaCorrenteId: item.id,
    }));
  };

  return (
    <>
      <RowForm
        style={{
          marginTop: "12px",
          borderRadius: "5px 5px 0px 0px",
          padding: "8px 10px",
        }}
      >
        <CCol
          style={{
            fontSize: "16px",
            color: "#515c70",
            fontWeight: "700",
          }}
        >
          Outras Informações
        </CCol>
      </RowForm>

      <RowForm className="mt-1">
        <CCol>
          <MyAutoComplete
            disabled={
              valuesContaBancaria.tipoDeContaId === 1 ||
              valuesContaBancaria.tipoDeContaId === 2
            }
            inputValue={inputContaCorrente}
            setInputValue={setInputContaCorrente}
            onOptionSelect={handleSelectContaCorrente}
            labelInput="Conta Corrente"
            placeholder="Conta Corrente"
            options={optionsContasCorrentes || []}
            labelFormat={(option) => `${option.conta} - ${option.nome}`}
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={3}>
          <MyAutoComplete
            labelInput="Convênio"
            placeholder="Convênio"
            disabled={valuesContaBancaria.tipoDeContaId !== 1}
            options={[{ label: "Teste", value: 1 }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={2}>
          {/* <button onClick={debug}>console</button> */}
          <InputData
            label="Data Fechamento"
            onChange={(e) =>
              setValuesContaBancaria((prev) => ({
                ...prev,
                dataFechamento: e.target.value,
              }))
            }
            externalValue={valuesContaBancaria.dataFechamento}
          />
        </CCol>
      </RowForm>
    </>
  );
};

export default OutrasInfos;
