import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Container } from "react-bootstrap";
import GlobalStyles from "../../../../../styles/StyledComponents/GlobalStyles";
import { DivPrintOnly } from "../../../../../styles/StyledComponents/StylesRelatorios";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { useGenerateRelatorio } from "../../../../../hooks/useGenerateRelatorio";
import { CurrentClientContext } from "../../../../../contexts/CurrentClientContext";
import HeaderCadastros from "../../../../../components/HeaderCadastros/HeaderCadastros";
import PagRelatorioComponente from "../../../../../components/ComponenteDeRelatorio/PagRelatorioComponente";
import VisualizacaoRelatorioUnidade from "./VisualizacaoRelatorioUnidade";
import { PdfUnidadeOrcamentaria } from "./PdfUnidadeOrcamentaria";
import { isBase64 } from "../../../../../utils/Gerais/isBase64";

const RelatorioUnidadeOrcamentaria = () => {
  const relatorioName = "Relatório Unidade Orçamentária";
  const columnWidth = [10, 35, 35, 20];
  const navigate = useNavigate();
  const { currentClient } = useContext(CurrentClientContext);
  const [selectBoxesOptionsAbaPrincipal, setSelectBoxesOptionsAbaPrincipal] =
    useState([]);
  const [selectBoxesOptionsAbaOptions, setSelectBoxesOptionsAbaOptions] =
    useState([
      {
        opcao: "Exibir", //"Unidade Orçamentária",
        checkBoxes: [
          { label: "Código", value: "codigo", selected: true },
          {
            label: "Unidade Orçamentária",
            value: "titulo",
            selected: true,
          },
          {
            label: "Tipo de Natureza Jurídica",
            value: "naturezaJuridica",
            selected: true,
          },
          { label: "Tipo de Vínculo", value: "funcao", selected: true },
        ],
      },
    ]);

  const { data: dataUnidadeOrcamentaria } = useFetchData(
    `unidadeOrcamentaria/cliente/${currentClient.clienteId}`
  );

  const {
    dadosRelatorio,
    dataClientInfos,
    handleGenerateDocx,
    handleGeneratePdf,
    handleGenerateXls,
    handlePrint,
    pdfData,
  } = useGenerateRelatorio(
    selectBoxesOptionsAbaOptions,
    selectBoxesOptionsAbaPrincipal,
    currentClient,
    relatorioName,
    columnWidth
  );

  const getDadosRelatorio = () => {
    const selectBox = selectBoxesOptionsAbaPrincipal?.find(
      (item) => item.opcao === "Unidade Orçamentária"
    );

    const selectBoxSelected = selectBox?.checkBoxes.some(
      (item) => item.selected
    );

    if (!selectBoxSelected) return [];

    const itensSelecionados = selectBox.checkBoxes
      .filter((item) => item.selected)
      .map((checkBox) => checkBox.value);

    const dadosSelecionados = dataUnidadeOrcamentaria?.filter((item) =>
      itensSelecionados.includes(item.unidade.id)
    );

    const dadosFiltrados = dadosSelecionados?.map((item) => ({
      codigo: item.unidade.codigo,
      titulo: item.unidade.titulo,
      naturezaJuridica: item.unidade.naturezaJuridica,
      funcao: item.unidade.funcao,
    }));

    return dadosFiltrados;
  };

  const handleGenerateDataXls = () => {
    const dadosRelatorio = getDadosRelatorio();

    const dataTable = dadosRelatorio
      ?.map((item) => {
        const selectedFields = {};
        selectBoxesOptionsAbaOptions[0]?.checkBoxes?.forEach(
          ({ label, value, selected }) => {
            const actualItem = item[value];
            if (selected && !isBase64(actualItem)[0]) {
              selectedFields[label] = actualItem;
            }
          }
        );

        return Object.keys(selectedFields).length > 0 ? selectedFields : null;
      })
      .filter((item) => item);
    if (dataTable.length !== 0) {
      handleGenerateXls(dataTable);
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  useEffect(() => {
    if (
      dataUnidadeOrcamentaria &&
      !selectBoxesOptionsAbaPrincipal.some(
        (item) => item.opcao === "Unidade Orçamentária"
      )
    ) {
      const dataOrdered = dataUnidadeOrcamentaria.sort(
        (a, b) => parseFloat(a.unidade.codigo) - parseFloat(b.unidade.codigo)
      );

      setSelectBoxesOptionsAbaPrincipal((prev) => [
        ...prev,
        {
          opcao: "Unidade Orçamentária",
          checkBoxes: dataOrdered.map((item) => ({
            label: `${item.unidade.codigo} - ${item.unidade.titulo}`,
            value: item.unidade.id,
            selected: false,
          })),
        },
      ]);
    }
  }, [dataUnidadeOrcamentaria, selectBoxesOptionsAbaPrincipal]);

  return (
    <>
      <GlobalStyles orientation={"landscape"} />
      {dadosRelatorio && (
        <DivPrintOnly>
          <VisualizacaoRelatorioUnidade
            dadosRelatorio={dadosRelatorio}
            dataClientInfos={dataClientInfos}
            numeroDaPagina={1}
            totalDePaginas={2}
          />
        </DivPrintOnly>
      )}

      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina hide_on_print"
      >
        <HeaderCadastros
          PaginaConsulta={
            "/planejamento/cadastro/unidade-orcamentaria/consulta"
          }
          NomePaginaTitulo={"Unidade Orçamentária"}
          PaginaSubtitulo={"Relatório"}
          BotaoNovoAtivo={true}
          BotaoSaveAtivo={false}
          BotaoNovoFunction={() =>
            navigate("/planejamento/cadastro/unidade-orcamentaria")
          }
          OpcoesDeArquivo={true}
          ButtonVisualizar={() => handlePrint(getDadosRelatorio)}
          ButtonPDF={(action) =>
            handleGeneratePdf(PdfUnidadeOrcamentaria, getDadosRelatorio, action)
          }
          ButtonDocx={() => handleGenerateDocx(getDadosRelatorio)}
          PdfData={pdfData}
          ButtonXls={handleGenerateDataXls}
        />
        <ToastContainer />

        <PagRelatorioComponente
          topoEsquerdoOptions={[
            { label: "Relatório", value: null },
            { label: "Relatório", value: 1 },
          ]}
          principalOptions={[
            {
              label: "Unidade Orçamentária",
              value: "Unidade Orçamentária",
            },
          ]}
          labelsCheckAbaPrincipal={selectBoxesOptionsAbaPrincipal}
          setLabelsCheckAbaPrincipal={setSelectBoxesOptionsAbaPrincipal}
          labelsCheckAbaOpcoes={selectBoxesOptionsAbaOptions}
          setLabelsCheckAbaOpcoes={setSelectBoxesOptionsAbaOptions}
          hasAssinatura={false}
          hasFavorite={false}
          // handleYearChange={handleYearChange}
        />
      </Container>
    </>
  );
};

export default RelatorioUnidadeOrcamentaria;
