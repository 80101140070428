import { useEffect, useState } from "react";
import api from "../../../../../utils/api";
import { FormatCpfPaste } from "../../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { FormatCnpjPaste } from "../../../../../utils/FormatacaoDeDados/FormatCnpjPaste";

const useAutoCompletes = (resgataDadosCnpj) => {
  const [optionsUnidadesGestoras, setOptionsUnidadesGestoras] = useState([]);
  const [optionsClientes, setOptionsClientes] = useState([]);
  const [optionsCpfs, setOptionsCpfs] = useState([]);
  const [inputAutoCompleteCpfs, setInputAutoCompleteCpfs] = useState("");
  const [inputAutoCompleteCliente, setInputAutoCompleteCliente] = useState("");
  const [inputAutoCompleteUnidadeGestora, setInputAutoCompleteUnidadeGestora] =
    useState("");

  const { data: listaDeClientes } = useFetchData("/cnpj");
  const { data: listaUnidadesGestoras } = useFetchData("/cnpj/prefeituras");

  const handleSelectCliente = (newValue) => {
    if (newValue) {
      console.log("entrei aqui: autocomplete");
      console.log(newValue.cnpj);
      resgataDadosCnpj(newValue.cnpj.replace(/[^0-9]/g, ""));
    } else {
      console.log("");
    }
  };

  const handleSelectUnidadeGestora = (newValue) => {
    if (newValue) {
      console.log("entrei aqui: autocomplete");
      resgataDadosCnpj(newValue.cnpj.replace(/[^0-9]/g, ""), true);
    }
  };

  const fetchCPFs = () => {
    api.get(`pessoas`).then((resp) => {
      const arrayObjects = resp.data
        .filter((obj) => obj.dados.nomeCompleto && obj.dados.cpf)
        .map((item) => ({
          nome: item.dados.nomeCompleto,
          cpf: FormatCpfPaste(item.dados.cpf),
          id: item.dados.id,
        }));

      console.log(arrayObjects);

      setOptionsCpfs([...arrayObjects]);
    });
  };

  useEffect(() => {
    if (listaDeClientes) {
      setOptionsClientes(
        listaDeClientes.map((item) => ({
          nomeFantasia: item.nomeFantasia,
          cnpj: FormatCnpjPaste(item.cnpj),
          id: item.pessoaId,
        }))
      );
    }
  }, [listaDeClientes]);

  useEffect(() => {
    if (listaUnidadesGestoras) {
      setOptionsUnidadesGestoras(
        listaUnidadesGestoras.map((item) => ({
          nomeFantasia: item.nomeFantasia,
          cnpj: FormatCnpjPaste(item.cnpj),
          id: item.pessoaId,
        }))
      );
    }
  }, [listaUnidadesGestoras]);

  useEffect(() => {
    fetchCPFs();
  }, []);

  return {
    optionsUnidadesGestoras,
    setOptionsUnidadesGestoras,
    optionsClientes,
    setOptionsClientes,
    optionsCpfs,
    setOptionsCpfs,
    inputAutoCompleteCpfs,
    setInputAutoCompleteCpfs,
    inputAutoCompleteCliente,
    setInputAutoCompleteCliente,
    inputAutoCompleteUnidadeGestora,
    setInputAutoCompleteUnidadeGestora,
    handleSelectCliente,
    handleSelectUnidadeGestora,
  };
};

export default useAutoCompletes;
