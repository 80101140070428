export const contaPCASPAutoPreencher = (value) => {
  const valueContaCleaned = value.replace(/[^0-9]/g, "");
  const mask = "#.#.#.#.#.##.##";
  let contaFormated = "";
  let index = 0;

  for (let i = 0; i < mask.length && index <= valueContaCleaned.length; i++) {
    if (index < valueContaCleaned.length) {
      if (mask[i] === "#") {
        contaFormated += valueContaCleaned[index];
        index++;
      } else {
        contaFormated += mask[i];
      }
    } else {
      const restante = mask.substring(i).replace(/#/g, "0");
      contaFormated += restante;
      index++;
    }
  }

  return contaFormated;
};
