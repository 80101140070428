import React, { useState } from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import InputData from "../../../../components/Grid/InputData";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import styled from "styled-components";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { HistoricoAba } from "./HistoricoAba";
import { ReceitaExtraAba } from "./ReceitaExtraAba";
import { TransfConcedidaAba } from "./TransfConcedidaAba";
import { FonteDeRecursosAba } from "./FonteDeRecursosAba";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const GdeDespesa = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"GDE - Despesa"}
        PaginaSubtitulo={"Cadastro"}
      />

      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput label="Número" placeholder="Número" />
        </CColNumero>
        <CCol md={2}>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="EVB"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="QDRDE"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={2}>
          <InputData />
        </CCol>
      </RowForm>

      <RowInfosAcao>
        <CCol>
          <p>
            <span>
              Compreende/Registra os valores relativos ao salário-família,
              incluídos em folhas de pagamento, pagos aos empregados (CLT), com
              filhos{" "}
            </span>
          </p>
        </CCol>
      </RowInfosAcao>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Beneficiário"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={3}>
          <InputFormatRealBrasileiro iconReal />
        </CCol>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Fonte de Recursos"
            style={{ height: "37px" }}
          />
        </CCol>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={[
          "Histórico",
          "Fonte de Recursos",
          "Receita Extra",
          "Transferência Concedida",
        ]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Histórico" && <HistoricoAba />}
      {abaSelecionada.nomeAba === "Fonte de Recursos" && <FonteDeRecursosAba />}
      {abaSelecionada.nomeAba === "Receita Extra" && <ReceitaExtraAba />}
      {abaSelecionada.nomeAba === "Transferência Concedida" && (
        <TransfConcedidaAba />
      )}
    </Container>
  );
};

export default GdeDespesa;
