import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfDespesaDemonstrativo = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  console.log(dados);
  console.log(opcoes);

  const styledCol = [
    { flexBasis: "200px", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    {
      flexBasis: "170px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "170px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "170px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "170px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const optionDespesaDemonstrativo = opcoes.find(
    (option) => option.opcao === "Programa de Trabalho"
  );

  const renderReceitas = (codigo, titulo, despesa) => {
    const seccoes = (codigo.match(/\./g) || []).length;

    const despesaStyleBold = seccoes > 2;

    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,

            fontWeight: despesaStyleBold ? "bold" : "normal",
          },
        ]}
      >
        {optionDespesaDemonstrativo?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={codigo || ""}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textAlign: "left",
              },
            ]}
          />
        )}
        {optionDespesaDemonstrativo?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={titulo}
            styledCol={[styledCol[1], { padding: "2px 0", textAlign: "left" }]}
          />
        )}

        {optionDespesaDemonstrativo?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={
              despesa.totalEspeciais
                ? FormatValueToLocaleString(despesa.totalEspeciais)
                : ""
            }
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaDemonstrativo?.checkBoxes[3].selected && (
          <PdfInfos
            pdfInfo={
              despesa.totalProjetos
                ? FormatValueToLocaleString(despesa.totalProjetos)
                : ""
            }
            styledCol={[
              styledCol[3],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaDemonstrativo?.checkBoxes[4].selected && (
          <PdfInfos
            pdfInfo={
              despesa.totalAtividades
                ? FormatValueToLocaleString(despesa.totalAtividades)
                : ""
            }
            styledCol={[
              styledCol[4],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
      </View>
    );
  };

  // const renderUnidade = (codigo, titulo) => {
  //   return (
  //     <View
  //       style={[
  //         stylesPdf.colunas,
  //         {
  //           gap: 0,
  //           backgroundColor: "#a6a6a6",
  //           fontWeight: "bold",
  //         },
  //       ]}
  //     >
  //       {optionDespesaDemonstrativo?.checkBoxes[0].selected && (
  //         <PdfInfos
  //           pdfInfo={codigo}
  //           styledCol={[
  //             styledCol[0],
  //             {
  //               padding: "2px 0",
  //               textAlign: "right",
  //             },
  //           ]}
  //         />
  //       )}
  //       {optionDespesaDemonstrativo?.checkBoxes[1].selected && (
  //         <PdfInfos
  //           pdfInfo={titulo}
  //           styledCol={[
  //             styledCol[1],
  //             { padding: "2px 0", textTransform: "capitalize" },
  //           ]}
  //         />
  //       )}
  //       {optionDespesaDemonstrativo?.checkBoxes[2].selected && (
  //         <PdfInfos
  //           pdfInfo={""}
  //           styledCol={[
  //             styledCol[2],
  //             {
  //               padding: "2px 0",
  //               textAlign: "right",
  //             },
  //           ]}
  //         />
  //       )}
  //       {optionDespesaDemonstrativo?.checkBoxes[3].selected && (
  //         <PdfInfos
  //           pdfInfo={""}
  //           styledCol={[
  //             styledCol[3],
  //             {
  //               padding: "2px 0",
  //               textAlign: "center",
  //             },
  //           ]}
  //         />
  //       )}
  //       {optionDespesaDemonstrativo?.checkBoxes[4].selected && (
  //         <PdfInfos
  //           pdfInfo={""}
  //           styledCol={[
  //             styledCol[4],
  //             {
  //               padding: "2px 0",
  //               textAlign: "right",
  //             },
  //           ]}
  //         />
  //       )}
  //       {optionDespesaDemonstrativo?.checkBoxes[5].selected && (
  //         <PdfInfos
  //           pdfInfo={""}
  //           styledCol={[
  //             styledCol[5],
  //             {
  //               padding: "2px 0",
  //               textAlign: "right",
  //             },
  //           ]}
  //         />
  //       )}
  //     </View>
  //   );
  // };

  const renderTotal = (total) => {
    const totalGeral =
      total.totalEspeciaisCliente +
      total.totalProjetosCliente +
      total.totalAtividadesCliente;
    return (
      <>
        <View
          wrap={false}
          style={[
            stylesPdf.divInferiorColuna,
            {
              fontWeight: "bold",
              fontSize: 10,
              textTransform: "uppercase",
              color: "white",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 10,
              margin: 0,
              paddingHorizontal: 0,
              paddingLeft: 10,
            },
          ]}
        >
          <Text>TOTAL</Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                width: 70,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {total.totalEspeciaisCliente
                ? FormatValueToLocaleString(total.totalEspeciaisCliente)
                : ""}
            </Text>
            <Text
              style={{
                width: 70,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {total.totalProjetosCliente
                ? FormatValueToLocaleString(total.totalProjetosCliente)
                : ""}
            </Text>
            <Text
              style={{
                width: 70,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {total.totalAtividadesCliente
                ? FormatValueToLocaleString(total.totalAtividadesCliente)
                : ""}
            </Text>
            {/* <Text
              style={{
                width: 70,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {totalGeral ? FormatValueToLocaleString(totalGeral) : ""}
            </Text> */}
          </View>
        </View>
        {/* <View break /> */}
        <View
          wrap={false}
          style={[
            stylesPdf.divInferiorColuna,
            {
              fontWeight: "bold",
              fontSize: 10,
              textTransform: "uppercase",
              color: "white",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 10,
              margin: 0,
              paddingHorizontal: 0,
              paddingLeft: 10,
            },
          ]}
        >
          <Text>TOTAL GERAL</Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                width: 90,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {totalGeral ? FormatValueToLocaleString(totalGeral) : ""}
            </Text>
          </View>
        </View>
      </>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={
          "Demonstrativo de Funções, Subfunção, Programas, Ações por Especiais, Projetos e Atividades"
        }
        plusText={"Anexo VII"}
        nameProjeto={"Programa de Trabalho de Governo"}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {optionDespesaDemonstrativo?.checkBoxes?.map((option, index) => (
          <PdfColunas
            key={index}
            colName={option.label.toUpperCase()}
            styledCol={styledCol[index]}
            hideBar
          />
        ))}
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View style={{ margin: "0 -3px" }}>
          {dados.dataDespesa.map((despesa, unidadeIndex) => (
            <React.Fragment key={unidadeIndex}>
              {/* {renderUnidade(
                despesa.infoUnidade.idUnidade,
                despesa.infoUnidade.unidadeGestora
              )} */}
              {Object.keys(despesa.infoUnidade.unidades).map(
                (funcaoKey, funcaoIndex) => {
                  const despesaFuncao = despesa.infoUnidade.unidades[funcaoKey];
                  const codigoFuncao = despesaFuncao.codigoFuncao;
                  return (
                    <React.Fragment key={funcaoIndex}>
                      {renderReceitas(
                        codigoFuncao,
                        despesaFuncao.tituloFuncao,
                        despesaFuncao
                      )}
                      {Object.keys(despesaFuncao.subFuncoes)
                        .sort((a, b) => Number(a) - Number(b))
                        .map((subFuncaoKey, subFuncaoIndex) => {
                          const subFuncao =
                            despesaFuncao.subFuncoes[subFuncaoKey];
                          const codigoSubFuncao = `${codigoFuncao}.${subFuncao.codigoSubFuncao}`;
                          return (
                            <React.Fragment key={subFuncaoIndex}>
                              {renderReceitas(
                                codigoSubFuncao,
                                subFuncao.tituloSubFuncao,
                                subFuncao
                              )}
                              {Object.keys(subFuncao.programas).map(
                                (programaKey, programaIndex) => {
                                  const programa =
                                    subFuncao.programas[programaKey];
                                  const codigoPrograma = `${codigoSubFuncao}.${programa.codigoPrograma}`;
                                  return (
                                    <React.Fragment key={programaIndex}>
                                      {renderReceitas(
                                        codigoPrograma,
                                        programa.tituloPrograma,
                                        programa
                                      )}
                                      {Object.keys(programa.acoes).map(
                                        (acaoKey, acaoIndex) => {
                                          const acao = programa.acoes[acaoKey];
                                          const codigoAcao = `${codigoPrograma}.${acao.nAcao}`;
                                          return (
                                            <React.Fragment key={acaoIndex}>
                                              {renderReceitas(
                                                codigoAcao,
                                                acao.tituloAcao,
                                                acao
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </React.Fragment>
                          );
                        })}
                    </React.Fragment>
                  );
                }
              )}
              {renderTotal(despesa.infoUnidade.totalGeralUnidade)}
            </React.Fragment>
          ))}
        </View>
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
