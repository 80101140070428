import { Row } from "react-bootstrap";
import styled from "styled-components";

export const RowInfosAcao = styled(Row)`
  margin: 0px;
  border-radius: 10px;
  background-color: #ebeaea;
  padding: 14px;

  span {
    float: left;
    color: #73767e;
  }

  p {
    margin: 0px;
    margin-left: 10px;
    float: left;
    color: #515c70;
    text-transform: uppercase;
    font-weight: 500;
  }
`;

export const SpanTotalPorFonte = styled.span`
  font-weight: 600;
  color: #515c70;
`;
