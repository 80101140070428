import React, { useState } from "react";
import { ContainerPag } from "../../../../components/ContainerPag/ContainerPag";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import styled from "styled-components";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { HistoricoAba } from "./HistoricoAba";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const GrpDespesa = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });

  return (
    <ContainerPag>
      <HeaderCadastros
        NomePaginaTitulo={"GRP - Despesa"}
        PaginaSubtitulo={"Cadastro"}
        RowTitle={"Guia de Restos a Pagar"}
      />

      <RowForm>
        <CColNumero md={1}>
          <FloatingLabelInput disabled label="Número" placeholder="Número" />
        </CColNumero>
        <CColNumero md={1}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Ano"
          />
        </CColNumero>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Empenho"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={2}>
          <InputData />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            disabled
            label="Favorecido"
            placeholder="Favorecido"
          />
        </CCol>
        <CCol md={2}>
          <InputFormatRealBrasileiro iconReal />
        </CCol>
      </RowForm>
      <RowInfosAcao>
        <CCol md={7}>
          <p>
            Unidade Orçamentária:{" "}
            <span>02.050 - Secretaria Municipal de Educação</span>
          </p>
          <p>
            Programa:{" "}
            <span>
              2025 - Manutenção dos Serviços da Secretaria Municipal de Educação
            </span>
          </p>
          <p>
            Ação:{" "}
            <span>
              2025 - Manutenção dos Serviços da Secretaria Municipal de Educação
            </span>
          </p>
          <p>
            Fonte de Recursos - CO:{" "}
            <span>1500.1001 - Recursos Vinculados da Educação</span>
          </p>
        </CCol>
        <CCol>
          <p>
            Função: <span>08 - Assistência Social</span>
          </p>
          <p>
            Subfunção: <span>244 - Assistência Comunitária</span>
          </p>
          <p>
            Elemento:{" "}
            <span>3.3.90.39 - Outros Serviços Pessoa Jurídica - PJ</span>
          </p>
        </CCol>
      </RowInfosAcao>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            disabled
            label="Fonte de Recursos Pagamento"
            placeholder="Fonte de Recursos Pagamento"
          />
        </CCol>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={["Histórico"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Histórico" && <HistoricoAba />}

      <div className="p-3"></div>
    </ContainerPag>
  );
};

export default GrpDespesa;
