import React from "react";
import { Row } from "react-bootstrap";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

const Valores = () => {
  return (
    <Row className={"row_form mt-1"}>
      <RowAninhada>
        <CCol md={2}>
          <FloatingLabelInput label={"Dimensões"} placeholder={"Dimensões"} />
        </CCol>
        <CCol>
          <FloatingLabelInput
            label={"Valor Inicial da Obra"}
            placeholder={"Valor Inicial da Obra"}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            label={"Valor do Contrato"}
            placeholder={"Valor do Contrato"}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            label={"Valor Aditivado"}
            placeholder={"Valor Aditivado"}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            label={"Total de Recursos Próprios"}
            placeholder={"Total de Recursos Próprios"}
          />
        </CCol>
      </RowAninhada>
    </Row>
  );
};

export default Valores;
