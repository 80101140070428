import React from "react";
import { Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  CCol,
  CColButtonsAcoes,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import {
  AutocompleteStyled,
  TextFieldStyled,
} from "../../../../styles/StyledComponents/FormsStyleds";
import SearchIcon from "@mui/icons-material/Search";
import {
  AutocompleteListaDeInputs,
  FormControlListaDeInputs,
  PTitulosInputs,
  TextFieldListaDeInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import { Icon } from "@iconify/react";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { DivFontesDeRecursos } from "./StyledCompsReceitaEstimada";
import { SpanTotalPorFonte } from "../../LOA_QDD/StyledComponentsQDD";

const ReceitaEstimadaPPA = () => {
  const arrayTeste = [1, 2, 3, 4];

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"PPA"}
        PaginaSubtitulo={"Receita Estimada"}
      />
      <Row className="row_form_2 mt-1">
        <CCol>
          <SearchIcon
            sx={{
              marginTop: "7px",
              marginLeft: "10px",
              position: "absolute",
              color: "#515C70",
            }}
          />
          <AutocompleteStyled
            freeSolo
            disableClearable
            options={[]}
            //   onInputChange={(event, newInputValue) => {
            //     setInputAcao(newInputValue);
            //   }}
            onChange={() => {
              console.log("");
            }}
            getOptionLabel={(option) => `${option.codigo} - ${option.titulo}`}
            renderInput={(params) => (
              <TextFieldStyled
                {...params}
                //   title={inputAcao}
                placeholder="Unidade Orçamentária Gestora"
                label="Unidade Orçamentária Gestora"
                inputProps={{
                  ...params.inputProps,
                  // value: inputAcao,
                  type: "search",
                }}
                size="small"
              />
            )}
          />
        </CCol>
      </Row>

      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Receita Prevista
          </span>
        </CCol>
      </Row>

      <Row className="row_form mt-1">
        <CCol md={9}>
          <PTitulosInputs>Natureza da Receita</PTitulosInputs>
          <SearchIcon
            sx={{
              marginTop: "7px",
              marginLeft: "10px",
              position: "absolute",
              color: "#515C70",
            }}
          />
          <AutocompleteStyled
            freeSolo
            disableClearable
            options={[]}
            //   onInputChange={(event, newInputValue) => {
            //     setInputAcao(newInputValue);
            //   }}
            onChange={() => {
              console.log("");
            }}
            getOptionLabel={(option) => `${option.codigo} - ${option.titulo}`}
            renderInput={(params) => (
              <TextFieldStyled
                {...params}
                //   title={inputAcao}
                inputProps={{
                  ...params.inputProps,
                  // value: inputAcao,
                  type: "search",
                }}
                size="small"
              />
            )}
          />
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>R$ Previsto Corrente</PTitulosInputs>
          <FloatingLabelInput />
        </CCol>
        <CColButtonsAcoes>
          <PTitulosInputs>Ação</PTitulosInputs>
          <Icon
            onClick={() => {
              console.log();
            }}
            icon="ic:baseline-add-box"
            color="#105200"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "3px",
              marginTop: "5px",
            }}
          />
        </CColButtonsAcoes>
      </Row>

      <Row className="row_form_2 mt-1">
        <CCol md={1}>
          <PTitulosInputs>Ficha</PTitulosInputs>
          <FormControlListaDeInputs disabled />
        </CCol>
        <CCol md={8}>
          <PTitulosInputs>Natureza da Receita</PTitulosInputs>
          <FormControlListaDeInputs disabled />
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>R$ Previsto</PTitulosInputs>
          <InputFormatRealBrasileiro listaDeInputs={true} disabled />
        </CCol>
        <CColButtonsAcoes>
          <PTitulosInputs>Ação</PTitulosInputs>

          <Icon
            icon="ic:baseline-delete"
            color="#E79900"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "3px",
              marginTop: "1px",
            }}
          />
        </CColButtonsAcoes>

        <RowAninhada style={{ marginTop: "14px" }}>
          <CCol md={11}>
            <DivFontesDeRecursos>
              <div style={{ display: "flex" }}>
                <CCol md={5} style={{ marginRight: "16px" }}>
                  <PTitulosInputs>Fonte de Recursos</PTitulosInputs>
                  <div style={{ position: "relative" }}>
                    <SearchIcon
                      sx={{
                        marginTop: "5px",
                        marginLeft: "10px",
                        position: "absolute",
                        color: "#515C70",
                      }}
                    />
                    <AutocompleteListaDeInputs
                      radiusborder={
                        arrayTeste.length > 0 ? "5px 5px 0px 0px" : "5px"
                      }
                      freeSolo
                      disableClearable
                      options={[]}
                      //   onInputChange={(event, newInputValue) => {
                      //     setInputAcao(newInputValue);
                      //   }}
                      onChange={() => {
                        console.log("");
                      }}
                      getOptionLabel={(option) =>
                        `${option.codigo} - ${option.titulo}`
                      }
                      renderInput={(params) => (
                        <TextFieldListaDeInputs
                          {...params}
                          //   title={inputAcao}
                          inputProps={{
                            ...params.inputProps,
                            // value: inputAcao,
                            type: "search",
                          }}
                          size="small"
                        />
                      )}
                    />
                  </div>
                </CCol>
                <CCol md={2} style={{ marginRight: "16px" }}>
                  <PTitulosInputs>Esfera</PTitulosInputs>
                  <FormControlListaDeInputs
                    radiusborder={
                      arrayTeste.length > 0 ? "5px 5px 0px 0px" : "5px"
                    }
                  />
                </CCol>
                <CCol md={1} style={{ marginRight: "16px" }}>
                  <PTitulosInputs>%</PTitulosInputs>
                  <FormControlListaDeInputs
                    radiusborder={
                      arrayTeste.length > 0 ? "5px 5px 0px 0px" : "5px"
                    }
                  />
                </CCol>
                <CCol md={2} style={{ marginRight: "16px" }}>
                  <PTitulosInputs>2024 Corrente</PTitulosInputs>
                  <FormControlListaDeInputs
                    radiusborder={
                      arrayTeste.length > 0 ? "5px 5px 0px 0px" : "5px"
                    }
                  />
                </CCol>
                <CCol md={1} style={{ marginRight: "16px" }}>
                  <PTitulosInputs>%</PTitulosInputs>
                  <FormControlListaDeInputs
                    radiusborder={
                      arrayTeste.length > 0 ? "5px 5px 0px 0px" : "5px"
                    }
                  />
                </CCol>
                <CCol md={2} style={{ marginRight: "16px" }}>
                  <PTitulosInputs>2025 Corrente</PTitulosInputs>
                  <FormControlListaDeInputs
                    radiusborder={
                      arrayTeste.length > 0 ? "5px 5px 0px 0px" : "5px"
                    }
                  />
                </CCol>
                <CCol md={1} style={{ marginRight: "16px" }}>
                  <PTitulosInputs>%</PTitulosInputs>
                  <FormControlListaDeInputs
                    radiusborder={
                      arrayTeste.length > 0 ? "5px 5px 0px 0px" : "5px"
                    }
                  />
                </CCol>
                <CCol md={2} style={{ marginRight: "16px" }}>
                  <PTitulosInputs>2026 Corrente</PTitulosInputs>
                  <FormControlListaDeInputs
                    radiusborder={
                      arrayTeste.length > 0 ? "5px 5px 0px 0px" : "5px"
                    }
                  />
                </CCol>
                <CCol md={1} style={{ marginRight: "16px" }}>
                  <PTitulosInputs>%</PTitulosInputs>
                  <FormControlListaDeInputs
                    radiusborder={
                      arrayTeste.length > 0 ? "5px 5px 0px 0px" : "5px"
                    }
                  />
                </CCol>
                <CCol md={2} style={{ marginRight: "16px" }}>
                  <PTitulosInputs>2027 Corrente</PTitulosInputs>
                  <FormControlListaDeInputs
                    radiusborder={
                      arrayTeste.length > 0 ? "5px 5px 0px 0px" : "5px"
                    }
                  />
                </CCol>
                <CCol md={1}>
                  <PTitulosInputs>%</PTitulosInputs>
                  <FormControlListaDeInputs
                    radiusborder={
                      arrayTeste.length > 0 ? "5px 5px 0px 0px" : "5px"
                    }
                  />
                </CCol>
              </div>
              {arrayTeste.map((item, index) => (
                <div style={{ display: "flex" }}>
                  <CCol md={5} style={{ marginRight: "16px" }}>
                    <FormControlListaDeInputs
                      disabled
                      radiusborder={
                        index === arrayTeste.length - 1
                          ? " 0px 0px 5px 5px"
                          : "0px"
                      }
                    />
                  </CCol>
                  <CCol md={2} style={{ marginRight: "16px" }}>
                    <FormControlListaDeInputs
                      disabled
                      radiusborder={
                        index === arrayTeste.length - 1
                          ? " 0px 0px 5px 5px"
                          : "0px"
                      }
                    />
                  </CCol>
                  <CCol md={1} style={{ marginRight: "16px" }}>
                    <FormControlListaDeInputs
                      disabled
                      radiusborder={
                        index === arrayTeste.length - 1
                          ? " 0px 0px 5px 5px"
                          : "0px"
                      }
                    />
                  </CCol>
                  <CCol md={2} style={{ marginRight: "16px" }}>
                    <FormControlListaDeInputs
                      disabled
                      radiusborder={
                        index === arrayTeste.length - 1
                          ? " 0px 0px 5px 5px"
                          : "0px"
                      }
                    />
                  </CCol>
                  <CCol md={1} style={{ marginRight: "16px" }}>
                    <FormControlListaDeInputs
                      disabled
                      radiusborder={
                        index === arrayTeste.length - 1
                          ? " 0px 0px 5px 5px"
                          : "0px"
                      }
                    />
                  </CCol>
                  <CCol md={2} style={{ marginRight: "16px" }}>
                    <FormControlListaDeInputs
                      disabled
                      radiusborder={
                        index === arrayTeste.length - 1
                          ? " 0px 0px 5px 5px"
                          : "0px"
                      }
                    />
                  </CCol>
                  <CCol md={1} style={{ marginRight: "16px" }}>
                    <FormControlListaDeInputs
                      disabled
                      radiusborder={
                        index === arrayTeste.length - 1
                          ? " 0px 0px 5px 5px"
                          : "0px"
                      }
                    />
                  </CCol>
                  <CCol md={2} style={{ marginRight: "16px" }}>
                    <FormControlListaDeInputs
                      disabled
                      radiusborder={
                        index === arrayTeste.length - 1
                          ? " 0px 0px 5px 5px"
                          : "0px"
                      }
                    />
                  </CCol>
                  <CCol md={1} style={{ marginRight: "16px" }}>
                    <FormControlListaDeInputs
                      disabled
                      radiusborder={
                        index === arrayTeste.length - 1
                          ? " 0px 0px 5px 5px"
                          : "0px"
                      }
                    />
                  </CCol>
                  <CCol md={2} style={{ marginRight: "16px" }}>
                    <FormControlListaDeInputs
                      disabled
                      radiusborder={
                        index === arrayTeste.length - 1
                          ? " 0px 0px 5px 5px"
                          : "0px"
                      }
                    />
                  </CCol>
                  <CCol md={1}>
                    <FormControlListaDeInputs
                      disabled
                      radiusborder={
                        index === arrayTeste.length - 1
                          ? " 0px 0px 5px 5px"
                          : "0px"
                      }
                    />
                  </CCol>
                </div>
              ))}
              <div style={{ display: "flex", marginTop: "8px" }}>
                <CCol md={5} style={{ marginRight: "16px" }}></CCol>
                <CCol
                  md={2}
                  style={{
                    marginRight: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SpanTotalPorFonte>Valor Previsto</SpanTotalPorFonte>
                </CCol>
                <CCol md={1} style={{ marginRight: "16px" }}>
                  <FormControlListaDeInputs disabled />
                </CCol>
                <CCol md={2} style={{ marginRight: "16px" }}>
                  <FormControlListaDeInputs disabled />
                </CCol>
                <CCol md={1} style={{ marginRight: "16px" }}>
                  <FormControlListaDeInputs disabled />
                </CCol>
                <CCol md={2} style={{ marginRight: "16px" }}>
                  <FormControlListaDeInputs disabled />
                </CCol>
                <CCol md={1} style={{ marginRight: "16px" }}>
                  <FormControlListaDeInputs disabled />
                </CCol>
                <CCol md={2} style={{ marginRight: "16px" }}>
                  <FormControlListaDeInputs disabled />
                </CCol>
                <CCol md={1} style={{ marginRight: "16px" }}>
                  <FormControlListaDeInputs disabled />
                </CCol>
                <CCol md={2} style={{ marginRight: "16px" }}>
                  <FormControlListaDeInputs disabled />
                </CCol>
                <CCol md={1}>
                  <FormControlListaDeInputs disabled />
                </CCol>
              </div>
            </DivFontesDeRecursos>
          </CCol>
          <CColButtonsAcoes style={{ paddingTop: "10px" }}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <Icon
              onClick={() => {
                console.log();
              }}
              icon="ic:baseline-add-box"
              color="#105200"
              height="28"
              style={{
                cursor: "pointer",
                marginLeft: "3px",
                marginTop: "5px",
              }}
            />

            {arrayTeste.map((item, index) => (
              <div>
                <Icon
                  icon="bx:edit"
                  color="#5971C8"
                  height="26"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "3px",
                  }}
                />
                <Icon
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </div>
            ))}
          </CColButtonsAcoes>
        </RowAninhada>
      </Row>
      <div className="p-3"></div>
    </Container>
  );
};

export default ReceitaEstimadaPPA;
