import styled from "styled-components";
import { Form, InputGroup } from "react-bootstrap";

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative; /* Adicionado */
  color: #515c70;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
  margin-left: 3px;
`;

export const FilterLabel = styled.span`
  margin-right: 0.5rem;
`;

export const FilterSelect = styled.select`
  color: #515c70;
  border-color: #888a8e;
  border-left: none;
  border-right: none;
  border-top: none;
  border-width: 2px;
  padding-right: 10px;
  padding-left: 10px;
`;

export const CustomInputGroup = styled(InputGroup)`
  width: 100%;
`;

export const SearchInput = styled(Form.Control)`
  border-color: #e8e8e8;
  border-right: none;
`;

export const SearchButton = styled(InputGroup.Text)`
  color: #6c7585;
  border-left: none;
  background-color: transparent;
  border-color: #e8e8e8;
  &:hover {
    color: black;
    background-color: transparent;
  }
`;

export const TituloTable = styled.th`
  color: #888a8e;
  font-weight: 600;
  font-size: 12px;
`;

export const TituloTableAction = styled.th`
  color: #888a8e;
  font-weight: 600;
  font-size: 12px;
  width: 0px;
`;

export const PassadorPage = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const DivAction = styled.div`
  height: 10px;
  display: flex;
  gap: 6px;
  /* justify-content: end; */
`;

export const DivSist = styled.div``;

export const BodyTable = styled.tbody`
  color: #515c70;
  font-weight: 600;
  font-size: 14px;
`;
