import "./App.css";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rotas from "./components/routes/Rotas";
import { AuthContextProvider } from "./contexts/AuthContext";
import { CurrentClientProvider } from "./contexts/CurrentClientContext";
import { CurrentCityProvider } from "./contexts/CurrentCityContext";
import { SidebarExpandidaProvider } from "./contexts/SidebarExpandidaContext";
import { OpenedModalsProvider } from "./contexts/OpenedModalsContext";
import { CurrentYearProvider } from "./contexts/YearContext";

function App() {
  return (
    <>
      <OpenedModalsProvider>
        <CurrentClientProvider>
          <CurrentCityProvider>
            <AuthContextProvider>
              <SidebarExpandidaProvider>
                <CurrentYearProvider>
                  <Rotas />
                </CurrentYearProvider>
              </SidebarExpandidaProvider>
            </AuthContextProvider>
          </CurrentCityProvider>
        </CurrentClientProvider>
      </OpenedModalsProvider>
    </>
  );
}

export default App;
