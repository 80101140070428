import React, { useState, useEffect, useRef, useContext } from "react";
import { Row } from "react-bootstrap";
import {
  CCol,
  ContainerToModal,
  RowToModal,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useFetchData } from "../../../../hooks/useFetchData";
import styled from "styled-components";
import { InputDateRelatorio } from "../../../../components/Inputs/InputDateRelatorio";
import ButtonSaveModalAutoComplete from "../../../../components/Modais/ButtonSaveModalAutoComplete";
import { toast } from "react-toastify";
import { OpenedModalsContext } from "../../../../contexts/OpenedModalsContext";

const UserAddBox = styled.div`
  position: absolute;
  background-color: #fefffe;
  width: 150px;
  height: 166px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 5px;
  box-shadow: 0px 3px 5px #bebebe;
  border-radius: 5px;
  padding: 10px 0;
  align-items: center;
  opacity: ${(props) => (props.opened ? 1 : 0)};
  pointer-events: ${(props) => (props.opened ? "all" : "none")};
  transition: opacity 0.2s;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const UserBox = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 16px;
  padding-left: 10px;
  cursor: pointer;
  &:hover {
    background-color: #eceded;
  }
`;

const getColorContrast = (hexcolor) => {
  const r = parseInt(hexcolor.slice(1, 3), 16);
  const g = parseInt(hexcolor.slice(3, 5), 16);
  const b = parseInt(hexcolor.slice(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000000" : "#ffffff";
};

const UserCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  width: 35px;
  height: 35px;
  font-size: 30px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  color: ${(props) => getColorContrast(props.color)};
  margin: 0 2px 4px 2px;
  cursor: pointer;
  border: 1px solid #00000014;
`;

const RegistroDePrazos = ({ isAModalPage }) => {
  const { /*openedModals,*/ setOpenedModals } = useContext(OpenedModalsContext);
  const { data: listaDeUsuarios } = useFetchData("/usuario");
  const [openedUserList, setOpenedUserList] = useState(false);
  const [valuesToSend, setValuesToSend] = useState([]);
  const userAddBoxRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      userAddBoxRef.current &&
      !userAddBoxRef.current.contains(event.target)
    ) {
      setOpenedUserList(false);
    }
  };

  const addUser = (userId) => {
    setOpenedUserList(false);
    setValuesToSend((prevValues) => {
      const usuarios = prevValues.usuarios;

      if (!usuarios.some((item) => userId === item)) {
        return {
          ...prevValues,
          usuarios: [...usuarios, userId],
        };
      }

      return prevValues;
    });
  };

  const removeUser = (userId) => {
    setOpenedUserList(false);
    setValuesToSend((prevValues) => {
      const usuarios = prevValues.usuarios;

      return {
        ...prevValues,
        usuarios: usuarios.filter((item) => item !== userId),
      };
    });
  };

  const stringToColor = (letter) => {
    const colors = [
      "#FF5733",
      "#33FF57",
      "#3357FF",
      "#FF33A1",
      "#33FFA1",
      "#A133FF",
      "#FFA133",
      "#33A1FF",
      "#A1FF33",
      "#FF5733",
      "#33FF57",
      "#3357FF",
      "#FF33A1",
      "#33FFA1",
      "#A133FF",
      "#FFA133",
      "#33A1FF",
      "#A1FF33",
      "#5733FF",
      "#A1FF57",
      "#FF33FF",
      "#33FF57",
      "#3357FF",
      "#FF5733",
      "#33FF57",
      "#3357FF",
    ];

    const index = letter.charCodeAt(0) % colors.length;
    return colors[index];
  };

  const openUserList = () => {
    setOpenedUserList(!openedUserList);
  };

  const maskDate = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})\/(\d{2})(\d)/, "$1/$2/$3")
      .substring(0, 10);
  };

  const cadastrarAcao = () => {
    console.log(valuesToSend);
    toast.success("Cadastrado com sucesso!");
    if (isAModalPage) {
      closeModal("Tarefas e Prazos");
    }
  };

  const closeModal = (titulo) => {
    setOpenedModals((prev) =>
      prev.filter((modal) => modal.tituloModal !== titulo)
    );
  };

  useEffect(() => {
    if (openedUserList) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openedUserList]);

  useEffect(() => {
    setValuesToSend({
      titulo: "",
      descricao: "",
      usuarios: [],
      inicio: "",
      final: "",
      freq: "",
      prioridade: "",
    });
  }, []);

  return (
    <>
      <ContainerToModal
        isAModalPage={isAModalPage}
        fluid
        style={{ padding: 0 }}
      >
        {isAModalPage && (
          <ButtonSaveModalAutoComplete
            SaveFunction={cadastrarAcao}
            pageTitle={"Tarefas e Prazos"}
          />
        )}

        <RowToModal isAModalPage={isAModalPage} className="row_form mt-1">
          <Row>
            <CCol>
              <FloatingLabelInput
                placeholder={"Título"}
                value={valuesToSend.titulo}
                onChange={(e) => {
                  setValuesToSend((prevValues) => ({
                    ...prevValues,
                    titulo: e.target.value,
                  }));
                }}
              />
            </CCol>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <CCol>
              <FloatingLabelInput
                placeholder={"Descrição"}
                value={valuesToSend.descricao}
                onChange={(e) => {
                  setValuesToSend((prevValues) => ({
                    ...prevValues,
                    descricao: e.target.value,
                  }));
                }}
              />
            </CCol>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <CCol
              md={4}
              style={{
                display: "flex",
                alignItems: "flex-start",
                position: "relative",
                marginTop: "2px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {valuesToSend.usuarios &&
                  valuesToSend.usuarios.map((item, index) => {
                    const user = listaDeUsuarios.find(
                      (user) => user.userId === item
                    );
                    return (
                      <UserCircle
                        key={index}
                        color={stringToColor(user.nome.substring(0, 1))}
                        onClick={() => removeUser(item)}
                        title={user.nome}
                      >
                        {user.nome.substring(0, 1)}
                      </UserCircle>
                    );
                  })}
              </div>
              <div
                style={{
                  marginLeft: "5px",
                  position: "relative",
                  alignSelf: "flex-start",
                }}
              >
                <Icon
                  icon="zondicons:add-solid"
                  color="#888A8E"
                  width={35}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => openUserList()}
                />
                <UserAddBox opened={openedUserList} ref={userAddBoxRef}>
                  {listaDeUsuarios &&
                    listaDeUsuarios.map((item, index) => (
                      <UserBox key={index} onClick={() => addUser(item.userId)}>
                        <span>
                          {item.nome.length > 13
                            ? item.nome.substring(0, 13) + "."
                            : item.nome + "."}
                        </span>
                      </UserBox>
                    ))}
                </UserAddBox>
              </div>
            </CCol>
            <CCol md={2}>
              <InputDateRelatorio
                placeholder={"Início"}
                value={valuesToSend.inicio}
                onChange={(e) => {
                  setValuesToSend((prevValues) => ({
                    ...prevValues,
                    inicio: maskDate(e.target.value),
                  }));
                }}
              />
            </CCol>
            <CCol md={2}>
              <InputDateRelatorio
                placeholder={"Final"}
                value={valuesToSend.final}
                onChange={(e) => {
                  setValuesToSend((prevValues) => ({
                    ...prevValues,
                    final: maskDate(e.target.value),
                  }));
                }}
              />
            </CCol>
            <CCol md={2}>
              <FloatingLabelInput
                new
                tipo="input_select"
                options={[
                  { label: "Frequência", value: null },
                  { label: "Frequência", value: 1 },
                ]}
              />
            </CCol>
            <CCol md={2}>
              <FloatingLabelInput
                new
                tipo="input_select"
                options={[
                  { label: "Prioridade", value: null },
                  { label: "Baixa", value: 1 },
                  { label: "Média", value: 2 },
                  { label: "Alta", value: 3 },
                ]}
                onSelect={(option) => {
                  setValuesToSend((prevValues) => ({
                    ...prevValues,
                    prioridade: option.value,
                  }));
                }}
              />
            </CCol>
          </Row>
        </RowToModal>
      </ContainerToModal>
    </>
  );
};

export default RegistroDePrazos;
