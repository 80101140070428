import { getDemoAvaliacao } from "./getDemoAvaliacao";
import { getDemoMetasFiscais } from "./getDemoMetasFiscais";
import { getDemoMetasAnuais } from "./getDemoMetasAnuais";
import { getDemoRiscosFiscaisProvidencias } from "./getDemoRiscosFiscaisProvidencias";
import { getEvolucaoPatrimonio } from "./getEvolucaoPatrimonio";
import { getDemoOrigemAplicacao } from "./getDemoOrigemAplicacao";
import { getDemoAvaliacaoSituacao } from "./getDemoAvaliacaoSituacao";
import { getDemoEstimativa } from "./getDemoEstimativa";
import { getDemoMargenExpansao } from "./getDemoMargenExpansao";
import { getAnexoEstimativa } from "./getAnexoEstimativa";
import { getAnexoCompatibilidade } from "./getAnexoCompatibilidade";
import { getAnexoPrioridade } from "./getAnexoPrioridade";

export const getDataLdo = {
  getDemoRiscosFiscaisProvidencias,
  getDemoMetasFiscais,
  getDemoAvaliacao,
  getDemoMetasAnuais,
  getEvolucaoPatrimonio,
  getDemoOrigemAplicacao,
  getDemoAvaliacaoSituacao,
  getDemoEstimativa,
  getDemoMargenExpansao,
  getAnexoEstimativa,
  getAnexoCompatibilidade,
  getAnexoPrioridade
};
