import * as RS from "../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/stylesVisualizacaoRelatorio";

import React, { useContext } from "react";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { AuthContext } from "../../../../contexts/AuthContext";

import { RodapeRelatorio } from "../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/RodapeRelatorio";
import { InfosRelatorio } from "../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/InfosRelatorio";
import { ColunasRelatorio } from "../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/ColunasRelatorio";
import { HeaderRelatorio } from "../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/HeaderRelatorio";

const VisualizacaoRelatorioEixoEstrategico = ({
  dadosRelatorio,
  dataClientInfos,
  numeroDaPagina,
  totalDePaginas,
}) => {
  const { currentClient } = useContext(CurrentClientContext);
  const { userAuthData } = useContext(AuthContext);

  const colNames = ["Código", "Título", "Qtd Diretrizes"];
  const styledCol = [
    { flexBasis: "125px", flexShrink: 0 },
    { flexBasis: "100%", flexShrink: 1 },
    { flexBasis: "125px", flexShrink: 0 },
    
  ];

  return (
    <RS.ContainerRelatorio>
      <RS.PageHeader>
        <HeaderRelatorio
          currentClient={currentClient}
          relatorioName={"Eixo Estratégico"}
          dataClientInfos={dataClientInfos}
        />
      </RS.PageHeader>

      <RS.DivExterna>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <td>
                <RS.PageHeaderSpace />
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <RS.DivRelatorio>
                  <RS.Colunas>
                    {colNames.map((item, index) => (
                      <ColunasRelatorio
                        key={index}
                        colName={item}
                        styledCol={styledCol[index]}
                      />
                    ))}
                  </RS.Colunas>
                  <RS.InfosColunas>
                    {dadosRelatorio?.map((item) => (
                      <React.Fragment key={item.id}>
                        <RS.Colunas>
                          <InfosRelatorio
                            infoRelatorio={item.codigo}
                            styledCol={styledCol[0]}
                          />
                          <InfosRelatorio
                            infoRelatorio={item.titulo}
                            styledCol={styledCol[1]}
                          />
                          <InfosRelatorio
                            infoRelatorio={item.diretrizes.length}
                            styledCol={styledCol[2]}
                          />
                        </RS.Colunas>
                        <RS.LineInfosRelatorio />
                      </React.Fragment>
                    ))}
                    <RS.DivInferiorColuna></RS.DivInferiorColuna>
                  </RS.InfosColunas>
                </RS.DivRelatorio>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <RS.PageFooterSpace />
              </td>
            </tr>
          </tfoot>
        </table>
      </RS.DivExterna>
      <RS.PageFooter>
        <RodapeRelatorio
          currentClient={currentClient}
          userAuthData={userAuthData}
        />
      </RS.PageFooter>
    </RS.ContainerRelatorio>
  );
};

export default VisualizacaoRelatorioEixoEstrategico;

// import React, { useContext } from "react";

// import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

// import * as RS from "../../../../styles/StyledComponents/StylesRelatorios"

// import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
// import { FormatCnpjPaste } from "../../../../utils/FormatacaoDeDados/FormatCnpjPaste";
// import { AuthContext } from "../../../../contexts/AuthContext";

// const VisualizacaoRelatorioEixoEstrategico = ({
//   dadosRelatorioProps,
//   numeroDaPagina,
//   totalDePaginas,
//   dataClientInfos
// }) => {
//   const { currentClient } = useContext(CurrentClientContext);
//   const { userAuthData } = useContext(AuthContext);
//   const dataAtual = new Date();
//   const anoSolicitadoNoRelatorio = dataAtual.getFullYear();
//   const horas = String(dataAtual.getHours()).padStart(2, "0");
//   const minutos = String(dataAtual.getMinutes()).padStart(2, "0");

//   if (!dadosRelatorioProps) return null;

//   console.log('oi');
//   return (
//     <>
//       <RS.DivExterna orientation={'portrait'}>
//         <RS.DivExternaCabecalhoRelatorio>
//           <RS.DivInternaCabecalho>
//             <RS.DivImageCabecalho>
//               <img
//                 alt="brasão do municipio"
//                 src={dataClientInfos ? dataClientInfos[0]?.brasao : ""}
//               />
//             </RS.DivImageCabecalho>

//             <RS.DivInfosCabecalho>
//               <h4>{`MUNICÍPIO DE ${currentClient.endereco.municipio} - ${currentClient.endereco.estado}`}</h4>
//               <p>Relatorio de verificação</p>
//               <h5>Eixo Estratégico</h5>
//             </RS.DivInfosCabecalho>
//           </RS.DivInternaCabecalho>

//           <RS.DivInternaCabecalho
//             style={{
//               display: "flex",
//               alignItems: "end",
//             }}
//           >
//             <h5>Anual {anoSolicitadoNoRelatorio}</h5>
//           </RS.DivInternaCabecalho>
//         </RS.DivExternaCabecalhoRelatorio>

//         <RS.DivRelatorio>
//           <RS.Colunas>
//             <RS.Coluna style={{ flexBasis: "125px" }}>
//               <RS.DivTituloColuna>
//                 <h6>CÓDIGO</h6>
//               </RS.DivTituloColuna>
//               <RS.SubDivColunas></RS.SubDivColunas>
//             </RS.Coluna>

//             <RS.Coluna style={{ width: "100%", flexShrink: 1 }}>
//               <RS.DivTituloColuna>
//                 <h6>TÍTULO</h6>
//               </RS.DivTituloColuna>
//               <RS.SubDivColunas></RS.SubDivColunas>
//             </RS.Coluna>

//             <RS.Coluna style={{ flexBasis: "125px" }}>
//               <RS.DivTituloColuna>
//                 <h6>QTD DIRETRIZES</h6>
//               </RS.DivTituloColuna>
//               <RS.SubDivColunas></RS.SubDivColunas>
//             </RS.Coluna>
//           </RS.Colunas>
//           <RS.InfosColunas>
//             {dadosRelatorioProps.map((objetivo) => (
//               <RS.Colunas key={objetivo.id}>
//                 <RS.DivInfosDoRelatorio
//                   style={{ width: "150px", flexShrink: 0 }}
//                 >
//                   {objetivo.codigo}
//                 </RS.DivInfosDoRelatorio>

//                 <RS.DivInfosDoRelatorio
//                   style={{ width: "100%", flexShrink: 1 }}
//                 >
//                   {objetivo.titulo}
//                 </RS.DivInfosDoRelatorio>
//                 <RS.DivInfosDoRelatorio
//                   style={{ width: "150px", flexShrink: 0 }}
//                 >
//                   {objetivo.diretrizes.length}
//                 </RS.DivInfosDoRelatorio>
//               </RS.Colunas>
//             ))}

//             <RS.DivInferiorColuna></RS.DivInferiorColuna>
//           </RS.InfosColunas>
//           <RS.RodapeRelatorio>
//             <RS.InfosDaImpressao>
//               <p>
//                 Impresso por <span>{userAuthData.nome}</span> em{" "}
//                 {ReturnDataAtual()} ás {horas}:{minutos}
//               </p>
//             </RS.InfosDaImpressao>
//             <RS.InfosGeraisRodape>
//               <p>
//                 Fonte:{" "}
//                 <span>Save – Soluções Tecnológicas – www.savest.com.br</span> ,
//                 {`${currentClient.nomeFantasia} - ${currentClient.endereco.estado}`}{" "}
//                 CNPJ: {FormatCnpjPaste(currentClient.cnpj)}
//               </p>

//               <p>
//                 Página {numeroDaPagina} de {totalDePaginas}
//               </p>
//             </RS.InfosGeraisRodape>
//           </RS.RodapeRelatorio>
//         </RS.DivRelatorio>
//       </RS.DivExterna>
//     </>
//   );
// };

// export default VisualizacaoRelatorioEixoEstrategico;
