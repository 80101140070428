const dataHeaderReceitasDespesas = [
  { text: "(a)" },
  { text: "(b)" },
  { text: "(c)" },
];
const dataHeaderSaldo = [
  { text: "(g) = ((Ia – II) + IIIh)" },
  { text: "(h) = ((Ib – IIe) + IIIi)" },
  { text: "(i) = (Ic – IIf)" },
];

export const getDemoOrigemAplicacao = async (
  clienteId,
  entidadesGestorasDoCliente,
  ano = 2024
) => {
  return {
    contentHeader: getHeader(ano),
  };
};

function getPreviousYears(year) {
  const parsedYear = parseInt(year, 10);
  const yearRange = [];

  for (let i = parsedYear - 1; i >= parsedYear - 3; i--) {
    yearRange.push(i.toString());
  }

  return yearRange;
}

const getHeader = (year) => {
  const years = getPreviousYears(year);
  const headerReceitaDespesa = formatHeaderContent(
    dataHeaderReceitasDespesas,
    years
  );
  const headerSaldo = formatHeaderContent(dataHeaderSaldo, years);

  return {
    headerReceitaDespesa,
    headerSaldo,
  };
};

const formatHeaderContent = (dataHeader, years) => {
  const headerContent = years.map((year, index) => ({
    year,
    content: dataHeader[index],
  }));
  return headerContent;
};
