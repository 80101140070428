import React from "react";
import { CCol, RowToModal } from "../../../../components/Grid/CCol";
import IconModulosHeader from "../../../../components/SvgComponents/IconModulosHeader";
import InputVoiceToText from "../../../../components/Inputs/InputVoiceToText";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

export const MotivosAba = () => {
  return (
    <>
      <RowToModal
        className="row_form mt-3"
        style={{
          borderRadius: "5px 5px 0px 0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <CCol
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              margin: 0,
              padding: 0,
              fontWeight: "600",
              color: "#515c70",
              marginLeft: "-5px",
            }}
          >
            Motivo
          </p>
        </CCol>
        <CCol style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "15px" }}>
            <IconModulosHeader savebot />
            <span style={{ color: "#6C7585" }}>SaveBot</span>
          </div>
          <div style={{ flexGrow: "1" }}>
            <InputVoiceToText />
          </div>
        </CCol>
      </RowToModal>
      <RowToModal className="row_form_2 mt-1">
        <CCol>
          <FloatingLabelInput
            tipo="text_area"
            voiceInput
            as="textarea"
            label="Descrição"
            placeholder="Descrição"
            style={{ minHeight: "98px" }}
          />
        </CCol>
      </RowToModal>
    </>
  );
};
