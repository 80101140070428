import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getDespesaDemonstrativo = async (
  clientes,
  entidadesGestoras,
  ano = 2024
) => {
  const url = getUrl(clientes, entidadesGestoras);

  try {
    const response = await api.get(
      `qdd/relatorio/agregado/${url.idsUrl}/${ano}?${url.filterConsolidado}&anexo=1&porAcao=1`
    );

    return formattedAllDespesas(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Anexo VII - Programa de Trabalho - Demonstrativo de Funções, Subfunções, Programas, Ações por Especiais, Projetos e Atividades",
    };
  }
};

function sortByCodigoFuncao(data) {
  const dataArray = Object.values(data);
  dataArray.sort((a, b) => {
    return a.codigoFuncao.localeCompare(b.codigoFuncao);
  });
  console.log(dataArray);

  return dataArray;
}

const formattedAllDespesas = (dataQddRelatorioOrgaoUnidade) => {
  console.log(dataQddRelatorioOrgaoUnidade);

  const dadosFormatados = Object.keys(dataQddRelatorioOrgaoUnidade).map(
    (key) => {
      const unidade = dataQddRelatorioOrgaoUnidade[key];
      return {
        infoUnidade: {
          idUnidade: unidade.clienteInfo[0].id,
          tipoNaturezaJuridica: unidade.clienteInfo[0].tipoNaturezaJuridica,
          unidadeGestora: unidade.clienteInfo[0].pessoaJuridica.nomeFantasia,
          totalGeralUnidade: {
            totalProjetosCliente: unidade.totalProjetosCliente,
            totalAtividadesCliente: unidade.totalAtividadesCliente,
            totalEspeciaisCliente: unidade.totalEspeciaisCliente,
          },
          unidades: sortByCodigoFuncao(unidade.relatorio),
        },
      };
    }
  );

  const orderJson = (data) => {
    data.forEach((unidadeInfo) => {
      const unidade = unidadeInfo.infoUnidade;

      // Organizar as funções pela propriedade codigoFuncao
      unidade.unidades.sort((a, b) =>
        a.codigoFuncao.localeCompare(b.codigoFuncao)
      );

      unidade.unidades.forEach((funcao) => {
        // Organizar as subFuncoes pela propriedade codigoSubFuncao
        funcao.subFuncoes = Object.fromEntries(
          Object.entries(funcao.subFuncoes).sort((a, b) =>
            a[0].localeCompare(b[0])
          )
        );

        Object.values(funcao.subFuncoes).forEach((subFuncao) => {
          // Organizar os programas pela propriedade codigoPrograma
          subFuncao.programas = Object.fromEntries(
            Object.entries(subFuncao.programas).sort((a, b) =>
              a[0].localeCompare(b[0])
            )
          );

          Object.values(subFuncao.programas).forEach((programa) => {
            // Organizar as acoes pela propriedade nAcao
            programa.acoes = Object.fromEntries(
              Object.entries(programa.acoes).sort((a, b) =>
                a[0].localeCompare(b[0])
              )
            );
          });
        });
      });
    });

    return data;
  };

  const orderedJson = orderJson(dadosFormatados);

  console.log("dadosFormatados: ", dadosFormatados, orderedJson);

  // dadosFormatados.sort((a, b) => {
  //   const tipoA = parseInt(a.infoUnidade.tipoNaturezaJuridica[0]);
  //   const tipoB = parseInt(b.infoUnidade.tipoNaturezaJuridica[0]);
  //   return tipoA - tipoB;
  // });

  return {
    dataDespesa: orderedJson,
    total: 0,
  };
};
