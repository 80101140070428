import api from "../../../../../../utils/api";

export const getDemoRiscosFiscaisProvidencias = async (
  clienteId,
  entidadesGestorasDoCliente,
  ano = 2024
) => {
  try {
    const response = await api.get(`riscosFiscais/relatorio/${clienteId}`);
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Demonstrativo de Riscos Fiscais e Providências - Anexos Riscos Fiscais",
    };
  }
};

const ordem = [
  "Demandas Judiciais - PASSIVOS CONTINGENTES",
  "Dívidas em Processo de Reconhecimento - PASSIVOS CONTINGENTES",
  "Avais e Garantias Concedidas - PASSIVOS CONTINGENTES",
  "Assunção de Passivos - PASSIVOS CONTINGENTES",
  "Assistências Diversas - PASSIVOS CONTINGENTES",
  "Outros Passivos Contingentes - PASSIVOS CONTINGENTES",
  "Frustração de Arrecadação - DEMAIS RISCOS FISCAIS PASSIVOS",
  "Restituição de Tributos a Maior - DEMAIS RISCOS FISCAIS PASSIVOS",
  "Discrepância de Projeções - DEMAIS RISCOS FISCAIS PASSIVOS",
  "Outros Riscos Fiscais - DEMAIS RISCOS FISCAIS PASSIVOS",
];

export const processAllData = (data) => {
  console.log(data);
  const filterData = () => {
    const passivosContingentes = data?.riscosFiscais?.filter((item) =>
      item?.especificacao.endsWith("PASSIVOS CONTINGENTES")
    );
    const passivosContingentesOrganizados = passivosContingentes.sort(
      (a, b) => {
        return ordem.indexOf(a.especificacao) - ordem.indexOf(b.especificacao);
      }
    );
    const demaisRFPassivos = data?.riscosFiscais?.filter((item) =>
      item?.especificacao.endsWith("DEMAIS RISCOS FISCAIS PASSIVOS")
    );
    const demaisRFPOrganizados = demaisRFPassivos.sort((a, b) => {
      return ordem.indexOf(a.especificacao) - ordem.indexOf(b.especificacao);
    });

    const newJson1 = [];
    passivosContingentesOrganizados.forEach((item) => {
      newJson1.push({
        title1: item?.especificacao.replace(" - PASSIVOS CONTINGENTES", ""),
        title2: "",
        value1: item?.totalProvidencias,
        value2: item?.totalProvidencias,
      });
    });
    newJson1.push({
      title1: "SUBTOTAL",
      title2: "SUBTOTAL",
      value1: data?.subtotalPassivosContingentes,
      value2: data?.subtotalPassivosContingentes,
    });

    const newJson2 = [];
    demaisRFPOrganizados.forEach((item) => {
      newJson2.push({
        title1: item?.especificacao.replace(
          " - DEMAIS RISCOS FISCAIS PASSIVOS",
          ""
        ),
        title2: "",
        value1: item?.totalProvidencias,
        value2: item?.totalProvidencias,
      });
      item?.providencias.forEach((item) => {
        item?.descricao !== "" &&
          newJson2.push({
            title1: "  • " + item?.descricao || "",
            title2: "",
            value1: item?.valor,
            value2: item?.valor,
          });
      });
    });
    newJson2.push({
      title1: "SUBTOTAL",
      title2: "SUBTOTAL",
      value1: data?.subtotalDemaisRiscos,
      value2: data?.subtotalDemaisRiscos,
    });

    return {
      part1: newJson1,
      part2: newJson2,
    };
  };
  const newData = filterData();
  const filteredData = {
    data: newData,
    sum: { total1: data?.totalGeral, total2: data?.totalGeral },
    fonte: data.fonte.titulo,
  };
  console.log(filteredData);
  return filteredData;
};
