export const DefinirRequisicaoDoModalComoConluida = (
  openedModals,
  setOpenedModals,
  tituloModal
) => {
  let newArrayModals = [...openedModals];

  //encontrando o objeto que representa o modal da pagina atual
  const modalAcao = openedModals.find(
    (item) => item.tituloModal === tituloModal
  );
  if (modalAcao) {
    //encontrando o index desse objeto
    let index = newArrayModals.findIndex((item) => item === modalAcao);

    //mudando o estado do atributo para "true"
    newArrayModals[index].requisicaoDoModalConcluida = true;

    setOpenedModals([...newArrayModals]);
  }
};
