import { createGlobalStyle } from "styled-components";
// import CalibriWoff2 from  '../../assets/Fonts/Calibri/Calibri.woff2'
// import CalibriWoff from  '../../assets/Fonts/Calibri/Calibri.woff'

// import RobotoRegular from '../../assets/Fonts/Roboto/Roboto-Regular.woff'
// import RobotoRegularWoff2 from '../../assets/Fonts/Roboto/Roboto-Regular.woff2'
// @font-face {
//   font-family: 'Roboto';
//   src: local('Roboto'), local('Roboto'),
//        url(${RobotoRegular}) format('woff'),
//        url(${RobotoRegularWoff2}) format('woff2');
//   font-weight: normal;
//   font-style: normal;
// }

const GlobalStyles = createGlobalStyle`
   
  @media print {
    @page {
      size: ${(props) =>
        props.orientation === "landscape" ? "29.7cm 21cm" : "21cm 29.7cm"};
      position: relative;
      margin: 20px;
    }
    body {
      margin: 0;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
  }
`;

export default GlobalStyles;