import {
  Table,
  TableRow,
  TableCell,
  Paragraph,
  WidthType,
  ImageRun,
  TextRun,
  TableLayoutType,
  AlignmentType,
  BorderStyle,
} from "docx";
import defaultImg64 from "../../../../../../utils/RelatorioDocx/defaultImg64";

const isBase64 = (str) => {
  const isBase64 = /^data:'?image\/[a-z]+';base64,[a-zA-Z0-9+/=]+$/.test(str);
  if (!isBase64) {
    return [false, false];
  }
  const base64Content = str.split(",")[1];
  const lengthIsValid = base64Content.length % 4 === 0;
  const paddingIsValid =
    base64Content.endsWith("=") || !base64Content.includes("=");
  return [isBase64, lengthIsValid && paddingIsValid];
};

const getHeaderText = (text, piece) => {
  if (text && text.includes("/")) {
    const parts = text.split("/");
    return parts.length > piece ? parts[piece].trim() : "";
  }
  return text;
};

const createDataRows = (data, columns, font) => {
  return data.map((item) => {
    const values = Object.values(item);
    return new TableRow({
      children: columns.map((_, colIndex) => {
        const cellValue = values[colIndex] && values[colIndex].toString();
        const contentBase64 = isBase64(cellValue);

        return new TableCell({
          children: [
            cellValue && contentBase64[0]
              ? new Paragraph({
                  children: [
                    new ImageRun({
                      data: contentBase64[1] ? cellValue : defaultImg64,
                      transformation: {
                        width: 50,
                        height: 50,
                      },
                      font: font,
                    }),
                  ],
                  spacing: {
                    before: 50,
                    after: 50,
                  },
                })
              : new Paragraph({
                  children: [
                    new TextRun({
                      text: cellValue || "",
                      size: 9 * 2,
                      font: font,
                    }),
                  ],
                  spacing: {
                    before: 20,
                    after: 50,
                  },
                  indent: {
                    left: colIndex === 0 ? 75 : 0,
                  },
                }),
          ],
          borders: {
            bottom: { style: BorderStyle.SINGLE, size: 1, space: 0 },
            top: { style: BorderStyle.NONE, size: 0, space: 0 },
            left: { style: BorderStyle.NONE, size: 0, space: 0 },
            right: { style: BorderStyle.NONE, size: 0, space: 0 },
            insideHorizontal: { style: BorderStyle.NONE, size: 0, space: 0 },
            insideVertical: { style: BorderStyle.NONE, size: 0, space: 0 },
          },
        });
      }),
    });
  });
};

export const DocxTableParametrosIndices = ({
  columns,
  data,
  font = "Times New Roman",
  subTitle = "",
  piece = 0,
  columnWidth = [10, 50, 40],
}) => {
  console.log("DocxTableParametrosIndices foi chamado");
  console.log("columns: ", columns);
  console.log("data: ", data);
  console.log("font: ", font);
  console.log("subTitle: ", subTitle);
  console.log("piece: ", piece);
  console.log("columnWidth: ", columnWidth);
  const headerCells = columns.map((header, index) => {
    return new TableCell({
      width: {
        size: columnWidth[index],
        type: WidthType.PERCENTAGE,
      },
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: subTitle ? getHeaderText(header, piece) : header,
              bold: true,
              color: "FFFFFF",
              size: 10 * 2,
              font: font,
            }),
          ],
          spacing: {
            before: 250,
            after: 250,
          },
          alignment: AlignmentType.LEFT,
          indent: {
            left: index === 0 ? 75 : 0,
          },
        }),
      ],
      shading: {
        fill: "515c70",
      },
    });
  });

  const createHeaderLine = (subTitle) => {
    return columns.map(
      (_, index) =>
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: index === 0 ? subTitle : "",
                  bold: true,
                  size: 8 * 2,
                  font: font,
                }),
              ],
              alignment: AlignmentType.LEFT,
              indent: {
                left: index === 0 ? 75 : 0,
              },
              spacing: {
                before: 120,
              },
            }),
          ],
          shading: {
            fill: "a6a6a6",
          },
        })
    );
  };

  const createEmptyRow = (color) =>
    new TableRow({
      children: columns.map(
        () =>
          new TableCell({
            children: [
              new Paragraph({
                children: [new TextRun({ text: "", font: font })],
              }),
            ],
            spacing: {
              before: 120,
            },
            shading: {
              fill: color,
            },
          })
      ),
    });

  const dataRows0 =
    data[0].length !== 0 && createDataRows(data[0], columns, font);
  const dataRows1 =
    data[1].length !== 0 && createDataRows(data[1], columns, font);

  const tableRows = [
    new TableRow({
      children: headerCells,
      tableHeader: true,
    }),
    ...(data[0] && data[0].length > 0
      ? [
          new TableRow({
            children: createHeaderLine("Parâmetros"),
            tableHeader: true,
          }),
          ...dataRows0,
        ]
      : []),
    ...(data[1] && data[1].length > 0
      ? [
          new TableRow({
            children: createHeaderLine("Índices"),
            tableHeader: true,
          }),
          ...dataRows1,
        ]
      : []),
    createEmptyRow("515c70"),
  ];

  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    layout: TableLayoutType.FIXED,
    rows: tableRows,
    borders: {
      top: { style: BorderStyle.NONE, size: 0, space: 0 },
      bottom: { style: BorderStyle.NONE, size: 0, space: 0 },
      left: { style: BorderStyle.NONE, size: 0, space: 0 },
      right: { style: BorderStyle.NONE, size: 0, space: 0 },
      insideHorizontal: { style: BorderStyle.NONE, size: 0, space: 0 },
      insideVertical: { style: BorderStyle.NONE, size: 0, space: 0 },
    },
  });
};
