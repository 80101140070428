import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import NavbarSubtituloIcon from "./NavbarSubtituloIcon";

const SideBarContainer = styled.div`
  padding-top: 67px;
  width: 270px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #fafafa;
  border-right: 2px solid #efefef;
  transition: all 1s;
  margin-right: 10px;
  margin-left: ${({ checkSidebar }) => (checkSidebar ? "-210px" : "")};
`;

export const TitulosNavs = styled.h5`
  font-weight: bold;
  margin-left: 15px;
  margin-top: 12px;
  color: #9399a4;
  font-size: 0.7em;
  text-transform: uppercase;
`;

const Subtitulo = styled.div`
  padding: 4px;
  height: 32px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  background: ${({ background }) => background};
  margin-top: -4px;
  border-radius: 0px 0px 15px 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  ${({ checkSidebar }) => {
    if (checkSidebar) {
      return css`
        position: absolute;
        width: 60px;
        right: 0;
        border-radius: 0px 0px 8px 8px;
      `;
    }
  }}

  span {
    position: relative;
    font-size: 12px;
    display: ${({ checkSidebar }) => (checkSidebar ? "none" : "")};
  }
`;

const NavItems = styled.div`
  margin-top: ${({ checkSidebar }) => (checkSidebar ? "45px" : "20px")};
  height: calc(100% - 60px);
  overflow-y: ${(props) => (props.checkSidebar ? "visible" : "auto")};

  ::-webkit-scrollbar {
    width: 2px; /* Largura da barra de rolagem */
  }

  /* Estilizando o track (trilha) da barra de rolagem */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Cor de fundo do track */
  }

  /* Estilizando o thumb (ponteiro) da barra de rolagem */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Cor do thumb */
  }

  /* Estilizando o thumb ao passar o mouse por cima */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Cor do thumb ao passar o mouse por cima */
  }

  a {
    display: block;
    width: ${(props) => (props.checkSidebar ? "100%" : "250px")};
    font-size: 18px;
    text-decoration: none;
    color: #515c70;
    padding-left: 15px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: 500;
  }

  a:hover {
    background-color: #8b8c900d;
    border-radius: ${(props) => (props.checkSidebar ? "0px" : "0 10px 10px 0")};
  }

  a.active {
    color: #fff;
    background-color: #515c70;
    font-weight: 600;
    border-radius: ${(props) => (props.checkSidebar ? "0px" : "0 10px 10px 0")};
  }

  svg {
    color: #515c70;
    position: relative;
    margin-right: 5px;
  }

  a.active svg {
    color: #fff;
  }

  a.link_direto > span {
    display: ${(props) => (props.checkSidebar ? "none" : "")};
  }

  //Buttons

  button {
    display: flex;
    align-items: center;
    width: ${(props) => (props.checkSidebar ? "100%" : "250px")};
    text-decoration: none;
    color: #515c70;
    font-weight: 500;
    background-color: transparent;
    text-align: start;
    padding: 6px 0 6px 15px;
    transition: background-color 0.1s;
    font-size: 17px;
    border: none;
    border-radius: ${(props) => (props.checkSidebar ? "0px" : "0 10px 10px 0")};
  }

  button > svg,
  a > svg {
    left: ${(props) => (props.checkSidebar ? "212.5px" : "0")};
  }

  button:hover {
    background-color: #8b8c900d;
    font-weight: 500;
  }

  button span svg {
    translate: 0px -50%;
    position: absolute;
    left: 160px;
    top: 50%;
    transition: all 0.3s;
  }

  button span p {
    margin: 0;
  }

  button span {
    margin-left: 3px;
    position: relative;
    display: ${(props) => (props.checkSidebar ? "none" : "auto")};
  }

  button.active {
    color: #fff;
    background-color: #515c70;
    font-weight: 600;
  }

  button.active svg {
    color: #fff;
  }

  button span svg.active {
    transform: translateY(-2px) rotate(90deg);
  }

  ul {
    list-style: none;
  }

  ul > li {
    margin-left: -32px;
  }

  ul > li > a {
    font-size: 14px;
  }

  ul > li > a.active {
    background-color: transparent;
    color: #515c70;
  }

  ul > li > a.active > svg {
    color: #515c70;
  }

  ul > li > a:hover {
    background-color: transparent;
    font-weight: 600;
  }
`;

export const NavLinksMenores = styled.div`
  ${({ checkSidebar }) => {
    if (!checkSidebar) {
      return css`
        a {
          /* padding-left: 35px; */
          font-size: 16px;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      `;
    }
  }}
`;

const SideBarComponent = ({ checkSidebar, NavComponent, nomeDoModulo }) => {
  const [backgroundSubtitulo, setBackgroundSubtitulo] = useState("");

  const handleModulo = () => {
    let backgroundColor = "";

    switch (nomeDoModulo.toLowerCase()) {
      case "gerenciador":
        backgroundColor = "#515c70";
        break;
      case "planejamento":
        backgroundColor = "#f6991e";
        break;
      case "contabilidade":
        backgroundColor = "#da499a";
        break;
      default:
        backgroundColor = "#515c70";
        break;
    }

    setBackgroundSubtitulo(backgroundColor);
  };

  useEffect(() => {
    if (nomeDoModulo) {
      handleModulo();
    }

    //eslint-disable-next-line
  }, [nomeDoModulo]);

  return (
    <SideBarContainer checkSidebar={checkSidebar}>
      <Subtitulo checkSidebar={checkSidebar} background={backgroundSubtitulo}>
        <NavbarSubtituloIcon
          nomeDoModulo={nomeDoModulo}
          checkSidebar={checkSidebar}
        />
      </Subtitulo>
      <NavItems checkSidebar={checkSidebar}>{NavComponent}</NavItems>
    </SideBarContainer>
  );
};

export default SideBarComponent;
