export const getUrl = (clienteId, entidadesGestoras) => {
  const prefeitura = clienteId.consolidacao
    ? entidadesGestoras
        .flatMap((item) => item.checkBoxes)
        .find(
          (checkBox) =>
            checkBox.selected &&
            checkBox.dados.tipoNaturezaJuridica.startsWith("2 - Prefeitura")
        )
    : "";

  const prefeituraId = prefeitura ? prefeitura?.dados?.clienteId : "";
  console.log(prefeituraId);

  const idsUrl = prefeituraId || clienteId.ids.join(",");
  const filterConsolidado =
    clienteId.consolidacao && prefeituraId ? "consolidado=1" : "";

  return {
    filterConsolidado,
    idsUrl,
  };
};