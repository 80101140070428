import React, { useState } from "react";
import { ContainerPag } from "../../../../components/ContainerPag/ContainerPag";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import { CCol10PorCento } from "../../../../styles/StyledComponents/CCol10PorCento";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { SaveChatBotAba } from "../../../../components/SaveChatBotAba/SaveChatBotAba";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";
const InscricaoRp = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });

  return (
    <ContainerPag>
      <HeaderCadastros
        NomePaginaTitulo={"Inscrição RP"}
        PaginaSubtitulo={"Cadastro"}
      />

      <RowForm className="mt-1">
        <CCol10PorCento md={1}>
          <FloatingLabelInput disabled placeholder="Número" label="Número" />
        </CCol10PorCento>
        <CCol10PorCento md={1}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Ano"
          />
        </CCol10PorCento>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Empenho"
            style={{ height: "37px" }}
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <InputData disabled />
        </CCol>
        <CCol>
          <FloatingLabelInput
            placeholder="Valor Inscrito"
            label="Valor Inscrito"
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            placeholder="Valor Processado"
            label="Valor Processado"
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            placeholder="Valor Não Processado"
            label="Valor Não Processado"
          />
        </CCol>
      </RowForm>
      <RowInfosAcao>
        <CCol md={7}>
          <p>
            Unidade Orçamentária:{" "}
            <span>02.050 - Secretaria Municipal de Educação</span>
          </p>
          <p>
            Programa:{" "}
            <span>
              2025 - Manutenção dos Serviços da Secretaria Municipal de Educação
            </span>
          </p>
          <p>
            Ação:{" "}
            <span>
              2025 - Manutenção dos Serviços da Secretaria Municipal de Educação
            </span>
          </p>
          <p>
            Fonte de Recursos - CO:{" "}
            <span>1500.1001 - Recursos Vinculados da Educação</span>
          </p>
        </CCol>
        <CCol>
          <p>
            Função: <span>08 - Assistência Social</span>
          </p>
          <p>
            Subfunção: <span>244 - Assistência Comunitária</span>
          </p>
          <p>
            Elemento:{" "}
            <span>3.3.90.39 - Outros Serviços Pessoa Jurídica - PJ</span>
          </p>
          <p>
            Subelemento:{" "}
            <span>3.3.90.39 - Outros Serviços Pessoa Jurídica - PJ</span>
          </p>
        </CCol>
      </RowInfosAcao>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Fonte de Recursos - CO "
            style={{ height: "37px" }}
          />
        </CCol>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={["Histórico", "Produtos e Aquisição"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Histórico" && <SaveChatBotAba />}

      <div className="p-3"></div>
    </ContainerPag>
  );
};

export default InscricaoRp;
