import styled from "styled-components";

export const DivPrintOnly = styled.div`
  display: none;

  @page {
    /* margin: 15px; */
    /* size: 21cm 29.7cm ; A4 em orientação retrato */
  }

  @media print {
    display: block;
  }
`;

export const RodapeRelatorio = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 7pt;
  /* padding-top: 100px; */
  /* position: fixed; */

  /* bottom: 0;   */
  width: 100%;

  p > span {
    font-weight: bold;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

export const InfosDaImpressao = styled.div`
  display: flex;
  justify-content: end;

  p > span {
    text-transform: uppercase;
    font-weight: 400;
  }
`;

export const InfosGeraisRodape = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #000;
`;

export const DivHideOnPrint = styled.div`
  visibility: visible;

  @media print {
    visibility: hidden;
  }
`;

export const DivExterna = styled.div`
  /* position: relative; */
  /* z-index: 100; */
  width: 100%;
  /* margin-top: 100px; */
  /* overflow-x: hidden; */
  background-color: #f2f3f8;
  @media print {
    /* height: 100vh; */
    box-sizing: border-box;
    /* left: -210px; */
    /* width: ${({ orientation }) =>
      orientation === "portrait" ? "1080px" : "1540px"}; */

    background-color: #fff;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

export const DivRelatorio = styled.div`
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
`;

export const Colunas = styled.div`
  display: flex;
  gap: 2px;
  /* border-bottom: 1px solid #000000; */
`;

export const DivTituloColuna = styled.div`
  background-color: #515c70;
  text-align: center;
  padding: 28px 0px;

  h6 {
    white-space: nowrap;
    margin: 0;
    padding: 0;
    color: #fff;
  }
`;

export const SubDivColunas = styled.div`
  background-color: #a6a6a6;
  padding: 3px 0px;
  min-height: 24px;
  text-align: center;
  /* max-height: 24px; */

  /* h6 {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-size: 12px;
  } */
  /* @media print {
    h6 {
      font-size: 0.95em;
    }
  } */
`;

export const DivInfosDoRelatorio = styled.div`
  padding: 0px 8px;

  p {
    margin: 0;
    padding: 0;
    font-size: 9pt;
  }
`;

export const Coluna = styled.div`
  flex-shrink: 0;
`;

export const InfosColunas = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivInferiorColuna = styled.div`
  min-height: 15px;
  background-color: #515c70;
  padding: 2px 10px 2px 10px;

  h6 {
    margin: 0;
    padding: 0;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
  }

  /* @media print {
    h6 {
      font-size: 0.95em;
    }
  } */
`;

export const DivExternaCabecalhoRelatorio = styled.div`
  /* margin-bottom: 10px; */

  /* display: flex;
  justify-content: space-between; */
  border-bottom: 1px solid #000;
  height: 90px;
  /* position: fixed; */
  z-index: 100;
  /* top: 0; */
  width: 100%;
  /* @media print {
    @page {

    }
  } */
`;

export const DivInternaCabecalho = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  /* h4 {
    text-transform: uppercase;
    margin: 0px;
    padding: 0px;
    font-size: 11pt;
    font-family: 'Roboto';
    font-weight: bold;
    background-color: red;
  } */

  p {
    margin: 0px;
    padding: 0px;
    font-size: 9pt;
    font-family: "Roboto";
    text-transform: capitalize;
  }

  h5 {
    margin: 0px;
    padding: 0px;
    font-size: 9pt;
    font-family: "Roboto";
  }
`;
export const TitleCabecalho = styled.p`
  margin: 0px;
  padding: 0px;
  font-family: "Roboto";
  font-weight: bold;
  padding-top: 5pt;
`;

export const DivImageCabecalho = styled.div`
  max-width: 85px;
  max-height: 85px;
  /* flex-basis: 150px;
  flex-shrink: 0; */
  overflow: hidden;
  /* margin-right: 20px; */

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivInfosCabecalho = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-top: 10px;
`;

export const ContainerRelatorio = styled.div`
  width: 100vw;
  position: relative;
  left: -210px;
  font-family: "Roboto";
`;
export const LineInfosRelatorio = styled.div`
  height: 1px;
  width: 100%;
  background-color: #a6a6a6;
`;

export const PageHeaderSpace = styled.div`
  height: 90px;
  margin-bottom: 10px;
`;

export const PageHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`;

export const PageFooterSpace = styled.div`
  height: 30px;
`;

export const PageFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export const TextBar = styled.p`
  font-weight: bold;
  color: #000;
  font-size: 9pt;
  text-transform: uppercase;
  font-family: "Roboto";
`;

export const HeaderColuna = styled.div`
  background-color: #515c70;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50pt;
  padding: 4pt;
  p {
    font-size: 11pt;
    text-transform: capitalize;
    color: #fff;
    font-weight: bold;
    font-family: "Roboto";
  }
`;

export const BarTitleInfos = styled.div`
  /* min-height: 24px;
  height: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
  padding: 0px 0px 2px;
  p {
    font-weight: bold;
    color: #000;
    font-size: 9pt;
    text-transform: capitalize;
    font-family: "Roboto";
  }
`;

export const DivTitleInfos = styled.div`
  display: flex;
  gap: 16px;
  min-width: 85%;
  max-width: 85%;
  width: 100%;
  background-color: #a6a6a6;
  padding: 0px 4px;
  p {
    font-family: "Roboto";
  }
`;

export const TotalTitleInfo = styled.p`
  white-space: nowrap;
  background-color: #a6a6a6;
  flex: 1;
  text-align: end;
  display: inline-block;
  font-family: "Roboto";
`;

export const InfosRelatorio = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 2px;

  p {
    font-size: 9pt;
    text-transform: capitalize;
    color: #000;
    font-family: "Roboto";
  }
`;

export const SubTitleInfos = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  padding: 2px 4px;
  p {
    font-weight: bold;
    color: #000;
    font-size: 9pt;
    text-transform: capitalize;
    font-family: "Roboto";
  }
`;

export const InfoTotal = styled.p`
  font-weight: bold;
  font-family: "Roboto";
  font-size: 9pt;
  text-transform: uppercase;
  text-align: end;
  color: #000;
`;
export const TextFiltro = styled.p`
  margin: 0px;
  padding: 0px;
  font-size: 9pt;
  width: 100%;
  text-align: end;
  font-family: "Roboto";
`;
export const TextCodigoQdd = styled.div`
  max-width: 14%;
  width: 100%;
  text-align: end;
  padding: 0px 4px;
  font-family: "Roboto";
`;
export const TextTituloQdd = styled.div`
  width: 100%;
  padding-left: 4px;
  font-family: "Roboto";
`;
export const TextTotalQdd = styled.p`
  max-width: 15%;
  width: 100%;
  text-align: end;
  font-family: "Roboto";
`;
