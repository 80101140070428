import React, { useState, useEffect, useContext } from "react";
import {
  CCol,
  CColButtonsAcoes,
  ContainerToModal,
  RowToModal,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { ToastContainer, toast } from "react-toastify";
import {
  RowItemListaDeInputs,
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import CadastroDiretriz from "../Diretriz/CadastroDiretriz";
import { Icon } from "@iconify/react";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../../utils/api";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { OpenedModalsContext } from "../../../../contexts/OpenedModalsContext";
import ModalAutoComplete from "../../../../components/Modais/ModalAutoComplete";
import ButtonSaveModalAutoComplete from "../../../../components/Modais/ButtonSaveModalAutoComplete";
import { DefinirRequisicaoDoModalComoConluida } from "../../../../utils/Gerais/DefinirRequisicaoDoModalComoConcluida";
import { CurrentYearContext } from "../../../../contexts/YearContext";

// const PTituloLista = styled.p`
//   padding: 0px;
//   margin-top: -10px;
//   margin-bottom: 0px;
//   color: #515c70;
//   font-weight: 600;
//   font-size: 18px;
// `;

function EixoEstrategico({ isAModalPage = false }) {
  const { currentYear } = useContext(CurrentYearContext);
  const { currentClient } = useContext(CurrentClientContext);
  const { openedModals, setOpenedModals } = useContext(OpenedModalsContext);
  const { codigo: paramsCodigo } = useParams();

  const [modalOpened, setModalOpened] = useState(false);

  const [dataDelete, setDataDelete] = useState({});

  const navigate = useNavigate();

  const [eixoValues, setEixoValues] = useState({
    ano: currentYear,
    clienteId: currentClient.clienteId,
    codigo: "",
    titulo: "",
    diretrizes: [],
  });
  const [options, setOptions] = useState([]);
  const [inputAutoCompleteValue, setInputAutoComplete] = useState("");
  const [codigoDiretriz, setCodigoDiretriz] = useState({ codigo: "" });
  const [valuesDiretriz, setValuesDiretriz] = useState({
    demanda: "",
    exercicio: "",
  });
  const [arrayDiretrizes, setArrayDiretrizes] = useState([]);
  const [condicaoDelete, setCondicaoDelete] = useState(false);
  const [parametrosCarregados, setParametrosCarregados] = useState(
    paramsCodigo !== undefined ? false : true
  );

  const [countClientChange, setCountClientChange] = useState(0);

  const [eixoAtualizacaoValues, setEixoAtualizacaoValues] = useState({
    titulo: "",
    novasDiretrizes: [],
  });

  const checkClient = true;

  useEffect(() => {
    if (paramsCodigo && !isAModalPage) {
      fetchEixoEstrategico();
    } else {
      fetchNEixo();
    }
    //eslint-disable-next-line
  }, [paramsCodigo, isAModalPage]);

  useEffect(() => {
    setCondicaoDelete(false);
    if (!condicaoDelete && parametrosCarregados) {
      fetchSingleDiretriz(false);
    }

    //eslint-disable-next-line
  }, [eixoValues.diretrizes]);

  useEffect(() => {
    setCountClientChange((prevValue) => prevValue + 1);
    console.log("passou pelo if do navigate");
    if (countClientChange === 2) {
      navigate("/planejamento/cadastro/eixo-estrategico/consulta");
    }
    //eslint-disable-next-line
  }, [currentClient]);

  const fetchNEixo = () => {
    api
      .get(
        `eixoEstrategicoPlanejamento/${currentClient.clienteId}/${currentYear}`
      )
      .then((response) => {
        const existingCodes = response.data.map((item) => item.codigo);

        let nextCode = 1;
        while (existingCodes.includes(nextCode)) {
          nextCode++;
        }
        setEixoValues((prevValues) => ({
          ...prevValues,
          codigo: nextCode,
        }));
      })
      .catch((error) =>
        toast.error(
          error.response.data.message ||
            "Algo deu errado ao carregar o número do eixo"
        )
      );
  };

  const fetchEixoEstrategico = () => {
    api
      .get(
        `eixoEstrategicoPlanejamento/${currentClient.clienteId}/${paramsCodigo}`
      )
      .then((resp) => {
        console.log(resp.data);

        //Setando os valores do eixo Values
        let arrayDiretrizesParams = resp.data.diretrizes.map(
          (item) => item.codigo
        );
        console.log(arrayDiretrizesParams);
        setEixoValues((prevValues) => ({
          ...prevValues,
          titulo: resp.data.titulo,
          codigo: resp.data.codigo,
          diretrizes: arrayDiretrizesParams,
        }));

        setEixoAtualizacaoValues((prevValues) => ({
          ...prevValues,
          titulo: resp.data.titulo,
        }));

        //Setando os valores do array diretriz
        let arrayDiretrizesListaParams = resp.data.diretrizes.map((objeto) => {
          const {
            audiencia: demanda,
            bairro,
            cep,
            dataDiretriz,
            descricaoAudiencia,
            id,
            ...resto
          } = objeto;
          return { demanda, ...resto };
        });
        setArrayDiretrizes(arrayDiretrizesListaParams);

        setParametrosCarregados(true);
      });
  };

  const deleteDiretriz = () => {
    setCondicaoDelete(true);
    console.log("Função de deletar");

    //Primeiro deletar o objeto do array de diretrizes
    let arrayDiretrizesDeleta = [...arrayDiretrizes];
    arrayDiretrizesDeleta = arrayDiretrizesDeleta.filter((item) => {
      return item.codigo !== dataDelete.codigo;
    });
    console.log(arrayDiretrizesDeleta);
    setArrayDiretrizes(arrayDiretrizesDeleta);

    //Depois deletar o valor em eixo values
    let arrayEixoValuesDiretrizesDeleta = [...eixoValues.diretrizes];
    arrayEixoValuesDiretrizesDeleta = arrayEixoValuesDiretrizesDeleta.filter(
      (item) => {
        return item !== dataDelete.codigo;
      }
    );
    setEixoValues((prevValues) => ({
      ...prevValues,
      diretrizes: arrayEixoValuesDiretrizesDeleta,
    }));
    // setCondicaoDelete(false);
  };

  const handleAutocompleteChange = (option) => {
    if (option) {
      //AQUIIIIIIIIIIIIIIIIII
      console.log("entrei aqui: autocomplete");
      console.log("Codigo da diretriz: " + option.codigo);
      setCodigoDiretriz((prevValues) => ({
        ...prevValues,
        codigo: option.codigo,
      }));
    } else {
      console.log("");
    }
  };

  useEffect(() => {
    if (codigoDiretriz !== "") {
      console.log("vai checar");
      fetchSingleDiretriz(true);
    }
    //eslint-disable-next-line
  }, [codigoDiretriz]);

  const adicionaDiretriz = () => {
    console.log("clicked");

    let arrayDiretrizes = [...eixoValues.diretrizes];

    if (
      codigoDiretriz.codigo !== undefined &&
      codigoDiretriz.codigo !== "" &&
      !eixoValues.diretrizes.includes(codigoDiretriz.codigo)
    ) {
      arrayDiretrizes.push(codigoDiretriz.codigo);
      console.log("array de diretrizes ------");
      console.log(arrayDiretrizes);
      console.log("--------------------------");
      setEixoValues((prevValues) => ({
        ...prevValues,
        diretrizes: arrayDiretrizes,
      }));

      if (paramsCodigo !== undefined) {
        //Valores para atualizacao
        let arrayParaAtualizacaoDiretrizes = [
          ...eixoAtualizacaoValues.novasDiretrizes,
        ];
        arrayParaAtualizacaoDiretrizes.push(codigoDiretriz.codigo);
        setEixoAtualizacaoValues((prevValues) => ({
          ...prevValues,
          novasDiretrizes: arrayParaAtualizacaoDiretrizes,
        }));
        console.log("array de diretrizes ATUALIZADAS ------");
        console.log(arrayParaAtualizacaoDiretrizes);
        console.log("--------------------------");
      }

      limparDadosDiretriz();
    } else if (
      codigoDiretriz.codigo === undefined ||
      codigoDiretriz.codigo === ""
    ) {
      toast.warning("Informe uma diretriz válida.");
    } else if (eixoValues.diretrizes.includes(codigoDiretriz.codigo)) {
      toast.warning("Essa diretriz ja foi adicionada anteriormente.");
    }
  };

  const limparDadosDiretriz = () => {
    setInputAutoComplete("");
    // setOptions([]);
    setValuesDiretriz((prevValues) => ({
      ...prevValues,
      demanda: "",
      exercicio: "",
    }));
  };

  const limparCampos = () => {
    setEixoValues({
      clienteId: currentClient.clienteId,
      codigo: "",
      titulo: "",
      diretrizes: [],
    });

    setValuesDiretriz({
      demanda: "",
      exercicio: "",
    });

    setEixoAtualizacaoValues({
      titulo: "",
      novasDiretrizes: [],
    });

    setInputAutoComplete("");
    // setOptions([]);
    setArrayDiretrizes([]);
    setCodigoDiretriz({ codigo: "" });

    fetchNEixo();
  };

  //A funcao fetchSingleDiretriz esta funcionando tanto para fazer o autoComplete quanto para adicionar um item no
  //array de diretrizes, a condicao ao ser enviada com True preenche o valor da diretriz do autocomplete
  //ao ser enviada como false, adiciona um objeto com seus atributos ao array de diretrizes
  const fetchSingleDiretriz = (condicao) => {
    api
      .get(
        `diretrizPlanejamento/por-cliente/${currentClient.clienteId}/${currentYear}`
      )
      .then((resp) => {
        console.log(resp.data);
        console.log("fazendo o fetch da diretriz unica");

        if (condicao) {
          resp.data.forEach((item) => {
            if (item.codigo === parseInt(codigoDiretriz.codigo)) {
              setValuesDiretriz((prevValues) => ({
                ...prevValues,
                demanda: item.audiencia,
                exercicio: item.exercicio,
              }));
            }
          });
        } else {
          let arrayDiretrizesAtualiza = [...arrayDiretrizes];
          console.log(arrayDiretrizesAtualiza);
          resp.data.forEach((item) => {
            if (item.codigo === parseInt(codigoDiretriz.codigo)) {
              arrayDiretrizesAtualiza.push({
                codigo: item.codigo,
                titulo: item.titulo,
                demanda: item.audiencia,
                exercicio: item.exercicio,
              });
              console.log("ARRAY DE ATT");
              console.log(arrayDiretrizesAtualiza);
              setArrayDiretrizes(arrayDiretrizesAtualiza);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchDiretrizOptions = (reqDeModal) => {
    console.log(inputAutoCompleteValue);
    api
      .get(
        `diretrizPlanejamento/por-cliente/${currentClient.clienteId}/${currentYear}`
      )
      .then((resp) => {
        const data = resp.data;

        let arrayOptions = data.map((item) => {
          return { codigo: item.codigo, titulo: item.titulo };
        });
        console.log(arrayOptions);
        setOptions(arrayOptions);

        if (reqDeModal) {
          const lastPostedData = data.reverse()[0];
          console.log(lastPostedData);

          console.log("reqDeModal");

          setInputAutoComplete(
            `${lastPostedData.codigo} - ${lastPostedData.titulo}`
          );
          setCodigoDiretriz((prevValues) => ({
            ...prevValues,
            codigo: lastPostedData.codigo,
          }));

          setOpenedModals((prev) =>
            prev.filter((item) => item.tituloModal !== "Diretriz")
          );
        }
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados de diretriz.");
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDiretrizOptions();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // fetchDiretrizOptions();
    console.log("AQUIIII:", arrayDiretrizes);

    if (inputAutoCompleteValue.length === 0) {
      console.log("é zero");
      limparDadosDiretriz();
    }
    //eslint-disable-next-line
  }, [inputAutoCompleteValue]);

  const cadastraEixoEstrategico = () => {
    let nonNullValues = true;

    Object.keys(eixoValues).forEach((item) => {
      if (item === "diretrizes") {
        if (eixoValues[item].length === 0) {
          nonNullValues = false;
        }
      }

      if (
        eixoValues[item] === "" ||
        eixoValues[item] === null ||
        eixoValues[item] === undefined
      ) {
        nonNullValues = false;
      }
    });

    console.log(nonNullValues);

    if (nonNullValues) {
      console.log("EIXO VALUES AQUI: ", eixoValues);
      api
        .post("eixoEstrategicoPlanejamento", eixoValues)
        .then((resp) => {
          console.log(resp.data);
          toast.success("Dados cadastrados com sucesso!");
          limparCampos();

          if (isAModalPage) {
            DefinirRequisicaoDoModalComoConluida(
              openedModals,
              setOpenedModals,
              "Eixo Estratégico"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Algo deu errado, por favor, tente mais tarde");
        });
    } else {
      toast.warning(
        "Preencha todos os valores necessarios para cadastrar os dados"
      );
    }
  };

  const deleDiretrizesJaCadastradas = () => {
    if (eixoValues.diretrizes.length > 1) {
      api
        .get(
          `eixoEstrategicoPlanejamento/${currentClient.clienteId}/${paramsCodigo}`
        )
        .then((resp) => {
          let arrayDiretrizesJaCadastradas = resp.data.diretrizes.map(
            (item) => item.codigo
          );
          console.log(arrayDiretrizesJaCadastradas);

          if (arrayDiretrizesJaCadastradas.includes(dataDelete.codigo)) {
            api
              .delete(
                `eixoEstrategicoPlanejamento/${currentClient.clienteId}/delete/${paramsCodigo}/${dataDelete.codigo}`
              )
              .then((resp) => {
                console.log(resp);
                deleteDiretriz();
                toast.success("Diretriz deletada com sucesso!");
              });
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            "Algo deu errado ao deletar a diretriz. Por favor tente mais tarde ou entre em contato com o suporte."
          );
        });
    } else {
      toast.warning("Um eixo não poder ter menos que uma diretriz cadastrada");
    }
  };

  const atualizarEixoEstrategico = () => {
    console.log("AQUI: ", eixoAtualizacaoValues);
    api
      .put(
        `eixoEstrategicoPlanejamento/${currentClient.clienteId}/atualiza/${paramsCodigo}`,
        eixoAtualizacaoValues
      )
      .then((resp) => {
        console.log(resp);
        toast.success("Eixo atualizado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Algo deu errado ao atualizar os dados do Eixo. Por favor tente mais tarde."
        );
      });
  };

  function openModal() {
    setModalOpened(true);
  }

  function closeModal() {
    setModalOpened(false);
  }

  const handleAddNewDiretriz = () => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: "Diretriz", requisicaoDoModalConcluida: false },
    ]);
  };

  useEffect(() => {
    const modalDiretriz = openedModals.find(
      (item) => item.tituloModal === "Diretriz"
    );

    if (modalDiretriz && modalDiretriz.requisicaoDoModalConcluida) {
      fetchDiretrizOptions(true);
    }

    //eslint-disable-next-line
  }, [openedModals]);

  return (
    <ContainerToModal
      id={isAModalPage ? undefined : "pags_responsividade_padding_geral"}
      fluid
      isAModalPage={isAModalPage}
      className={
        isAModalPage ? undefined : "container_conteudo conteudo_pagina"
      }
    >
      {!isAModalPage && <ToastContainer />}

      {!isAModalPage && (
        <HeaderCadastros
          NomePaginaTitulo={"Eixo Estratégico"}
          PaginaSubtitulo={"Cadastro"}
          PaginaConsulta={"/planejamento/cadastro/eixo-estrategico/consulta"}
          PaginaRelatorio={"/planejamento/cadastro/eixo-estrategico/relatorio"}
          DashboardPage={"/planejamento/cadastro/eixo-estrategico/dashboard"}
          ButtonSaveFunction={
            paramsCodigo !== undefined
              ? atualizarEixoEstrategico
              : cadastraEixoEstrategico
          }
          BotaoSaveAtivo={
            eixoAtualizacaoValues.titulo === eixoValues.titulo &&
            eixoAtualizacaoValues.novasDiretrizes.length === 0
              ? false
              : true
          }
        />
      )}

      {isAModalPage && (
        <ButtonSaveModalAutoComplete
          SaveFunction={cadastraEixoEstrategico}
          pageTitle={"Eixo Estratégico"}
        />
      )}

      <ModalAutoComplete
        tituloModal={"Diretriz"}
        Component={<CadastroDiretriz isAModalPage={true} />}
      />

      <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        deleteDataFunction={
          paramsCodigo !== undefined
            ? deleDiretrizesJaCadastradas
            : deleteDiretriz
        }
      />
      <RowToModal isAModalPage={isAModalPage} className="row_form mt-1">
        <CCol md={2}>
          <FloatingLabelInput
            placeholder="Nº Eixo"
            label="Nº Eixo"
            disabled={paramsCodigo !== undefined ? true : false}
            value={eixoValues.codigo}
            maxLength="4"
            onChange={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
              setEixoValues((prevValues) => ({
                ...prevValues,
                codigo: e.target.value,
              }));
            }}
          />
        </CCol>
        <CCol md={10}>
          <FloatingLabelInput
            placeholder="Título"
            label="Título"
            value={
              paramsCodigo !== undefined
                ? eixoAtualizacaoValues.titulo
                : eixoValues.titulo
            }
            type="text"
            onChange={
              paramsCodigo !== undefined
                ? (e) => {
                    setEixoAtualizacaoValues((prevValues) => ({
                      ...prevValues,
                      titulo: e.target.value,
                    }));
                  }
                : (e) => {
                    setEixoValues((prevValues) => ({
                      ...prevValues,
                      titulo: e.target.value,
                    }));
                  }
            }
          />
        </CCol>
      </RowToModal>
      <RowToModal isAModalPage={isAModalPage}>
        <CCol
          className="d-flex align-items-center row_endereco_aba mt-1"
          md={12}
        >
          <span className="span_endereco">Diretriz</span>
        </CCol>
      </RowToModal>
      <div>
        <RowToModal isAModalPage={isAModalPage}>
          <CCol
            className="d-flex align-items-center p-2 row_endereco_conteudo"
            md={12}
          >
            <span className="span_endereco_titulo">Diretriz</span>
          </CCol>
        </RowToModal>
        <RowToModal isAModalPage={isAModalPage} className="mt-1 row_form_2">
          <CCol>
            {/* AutoComplete CNPJ Cliente */}
            <PTitulosInputs>Título ou Código</PTitulosInputs>
            <MyAutoComplete
              onChange={(e) => {
                setInputAutoComplete(e.target.value.replace(/[^\w\s]/gi, ""));
              }}
              addNewDataFunction={handleAddNewDiretriz}
              inputValue={inputAutoCompleteValue}
              setInputValue={setInputAutoComplete}
              options={options}
              radiusborder={
                eixoValues.diretrizes.length > 0 ? "5px 5px 0px 0px" : null
              }
              labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
              onOptionSelect={handleAutocompleteChange}
              placeholder="Nome ou Código da diretriz"
              style={{ width: "100%" }}
              iconClearFunction={limparDadosDiretriz}
            />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Demanda</PTitulosInputs>
            <FormControlListaDeInputs
              radiusborder={
                eixoValues.diretrizes.length > 0 ? "5px 5px 0px 0px" : null
              }
              value={valuesDiretriz.demanda}
              disabled
            />
          </CCol>
          <CCol md={1}>
            <PTitulosInputs>Exercicio</PTitulosInputs>
            <FormControlListaDeInputs
              radiusborder={
                eixoValues.diretrizes.length > 0 ? "5px 5px 0px 0px" : null
              }
              value={valuesDiretriz.exercicio}
              disabled
            />
          </CCol>
          <CColButtonsAcoes md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <Icon
              onClick={adicionaDiretriz}
              icon="ic:baseline-add-box"
              color="#105200"
              height="28"
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "1px",
              }}
            />
          </CColButtonsAcoes>
          {/* -------------------------------------------------------------- */}
          {arrayDiretrizes.map((item, index, selfArray) => (
            <RowItemListaDeInputs key={index}>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    index === selfArray.length - 1 ? "0px 0px 5px 5px" : "0px"
                  }
                  value={`${item.codigo} - ${item.titulo}`}
                  disabled
                />
              </CCol>
              <CCol md={2}>
                <FormControlListaDeInputs
                  radiusborder={
                    index === selfArray.length - 1 ? "0px 0px 5px 5px" : "0px"
                  }
                  value={item.demanda}
                  disabled
                />
              </CCol>
              <CCol md={1}>
                <FormControlListaDeInputs
                  radiusborder={
                    index === selfArray.length - 1 ? "0px 0px 5px 5px" : "0px"
                  }
                  value={item.exercicio}
                  disabled
                />
              </CCol>
              <CColButtonsAcoes md={1}>
                <Icon
                  onClick={
                    checkClient
                      ? () => {
                          setDataDelete({ codigo: item.codigo });
                          openModal();
                        }
                      : () =>
                          toast.warning(
                            "Você não tem permissao para alterar esses dados"
                          )
                  }
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes>
            </RowItemListaDeInputs>
          ))}
        </RowToModal>
      </div>
    </ContainerToModal>
  );
}

export default EixoEstrategico;
