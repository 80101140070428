import { Text, View, Image } from "@react-pdf/renderer";
import { stylesPdf } from "./StyledPdfRelatorio";
import defaultImg64 from "../../../utils/RelatorioDocx/defaultImg64";

const isBase64Image = (str) => {
  const isBase64 = /^data:'?image\/[a-z]+';base64,[a-zA-Z0-9+/=]+$/.test(str);
  if (!isBase64) {
    return [false, false];
  }
  const base64Content = str.split(",")[1];
  const lengthIsValid = base64Content.length % 4 === 0;
  const paddingIsValid =
    base64Content.endsWith("=") || !base64Content.includes("=");
  return [isBase64, lengthIsValid && paddingIsValid];
};

export const PdfInfos = ({ pdfInfo, styledCol,styledText }) => {
  const imageSource = isBase64Image(pdfInfo);
  return (
    <View style={[stylesPdf.divInfosDoRelatorio, styledCol]} wrap={false}>
      {imageSource[0] ? (
        <Image
          style={{ width: "25px", margin: "2px 0" }}
          src={imageSource[1] ? pdfInfo : defaultImg64}
        />
      ) : (
        <Text style={[stylesPdf.textInfosRelatorio,styledText]}>{pdfInfo}</Text>
      )}
    </View>
  );
};
