// const ITEMS_PER_PAGE = 3;

const paginateData = (data, pageNumber, dataPerPage) => {
  const startIndex = (pageNumber - 1) * dataPerPage;
  return data.slice(startIndex, startIndex + dataPerPage);
};

export const paginateDataRelatorio = (dados, dataPerPage = 3) => {
  const totalPages = Math.ceil(dados.length / dataPerPage);

  const pages = Array.from({ length: totalPages }, (_, index) =>
    paginateData(dados, index + 1, dataPerPage)
  );
  return pages;
};
