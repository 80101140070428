import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { PdfInfos } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";

export const RenderPdfAnexoEstimativa = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    {
      flexBasis: 300,
      flexShrink: 1,
      flexGrow: 1,
    },
    {
      flexBasis: 220,
      flexShrink: 1,
      flexGrow: 1,
    },
    {
      flexBasis: 130,
      flexShrink: 1,
      flexGrow: 1,
    },
  ];
  const mockData = [
    {
      sectionTitle: "Receitas Correntes (I) ",
      sectionValues: {
        firstYear: ["37.613.800,00 ", "37.613.800,00"],
        values: ["37.613.800,00 ", "37.613.800,00", "37.613.800,00"],
      },
      sectionData: [
        {
          subSectionTitle: "Impostos, Taxas e Contribuições de Melhoria",
          subSectionValues: {
            firstYear: ["37.613.800,00 ", "37.613.800,00"],
            values: ["37.613.800,00 ", "37.613.800,00", "37.613.800,00"],
          },
        },
        {
          subSectionTitle: "Contribuições",
          subSectionValues: {
            firstYear: ["37.613.800,00 ", "37.613.800,00"],
            values: ["37.613.800,00 ", "37.613.800,00", "37.613.800,00"],
          },
        },
        {
          subSectionTitle: "Receita Patrimonial ",
          subSectionValues: {
            firstYear: ["37.613.800,00 ", "37.613.800,00"],
            values: ["37.613.800,00 ", "37.613.800,00", "37.613.800,00"],
          },
        },
        {
          subSectionTitle: "Transferências Correntes",
          subSectionValues: {
            firstYear: ["37.613.800,00 ", "37.613.800,00"],
            values: ["37.613.800,00 ", "37.613.800,00", "37.613.800,00"],
          },
        },
        {
          subSectionTitle: "Outras Receitas Correntes",
          subSectionValues: {
            firstYear: ["37.613.800,00 ", "37.613.800,00"],
            values: ["37.613.800,00 ", "37.613.800,00", "37.613.800,00"],
          },
        },
      ],
    },
    {
      sectionTitle: "Dedução da Receita (II) ",
      sectionValues: {
        firstYear: ["37.613.800,00 ", "37.613.800,00"],
        values: ["37.613.800,00 ", "37.613.800,00", "37.613.800,00"],
      },
      sectionData: [
        {
          subSectionTitle: "Contribuição para Formação do FUNDEB ",
          subSectionValues: {
            firstYear: ["37.613.800,00 ", "37.613.800,00"],
            values: ["37.613.800,00 ", "37.613.800,00", "37.613.800,00"],
          },
        },
      ],
    },
  ];


  const renderTextColumn = (text, style = {}) => (
    <Text
      style={[
        stylesPdf.textTituloColuna,
        style,
        { textTransform: "uppercase", fontSize: 8 },
      ]}
    >
      {text}
    </Text>
  );
  const renderHeader = (styled = {}) => {
    return (
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }, styled]}>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: 32,
              },
            ]}
          >
            {renderTextColumn("receita")}
          </View>
        </View>
        {dados.contentHeader.map((year, index) => (
          <View
            key={index}
            style={[stylesPdf.coluna, styledCol[index === 0 ? 1 : 2]]}
          >
            <View
              style={[
                stylesPdf.divTituloColuna,
                {
                  textAlign: "center",
                  paddingVertical: 2,
                  paddingHorizontal: 4,
                  height: 32,
                  justifyContent: index === 0 ? "space-between" : "center",
                },
              ]}
            >
              {renderTextColumn(index === 0 ? year - 1 : year)}
              {index === 0 && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {renderTextColumn("prevista", {
                    textTransform: "capitalize",
                  })}
                  {renderTextColumn("arrecadada", {
                    textTransform: "capitalize",
                  })}
                </View>
              )}
            </View>
          </View>
        ))}
      </View>
    );
  };

  const renderHeaderInflacao = (title, styled = {}) => {
    return (
      <View
        style={[
          stylesPdf.divTituloColuna,
          {
            textAlign: "center",
            height: "auto",
            alignItems: "center",
            width: "100%",
            paddingVertical: 2,
          },
          styled,
        ]}
      >
        {renderTextColumn(title, { textTransform: "uppercase" })}
      </View>
    );
  };

  const renderInfos = (title, firstYearValues, values, isSection = false) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            backgroundColor: isSection ? "#a6a6a6" : "transparent",
            fontWeight: isSection ? "bold" : "normal",
          },
        ]}
      >
        <PdfInfos
          pdfInfo={title}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
              textAlign: "left",
            },
          ]}
          styledText={{
            fontSize: 8,
          }}
        />
        <View
          style={[
            stylesPdf.divInfosDoRelatorio,
            styledCol[1],
            {
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            },
          ]}
          wrap={false}
        >
          {firstYearValues.map((value, index) => (
            <React.Fragment key={index}>
              <Text
                style={[
                  stylesPdf.textInfosRelatorio,
                  { paddingHorizontal: 4, fontSize: 8 },
                ]}
              >
                {value}
              </Text>
            </React.Fragment>
          ))}
        </View>
        {values.map((value, index) => (
          <View
            key={index}
            style={[
              stylesPdf.divInfosDoRelatorio,
              styledCol[2],
              {
                alignItems: "center",
              },
            ]}
            wrap={false}
          >
            <Text
              style={[
                stylesPdf.textInfosRelatorio,
                { textAlign: "right", fontSize: 8 },
              ]}
            >
              {value}
            </Text>
          </View>
        ))}
      </View>
    );
  };

  const renderHeaderSection = (firstValueColum, values, styled = {}) => {
    return (
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }, styled]}>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                paddingVertical: 2,
                height: "auto",
                paddingLeft: 8,
              },
            ]}
          >
            {renderTextColumn("Receita Corrente Líquida (III) = (I-II) ")}
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[1]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                paddingVertical: 2,
                paddingHorizontal: 4,
                height: "auto",
                justifyContent: "space-between",
                flexDirection: "row",
              },
            ]}
          >
            {firstValueColum.map((item, index) => (
              <React.Fragment key={index}>
                {renderTextColumn(item, {
                  textAlign: "center",
                  width: "100%",
                })}
              </React.Fragment>
            ))}
          </View>
        </View>

        {values.map((value, index) => (
          <View key={index} style={[stylesPdf.coluna, styledCol[2]]}>
            <View
              style={[
                stylesPdf.divTituloColuna,
                {
                  textAlign: "center",
                  paddingVertical: 2,
                  paddingHorizontal: 4,
                  height: "auto",
                  justifyContent: "center",
                },
              ]}
            >
              {renderTextColumn(value)}
            </View>
          </View>
        ))}
      </View>
    );
  };

  const renderLineDivider = (styled = {}) => {
    return (
      <View
        style={[
          {
            height: 1,
            width: "100%",
            backgroundColor: "#000",
          },
          styled,
        ]}
      />
    );
  };

  return (
    <Page size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Estimativa da Receita Corrente Líquida "}
        anoRelatorio={currentYear}
        plusText={"Anexo XI "}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: 8,
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>

      {renderHeader()}

      <View style={stylesPdf.divRelatorio}>
        {mockData.map((item, index) => (
          <React.Fragment key={index}>
            {renderInfos(
              item.sectionTitle,
              item.sectionValues.firstYear,
              item.sectionValues.values,
              true
            )}
            {item.sectionData.map((dataSection, index) => (
              <React.Fragment key={index}>
                {renderInfos(
                  dataSection.subSectionTitle,
                  dataSection.subSectionValues.firstYear,
                  dataSection.subSectionValues.values
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </View>

      {renderHeaderSection(
        ["1.000.00,00", "1.000.00,00"],
        ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
        { marginTop: 10 }
      )}

      {renderHeaderInflacao("ÍNDICES DE INFLAÇÃO", { marginTop: 10 })}

      <View
        style={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {dados.contentHeader.map((year, index) => (
          <View
            key={index}
            style={[
              stylesPdf.colunas,
              {
                backgroundColor: "#a6a6a6",
                fontWeight: "bold",
                width: "100%",
              },
            ]}
          >
            <PdfInfos
              pdfInfo={year}
              styledCol={[
                {
                  padding: "2px 0",
                  textAlign: "center",
                  width: "100%",
                },
              ]}
              styledText={{
                fontSize: 8,
              }}
            />
          </View>
        ))}
      </View>
      {renderLineDivider({ marginTop: 10 })}
      <Text
        style={{ fontWeight: "bold", marginLeft: 8, marginTop: 2, fontSize: 8 }}
      >
        FONTE: Banco Central – Relatório Focus de abril 2024
      </Text>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
