import React from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import styled from "styled-components";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const DescontoNaFonte = () => {
  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Desconto na Fonte"}
        PaginaSubtitulo={"Cadastro"}
      />

      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput label="Número" placeholder="Número" disabled />
        </CColNumero>
        <CCol>
          <FloatingLabelInput label="Título" placeholder="Título" />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Indicador de Lançamento"
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Natureza da Receita"
            style={{ height: "37px" }}
          />
        </CCol>
      </RowForm>
      <RowInfosAcao>
        <CCol>
          <p>
            <span>
              Registra as receitas que se originaram dos impostos sobre a
              importação. De competência da União, o imposto de importação
              possui natureza
            </span>
          </p>
        </CCol>
      </RowInfosAcao>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Devedor"
            style={{ height: "37px" }}
          />
        </CCol>
      </RowForm>

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Conta Bancária Destino da Retenção"
            style={{ height: "37px" }}
          />
        </CCol>
      </RowForm>
    </Container>
  );
};

export default DescontoNaFonte;
