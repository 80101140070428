import React from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowForm,
} from "../../../../components/Grid/CCol";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { PTitulosInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import styled from "styled-components";

const ContentInfos = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    white-space: nowrap;
    font-size: 12px;
  }

  @media (max-width: 1077px) {
    flex-wrap: wrap;
    gap: 5px;
  }
`;

export const AnulacaoCollapse = () => {
  return (
    <>
      <RowForm className="mt-1">
        <CCol>
          <PTitulosInputs>Ficha</PTitulosInputs>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
          />
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Tipo de Alteração</PTitulosInputs>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Tipo Alteração"
            height="30px"
          />
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Valor</PTitulosInputs>
          <InputFormatRealBrasileiro listaDeInputs />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar />
        </CColButtonsAcoes2Icons>
      </RowForm>
      <RowForm $borderRadiusType={2} style={{ marginTop: "-14px" }}>
        <CCol></CCol>
        <CCol
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
          md={2}
        >
          <p style={{ margin: 0, color: "#515c70", fontWeight: 600 }}>
            Valor Total
          </p>
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: 10, textAlign: "end" }}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
      </RowForm>

      <RowInfosAcao $borderRadiusType={3} style={{ marginTop: "15px" }}>
        <CCol md={8}>
          <p>
            Unidade Orçamentária:{" "}
            <span>02.050 - Secretaria Municipal de Educação</span>
          </p>
          <p>
            Programa:{" "}
            <span>
              2025 - Manutenção dos Serviços da Secretaria Municipal de Educação
            </span>
          </p>
          <p>
            Elemento de Despesa:{" "}
            <span>
              3.3.90.39 - OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA
            </span>
          </p>
          <p>
            Fonte de Recursos:{" "}
            <span>
              1.500.1001 - Identificação das despesas com manutenção e
              desenvolvimento do ensino
            </span>
          </p>
        </CCol>
        <CCol>
          <p>
            Função: <span>08 - Assistência Social</span>
          </p>
          <p>
            Subfunção: <span>244 - Assistência Comunitária</span>
          </p>
        </CCol>
      </RowInfosAcao>

      <RowInfosAcao
        $borderRadiusType={2}
        contentStyle={{ overflowX: "hidden" }}
      >
        <ContentInfos>
          <p>
            Dotação Inicial: <span>R$ 150.000,00</span>
          </p>

          <p>
            Utilizado: <span>R$ 150.000,00</span>
          </p>

          <p>
            Suplementação: <span>R$ 150.000,00</span>
          </p>

          <p>
            Anulação: <span>R$ 150.000,00</span>
          </p>

          <p>
            Saldo: <span>R$ 150.000,00</span>
          </p>
        </ContentInfos>
      </RowInfosAcao>
    </>
  );
};
