import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";

import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import {
  ColButtons,
  ButtonOptionsFirst,
  ButtonOptionsPages,
  ButtonOptionsLast,
} from "../../../../styles/StyledComponents/ButtonsSelectPage";
import ListaAgencias from "./ListaAgencias";
import ListaContas from "./ListaContas";
import { useLocation } from "react-router-dom";

function ConsultaContaAgencia() {
  const location = useLocation();
  const path = location.pathname.split("/")[3];
  const [botaoAtivo, setBotaoAtivo] = useState(path === "agencias" ? 1 : 0);

  //   const deletUser = (id) => {
  //     axios
  //       .delete(`https://api.savest.com.br/usuario/${id}`)
  //       .then((data) => {
  //         toast.success("Usuario deletado com sucesso");
  //         fetchData();
  //       })
  //       .catch((error) => {
  //         toast.error("Algo deu errado. Por favor, tente mais tarde.");
  //       });
  //   };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        withRow={false}
        TituloPagina={botaoAtivo === 0 ? "Contas Bancárias" : "Agências"}
        SubTituloPagina={"Consulta"}
        ToPag={
          botaoAtivo === 0
            ? "/contabilidade/financeiro/conta-bancaria/cadastro"
            : "/contabilidade/financeiro/agencia/cadastro"
        }
      />
      <Row>
        <ColButtons md={12}>
          <ButtonOptionsPages>
            <ButtonOptionsFirst
              ativo={botaoAtivo === 0}
              onClick={() => setBotaoAtivo(0)}
            >
              Contas Bancárias
            </ButtonOptionsFirst>
            <ButtonOptionsLast
              ativo={botaoAtivo === 1}
              onClick={() => setBotaoAtivo(1)}
            >
              Agências
            </ButtonOptionsLast>
          </ButtonOptionsPages>
        </ColButtons>
      </Row>
      {botaoAtivo === 0 && <ListaContas />}
      {botaoAtivo === 1 && <ListaAgencias />}
    </Container>
  );
}

export default ConsultaContaAgencia;
