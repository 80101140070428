import React from "react";

const DivCentralizeIcon = ({ children }) => {
  return (
    <div
      style={{
        flex: "1",
        display: "flex",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};

export default DivCentralizeIcon;
