import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getDespesaOrgaoUnidade = async (
  clientes,
  entidadesGestoras,
  ano = 2024
) => {
  const url = getUrl(clientes, entidadesGestoras);

  try {
    const response = await api.get(
      `qdd/relatorio/agregado/${url.idsUrl}/${ano}?${url.filterConsolidado}&anexo=1`
    );
    console.log("response.data", response.data, entidadesGestoras)

    return formattedAllDespesas(response.data, entidadesGestoras);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Anexo VI - Programa de Trabalho - Órgão e Unidade Orçamentária",
    };
  }
};

const calcularTotalGeralUnidades = (unidades) => {
  const totais = Object.keys(unidades).reduce(
    (acc, unidadeKey) => {
      const unidadeOrcamentaria = unidades[unidadeKey];

      acc.totalDaUnidade += unidadeOrcamentaria.totalDaUnidade;
      acc.totalEspeciaisUnidade += unidadeOrcamentaria.totalEspeciaisUnidade;
      acc.totalProjetosUnidade += unidadeOrcamentaria.totalProjetosUnidade;
      acc.totalAtividadesUnidade += unidadeOrcamentaria.totalAtividadesUnidade;
      return acc;
    },
    {
      totalDaUnidade: 0,
      totalEspeciaisUnidade: 0,
      totalProjetosUnidade: 0,
      totalAtividadesUnidade: 0,
    }
  );

  return totais;
};

const calculateTotalGeralEntidades = (entidades) => {
  const total = entidades.reduce((acc, unidade) => {
    return (acc += unidade.totalGeralUnidade.totalDaUnidade);
  }, 0);

  return total;
};

const orderRelatorio = (unidades) => {
  const unidadesOrdenadas = unidades.map((unidade) => {
    return {
      ...unidade,
      relatorio: unidade.relatorio
        .sort((a, b) => a.codigoFuncao.localeCompare(b.codigoFuncao))
        .map((funcao) => ({
          ...funcao,
          subFuncoes: funcao.subFuncoes
            .sort((a, b) => a.codigoSubFuncao.localeCompare(b.codigoSubFuncao))
            .map((subFuncao) => ({
              ...subFuncao,
              programas: subFuncao.programas.sort((a, b) =>
                a.codigoPrograma.localeCompare(b.codigoPrograma)
              ),
            })),
        })),
    };
  });

  return unidadesOrdenadas;
};

const converterUnidadesToArray = (unidades) => {
  const unidadesArray = Object.values(unidades).map((unidade) => {
    const relatorioArray = Object.values(unidade.relatorio).map((funcao) => {
      const subFuncoesArray = Object.values(funcao.subFuncoes).map(
        (subFuncao) => {
          return {
            ...subFuncao,
            programas: Object.values(subFuncao.programas),
          };
        }
      );

      return {
        ...funcao,
        subFuncoes: subFuncoesArray,
      };
    });

    return {
      ...unidade,
      relatorio: relatorioArray,
    };
  });

  return orderRelatorio(unidadesArray);
};

const formattedAllDespesas = (
  dataQddRelatorioOrgaoUnidade,
  entidadesGestoras
) => {
  const dadosFormatados = Object.keys(dataQddRelatorioOrgaoUnidade).map(
    (key) => {
      const unidade = dataQddRelatorioOrgaoUnidade[key];
      return {
        unidades: converterUnidadesToArray(unidade.unidadesOrcamentarias),
      };
    }
  );

  const dataOrgao = entidadesGestoras[0].checkBoxes
    .map((entidade) => {
      const filterUnidades = dadosFormatados
        .map((unidade) =>
          unidade.unidades.filter(
            (unidadeGestora) =>
              unidadeGestora.unidadeGestoraId === entidade.dados.clienteId
          )
        )
        .flat();

      return {
        clientId: entidade.dados.poderOrgao.match(/\d+/g).join('') || "",
        tipoNaturezaJuridica: entidade.dados.tipoNaturezaJuridica,
        unidadeGestora: entidade.dados.nomeFantasia,
        totalGeralUnidade: calcularTotalGeralUnidades(filterUnidades),
        unidades: converterUnidadesToArray(filterUnidades),
      };
    })
    .filter((data) => data.unidades.length);

  dataOrgao.sort((dataOrgaoA, dataOrgaoB) => {
    const codigoDataOrgaoA = parseInt(dataOrgaoA.tipoNaturezaJuridica[0]);
    const codigoDataOrgaoB = parseInt(dataOrgaoB.tipoNaturezaJuridica[0]);
    return codigoDataOrgaoA - codigoDataOrgaoB;
  });

  dataOrgao.forEach((data) =>
    data.unidades.sort((unidadeA, unidadeB) => {
      const codigoUnidadeA = parseFloat(
        unidadeA.codigoUnidadeOrcamentaria.replace(".", "")
      );
      const codigoUnidadeB = parseFloat(
        unidadeB.codigoUnidadeOrcamentaria.replace(".", "")
      );
      return codigoUnidadeA - codigoUnidadeB;
    })
  );

  const total = calculateTotalGeralEntidades(dataOrgao);

  return {
    dataOrgao,
    total,
  };
};
