import { Row, Form } from "react-bootstrap";
import styled from "styled-components";
import { CCol } from "../../components/Grid/CCol";

export const TableRowPdc = styled.tr`
  background-color: aliceblue;
`;

export const TableBodyPdc = styled.tbody`
  border: 1px solid red;
`;

export const RowListaPdc = styled(Row)`
  background-color: ${({ background }) => background};
  margin: 10px 0px 0px 0px;
  padding: 10px 0px 10px 0px;
  border-radius: 10px;

  h6 {
    margin: 0;
    padding: 0;
  }
`;

export const CheckBoxListaPCASP = styled(Form.Check)`
  color: #6c7585;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  input[type="checkbox"] {
    background-color: #f7f7f7;
    margin-right: 5px;
    border-radius: 2px;
    border: 2px solid #515c70;
  }

  input[type="checkbox"]:checked {
    background-color: green;
    border-color: green;
  }
`;

export const RowCheckBoxesListaPCASP = styled(Row)`
  border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
`;

//CCols Tabela da Pagina PCASP Consulta

export const CColContaListaPCASP = styled(CCol)`
  width: 3%;
`;

export const CColItensGeraisListaPCASP = styled(CCol)`
  width: 3.5%;
`;

export const CColAtributoTituloListaPCASP = styled(CCol)`
  width: 8.5%;
`;
