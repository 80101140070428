import React, { useEffect, useState } from "react";
import { CCol, RowToModal } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import api from "../../../../utils/api";
import ReactInputMask from "react-input-mask";
import { Search } from "@mui/icons-material";

const Agencia = ({
  agenciaValues,
  setAgenciaValues,
  inputSearchBancoValue,
  setInputSearchBancoValue,
  paramsId,
}) => {
  const [todosOsBancos, setTodosOsBancos] = useState([]);
  const [bancosFiltrados, setBancosFiltrados] = useState([]);
  const [loadingCep, setLoadingCep] = useState(false);

  const fetchBancos = async () => {
    try {
      const resp = await api.get("brasilApi/bank");
      const data = resp.data;

      setTodosOsBancos(
        data
          .filter((item) => item.code !== null)
          .map((item) => ({
            ...item,
            code: item.code.toString().padStart(3, "0"),
          }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBancos();
  }, []);

  const handleSearchInputChange = (e) => {
    if (e.target.value.length > 0) {
      const cleanedValue = e.target.value.replace(/\./g, "").toLowerCase();
      const arrayFiltrado = todosOsBancos.filter(
        (banco) =>
          banco.fullName.toLowerCase().includes(cleanedValue) ||
          banco.code.includes(cleanedValue)
      );

      console.log(arrayFiltrado);

      setBancosFiltrados(arrayFiltrado);
    } else {
      setBancosFiltrados([]);
    }
  };

  const handleOptionSelect = (item) => {
    console.log(item);

    setAgenciaValues((prev) => ({
      ...prev,
      codigo: item.code,
      nome: item.fullName,
    }));
  };

  const fetchCep = async (cep) => {
    setLoadingCep(true);

    try {
      const resp = await api.get(`brasilApi/cep/${cep}`);
      const data = resp.data;

      setAgenciaValues((prev) => ({
        ...prev,
        logradouro: data.street || "",
        bairro: data.neighborhood || "",
        cidade: data.city || "",
        estado: data.state || "",
      }));
    } catch (error) {
      console.log(error);
    }

    setLoadingCep(false);
  };

  const handleChangeCep = (e) => {
    const cep = e.target.value;
    const cleanedCep = cep.replace(/[^0-9]/g, "");
    setAgenciaValues((prev) => ({ ...prev, cep: cep }));

    if (cleanedCep.length === 8) {
      fetchCep(cep);
    }
  };

  return (
    <>
      <RowToModal className="row_form mt-1">
        <CCol>
          {/* <button onClick={debug}>debug</button> */}
          {paramsId && (
            <FloatingLabelInput
              label="Código Banco"
              placeholder="Código Banco"
              disabled
              value={inputSearchBancoValue}
            />
          )}

          {!paramsId && (
            <MyAutoComplete
              labelInput={"Código Banco"}
              placeholder="Código Banco"
              style={{ height: "38px" }}
              options={bancosFiltrados}
              inputValue={inputSearchBancoValue}
              setInputValue={setInputSearchBancoValue}
              labelFormat={(option) => `${option.code} - ${option.fullName}`}
              onChange={handleSearchInputChange}
              onOptionSelect={handleOptionSelect}
            />
          )}
        </CCol>
        <CCol md={2}>
          <ReactInputMask
            mask="9999"
            maskPlaceholder={null}
            value={agenciaValues.agencia}
            onChange={(e) =>
              setAgenciaValues((prev) => ({
                ...prev,
                agencia: e.target.value,
              }))
            }
          >
            <FloatingLabelInput label="Nº Agência" placeholder="Nº Agência" />
          </ReactInputMask>
        </CCol>
        <CCol md={1}>
          <ReactInputMask
            mask="9"
            maskPlaceholder={null}
            value={agenciaValues.digito}
            onChange={(e) =>
              setAgenciaValues((prev) => ({
                ...prev,
                digito: e.target.value,
              }))
            }
          >
            <FloatingLabelInput label="Dígito" placeholder="Dígito" />
          </ReactInputMask>
        </CCol>
        <CCol md={2}>
          <InputData
            externalValue={agenciaValues.data}
            onChange={(e) =>
              setAgenciaValues((prev) => ({ ...prev, data: e.target.value }))
            }
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol md={3}>
          <div style={{ position: "relative" }}>
            <Search
              style={{
                color: "#515c70",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: "5px",
              }}
            />
            <ReactInputMask
              mask="99999-999"
              maskPlaceholder={null}
              value={agenciaValues.cep}
              onChange={handleChangeCep}
            >
              <FloatingLabelInput placeholder="CEP" label="CEP" />
            </ReactInputMask>
          </div>
        </CCol>
        <CCol>
          <FloatingLabelInput
            label="Logradouro"
            placeholder="Logradouro"
            disabled={loadingCep}
            value={agenciaValues.logradouro}
            onChange={(e) =>
              setAgenciaValues((prev) => ({
                ...prev,
                logradouro: e.target.value,
              }))
            }
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form_2" style={{ marginTop: "-15px" }}>
        <CCol md={6}>
          <FloatingLabelInput
            label="Bairro"
            placeholder="Bairro"
            disabled={loadingCep}
            value={agenciaValues.bairro}
            onChange={(e) =>
              setAgenciaValues((prev) => ({ ...prev, bairro: e.target.value }))
            }
          />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput
            label="Cidade"
            placeholder="Cidade"
            onChange={(e) =>
              setAgenciaValues((prev) => ({ ...prev, cidade: e.target.value }))
            }
            disabled={loadingCep}
            value={agenciaValues.cidade}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            label="Estado"
            placeholder="Estado"
            onChange={(e) =>
              setAgenciaValues((prev) => ({ ...prev, estado: e.target.value }))
            }
            disabled={loadingCep}
            value={agenciaValues.estado}
          />
        </CCol>
      </RowToModal>
    </>
  );
};

export default Agencia;
