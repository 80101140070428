import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfDespesaClassificacao = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: "130px", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    {
      flexBasis: "90px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];
  console.log("DADOOOOOOOOOOOOOS:", dados);

  const optionDespesaDemonstrativo = opcoes.find(
    (option) => option.opcao === "Quadro Detalhado da Receita - QDR"
  );

  const renderReceitas = (codigo, titulo, despesa) => {
    const seccoes = (codigo.match(/\./g) || []).length;

    const despesaStyleBold = seccoes > 2;

    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            fontWeight: despesaStyleBold ? "bold" : "normal",
            margin: "0 -3px",
          },
        ]}
      >
        {optionDespesaDemonstrativo?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={codigo || ""}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textAlign: "left",
              },
            ]}
          />
        )}
        {optionDespesaDemonstrativo?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={titulo}
            styledCol={[styledCol[1], { padding: "2px 0", textAlign: "left" }]}
          />
        )}

        {optionDespesaDemonstrativo?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={
              despesa.total ? FormatValueToLocaleString(despesa.total) : ""
            }
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
                flexBasis: 180,
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderUnidade = (codigo, titulo) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            backgroundColor: "#a6a6a6",
            fontWeight: "bold",
            margin: "0 -3px",
          },
        ]}
        fixed
      >
        {optionDespesaDemonstrativo?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={codigo}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaDemonstrativo?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={titulo}
            styledCol={[
              styledCol[1],
              {
                padding: "2px 0",
                textTransform: "capitalize",
                textAlign: "left",
              },
            ]}
          />
        )}
        {optionDespesaDemonstrativo?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
                flexBasis: 180,
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderTotal = (textTypeTotal, total) => {
    return (
      <View
        wrap={false}
        style={[
          stylesPdf.divInferiorColuna,
          {
            fontWeight: "bold",
            fontSize: 10,
            textTransform: "uppercase",
            color: "white",
            flexDirection: "row",
            justifyContent: "space-between",
            marginHorizontal: -3,
            paddingRight: 8,
          },
        ]}
      >
        <Text>{textTypeTotal}</Text>
        <Text>{total ? FormatValueToLocaleString(total) : ""}</Text>
      </View>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={
          "Despesa por Classificação Funcional Programática Função Subfunção Programa Ação"
        }
        plusText={"Anexo V"}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {optionDespesaDemonstrativo?.checkBoxes?.map((option, index) => (
          <PdfColunas
            key={index}
            colName={option.label.toUpperCase()}
            styledCol={styledCol[index]}
            hideBar
          />
        ))}
      </View>
      <React.Fragment>
        <React.Fragment>
          {dados.dataDespesa.map((despesa, unidadeIndex) => (
            <React.Fragment key={unidadeIndex}>
              {Object.keys(despesa.infoUnidade.unidades).map(
                (unidadeKey, unidadeIndex) => {
                  const unidade = despesa.infoUnidade.unidades[unidadeKey];
                  const relatorios = unidade.relatorio;

                  return (
                    <View
                      key={`despesaFuncao-${unidadeIndex}`}
                      break={unidadeIndex > 0}
                    >
                      {renderUnidade(
                        unidade.codigoUnidadeOrcamentaria,
                        unidade.tituloUnidadeOrcamentaria
                      )}

                      {Object.keys(relatorios).map(
                        (relatorioKey, relatorioIndex) => {
                          const despesaFuncao = relatorios[relatorioKey];
                          const codigoFuncao = despesaFuncao.codigoFuncao;

                          return (
                            <React.Fragment key={relatorioIndex}>
                              {renderReceitas(
                                codigoFuncao,
                                despesaFuncao.tituloFuncao,
                                despesaFuncao
                              )}

                              {Object.keys(despesaFuncao.subFuncoes)
                                .sort((a, b) => Number(a) - Number(b))
                                .map((subFuncaoKey, subFuncaoIndex) => {
                                  const subFuncao =
                                    despesaFuncao.subFuncoes[subFuncaoKey];
                                  const codigoSubFuncao = `${codigoFuncao}.${subFuncao.codigoSubFuncao}`;

                                  return (
                                    <React.Fragment
                                      key={`subFuncao-${subFuncaoIndex}`}
                                    >
                                      {renderReceitas(
                                        codigoSubFuncao,
                                        subFuncao.tituloSubFuncao,
                                        subFuncao
                                      )}

                                      {Object.keys(subFuncao.programas).map(
                                        (programaKey, programaIndex) => {
                                          const programa =
                                            subFuncao.programas[programaKey];
                                          const codigoPrograma = `${codigoSubFuncao}.${programaKey}`;

                                          return (
                                            <React.Fragment
                                              key={`programa-${programaIndex}`}
                                            >
                                              {renderReceitas(
                                                codigoPrograma,
                                                programa.tituloPrograma,
                                                programa
                                              )}

                                              {Object.keys(programa.acoes).map(
                                                (acaoKey, acaoIndex) => {
                                                  const acao =
                                                    programa.acoes[acaoKey];
                                                  const codigoAcao = `${codigoPrograma}.${acaoKey}`;

                                                  return (
                                                    <React.Fragment
                                                      key={acaoIndex}
                                                    >
                                                      {renderReceitas(
                                                        codigoAcao,
                                                        acao.tituloAcao,
                                                        acao
                                                      )}
                                                    </React.Fragment>
                                                  );
                                                }
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              {/* {renderTotal("subtotal", unidade.totalDaUnidade)} */}
                            </React.Fragment>
                          );
                        }
                      )}
                      {renderTotal("subtotal", unidade.totalDaUnidade)}
                    </View>
                  );
                }
              )}
            </React.Fragment>
          ))}

          <View style={{ marginTop: 10 }}>
            {renderTotal("total geral", dados.total)}
          </View>
        </React.Fragment>
      </React.Fragment>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
