import styled from "styled-components";

export const LabelSelectFileImgUser = styled.label`
  cursor: pointer;

  & input {
    display: none;
  }

  & div {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const CheckBoxCircle = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #d9d9d9;
  border: 4px solid #d9d9d9;
  margin-right: 5px;
  transition: background-color 0.1s;
  cursor: pointer;

  &:checked {
    background-color: #0d6efd;
  }
`;
