import React from "react";
import styled from "styled-components";

const InfoBox = styled.div`
  background-color: #e9edee;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px 20px;
  cursor: pointer;
`;

const NumberBox = styled.div`
  background-color: ${(props) =>
    props.percentage === 0
      ? "#b0b0b7"
      : props.percentage < 100
      ? props.error
        ? "#f67f4a"
        : "#f6bf4a"
      : "#19cf70"};
  border-radius: 5px;
  padding: 7px 0;
  min-width: 50px;
  height: fit-content;
  text-align: center;
  color: #ffffff;
  font-size: 25px;
  font-weight: 600;
  margin-right: 15px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0df;
  border-radius: 3px;
  overflow: hidden;
  margin: 10px 0;
  height: 13px;
`;

const ProgressBarFiller = styled.div`
  height: 100%;
  width: ${(props) => props.percentage}%;
  background-color: ${(props) =>
    props.percentage === 0
      ? "#b0b0b7"
      : props.percentage < 100
      ? props.error
        ? "#f67f4a"
        : "#f6bf4a"
      : "#19cf70"};
  border-radius: 3px;
  text-align: right;
  transition: width 0.2s ease-in;
`;

const PercentageLabel = styled.div`
  margin-left: 10px;
  font-weight: bold;
  color: ${(props) =>
    props.percentage === 0
      ? "#b0b0b7"
      : props.percentage < 100
      ? props.error
        ? "#f67f4a"
        : "#f6bf4a"
      : "#19cf70"};
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressCard = ({ number, month, percentage, error, onClick }) => {
  return (
    <InfoBox onClick={onClick}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <NumberBox percentage={percentage} error={error}>
          {number < 10 ? "0" + number : number}
        </NumberBox>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <span style={{ fontWeight: "700", fontSize: "18px" }}>{month}</span>
          <span style={{ fontSize: "14px" }}>
            {percentage === 0
              ? "Aberto"
              : percentage < 100
              ? "Em Processo"
              : "Processado"}
          </span>
        </div>
      </div>
      <ProgressBarWrapper>
        <ProgressBarContainer>
          <ProgressBarFiller percentage={percentage} error={error} />
        </ProgressBarContainer>
        <PercentageLabel percentage={percentage} error={error}>
          {`${percentage}%`}
        </PercentageLabel>
      </ProgressBarWrapper>
    </InfoBox>
  );
};

export default ProgressCard;
