import Form from "react-bootstrap/Form";
import { Autocomplete, TextField } from "@mui/material";
import styled from "styled-components";

export const AutocompleteStyled = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    font-family: "Poppins", sans-serif;
    height: 38px;
    border-radius: 5px;
  }
`;

export const TextFieldStyled = styled(TextField)`
  .MuiInputLabel-root {
    font-family: "Poppins", sans-serif;
    margin-left: 22px;
    font-weight: 500;
    color: #515c70;
    z-index: 0;
  }

  .MuiInputLabel-root.Mui-focused {
    margin-left: 2px;
    color: #515c70;
  }

  .MuiInputLabel-shrink {
    margin-left: 2px;
    color: #515c70;
  }

  .MuiInputBase-input {
    margin-left: 22px;
  }

  .MuiInputBase-root {
    fieldset {
      border-color: #cccccc;
    }

    &:hover {
      fieldset {
        border-color: #cccccc;
      }
    }

    &.Mui-focused {
      fieldset {
        border-color: #cccccc;
        border-width: 1px;
      }
    }
  }
`;

export const FormSelectStyled = styled(Form.Select)`
  background: transparent;
  border-color: #cccccc;
  ${({ height }) => (height !== undefined ? `height: ${height};` : null)}

  &.form-select {
    ${({ tamanhofonte }) =>
      tamanhofonte !== undefined ? `font-size: ${tamanhofonte};` : null}
    ${({ $formlistadeinputs }) =>
      $formlistadeinputs
        ? `line-height: normal; padding: 0px 0px 0px 12px;`
        : null}
    ${({ radiusborder }) =>
      radiusborder ? `border-radius: ${radiusborder}` : null}
  }

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: #cccccc;
  }
`;

export const FormControlStyled = styled(Form.Control)`
  background: transparent;
  padding-left: 27px;
`;
