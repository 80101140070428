import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import FloatingLabelInput from "../Grid/FloatingLabelInput";
import { LabelCheckLOA,ContainerOptions } from "./StyledCompsRelatorio";
import InputSearch from "../Inputs/InputSearch";
import { useRef } from "react";

const AbaPrincipalRelatorio = ({
  principalOptions = [],
  labelsCheckAbaPrincipal = [],
  setLabelsCheckAbaPrincipal,
  // handleOptionSelected
}) => {
  const [valueInputSearch, setValueInputSearch] = useState("");
  const [optionSelectPrincipal, setOptionSelectPrincipal] = useState(
    principalOptions.find((item) => item.value !== "")?.value
  );
  const [checkBoxTodosSelecionados, setCheckBoxTodosSelecionados] =
    useState(false);
  const floatingLabelInputRef = useRef(null);

  // const gerenciarSelects = (idDaLabel) => {
  //   const indexOpcao = labelsCheckAbaPrincipal.findIndex(
  //     (item) => item.opcao === optionSelectPrincipal
  //   );
  //   console.log(labelsCheckAbaPrincipal);

  //   const newArray = labelsCheckAbaPrincipal[indexOpcao].checkBoxes.map(item =>
  //     item.value === idDaLabel ? { ...item, selected: !item.selected } : item
  //   );
  //   console.log(newArray);
  //   setLabelsCheckAbaPrincipal(newArray);

  //   // console.log(indexOpcao);

  //   // console.log(indexDaLabel);

  //   // let newArray = [...labelsCheckAbaPrincipal];
  //   // console.log(newArray[indexOpcao].checkBoxes[indexDaLabel]);
  //   // newArray[indexOpcao].checkBoxes[indexDaLabel].selected =
  //   //   !newArray[indexOpcao].checkBoxes[indexDaLabel].selected;

  //   // console.log(newArray);

  //   // setLabelsCheckAbaPrincipal([...newArray]);
  // };
  const gerenciarSelects = (idDaLabel) => {
    const indexOpcao = labelsCheckAbaPrincipal.findIndex(
      (item) => item.opcao === optionSelectPrincipal
    );
    
    console.log(idDaLabel);
    console.log(indexOpcao);

    if (indexOpcao === -1) return; // Verifica se a opção foi encontrada
  
    // Atualiza os checkBoxes da opção selecionada
    const updatedCheckBoxes = labelsCheckAbaPrincipal[indexOpcao].checkBoxes.map(item =>
      Number(item.value) === Number(idDaLabel) ? { ...item, selected: !item.selected } : item
    );
    console.log(updatedCheckBoxes);
  
    // Atualiza a estrutura completa do labelsCheckAbaPrincipal
    const newArray = labelsCheckAbaPrincipal.map((item, index) =>
      index === indexOpcao
        ? { ...item, checkBoxes: updatedCheckBoxes }
        : item
    );
    console.log(newArray);
  
    setLabelsCheckAbaPrincipal(newArray);
  };

  const checkAllBoxes = (checked) => {
    console.log(checked);
    const indexOpcao = labelsCheckAbaPrincipal.findIndex(
      (item) => item.opcao === optionSelectPrincipal
    );

    let newArray = [...labelsCheckAbaPrincipal];

    if (checked) {
      newArray[indexOpcao].checkBoxes.forEach((item) => {
        item.selected = true;
      });

      console.log(newArray);
    } else {
      newArray[indexOpcao].checkBoxes.forEach((item) => {
        item.selected = false;
      });

      console.log(newArray);
    }

    setLabelsCheckAbaPrincipal(newArray);
  };

  useEffect(() => {
    if (optionSelectPrincipal) {
      console.log(labelsCheckAbaPrincipal
        .find((item) => item.opcao === optionSelectPrincipal)
        ?.checkBoxes.every((option) => option.selected === true));
      setCheckBoxTodosSelecionados(
        labelsCheckAbaPrincipal
          .find((item) => item.opcao === optionSelectPrincipal)
          ?.checkBoxes.every((option) => option.selected === true)
      );
    }
  }, [optionSelectPrincipal, labelsCheckAbaPrincipal]);

  return (
    <>
      <Col style={{ padding: "0 10px", marginTop: "10px" }} md={12}>
        <FloatingLabelInput
          new
          ref={floatingLabelInputRef}
          tipo="input_select"
          label="Relatório por..."
          onChange={(e) => {
            console.log(e.target.value);
            setOptionSelectPrincipal(e.target.value)
          }}
          value={optionSelectPrincipal}
          options={[
            { label: "Relatório por...", value: null },
            ...principalOptions,
          ]}
          onSelect={(option) =>{
            console.log(option.value);
            // handleOptionSelected(option.value)
            setOptionSelectPrincipal(option.value)
          }
          }
        />
      </Col>
      <Col style={{ padding: "0 10px", marginTop: "10px" }} md={12}>
        <InputSearch
          value={valueInputSearch}
          onChange={(e) => setValueInputSearch(e.target.value)}
        />
      </Col>
      <ContainerOptions
        
        md={12}
      >

        {valueInputSearch.length === 0 &&
          labelsCheckAbaPrincipal.find(
            (item) => item.opcao === optionSelectPrincipal
          )?.checkBoxes.length > 0 && (
            <LabelCheckLOA
              label={"Todos Selecionados"}
              checked={checkBoxTodosSelecionados}
              onChange={(e) => {
                checkAllBoxes(e.target.checked);
              }}
            />
          )}

        {valueInputSearch.length === 0 &&
          labelsCheckAbaPrincipal
            .find((item) => item.opcao === optionSelectPrincipal)
            ?.checkBoxes.map((option, index) => (
              <LabelCheckLOA
                key={index}
                title={option.label}
                label={`${option.label.length > 50 ? `${option.label.substring(0, 50)}...`: option.label}`}
                value={option.value}
                checked={option.selected}
                onChange={(e) => {
                  gerenciarSelects(e.target.value);
                  console.log(e.target.value);
                }}
              />
            ))}
{/* `${option.label.length > 50 ? `${option.label.substring(0, 50)}...`: option.label}` */}
        {valueInputSearch.length > 0 &&
          labelsCheckAbaPrincipal
            .find((item) => item.opcao === optionSelectPrincipal)
            ?.checkBoxes.filter((item) =>
              item.label.toLowerCase().includes(valueInputSearch.toLowerCase())
            )
            .map((option, index) => (
              <LabelCheckLOA
                key={index}
                title={option.label}
                label={`${option.label.length > 50 ? `${option.label.substring(0, 50)}...`: option.label}`}
                value={option.value}
                checked={option.selected}
                onChange={(e) => {
                  console.log(e.target.value);
                  gerenciarSelects(e.target.value);
                }}
              />
            ))}
      </ContainerOptions>
    </>
  );
};

export default AbaPrincipalRelatorio;
