export const isBase64 = (str) => {
  const isBase64 = /^data:'?image\/[a-z]+';base64,[a-zA-Z0-9+/=]+$/.test(str);
  if (!isBase64) {
    return [false, false];
  }
  const base64Content = str.split(",")[1];
  const lengthIsValid = base64Content.length % 4 === 0;
  const paddingIsValid =
    base64Content.endsWith("=") || !base64Content.includes("=");
  return [isBase64, lengthIsValid && paddingIsValid];
};
