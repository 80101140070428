export const calcularTotalReceitas = (
  dadosRelatorio,
  codigosSectionRelatorio
) => {
  // const receitasSemDeducao = dadosRelatorio?.naturezas?.filter((item) => codigosSectionRelatorio.includes(item.nr))
  // console.log(receitasSemDeducao);
  // const totalReceitasSemDeducao = receitasSemDeducao.reduce((acc, item) =>{
  //   return acc += item.valor
  // },0)
  const totalReceitasSemDeducao = calcularTotalReceitasSemDeducao(
    dadosRelatorio,
    codigosSectionRelatorio
  );

  const totalReceitasComDeducao = calcularTotalReceitasComDeducao(
    dadosRelatorio,
    codigosSectionRelatorio
  );
  const totalReceitas = totalReceitasSemDeducao - totalReceitasComDeducao;
  return totalReceitas;
};

export const calcularTotalReceitasComDeducao = (
  dadosRelatorio,
  codigosSectionRelatorio
) => {
  if (!dadosRelatorio?.naturezasDeducao) return 0;

  const receitasComDeducao = dadosRelatorio?.naturezasDeducao?.filter((item) =>
    codigosSectionRelatorio.includes(item.nr)
  );

  const totalReceitasComDeducao = receitasComDeducao.reduce((acc, item) => {
    return (acc += item.valor);
  }, 0);
  return totalReceitasComDeducao;
};

export const calcularTotalReceitasSemDeducao = (
  dadosRelatorio,
  codigosSectionRelatorio
) => {
  if (!dadosRelatorio?.naturezas) return 0;

  const receitasSemDeducao = dadosRelatorio?.naturezas?.filter((item) =>
    codigosSectionRelatorio.includes(item.nr)
  );

  const totalReceitasSemDeducao = receitasSemDeducao.reduce((acc, item) => {
    return (acc += item.valor);
  }, 0);
  return totalReceitasSemDeducao;
};
