import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";

export const RenderPdfDemoEstimativa = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    {
      flexBasis: 80,
      flexShrink: 1,
      flexGrow: 1,
    },
    {
      flexBasis: 140,
      flexShrink: 1,
      flexGrow: 1,
    },
  ];

  const renderTextColumn = (text, style = {}) => (
    <Text
      style={[
        stylesPdf.textTituloColuna,
        style,
        { textTransform: "uppercase", fontSize: 8 },
      ]}
    >
      {text}
    </Text>
  );
  const renderHeader = () => {
    return (
      <>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: 38,
              },
            ]}
          >
            {renderTextColumn("tributo")}
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: 38,
              },
            ]}
          >
            {renderTextColumn("modalidade")}
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: 38,
              },
            ]}
          >
            {renderTextColumn("setores/ \nprogramas/ \nbeneficiário")}
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[1]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 4,
                justifyContent: "space-between",
                height: 38,
              },
            ]}
          >
            {renderTextColumn("RENUNCIA DE RECEITA PREVISTA ")}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {dados.contentHeader.map((item, index) => (
                <React.Fragment key={index}>
                  {renderTextColumn(item, { width: "100%" })}
                </React.Fragment>
              ))}
            </View>
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: 38,
              },
            ]}
          >
            {renderTextColumn("compensação")}
          </View>
        </View>
      </>
    );
  };

  const renderTotal = () => {
    return (
      <>
        <View
          wrap={false}
          style={[
            stylesPdf.divInferiorColuna,
            {
              fontWeight: "bold",
              fontSize: 8,
              textTransform: "uppercase",
              color: "white",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: 0,
              paddingHorizontal: 0,
              paddingLeft: 10,
            },
          ]}
        >
          <Text>total</Text>
          <View style={{ flexDirection: "row" }}></View>
        </View>
      </>
    );
  };

  return (
    <Page size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Estimativa e Compensação da Renúncia de Receita"}
        anoRelatorio={currentYear}
        plusText={"Anexo de Metas Fiscais"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: 8,
          padding: "0 4px",
        }}
      >
        <Text>AMF - Demonstrativo 7 (LRF, art. 4º, § 2º, inciso V)</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
        {renderHeader()}
      </View>
      <View style={stylesPdf.divRelatorio}></View>

      <View style={{ marginTop: 10 }} />
      {renderTotal()}
      <Text
        style={{ fontWeight: "bold", marginLeft: 8, marginTop: 2, fontSize: 8 }}
      >
        FONTE: Secretaria de Finanças
      </Text>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
