import React from "react";

export const TestButton = ({ children, ...props }) => {
  const isDevelopment = process.env.NODE_ENV === "development";
  if (!isDevelopment) {
    return null;
  }

  return <button {...props}>{children}</button>;
};
