import styled from "styled-components";

const InputContainer = styled.div`
  border: 1px solid #cccccc;
  padding: 6px;
  padding-left: 10px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ disable, backgroundColor }) =>
    disable ? "#e9ecef" : backgroundColor ? backgroundColor : ""};
`;

const InputUploadStyle = styled.input`
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  width: 100%;
  font-weight: 500;
  color: #515c70;
`;

const UploadIcon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='%23515C70' d='M15.086 12.469a.75.75 0 0 1-1.055.117l-1.28-1.026V15a.75.75 0 1 1-1.5 0v-3.44l-1.282 1.026a.75.75 0 0 1-.937-1.172l2.497-1.998A.747.747 0 0 1 12 9.25h.002c.18 0 .344.064.473.17l2.494 1.994a.75.75 0 0 1 .117 1.055'/%3E%3Cpath fill='%23515C70' fill-rule='evenodd' d='M8.38 7.194a5.41 5.41 0 0 1 9.952 2.605a4.478 4.478 0 1 1 .191 8.951H6.875A5.875 5.875 0 1 1 8.38 7.194m4.552-.986a3.91 3.91 0 0 0-3.524 2.214a.75.75 0 0 1-.947.373a4.375 4.375 0 1 0-1.586 8.455h11.648a2.978 2.978 0 1 0-.77-5.855a.75.75 0 0 1-.939-.812a3.91 3.91 0 0 0-3.882-4.374' clip-rule='evenodd'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 23px;
  width: 24px;
  height: 24px;
`;

export const InputUpload = ({
  handleDate,
  disable = false,
  backgroundColor,
  ...props
}) => {
  return (
    <InputContainer disable={disable} backgroundColor={backgroundColor}>
      <InputUploadStyle
        type="text"
        onChange={(e) => handleDate(e.target.value)}
        disabled={disable}
        {...props}
      />
      <UploadIcon />
    </InputContainer>
  );
};
