import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import api from "../../utils/api";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { FormatInternUserObject } from "../../utils/Gerais/FormatInterUserObject";
import { Navigate } from "react-router-dom";
import { CurrentClientContext } from "../../contexts/CurrentClientContext";

const PrivateRoute = ({ children }) => {
  const bearerToken = localStorage.getItem("token");
  const [userAuthenticated, setUserAuthenticated] = useState(null);
  const { setUserAuthData, setTokenAuth } = useContext(AuthContext);
  const { setCurrentClient } = useContext(CurrentClientContext);

  const fillUserData = async (usuario) => {
    if (usuario.cnpj) {
      const userObject = await FormatInternUserObject(usuario);
      console.log(userObject);
      setUserAuthData(userObject);
      return userObject;
    } else {
      setUserAuthData(usuario);
      return usuario;
    }
  };

  const verifyUserHistory = (usuario) => {
    const userHistory = JSON.parse(localStorage.getItem("userHistory"));
    console.log(userHistory);
    console.log(usuario);

    if (userHistory && userHistory.userId === usuario.userId) {
      console.log(usuario.clientes);
      const clienteAtual = usuario.clientes.find(
        (item) => item.clienteId === userHistory.clienteId
      );
      console.log(clienteAtual);
      setCurrentClient(clienteAtual);
    } else {
      const clienteInicial = usuario.clientes[0];

      localStorage.setItem(
        "userHistory",
        JSON.stringify({
          clienteId: clienteInicial.id,
          regiao: {
            estado: clienteInicial.endereco.estado,
            municipio: clienteInicial.endereco.municipio,
          },
          sistema: "gerenciador",
          userId: usuario.userId,
        })
      );
      console.log(clienteInicial);

      setCurrentClient(clienteInicial);
    }
  };

  const authUser = async () => {
    try {
      const response = await api.get("usuario/pesquisa-via/token");
      const data = response.data;
      console.log(data);

      const user = await fillUserData(data);
      console.log(user);
      verifyUserHistory(user);
      setUserAuthenticated(true);
      setTokenAuth(bearerToken);
    } catch (error) {
      console.log(error);
      setUserAuthenticated(false);
    }
  };

  useEffect(() => {
    authUser();

    //eslint-disable-next-line
  }, []);

  if (userAuthenticated === null) {
    return <div>...</div>;
  }

  return userAuthenticated === false ? <Navigate to="/" /> : children;
};

export default PrivateRoute;
