import React from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

export const FonteDeRecursosAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Fonte de Recursos / CO - Pagamento</span>
        </div>
      </RowTituloAba>

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol>
          <FloatingLabelInput
            disabled
            style={{ border: "none" }}
            placeholder="Fonte de Recursos"
            label="Fonte de Recursos"
          />
        </CCol>
      </RowForm>
    </>
  );
};
