import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfDespesaDemonstrativoOrgaoFuncao = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: "90px", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    {
      flexBasis: "90px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const optionDespesaDemonstrativo = opcoes.find(
    (option) => option.opcao === "Quadro Detalhado da Receita - QDR"
  );

  const renderReceitas = (codigo, titulo, despesa, forceBold = false) => {
    const seccoes = (codigo.match(/\./g) || []).length;

    const despesaStyleBold = seccoes > 2;

    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            marginHorizontal: -3,
            fontWeight: despesaStyleBold || forceBold ? "bold" : "normal",
          },
        ]}
      >
        {optionDespesaDemonstrativo?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={codigo || ""}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaDemonstrativo?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={titulo}
            styledCol={[styledCol[1], { padding: "2px 0", textAlign: "left" }]}
          />
        )}

        {optionDespesaDemonstrativo?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={
              despesa.total ? FormatValueToLocaleString(despesa.total) : ""
            }
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
                flexBasis: 180,
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderUnidade = (codigo, titulo) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            backgroundColor: "#a6a6a6",
            fontWeight: "bold",
            marginHorizontal: -3,
          },
        ]}
      >
        {optionDespesaDemonstrativo?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={codigo}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaDemonstrativo?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={titulo}
            styledCol={[
              styledCol[1],
              {
                padding: "2px 0",
                textTransform: "capitalize",
                textAlign: "left",
              },
            ]}
          />
        )}
        {optionDespesaDemonstrativo?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
                flexBasis: 180,
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderTotal = (textTotalType, total) => {
    return (
      <View
        wrap={false}
        style={[
          stylesPdf.divInferiorColuna,
          {
            fontWeight: "bold",
            fontSize: 10,
            textTransform: "uppercase",
            color: "white",
            flexDirection: "row",
            justifyContent: "space-between",
            // marginBottom: 10,
          },
        ]}
      >
        <Text>{textTotalType}</Text>
        <Text>{total}</Text>
      </View>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Demonstrativo da Despesa por Órgãos e Funções"}
        plusText={"Anexo IX"}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {optionDespesaDemonstrativo?.checkBoxes?.map((option, index) => (
          <PdfColunas
            key={index}
            colName={option.label.toUpperCase()}
            styledCol={styledCol[index]}
            hideBar
          />
        ))}
      </View>
      <React.Fragment>
        <React.Fragment>
          {dados.dataDespesa.map((despesa, unidadeIndex) => (
            <View
              key={unidadeIndex}
              style={{ marginTop: unidadeIndex > 0 ? "10px" : "0" }}
            >
              {renderUnidade(
                despesa.infoUnidade.idUnidade,
                despesa.infoUnidade.unidadeGestora
              )}

              {Object.keys(despesa.infoUnidade.unidades).map(
                (funcaoKey, funcaoIndex) => {
                  const despesaFuncao = despesa.infoUnidade.unidades[funcaoKey];
                  const codigoFuncao = despesaFuncao.codigoFuncao;
                  return (
                    <React.Fragment key={funcaoIndex}>
                      {renderReceitas(
                        codigoFuncao,
                        despesaFuncao.tituloFuncao,
                        despesaFuncao,
                        true
                      )}
                      {renderReceitas("", despesaFuncao.descricaoFuncao, "")}
                    </React.Fragment>
                  );
                }
              )}
              {renderTotal(
                "subtotal",
                FormatValueToLocaleString(
                  despesa.infoUnidade.totalGeralUnidade.totalEspeciaisCliente +
                    despesa.infoUnidade.totalGeralUnidade.totalProjetosCliente +
                    despesa.infoUnidade.totalGeralUnidade.totalAtividadesCliente
                )
              )}
              {/* <View break={unidadeIndex > 0} /> */}
            </View>
          ))}
          <View style={{ marginTop: 10 }}>
            {renderTotal("total geral", FormatValueToLocaleString(dados.total))}
          </View>
        </React.Fragment>
      </React.Fragment>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
