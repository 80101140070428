export const permissoes = [
  {
    menu: "Clientes",
    menuAtivo: false,
    acoes: [
      { acao: "Novo", acaoAtiva: false },
      { acao: "Editar", acaoAtiva: false },
      { acao: "Excluir", acaoAtiva: false },
    ],
  },
  {
    menu: "Usuário",
    menuAtivo: false,
    acoes: [
      { acao: "Novo", acaoAtiva: false },
      { acao: "Editar", acaoAtiva: false },
      { acao: "Excluir", acaoAtiva: false },
    ],
  },
  {
    menu: "Financeiro",
    menuAtivo: false,
    acoes: [
      { acao: "Novo", acaoAtiva: false },
      { acao: "Editar", acaoAtiva: false },
      { acao: "Excluir", acaoAtiva: false },
    ],
  },
  {
    menu: "SaveBot",
    menuAtivo: false,
    acoes: [
      { acao: "Novo", acaoAtiva: false },
      { acao: "Editar", acaoAtiva: false },
      { acao: "Excluir", acaoAtiva: false },
    ],
  },
  {
    menu: "Cadastro",
    menuAtivo: false,
    acoes: [
      { acao: "Novo", acaoAtiva: false },
      { acao: "Editar", acaoAtiva: false },
      { acao: "Excluir", acaoAtiva: false },
    ],
  },
  {
    menu: "Módulos",
    menuAtivo: false,
    acoes: [
      { acao: "Novo", acaoAtiva: false },
      { acao: "Editar", acaoAtiva: false },
      { acao: "Excluir", acaoAtiva: false },
    ],
  },
];

export const userTypes = [
  { label: "Suporte", value: 3 },
  { label: "Master", value: 1 },
];
