import React, { useState, useContext, useEffect } from "react";
import { CurrentYearContext } from "../../../../../contexts/YearContext";
import {
  CCol,
  RowAninhada,
  CColButtonsAcoes2Icons,
} from "../../../../../components/Grid/CCol";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { FormControlListaDeInputs } from "../../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../../components/ListaDeInputs/IconAdicionar";

const CustomInput = ({ title, md = 2, size, Btop, Bbottom, all, ...props }) => {
  return (
    <CCol md={md} style={size && { width: size }}>
      {title && <Title>{title}</Title>}
      <FormControlListaDeInputs
        radiusborder={
          Btop
            ? "5px 5px 0 0"
            : Bbottom
            ? "0 0 5px 5px"
            : all
            ? "5px 5px 5px 5px"
            : "0"
        }
        {...props}
      />
    </CCol>
  );
};

const AbaLicitacao = () => {
  const { currentYear } = useContext(CurrentYearContext);

  const [titles, setTitles] = useState([]);

  const updateTitles = () => {
    setTitles([
      "Nº Licitação",
      "Modalidade",
      "Data Homologação",
      "Valor",
      "Tipo de Objeto",
      "Objeto",
    ]);
  };

  useEffect(() => {
    updateTitles();
    // eslint-disable-next-line
  }, [currentYear]);

  return (
    <>
      <Row className="row_form mt-1">
        <StyledCCol style={{ height: "60px" }}>
          <RowAninhada
            style={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <CustomInput title={titles[0]} all md={1} />
            <CustomInput title={titles[1]} all md={4} />
            <CustomInput title={titles[2]} all />
            <CustomInput title={titles[3]} all />
            <CustomInput title={titles[4]} all md={3} />
            <CustomInput title={titles[5]} all md={3} />
          </RowAninhada>
        </StyledCCol>
        <CColButtonsAcoes2Icons md={2} style={{ marginLeft: "5px" }}>
          <Title>Ação</Title>
          <IconAdicionar />
        </CColButtonsAcoes2Icons>
      </Row>
    </>
  );
};

export default AbaLicitacao;

const Title = styled.p`
  text-align: left;
  margin-top: 0;
  padding: 0px;
  margin-bottom: 0px;
  color: #888a8e;
  font-weight: 600;
  font-size: 12px;
`;

const StyledCCol = styled(CCol)`
  overflow: auto;

  &::-webkit-scrollbar {
    height: 2px;
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
