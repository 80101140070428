export const CPFMaskCustom = (value) => {
  let formattedValue = "";
  const onlyNumbers = value.replace(/\D/g, "");

  if (/^\d+$/.test(onlyNumbers)) {
    if (onlyNumbers.length <= 11) {
      let index = 0;
      const mask = "###.###.###-##";

      for (let i = 0; i < mask.length && index < onlyNumbers.length; i++) {
        if (mask[i] === "#") {
          formattedValue += onlyNumbers[index];
          index++;
        } else {
          formattedValue += mask[i];
        }
      }
    } else {
      formattedValue = value;
    }
  } else {
    formattedValue = value;
  }

  return formattedValue;
};
