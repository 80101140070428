import styled from "styled-components";
import { useState, useContext } from "react";
import { ReactComponent as LogoSave } from "../../assets/logoSave/logoSave.svg";
import imageLogin from "../../assets/imageLogin/imageLogin.png";
import { FaUser } from "react-icons/fa";
import { MdOutlineLock } from "react-icons/md";
import ReactInputMask from "react-input-mask";
import { AuthContext } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import api from "../../utils/api";
import { CurrentClientContext } from "../../contexts/CurrentClientContext";
import { CurrentCityContext } from "../../contexts/CurrentCityContext";
import { FormatInternUserObject } from "../../utils/Gerais/FormatInterUserObject";

const DivTela = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(217, 222, 242);
  background: linear-gradient(
    180deg,
    rgba(217, 222, 242, 1) 42%,
    rgba(253, 253, 253, 1) 84%,
    rgba(240, 200, 255, 0.997084502746411) 100%
  );
`;

const DivFormLogin = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 62px 0 0 62px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
`;

const DivImage = styled.div`
  position: relative;
  width: 50%;
  margin-top: 150px;
  margin-left: 30px;
  margin-bottom: 20px;
  z-index: 2;
`;

const ImgLogin = styled.img`
  position: absolute;
  width: 120%;
  height: 100%;
  left: 50px;
`;

const Label = styled.label`
  width: 150%;
  font-size: 18px;
  font-weight: 500;
  text-align: right;
  margin-top: 14px;
  margin-bottom: 44px;
  color: #bdbdbd;
`;

const Alink = styled(Link)`
  text-decoration: none;
  color: #448ecb;
  margin-left: 5px;
  font-weight: 500;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 120%;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  padding: 10px;
  margin-top: 55px;
  color: #bdbdbd;
  background: #fdfdfd;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  box-sizing: border-box;
  width: 150%;
  height: 60px;
`;

const InputField = styled(ReactInputMask)`
  flex: 1;
  border: none;
  outline: none;
  font-weight: 500;
  color: #757575;
`;

const UserIcon = styled(FaUser)`
  margin-right: 0.5rem;
  width: 32px;
`;

const LockIcon = styled(MdOutlineLock)`
  margin-right: 0.5rem;
  width: 32px;
  height: 25px;
`;

const SubmitButton = styled.button`
  width: 150%;
  height: 60px;
  padding: 10px 20px;
  background: #74bee9;
  border-radius: 10px;
  color: #fff;
  border: none;

  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #006cba;
  }
`;

const Header = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: transparent;
  z-index: 1;
`;

const NavLinks = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin-right: 50px;
`;

const NavLink = styled.li`
  margin-right: 50px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  cursor: pointer;
`;

const MsgErro = styled.p`
  color: red;
  position: relative;
  top: 20px;
  left: -75px;
  margin-bottom: -18px;
  margin-top: -8px;
  animation: ${(props) => (props.clicksubmit ? "shake2 0.2s" : "")};

  @keyframes shake2 {
    0% {
      transform: translateX(0rem);
    }
    20% {
      transform: translateX(-0.5rem);
    }
    40% {
      transform: translateX(0.5rem);
    }
    60% {
      transform: translateX(-0.5rem);
    }
    80% {
      transform: translateX(0.5rem);
    }
    100% {
      transform: translateX(0rem);
    }
  }
`;

function Login() {
  const [loginValues, setLoginValues] = useState({
    cpf: "",
    senha: "",
  });
  const navigate = useNavigate();
  const [erroLogin, setErroLogin] = useState(false);
  const [clickSubmit, setClickSubmit] = useState(false);
  const { setUserAuthData, setTokenAuth } = useContext(AuthContext);
  const { setCurrentCity } = useContext(CurrentCityContext);
  const { setCurrentClient } = useContext(CurrentClientContext);

  const navigateToInitialPage = (system) => {
    switch (system.toLowerCase()) {
      case "planejamento":
        navigate("/planejamento/inicio");
        return;
      default:
        navigate("/home-test");
        return;
    }
  };

  const verifyUserHistory = (usuario) => {
    return new Promise((resolve, reject) => {
      try {
        const userHistory = JSON.parse(localStorage.getItem("userHistory"));
        console.log(usuario);

        const clienteInicial = usuario.clientes.filter(
          (cliente) =>
            cliente.clienteAtivo === 1 &&
            cliente.permissoes.sistemas.some(
              (sistema) => sistema.sistemaAtivo === 1
            )
        )[0];

        const sistemaInicial = clienteInicial.permissoes.sistemas.filter(
          (sistema) => sistema.sistemaAtivo === 1
        )[0];

        const object = {
          userId: usuario.userId,
          clienteId: clienteInicial?.clienteId,
          sistema: sistemaInicial?.sistema,
          regiao: {
            estado: clienteInicial?.endereco.estado,
            municipio: clienteInicial?.endereco.municipio,
          },
        };

        console.log(object);

        console.log(userHistory);

        if (userHistory && userHistory.userId === usuario.userId) {
          console.log("é o mesmo id");
          if (userHistory.sistema.toLowerCase() === "planejamento") {
            console.log("vai entrar no sistema de planejamento");
            navigateToInitialPage(userHistory.sistema);
          } else {
            navigateToInitialPage(object.sistema);
          }
        } else {
          localStorage.setItem("userHistory", JSON.stringify(object));

          setCurrentClient({ ...clienteInicial });
          setCurrentCity(object.regiao);

          navigateToInitialPage(object.sistema);
        }

        resolve({
          message: "Usuário verificado com sucesso.",
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const verifyInternUserHistory = (usuario) => {
    const userHistory = JSON.parse(localStorage.getItem("userHistory"));

    if (userHistory && userHistory.userId === usuario.userId) {
      const clienteAtual = usuario.clientes.find(
        (item) => item.clienteId === userHistory.clienteId
      );

      setCurrentClient(clienteAtual);
    } else {
      const clienteInicial = usuario.clientes[0];
      console.log(clienteInicial);

      localStorage.setItem(
        "userHistory",
        JSON.stringify({
          clienteId: clienteInicial.clienteId,
          regiao: {
            estado: clienteInicial.endereco.estado,
            municipio: clienteInicial.endereco.municipio,
          },
          sistema: "gerenciador",
          userId: usuario.userId,
        })
      );
    }

    navigateToInitialPage(
      userHistory?.sistema ? userHistory.sistema : "/home-test"
    );
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    console.log(loginValues);

    try {
      const resApi = await api.post("login/authentication", loginValues);
      const data = resApi.data;

      localStorage.setItem("token", data.token);
      setTokenAuth(data.token);

      if (data.usuario.cnpj) {
        console.log("ahaaa entao você é um usuário interno nao é?");
        const formattedInterUser = await FormatInternUserObject(data.usuario);

        verifyInternUserHistory(formattedInterUser);

        console.log(data.usuario);
        setUserAuthData(formattedInterUser);
      } else {
        await verifyUserHistory(data.usuario);
        setUserAuthData(data.usuario);
      }

      console.log(data.token);
      console.log(data.usuario);

      console.log("login feito com sucesso");
    } catch (error) {
      setClickSubmit(false);
      setErroLogin(true);
      console.error(error);
      setLoginValues({ ...loginValues, senha: "" });
      setTimeout(() => setClickSubmit(true), 500);
    }
  };

  return (
    <DivTela>
      <Header>
        <LogoSave height="70px" />

        <NavLinks>
          <NavLink>Início</NavLink>
          <NavLink>Sobre</NavLink>
          <NavLink>Páginas</NavLink>
          <NavLink>Contato</NavLink>
        </NavLinks>
      </Header>

      <DivImage>
        <ImgLogin src={imageLogin} alt="Logo" />
      </DivImage>

      <DivFormLogin>
        {/* <CardLogin> */}
        <FormContainer>
          {erroLogin && (
            <MsgErro id="msg_erro_login" clicksubmit={clickSubmit}>
              CPF ou senha incorretos
            </MsgErro>
          )}
          {/* <button onClick={() => verifyUserHistory()}>cons</button> */}
          <Form onSubmit={handleLogin}>
            <InputContainer>
              <UserIcon />
              <InputField
                mask="999.999.999-99"
                type="text"
                id="username"
                maskPlaceholder={null}
                value={loginValues.cpf}
                onChange={(e) => {
                  setLoginValues({
                    ...loginValues,
                    cpf: e.target.value.replace(/[^0-9]/g, ""),
                  });
                }}
                placeholder="CPF"
                required
              />
            </InputContainer>

            <InputContainer>
              <LockIcon />
              <InputField
                type="password"
                id="password"
                value={loginValues.senha}
                onChange={(e) =>
                  setLoginValues({ ...loginValues, senha: e.target.value })
                }
                placeholder="Senha"
                required
              />
            </InputContainer>

            <Label>
              Esqueceu sua senha?<Alink to="/recuperacao-de-senha">Ajuda</Alink>
            </Label>

            <SubmitButton type="submit">Entrar</SubmitButton>
          </Form>
        </FormContainer>
        {/* </CardLogin> */}
      </DivFormLogin>
    </DivTela>
  );
}

export default Login;
