import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { CCol } from "../../../components/Grid/CCol";

import { useFetchData } from "../../../hooks/useFetchData";
import HeaderCadastros from "../../../components/HeaderCadastros/HeaderCadastros";
import { useNavigate } from "react-router-dom";
import { CurrentClientContext } from "../../../contexts/CurrentClientContext";
import Dashboard from "../../../components/ComponentesDeDashboard/Dashboard";

export const DashboardIndicadores = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { data: dataIndicadores } = useFetchData(
    `programaPlanejamento/indicadores/${currentClient.clienteId}`
  );
  const [quantidadeIndicadores, setQuantidadeIndicadores] = useState("0");
  const [box1, setBox1] = useState([]);
  const navigate = useNavigate();
  const options = [
    { label: "Quantidade", value: null },
    { label: "Quantidade", value: 1 },
  ];

  useEffect(() => {
    if (dataIndicadores) {
      setQuantidadeIndicadores(
        dataIndicadores.length < 10
          ? "0" + dataIndicadores.length
          : dataIndicadores.length
      );
    }
  }, [dataIndicadores]);

  useEffect(() => {
    if (quantidadeIndicadores > 0) {
      setBox1({
        boxTitle: "Quantidade",
        boxSubtitle: "Indicadores",
        boxValue: quantidadeIndicadores,
      });
    }
  }, [quantidadeIndicadores]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Indicadores"}
        PaginaSubtitulo={"Dashboard"}
        PaginaConsulta={"/planejamento/cadastro/indicadores/consulta"}
        BotaoNovoFunction={() => navigate("/planejamento/cadastro/indicadores")}
        PaginaRelatorio={"/planejamento/cadastro/indicadores/relatorio"}
        BotaoSaveAtivo={false}
      />
      <Row className="row_form_2 mt-1" style={{ padding: "20px 10px" }}>
        <CCol>
          <Dashboard box1={box1} options={options} />
        </CCol>
        <CCol>
          <Dashboard />
        </CCol>
      </Row>
      <div className="p-3"></div>
    </Container>
  );
};
