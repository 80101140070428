import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import styled from "styled-components";

const ButtonPages = styled.button`
  border: none;
  color: #515c70;
  background-color: transparent;
  margin-left: 34px;
  padding: 0 10px;
`;

const CustomPrevIcon = () => (
  <Icon width={13} icon="material-symbols:arrow-back-ios-rounded" />
);

const CustomDoublePrevIcon = () => (
  <Icon width={18} icon="material-symbols:keyboard-double-arrow-left-rounded" />
);

const CustomNextIcon = () => (
  <Icon width={13} icon="material-symbols:arrow-forward-ios-rounded" />
);

const CustomDoubleNextIcon = () => (
  <Icon
    width={18}
    icon="material-symbols:keyboard-double-arrow-right-rounded"
  />
);

function Pagination({ pages, currentPage, setCurrentPage, itemsPerPage }) {
  const handleNextPage = () => {
    if (currentPage < pages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    if (currentPage < pages - 1) {
      setCurrentPage(pages - 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  };

  useEffect(() => {
    setCurrentPage(0); // Define a página atual como 0 sempre que o número de itens por página mudar
  }, [itemsPerPage, setCurrentPage]);

  return (
    <div>
      <ButtonPages onClick={handleFirstPage}>
        <CustomDoublePrevIcon />
      </ButtonPages>
      <ButtonPages onClick={handlePrevPage}>
        <CustomPrevIcon />
      </ButtonPages>
      <ButtonPages onClick={handleNextPage}>
        <CustomNextIcon />
      </ButtonPages>
      <ButtonPages onClick={handleLastPage}>
        <CustomDoubleNextIcon />
      </ButtonPages>
    </div>
  );
}

export default Pagination;
