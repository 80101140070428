import { useEffect, useState } from "react";

const useCliente = () => {
  const [valuesCliente, setValuesCliente] = useState({
    codigoSiconfi: "",
    codigoTCE: "",
    ibge: "",
    poder: "",
    regiao: "",
    poderOrgao: "",
    naturezaJuridica: "",
    tipoNaturezaJuridica: "",
    unidadeGestoraId: null,
    brasao: "",
    pessoaJuridicaId: "",
    gestao: [
      //     {
      //       dataInicial: "02/02/2021",
      //       dataFim: "02/02/3000",
      //       pessoaId: 23,
      //       modulos: [
      //         {
      //           sistema: "Planejamento",
      //         },
      //         {
      //           sistema: "Contabilidade",
      //         },
      //       ],
      //     },
    ],
  });
  const [tipoNatJur, setTipoNatJur] = useState(null);

  useEffect(() => {
    console.log(valuesCliente.naturezaJuridica.replace(/[^0-9]/g, ""));
    console.log("natJurMudada");
    if (valuesCliente.naturezaJuridica !== "") {
      switch (valuesCliente.naturezaJuridica.replace(/[^0-9]/g, "")) {
        case "1066":
          setTipoNatJur("1 - Câmara Municipal");
          break;
        case "1244":
          setTipoNatJur("2 - Prefeitura Municipal/Secretarias");
          break;
        default:
          setTipoNatJur("3 - Autarquia");
      }
    }
  }, [valuesCliente.naturezaJuridica]);

  const defPoderOrgao = () => {
    if (tipoNatJur) {
      switch (tipoNatJur) {
        case "1 - Câmara Municipal":
          setValuesCliente((prevValues) => ({
            ...prevValues,
            poderOrgao: "20231 - Poder Legislativo - Câmara Municipal",
          }));
          break;
        case "9 - Fundo Previdenciário":
        case "8 - Autarquia Previdenciária":
          setValuesCliente((prevValues) => ({
            ...prevValues,
            poderOrgao: "10132 - Poder Executivo - RPPS Municipal",
          }));
          break;
        default:
          setValuesCliente((prevValues) => ({
            ...prevValues,
            poderOrgao: "10131 - Poder Executivo - Prefeitura Municipal",
          }));
      }
    }
  };

  useEffect(() => {
    setValuesCliente((prevValues) => ({
      ...prevValues,
      tipoNaturezaJuridica: tipoNatJur,
    }));
    defPoderOrgao();
    // eslint-disable-next-line
  }, [tipoNatJur]);

  return {
    valuesCliente,
    setValuesCliente,
    setTipoNatJur,
    tipoNatJur,
  };
};

export default useCliente;
