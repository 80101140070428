import { Document } from "@react-pdf/renderer";
import React from "react";
import * as RenderPdf from "./pdfRenders";


export const AllRelatorios = ({ pdfContent }) => {
  const {
    dadosRelatorio,
    opcoes,
    userData: { currentClient, userAuthData, dataClientInfos },
    currentYear,
    selectBoxesOptionsAbaPrincipal,
  } = pdfContent;

  if (!dadosRelatorio) return null;

  const optionsToCheck = [
    "LOA - Lei Orçamentária Anual",
    "LDO - Lei de Diretrizes Orçamentárias",
    "PPA - Plano Plurianual",
  ];

  let nameTitleDocumentPdf = optionsToCheck.reduce((title, optionKey) => {
    const selectedOption = selectBoxesOptionsAbaPrincipal.find(
      (option) => option.opcao === optionKey
    );

    if (selectedOption) {
      const selectedCheckBox = selectedOption.checkBoxes.find(
        (checkBox) => checkBox.selected
      );

      if (selectedCheckBox) {
        return selectedCheckBox.label;
      }
    }
    return title;
  }, "Lei Orçamentária Anual");

  const renderers = [
    {
      id: 1,
      condition: dadosRelatorio.data.receitaDespesaCEconomica,
      renderer: RenderPdf.RenderPdfReceitaDespesaCEconomica,
    },
    {
      id: 2,
      condition: dadosRelatorio.data.receitaSegCEconomica,
      renderer: RenderPdf.RenderPdfReceitaSegCEconomica,
    },
    {
      id: 3,
      condition: dadosRelatorio.data.receitasCatEconOrgao,
      renderer: RenderPdf.RenderPdfReceitaCatEconOrgao,
    },
    {
      id: 4,
      condition: dadosRelatorio.data.receitasEsferas,
      renderer: RenderPdf.RenderPdfEsfera,
    },
    {
      id: 5,
      condition: dadosRelatorio.data.receitaPorFonteRec,
      renderer: RenderPdf.RenderPdfReceitaPorFonteRec,
    },
    {
      id: 6,
      condition: dadosRelatorio.data.receitaQdr,
      renderer: RenderPdf.RenderPdfReceitaQdr,
    },
    {
      id: 7,
      condition: dadosRelatorio.data.despesaSegCEconomica,
      renderer: RenderPdf.RenderPdfReceitaSegCEconomica,
      customLabel: "Despesa Segundo a Categoria Econômica",
    },
    {
      id: 8,
      condition: dadosRelatorio.data.despesaCatEconOrgao,
      renderer: RenderPdf.RenderPdfDespesaCatEconOrgao,
    },
    {
      id: 9,
      condition: dadosRelatorio.data.despesaPorEsfera,
      renderer: RenderPdf.RenderPdfEsfera,
      isDespesa: true,
    },
    {
      id: 10,
      condition: dadosRelatorio.data.despesaPorFonteRec,
      renderer: RenderPdf.RenderPdfReceitaPorFonteRec,
      customLabel: "Natureza da Despesa por Fontes ou Destinações de Recursos",
    },
    {
      id: 11,
      condition: dadosRelatorio.data.despesaQdd,
      renderer: RenderPdf.RenderPdfDespesaQdd,
    },
    {
      id: 12,
      condition: dadosRelatorio.data.qddAplicacao,
      renderer: RenderPdf.RenderPdfQDDAplicacao,
    },
    {
      id: 13,
      condition: dadosRelatorio.data.despesaOrgaoUnidade,
      renderer: RenderPdf.RenderPdfDespesaOrgaoUnidade,
    },
    {
      id: 14,
      condition: dadosRelatorio.data.despesaDemonstrativo,
      renderer: RenderPdf.RenderPdfDespesaDemonstrativo,
    },
    {
      id: 15,
      condition: dadosRelatorio.data.despesaVinculado,
      renderer: RenderPdf.RenderPdfDespesaVinculado,
    },
    {
      id: 16,
      condition: dadosRelatorio.data.despesaDemonstrativoUnidade,
      renderer: RenderPdf.RenderPdfDespesaDemonstrativoUnidade,
    },
    {
      id: 17,
      condition: dadosRelatorio.data.despesaDemonstrativoAcao,
      renderer: RenderPdf.RenderPdfDespesaDemonstrativoAcao,
    },
    {
      id: 18,
      condition: dadosRelatorio.data.despesaDemonstrativoPrograma,
      renderer: RenderPdf.RenderPdfDespesaDemonstrativoPrograma,
    },
    {
      id: 19,
      condition: dadosRelatorio.data.despesaCompatibilidade,
      renderer: RenderPdf.RenderPdfDespesaCompatibilidade,
    },
    {
      id: 20,
      condition: dadosRelatorio.data.despesaClassificacao,
      renderer: RenderPdf.RenderPdfDespesaClassificacao,
    },
    {
      id: 21,
      condition: dadosRelatorio.data.despesaDemonstrativoOrgaoFuncao,
      renderer: RenderPdf.RenderPdfDespesaDemonstrativoOrgaoFuncao,
    },
    {
      id: 22,
      condition: dadosRelatorio.data.despesaDemonstrativoRegionalizado,
      renderer: RenderPdf.RenderPdfDespesaDemonstrativoRegionalizado,
    },
    {
      id: 23,
      condition: dadosRelatorio.data.demoRiscosFiscaisProvidencias,
      renderer: RenderPdf.RenderPdfDemoRiscosFiscaisProvidencias,
      customLabel: "Lei de Diretrizes Orçamentárias",
    },
    {
      id: 24,
      condition: dadosRelatorio.data.ldoDemoMetasAnuais,
      renderer: RenderPdf.RenderPdfDemoMetasAnuais,
    },
    {
      id: 25,
      condition: dadosRelatorio.data.ldoDemoAvaliacao,
      renderer: RenderPdf.RenderPdfDemoAvaliacao,
    },
    {
      id: 26,
      condition: dadosRelatorio.data.ldoDemoMetasFiscais,
      renderer: RenderPdf.RenderPdfDemoMetasFiscais,
    },
    {
      id: 27,
      condition: dadosRelatorio.data.ldoEvolucaoPatrimonio,
      renderer: RenderPdf.RenderPdfDemoEvolucaoPatrimonio,
    },
    {
      id: 28,
      condition: dadosRelatorio.data.ldoDemoOrigemAplicacao,
      renderer: RenderPdf.RenderPdfDemoOrigemAplicacao,
    },
    {
      id: 29,
      condition: dadosRelatorio.data.ldoDemoAvaliacaoSituacao,
      renderer: RenderPdf.RenderPdfDemoAvaliacaoSituacao,
    },
    {
      id: 30,
      condition: dadosRelatorio.data.ldoDemoEstimativa,
      renderer: RenderPdf.RenderPdfDemoEstimativa,
    },
    {
      id: 31,
      condition: dadosRelatorio.data.ldoDemoMargenExpansao,
      renderer: RenderPdf.RenderPdfDemoMargenExpansao,
    },
    {
      id: 32,
      condition: dadosRelatorio.data.ldoAnexoPrioridade,
      renderer: RenderPdf.RenderPdfAnexoPrioridade,
    },
    {
      id: 33,
      condition: dadosRelatorio.data.ldoAnexoCompatibilidade,
      renderer: RenderPdf.RenderPdfAnexoCompatibilidade,
    },
    {
      id: 34,
      condition: dadosRelatorio.data.ldoAnexoEstimativa,
      renderer: RenderPdf.RenderPdfAnexoEstimativa,
    },
    {
      id: 35,
      condition: dadosRelatorio.data.ppaAnexoDemoEstimativa,
      renderer: RenderPdf.RenderPdfAnexoDemoEstimativa,
    },
    {
      id: 36,
      condition: dadosRelatorio.data.ppaAnexoDemoDespesa,
      renderer: RenderPdf.RenderPdfAnexoDemoDespesa,
    },
    {
      id: 37,
      condition: dadosRelatorio.data.ppaAnexoDemoSegCatEcon,
      renderer: RenderPdf.RenderPdfAnexoDemoSegCatEcon,
    },
    {
      id: 38,
      condition: dadosRelatorio.data.ppaAnexoDemoDespesaFuncao,
      renderer: RenderPdf.RenderPdfAnexoDemoDespesaFuncao
    },
  ];

  return (
    <Document title={dadosRelatorio.uniqueName[0] || nameTitleDocumentPdf}>
      {renderers.map(({ id, condition, renderer, customLabel,isDespesa }) =>
        dadosRelatorio.reportIds.includes(id) && condition ? (
          <React.Fragment key={id}>
            {renderer(
              condition,
              opcoes,
              currentClient,
              userAuthData,
              dataClientInfos,
              currentYear,
              dadosRelatorio.customData,
              customLabel,
              isDespesa
            )}
          </React.Fragment>
        ) : null
      )}
    </Document>
  );
};
