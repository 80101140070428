import React, { useState } from "react";
import { CCol, ContainerToModal } from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Row } from "react-bootstrap";
import Saldos from "../../Components/Saldos";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";

const FonteDeRecursos = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Saldos",
    numeroAba: 2,
  });

  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Cadastro"}
        PaginaSubtitulo={"Fonte de Recursos"}
        RowTitle={"Fonte de Recursos"}
        PaginaConsulta={"/contabilidade/cadastros/fonte-de-recursos/consulta"}
        BotaoSaveAtivo={false}
        BotaoNovoAtivo={false}
      />
      <Row className="row_form mt-1">
        <CCol md={2}>
          <FloatingLabelInput label={"Número de Receita"} />
        </CCol>
        <CCol>
          <FloatingLabelInput label={"Título"} disabled />
        </CCol>
        <CCol md={2} style={{ width: "20%" }}>
          <FloatingLabelInput
            new
            placeholder={"Tipo de Vínculo"}
            tipo="input_select"
          />
        </CCol>
      </Row>
      <Row className="row_form_2" style={{ marginTop: "-15px" }}>
        <CCol>
          <FloatingLabelInput label={"Descrição"} disabled />
        </CCol>
      </Row>
      <RowSelecaoAbasInferior
        abas={["Estrutura", "Orientações", "Saldos"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
        style={{ marginTop: "-15px" }}
      />
      {abaSelecionada.numeroAba === 2 && (
        <>
          <Row className="mt-3">
            <CCol
              className="d-flex align-items-center p-2 header_body_conteudo"
              md={12}
            >
              <span
                style={{
                  fontSize: "16.5px",
                  fontWeight: "600",
                  marginLeft: "10px",
                }}
              >
                Saldos
              </span>
            </CCol>
          </Row>
          <Saldos
            data={{
              "Saldo Anterior": "R$ 20.000,00",
              Débitos: "R$ 20.000,00",
              Crétidos: "R$ 20.000,00",
              "Saldo Atualizado": "R$ 20.000,00",
            }}
          />
        </>
      )}
      <div style={{ minHeight: "40px" }}> </div>
    </ContainerToModal>
  );
};

export default FonteDeRecursos;
