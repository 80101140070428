import { Text, View } from "@react-pdf/renderer";
import { ReturnDataAtual } from "../../../utils/Gerais/ReturnDataAtual";
import { stylesPdf } from "./StyledPdfRelatorio";
import { FormatCnpjPaste } from "../../../utils/FormatacaoDeDados/FormatCnpjPaste";

export const PdfRodape = ({ userAuthData, currentClient }) => {
  const dataAtual = new Date();
  const horas = String(dataAtual.getHours()).padStart(2, "0");
  const minutos = String(dataAtual.getMinutes()).padStart(2, "0");

  return (
    <View
      style={[
        stylesPdf.rodapeRelatorio,
        { marginTop: "20px" },
      ]}
      fixed
    >
      <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
        <Text>
          Impresso por
          <Text style={stylesPdf.rodapeRelatorioInfos}>
            {" "}
            {userAuthData.nome}{" "}
          </Text>
          em {ReturnDataAtual()} ás {horas}:{minutos}
        </Text>
      </View>
      <View style={stylesPdf.infosGeraisRodape}>
        <View style={{ flexDirection: "row" }}>
          <Text>Fonte:</Text>
          <Text style={stylesPdf.infosSave}>
            {" "}
            Save – Soluções Tecnológicas – www.savest.com.br
          </Text>
          <Text>
            {`, ${currentClient.nomeFantasia} - ${currentClient.endereco.estado}`}{" "}
            CNPJ: {FormatCnpjPaste(currentClient.cnpj)}
          </Text>
        </View>
        <Text
          render={({ pageNumber, totalPages }) =>
            `Página ${pageNumber} de ${totalPages}`
          }
          fixed
        />
      </View>
    </View>
  );
};
