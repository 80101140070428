import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { PdfInfos } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";

const mockData = {
  receitaTotal: {
    data: [
      {
        year: 2022,
        percentage: "61,78",
        total: "26.952.500,00",
      },
      {
        year: 2023,
        percentage: "61,78",
        total: "26.952.500,00",
      },
      {
        year: 2024,
        percentage: "61,78",
        total: "26.952.500,00",
      },
      {
        year: 2025,
        percentage: "61,78",
        total: "26.952.500,00",
      },
      {
        year: 2026,
        percentage: "61,78",
        total: "26.952.500,00",
      },
      {
        year: 2027,
        percentage: "61,78",
        total: "26.952.500,00",
      },
    ],
    receitasPrimarias: [
      {
        year: 2022,
        percentage: "61,78",
        total: "26.952.500,00",
      },
      {
        year: 2023,
        percentage: "61,78",
        total: "26.952.500,00",
      },
      {
        year: 2024,
        percentage: "61,78",
        total: "26.952.500,00",
      },
      {
        year: 2025,
        percentage: "61,78",
        total: "26.952.500,00",
      },
      {
        year: 2026,
        percentage: "61,78",
        total: "26.952.500,00",
      },
      {
        year: 2027,
        percentage: "61,78",
        total: "26.952.500,00",
      },
    ],
  },
};

export const RenderPdfDemoMetasFiscais = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: "200px", flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: "100%",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];
  const widthColumYear = 80;
  const widthColumPercentage = 40;

  const renderHeader = () => {
    return (
      <>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              { textAlign: "center", height: 38 ,fontSize:8},
            ]}
          >
            <Text
              style={[
                stylesPdf.textTituloColuna,
                { textTransform: "uppercase" ,fontSize:8},
              ]}
            >
              especificações
            </Text>
          </View>
        </View>

        <View style={[stylesPdf.coluna, styledCol[1]]}>
          <View style={[stylesPdf.divTituloColuna, { height: 38 }]}>
            <View
              style={{
                justifyContent: "space-between",
                height: "100%",
                paddingVertical: 2,
              }}
            >
              <Text
                style={[
                  stylesPdf.textTituloColuna,
                  { textTransform: "uppercase",fontSize:8 },
                ]}
              >
                valores a preços correntes
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {dados.years.map((year, index) => (
                  <React.Fragment key={index}>
                    <Text
                      style={[
                        stylesPdf.textTituloColuna,
                        { width: widthColumYear,fontSize:8 },
                      ]}
                    >
                      {year}
                    </Text>
                    {index >= 1 && (
                      <Text
                        style={[
                          stylesPdf.textTituloColuna,
                          {
                            width: widthColumPercentage,
                            fontSize:8
                          },
                        ]}
                      >
                        %
                      </Text>
                    )}
                  </React.Fragment>
                ))}
              </View>
            </View>
          </View>
        </View>
      </>
    );
  };
  const renderSection = (titulo, sectionColor = false, textBold = true) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            backgroundColor: sectionColor ? "#a6a6a6" : "transparent",
            fontWeight: textBold ? "bold" : "normal",
          },
        ]}
      >
        <PdfInfos
          pdfInfo={titulo}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{fontSize:8}}
        />

        <View
          style={[
            stylesPdf.coluna,
            styledCol[1],
            {
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",

            },
          ]}
        >
          {mockData.receitaTotal.data.map((receita, index) => (
            <React.Fragment key={index}>
              <View style={{ width: widthColumYear }} wrap={false}>
                <Text
                  style={[
                    stylesPdf.textInfosRelatorio,
                    { paddingHorizontal: 0,fontSize:8 },
                  ]}
                >
                  {receita.total}
                </Text>
              </View>

              {index >= 1 && (
                <View style={{ width: widthColumPercentage }} wrap={false}>
                  <Text
                    style={[
                      stylesPdf.textInfosRelatorio,
                      { paddingHorizontal: 0,fontSize:8 },
                    ]}
                  >
                    {receita.percentage}
                  </Text>
                </View>
              )}
            </React.Fragment>
          ))}
        </View>
      </View>
    );
  };

  const renderIndicesInflacao = (year) => {
    return (
      <View
        style={{
          gap: 4,
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <View
          style={{
            backgroundColor: "#eeeeee",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Text style={{ fontWeight: "bold", fontSize: 8 }}>{year}</Text>
        </View>
        <Text style={{ fontSize: 8, width: "100%", textAlign: "center" }}>
          3,10
        </Text>
      </View>
    );
  };

  return (
    <Page orientation="landscape" size={"A4"} style={[stylesPdf.page]} >
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={
          "Metas Fiscais Atuais Comparadas com as Fixadas nos três Exercícios Anteriores"
        }
        anoRelatorio={currentYear}
        plusText={"Anexo de Metas Fiscais"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>AMF - Demonstrativo 3 (LRF, art. 4º, § 2º, inciso II)</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
        {renderHeader()}
      </View>
      <View style={stylesPdf.divRelatorio}>
        {renderSection("Receita Total", true)}
        {renderSection("Receitas Primárias (I)")}
        {renderSection("Despesa Total", true)}
        {renderSection("Despesas Primárias (II)")}
        {renderSection("Resultado Primário (III) = (I – II)", true)}
        {renderSection("Dívida Pública Consolidada (DC)", false, false)}
        {renderSection("Dívida Consolidada Líquida (DCL)", false, false)}
        {renderSection("Resultado Nominal", true)}
      </View>

      <View
        style={[
          stylesPdf.colunas,
          { marginTop: 18, backgroundColor: "#515c70" },
        ]}
      >
        {renderHeader()}
      </View>
      <View style={stylesPdf.divRelatorio}>
        {renderSection("Receita Total", true)}
        {renderSection("Receitas Primárias (I)")}
        {renderSection("Despesa Total", true)}
        {renderSection("Despesas Primárias (II)")}
        {renderSection("Resultado Primário (III) = (I – II)", true)}
        {renderSection("Dívida Pública Consolidada (DC)", false, false)}
        {renderSection("Dívida Consolidada Líquida (DCL)", false, false)}
        {renderSection("Resultado Nominal", true)}
        <Text style={{ fontWeight: "bold", marginLeft: 8 ,fontSize:8}}>
          FONTE: Secretaria de Finanças{" "}
        </Text>
      </View>
      <View
        wrap={false}
        style={[
          stylesPdf.divInferiorColuna,
          {
            fontWeight: "bold",
            fontSize: 8,
            textTransform: "uppercase",
            color: "white",
            alignItems: "center",
            marginTop: 10,
            paddingVertical: 2,
            
          },
        ]}
      >
        <Text>indices de inflação</Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginHorizontal: -3,
        }}
      >
        {dados.years.map((year, index) => (
          <React.Fragment key={index}>
            {renderIndicesInflacao(year)}
          </React.Fragment>
        ))}
      </View>

      <View
        style={{
          width: "100%",
          height: 1,
          backgroundColor: "#000",
          marginVertical: 2,
        }}
      />
      <Text style={{ fontWeight: "bold", marginLeft: 8,fontSize:8 }}>
        FONTE: Banco Central – Relatório Focus de abril 2024
      </Text>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
