import React from "react";

export const Preparar = ({ option = null }) => {
  return (
    <>
      {/* {option === "1.0" && <PrepararLDO />} */}
      {/* {option === "1.1" && <PrepararLOA />} */}
    </>
  );
};

export default Preparar;
