import React, { useState, useEffect, useMemo, useRef } from "react";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { ToastContainer } from "react-toastify";
import { Container, Row } from "react-bootstrap";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { CCol } from "../../../../components/Grid/CCol";
import styled from "styled-components";
import { Icon } from "@iconify/react/dist/iconify.js";
import DailyCard from "../../Inicio/TarefasPrazos/DailyCard";
import Principal from "./Menu/Principal";

const Span = styled.span`
  position: relative;
  font-size: 16px;
  font-weight: 600;
  margin: 0 15px;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
`;

const RegisterBoxes = () => {
  return (
    <>
      <div
        style={{
          borderRadius: "8px 8px 0 0",
          border: "2px solid #E8E8E8",
          height: "38px",
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
        }}
      >
        <Span>Principal</Span>
      </div>
      <div
        style={{
          borderRadius: "0 0 8px 8px",
          border: "2px solid #E8E8E8",
          borderTop: "none",
          padding: "20px 15px",
        }}
      >
        <Principal />
      </div>
    </>
  );
};

const Exportar = () => {
  const [option, setOption] = useState(1);
  const [data, setData] = useState();
  const [month, setMonth] = useState();
  const currentDate = useMemo(() => new Date(), []);
  const dailyCardsRef = useRef(null);

  useEffect(() => {
    console.log(option);
  }, [option]);

  useEffect(() => {
    setData(currentDate.getFullYear() - 1);
    setMonth(currentDate.getMonth());
  }, [currentDate]);

  const scrollLeft = () => {
    if (dailyCardsRef.current) {
      dailyCardsRef.current.scrollTo({
        left: dailyCardsRef.current.scrollLeft - 800,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (dailyCardsRef.current) {
      dailyCardsRef.current.scrollTo({
        left: dailyCardsRef.current.scrollLeft + 800,
        behavior: "smooth",
      });
    }
  };

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber);
    return date.toLocaleString("pt-BR", { month: "long" });
  };

  const changeData = (number) => {
    if (month + number === 12) {
      setMonth(0);
      setData(data + number);
    } else if (month + number === -1) {
      setMonth(11);
      setData(data + number);
    } else {
      setMonth(month + number);
    }
  };

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      style={{ color: "#515C70" }}
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Exportar"}
        PaginaSubtitulo={"Cadastro"}
        BotaoSaveAtivo={true}
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        <Row>
          <CCol>
            <FloatingLabelInput
              new={true}
              tipo="input_select"
              placeholder={"Exportar SAGRES - TCE-PB"}
              options={[{ label: "Exportar SAGRES - TCE-PB", value: 1 }]}
              onSelect={(option) => setOption(option.value)}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              new={true}
              tipo="input_select"
              placeholder={"Diário"}
              options={[{ label: "Diário", value: 1 }]}
              onSelect={(option) => setOption(option.value)}
            />
          </CCol>
        </Row>
        <Row className="mt-3">
          <CCol
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon
              width={25}
              icon="material-symbols:arrow-back-ios-rounded"
              cursor={"pointer"}
              onClick={() => {
                changeData(-1);
              }}
            />
            <div
              style={{
                margin: "0 10px",
                fontWeight: "600",
                fontSize: "20px",
                width: "180px",
                textAlign: "center",
              }}
            >
              {getMonthName(month)} - {data}
            </div>
            <Icon
              width={25}
              icon="material-symbols:arrow-back-ios-rounded"
              rotate="180deg"
              cursor={"pointer"}
              onClick={() => {
                changeData(1);
              }}
            />
          </CCol>
        </Row>
        <Row className="mt-3">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon
              width={40}
              icon="material-symbols:arrow-back-ios-rounded"
              cursor={"pointer"}
              onClick={() => scrollLeft()}
            />
            <div
              ref={dailyCardsRef}
              style={{
                whiteSpace: "nowrap",
                overflowX: "hidden",
                display: "flex",
                width: "fit-content",
              }}
            >
              {Array.from(
                { length: new Date(data, month + 1, 0).getDate() },
                (_, index) => (
                  <CCol key={index}>
                    <DailyCard percentage={100} day={index + 1} />
                  </CCol>
                )
              )}
            </div>
            <Icon
              width={40}
              icon="material-symbols:arrow-back-ios-rounded"
              rotate="180deg"
              cursor={"pointer"}
              onClick={() => scrollRight()}
            />
          </div>
        </Row>
        <Row className="mt-3">
          <CCol>
            <RegisterBoxes />
          </CCol>
        </Row>
      </Row>
    </Container>
  );
};

export default Exportar;
