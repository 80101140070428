import styled from "styled-components";
import { Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";

export const HeaderDiv = styled.header`
  background-color: #fafafa;
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  height: 63px;
  align-items: center;
  border-bottom: 2px solid #efefef;

  label#btn_menu {
    color: #515c70;
    margin-left: 10px;
    cursor: pointer;
  }

  label#btn_menu:hover {
    color: #9399a4;
  }

  svg {
    flex-shrink: 0;
    margin-right: 10px;
    color: #515c70;
    height: 27px;
  }
`;

export const InvisibleCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

export const SelectSuperior = styled.select`
  width: 100%;
  border-color: rgba(255, 255, 255, 1);
  background: transparent;
  height: 40px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px #9399a4;
  margin-right: 10px;
  font-size: 14px;
  color: #515c70;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 30px;
  cursor: pointer;
  appearance: none;

  &:focus {
    outline: none;
  }
`;

export const DivSelectSup = styled.div`
  display: flex;
  flex-grow: 3;
  flex-shrink: 3;
  position: relative;
`;

export const HeaderBodyNav = styled.div`
  margin-right: -7px;
  button {
    margin-right: 5px;
  }
`;

export const ButtonIcon = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 40px;
    margin-left: -9px;
  }
`;

export const ParagraphUserName = styled.p`
  //margin-left: 50px; //comment
  color: #515c70;
  position: relative;
  top: 9px;
  span {
    font-weight: 600;
    position: relative;
  }
`;

export const ImgHeaderUsuario = styled.img`
  border-radius: 21px;
  object-fit: cover;
  width: 42px;
  max-height: 42px;
  margin-left: 10px;
  margin-right: 23px;
  cursor: pointer;
`;

export const DivUsuarioPerfil = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  left: 2px;
`;

export const StyledHeaderPag = styled(Col)`
  height: 62px;
`;

export const UserSubMenuContainer = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 5px;
  right: 20px;
  width: 150px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 16px -6px rgba(0, 0, 0, 0.75);

  background-color: #fafafa;
`;

export const ButtonNoStyles = styled.button`
  background: transparent;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
`;

export const UserSubMenuContent = styled.ul`
  list-style: none;
  padding: 2px;
  margin: 2px;
`;

export const UserSubMenuLink = styled(NavLink)`
  color: #515c70;
  text-decoration: none;
  display: block;
  padding: 8px;
  &:hover {
    color: #515c70;
    background-color: #f5f5f5;
  }
`;

export const ButtonExit = styled.button`
  color: #515c70;
  background-color: transparent;
  border: none;
  text-decoration: none;
  display: flex;
  justify-content: start;
  width: 100%;
  padding: 8px;
  &:hover {
    color: #515c70;
    background-color: #f5f5f5;
  }
`;

export const DivButtons = styled.div`
  display: flex;
  margin-top: 10px;
  /* margin-left: 8px; */
  justify-content: space-between;
  padding: 0 10px;
`;

export const ButtonActions = styled.button`
  background: transparent;
  border: none;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  color: #515c70;
  ${(props) => (props.$active ? "padding: 2px;" : "")}
`;

export const DivStyledIcon = styled.div`
  ${(props) => (props.$active ? "background-color: #e2e2ea;" : "")}
  width: 50px;
  height: 50px;
  margin-right: 5px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavLinkButtonNovo = styled(NavLink)`
  height: 38px;
  width: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #238c59;
  text-decoration: none;
  color: white;
  margin-right: 10px;
  transition: all 0.2s;

  &:hover {
    color: white;
    background-color: #1e7a4c;
  }
`;

export const NavLinkButtonsPags = styled(NavLink)`
  ${(props) => (props.$active ? "background-color: #e2e2ea;" : "")}
  width: 50px;
  height: 50px;
  margin-right: 5px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
