export const FormatNumeroCelular = (value) => {
  // Remove qualquer caracter que não seja número
  let numero = value.replace(/\D/g, "");
  // Aplica as pontuações
  let numeroFormatado = "";

  if (numero.length <= 2) {
    console.log(numero, "quando menor ou igual a 2");
    numeroFormatado += numero;
  }

  if (numero.length > 2) {
    numeroFormatado += "(" + numero.slice(0, 2) + ")";
    numeroFormatado += " " + numero.slice(2, 7);
    if (numero.length > 7) {
      numeroFormatado += "-" + numero.slice(7);
    }
  }

  console.log(numeroFormatado);
  return numeroFormatado;

  //toda vez que iniciar o numero vai vir limpo, apenas numeros
  // let numero = value.replace((/\D/g, ""));

  // o numero formatado vai iniciar com uma string vazia toda vez
  // let numeroFormatado = "";

  // console.log("funcione");
};
