import styled from "styled-components";

const ProgressBarContainer = styled.div`
  position: relative;
  background-color: #cad9f4;
  width: 100%;
  height: ${(props) => `${props.height}px` || "35px"};
  display: flex;
  align-items: center;
  border-radius: 4px;
`;

const ProgressLabel = styled.span`
  position: absolute;
  left: 10px;
  font-weight: 600;
  margin-right: 10px;
  z-index: 1;
  color: #f7f7f7;
  text-shadow: -0.5px -0.5px 2px #515c70, 0.5px -0.5px 2px #515c70,
    -0.5px 0.5px 2px #515c70, 0.5px 0.5px 2px #515c70;
`;

const ProgressBar = styled.div`
  background-color: #515c70;
  width: ${(props) => (props.percentage ? props.percentage : "0")}%;
  height: 100%;
  border-radius: 4px;
`;

const PercentageValue = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
  font-weight: 600;
  color: #515c70;
`;

export const ProgressBarComponent = ({
  percentage = 0,
  label = "teste",
  height = 35,
}) => {
  return (
    <>
      <ProgressBarContainer height={height}>
        <ProgressLabel>{label}</ProgressLabel>
        <ProgressBar percentage={percentage}>
          <PercentageValue>{percentage ? percentage : "0"}%</PercentageValue>
        </ProgressBar>
      </ProgressBarContainer>
    </>
  );
};
