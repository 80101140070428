import React, { useState } from "react";
import FloatingLabelInput from "../Grid/FloatingLabelInput";
import styled from "styled-components";

export const ContainerContent = styled.div`
  display: flex;
  padding: 0px 8px;
  gap: 25px;

  @media (max-width: 1280px) {
    & {
      flex-direction: column;
    }
  }
`;

export const ContentDiv = styled.div`
  flex-wrap: wrap;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
`;

export const DivItemsTop = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 25px;

  @media (max-width: 870px) {
    & {
      flex-direction: column;
      gap: 26px;
    }
  }
`;

export const BoxItemsTop = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  border: 2px solid #f2f3f8;
  flex-direction: column;
  padding: 10px;
  flex-basis: 100%;
  border-radius: 10px;
  color: #515c70;
  min-height: 226px;

  & > h6 {
    padding: 0;
    margin: 0;
    font-weight: 400;
  }
`;

export const BoxItemBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 23px;
  border: 2px solid #f2f3f8;
  border-radius: 10px;
  min-height: 452px;
  color: #515c70;
  padding: 10px;
  position: relative;

  & > h6 {
    padding: 0;
    margin: 0;
    font-weight: 400;
  }
`;

export const DivIndicadoresQuantidade = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: 700;
    font-size: 50px;
    margin: 0;
  }

  p {
    margin: 0;
  }
`;

export const InputDisabledDashBoard = styled.input`
  outline: none;
  border-radius: 5px;
  min-height: 38px;
  border: 1px solid #f2f2f2;

  :disabled {
    background-color: #f2f2f2;
  }
`;

const Dashboard = ({ box1, box2, box3, options, setOption }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (option) => {
    setSelectedOption(option.value);
    setOption && setOption(option.value);
  };
  return (
    <>
      <ContainerContent>
        <ContentDiv style={{ marginTop: !options ? "38px" : null }}>
          {options && (
            <>
              {box1 || box2 || box3 ? (
                <FloatingLabelInput
                  new
                  tipo="input_select"
                  value={selectedOption}
                  options={options}
                  onSelect={(option) => {
                    setOption && handleSelect(option);
                  }}
                />
              ) : (
                <InputDisabledDashBoard disabled />
              )}
            </>
          )}
          <DivItemsTop>
            <BoxItemsTop style={{ backgroundColor: box1 ? null : "#F2F2F2" }}>
              {box1 ? (
                typeof box1 === "object" && box1.component ? (
                  box1.component
                ) : (
                  <>
                    <h6>{box1.boxTitle}</h6>
                    <DivIndicadoresQuantidade>
                      <h1>{box1.boxValue}</h1>
                      <p>{box1.boxSubtitle}</p>
                    </DivIndicadoresQuantidade>
                  </>
                )
              ) : null}
            </BoxItemsTop>
            <BoxItemsTop style={{ backgroundColor: box2 ? null : "#F2F2F2" }}>
              {box2 ? (
                typeof box2 === "object" && box2.component ? (
                  box2.component
                ) : (
                  <>
                    <h6>{box2.boxTitle}</h6>
                    <DivIndicadoresQuantidade>
                      <h1>{box2.boxValue}</h1>
                      <p>{box2.boxSubtitle}</p>
                    </DivIndicadoresQuantidade>
                  </>
                )
              ) : null}
            </BoxItemsTop>
          </DivItemsTop>
          <BoxItemBottom style={{ backgroundColor: box3 ? null : "#F2F2F2" }}>
            {box3 ? (
              typeof box3 === "object" && box3.component ? (
                box3.component
              ) : (
                <>
                  <h6>{box3.boxTitle}</h6>
                  <DivIndicadoresQuantidade>
                    <h1>{box3.boxValue}</h1>
                    <p>{box3.boxSubtitle}</p>
                  </DivIndicadoresQuantidade>
                </>
              )
            ) : null}
          </BoxItemBottom>
        </ContentDiv>
      </ContainerContent>
    </>
  );
};

export default Dashboard;
