import React, { useContext, useState } from "react";
import { Row } from "react-bootstrap";
import { TituloAbaSolo } from "../../../../styles/StyledComponents/LayoutGerais";
import { especificacoes } from "../Riscos_Fiscais/Especificacoes";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import { Icon } from "@iconify/react/dist/iconify.js";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import { TestButton } from "../../../../utils/TestButton";

const Divida = ({
  arrayEspecificacoesNominal,
  setArrayEspecificacoesNominal,
  valueEspecificacao,
  setValueEspecificacao,
}) => {
  const { currentYear } = useContext(CurrentYearContext);
  const [values, setValues] = useState({});
  const [editMode, setEditMode] = useState([]);
  const [oldValues, setOldValues] = useState([]);

  const handleEditToggle = (
    rowIndex,
    columnIndex,
    key,
    insideKey,
    edit,
    cancel
  ) => {
    setEditMode((prev) => {
      const newEditMode = [...prev];
      newEditMode[rowIndex][columnIndex] = edit;
      return newEditMode;
    });

    if (edit) {
      setOldValues((prevOldValues) => ({
        ...prevOldValues,
        [key]: {
          ...prevOldValues[key],
          [insideKey]: values[key][insideKey],
        },
      }));
    }

    if (cancel) {
      setValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[key][insideKey] = oldValues[key][insideKey];
        return updatedValues;
      });
    }
  };

  const adicionarEspecificacao = () => {
    if (valueEspecificacao) {
      setValues((prevValues) => ({
        ...prevValues,
        [`${valueEspecificacao}`]: {
          prevista: {
            [`prevista${currentYear - 3}`]: "0",
            [`prevista${currentYear - 2}`]: "1",
            [`prevista${currentYear - 1}`]: "2",
            [`prevista${currentYear}`]: "3",
            [`prevista${currentYear}%`]: "4",
            [`prevista${currentYear + 1}`]: "5",
            [`prevista${currentYear + 1}%`]: "5",
          },
          realizada: {
            [`realizada${currentYear - 3}`]: "0",
            [`realizada${currentYear - 2}`]: "1",
            [`realizada${currentYear - 1}`]: "2",
            [`realizada${currentYear}`]: "3",
            [`realizada${currentYear}%`]: "4",
            [`realizada${currentYear + 1}`]: "5",
            [`realizada${currentYear + 1}%`]: "5",
          },
        },
      }));
      setEditMode((prev) => [...prev, [false, false]]);
    }
  };

  const deleteEspecificacao = (index) => {
    setValues((prevValues) => {
      const newValues = { ...prevValues };
      delete newValues[Object.keys(newValues)[index]];
      return newValues;
    });
    setEditMode((prev) => {
      const newEditMode = [...prev];
      newEditMode.splice(index, 1);
      return newEditMode;
    });
  };

  const handleInputChange = (insideIndex, rowIndex, year, event) => {
    const newValue = event.target.value;
    console.log(insideIndex);

    setValues((prevValues) => {
      const key = Object.keys(prevValues)[rowIndex];
      return {
        ...prevValues,
        [key]: {
          ...prevValues[key],
          [insideIndex === 0 ? "prevista" : "realizada"]: {
            ...prevValues[key][insideIndex === 0 ? "prevista" : "realizada"],
            [`${insideIndex === 0 ? "prevista" : "realizada"}${year}`]:
              newValue,
          },
        },
      };
    });
  };

  const formatValue = (input) => {
    return Object.entries(input).map(
      ([especificacao, { prevista, realizada }]) => ({
        especificacao,
        prevista: Object.fromEntries(
          Object.entries(prevista).map(([key, value]) => [
            key.replace(/^(prevista|realizada)/, ""),
            value,
          ])
        ),
        realizada: Object.fromEntries(
          Object.entries(realizada).map(([key, value]) => [
            key.replace(/^(prevista|realizada)/, ""),
            value,
          ])
        ),
      })
    );
  };

  return (
    <>
      <Row className="row_form mt-1">
        <TituloAbaSolo>Dívida Consolidada</TituloAbaSolo>
      </Row>
      <Row
        className="row_form mt-1"
        style={
          arrayEspecificacoesNominal.length === 0
            ? { borderRadius: "0px 0px 5px 5px" }
            : {}
        }
      >
        <CCol>
          <FloatingLabelInput
            placeholder="Especificações"
            new={true}
            label={"Especificações"}
            tipo="input_select"
            options={[...especificacoes]}
            onSelect={(option) => setValueEspecificacao(option.value)}
            value={valueEspecificacao}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar onClick={adicionarEspecificacao} />
        </CColButtonsAcoes2Icons>
      </Row>
      <Row className={"row_form"}>
        {Object.entries(values || {}).map(([key, value], index) => {
          return (
            <>
              <RowAninhada>
                <CCol>
                  <PTitulosInputs style={{ marginTop: "0px" }}>
                    Especificação
                  </PTitulosInputs>
                  <FloatingLabelInput
                    disabled
                    value={key}
                    style={{ border: "none" }}
                  />
                </CCol>
                <CColButtonsAcoes2Icons md={1}>
                  <Icon
                    icon="ic:baseline-delete"
                    color="#E79900"
                    height="28"
                    onClick={() => deleteEspecificacao(index)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2px",
                      marginTop: "17px",
                    }}
                  />
                </CColButtonsAcoes2Icons>
              </RowAninhada>
              <RowAninhada style={{ marginTop: "15px" }}>
                {Object.entries(value).map(
                  ([insideKey, insideValue], insideIndex) => {
                    console.log(insideValue[`prevista${currentYear - 3}`]);
                    return (
                      <RowAninhada>
                        <CCol
                          md={1}
                          style={{
                            marginTop: insideIndex === 0 ? "14px" : "8px",
                            color: "#888a8e",
                            fontSize: "14px",
                            fontWeight: "600",
                            width: "100px",
                          }}
                        >
                          {insideKey}
                        </CCol>
                        <CCol>
                          {insideIndex === 0 && (
                            <PTitulosInputs style={{ textAlign: "center" }}>
                              {currentYear - 3}
                            </PTitulosInputs>
                          )}
                          <FormControlListaDeInputs
                            value={
                              insideValue[
                                `${
                                  insideIndex === 0 ? "prevista" : "realizada"
                                }${currentYear - 3}`
                              ]
                            }
                            onChange={(e) =>
                              handleInputChange(
                                insideIndex,
                                index,
                                currentYear - 3,
                                e
                              )
                            }
                            height={"30px"}
                            disabled={
                              !editMode[index] || !editMode[index][insideIndex]
                            }
                            radiusborder={
                              insideIndex === 0 ? "5px 5px 0 0" : "0 0 5px 5px"
                            }
                          />
                        </CCol>
                        <CCol>
                          {insideIndex === 0 && (
                            <PTitulosInputs style={{ textAlign: "center" }}>
                              {currentYear - 2}
                            </PTitulosInputs>
                          )}
                          <FormControlListaDeInputs
                            value={
                              insideValue[
                                `${
                                  insideIndex === 0 ? "prevista" : "realizada"
                                }${currentYear - 2}`
                              ]
                            }
                            onChange={(e) =>
                              handleInputChange(
                                insideIndex,
                                index,
                                currentYear - 2,
                                e
                              )
                            }
                            height={"30px"}
                            disabled={
                              !editMode[index] || !editMode[index][insideIndex]
                            }
                            radiusborder={
                              insideIndex === 0 ? "5px 5px 0 0" : "0 0 5px 5px"
                            }
                          />
                        </CCol>
                        <CCol>
                          {insideIndex === 0 && (
                            <PTitulosInputs style={{ textAlign: "center" }}>
                              {currentYear - 1}
                            </PTitulosInputs>
                          )}
                          <FormControlListaDeInputs
                            value={
                              insideValue[
                                `${
                                  insideIndex === 0 ? "prevista" : "realizada"
                                }${currentYear - 1}`
                              ]
                            }
                            onChange={(e) =>
                              handleInputChange(
                                insideIndex,
                                index,
                                currentYear - 1,
                                e
                              )
                            }
                            height={"30px"}
                            disabled={
                              !editMode[index] || !editMode[index][insideIndex]
                            }
                            radiusborder={
                              insideIndex === 0 ? "5px 5px 0 0" : "0 0 5px 5px"
                            }
                          />
                        </CCol>
                        <CCol>
                          {insideIndex === 0 && (
                            <PTitulosInputs style={{ textAlign: "center" }}>
                              {currentYear}
                            </PTitulosInputs>
                          )}
                          <FormControlListaDeInputs
                            value={
                              insideValue[
                                `${
                                  insideIndex === 0 ? "prevista" : "realizada"
                                }${currentYear}`
                              ]
                            }
                            onChange={(e) =>
                              handleInputChange(
                                insideIndex,
                                index,
                                currentYear,
                                e
                              )
                            }
                            height={"30px"}
                            disabled={
                              !editMode[index] || !editMode[index][insideIndex]
                            }
                            radiusborder={
                              insideIndex === 0 ? "5px 5px 0 0" : "0 0 5px 5px"
                            }
                          />
                        </CCol>
                        <CCol>
                          {insideIndex === 0 && (
                            <PTitulosInputs style={{ textAlign: "center" }}>
                              {"%"}
                            </PTitulosInputs>
                          )}
                          <FormControlListaDeInputs
                            value={
                              insideValue[
                                `${
                                  insideIndex === 0 ? "prevista" : "realizada"
                                }${currentYear}%`
                              ]
                            }
                            onChange={(e) =>
                              handleInputChange(
                                insideIndex,
                                index,
                                currentYear + "%",
                                e
                              )
                            }
                            height={"30px"}
                            disabled={
                              !editMode[index] || !editMode[index][insideIndex]
                            }
                            radiusborder={
                              insideIndex === 0 ? "5px 5px 0 0" : "0 0 5px 5px"
                            }
                          />
                        </CCol>
                        <CCol>
                          {insideIndex === 0 && (
                            <PTitulosInputs style={{ textAlign: "center" }}>
                              {currentYear + 1}
                            </PTitulosInputs>
                          )}
                          <FormControlListaDeInputs
                            value={
                              insideValue[
                                `${
                                  insideIndex === 0 ? "prevista" : "realizada"
                                }${currentYear + 1}`
                              ]
                            }
                            onChange={(e) =>
                              handleInputChange(
                                insideIndex,
                                index,
                                currentYear + 1,
                                e
                              )
                            }
                            height={"30px"}
                            disabled={
                              !editMode[index] || !editMode[index][insideIndex]
                            }
                            radiusborder={
                              insideIndex === 0 ? "5px 5px 0 0" : "0 0 5px 5px"
                            }
                          />
                        </CCol>
                        <CCol>
                          {insideIndex === 0 && (
                            <PTitulosInputs style={{ textAlign: "center" }}>
                              {"%"}
                            </PTitulosInputs>
                          )}
                          <FormControlListaDeInputs
                            value={
                              insideValue[
                                `${
                                  insideIndex === 0 ? "prevista" : "realizada"
                                }${currentYear + 1}%`
                              ]
                            }
                            onChange={(e) =>
                              handleInputChange(
                                insideIndex,
                                index,
                                currentYear + 1 + "%",
                                e
                              )
                            }
                            height={"30px"}
                            disabled={
                              !editMode[index] || !editMode[index][insideIndex]
                            }
                            radiusborder={
                              insideIndex === 0 ? "5px 5px 0 0" : "0 0 5px 5px"
                            }
                          />
                        </CCol>
                        <CColButtonsAcoes2Icons md={1}>
                          {insideIndex === 0 && (
                            <PTitulosInputs>Ação</PTitulosInputs>
                          )}
                          <Icon
                            icon={
                              editMode[index][insideIndex]
                                ? "fluent:save-24-filled"
                                : "bx:edit"
                            }
                            height="26"
                            color={
                              editMode[index][insideIndex]
                                ? "#008CFF"
                                : "#5971C8"
                            }
                            onClick={() =>
                              editMode[index][insideIndex]
                                ? handleEditToggle(
                                    index,
                                    insideIndex,
                                    key,
                                    insideKey,
                                    false
                                  )
                                : handleEditToggle(
                                    index,
                                    insideIndex,
                                    key,
                                    insideKey,
                                    true
                                  )
                            }
                            style={{
                              cursor: "pointer",
                              marginTop: "3px",
                            }}
                          />

                          {editMode[index][insideIndex] && (
                            <Icon
                              icon="ic:baseline-cancel"
                              height="26"
                              color="#F24E1E"
                              onClick={() =>
                                handleEditToggle(
                                  index,
                                  insideIndex,
                                  key,
                                  insideKey,
                                  false,
                                  true
                                )
                              }
                              style={{
                                cursor: "pointer",
                                marginLeft: "2px",
                                marginRight: "-5px",
                                marginTop: "3px",
                              }}
                            />
                          )}
                        </CColButtonsAcoes2Icons>
                      </RowAninhada>
                    );
                  }
                )}
              </RowAninhada>
            </>
          );
        })}
      </Row>
      <TestButton onClick={() => console.log(formatValue(values))}>
        Console
      </TestButton>
    </>
  );
};

export default Divida;
