import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../PagesAcessoInterno/Cadastros/Gerenciador_Cadastros_CPF_CNPJ/Cadastros_cpf_cnpj";
import { Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import "../../styles/Css/TarefasPrazos/tarefas_e_prazos_hoje.css";
import { NavLink } from "react-router-dom";

const DataArrowsDiv = styled.div`
  padding: 2px;
  transform: translateX(-35px);

  span {
    font-size: 15px;
  }
`;

const IconArrowsData = styled(Icon)`
  margin: 0px 10px 0px 10px;
  transform: translateY(-1px);
`;

const ButtonArrowsData = styled.button`
  border: none;
  background: transparent;
`;

const ColTasksData = styled(Col)`
  height: 250px;

  @media (max-width: 767.98px) {
    margin-bottom: 10px;
  }
`;

const DivTasksData = styled.div`
  border-radius: 10px;
  border: 3px solid #b1b0b6;
  height: 250px;
`;

const NumeroData = styled.h2`
  border: 3px solid #b1b0b6;
  border-radius: 10px;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: bolder;
  color: #b1b0b6;
`;

const InfoDataDiv = styled.div`
  display: flex;
`;

const DivSpanMes = styled.div`
  height: 90px;
  display: flex;
  align-items: end;
`;

const SpanCustom = styled.span`
  margin-left: 10px;
  text-transform: uppercase;
  font-weight: bold;
  color: #b1b0b6;
`;

const CheksTasks = styled(Form.Check)`
  color: #6c7585;
  font-weight: 500;
  padding: 0px 0 5px 0;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: -5px;

  label {
    display: flex;
  }

  input[type="checkbox"] {
    background-color: #f7f7f7;
    border-radius: 2px;
    border: 2px solid #b1b0b6;
    margin-right: 7px;
  }

  input[type="checkbox"]:checked {
    background-color: green;
    border-color: green;
  }
`;

const DivChecksTasks = styled.div`
  margin-top: 25px;
`;

const DivSituacaoTasks = styled.div`
  height: 35px;
  background-color: #b1b0b6;
  border-radius: 0px 0px 5px 5px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  margin-top: 5px;

  span {
    color: #515c70;
    font-weight: bold;
  }
`;

const NavLinkStyledHoje = styled(NavLink)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  width: 60px;
  padding-top: 3px;
  padding-bottom: 3px;
  background: transparent;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-left: 9px;
  color: #515c70;

  :hover {
    background-color: #ced4da;
    color: #515c70;
  }
`;

const InfosGeraisTasks = styled.div`
  padding: 10px 10px 0px 10px;
`;

const TarefasPrazosHoje = () => {
  return (
    <Container
      id="pags_responsividade_margin_tarefas"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      {/*Row do Titulo Da Página*/}
      <Row>
        <Col md={12}>
          <div className="d-flex space_between titulo_conteudo">
            <span className="cor_letra_primaria fw-bolder">
              Tarefas e Prazos
            </span>

            <div className="header_conteudo">
              <Button variant="success">Adicionar</Button>
            </div>
          </div>
        </Col>
      </Row>

      {/*Row do Cabeçalho do Conteudo*/}
      <Row>
        <Col
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <NavLinkStyledHoje to="/planejamento/tarefas-prazos-mensal">
            Hoje
          </NavLinkStyledHoje>
          <DataArrowsDiv>
            <ButtonArrowsData>
              <IconArrowsData
                icon="material-symbols:arrow-back-ios-new-rounded"
                color="#515c70"
              />
            </ButtonArrowsData>
            <span>2023</span>
            <ButtonArrowsData>
              <IconArrowsData
                icon="material-symbols:arrow-back-ios-new-rounded"
                color="#515c70"
                rotate={2}
              />
            </ButtonArrowsData>
          </DataArrowsDiv>
          <div className="header_body_nav"></div>
        </Col>
      </Row>

      {/*Form da Página*/}
      <Row style={{ borderRadius: "0 0 5px 5px" }} className="row_form mt-1">
        <ColTasksData md={3}>
          <DivTasksData>
            <InfosGeraisTasks>
              <InfoDataDiv>
                <NumeroData>01</NumeroData>
                <DivSpanMes>
                  <SpanCustom>Janeiro</SpanCustom>
                </DivSpanMes>
              </InfoDataDiv>
              <DivChecksTasks>
                <CheksTasks reverse label="Abertura do Exercício" />
                <CheksTasks reverse label="Diagnostico Mensal" />
                <CheksTasks reverse label="Abertura do Exercício" />
              </DivChecksTasks>
            </InfosGeraisTasks>
            <DivSituacaoTasks>
              <span>Aberto</span>
            </DivSituacaoTasks>
          </DivTasksData>
        </ColTasksData>
        <ColTasksData md={3}>
          <DivTasksData>
            <InfosGeraisTasks>
              <InfoDataDiv>
                <NumeroData>02</NumeroData>
                <DivSpanMes>
                  <SpanCustom>Fevereiro</SpanCustom>
                </DivSpanMes>
              </InfoDataDiv>
              <DivChecksTasks>
                <CheksTasks reverse label="Abertura do Exercício" />
                <CheksTasks reverse label="Diagnostico Mensal" />
                <CheksTasks reverse label="Abertura do Exercício" />
              </DivChecksTasks>
            </InfosGeraisTasks>
            <DivSituacaoTasks>
              <span>Aberto</span>
            </DivSituacaoTasks>
          </DivTasksData>
        </ColTasksData>
        <ColTasksData md={3}>
          <DivTasksData>
            <InfosGeraisTasks>
              <InfoDataDiv>
                <NumeroData>03</NumeroData>
                <DivSpanMes>
                  <SpanCustom>Março</SpanCustom>
                </DivSpanMes>
              </InfoDataDiv>
              <DivChecksTasks>
                <CheksTasks reverse label="Abertura do Exercício" />
                <CheksTasks reverse label="Diagnostico Mensal" />
                <CheksTasks reverse label="Abertura do Exercício" />
              </DivChecksTasks>
            </InfosGeraisTasks>
            <DivSituacaoTasks>
              <span>Aberto</span>
            </DivSituacaoTasks>
          </DivTasksData>
        </ColTasksData>
        <ColTasksData md={3}>
          <DivTasksData>
            <InfosGeraisTasks>
              <InfoDataDiv>
                <NumeroData>04</NumeroData>
                <DivSpanMes>
                  <SpanCustom>Abril</SpanCustom>
                </DivSpanMes>
              </InfoDataDiv>
              <DivChecksTasks>
                <CheksTasks reverse label="Abertura do Exercício" />
                <CheksTasks reverse label="Diagnostico Mensal" />
                <CheksTasks reverse label="Abertura do Exercício" />
              </DivChecksTasks>
            </InfosGeraisTasks>
            <DivSituacaoTasks>
              <span>Aberto</span>
            </DivSituacaoTasks>
          </DivTasksData>
        </ColTasksData>
        <div className="d-none d-md-block p-2"></div>
        <ColTasksData md={3}>
          <DivTasksData>
            <InfosGeraisTasks>
              <InfoDataDiv>
                <NumeroData>05</NumeroData>
                <DivSpanMes>
                  <SpanCustom>Maio</SpanCustom>
                </DivSpanMes>
              </InfoDataDiv>
              <DivChecksTasks>
                <CheksTasks reverse label="Abertura do Exercício" />
                <CheksTasks reverse label="Diagnostico Mensal" />
                <CheksTasks reverse label="Abertura do Exercício" />
              </DivChecksTasks>
            </InfosGeraisTasks>
            <DivSituacaoTasks>
              <span>Aberto</span>
            </DivSituacaoTasks>
          </DivTasksData>
        </ColTasksData>
        <ColTasksData md={3}>
          <DivTasksData>
            <InfosGeraisTasks>
              <InfoDataDiv>
                <NumeroData>06</NumeroData>
                <DivSpanMes>
                  <SpanCustom>Junho</SpanCustom>
                </DivSpanMes>
              </InfoDataDiv>
              <DivChecksTasks>
                <CheksTasks reverse label="Abertura do Exercício" />
                <CheksTasks reverse label="Diagnostico Mensal" />
                <CheksTasks reverse label="Abertura do Exercício" />
              </DivChecksTasks>
            </InfosGeraisTasks>
            <DivSituacaoTasks>
              <span>Aberto</span>
            </DivSituacaoTasks>
          </DivTasksData>
        </ColTasksData>
        <ColTasksData md={3}>
          <DivTasksData>
            <InfosGeraisTasks>
              <InfoDataDiv>
                <NumeroData>07</NumeroData>
                <DivSpanMes>
                  <SpanCustom>Julho</SpanCustom>
                </DivSpanMes>
              </InfoDataDiv>
              <DivChecksTasks>
                <CheksTasks reverse label="Abertura do Exercício" />
                <CheksTasks reverse label="Diagnostico Mensal" />
                <CheksTasks reverse label="Abertura do Exercício" />
              </DivChecksTasks>
            </InfosGeraisTasks>
            <DivSituacaoTasks>
              <span>Aberto</span>
            </DivSituacaoTasks>
          </DivTasksData>
        </ColTasksData>
        <ColTasksData md={3}>
          <DivTasksData>
            <InfosGeraisTasks>
              <InfoDataDiv>
                <NumeroData>08</NumeroData>
                <DivSpanMes>
                  <SpanCustom>Agosto</SpanCustom>
                </DivSpanMes>
              </InfoDataDiv>
              <DivChecksTasks>
                <CheksTasks reverse label="Abertura do Exercício" />
                <CheksTasks reverse label="Diagnostico Mensal" />
                <CheksTasks reverse label="Abertura do Exercício" />
              </DivChecksTasks>
            </InfosGeraisTasks>
            <DivSituacaoTasks>
              <span>Aberto</span>
            </DivSituacaoTasks>
          </DivTasksData>
        </ColTasksData>
        <div className="d-none d-md-block p-2"></div>
        <ColTasksData md={3}>
          <DivTasksData>
            <InfosGeraisTasks>
              <InfoDataDiv>
                <NumeroData>09</NumeroData>
                <DivSpanMes>
                  <SpanCustom>Setembro</SpanCustom>
                </DivSpanMes>
              </InfoDataDiv>
              <DivChecksTasks>
                <CheksTasks reverse label="Abertura do Exercício" />
                <CheksTasks reverse label="Diagnostico Mensal" />
                <CheksTasks reverse label="Abertura do Exercício" />
              </DivChecksTasks>
            </InfosGeraisTasks>
            <DivSituacaoTasks>
              <span>Aberto</span>
            </DivSituacaoTasks>
          </DivTasksData>
        </ColTasksData>
        <ColTasksData md={3}>
          <DivTasksData>
            <InfosGeraisTasks>
              <InfoDataDiv>
                <NumeroData>10</NumeroData>
                <DivSpanMes>
                  <SpanCustom>Outubro</SpanCustom>
                </DivSpanMes>
              </InfoDataDiv>
              <DivChecksTasks>
                <CheksTasks reverse label="Abertura do Exercício" />
                <CheksTasks reverse label="Diagnostico Mensal" />
                <CheksTasks reverse label="Abertura do Exercício" />
              </DivChecksTasks>
            </InfosGeraisTasks>
            <DivSituacaoTasks>
              <span>Aberto</span>
            </DivSituacaoTasks>
          </DivTasksData>
        </ColTasksData>
        <ColTasksData md={3}>
          <DivTasksData>
            <InfosGeraisTasks>
              <InfoDataDiv>
                <NumeroData>11</NumeroData>
                <DivSpanMes>
                  <SpanCustom>Novembro</SpanCustom>
                </DivSpanMes>
              </InfoDataDiv>
              <DivChecksTasks>
                <CheksTasks reverse label="Abertura do Exercício" />
                <CheksTasks reverse label="Diagnostico Mensal" />
                <CheksTasks reverse label="Abertura do Exercício" />
              </DivChecksTasks>
            </InfosGeraisTasks>
            <DivSituacaoTasks>
              <span>Aberto</span>
            </DivSituacaoTasks>
          </DivTasksData>
        </ColTasksData>
        <ColTasksData md={3}>
          <DivTasksData>
            <InfosGeraisTasks>
              <InfoDataDiv>
                <NumeroData>12</NumeroData>
                <DivSpanMes>
                  <SpanCustom>Dezembro</SpanCustom>
                </DivSpanMes>
              </InfoDataDiv>
              <DivChecksTasks>
                <CheksTasks reverse label="Abertura do Exercício" />
                <CheksTasks reverse label="Diagnostico Mensal" />
                <CheksTasks reverse label="Abertura do Exercício" />
              </DivChecksTasks>
            </InfosGeraisTasks>
            <DivSituacaoTasks>
              <span>Aberto</span>
            </DivSituacaoTasks>
          </DivTasksData>
        </ColTasksData>
      </Row>
    </Container>
  );
};

export default TarefasPrazosHoje;
