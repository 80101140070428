import {
  Paragraph,
  TextRun,
  ImageRun,
  Header,
  TextWrappingType,
  TextWrappingSide,
  AlignmentType,
  BorderStyle,
} from "docx";

export const DocxHeader = ({
  logo,
  nameUnidadeGestora,
  // municipio,
  // estado,
  ano,
  title,
  font,
  nameFiltroHeader = "",
}) => {
  return new Header({
    children: [
      new Paragraph({
        children: [
          new ImageRun({
            data: logo,
            transformation: {
              width: 90,
              height: 90,
            },
            floating: {
              horizontalPosition: {
                relative: "page",
                offset: 350000,
              },
              verticalPosition: {
                relative: "paragraph",
                offset: 0,
              },
              wrap: {
                type: TextWrappingType.SQUARE,
                side: TextWrappingSide.BOTH_SIDES,
              },
            },
          }),
          new TextRun({
            text: nameUnidadeGestora?.toUpperCase(),
            bold: true,
            size: 24,
            font: font,
          }),
          new TextRun({
            text: "Relatório de Verificação",
            break: 1,
            font: font,
          }),
          new TextRun({
            text: title,
            break: 1,
            font: font,
          }),
        ],
        indent: {
          left: 0,
        },
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: `Anual ${ano}`,
            break: 2,
            font: font,
          }),
        ],
        alignment: AlignmentType.RIGHT,
        border: {
          bottom: {
            style: BorderStyle.SINGLE,
            size: 2,
            space: 1,
          },
        },
        spacing: {
          after: nameFiltroHeader.length ? 0 : 200,
        },
      }),
      // new Paragraph({
      //   children: [],
      //   border: {
      //     top: {
      //       style: BorderStyle.SINGLE,
      //       size: 2,
      //       space: 1,
      //     },
      //   },
      //   // spacing: {
      //   //   before: nameFiltroHeader.length ? 0 : 100,
      //   // },
      //   alignment: AlignmentType.CENTER,
      //   shading: {
      //     fill: "FF0000",
      //   },
      // }),
      ...(nameFiltroHeader
        ? [
            new Paragraph({
              children: [
                new TextRun({
                  text: nameFiltroHeader,
                  size: 9 * 2,
                  font: font,
                }),
              ],
              alignment: "right",
              spacing: {
                after: 5,
                before: 5,
              },
            }),
          ]
        : []),
    ],
  });
};
