import React, { useState, useContext, useEffect } from "react";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import {
  CCol,
  RowAninhada,
  CColButtonsAcoes2Icons,
} from "../../../../components/Grid/CCol";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { FormControlListaDeInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { toast } from "react-toastify";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import api from "../../../../utils/api";

const CustomInput = ({
  title,
  md = 2,
  size,
  Btop,
  Bbottom,
  all,
  menu,
  ...props
}) => {
  const borderRadius = Btop
    ? "5px 5px 0 0"
    : Bbottom
    ? "0 0 5px 5px"
    : all
    ? "5px 5px 5px 5px"
    : "0";

  return (
    <CCol md={md} style={size && { width: size }}>
      {title && <Title>{title}</Title>}
      {!menu ? (
        <FormControlListaDeInputs radiusborder={borderRadius} {...props} />
      ) : menu === 1 ? (
        <FloatingLabelInput
          new
          tipo="input_select"
          height={"30px"}
          radiusborder={borderRadius}
          {...props}
        />
      ) : (
        menu === 2 && <MyAutoComplete radiusborder={borderRadius} {...props} />
      )}
    </CCol>
  );
};

const Contratos = ({ addContratos, values }) => {
  const { currentYear } = useContext(CurrentYearContext);
  const { currentClient } = useContext(CurrentClientContext);

  const [inputAutoCompleteValue, setInputAutoComplete] = useState("");
  const [participantesOptions, setParticipantesOptions] = useState([]);
  const [height, setHeight] = useState(70);
  const [titles, setTitles] = useState([]);
  const [defValues, setDefValues] = useState({
    nContratos: values.length,
    participante: "",
    dataAssinatura: "",
    dataVigencia: "",
    valor: 0,
  });

  const addValues = () => {
    console.log(defValues);
    const hasEmptyValues = Object.values(defValues).some(
      (value) => value === "" || value === null || value === undefined
    );

    if (!hasEmptyValues) {
      setInputAutoComplete("");
      addContratos(defValues);
      setDefValues({
        nContratos: values.length + 1,
        participante: "",
        dataAssinatura: "",
        dataVigencia: "",
        valor: 0,
      });
    }
  };

  const updateTitles = () => {
    setTitles([
      "Nº Licitação",
      "Modalidade",
      "Nº Contratos",
      "Participante",
      "Data Assinatura",
      "Data Vigência",
      "Valor",
    ]);
  };

  useEffect(() => {
    updateTitles();
    // eslint-disable-next-line
  }, [currentYear]);

  useEffect(() => {
    setHeight(70 + 30 * values.length);
    setDefValues({
      nContratos: values.length,
      participante: "",
      dataAssinatura: "",
      dataVigencia: "",
      valor: 0,
    });
  }, [values]);

  useEffect(() => {
    console.log("Iniciada");
    api
      .get(`cpf/por-cliente/${currentClient.clienteId}`)
      .then((resp) => {
        const data = resp.data;
        console.log(data);

        const options = data.map((secretario) => ({
          cpf: secretario.cpf,
          pessoaId: secretario.pessoaId,
          nome: secretario.nomeCompleto,
        }));

        setParticipantesOptions(options);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu errado ao carregar os dados.");
      });
    //eslint-disable-next-line
  }, [currentClient]);

  const handleOptionSelect = (option) => {
    if (option) {
      setDefValues((prevValues) => ({
        ...prevValues,
        participante: option.pessoaId,
      }));
    }
  };

  return (
    <>
      <Row className="row_form mt-1">
        <StyledCCol
          style={{
            height:
              inputAutoCompleteValue === ""
                ? `${height}px`
                : `${height + 278}px`,
          }}
        >
          <RowAninhada
            style={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <CustomInput
              disabled
              title={titles[2]}
              all={values.length === 0}
              Btop={values.length > 0}
              value={defValues.nContratos}
              // onChange={(e) =>
              //   setDefValues((prevValues) => ({
              //     ...prevValues,
              //     nContratos: e.target.value,
              //   }))
              // }
            />
            <CustomInput
              menu={2}
              title={titles[3]}
              all={values.length === 0}
              Btop={values.length > 0}
              inputValue={inputAutoCompleteValue}
              setInputValue={setInputAutoComplete}
              options={participantesOptions}
              labelFormat={(option) =>
                `${option.nome} - ${FormatCpfPaste(option.cpf)}`
              }
              onOptionSelect={handleOptionSelect}
              placeholder="Participante"
              size={"23.5em"}
            />
            <CustomInput
              title={titles[4]}
              all={values.length === 0}
              Btop={values.length > 0}
              value={defValues.dataAssinatura}
              onChange={(e) =>
                setDefValues((prevValues) => {
                  let input = e.target.value.replace(/\D/g, "");
                  if (input.length > 8) input = input.slice(0, 8);
                  if (input.length > 4) {
                    input = input.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
                  } else if (input.length > 2) {
                    input = input.replace(/(\d{2})(\d{2})/, "$1/$2");
                  }

                  return {
                    ...prevValues,
                    dataAssinatura: input,
                  };
                })
              }
            />
            <CustomInput
              title={titles[5]}
              all={values.length === 0}
              Btop={values.length > 0}
              value={defValues.dataVigencia}
              onChange={(e) =>
                setDefValues((prevValues) => {
                  let input = e.target.value.replace(/\D/g, "");
                  if (input.length > 8) input = input.slice(0, 8);
                  if (input.length > 4) {
                    input = input.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
                  } else if (input.length > 2) {
                    input = input.replace(/(\d{2})(\d{2})/, "$1/$2");
                  }

                  return {
                    ...prevValues,
                    dataVigencia: input,
                  };
                })
              }
            />
            <CustomInput
              title={titles[6]}
              all={values.length === 0}
              Btop={values.length > 0}
              value={defValues.valor}
              onChange={(e) => {
                let input = e.target.value;
                input = input.replace(/[^0-9,]/g, "");
                if (input.includes(",")) {
                  const [inteiro, centavos] = input.split(",");
                  input = `${inteiro},${centavos.slice(0, 2)}`;
                }
                const partes = input.split(",");
                let numero = partes[0];
                numero = numero.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                input = partes.length > 1 ? `${numero},${partes[1]}` : numero;
                setDefValues((prevValues) => ({
                  ...prevValues,
                  valor: input,
                }));
              }}
            />
          </RowAninhada>
          {values.map((item, index) => {
            return (
              <RowAninhada
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <CustomInput
                  disabled
                  Bbottom={index + 1 === values.length}
                  value={values[index].nContratos}
                />
                <CustomInput
                  disabled
                  Bbottom={index + 1 === values.length}
                  size={"23.5em"}
                  value={
                    participantesOptions.find(
                      (option) => option.pessoaId === values[index].participante
                    )
                      ? `${
                          participantesOptions.find(
                            (option) =>
                              option.pessoaId === values[index].participante
                          ).nome
                        } - ${FormatCpfPaste(
                          participantesOptions.find(
                            (option) =>
                              option.pessoaId === values[index].participante
                          ).cpf
                        )}`
                      : ""
                  }
                />
                <CustomInput
                  disabled
                  Bbottom={index + 1 === values.length}
                  value={values[index].dataAssinatura}
                />
                <CustomInput
                  disabled
                  Bbottom={index + 1 === values.length}
                  value={values[index].dataVigencia}
                />
                <CustomInput
                  disabled
                  Bbottom={index + 1 === values.length}
                  value={values[index].valor}
                />
              </RowAninhada>
            );
          })}
        </StyledCCol>
        <CColButtonsAcoes2Icons md={2}>
          <Title>Ação</Title>
          <IconAdicionar onClick={() => addValues()} />
        </CColButtonsAcoes2Icons>
      </Row>
    </>
  );
};

export default Contratos;

const Title = styled.p`
  text-align: left;
  margin-top: 0;
  padding: 0px;
  margin-bottom: 0px;
  color: #888a8e;
  font-weight: 600;
  font-size: 12px;
`;

const StyledCCol = styled(CCol)`
  overflow: auto;

  &::-webkit-scrollbar {
    height: 2px;
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
