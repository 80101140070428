import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";

function ExpandableButtons({ saveFunction, active, iconsDisabled = false }) {
  const [expanded, setExpanded] = useState(active);
  const [buttonState, setButtonState] = useState(false);


  const toggleExpand = () => {
    if (expanded === true) {
      setButtonState(true);
      setExpanded(!expanded);
      setButtonState(false);
    } else {
      setExpanded(!expanded);
    }
  };

  const handleSave = () => {
    saveFunction();
    toggleExpand();
  };

  return (
    <IconesFormUsuario iconsDisabled={iconsDisabled}>
      {expanded && (
        <ButtonIcon
          style={{ padding: "3px 0" }}
          onClick={handleSave}
          disabled={buttonState}
        >
          <Icon
            icon="fluent:save-24-filled"
            color="#008CFF"
            width="25"
            height="25"
          />
        </ButtonIcon>
      )}
      <ButtonIcon
        style={{ padding: "3px 0" }}
        onClick={toggleExpand}
        disabled={buttonState}
      >
        <Icon
          icon={expanded ? "carbon:close-filled" : "bx:edit"}
          color={expanded ? "#ff2828" : "#5971C8"}
          width="25"
          height="25"
        />
      </ButtonIcon>
    </IconesFormUsuario>
  );
}

export default ExpandableButtons;

const IconesFormUsuario = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin: 1px 0 8px;
  ${({ iconsDisabled }) =>
    iconsDisabled &&
    `
    pointer-events: none;
    opacity: 0.8;
    `}
`;

const ButtonIcon = styled.button`
  border: none;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;