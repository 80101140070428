import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Container } from "react-bootstrap";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { useGenerateRelatorio } from "../../../../../hooks/useGenerateRelatorio";
import { CurrentClientContext } from "../../../../../contexts/CurrentClientContext";
import HeaderCadastros from "../../../../../components/HeaderCadastros/HeaderCadastros";
import PagRelatorioComponente from "../../../../../components/ComponenteDeRelatorio/PagRelatorioComponente";
import { PdfObjetivosMilenio } from "./PdfObjetivosMilenio";
import VizualizacaoRelatorioMilenio from "./VizualizacaoRelatorio";
import { DivPrintOnly } from "../../../../../styles/StyledComponents/StylesRelatorios";
import GlobalStyles from "../../../../../styles/StyledComponents/GlobalStyles";
import { isBase64 } from "../../../../../utils/Gerais/isBase64";

const RelatorioObjetiosMilenio = () => {
  const relatorioName = "Relatório Objetivos da ONU";
  const columnWidth = [15, 8, 8, 29, 40];

  // const { currentYear } = useContext(CurrentYearContext);
  const { currentClient } = useContext(CurrentClientContext);
  // const { userAuthData } = useContext(AuthContext);

  const { data: dataObjetivosDoMilenio } = useFetchData("/objetivoDoMilenio");
  // const { data: dataClientInfos } = useFetchData(
  //   `cliente/pesquisa/id/${currentClient.clienteId}`
  // );

  const [selectBoxesOptionsAbaOptions, setSelectBoxesOptionsAbaOptions] =
    useState([
      {
        opcao: "Exibir", //"Objetivos da ONU",
        checkBoxes: [
          { label: "Imagem", value: "img", selected: false },
          { label: "Código", value: "codigo", selected: true },
          { label: "De-Para", value: "dePara", selected: false },
          { label: "Descrição", value: "descricao", selected: true },
          { label: "Objetivos", value: "objetivo", selected: true },
        ],
      },
    ]);
  const [selectBoxesOptionsAbaPrincipal, setSelectBoxesOptionsAbaPrincipal] =
    useState([]);

  const {
    dadosRelatorio,
    dataClientInfos,
    handleGenerateDocx,
    handleGeneratePdf,
    handleGenerateXls,
    handlePrint,
    pdfData,
  } = useGenerateRelatorio(
    selectBoxesOptionsAbaOptions,
    selectBoxesOptionsAbaPrincipal,
    currentClient,
    relatorioName,
    columnWidth
  );
  // const [dadosRelatorio, setDadosRelatorio] = useState(null);
  // const [pdfData, setPdfData] = useState(null);
  // const [printReady, setPrintReady] = useState(false);
  // useEffect(() => {
  //   if (
  //     selectBoxesOptionsAbaPrincipal.length > 0 &&
  //     dataObjetivosDoMilenio &&
  //     selectBoxesOptionsAbaPrincipal[0].checkBoxes
  //   ) {
  //     const itensSelecionados = selectBoxesOptionsAbaPrincipal[0].checkBoxes
  //       .filter((item) => item.selected)
  //       .map((checkBox) => checkBox.label.replace(/[^0-9]/g, ""));
  //     const objetivosSelecionados = dataObjetivosDoMilenio.filter((item) =>
  //       itensSelecionados.includes(item.codigo)
  //     );

  //     const dadosRelatorio = {
  //       objetivos: objetivosSelecionados,
  //     };

  //     setDadosRelatorio(dadosRelatorio);
  //   }
  // }, [selectBoxesOptionsAbaPrincipal, dataObjetivosDoMilenio]);

  const getDadosRelatorio = () => {
    const selectBox = selectBoxesOptionsAbaPrincipal?.find(
      (item) => item.opcao === "Objetivos da ONU"
    );

    const selectBoxSelected = selectBox?.checkBoxes.some(
      (item) => item.selected
    );

    if (!selectBoxSelected) return [];

    const itensSelecionados = selectBox.checkBoxes
      .filter((item) => item.selected)
      .map((checkBox) => checkBox.label.replace(/[^0-9]/g, ""));

    const dadosSelecionados = dataObjetivosDoMilenio?.filter((item) =>
      itensSelecionados.includes(item.codigo)
    );

    return dadosSelecionados;
  };

  // const solicitarRelatorio = () => {
  //   console.log(selectBoxesOptionsAbaPrincipal);

  //   const itensSelecionados = selectBoxesOptionsAbaPrincipal[1].checkBoxes
  //     .filter((item) => item.selected)
  //     .map((checkBox) => checkBox.label.replace(/[^0-9]/g, ""));

  //   console.log(
  //     dataObjetivosDoMilenio.filter((item) =>
  //       itensSelecionados.includes(item.codigo)
  //     )
  //   );

  //   const objetivosSelecionados = dataObjetivosDoMilenio.filter((item) =>
  //     itensSelecionados.includes(item.codigo)
  //   );

  //   const dadosRelatorio = {
  //     objetivos: objetivosSelecionados,
  //   };
  //   setDadosRelatorio(dadosRelatorio);

  //   // const url = "/planejamento/visualizar/objetivos-da-onu/relatorio";
  //   // window.open(url, "_blank", "noopener,noreferrer");

  //   // setDadosRelatorio({
  //   //   objetivos: dataObjetivosDoMilenio.filter((item) =>
  //   //     itensSelecionados.includes(item.codigo)
  //   //   ),
  //   // });
  // };

  // const handlePrint = () => {
  //   const dadosRelatorio = getDadosRelatorio();
  //   setDadosRelatorio(dadosRelatorio);
  //   setPrintReady(true);
  // };

  const handleGenerateDataXls = () => {
    const dadosRelatorio = getDadosRelatorio();

    const dataTable = dadosRelatorio
      ?.map((item) => {
        const selectedFields = {};
        selectBoxesOptionsAbaOptions[0]?.checkBoxes?.forEach(
          ({ label, value, selected }) => {
            const actualItem = item[value];
            if (selected && !isBase64(actualItem)[0]) {
              selectedFields[label] = actualItem;
            }
          }
        );

        return Object.keys(selectedFields).length > 0 ? selectedFields : null;
      })
      .filter((item) => item);
    if (dataTable.length !== 0) {
      handleGenerateXls(dataTable);
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  // const handleGeneratePdf = () => {
  //   const dadosRelatorio = getDadosRelatorio();
  //   if (
  //     hasAnySelected(selectBoxesOptionsAbaOptions) &&
  //     hasAnySelected(selectBoxesOptionsAbaPrincipal)
  //   ) {
  //     if (dadosRelatorio) {
  //       console.log("pdf gerado");
  //       const pdfData = {
  //         pdfName: "Relatório Objetivos da ONU",
  //         PdfComponent: PdfObjetivosMilenio,
  //         pdfContent: {
  //           dadosRelatorio,
  //           opcoes: selectBoxesOptionsAbaOptions,
  //           userData: {
  //             currentClient,
  //             userAuthData,
  //             dataClientInfos,
  //           },
  //           currentYear,
  //         },
  //       };
  //       setPdfData(pdfData);
  //     }
  //   } else {
  //     toast.warn(
  //       "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
  //     );
  //   }
  // };

  // const handleGenerateDocx = async () => {
  //   const dadosRelatorio = getDadosRelatorio();
  //   const title = "Objetivos da ONU";
  //   const pdfContent = {
  //     dadosRelatorio: processarDadosRelatorio(
  //       dadosRelatorio,
  //       selectBoxesOptionsAbaOptions
  //     ),
  //     opcoes: getSelected(selectBoxesOptionsAbaOptions),
  //     userData: {
  //       currentClient,
  //       userAuthData,
  //     },
  //     dateRelatorio: { year: currentYear },
  //   };

  //   if (
  //     hasAnySelected(selectBoxesOptionsAbaOptions) &&
  //     hasAnySelected(selectBoxesOptionsAbaPrincipal)
  //   ) {
  //     toast.promise(
  //       generateDocxBlob({ pdfContent }, title, dataClientInfos[0].brasao).then(
  //         (blob) => {
  //           const url = URL.createObjectURL(blob);
  //           const link = document.createElement("a");
  //           link.href = url;
  //           link.download = title + ".docx";
  //           link.click();
  //           URL.revokeObjectURL(url);
  //         }
  //       ),
  //       {
  //         pending: "Gerando o documento...",
  //         success: "Documento gerado com sucesso!",
  //         error: "Falha ao gerar o documento.",
  //       }
  //     );
  //   } else {
  //     toast.warn(
  //       "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
  //     );
  //   }
  // };

  // useEffect(() => {
  //   if (printReady) {
  //     window.print();
  //     setPrintReady(false);
  //   }
  // }, [printReady]);

  // useEffect(() => {
  //   const handleAfterPrint = () => {
  //     setDadosRelatorio(null);
  //   };

  //   window.addEventListener("afterprint", handleAfterPrint);

  //   return () => {
  //     window.removeEventListener("afterprint", handleAfterPrint);
  //   };
  // }, []);

  useEffect(() => {
    if (
      dataObjetivosDoMilenio &&
      !selectBoxesOptionsAbaPrincipal.some(
        (item) => item.opcao === "Objetivos da ONU"
      )
    ) {
      setSelectBoxesOptionsAbaPrincipal((prev) => [
        ...prev,
        {
          opcao: "Objetivos da ONU",
          checkBoxes: dataObjetivosDoMilenio.map((item) => ({
            label: `${item.codigo} - ${
              item.descricao.length > 40
                ? `${item.descricao.substring(0, 40)}...`
                : item.descricao
            }`,
            value: item.id,
            selected: false,
          })),
        },
      ]);
    }
  }, [dataObjetivosDoMilenio, selectBoxesOptionsAbaPrincipal]);

  return (
    <>
      {/* {navigateRelatorio && Object.keys(dadosRelatorio).length > 0 && (
        <VizualizacaoRelatorio
          dadosRelatorio={dadosRelatorio}
          setNavigateRelatorio={setNavigateRelatorio}
        />
      )} */}
      <GlobalStyles orientation={"landscape"} />
      {dadosRelatorio && (
        <DivPrintOnly>
          <VizualizacaoRelatorioMilenio
            dadosRelatorio={dadosRelatorio}
            dataClientInfos={dataClientInfos}
            numeroDaPagina={1}
            totalDePaginas={2}
          />
        </DivPrintOnly>
      )}

      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina hide_on_print"
      >
        <HeaderCadastros
          PaginaConsulta={"/planejamento/cadastro/objetivos-da-onu/consulta"}
          NomePaginaTitulo={"Objetivos da ONU"}
          PaginaSubtitulo={"Relatório"}
          BotaoNovoAtivo={false}
          BotaoSaveAtivo={false}
          OpcoesDeArquivo={true}
          DashboardPage={"/planejamento/cadastro/objetivos-da-onu/dashboard"}
          ButtonVisualizar={() => handlePrint(getDadosRelatorio)}
          ButtonPDF={(action) =>
            handleGeneratePdf(PdfObjetivosMilenio, getDadosRelatorio, action)
          }
          ButtonDocx={() => handleGenerateDocx(getDadosRelatorio)}
          PdfData={pdfData}
          ButtonXls={handleGenerateDataXls}
        />
        <ToastContainer />

        <PagRelatorioComponente
          topoEsquerdoOptions={[
            { label: "Relatório", value: null },
            { label: "Relatório", value: 1 },
          ]}
          principalOptions={[
            {
              label: "Objetivos da ONU",
              value: "Objetivos da ONU",
            },
          ]}
          labelsCheckAbaPrincipal={selectBoxesOptionsAbaPrincipal}
          setLabelsCheckAbaPrincipal={setSelectBoxesOptionsAbaPrincipal}
          labelsCheckAbaOpcoes={selectBoxesOptionsAbaOptions}
          setLabelsCheckAbaOpcoes={setSelectBoxesOptionsAbaOptions}
          hasAssinatura={false}
          hasFavorite={false}
        />
      </Container>
    </>
  );
};

export default RelatorioObjetiosMilenio;
