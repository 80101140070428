import { Row, Col, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import InputSvg from "../../../../components/Grid/InputSvg";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import { ToastContainer, toast } from "react-toastify";
import {
  ModalStyled,
  ButtonsDiv,
  DivIconModal,
  IconAlertModal,
} from "../../../../styles/StyledComponents/ModalStyled";
import api from "../../../../utils/api";

const SubFuncaoListaPlanejamento = () => {
  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itensFiltrados, setItensFiltrados] = useState([]);
  const [loadingLista, setLoadingLista] = useState(true);

  const [modalOpened, setModalOpened] = useState(false);
  const [dataDelete, setDataDelete] = useState({});

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = itens.filter((item) => {
      return (
        item.nome.toLowerCase().includes(value.toLowerCase()) ||
        item.valor.includes(value)
      );
    });
    setItensFiltrados(filteredItems);
    setCurrentPage(0);
  };

  const fetchData = () => {
    setLoadingLista(true);
    api
      .get("subfuncao/subfuncoes")
      .then((response) => {
        setItens(response.data);
        setLoadingLista(false);
      })
      .catch((error) => {
        toast.error("Algo deu errado. Por favor, tente mais tarde.");
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  function closeModal() {
    setModalOpened(false);
  }

  const deleteSubfuncao = () => {
    const { id } = dataDelete;
    api
      .delete(`subfuncao/delete/${id}`)
      .then(() => {
        toast.success("Subfunção deletada com sucesso!");
        fetchData();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <ToastContainer />
      <div>
        <ModalStyled
          isOpen={modalOpened}
          onRequestClose={closeModal}
          overlayClassName="modal-overlay"
          contentLabel="Example Modal"
        >
          <DivIconModal>
            <IconAlertModal
              width={100}
              icon="fluent:error-circle-20-regular"
              color="red"
              hexcolor="#ff0000"
            />
            <Icon />
          </DivIconModal>

          <h6>Tem certeza que deseja deletar permanentemente esses dados?</h6>
          <p>Continuar e deletar os dados</p>
          <ButtonsDiv>
            <button
              className="sim_button"
              onClick={() => {
                deleteSubfuncao();
                closeModal();
              }}
            >
              Sim
            </button>
            <button
              className="nao_button"
              onClick={() => {
                closeModal();
                setDataDelete({});
              }}
            >
              Não
            </button>
          </ButtonsDiv>
        </ModalStyled>
      </div>
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loadingLista ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loadingLista ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Código</TituloTable>
              <TituloTable>Função</TituloTable>
              <TituloTable>Informação</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.valor}</td>
                    <td title={item.nome} style={{ whiteSpace: "nowrap" }}>
                      {item.nome.length > 35
                        ? item.nome.substring(0, 35) + "..."
                        : item.nome}
                    </td>
                    <td title={item.descricao} style={{ whiteSpace: "nowrap" }}>
                      {item.descricao.length > 60
                        ? item.descricao.substring(0, 60) + "..."
                        : item.descricao}
                    </td>
                    <td>
                      <ButtonsAcoesListas
                        IconDelete={false}
                        CaminhoPagUpdate={`/planejamento/cadastro/funcao-subfuncao/visualizar/${item.valor}`}
                      />
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.valor}</td>
                    <td title={item.nome} style={{ whiteSpace: "nowrap" }}>
                      {item.nome.length > 50
                        ? item.nome.substring(0, 50) + "..."
                        : item.nome}
                    </td>
                    <td title={item.descricao} style={{ whiteSpace: "nowrap" }}>
                      {item.descricao.length > 65
                        ? item.descricao.substring(0, 65) + "..."
                        : item.descricao}
                    </td>
                    <td>
                      <ButtonsAcoesListas
                        IconDelete={false}
                        CaminhoPagUpdate={`/planejamento/cadastro/funcao-subfuncao/visualizar/${item.valor}`}
                      />
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </div>
  );
};

export default SubFuncaoListaPlanejamento;
