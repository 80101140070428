import React, { useContext } from "react";

import * as RS from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/stylesVisualizacaoRelatorio";

import { CurrentClientContext } from "../../../../../contexts/CurrentClientContext";
import { AuthContext } from "../../../../../contexts/AuthContext";

import { RodapeRelatorio } from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/RodapeRelatorio";
import { InfosRelatorio } from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/InfosRelatorio";
import { ColunasRelatorio } from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/ColunasRelatorio";
import { HeaderRelatorio } from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/HeaderRelatorio";

const VisualizacaoRelatorioTipoPrograma = ({
  dadosRelatorio,
  numeroDaPagina,
  totalDePaginas,
  dataClientInfos
}) => {
  const { currentClient } = useContext(CurrentClientContext);
  const { userAuthData } = useContext(AuthContext);

  if (!dadosRelatorio) return null;

  const colNamesTipoPrograma = ["Código", "Descrição", "Indicador"];
  const styledCol = [
    { flexBasis: "125px", flexShrink: 0 },
    { flexBasis: "100%", flexShrink: 1 },
    { flexBasis: "125px", flexShrink: 0 },
  ];
  return (
    <RS.ContainerRelatorio>
      <RS.PageHeader>
        <HeaderRelatorio
          currentClient={currentClient}
          relatorioName={"Tipo de Programa"}
          dataClientInfos={dataClientInfos}
        />
      </RS.PageHeader>

      <RS.DivExterna>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <td>
                <RS.PageHeaderSpace />
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <RS.DivRelatorio>
                  <RS.Colunas>
                    {colNamesTipoPrograma.map((item, index) => (
                      <ColunasRelatorio
                        key={index}
                        colName={item}
                        styledCol={styledCol[index]}
                      />
                    ))}
                  </RS.Colunas>
                  <RS.InfosColunas>
                    {dadosRelatorio?.map((item) => (
                      <React.Fragment key={item.id}>
                        <RS.Colunas>
                          <InfosRelatorio
                            infoRelatorio={item.codigo}
                            styledCol={styledCol[0]}
                          />
                          <InfosRelatorio
                            infoRelatorio={item.descricao}
                            styledCol={styledCol[1]}
                          />
                          <InfosRelatorio
                            infoRelatorio={item.indicador}
                            styledCol={styledCol[2]}
                          />
                        </RS.Colunas>
                        <RS.LineInfosRelatorio />
                      </React.Fragment>
                    ))}
                    <RS.DivInferiorColuna></RS.DivInferiorColuna>
                  </RS.InfosColunas>
                </RS.DivRelatorio>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <RS.PageFooterSpace />
              </td>
            </tr>
          </tfoot>
        </table>
      </RS.DivExterna>
      <RS.PageFooter>
        <RodapeRelatorio
          currentClient={currentClient}
          userAuthData={userAuthData}
        />
      </RS.PageFooter>
    </RS.ContainerRelatorio>
  );
};

export default VisualizacaoRelatorioTipoPrograma;
