import React from "react";
import {
  CCol,
  ContainerToModal,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Row } from "react-bootstrap";
import Geral from "./Geral";

const NaturezaRendimento = () => {
  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Natureza de Rendimentos"}
        PaginaSubtitulo={"Cadastro"}
        BotaoSaveAtivo={false}
        BotaoNovoAtivo={false}
      />
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={2}>
            <FloatingLabelInput label={"Código"} placeholder={"Código"} />
          </CCol>
          <CCol>
            <FloatingLabelInput
              label={"Natureza de Rendimentos"}
              placeholder={"Natureza de Rendimentos"}
            />
          </CCol>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol>
            <FloatingLabelInput
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              tipo="input_select"
              label="Grupo"
              placeholder="Grupo"
              options={[{ label: "Grupo", value: "Grupo" }]}
            />
          </CCol>
        </RowAninhada>
      </Row>
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Geral
          </span>
        </CCol>
      </Row>
      <Geral />
      <div style={{ minHeight: "40px" }}> </div>
    </ContainerToModal>
  );
};

export default NaturezaRendimento;
