import React, { useState, useEffect, useContext } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import api from "../../../../utils/api";
import { toast } from "react-toastify";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";

const Participantes = ({ addParticipante, values }) => {
  const { currentClient } = useContext(CurrentClientContext);

  const [inputAutoCompleteValue, setInputAutoComplete] = useState("");
  const [participantesOptions, setParticipantesOptions] = useState([]);
  const [defValues, setDefValues] = useState({
    participante: "",
    valor: 0,
    situacao: "",
  });

  const addValues = () => {
    console.log(defValues);
    const hasEmptyValues = Object.values(defValues).some(
      (value) => value === "" || value === null || value === undefined
    );

    if (!hasEmptyValues) {
      setInputAutoComplete("");
      addParticipante(defValues);
      setDefValues({
        participante: "",
        valor: 0,
        situacao: "",
      });
    }
  };

  useEffect(() => {
    console.log("Iniciada");
    api
      .get(`cpf/por-cliente/${currentClient.clienteId}`)
      .then((resp) => {
        const data = resp.data;
        console.log(data);

        const options = data.map((secretario) => ({
          cpf: secretario.cpf,
          pessoaId: secretario.pessoaId,
          nome: secretario.nomeCompleto,
        }));

        setParticipantesOptions(options);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu errado ao carregar os dados.");
      });
    //eslint-disable-next-line
  }, [currentClient]);

  const handleOptionSelect = (option) => {
    if (option) {
      setDefValues((prevValues) => ({
        ...prevValues,
        participante: option.pessoaId,
      }));
    }
  };

  return (
    <>
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol>
            <PTitulosInputs>Participante</PTitulosInputs>
            <MyAutoComplete
              inputValue={inputAutoCompleteValue}
              setInputValue={setInputAutoComplete}
              options={participantesOptions}
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              labelFormat={(option) =>
                `${option.nome} - ${FormatCpfPaste(option.cpf)}`
              }
              onOptionSelect={handleOptionSelect}
              placeholder="Participante"
            />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Valor</PTitulosInputs>
            <FormControlListaDeInputs
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              value={defValues.valor}
              onChange={(e) => {
                let input = e.target.value;
                input = input.replace(/[^0-9,]/g, "");
                if (input.includes(",")) {
                  const [inteiro, centavos] = input.split(",");
                  input = `${inteiro},${centavos.slice(0, 2)}`;
                }
                const partes = input.split(",");
                let numero = partes[0];
                numero = numero.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                input = partes.length > 1 ? `${numero},${partes[1]}` : numero;
                setDefValues((prevValues) => ({
                  ...prevValues,
                  valor: input,
                }));
              }}
            />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Situação</PTitulosInputs>
            <FormControlListaDeInputs
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              value={defValues.situacao}
              onChange={(e) =>
                setDefValues((prevValues) => ({
                  ...prevValues,
                  situacao: e.target.value,
                }))
              }
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <IconAdicionar onClick={addValues} />
          </CColButtonsAcoes2Icons>
        </RowAninhada>

        {/* - Dados - */}
        {values.map((item, index) => {
          const border = index === values.length - 1 ? "0 0 5px 5px" : "0";
          return (
            <RowAninhada key={index}>
              <CCol>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={
                    participantesOptions.find(
                      (option) => option.pessoaId === item.participante
                    )
                      ? `${
                          participantesOptions.find(
                            (option) => option.pessoaId === item.participante
                          ).nome
                        } - ${FormatCpfPaste(
                          participantesOptions.find(
                            (option) => option.pessoaId === item.participante
                          ).cpf
                        )}`
                      : ""
                  }
                />
              </CCol>
              <CCol md={2}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={item.valor}
                />
              </CCol>
              <CCol md={2}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={item.situacao}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                {/* Ícone para ações futuras, se necessário */}
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          );
        })}
      </Row>
    </>
  );
};

export default Participantes;
