import React, { useState } from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import styled from "styled-components";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import HistoricoAba from "./HistoricoAba";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const AnulacaoExtra = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Anulação Extra"}
        PaginaSubtitulo={"Cadastro"}
        RowTitle={"Anulações Extraorçamentária"}
      />

      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput disabled label="Número" placeholder="Número" />
        </CColNumero>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Indicador de Lançamento"
          />
        </CCol>
        <CCol md={2}>
          <InputData />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Guia Anulada"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={2}>
          <InputFormatRealBrasileiro iconReal />
        </CCol>
      </RowForm>
      <RowSelecaoAbasInferior
        abas={["Histórico", "Fonte de Recursos"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Histórico" && <HistoricoAba />}
    </Container>
  );
};

export default AnulacaoExtra;
