import styled from "styled-components";

export const DivFontesDeRecursos = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 1px;
  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 2px; /* Largura da barra de rolagem */
    height: 5px;
  }

  /* Estilizando o track (trilha) da barra de rolagem */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Cor de fundo do track */
  }

  /* Estilizando o thumb (ponteiro) da barra de rolagem */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Cor do thumb */
    border-radius: 10px;
  }

  /* Estilizando o thumb ao passar o mouse por cima */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Cor do thumb ao passar o mouse por cima */
  }
`;
