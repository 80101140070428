import React, { useState, useEffect, useRef, useContext } from "react";
import {
  CCol,
  CColButtonsAcoes,
  RowToModal,
} from "../../../../components/Grid/CCol";
import { Icon } from "@iconify/react";
import { useParams } from "react-router-dom";
import {
  RowItemListaDeInputs,
  PTitulosInputs,
  FormControlListaDeInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import { toast } from "react-toastify";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import InputData from "../../../../components/Grid/InputData";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { FormatDateToNA } from "../../../../utils/FormatacaoDeDados/FormatDateToNA";
import { FormatDateToBR } from "../../../../utils/FormatacaoDeDados/FormatDateToBR";
import api from "../../../../utils/api";
// import ModalCPFCadastro from "../../../../components/Modais/ModalCPFCadastro";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { OpenedModalsContext } from "../../../../contexts/OpenedModalsContext";
import ModalAutoComplete from "../../../../components/Modais/ModalAutoComplete";
import CadastroCpfCnpjPlanejamento from "../../Cadastros/CpfCnpjPlanejamento/CadastroCpfCnpjPlanejamento";

function Secretario({
  setValues,
  values,
  valuesSecretarioAdicionar,
  setValuesSecretarioAdicionar,
  currentClient,
  isAModalPage,
}) {
  function closeModal() {
    setModalOpened(false);
  }

  const inputDataRef = useRef();
  const { setOpenedModals } = useContext(OpenedModalsContext);

  // const [openedModalCpf, setOpenedModalCpf] = useState(false);
  // const [requisicaoDoModalConcluida, setRequisicaoDoModalConcluida] =
  //   useState(false);

  const [modalOpened, setModalOpened] = useState(false);

  const [options, setOptions] = useState([]);
  const [inputAutoCompleteValue, setInputAutoComplete] = useState("");

  const { codigo: paramsUnidadeId } = useParams();

  useEffect(() => {
    console.log("Iniciada");
    api
      .get(`cpf/por-cliente/${currentClient.clienteId}`)
      .then((resp) => {
        console.log("concluida");

        const data = resp.data;
        console.log(data);
        // const length = data.length - 1;

        const options = data.map((secretario) => ({
          cpf: secretario.cpf,
          pessoaId: secretario.pessoaId,
          nome: secretario.nomeCompleto,
        }));

        setOptions(options);

        console.log(options);

        // if (requisicaoDoModalConcluida && data.length > 0) {
        //   setInputAutoComplete(
        //     `${data[length].nomeCompleto} - ${FormatCpfPaste(data[length].cpf)}`
        //   );

        //   setValuesSecretarioAdicionar({
        //     nome: data[length].nomeCompleto,
        //     pessoaId: data[length].pessoaId,
        //   });
        // }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu errado ao carregar os dados.");
      });
    //eslint-disable-next-line
  }, [currentClient]);

  const limpaValoresSecretarioAdicionar = () => {
    setInputAutoComplete("");
    inputDataRef.current.value = "";
    setValuesSecretarioAdicionar({
      dataContrato: "",
      nome: "",
      pessoaId: "",
      atoNomeacao: "1 - Lei",
    });
  };

  const adicionaSecretario = () => {
    if (
      valuesSecretarioAdicionar.atoNomeacao !== "" &&
      valuesSecretarioAdicionar.dataContrato !== "" &&
      valuesSecretarioAdicionar.dataContrato.length === 10 &&
      valuesSecretarioAdicionar.pessoaId !== "" &&
      valuesSecretarioAdicionar.nome !== ""
    ) {
      const secretarios = values.secretarios.filter(
        (secretario) => secretario.pessoaId
      );
      setValues((prevValues) => ({
        ...prevValues,
        secretarios: [
          {
            pessoaId: valuesSecretarioAdicionar.pessoaId,
            atoNomeacao: valuesSecretarioAdicionar.atoNomeacao,
            dataContrato: valuesSecretarioAdicionar.dataContrato,
          },
          ...secretarios,
        ],
      }));
      if (paramsUnidadeId) {
        addSecretarioEmUnidadeGestoraExistente(valuesSecretarioAdicionar);
      }
      limpaValoresSecretarioAdicionar();
    } else {
      toast.warning(
        "Preencha os campos necessarios para associar um secretario a essa unidade."
      );
      console.log(valuesSecretarioAdicionar);
    }
  };

  useEffect(() => {
    console.log(options);
  }, [options]);

  useEffect(() => {
    if (inputAutoCompleteValue.length === 0) {
      setValuesSecretarioAdicionar((prevValues) => ({
        ...prevValues,
        nome: "",
      }));
    }
    //eslint-disable-next-line
  }, [inputAutoCompleteValue]);

  const deletaSecretario = (secretarioSelected) => {
    const secretarios = values.secretarios.filter(
      (secretario) => secretario.pessoaId !== secretarioSelected.pessoaId
    );
    setValues((prevValues) => ({
      ...prevValues,
      secretarios: [...secretarios],
    }));
    if (paramsUnidadeId) {
      deleteSecretarioEmUnidadeGestoraExistente(
        paramsUnidadeId,
        secretarioSelected
      );
    }
  };

  const handleOptionSelect = (option) => {
    console.log(option);

    if (option) {
      console.log("entrei aqui: autocomplete");
      console.log("valor do cpf do user: " + option.cpf);

      console.log(option);

      setValuesSecretarioAdicionar((prevValues) => ({
        ...prevValues,
        nome: option.nome,
        pessoaId: option.pessoaId,
      }));
    }
  };

  // const cadastraNovoCpf = () => {
  //   setOpenedModalCpf(true);
  // };

  const addSecretarioEmUnidadeGestoraExistente = (secretario) => {
    const newSecretario = {
      dataContrato: secretario.dataContrato,
      secretario: {
        pessoaId: secretario.pessoaId,
        atoNomeacao: secretario.atoNomeacao,
      },
    };
    console.log(newSecretario);
    api
      .post(`/unidadeOrcamentaria/${paramsUnidadeId}/secretario`, newSecretario)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        toast.error(
          "Algo deu errado. Por favor, tente mais tarde ou entre em contato com o suporte."
        );
      });
  };

  const deleteSecretarioEmUnidadeGestoraExistente = (
    unidadeId,
    secretarioSelected
  ) => {
    const dataFormatada = secretarioSelected.dataContrato.replace(/\//g, "-");
    api
      .delete(
        `/unidadeOrcamentaria/${unidadeId}/secretario/${secretarioSelected.pessoaId}/${dataFormatada}`
      )
      .then((resp) => {
        console.log(resp);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Algo deu errado. Por favor, tente mais tarde ou entre em contato com o suporte."
        );
      });
  };

  const handleOpenNewModal = () => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: "CPF/CNPJ", requisicaoDoModalConcluida: false },
    ]);
  };

  return (
    <div>
      {/* <ModalCPFCadastro
        openedModal={openedModalCpf}
        setOpenedModal={setOpenedModalCpf}
        setRequisicaoDoModalConcluida={setRequisicaoDoModalConcluida}
        requisicaoDoModalConcluida={requisicaoDoModalConcluida}
      /> */}
      <ModalAutoComplete
        tituloModal={"CPF/CNPJ"}
        Component={<CadastroCpfCnpjPlanejamento isAModalPage={true} />}
      />
      <ModalDeleteData modalOpened={modalOpened} closeModal={closeModal} />
      <RowToModal isAModalPage={isAModalPage}>
        <CCol
          className="d-flex align-items-center p-2 row_endereco_conteudo"
          md={12}
        >
          <span className="mx-4">Secretário</span>
        </CCol>
      </RowToModal>
      {/* <button
        onClick={() => {
          console.log(values);
          console.log(options);
          inputDataRef.current.value = "";
        }}
      >
        console values
      </button> */}
      <RowToModal isAModalPage={isAModalPage} className="mt-1 row_form_2">
        <CCol md={2}>
          <PTitulosInputs>Data</PTitulosInputs>

          <InputData
            ref={inputDataRef}
            externalValue={valuesSecretarioAdicionar.dataContrato}
            radiusborder={
              values.secretarios[0]?.pessoaId ? "5px 5px 0px 0px" : null
            }
            maxlength="10"
            onChange={(e) => {
              setValuesSecretarioAdicionar((prevValues) => ({
                ...prevValues,
                dataContrato: FormatDateToBR(e.target.value),
              }));
            }}
            height={"30px"}
          />
        </CCol>
        <CCol>
          <PTitulosInputs>Nome</PTitulosInputs>
          {/* <button
            onClick={() => {
              console.log(inputAutoCompleteValue);
              setInputAutoComplete("");
            }}
          >
            teste
          </button> */}
          <MyAutoComplete
            inputValue={inputAutoCompleteValue}
            setInputValue={setInputAutoComplete}
            options={options}
            radiusborder={
              values.secretarios[0]?.pessoaId > 0 ? "5px 5px 0px 0px" : null
            }
            labelFormat={(option) =>
              `${option.nome} - ${FormatCpfPaste(option.cpf)}`
            }
            onOptionSelect={handleOptionSelect}
            addNewDataFunction={handleOpenNewModal}
            placeholder="Nome do(a) Secretário(a)"
          />
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Ato de Nomeação</PTitulosInputs>
          <div className="input_icon_arrow_dir_formListaDeInputs">
            <FloatingLabelInput
              placeholder={"1 - Lei"}
              height={"30px"}
              tipo="input_select"
              new={true}
              radiusborder={
                values.secretarios[0]?.pessoaId > 0 ? "5px 5px 0px 0px" : null
              }
              options={[
                { label: "1 - Lei", value: "1 - Lei" },
                { label: "2 - Decreto", value: "2 - Decreto" },
                { label: "3 - Portaria", value: "3 - Portaria" },
                { label: "4 - Outros", value: "4 - Outros" },
              ]}
              onSelect={(option) => {
                setValuesSecretarioAdicionar((prevValues) => ({
                  ...prevValues,
                  atoNomeacao: option.value,
                }));
              }}
            />
          </div>
        </CCol>
        <CColButtonsAcoes md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <Icon
            onClick={adicionaSecretario}
            icon="ic:baseline-add-box"
            color="#105200"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              marginTop: "1px",
            }}
          />
        </CColButtonsAcoes>
        {/* -------------------------------------------------------------- */}
        {/* <RowItemListaDeInputs>
            <CCol md={12}>
              <PTituloLista>Diretrizes do Eixo</PTituloLista>
            </CCol>
          </RowItemListaDeInputs> */}
        {/* <button
          onClick={() => console.log(FormatDateToNA(values.dataContrato))}
        >
          console date
        </button> */}
        {!!values.secretarios.length &&
          values.secretarios[0].pessoaId &&
          options.length > 0 &&
          values.secretarios.map((secretario) => (
            <RowItemListaDeInputs key={secretario.pessoaId}>
              <CCol md={2}>
                <InputData
                  radiusborder="0px 0px 5px 5px"
                  externalValue={FormatDateToNA(secretario.dataContrato)}
                  disabled
                  height={"30px"}
                />
              </CCol>

              <CCol>
                <FormControlListaDeInputs
                  radiusborder="0px 0px 5px 5px"
                  value={
                    options.find(
                      (item) => item.pessoaId === secretario.pessoaId
                    ) &&
                    `${
                      options.find(
                        (item) => item.pessoaId === secretario.pessoaId
                      ).nome
                    } - ${FormatCpfPaste(
                      options.find(
                        (item) => item.pessoaId === secretario.pessoaId
                      ).cpf
                    )}`
                  }
                  disabled
                />
              </CCol>

              <CCol md={2}>
                <div className="input_icon_arrow_dir_$formlistadeinputs">
                  <FormControlListaDeInputs
                    radiusborder="0px 0px 5px 5px"
                    disabled
                    value={secretario.atoNomeacao}
                  />
                </div>
              </CCol>
              <CColButtonsAcoes md={1}>
                <Icon
                  onClick={() => {
                    deletaSecretario(secretario);
                  }}
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes>
            </RowItemListaDeInputs>
          ))}
        {/* {values.secretario.pessoaId && options.length > 0 && (
          <RowItemListaDeInputs>
            <CCol md={2}>
              <InputData
                radiusborder="0px 0px 5px 5px"
                externalValue={FormatDateToNA(values.dataContrato)}
                disabled
                height={"30px"}
              />
            </CCol>

            <CCol>
              <FormControlListaDeInputs
                radiusborder="0px 0px 5px 5px"
                value={
                  options.find(
                    (item) => item.pessoaId === values.secretario.pessoaId
                  ) &&
                  `${
                    options.find(
                      (item) => item.pessoaId === values.secretario.pessoaId
                    ).nome
                  } - ${FormatCpfPaste(
                    options.find(
                      (item) => item.pessoaId === values.secretario.pessoaId
                    ).cpf
                  )}`
                }
                disabled
              />
            </CCol>

            <CCol md={2}>
              <div className="input_icon_arrow_dir_$formlistadeinputs">
                <FormControlListaDeInputs
                  radiusborder="0px 0px 5px 5px"
                  disabled
                  value={values.secretario.atoNomeacao}
                />
              </div>
            </CCol>
            <CColButtonsAcoes md={1}>
              <Icon
                onClick={() => {
                  deletaSecretario();
                }}
                icon="ic:baseline-delete"
                color="#E79900"
                height="28"
                style={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginTop: "1px",
                }}
              />
            </CColButtonsAcoes>
          </RowItemListaDeInputs>
        )} */}
      </RowToModal>
    </div>
  );
}

export default Secretario;
