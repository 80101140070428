import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { useFetchData } from "../../../../hooks/useFetchData";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol } from "../../../../components/Grid/CCol";
import Dashboard from "../../../../components/ComponentesDeDashboard/Dashboard";

export const DashboardUnidadeOrcamentaria = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { data: quantidades } = useFetchData(
    `/unidadeOrcamentaria/total/cliente/${currentClient.clienteId}`
  );
  const [boxes, setBoxes] = useState({ box1: "0" });
  const navigate = useNavigate();
  const options = [
    { label: "Quantidade", value: null },
    { label: "Quantidade", value: 1 },
  ];

  const getQuantidades = (value) => {
    if (value) {
      return value < 10 ? "0" + value : value;
    }
  };

  useEffect(() => {
    if (quantidades) {
      setBoxes((prevValues) => ({
        ...prevValues,
        box1: {
          boxTitle: "Quantidade",
          boxSubtitle: "Unidades Orçamentarias",
          boxValue: getQuantidades(quantidades.totalUnidades),
        },
      }));
    }
  }, [quantidades]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Unidade Orçamentária"}
        PaginaSubtitulo={"Dashboard"}
        PaginaConsulta={"/planejamento/cadastro/unidade-orcamentaria/consulta"}
        PaginaRelatorio={"/planejamento/relatorio/unidade-orcamentaria"}
        BotaoNovoFunction={() =>
          navigate("/planejamento/cadastro/unidade-orcamentaria")
        }
        BotaoSaveAtivo={false}
      />
      <Row className="row_form_2 mt-1" style={{ padding: "20px 10px" }}>
        <CCol>
          <Dashboard box1={boxes.box1} options={options} />
        </CCol>
        <CCol>
          <Dashboard />
        </CCol>
      </Row>
      <div className="p-3"></div>
    </Container>
  );
};

export default DashboardUnidadeOrcamentaria;
