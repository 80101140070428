import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";


export const RenderPdfDespesaDemonstrativoRegionalizado = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: 150, flexShrink: 1, flexGrow: 1,textAlign: "center" },
    {
      flexBasis: 150,
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: 150,
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "70%",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: 180,
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const renderHeader = () => {
    return (
      <>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View style={[stylesPdf.divTituloColuna]}>
            <Text style={stylesPdf.textTituloColuna}>tributo</Text>
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[1]]}>
          <View style={[stylesPdf.divTituloColuna]}>
            <Text style={stylesPdf.textTituloColuna}>modalidade</Text>
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[2]]}>
          <View style={[stylesPdf.divTituloColuna]}>
            <Text style={stylesPdf.textTituloColuna}>
              SETORES/{'\n'}PROGRAMAS/{'\n'}BENEFICIÁRIO
            </Text>
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[3]]}>
          <View style={[stylesPdf.divTituloColuna]}>
            <View>
              <Text
                style={[
                  stylesPdf.textTituloColuna,
                  { borderBottomColor: "#FFF", borderBottomWidth: 1 },
                ]}
              >
                renuncia da receita prevista
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingHorizontal: 2,
                }}
              >
                <Text style={stylesPdf.textTituloColuna}>2024</Text>
                <Text style={stylesPdf.textTituloColuna}>2025</Text>
                <Text style={stylesPdf.textTituloColuna}>2026</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[4]]}>
          <View style={[stylesPdf.divTituloColuna]}>
            <Text style={stylesPdf.textTituloColuna}>compensação</Text>
          </View>
        </View>
      </>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={
          "Demonstrativo Regionalizado do Efeito sobre Receitas e Despesa"
        }
        anoRelatorio={currentYear}
        plusText={"vazio"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>LRF art. 4°, § 2°, inciso V</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas, { textTransform: "uppercase", backgroundColor: '#515c70' }]} fixed>
        {renderHeader()}
      </View>
      <View style={stylesPdf.divRelatorio}>
        {/* <View wrap={false} style={[stylesPdf.divInferiorColuna, {marginHorizontal: .2}]} /> */}
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
