import React, { useEffect, useState } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowToModal,
} from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { Icon } from "@iconify/react/dist/iconify.js";
import { toast } from "react-toastify";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

const FonteDeRecursosAba = ({
  setFontesDeRecursos,
  fontesDeRecursos,
  filteredOptionsFonteRec,
  valorTotalReceita,
  contaBancariaId,
  naturezaId,
  fonteDeRecursoAdicionar,
  setFonteDeRecursoAdicionar,
  inputFonteRec,
  setInputFonteRec,
}) => {
  const [valorTotalFontes, setValorTotalFontes] = useState({
    valor: 0,
  });

  const handleFonteSelect = (item) => {
    setFonteDeRecursoAdicionar((prev) => ({
      ...prev,
      fonteId: item.id,
    }));
  };

  const addFonteDeRecursos = () => {
    console.log(fonteDeRecursoAdicionar);
    if (
      Object.values(fonteDeRecursoAdicionar).every((item) => item !== 0) &&
      parseFloat(fonteDeRecursoAdicionar.valor) <= valorTotalReceita &&
      valorTotalFontes.valor + parseFloat(fonteDeRecursoAdicionar.valor) <=
        valorTotalReceita &&
      !fontesDeRecursos.some(
        (item) => item.id === fonteDeRecursoAdicionar.fonteId
      )
    ) {
      console.log("AAAAAAAAAAAAAAAAAAAAAAA");
      setFontesDeRecursos((prev) => [
        ...prev,
        {
          clienteId: fonteDeRecursoAdicionar.clienteId,
          fonteId: fonteDeRecursoAdicionar.fonteId,
          valor: fonteDeRecursoAdicionar.valor.toString(),
        },
      ]);
      setFonteDeRecursoAdicionar((prev) => ({
        ...prev,
        fonteId: 0,
        valor: "0,00",
      }));
      setInputFonteRec("");
    } else if (
      fontesDeRecursos.some(
        (item) => item.id === fonteDeRecursoAdicionar.fonteId
      )
    ) {
      toast.error("Essa fonte de recursos ja foi adicionada a lista.");
    } else if (
      !(parseFloat(fonteDeRecursoAdicionar.valor) <= valorTotalReceita) ||
      valorTotalFontes.valor + parseFloat(fonteDeRecursoAdicionar.valor) >
        valorTotalReceita
    ) {
      toast.error(
        "O valor das fontes não pode ultrapassar o valor total da receita."
      );
    } else {
      toast.error(
        "Preencha os campos corretamente para adicionar uma fonte de recurso a lista."
      );
    }
  };

  useEffect(() => {
    if (inputFonteRec.length === 0) {
      setFonteDeRecursoAdicionar((prev) => ({
        ...prev,
        fonteId: 0,
      }));
    }

    //eslint-disable-next-line
  }, [inputFonteRec]);

  const calculaValorTotalFontes = () => {
    const valorTotal = fontesDeRecursos.reduce(
      (acc, fonte) => acc + parseFloat(fonte.valor.replace(/,/g, ".")),
      0
    );

    setValorTotalFontes((prev) => ({
      ...prev,
      valor: valorTotal,
    }));
  };

  useEffect(() => {
    calculaValorTotalFontes();

    //eslint-disable-next-line
  }, [fontesDeRecursos]);

  const handleChangeValor = (_, valor) => {
    const valorFloat = parseFloat(valor.replace(",", "."));

    setFonteDeRecursoAdicionar((prev) => ({
      ...prev,
      valor: valorFloat,
    }));
  };

  const deleteFonteDeRecursos = (index) => {
    setFontesDeRecursos((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <>
      <RowToModal
        className={"row_form mt-3"}
        style={{
          borderRadius: "5px 5px 0px 0px",
          padding: "8px 10px",
        }}
      >
        <CCol
          style={{
            fontSize: "16px",
            color: "#515c70",
            fontWeight: "700",
          }}
        >
          Fonte de Recursos / CO
        </CCol>
      </RowToModal>
      <RowToModal className={"row_form_2 mt-1"}>
        <CCol>
          <PTitulosInputs>Fonte de Recursos + CO</PTitulosInputs>
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Valor</PTitulosInputs>
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
        </CColButtonsAcoes2Icons>

        <RowAninhada>
          <CCol>
            <MyAutoComplete
              radiusborder={
                fontesDeRecursos.length > 0 ? "5px 5px 0px 0px" : "5px"
              }
              disabled={!(contaBancariaId && naturezaId)}
              inputValue={inputFonteRec}
              setInputValue={setInputFonteRec}
              options={filteredOptionsFonteRec || []}
              labelFormat={(option) => `${option.conta} - ${option.titulo}`}
              onOptionSelect={handleFonteSelect}
            />
          </CCol>
          <CCol md={2}>
            <InputFormatRealBrasileiro
              radiusborder={
                fontesDeRecursos.length > 0 ? "5px 5px 0px 0px" : "5px"
              }
              onChange={handleChangeValor}
              listaDeInputs={true}
              externalValue={fonteDeRecursoAdicionar.valor}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <Icon
              icon="ic:baseline-add-box"
              color="#105200"
              height="28"
              onClick={addFonteDeRecursos}
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "1px",
              }}
            />
          </CColButtonsAcoes2Icons>
          {fontesDeRecursos.map((item, index, array) => (
            <RowAninhada key={index} style={{ marginTop: "-1px" }}>
              <CCol>
                <FormControlListaDeInputs
                  value={`${
                    filteredOptionsFonteRec.find(
                      (obj) => obj.id === item.fonteId
                    ).conta
                  } - ${
                    filteredOptionsFonteRec.find(
                      (obj) => obj.id === item.fonteId
                    ).titulo
                  }`}
                  disabled
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                />
              </CCol>

              <CCol md={2}>
                <FormControlListaDeInputs
                  disabled
                  style={{ textAlign: "end" }}
                  value={FormatValueToLocaleString(
                    item.valor.replace(/,/g, ".")
                  )}
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                <Icon
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  onClick={() => deleteFonteDeRecursos(index)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          ))}
        </RowAninhada>

        <RowAninhada style={{ marginTop: "8px" }}>
          <CCol
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "600", color: "#515c70" }}>
              Valor Total
            </span>
          </CCol>

          <CCol md={2}>
            <FormControlListaDeInputs
              value={FormatValueToLocaleString(valorTotalFontes.valor)}
              style={{
                borderRadius: "10px",
                padding: "15px",
                border: "none",
                textAlign: "end",
              }}
              disabled
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
        </RowAninhada>
      </RowToModal>
    </>
  );
};

export default FonteDeRecursosAba;
