import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { CCol } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import {
  EstruturaTitulos,
  EstruturaTitulosDescricao,
} from "../../../PagesAcessoInterno/Cadastros/Funcao_Subfuncao/CadastroFuncao";
import { useParams } from "react-router-dom";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { ToastContainer, toast } from "react-toastify";
import api from "../../../../utils/api";

const VizualizarFuncaoSubfuncao = () => {
  const [values, setValues] = useState({
    codigo: "",
    titulo: "",
    descricao: "",
  });

  const { valor: paramsValor } = useParams();

  const fetchData = () => {
    let urlPath = ["funcao", "subfuncao"];
    let posicaoUrl = 0;

    switch (paramsValor.length) {
      case 3:
        posicaoUrl = 1;
        break;
      case 2:
        posicaoUrl = 0;
        break;
      default:
        posicaoUrl = 0;
        break;
    }

    api
      .get(`${urlPath[posicaoUrl]}/porvalor/${paramsValor}`)
      .then((resp) => {
        console.log(resp.data);
        setValues((prevValues) => ({
          ...prevValues,
          codigo: resp.data.valor,
          titulo: resp.data.nome,
          descricao: resp.data.descricao,
        }));
      })
      .catch((error) => {
        toast.error(
          "Algo deu errado ao buscar os dados. Por favor, tente mais tarde."
        );
      });
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, []);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <ToastContainer />
      <HeaderCadastros
        BotaoSaveAtivo={false}
        BotaoNovoAtivo={false}
        NomePaginaTitulo={paramsValor?.length === 2 ? "Função" : "Subfunção"}
        PaginaSubtitulo={"Vizualizar"}
        PaginaConsulta={"/planejamento/cadastro/funcao-subfuncao/consulta"}
        PaginaRelatorio={"/planejamento/cadastro/funcao-subfuncao/relatorio"}
        DashboardPage={"/planejamento/cadastro/funcao-subfuncao/dashboard"}
      />
      <Row className="row_form mt-1">
        <CCol md={2}>
          <FloatingLabelInput
            type="text"
            disabled={true}
            value={values.codigo}
            placeholder="Código"
            label="Código"
          />
        </CCol>
        <CCol md={10}>
          <FloatingLabelInput
            type="text"
            placeholder="Título"
            disabled={true}
            value={values.titulo}
            label="Título"
          />
        </CCol>
        <div className="pt-3"></div>
        <CCol md={12}>
          <FloatingLabelInput
            as="textarea"
            tipo="text_area"
            style={{ height: "38px", minHeight: "38px" }}
            placeholder="Descrição"
            disabled={true}
            value={values.descricao}
            label="Descrição"
          />
        </CCol>
      </Row>
      <Row className="row_endereco_aba mt-1">
        <CCol
          className="d-flex align-items-center"
          style={{ height: "40px" }}
          md={12}
        >
          <span style={{ marginBottom: "-2px" }} className="span_endereco">
            Estrutura
          </span>
        </CCol>
      </Row>

      <Row>
        <Col
          className="d-flex align-items-center p-2 row_endereco_conteudo"
          md={12}
        >
          <span style={{ marginLeft: "20px" }}>Estrutura</span>
        </Col>
      </Row>

      <Row className=" d-flex row_form_2 mt-1" style={{ padding: "10px 20px" }}>
        <Row
          style={{
            borderBottom: "1px solid #dfdfe0",
            margin: "0",
            padding: "0",
          }}
        >
          <CCol md={2}>
            <EstruturaTitulos>Código</EstruturaTitulos>
          </CCol>
          <CCol>
            <EstruturaTitulos>Título</EstruturaTitulos>
          </CCol>
        </Row>
        <Row
          style={{
            margin: "0",
            marginTop: "5px",
            padding: "0",
          }}
        >
          <CCol md={2}>
            <EstruturaTitulosDescricao>
              {values.codigo || "00"}
            </EstruturaTitulosDescricao>
          </CCol>
          <CCol>
            <EstruturaTitulosDescricao>
              {values.titulo || "Título"}
            </EstruturaTitulosDescricao>
          </CCol>
        </Row>
      </Row>
    </Container>
  );
};

export default VizualizarFuncaoSubfuncao;
