import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../../components/Grid/InputSvg";
import { ToastContainer } from "react-toastify";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

import api from "../../../../utils/api";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";

function QDDConsultaContabilidade() {
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();

  const [dataQDD, setDataQDD] = useState(null);
  const [loading, setLoading] = useState(true);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itensFiltrados, setItensFiltrados] = useState([]);
  const [itens, setItens] = useState([]);

  axios.defaults.headers.common["Authorization"] = null;

  const reverseMask = (value) => {
    let noDots = value.replace(/\./g, "");
    return noDots;
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = dataQDD.filter((item) => {
      return (
        item.despesaFixadaQDD.fichasQDD.numeroFicha
          .toString()
          .includes(value) ||
        item.acaoGovernamentalInfo.nAcao.includes(value) ||
        item.acaoGovernamentalInfo.titulo
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.nome
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        reverseMask(
          item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.codigo
        ).includes(value) ||
        item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.codigo.includes(
          value
        ) ||
        item.despesaFixadaQDD.fonteDeRecurso.titulo
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    });
    setItensFiltrados(filteredItems);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  const fetchDataQDD = () => {
    api
      .get(`/qdd/${currentClient.clienteId}/${currentYear}`)
      .then((response) => {
        console.log("RESPONSE: ", response.data);
        setItens(response.data);
        setDataQDD(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDataQDD();
    //eslint-disable-next-line
  }, [currentClient.clienteId]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <ToastContainer />
      <HeaderOptions
        withRow={true}
        TituloPagina={"QDD - Quadro Detalhado da Despesa"}
        SubTituloPagina={"Consulta"}
        ToPag={"/contabilidade/cadastros/qdd-despesa"}
      />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        {currentItens.length > 0 && (
          <Table responsive style={{ marginTop: "-10px" }}>
            <thead>
              <tr>
                <TituloTable>Ficha</TituloTable>
                <TituloTable>Ação</TituloTable>
                <TituloTable>Elemento de Despesa</TituloTable>
                <TituloTable>Fonte de Recursos</TituloTable>
                <TituloTable>Valor R$</TituloTable>
                <TituloTableAction>Ação</TituloTableAction>
              </tr>
            </thead>
            <BodyTable>
              {searchTerm
                ? currentFilteredItens.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          verticalAlign: "middle",
                        }}
                      >
                        {item.despesaFixadaQDD.fichasQDD.numeroFicha}
                      </td>
                      <td
                        width={"20%"}
                        style={{
                          whiteSpace: "nowrap",
                          verticalAlign: "middle",
                        }}
                        title={`${item.acaoGovernamentalInfo.nAcao} - ${item.acaoGovernamentalInfo.titulo}`}
                      >
                        {`${item.acaoGovernamentalInfo.nAcao} - ${
                          item.acaoGovernamentalInfo.titulo.length > 20
                            ? `${item.acaoGovernamentalInfo.titulo.substring(
                                0,
                                20
                              )}...`
                            : item.acaoGovernamentalInfo.titulo
                        }`}
                      </td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          verticalAlign: "middle",
                        }}
                        title={`${item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.codigo.substring(
                          0,
                          9
                        )} - ${
                          item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.nome
                        }`}
                      >
                        {item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa
                          .codigo
                          ? `${item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.codigo.substring(
                              0,
                              9
                            )} - ${
                              item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa
                                .nome.length > 20
                                ? `${item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.nome.substring(
                                    0,
                                    20
                                  )}...`
                                : item.despesaFixadaQDD.fichasQDD
                                    .naturezaDaDespesa.nome
                            }`
                          : ""}
                      </td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          verticalAlign: "middle",
                        }}
                        title={`${item.despesaFixadaQDD.fonteDeRecurso.conta.substring(
                          0,
                          5
                        )} - ${item.despesaFixadaQDD.fonteDeRecurso.titulo}`}
                      >
                        {`${item.despesaFixadaQDD.fonteDeRecurso.conta.substring(
                          0,
                          5
                        )} - ${
                          item.despesaFixadaQDD.fonteDeRecurso.titulo.length >
                          20
                            ? `${item.despesaFixadaQDD.fonteDeRecurso.titulo.substring(
                                0,
                                20
                              )}...`
                            : item.despesaFixadaQDD.fonteDeRecurso.titulo
                        }`}
                      </td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          verticalAlign: "middle",
                        }}
                      >
                        {`R$ ${FormatValueToLocaleString(
                          parseFloat(item.despesaFixadaQDD.fichasQDD.valor)
                        )}`}
                      </td>
                      <td /*style={{ verticalAlign: "middle" }}*/>
                        <ButtonsAcoesListas
                          //   CaminhoPagUpdate={`/planejamento/loa/qdd/visualizar/${item.qddId}`}
                          CaminhoPagUpdate={`/contabilidade/cadastros/qdd-despesa/visualizar/${item.id}`}
                        />
                      </td>
                    </tr>
                  ))
                : currentItens.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          verticalAlign: "middle",
                        }}
                        title={item.despesaFixadaQDD.fichasQDD.numeroFicha}
                      >
                        {item.despesaFixadaQDD.fichasQDD.numeroFicha}
                      </td>
                      <td
                        width={"20%"}
                        style={{
                          whiteSpace: "nowrap",
                          verticalAlign: "middle",
                        }}
                        title={`${item.acaoGovernamentalInfo.nAcao} - ${item.acaoGovernamentalInfo.titulo}`}
                      >
                        {`${item.acaoGovernamentalInfo.nAcao} - ${
                          item.acaoGovernamentalInfo.titulo.length > 20
                            ? `${item.acaoGovernamentalInfo.titulo.substring(
                                0,
                                20
                              )}...`
                            : item.acaoGovernamentalInfo.titulo
                        }`}
                      </td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          verticalAlign: "middle",
                        }}
                        title={`${item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.codigo.substring(
                          0,
                          9
                        )} - ${
                          item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.nome
                        }`}
                      >
                        {item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa
                          .codigo
                          ? `${item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.codigo.substring(
                              0,
                              9
                            )} - ${
                              item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa
                                .nome.length > 20
                                ? `${item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.nome.substring(
                                    0,
                                    20
                                  )}...`
                                : item.despesaFixadaQDD.fichasQDD
                                    .naturezaDaDespesa.nome
                            }`
                          : ""}
                      </td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          verticalAlign: "middle",
                        }}
                        title={`${item.despesaFixadaQDD.fonteDeRecurso.conta.substring(
                          0,
                          5
                        )} - ${item.despesaFixadaQDD.fonteDeRecurso.titulo}`}
                      >
                        {`${item.despesaFixadaQDD.fonteDeRecurso.conta.substring(
                          0,
                          5
                        )} - ${
                          item.despesaFixadaQDD.fonteDeRecurso.titulo.length >
                          20
                            ? `${item.despesaFixadaQDD.fonteDeRecurso.titulo.substring(
                                0,
                                20
                              )}...`
                            : item.despesaFixadaQDD.fonteDeRecurso.titulo
                        }`}
                      </td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          verticalAlign: "middle",
                        }}
                      >
                        {`R$ ${FormatValueToLocaleString(
                          parseFloat(item.despesaFixadaQDD.fichasQDD.valor)
                        )}`}
                      </td>
                      <td>
                        <ButtonsAcoesListas
                          //   CaminhoPagUpdate={`/planejamento/loa/qdd/visualizar/${item.qddId}`}
                          CaminhoPagUpdate={`/contabilidade/cadastros/qdd-despesa/visualizar/${item.id}`}
                        />
                      </td>
                    </tr>
                  ))}
            </BodyTable>
          </Table>
        )}
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : dataQDD ? dataQDD.length : 0}{" "}
            registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default QDDConsultaContabilidade;
