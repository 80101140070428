import React from "react";

import { Document, Page, Text, View } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";

import { calcularTotalUnidade } from "./calcularTotalUnidade";

import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

// const ITEMS_PER_PAGE = 20;

// const paginateData = (data, pageNumber) => {
//   const startIndex = (pageNumber - 1) * ITEMS_PER_PAGE;
//   return data.slice(startIndex, startIndex + ITEMS_PER_PAGE);
// };

export const PdfQDD = ({ pdfContent }) => {
  const {
    dadosRelatorio,
    currentYear,
    userData: { currentClient, userAuthData, dataClientInfos },
    idsSelected,
    selectBoxesOptionsAbaOptions,
  } = pdfContent;

  const {
    idsAcaoGovernamentalSelected,
    idsElementoDespesaSelected,
    idsFonteDeRecursoSelected,
    idsFuncaoSelected,
    idsSubfuncaoSelected,
    idsProgramaSelected,
    idsUnidadeOrcamentariaSelected,
    esferaSelected,
  } = idsSelected;

  const filtrosIds = [
    idsAcaoGovernamentalSelected,
    idsElementoDespesaSelected,
    idsFuncaoSelected,
    idsSubfuncaoSelected,
    idsProgramaSelected,
    idsUnidadeOrcamentariaSelected,
  ];
  console.log(dadosRelatorio);

  console.log(selectBoxesOptionsAbaOptions);

  const showOptionsSelected = (option) => {
    const optionSelected = selectBoxesOptionsAbaOptions.find(
      (item) => item.opcao === "Exibir"
    );

    const hasOptionSelected = optionSelected.checkBoxes.some(
      (item) => item.value === option && item.selected
    );
    return hasOptionSelected;
  };

  const showCodigo = showOptionsSelected("codigo");
  const showTitulo = showOptionsSelected("descricao");
  const showClassificacao = showOptionsSelected("classificacao");
  const showValue = showOptionsSelected("valor");

  console.log(showCodigo);
  console.log(showTitulo);
  console.log(showValue);

  const styledCol = [
    { flexBasis: "125px", flexGrow: 1 },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "90px", flexGrow: 1 },
    { flexBasis: "115px", flexGrow: 1 },
  ];

  // const totalPages = Math.ceil(dadosRelatorio.length / ITEMS_PER_PAGE);

  // const pages = Array.from({ length: totalPages }, (_, index) =>
  //   paginateData(dadosRelatorio, index + 1)
  // );

  const filterData = {
    "Ação Governamental": idsAcaoGovernamentalSelected,
    "Elemento da Despesa": idsElementoDespesaSelected,
    "Fonte de Recursos": idsFonteDeRecursoSelected,
    Função: idsFuncaoSelected,
    Subfunção: idsSubfuncaoSelected,
    Programa: idsProgramaSelected,
    "Unidade Orçamentária": idsUnidadeOrcamentariaSelected,
    Esfera: esferaSelected,
  };

  const nameFilter = Object.keys(filterData)
    .filter((key) => filterData[key].length > 0)
    .join(" - ");

  // const cabecalhoGeral = ()=>{
  //   return (
  //     <View
  //       style={[
  //         stylesPdf.headerInfos,
  //         {  fontWeight: "bold" },
  //       ]}
  //       fixed
  //     >
  //       <View
  //         style={{
  //           flexDirection: "row",
  //           width: '100%',
  //           gap: "16px",
  //           alignItems: "center",
  //           backgroundColor: 'orange',
  //         }}
  //       >
  //         {showCodigo && (
  //           <Text
  //           style={[
  //             stylesPdf.textTituloColuna,
  //             { textAlign: "left", width: "70px", backgroundColor: 'red' },
  //           ]}
  //         >
  //           código
  //         </Text>
  //         )}
  //         {showTitulo && (
  //           <Text
  //           style={[
  //             stylesPdf.textTituloColuna,
  //             {

  //               width: "65%",
  //               textAlign: "left",
  //               backgroundColor: 'blue'
  //             },
  //           ]}
  //         >
  //           descrição
  //         </Text>
  //         )}
  //         {esferaSelected.length > 0 && idsElementoDespesaSelected.length > 0 && (
  //           <Text style={[stylesPdf.textTituloColuna,]}>esfera</Text>
  //         )}
  //       {showValue && (
  //         <Text style={[stylesPdf.textTituloColuna,{backgroundColor: 'green', flex: 1, justifyContent: 'flex-end'}]}>valor R$</Text>
  //       )}
  //       </View>
  //     </View>
  //   );
  // }

  const cabecalhoGeral = () => {
    return (
      <View style={[stylesPdf.colunas]} fixed>
        {showCodigo && (
          <View style={[stylesPdf.coluna, styledCol[0]]}>
            <View style={stylesPdf.divTituloColuna}>
              <Text
                style={[stylesPdf.textTituloColuna, { paddingHorizontal: 4 }]}
              >
                Código
              </Text>
            </View>
          </View>
        )}
        <View style={[stylesPdf.coluna, styledCol[1]]}>
          <View style={stylesPdf.divTituloColuna}>
            <Text
              style={[
                stylesPdf.textTituloColuna,
                {
                  paddingHorizontal: 4,
                  color: showTitulo ? "#FFF" : "#515c70",
                },
              ]}
            >
              {showTitulo ? "Descrição" : "0"}
            </Text>
          </View>
        </View>
        {esferaSelected.length && idsElementoDespesaSelected.length && (
          <View style={[stylesPdf.coluna, styledCol[2]]}>
            <View style={stylesPdf.divTituloColuna}>
              <Text
                style={[stylesPdf.textTituloColuna, { paddingHorizontal: 4 }]}
              >
                Esfera
              </Text>
            </View>
          </View>
        )}
        <View style={[stylesPdf.coluna, styledCol[3]]}>
          <View style={stylesPdf.divTituloColuna}>
            <Text
              style={[
                stylesPdf.textTituloColuna,
                {
                  textAlign: "right",
                  paddingRight: 4,
                  color: showValue ? "#FFF" : "#515c70",
                },
              ]}
            >
              {showValue ? "Valor R$" : "0"}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const cabecalhoFonteDeRecurso = () => {
    return (
      <View
        style={[
          stylesPdf.headerInfos,
          { justifyContent: "space-between", fontWeight: "bold" },
        ]}
        fixed
      >
        <View
          style={{
            flexDirection: "row",
            flex: "1",
            gap: "16px",
            alignItems: "center",
          }}
        >
          {showCodigo && (
            <>
              <Text
                style={[
                  stylesPdf.textTituloColuna,
                  { textAlign: "left", width: "50px" },
                ]}
              >
                exercício
              </Text>
              <Text
                style={[
                  stylesPdf.textTituloColuna,
                  { textAlign: "left", width: "50px" },
                ]}
              >
                fonte de{"\n"}recurso
              </Text>
            </>
          )}
          <Text
            style={[
              stylesPdf.textTituloColuna,
              {
                maxWidth: "250px",
                width: "100%",
                textAlign: "left",
              },
            ]}
          >
            {showTitulo ? "descrição fonte de recursos" : ""}
          </Text>
          {esferaSelected.length && (
            <Text style={[stylesPdf.textTituloColuna]}>esfera</Text>
          )}
        </View>
        {showValue && (
          <Text style={[stylesPdf.textTituloColuna]}>valor R$</Text>
        )}
      </View>
    );
  };

  // const cabecalhoFonteDeRecurso = () => {
  //   return (
  //     // <View style={[stylesPdf.colunas]} fixed>
  //     //   {showCodigo && (
  //     //     <>
  //     //       <View style={[stylesPdf.coluna, styledCol[0]]}>
  //     //       <View style={stylesPdf.divTituloColuna}>
  //     //         <Text
  //     //           style={[stylesPdf.textTituloColuna, { paddingHorizontal: 4 }]}
  //     //         >
  //     //           exercício
  //     //         </Text>
  //     //       </View>
  //     //     </View>
  //     //     <View style={[stylesPdf.coluna, styledCol[0]]}>
  //     //       <View style={stylesPdf.divTituloColuna}>
  //     //         <Text
  //     //           style={[stylesPdf.textTituloColuna, { paddingHorizontal: 4 }]}
  //     //         >
  //     //           fonte de{"\n"}recurso
  //     //         </Text>
  //     //       </View>
  //     //     </View>
  //     //     </>
  //     //   )}
  //     //   <View style={[stylesPdf.coluna, styledCol[1]]}>
  //     //     <View style={stylesPdf.divTituloColuna}>
  //     //       <Text
  //     //         style={[
  //     //           stylesPdf.textTituloColuna,
  //     //           {
  //     //             paddingHorizontal: 4,
  //     //             color: showTitulo ? "#FFF" : "#515c70",
  //     //           },
  //     //         ]}
  //     //       >
  //     //         {showTitulo ? "descrição fonte de recursos" : "0"}
  //     //       </Text>
  //     //     </View>
  //     //   </View>
  //     //   {esferaSelected.length && (
  //     //     <View style={[stylesPdf.coluna, styledCol[2]]}>
  //     //       <View style={stylesPdf.divTituloColuna}>
  //     //         <Text
  //     //           style={[stylesPdf.textTituloColuna, { paddingHorizontal: 4 }]}
  //     //         >
  //     //           Esfera
  //     //         </Text>
  //     //       </View>
  //     //     </View>
  //     //   )}
  //     //   <View style={[stylesPdf.coluna, styledCol[3]]}>
  //     //     <View style={stylesPdf.divTituloColuna}>
  //     //       <Text
  //     //         style={[
  //     //           stylesPdf.textTituloColuna,
  //     //           {
  //     //             textAlign: "right",
  //     //             paddingRight: 4,
  //     //             color: showValue ? "#FFF" : "#515c70",
  //     //           },
  //     //         ]}
  //     //       >
  //     //         {showValue ? "Valor R$" : "0"}
  //     //       </Text>
  //     //     </View>
  //     //   </View>
  //     // </View>
  //     <View
  //       style={[
  //         stylesPdf.headerInfos,
  //         { justifyContent: "space-between", fontWeight: "bold" },
  //       ]}
  //       fixed
  //     >
  //       <View
  //         style={{
  //           flexDirection: "row",
  //           flex: "1",
  //           gap: "16px",
  //           alignItems: "center",
  //         }}
  //       >
  //         {showCodigo && (
  //           <>
  //             <Text
  //           style={[
  //             stylesPdf.textTituloColuna,
  //             { textAlign: "left", width: "50px" },
  //           ]}
  //         >
  //           exercício
  //         </Text>
  //         <Text
  //           style={[
  //             stylesPdf.textTituloColuna,
  //             { textAlign: "left", width: "50px" },
  //           ]}
  //         >
  //           fonte de{"\n"}recurso
  //         </Text>
  //           </>
  //         )}
  //         <Text
  //           style={[
  //             stylesPdf.textTituloColuna,
  //             {
  //               maxWidth: "250px",
  //               width: "100%",
  //               textAlign: "left",
  //             },
  //           ]}
  //         >
  //           descrição fonte de recursos
  //         </Text>
  //         {esferaSelected.length && (
  //           <Text style={[stylesPdf.textTituloColuna]}>esfera</Text>
  //         )}
  //       </View>
  //       <Text style={[stylesPdf.textTituloColuna]}>valor R$</Text>
  //     </View>
  //   );
  // };

  const cabecalhoEsfera = () => {
    return (
      <View
        style={[
          stylesPdf.headerInfos,
          { justifyContent: "space-between", fontWeight: "bold" },
        ]}
        fixed
      >
        <Text style={[stylesPdf.textTituloColuna]}>esfera</Text>

        <Text style={[stylesPdf.textTituloColuna]}>valor R$</Text>
      </View>
    );
  };

  const renderInfoUnidadeOrcamentaria = (item) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          { fontWeight: "bold", backgroundColor: "#a6a6a6" },
        ]}
        key={item.unidadeOrcamentariaId.id}
      >
        {showCodigo && (
          <View style={[styledCol[0]]}>
            <Text
              style={[
                stylesPdf.textInfosRelatorio,
                {
                  paddingHorizontal: 4,
                  paddingVertical: 2,
                  color: "#000",
                },
              ]}
            >
              {item.codigoUnidadeOrcamentaria}
            </Text>
          </View>
        )}

        <View style={[styledCol[1]]}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingHorizontal: 4,
                paddingVertical: 2,
                color: "#000",
              },
            ]}
          >
            {showTitulo ? item.tituloUnidadeOrcamentaria : ""}
          </Text>
        </View>

        {esferaSelected.length > 0 && (
          <View style={[styledCol[2]]}>
            <Text
              style={[
                stylesPdf.textInfosRelatorio,
                {
                  paddingHorizontal: 4,
                  paddingVertical: 2,
                },
              ]}
            >
              {`${""}`}
            </Text>
          </View>
        )}
        <View style={[styledCol[3]]}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "right",
                paddingRight: 4,
                paddingVertical: 2,
                color: "#000",
              },
            ]}
          >
            {showValue
              ? `${FormatValueToLocaleString(
                  parseFloat(item.totalAcoesDaUnidade)
                )}`
              : ""}
          </Text>
        </View>
      </View>

      // <View key={item.unidadeOrcamentariaId.id} style={{ fontWeight: "bold" }}>
      //   <View style={[stylesPdf.barTitleInfos, {backgroundColor: "#a6a6a6"}]}>
      //     {showCodigo && (
      //       <View
      //       style={[
      //         stylesPdf.textCodigoQdd,
      //         { textAlign: "start", backgroundColor: 'red' },
      //       ]}
      //     >
      //       <Text>{item.codigoUnidadeOrcamentaria}</Text>
      //     </View>
      //     )}

      //     {showTitulo && (
      //       <View
      //       style={[stylesPdf.textTituloQdd]}
      //     >
      //       <Text>{item.tituloUnidadeOrcamentaria}</Text>
      //     </View>
      //     )}

      //     {showValue && (
      //       <View
      //       style={[stylesPdf.textTotalQdd, {backgroundColor: 'green'}]}
      //     >
      //       <Text>{`R$ ${FormatValueToLocaleString(
      //         parseFloat(item.totalAcoesDaUnidade)
      //       )}`}</Text>
      //     </View>
      //     )}
      //   </View>
      // </View>
    );
  };

  const renderInfoAcao = (item) => {
    return (
      <View
        style={[stylesPdf.colunas, { fontWeight: "bold" }]}
        key={item.acaoGovernamentalId}
      >
        {showCodigo && (
          <View
            style={[
              styledCol[0],
              { flexDirection: "row", justifyContent: "space-between" },
            ]}
          >
            {showClassificacao && (
              <Text
                style={[
                  stylesPdf.textInfosRelatorio,
                  {
                    paddingHorizontal: 4,
                    paddingVertical: 2,
                    fontWeight: "normal",
                  },
                ]}
              >
                {`${item.funcao.valor}.${item.subFuncao.valor}.${item.programaPlanejamentoId.numeroPrograma}`}
              </Text>
            )}
            <Text
              style={[
                stylesPdf.textInfosRelatorio,
                {
                  paddingHorizontal: 4,
                  paddingVertical: 2,
                  color: "#000",
                },
              ]}
            >
              {item.nAcao}
            </Text>
          </View>
        )}
        <View style={[styledCol[1]]}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingHorizontal: 4,
                paddingVertical: 2,
                color: "#000",
              },
            ]}
          >
            {showTitulo ? item.titulo : ""}
          </Text>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingHorizontal: 4,
                paddingVertical: 2,
              },
            ]}
          >
            <Text style={{ fontWeight: "normal" }}>
              {showTitulo ? item.objetivos : ""}
            </Text>
          </Text>
        </View>
        {esferaSelected.length > 0 && (
          <View style={[styledCol[2]]}>
            <Text
              style={[
                stylesPdf.textInfosRelatorio,
                {
                  paddingHorizontal: 4,
                  paddingVertical: 2,
                },
              ]}
            >
              {`${""}`}
            </Text>
          </View>
        )}
        <View style={[styledCol[3]]}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "right",
                paddingRight: 4,
                paddingVertical: 2,
                color: "#000",
              },
            ]}
          >
            {showValue
              ? `${FormatValueToLocaleString(
                  parseFloat(item.valorTotalDaAcao)
                )}`
              : ""}
          </Text>
        </View>
      </View>
      // <View key={item.acaoGovernamentalId} style={{ fontWeight: "bold" }}>
      //   <View style={[stylesPdf.barTitleInfos]}>
      //     <View
      //       style={[
      //         stylesPdf.textCodigoQdd,
      //         {
      //           flexDirection: "row",
      //           justifyContent: "space-between",
      //           alignItems: "center",
      //         },
      //       ]}
      //     >
      //       <Text style={{ fontWeight: "normal" }}>
      //         {`${item.funcao.valor}.${item.subFuncao.valor}.${item.programaPlanejamentoId.numeroPrograma}`}
      //       </Text>
      //       <Text>{item.nAcao}</Text>
      //     </View>

      //     <View style={stylesPdf.textTituloQdd}>
      //       <Text>{item.titulo}</Text>
      //     </View>

      //     <View style={stylesPdf.textTotalQdd}>
      //       <Text>{`R$ ${FormatValueToLocaleString(
      //         parseFloat(item.valorTotalDaAcao)
      //       )}`}</Text>
      //     </View>
      //   </View>

      //   <View style={stylesPdf.barTitleInfos}>
      //     <View style={stylesPdf.textCodigoQdd} />

      //     <View style={[stylesPdf.textTituloQdd, { fontWeight: "normal" }]}>
      //       <Text>{item.objetivos}</Text>
      //     </View>

      //     <View style={stylesPdf.textTotalInfos} />
      //   </View>
      // </View>
    );
  };

  const renderInfosFonteDeRecurso = (item) => {
    return (
      <View style={[stylesPdf.colunas]} key={item.fonteDeRecurso.id}>
        {showCodigo && (
          <View style={[styledCol[0]]}>
            <Text
              style={[
                stylesPdf.textInfosRelatorio,
                {
                  paddingHorizontal: 4,
                  paddingVertical: 2,
                  textAlign: "right",
                  color: "#000",
                },
              ]}
            >
              {item.fonteDeRecurso.conta}
            </Text>
          </View>
        )}
        <View style={[styledCol[1]]}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingHorizontal: 4,
                paddingVertical: 2,
                color: "#000",
              },
            ]}
          >
            {showTitulo ? item.fonteDeRecurso.titulo : ""}
          </Text>
        </View>
        {esferaSelected.length > 0 && (
          <View style={[styledCol[2]]}>
            <Text
              style={[
                stylesPdf.textInfosRelatorio,
                {
                  paddingHorizontal: 4,
                  paddingVertical: 2,
                },
              ]}
            >
              {`${""}`}
            </Text>
          </View>
        )}
        <View style={[styledCol[3]]}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "right",
                paddingRight: 4,
                paddingVertical: 2,
                color: "#000",
              },
            ]}
          >
            {showValue
              ? `${FormatValueToLocaleString(parseFloat(item.totalPorFonte))}`
              : ""}
          </Text>
        </View>
      </View>
      // <View key={item.fonteDeRecurso.id}>
      //   <View style={[stylesPdf.barTitleInfos, { fontWeight: "normal" }]}>
      //     <View style={stylesPdf.textCodigoQdd}>
      //       <Text>{item.fonteDeRecurso.conta}</Text>
      //     </View>

      //     <View style={stylesPdf.textTituloQdd}>
      //       <Text>{item.fonteDeRecurso.titulo}</Text>
      //     </View>

      //     <View style={stylesPdf.textTotalQdd}>
      //       <Text>
      //         {`R$ ${FormatValueToLocaleString(
      //           parseFloat(item.totalPorFonte)
      //         )}`}
      //       </Text>
      //     </View>
      //   </View>
      // </View>
    );
  };

  const renderInfosNaturezaDespesa = (item) => {
    return (
      <View style={[stylesPdf.colunas, { fontWeight: "bold" }]} key={item.id}>
        {showCodigo && (
          <View
            style={[
              styledCol[0],
              { flexDirection: "row", justifyContent: "space-between" },
            ]}
          >
            <Text
              style={[
                stylesPdf.textInfosRelatorio,
                {
                  paddingHorizontal: 4,
                  paddingVertical: 2,
                  color: "#000",
                },
              ]}
            >
              {item.numeroFicha}
            </Text>
            <Text
              style={[
                stylesPdf.textInfosRelatorio,
                {
                  paddingHorizontal: 4,
                  paddingVertical: 2,
                  color: "#000",
                },
              ]}
            >
              {item.naturezaDaDespesa.codigo}
            </Text>
          </View>
        )}
        <View style={[styledCol[1]]}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                paddingHorizontal: 4,
                paddingVertical: 2,
                color: "#000",
              },
            ]}
          >
            {showTitulo ? item.naturezaDaDespesa.nome : ""}
          </Text>
        </View>
        {esferaSelected.length > 0 && (
          <View style={[styledCol[2]]}>
            <Text
              style={[
                stylesPdf.textInfosRelatorio,
                {
                  paddingHorizontal: 4,
                  paddingVertical: 2,
                },
              ]}
            >
              {item.esfera}
            </Text>
          </View>
        )}
        <View style={[styledCol[3]]}>
          <Text
            style={[
              stylesPdf.textInfosRelatorio,
              {
                textAlign: "right",
                paddingRight: 4,
                paddingVertical: 2,
                color: "#000",
              },
            ]}
          >
            {showValue
              ? `${FormatValueToLocaleString(parseFloat(item.valor))}`
              : ""}
          </Text>
        </View>
      </View>
      // <View key={item.id} style={{ fontWeight: "bold" }}>
      //   <View style={[stylesPdf.barTitleInfos]}>
      //     <View
      //       style={[
      //         stylesPdf.textCodigoQdd,
      //         { flexDirection: "row", justifyContent: "space-between" },
      //       ]}
      //     >
      //       <Text>{item.numeroFicha}</Text>
      //       <Text>{item.naturezaDaDespesa.codigo}</Text>
      //     </View>

      //     <View
      //       style={[
      //         stylesPdf.textTituloQdd,
      //         {
      //           flexDirection: "row",
      //           justifyContent: "space-between",
      //           paddingRight: "15px",
      //         },
      //       ]}
      //     >
      //       <Text>{item.naturezaDaDespesa.nome}</Text>
      //       {esferaSelected.length > 0 && <Text>{item.esfera}</Text>}
      //     </View>

      //     <View style={stylesPdf.textTotalQdd}>
      //       <Text>
      //         {`R$ ${FormatValueToLocaleString(parseFloat(item.valor))}`}
      //       </Text>
      //     </View>
      //   </View>
      // </View>
    );
  };

  const renderInfosRelatorio = (item) => {
    return (
      <View key={item.unidadeOrcamentariaId} style={{ paddingRight: "1px" }}>
        {idsUnidadeOrcamentariaSelected.length
          ? renderInfoUnidadeOrcamentaria(item)
          : []}

        {item.acoes.map((acao) => (
          <View key={acao.acaoGovernamentalId} style={{ marginBottom: "10px" }}>
            {idsAcaoGovernamentalSelected.length ? renderInfoAcao(acao) : []}
            {acao.despesaFixadaQDD.map((ficha) => (
              <React.Fragment key={ficha.id}>
                {idsFonteDeRecursoSelected.length
                  ? renderInfosFonteDeRecurso(ficha)
                  : []}
                {ficha.fichasQDD.map((natureza) =>
                  idsElementoDespesaSelected.length
                    ? renderInfosNaturezaDespesa(natureza)
                    : []
                )}
              </React.Fragment>
            ))}
          </View>
        ))}
      </View>
    );
  };

  const renderInfosFiltroFonteRecursos = (fonte) => {
    const filtroPorFiscal = esferaSelected.includes("Fiscal");
    const filtroPorSeguridade = esferaSelected.includes("Seguridade");

    return (
      <View
        key={fonte.id}
        style={[
          stylesPdf.barTitleInfos,
          {
            paddingHorizontal: 4,
            fontWeight: "normal",
            marginBottom: 5,
          },
        ]}
      >
        <View style={{ flexDirection: "row", alignItems: "center", gap: 16 }}>
          {showCodigo && (
            <>
              <Text style={{ width: 50 }}>{fonte.conta[0]}</Text>
              <Text style={{ width: 50 }}>{fonte.conta.split(".")[1]}</Text>
            </>
          )}
          <View style={[{ maxWidth: 250, width: "100%" }]}>
            <Text>{showTitulo ? fonte.titulo : ""}</Text>
          </View>
          <View
            style={{
              textTransform: "capitalize",
              gap: 2,
            }}
          >
            {filtroPorFiscal && <Text>fiscal</Text>}
            {filtroPorSeguridade && <Text>seguridade</Text>}
          </View>
        </View>

        {showValue && (
          <>
            <View style={[stylesPdf.textTotalInfos]}>
              {filtroPorFiscal && (
                <Text>{FormatValueToLocaleString(fonte.totalFiscal)}</Text>
              )}
              {filtroPorSeguridade && (
                <Text>{FormatValueToLocaleString(fonte.totalSeguridade)}</Text>
              )}
              {!filtroPorFiscal && !filtroPorSeguridade && (
                <Text>
                  {FormatValueToLocaleString(
                    fonte.totalFiscal + fonte.totalSeguridade
                  )}
                </Text>
              )}
            </View>
          </>
        )}
      </View>
    );
  };

  // const renderInfosFiltroFonteRecursos = (fonte) => {
  //   const filtroPorFiscal = esferaSelected.includes("Fiscal");
  //   const filtroPorSeguridade = esferaSelected.includes("Seguridade");

  //   return (
  //     <View
  //       key={fonte.id}
  //       style={[
  //         stylesPdf.barTitleInfos,
  //         {
  //           paddingHorizontal: 4,
  //           fontWeight: "normal",
  //           marginBottom: 5,
  //         },
  //       ]}
  //     >
  //       <View style={{ flexDirection: "row", alignItems: "center", gap: 16 }}>
  //         <Text style={{ width: 50 }}>{fonte.conta[0]}</Text>
  //         <Text style={{ width: 50 }}>{fonte.conta.split(".")[1]}</Text>
  //         <View style={[{ maxWidth: 250, width: "100%" }]}>
  //           <Text>{fonte.titulo}</Text>
  //         </View>
  //         <View
  //           style={{
  //             textTransform: "capitalize",
  //             gap: 2,
  //           }}
  //         >
  //           {filtroPorFiscal && <Text>fiscal</Text>}
  //           {filtroPorSeguridade && <Text>seguridade</Text>}
  //         </View>
  //       </View>

  //       <View style={[stylesPdf.textTotalInfos]}>
  //         {filtroPorFiscal && (
  //           <Text>{FormatValueToLocaleString(fonte.totalFiscal)}</Text>
  //         )}
  //         {filtroPorSeguridade && (
  //           <Text>{FormatValueToLocaleString(fonte.totalSeguridade)}</Text>
  //         )}
  //         {!filtroPorFiscal && !filtroPorSeguridade && (
  //           <Text>
  //             {FormatValueToLocaleString(
  //               fonte.totalFiscal + fonte.totalSeguridade
  //             )}
  //           </Text>
  //         )}
  //       </View>
  //     </View>
  //   );
  // };

  const renderInfosFiltroEsfera = (fonteTotais) => {
    const filtroPorFiscal = esferaSelected.includes("Fiscal");
    const filtroPorSeguridade = esferaSelected.includes("Seguridade");
    return (
      <View
        style={[
          stylesPdf.barTitleInfos,
          { paddingHorizontal: "4px", fontWeight: "normal" },
        ]}
      >
        <View
          style={[
            {
              textTransform: "capitalize",
              gap: "2px",
            },
          ]}
        >
          {filtroPorFiscal && <Text>fiscal</Text>}
          {filtroPorSeguridade && <Text>seguridade</Text>}
        </View>

        <View style={[stylesPdf.textTotalInfos]}>
          {filtroPorFiscal && (
            <Text>
              {FormatValueToLocaleString(Number(fonteTotais.totalGeralFiscal))}
            </Text>
          )}
          {filtroPorSeguridade && (
            <Text>
              {FormatValueToLocaleString(
                Number(fonteTotais.totalGeralSeguridade)
              )}
            </Text>
          )}
        </View>
      </View>
    );
  };

  const relatorioPorFonte =
    idsFonteDeRecursoSelected.length > 0 &&
    filtrosIds.every((arr) => arr.length === 0);

  const relatorioEsfera =
    esferaSelected.length > 0 &&
    !idsFonteDeRecursoSelected.length > 0 &&
    filtrosIds.every((arr) => arr.length === 0);

  const relatorioGeral = !relatorioPorFonte && !relatorioEsfera;

  return (
    <Document>
      <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
        <PdfHeader
          currentClient={currentClient}
          nameRelatorio={"QDD - Quadro Detalhado da Despesa"}
          anoRelatorio={currentYear}
          dataClientInfos={dataClientInfos}
        />
        <Text style={[stylesPdf.textFiltro]} fixed>
          {nameFilter}
        </Text>
        {relatorioGeral && cabecalhoGeral()}

        {relatorioPorFonte && cabecalhoFonteDeRecurso()}

        {relatorioEsfera && cabecalhoEsfera()}

        <View style={stylesPdf.divRelatorio}>
          {relatorioGeral &&
            dadosRelatorio?.map((item) => renderInfosRelatorio(item))}

          {relatorioPorFonte &&
            dadosRelatorio.fontes.map((item) =>
              renderInfosFiltroFonteRecursos(item)
            )}
          {relatorioEsfera && renderInfosFiltroEsfera(dadosRelatorio.totais)}
        </View>
        <View style={stylesPdf.divInferiorColuna}></View>
        {dadosRelatorio && relatorioGeral && showValue && (
          <Text style={[stylesPdf.textTotalInfos, { fontWeight: "bold" }]}>
            {`Total R$ ${FormatValueToLocaleString(
              calcularTotalUnidade(dadosRelatorio)
            )}`}
          </Text>
        )}

        {dadosRelatorio &&
          (relatorioPorFonte || relatorioEsfera) &&
          showValue && (
            <Text style={[stylesPdf.textTotalInfos, { fontWeight: "bold" }]}>
              {`Total R$ ${FormatValueToLocaleString(
                Number(dadosRelatorio.totais.totalGeral)
              )}`}
            </Text>
          )}
        <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
      </Page>
      {/* {pages.map((pageData, pageIndex) => (
        <Page key={pageIndex} orientation="portrait" size={"A4"} style={stylesPdf.page}>
          <PdfHeader
            currentClient={currentClient}
            nameRelatorio={"QDD - Quadro Detalhado da Despesa"}
            anoRelatorio={currentYear}
            dataClientInfos={dataClientInfos}
          />
          <Text style={[stylesPdf.textFiltro]} fixed>{nameFilter}</Text>
          <PdfHeaderInfos textHeader={"natureza da despesa"} />

          <View style={stylesPdf.divRelatorio}>
            {pageData?.map((item) => renderInfosRelatorio(item))}
          </View>
          <View style={stylesPdf.divInferiorColuna}></View>
          <PdfRodape
            currentClient={currentClient}
            userAuthData={userAuthData}
          />
        </Page>
      ))} */}
    </Document>
  );
};
