import { FormatValueToLocaleString } from "../../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { calcularTotalReceitas } from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getReceitasCatEconOrgao = async (
  clientes,
  entidadesGestoras,
  ano = 2024
) => {
  const url = getUrl(clientes, entidadesGestoras);
  try {
    const rota = `qdr/relatorio/${url.idsUrl}/${ano}?${url.filterConsolidado}&separarPorUnidade=1&somarFontes=1`;
    console.log(rota);
    const response = await api.get(rota);
    console.log(clientes);
    console.log("response.data", response.data);
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Anexo II - Receita Segundo as Categorias Econômicas por Órgão",
    };
  }
};

const orderFonts = (naturezas) => {
  return naturezas.map((natureza) => {
    const { qdr } = natureza;

    if (qdr && qdr !== "vazio" && qdr.fontesDeRecursos) {
      qdr.fontesDeRecursos.sort((a, b) => a.conta.localeCompare(b.conta));
    }

    return natureza;
  });
};

export const processAllData = (data) => {
  const dataQdrRelatorioCatEconOrgao = data.resultadosPorUnidade;
  console.log(dataQdrRelatorioCatEconOrgao);

  const codigosSectionRelatorio = ["1.0.0.0.00.0.0", "2.0.0.0.00.0.0"];
  const fonteComDeducao = 3;
  const fonteSemDeducao = 1;

  const processItem = (item, deducao) => {
    const seccoes = item.nr.split(".").filter((secao) => parseInt(secao) !== 0);
    let desdobramento = null,
      fonte = null,
      catEcon = null;

    if (seccoes.length === 1) {
      catEcon = item.valor;
    } else if (seccoes.length === 2) {
      fonte = item.valor;
    } else if (seccoes.length > 2) {
      desdobramento = item.valor;
    }

    const resultado = [
      {
        codigo: item.nr,
        especificacao: item.especificacao,
        desdobramento: desdobramento,
        fonte: fonte,
        catEcon: catEcon,
        valor: item.valor,
      },
    ];

    if (
      item.qdr &&
      item.qdr.fontesDeRecursos &&
      item.qdr.fontesDeRecursos.length > 0
    ) {
      const recursos = item.qdr.fontesDeRecursos
        .map((recurso) => {
          return {
            //parei aquiiiii
            codigo: recurso.conta,
            especificacao: recurso.titulo,
            desdobramento: recurso.valorPrevisto,
            fonte: null,
            catEcon: null,
            valor: recurso.valorPrevisto,
          };
        })
        .filter((recurso) => recurso !== null);
      resultado.push(...recursos);
    }

    return resultado;
  };

  console.log("myPrint1: ", dataQdrRelatorioCatEconOrgao);

  const dadosFiltrados = dataQdrRelatorioCatEconOrgao
    .map((unidade) => {
      const { infoUnidade, naturezas, naturezasDeducao } = unidade;

      if (!naturezas.length && !naturezasDeducao.length) return null;

      const receitas = {
        naturezas: naturezas,
        naturezasDeducao: naturezasDeducao,
      };

      return {
        infoCliente: {
          codigo: infoUnidade.unidade.poderOrgao.match(/\d+/g).join('') || "",
          especificacao: infoUnidade.unidadeGestora.nomeFantasia,
          tipoNaturezaJuridica: infoUnidade.unidade.naturezaJuridica,
          total: calcularTotalReceitas(receitas, codigosSectionRelatorio),
        },
        naturezas: orderFonts(naturezas).flatMap((natureza) =>
          processItem(natureza, fonteSemDeducao)
        ),
        naturezasDeducao: orderFonts(naturezasDeducao).flatMap((natureza) =>
          processItem(natureza, fonteComDeducao)
        ),
      };
    })
    .filter((data) => data !== null);

  console.log("myPrint2: ", dadosFiltrados);

  dadosFiltrados.sort((a, b) => {
    const tipoA = parseInt(a.infoCliente.tipoNaturezaJuridica[0]);
    const tipoB = parseInt(b.infoCliente.tipoNaturezaJuridica[0]);
    return tipoA - tipoB;
  });

  const totalReceitas = dataQdrRelatorioCatEconOrgao.reduce((acc, unidade) => {
    const receitas = {
      naturezas: unidade.naturezas,
      naturezasDeducao: unidade.naturezasDeducao,
    };

    const total = calcularTotalReceitas(receitas, codigosSectionRelatorio);

    return acc + total;
  }, 0);

  return {
    data: dadosFiltrados,
    sum: FormatValueToLocaleString(totalReceitas),
  };
};
