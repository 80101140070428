import React from "react";
import { Row } from "react-bootstrap";
import { CCol } from "./CCol";
import { OptionsStyle } from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/NaturezaDaReceita";

const RowSelecaoAbasInferior = ({
  abas,
  abaSelecionada,
  setAbaSelecionada,
  style,
}) => {
  return (
    <Row style={style}>
      <CCol className="d-flex align-items-center row_endereco_aba mt-1">
        {abas.map((aba, index) => (
          <OptionsStyle
            onClick={() =>
              setAbaSelecionada({ nomeAba: aba, numeroAba: index })
            }
            selecionado={
              abaSelecionada.numeroAba === index ||
              abaSelecionada.nomeAba === aba
            }
          >
            {aba}
          </OptionsStyle>
        ))}
      </CCol>
    </Row>
  );
};

export default RowSelecaoAbasInferior;
