import { Row } from "react-bootstrap";
import { CCol, RowAninhada } from "../../../../../../components/Grid/CCol";
import { ProgressBarComponent } from "../../../../../../components/Grid/ProgressBarComponent";

export const ImportarLOA = () => {
  return (
    <>
      <Row>
        <RowAninhada className="mt-3">
          <CCol
            className="d-flex align-items-center p-2 header_body_conteudo"
            md={12}
            style={{ justifyContent: "left" }}
          >
            <span
              style={{
                fontSize: "16.5px",
                fontWeight: 600,
                marginLeft: "10px",
              }}
            >
              Principal
            </span>
          </CCol>
        </RowAninhada>
        <RowAninhada className="row_form mt-1" style={{ padding: "15px 0" }}>
          <RowAninhada>
            <CCol>
              <ProgressBarComponent
                percentage={40}
                label={"Verificando dados Orçamentários no Sistema SAVE"}
              />
            </CCol>
          </RowAninhada>
          <RowAninhada className="mt-3">
            <CCol>
              <ProgressBarComponent
                percentage={40}
                label={"Importando Natureza da Receita"}
              />
            </CCol>
          </RowAninhada>
          <RowAninhada className="mt-3">
            <CCol>
              <ProgressBarComponent
                percentage={40}
                label={"Importando Natureza da Despesa"}
              />
            </CCol>
          </RowAninhada>
          <RowAninhada className="mt-3">
            <CCol>
              <ProgressBarComponent
                percentage={40}
                label={"Importando Fonte ou Destinação de Recursos"}
              />
            </CCol>
          </RowAninhada>
        </RowAninhada>
      </Row>
    </>
  );
};

export default ImportarLOA;
