import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { TituloAbaSolo } from "../../../../styles/StyledComponents/LayoutGerais";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import { PTitulosInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { TestButton } from "../../../../utils/TestButton";
import { Icon } from "@iconify/react/dist/iconify.js";
import { FormControlListaDeInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import { CurrentYearContext } from "../../../../contexts/YearContext";

const PatrimonioLiquido = ({ saveButtonStatus, setSaveButtonStatus }) => {
  const { currentYear } = useContext(CurrentYearContext);
  const [values, setValues] = useState({
    "Patrimônio: ": {
      "Patrimônio / Capital": {
        [currentYear - 2]: "00",
        [`${currentYear - 2}%`]: "11%",
        [currentYear - 3]: "22",
        [`${currentYear - 3}%`]: "33%",
        [currentYear - 4]: "44",
        [`${currentYear - 4}%`]: "55%",
      },
      Reservas: {
        [currentYear - 2]: "00",
        [`${currentYear - 2}%`]: "11%",
        [currentYear - 3]: "22",
        [`${currentYear - 3}%`]: "33%",
        [currentYear - 4]: "44",
        [`${currentYear - 4}%`]: "55%",
      },
      "Resultado Acumulado": {
        [currentYear - 2]: "00",
        [`${currentYear - 2}%`]: "11%",
        [currentYear - 3]: "22",
        [`${currentYear - 3}%`]: "33%",
        [currentYear - 4]: "44",
        [`${currentYear - 4}%`]: "55%",
      },
    },
    "Patrimônio (REGIME PREVIDENCIÁRIO): ": {
      "Patrimônio / Capital": {
        [currentYear - 2]: "00",
        [`${currentYear - 2}%`]: "11%",
        [currentYear - 3]: "22",
        [`${currentYear - 3}%`]: "33%",
        [currentYear - 4]: "44",
        [`${currentYear - 4}%`]: "55%",
      },
      Reservas: {
        [currentYear - 2]: "00",
        [`${currentYear - 2}%`]: "11%",
        [currentYear - 3]: "22",
        [`${currentYear - 3}%`]: "33%",
        [currentYear - 4]: "44",
        [`${currentYear - 4}%`]: "55%",
      },
      "Resultado Acumulado": {
        [currentYear - 2]: "00",
        [`${currentYear - 2}%`]: "11%",
        [currentYear - 3]: "22",
        [`${currentYear - 3}%`]: "33%",
        [currentYear - 4]: "44",
        [`${currentYear - 4}%`]: "55%",
      },
    },
  });
  const [oldValues, setOldValues] = useState({});

  const [editMode, setEditMode] = useState(
    Object.keys(values).map(() => [false, false])
  );

  useEffect(() => {
    console.log(saveButtonStatus);
    if (saveButtonStatus) {
      setSaveButtonStatus(false);
    }
  }, [saveButtonStatus, setSaveButtonStatus]);

  useEffect(() => {
    setValues((prevValues) => {
      const updatedValues = { ...prevValues };

      Object.keys(updatedValues).forEach((key) => {
        Object.keys(updatedValues[key]).forEach((subKey) => {
          updatedValues[key][subKey] = {
            [currentYear - 2]: prevValues[key][subKey][currentYear - 2] || "",
            [`${currentYear - 2}%`]:
              prevValues[key][subKey][`${currentYear - 2}%`] || "",
            [currentYear - 3]: prevValues[key][subKey][currentYear - 3] || "",
            [`${currentYear - 3}%`]:
              prevValues[key][subKey][`${currentYear - 3}%`] || "",
            [currentYear - 4]: prevValues[key][subKey][currentYear - 4] || "",
            [`${currentYear - 4}%`]:
              prevValues[key][subKey][`${currentYear - 4}%`] || "",
          };
        });
      });

      return updatedValues;
    });
  }, [currentYear]);

  useEffect(() => {
    // Atualiza os valores baseados no objeto original e no currentYear
    const newValues = {
      "Patrimônio: ": {
        "Patrimônio / Capital": {
          [currentYear - 2]:
            values["Patrimônio: "]["Patrimônio / Capital"][
              `${currentYear - 2}`
            ] || "00",
          [`${currentYear - 2}%`]:
            values["Patrimônio: "]["Patrimônio / Capital"][
              `${currentYear - 2}%`
            ] || "11%",
          [currentYear - 3]:
            values["Patrimônio: "]["Patrimônio / Capital"][
              `${currentYear - 3}`
            ] || "22",
          [`${currentYear - 3}%`]:
            values["Patrimônio: "]["Patrimônio / Capital"][
              `${currentYear - 3}%`
            ] || "33%",
          [currentYear - 4]:
            values["Patrimônio: "]["Patrimônio / Capital"][
              `${currentYear - 4}`
            ] || "44",
          [`${currentYear - 4}%`]:
            values["Patrimônio: "]["Patrimônio / Capital"][
              `${currentYear - 4}%`
            ] || "55%",
        },
        Reservas: {
          [currentYear - 2]:
            values["Patrimônio: "]["Reservas"][`${currentYear - 2}`] || "00",
          [`${currentYear - 2}%`]:
            values["Patrimônio: "]["Reservas"][`${currentYear - 2}%`] || "11%",
          [currentYear - 3]:
            values["Patrimônio: "]["Reservas"][`${currentYear - 3}`] || "22",
          [`${currentYear - 3}%`]:
            values["Patrimônio: "]["Reservas"][`${currentYear - 3}%`] || "33%",
          [currentYear - 4]:
            values["Patrimônio: "]["Reservas"][`${currentYear - 4}`] || "44",
          [`${currentYear - 4}%`]:
            values["Patrimônio: "]["Reservas"][`${currentYear - 4}%`] || "55%",
        },
        "Resultado Acumulado": {
          [currentYear - 2]:
            values["Patrimônio: "]["Resultado Acumulado"][
              `${currentYear - 2}`
            ] || "00",
          [`${currentYear - 2}%`]:
            values["Patrimônio: "]["Resultado Acumulado"][
              `${currentYear - 2}%`
            ] || "11%",
          [currentYear - 3]:
            values["Patrimônio: "]["Resultado Acumulado"][
              `${currentYear - 3}`
            ] || "22",
          [`${currentYear - 3}%`]:
            values["Patrimônio: "]["Resultado Acumulado"][
              `${currentYear - 3}%`
            ] || "33%",
          [currentYear - 4]:
            values["Patrimônio: "]["Resultado Acumulado"][
              `${currentYear - 4}`
            ] || "44",
          [`${currentYear - 4}%`]:
            values["Patrimônio: "]["Resultado Acumulado"][
              `${currentYear - 4}%`
            ] || "55%",
        },
      },
      "Patrimônio (REGIME PREVIDENCIÁRIO): ": {
        "Patrimônio / Capital": {
          [currentYear - 2]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "][
              "Patrimônio / Capital"
            ][`${currentYear - 2}`] || "00",
          [`${currentYear - 2}%`]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "][
              "Patrimônio / Capital"
            ][`${currentYear - 2}%`] || "11%",
          [currentYear - 3]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "][
              "Patrimônio / Capital"
            ][`${currentYear - 3}`] || "22",
          [`${currentYear - 3}%`]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "][
              "Patrimônio / Capital"
            ][`${currentYear - 3}%`] || "33%",
          [currentYear - 4]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "][
              "Patrimônio / Capital"
            ][`${currentYear - 4}`] || "44",
          [`${currentYear - 4}%`]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "][
              "Patrimônio / Capital"
            ][`${currentYear - 4}%`] || "55%",
        },
        Reservas: {
          [currentYear - 2]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "]["Reservas"][
              `${currentYear - 2}`
            ] || "00",
          [`${currentYear - 2}%`]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "]["Reservas"][
              `${currentYear - 2}%`
            ] || "11%",
          [currentYear - 3]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "]["Reservas"][
              `${currentYear - 3}`
            ] || "22",
          [`${currentYear - 3}%`]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "]["Reservas"][
              `${currentYear - 3}%`
            ] || "33%",
          [currentYear - 4]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "]["Reservas"][
              `${currentYear - 4}`
            ] || "44",
          [`${currentYear - 4}%`]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "]["Reservas"][
              `${currentYear - 4}%`
            ] || "55%",
        },
        "Resultado Acumulado": {
          [currentYear - 2]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "][
              "Resultado Acumulado"
            ][`${currentYear - 2}`] || "00",
          [`${currentYear - 2}%`]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "][
              "Resultado Acumulado"
            ][`${currentYear - 2}%`] || "11%",
          [currentYear - 3]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "][
              "Resultado Acumulado"
            ][`${currentYear - 3}`] || "22",
          [`${currentYear - 3}%`]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "][
              "Resultado Acumulado"
            ][`${currentYear - 3}%`] || "33%",
          [currentYear - 4]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "][
              "Resultado Acumulado"
            ][`${currentYear - 4}`] || "44",
          [`${currentYear - 4}%`]:
            values["Patrimônio (REGIME PREVIDENCIÁRIO): "][
              "Resultado Acumulado"
            ][`${currentYear - 4}%`] || "55%",
        },
      },
    };

    setValues(newValues);
    // eslint-disable-next-line
  }, [currentYear]);

  const handleChange = (key, insideKey, year, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: {
        ...prevValues[key],
        [insideKey]: {
          ...prevValues[key][insideKey],
          [year]: value,
        },
      },
    }));
  };

  const handleEditToggle = (
    rowIndex,
    columnIndex,
    key,
    insideKey,
    edit,
    cancel
  ) => {
    setEditMode((prev) => {
      const newEditMode = [...prev];
      newEditMode[rowIndex][columnIndex] = edit;
      return newEditMode;
    });

    if (edit) {
      setOldValues((prevOldValues) => ({
        ...prevOldValues,
        [key]: {
          ...prevOldValues[key],
          [insideKey]: values[key][insideKey],
        },
      }));
    }

    if (cancel) {
      setValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[key][insideKey] = oldValues[key][insideKey];
        return updatedValues;
      });
    }
  };

  const formatValue = (input) => {
    const result = [];

    for (const [key, value] of Object.entries(input)) {
      const transformedObject = {
        especificacao: key.trim(),
        ...value,
      };

      if (transformedObject["Patrimônio / Capital"]) {
        transformedObject["PatrimonioCapital"] =
          transformedObject["Patrimônio / Capital"];
        delete transformedObject["Patrimônio / Capital"];
      }
      if (transformedObject["Resultado Acumulado"]) {
        transformedObject["ResultadoAcumulado"] =
          transformedObject["Resultado Acumulado"];
        delete transformedObject["Resultado Acumulado"];
      }

      const transformedObjectWithLowercase = Object.keys(
        transformedObject
      ).reduce((acc, currKey) => {
        const lowercaseKey = currKey.charAt(0).toLowerCase() + currKey.slice(1);
        acc[lowercaseKey] = transformedObject[currKey];
        return acc;
      }, {});

      result.push(transformedObjectWithLowercase);
    }

    return result;
  };

  const reverseFormatValue = (inputArray) => {
    const result = {};

    inputArray.forEach((item) => {
      // Clona o objeto e remove a propriedade 'especificacao'
      const { especificacao, patrimonioCapital, resultadoAcumulado, ...resto } =
        item;

      // Cria o novo objeto no formato desejado
      const transformedObject = {
        "Patrimônio / Capital": patrimonioCapital,
        "Resultado Acumulado": resultadoAcumulado,
        ...resto,
      };

      // Ajusta o nome da especificação, removendo possíveis espaços extras
      result[especificacao.trim()] = transformedObject;
    });

    return result;
  };

  return (
    <>
      <Row className="row_form mt-1">
        <TituloAbaSolo>Patrimônio Líquido</TituloAbaSolo>
      </Row>

      {Object.entries(values).map(([key, objValue], index) => (
        <Row className="row_form mt-1" key={index}>
          <RowAninhada>
            <CCol>
              <PTitulosInputs>Especificação</PTitulosInputs>
              <FloatingLabelInput
                disabled
                value={key}
                style={{ border: "none" }}
              />
            </CCol>
            <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
          </RowAninhada>

          {Object.entries(objValue).map(
            ([insideKey, insideObjValue], insideIndex) => {
              return (
                <RowAninhada
                  key={insideIndex}
                  style={{
                    marginTop: insideIndex === 0 ? "15px" : "0",
                    aligninsideKeys: "center",
                  }}
                >
                  <CCol
                    md={1}
                    style={{
                      color: "#888a8e",
                      fontSize: "14px",
                      fontWeight: "600",
                      width: "200px",
                      marginTop: insideIndex === 0 ? "14px" : "5px",
                    }}
                  >
                    {insideKey}
                  </CCol>
                  <CCol>
                    {insideIndex === 0 && (
                      <PTitulosInputs style={{ textAlign: "center" }}>
                        {Object.keys(insideObjValue)[2]}
                      </PTitulosInputs>
                    )}
                    <FormControlListaDeInputs
                      value={insideObjValue[currentYear - 2]}
                      height={"30px"}
                      onChange={(e) =>
                        handleChange(
                          key,
                          insideKey,
                          currentYear - 2,
                          e.target.value
                        )
                      }
                      radiusborder={
                        insideIndex === 0
                          ? "5px 5px 0 0"
                          : insideIndex === Object.entries(objValue).length - 1
                          ? "0 0 5px 5px"
                          : "0"
                      }
                      disabled={!editMode[index][insideIndex]}
                    />
                  </CCol>
                  <CCol md={1} style={{ width: "10%" }}>
                    {insideIndex === 0 && (
                      <PTitulosInputs style={{ textAlign: "center" }}>
                        {Object.keys(insideObjValue)[3].slice(-1)}
                      </PTitulosInputs>
                    )}
                    <FormControlListaDeInputs
                      value={insideObjValue[`${currentYear - 2}%`]}
                      height={"30px"}
                      onChange={(e) =>
                        handleChange(
                          key,
                          insideKey,
                          `${currentYear - 2}%`,
                          e.target.value
                        )
                      }
                      radiusborder={
                        insideIndex === 0
                          ? "5px 5px 0 0"
                          : insideIndex === Object.entries(objValue).length - 1
                          ? "0 0 5px 5px"
                          : "0"
                      }
                      disabled={!editMode[index][insideIndex]}
                    />
                  </CCol>
                  <CCol>
                    {insideIndex === 0 && (
                      <PTitulosInputs style={{ textAlign: "center" }}>
                        {Object.keys(insideObjValue)[1]}
                      </PTitulosInputs>
                    )}
                    <FormControlListaDeInputs
                      value={insideObjValue[currentYear - 3]}
                      height={"30px"}
                      onChange={(e) =>
                        handleChange(
                          key,
                          insideKey,
                          currentYear - 3,
                          e.target.value
                        )
                      }
                      radiusborder={
                        insideIndex === 0
                          ? "5px 5px 0 0"
                          : insideIndex === Object.entries(objValue).length - 1
                          ? "0 0 5px 5px"
                          : "0"
                      }
                      disabled={!editMode[index][insideIndex]}
                    />
                  </CCol>
                  <CCol md={1} style={{ width: "10%" }}>
                    {insideIndex === 0 && (
                      <PTitulosInputs style={{ textAlign: "center" }}>
                        {Object.keys(insideObjValue)[4].slice(-1)}
                      </PTitulosInputs>
                    )}
                    <FormControlListaDeInputs
                      value={insideObjValue[`${currentYear - 3}%`]}
                      height={"30px"}
                      onChange={(e) =>
                        handleChange(
                          key,
                          insideKey,
                          `${currentYear - 3}%`,
                          e.target.value
                        )
                      }
                      radiusborder={
                        insideIndex === 0
                          ? "5px 5px 0 0"
                          : insideIndex === Object.entries(objValue).length - 1
                          ? "0 0 5px 5px"
                          : "0"
                      }
                      disabled={!editMode[index][insideIndex]}
                    />
                  </CCol>
                  <CCol>
                    {insideIndex === 0 && (
                      <PTitulosInputs style={{ textAlign: "center" }}>
                        {Object.keys(insideObjValue)[0]}
                      </PTitulosInputs>
                    )}
                    <FormControlListaDeInputs
                      value={insideObjValue[currentYear - 4]}
                      height={"30px"}
                      onChange={(e) =>
                        handleChange(
                          key,
                          insideKey,
                          currentYear - 4,
                          e.target.value
                        )
                      }
                      radiusborder={
                        insideIndex === 0
                          ? "5px 5px 0 0"
                          : insideIndex === Object.entries(objValue).length - 1
                          ? "0 0 5px 5px"
                          : "0"
                      }
                      disabled={!editMode[index][insideIndex]}
                    />
                  </CCol>
                  <CCol md={1} style={{ width: "10%" }}>
                    {insideIndex === 0 && (
                      <PTitulosInputs style={{ textAlign: "center" }}>
                        {Object.keys(insideObjValue)[5].slice(-1)}
                      </PTitulosInputs>
                    )}
                    <FormControlListaDeInputs
                      value={insideObjValue[`${currentYear - 4}%`]}
                      height={"30px"}
                      onChange={(e) =>
                        handleChange(
                          key,
                          insideKey,
                          `${currentYear - 4}%`,
                          e.target.value
                        )
                      }
                      radiusborder={
                        insideIndex === 0
                          ? "5px 5px 0 0"
                          : insideIndex === Object.entries(objValue).length - 1
                          ? "0 0 5px 5px"
                          : "0"
                      }
                      disabled={!editMode[index][insideIndex]}
                    />
                  </CCol>
                  <CColButtonsAcoes2Icons md={1}>
                    {insideIndex === 0 && <PTitulosInputs>Ação</PTitulosInputs>}
                    <Icon
                      icon={
                        editMode[index][insideIndex]
                          ? "fluent:save-24-filled"
                          : "bx:edit"
                      }
                      height="26"
                      color={
                        editMode[index][insideIndex] ? "#008CFF" : "#5971C8"
                      }
                      onClick={() =>
                        editMode[index][insideIndex]
                          ? handleEditToggle(
                              index,
                              insideIndex,
                              key,
                              insideKey,
                              false
                            )
                          : handleEditToggle(
                              index,
                              insideIndex,
                              key,
                              insideKey,
                              true
                            )
                      }
                      style={{
                        cursor: "pointer",
                        marginTop: "3px",
                      }}
                    />

                    {editMode[index][insideIndex] && (
                      <Icon
                        icon="ic:baseline-cancel"
                        height="26"
                        color="#F24E1E"
                        onClick={() =>
                          handleEditToggle(
                            index,
                            insideIndex,
                            key,
                            insideKey,
                            false,
                            true
                          )
                        }
                        style={{
                          cursor: "pointer",
                          marginLeft: "2px",
                          marginRight: "-5px",
                          marginTop: "3px",
                        }}
                      />
                    )}
                  </CColButtonsAcoes2Icons>
                </RowAninhada>
              );
            }
          )}
        </Row>
      ))}
      <TestButton onClick={() => console.log(formatValue(values))}>
        formated
      </TestButton>
      <TestButton onClick={() => console.log(values)}>notFormated</TestButton>
      <TestButton
        onClick={() => console.log(reverseFormatValue(formatValue(values)))}
      >
        reversed
      </TestButton>
    </>
  );
};

export default PatrimonioLiquido;
