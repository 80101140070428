import React, { useState, useContext } from "react";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { ToastContainer } from "react-toastify";
import { Row, Container } from "react-bootstrap";
import { CCol } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { InputDateRelatorio } from "../../../../components/Inputs/InputDateRelatorio";
import { Icon } from "@iconify/react/dist/iconify.js";
import styled from "styled-components";
import { AuthContext } from "../../../../contexts/AuthContext";

const PDFBox = styled.div`
  border: 1px solid #888a8e;
  height: 140px;
  width: 100px;
  -webkit-box-shadow: 0 0 2px #000000;
  box-shadow: 0 0 2px #000000;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  accent-color: #2f7716;
  width: 16px;
  height: 16px;
`;

const LeisDocumentos = () => {
  const [file, setFile] = useState({
    file: "",
    date: "",
    user: "",
  });
  const dataAtual = new Date();
  const { userAuthData } = useContext(AuthContext);
  const nameUser =
    Object.keys(userAuthData).length > 0 ? userAuthData.nome : "N/D";

  const handleUpload = (event) => {
    const uploadedFile = event.target.files[0];
    const date =
      dataAtual.getDate() +
      " de " +
      getMonthName(dataAtual.getMonth()) +
      " de " +
      dataAtual.getFullYear();
    if (uploadedFile) {
      setFile((prevValues) => ({
        ...prevValues,
        file: uploadedFile,
        date: date,
        user: nameUser,
      }));
    }
  };

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber);
    return date.toLocaleString("pt-BR", { month: "short" });
  };

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      style={{ color: "#515C70" }}
      className={"container_conteudo conteudo_pagina"}
    >
      {/*<button onClick={() => console.log(nameUser)}>console</button>*/}
      <ToastContainer />
      <HeaderCadastros
        NomePaginaTitulo={"Leis e Documentos"}
        PaginaSubtitulo={"Cadastro"}
        BotaoSaveAtivo={false}
        OpcoesDeArquivo={true}
      />

      <Row className="row_form_2 mt-1">
        <Row>
          <CCol>
            <FloatingLabelInput
              new={true}
              tipo="input_select"
              placeholder={
                "Documento de Atualização Orçamentária “Lei Orçamentária”"
              }
              options={[
                {
                  label:
                    "Documento de Atualização Orçamentária “Lei Orçamentária”",
                  value: 1,
                },
              ]}
            />
          </CCol>
        </Row>
        <Row className="mt-3">
          <CCol md={2}>
            <FloatingLabelInput placeholder={"Lei Número"} />
          </CCol>
          <CCol>
            <FloatingLabelInput
              new={true}
              tipo="input_select"
              placeholder={"Tipo de Crédito"}
              options={[
                {
                  label: "Tipo de Crédito",
                  value: 1,
                },
              ]}
            />
          </CCol>
          <CCol md={2}>
            <InputDateRelatorio />
          </CCol>
        </Row>
        <Row className="mt-3">
          <CCol>
            <Row>
              <CCol style={{ marginLeft: "3.5px" }}>
                <FloatingLabelInput placeholder={"Percentual Autorizado"} />
              </CCol>
            </Row>
            <Row className="mt-3">
              <CCol style={{ marginLeft: "3.5px" }}>
                <FloatingLabelInput placeholder={"Valor Autorizado"} />
              </CCol>
            </Row>
          </CCol>
          <CCol>
            <FloatingLabelInput
              placeholder="Destinação"
              label="Destinação"
              as="textarea"
              tipo="text_area"
              style={{ minHeight: "92px" }}
            />
          </CCol>
        </Row>
      </Row>
      <Row className="row_form_2 mt-1">
        <CCol>
          <Row
            style={{
              backgroundColor: "#F7F7F7",
              borderRadius: "10px",
              padding: "8px 5px",
              fontSize: "12px",
              color: "#888A8E",
              fontWeight: "500",
              margin: "0 0px",
            }}
          >
            <CCol>
              <span>
                Autorizado:{" "}
                <span style={{ fontWeight: "600", marginLeft: "15px" }}>
                  R$ 150.000,00
                </span>
              </span>
            </CCol>
            <CCol>
              <span>
                Utilizado:{" "}
                <span style={{ fontWeight: "600", marginLeft: "15px" }}>
                  R$ 150.000,00
                </span>
              </span>
            </CCol>
            <CCol>
              <span>
                Percentual Utilizado:{" "}
                <span style={{ fontWeight: "600", marginLeft: "15px" }}>
                  45%
                </span>
              </span>
            </CCol>
            <CCol>
              <span>
                Anulação:{" "}
                <span style={{ fontWeight: "600", marginLeft: "15px" }}>
                  R$ 150.000,00
                </span>
              </span>
            </CCol>
            <CCol>
              <span>
                Saldo:{" "}
                <span style={{ fontWeight: "600", marginLeft: "15px" }}>
                  R$ 150.000,00
                </span>
              </span>
            </CCol>
          </Row>
        </CCol>
      </Row>
      <Row className="mt-3">
        <CCol md={8} style={{ paddingRight: "25px" }}>
          <Row
            className="row_form_2"
            style={{
              marginLeft: "-8px",
              borderRadius: "5px 5px 0 0",
              padding: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CCol
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon="mdi:drive-document"
                  style={{ color: "#699BF7", margin: "0 10px 0 10px" }}
                  width={26}
                />
                <span style={{ fontWeight: "600" }}>Modelo</span>
              </div>
            </CCol>
            <CCol
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              <div>
                <label htmlFor="file-upload">
                  <Icon
                    icon="octicon:upload-16"
                    style={{
                      color: "#515C70",
                      margin: "3px 10px",
                      cursor: "pointer",
                    }}
                    width={24}
                  />
                </label>
                <input
                  id="file-upload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleUpload}
                />
                <Icon
                  icon="octicon:download-16"
                  style={{
                    color: "#515C70",
                    margin: "3px 10px",
                  }}
                  width={24}
                />
              </div>
            </CCol>
          </Row>
          <Row
            className="row_form_2"
            style={{
              marginLeft: "-8px",
              borderRadius: "0 0 5px 5px",
              marginTop: "2px",
              paddingLeft: "18px",
            }}
          >
            <CCol md={1} style={{ width: "fit-content" }}>
              <PDFBox></PDFBox>
            </CCol>
            <CCol>
              <Row className="mt-2" style={{ display: "none" }}>
                <CCol>
                  <span style={{ fontWeight: "500" }}>
                    {file.file.name && file.file.name}
                  </span>
                </CCol>
              </Row>
              <Row className="mt-2">
                <CCol>
                  <span style={{ fontWeight: "500" }}>
                    {"Documento Editado"}
                  </span>
                </CCol>
              </Row>
              <Row className="mt-4">
                <CCol>
                  <span style={{ whiteSpace: "pre-wrap", fontWeight: "500" }}>
                    {`por: ${file.user}\n${file.date && file.date}`}
                  </span>
                </CCol>
              </Row>
            </CCol>
          </Row>
        </CCol>
        <CCol md={4}>
          <Row
            className="row_form_2"
            style={{
              margin: "0 -8px 0 0",
              borderRadius: "5px 5px 0 0",
              padding: "5px",
            }}
          >
            <CCol
              style={{
                display: "flex",
                alignItems: "center",
                padding: "3px 5px",
              }}
            >
              <span style={{ fontWeight: "600" }}>Assinaturas</span>
            </CCol>
          </Row>
          <Row
            className="row_form_2"
            style={{
              margin: "2px -8px 0 0",
              borderRadius: "0 0 5px 5px",
              paddingBottom: "131.5px",
            }}
          >
            <CCol style={{ display: "flex", alignItems: "center" }}>
              <Checkbox />{" "}
              <span style={{ marginLeft: "10px", fontWeight: "500" }}>
                Prefeito
              </span>
            </CCol>
          </Row>
        </CCol>
      </Row>
    </Container>
  );
};

export default LeisDocumentos;
