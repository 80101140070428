import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../components/Grid/InputSvg";
import ButtonsAcoesListas from "../../../styles/StyledComponents/ButtonsAcoesListas";
import api from "../../../utils/api";

function GerenciadorFinanceiro() {
  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itensFiltrados, setItensFiltrados] = useState([]);
  const [loadingLista, setLoadingLista] = useState(true);

  axios.defaults.headers.common["Authorization"] = null;

  useEffect(() => {
    api
      .get("cliente")
      .then((response) => {
        setItens(response.data.slice().reverse());
        setLoadingLista(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = itens.filter((item) => {
      return item.unidadeGestora.razaoSocial
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    setItensFiltrados(filteredItems);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        withRow={true}
        TituloPagina={"Financeiro"}
        SubTituloPagina={"Consulta"}
        ToPag={"/acesso-interno/gerenciador-clientes"}
      />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loadingLista ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loadingLista ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Estado</TituloTable>
              <TituloTable>Cidade</TituloTable>
              <TituloTable>Entidade Gestora</TituloTable>
              <TituloTable>Sistemas</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td>{item.pessoaJuridica.endereco.estado}</td>
                    <td>{item.pessoaJuridica.endereco.municipio}</td>
                    <td>{item.unidadeGestora.razaoSocial}</td>
                    <td>
                      <div>
                        {item.modulosSistema.map((sistema) => {
                          if (sistema.modulo === "Planejamento") {
                            return (
                              <Icon
                                icon="healthicons:p-negative"
                                color="#e79900"
                                height="19"
                                style={{
                                  borderRadius: "2px",
                                  marginRight: "7px",
                                }}
                              />
                            );
                          } else if (sistema.modulo === "Financeiro") {
                            return (
                              <Icon
                                icon="healthicons:f-negative"
                                color="#2F7716"
                                height="19"
                                style={{
                                  borderRadius: "2px",
                                  marginRight: "7px",
                                }}
                              />
                            );
                          } else {
                            return (
                              <Icon
                                icon="healthicons:c-negative"
                                color="#fc77e6"
                                height="19"
                                style={{
                                  borderRadius: "2px",
                                  marginRight: "7px",
                                }}
                              />
                            );
                          }
                        })}
                      </div>
                    </td>

                    <td>
                      <ButtonsAcoesListas />
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td>{item.pessoaJuridica.endereco.estado}</td>
                    <td>{item.pessoaJuridica.endereco.municipio}</td>
                    <td>{item.unidadeGestora.razaoSocial}</td>
                    <td>
                      <div>
                        {item.modulosSistema.map((sistema) => {
                          if (sistema.modulo === "Planejamento") {
                            return (
                              <Icon
                                icon="healthicons:p-negative"
                                color="#e79900"
                                height="19"
                                style={{
                                  borderRadius: "2px",
                                  marginRight: "7px",
                                }}
                              />
                            );
                          } else if (sistema.modulo === "Financeiro") {
                            return (
                              <Icon
                                icon="healthicons:f-negative"
                                color="#2F7716"
                                height="19"
                                style={{
                                  borderRadius: "2px",
                                  marginRight: "7px",
                                }}
                              />
                            );
                          } else {
                            return (
                              <Icon
                                icon="healthicons:c-negative"
                                color="#fc77e6"
                                height="19"
                                style={{
                                  borderRadius: "2px",
                                  marginRight: "7px",
                                }}
                              />
                            );
                          }
                        })}
                      </div>
                    </td>

                    <td>
                      <ButtonsAcoesListas />
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default GerenciadorFinanceiro;
