import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useCurrentYear } from "../../hooks/useCurrentYear";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useLocation, useNavigate } from "react-router-dom";

const SelectContainer = styled.div`
  position: relative;
  margin-right: 17px;
`;

const BoxSelect = styled.div`
  width: 100%;
  padding: 0px 10px;
  padding-right: 25px;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fafafa;
  box-shadow: 0px 2px 2px #9399a4;
  border-radius: 5px;
  cursor: pointer;

  span {
    color: #515c70;
    font-weight: 500;
  }
`;

const ArrowContainer = styled.div`
  position: absolute;
  right: 0;
`;

const BoxOptions = styled.div`
  position: absolute;
  background-color: #fafafa;
  width: 100%;
  padding: 3px 0px;

  border-radius: 5px;
  border: 1px solid #ccc;
`;

const Option = styled.div`
  color: #5f5f5f;
  margin: 0;
  width: 100%;
  padding: 5px;
  cursor: pointer;
  background-color: ${({ $selected }) => ($selected ? "#e7e7e7" : "")};

  :hover {
    background-color: #e7e7e7;
  }
`;

const options = [2024, 2025];

const SeletorAnoHeader = () => {
  //contexts
  const { currentYear, setCurrentYear } = useCurrentYear();

  //states
  const [openedOptions, setOpenedOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  //refs
  const containerRef = useRef();

  //hooks
  const navigate = useNavigate();
  const location = useLocation();

  const isOptionSelected = (valor) => {
    const valorInt = parseInt(valor);

    return currentYear === valorInt && !selectedOption;
  };

  const makeNavigateUrl = () => {
    const pathPrincipal = location.pathname.split("/")[1];
    switch (pathPrincipal.toLowerCase()) {
      case "acesso-interno":
        return "/home-test";
      case "planejamento":
        return "/planejamento/inicio";
      default:
        return "/";
    }
  };

  const handleSelectOption = (valor) => {
    setCurrentYear(valor);
    setOpenedOptions(false);

    const navigateUrl = makeNavigateUrl();
    navigate(navigateUrl);
  };

  useEffect(() => {
    const onClickOutContainer = (e) => {
      if (containerRef && !containerRef.current.contains(e.target)) {
        setOpenedOptions(false);
        setSelectedOption(null);
      }
    };

    window.addEventListener("click", onClickOutContainer);

    return () => window.removeEventListener("click", onClickOutContainer);
  }, []);

  return (
    <SelectContainer ref={containerRef}>
      <BoxSelect onClick={() => setOpenedOptions((prev) => !prev)}>
        <span>{currentYear}</span>
        <ArrowContainer>
          <Icon
            icon="iconamoon:arrow-down-2-light"
            fontSize={26}
            style={{ margin: "0" }}
          />
        </ArrowContainer>
      </BoxSelect>

      {openedOptions && (
        <BoxOptions>
          {options.map((item, index) => (
            <Option
              $selected={
                isOptionSelected(item) || selectedOption === parseInt(item)
              }
              onMouseEnter={() => setSelectedOption(parseInt(item))}
              onClick={() => handleSelectOption(parseInt(item))}
            >
              {item}
            </Option>
          ))}
        </BoxOptions>
      )}
    </SelectContainer>
  );
};

export default SeletorAnoHeader;
