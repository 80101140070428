export const tiposDeUsuario = [
  { label: "Master", value: 1 },
  { label: "Gestor", value: 2 },
  { label: "Suporte", value: 3 },
  { label: "Administrador", value: 4 },
  { label: "Usuário", value: 5 },
  { label: "Externo", value: 6 },
];

export const tiposDeUsuarioInterno = [
  { label: "Administrador", value: 4 },
  { label: "Usuário", value: 5 },
  { label: "Externo", value: 6 },
];
