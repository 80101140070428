export const mockedData = [
  {
    infoUnidade: {
      receitas: [
        {
          receitaInfos: {
            nameReceita: "Receitas Correntes ( I )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "100",
            },
          },
          despesas: [
            {
              titulo: "Impostos, Taxas e Contribuições de Melhoria ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Contribuições ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Receita Patrimonial ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Transferências Correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Outras Receitas Correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
        {
          receitaInfos: {
            nameReceita: "Receitas de Capital ( II )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Impostos, Taxas e Contribuições de Melhoria ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Contribuições ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Receita Patrimonial ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Transferências Correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Outras Receitas Correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
        {
          receitaInfos: {
            nameReceita: "Receitas Financeiras ( IV )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Aplicação Financeira ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Operações de Crédito ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Alienação de Bens ",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Amortização de Empréstimos",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Outras Receitas de Capital",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
      ],
      despesas: [
        {
          receitaInfos: {
            nameReceita: "Despesas Correntes ( I )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Pessoal e Encargos Sociais",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Outras Despesas Correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Juros e Encargos da Dívida",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
        {
          receitaInfos: {
            nameReceita: "Despesas de Capital ( II )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Investimentos",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Inversões Financeiras",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Amortizações de Empréstimos",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
        {
          receitaInfos: {
            nameReceita: "Reserva de Contingência ( III )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [],
        },
        {
          receitaInfos: {
            nameReceita: "Despesas Financeiras ( V )",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Juros e Encargos da Dívida",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Amortizações de Empréstimos",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Concessão de Empréstimos",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Outras despesas correntes",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
      ],
      especificacoes: [
        {
          receitaInfos: {
            nameReceita: "Receitas Total",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Receitas Primárias",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Receitas Financeiras ( I )",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
        {
          receitaInfos: {
            nameReceita: "Despesas Total",
            totalLdo: "25.000,00",
            totalPloa: "25.000,00",
            diferenca: {
              TotalReal: "25.000,00",
              porcentagem: "25",
            },
          },
          despesas: [
            {
              titulo: "Despesas Primárias",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
            {
              titulo: "Despesas Financeiras ( I )",
              despesaInfos: {
                totalLdo: "25.000,00",
                totalPloa: "25.000,00",
                diferenca: {
                  TotalReal: "25.000,00",
                  porcentagem: "25",
                },
              },
            },
          ],
        },
      ],
    },
  },
];
