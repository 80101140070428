import React, { useState } from "react";
import {
  Block,
  DivInternaBlock,
  LabelCheck,
  TituloPermissao,
} from "./UsuariosPage";
import { Row, Carousel } from "react-bootstrap";
import { menusGerenciador, acoesMenusGerenciador } from "./ArraysCheckBoxes";

const CheckBoxesPermissoes = ({
  gerenciaSistemas,
  gerenciaAcoes,
  gerenciaMenu,
  arraySistemas,
  valuesUser,
  setValuesUser,
  acoesValues,
}) => {
  const [indexCarouselGerenciadorAcoes, setIndexCarouselGerenciadorAcoes] =
    useState(0);
  const [indexCarouselPlanejamentoAcoes, setIndexCarouselPlanejamentoAcoes] =
    useState(0);

  const handleSelectGerenciadorAcoes = (selectedIndex, e) => {
    setIndexCarouselGerenciadorAcoes(selectedIndex);
  };

  const handleSelectPlanejamentoAcoes = (selectedIndex, e) => {
    setIndexCarouselPlanejamentoAcoes(selectedIndex);
  };

  const gerenciaPermissoesSistemas = (valor) => {
    if (
      valuesUser.clientes[0].permissoes.length > 0 &&
      valuesUser.clientes[0].permissoes.some(
        (permissoes) => permissoes.sistemaId === valor
      )
    ) {
      console.log("esse valor ja existe");
      const arraySemElemento = valuesUser.clientes[0].permissoes.filter(
        (permissoes) => permissoes.sistemaId !== valor
      );

      setValuesUser((prevValues) => ({
        ...prevValues,
        clientes: [
          {
            ...prevValues.clientes[0],
            permissoes: arraySemElemento,
          },
        ],
      }));
    } else {
      setValuesUser((prevValues) => ({
        ...prevValues,
        clientes: [
          {
            ...prevValues.clientes[0],
            permissoes: [
              ...prevValues.clientes[0].permissoes,
              {
                sistemaId: valor,
                menuId: "",
                acoes: [],
              },
            ],
          },
        ],
      }));
    }
  };

  //Funcao para gerenciar os Menus que o usuario ira ter acesso em cada sistemaId
  const gerenciaPermissoesMenusSistemas = (valor, numeroSistema) => {
    if (valuesUser.clientes[0].permissoes.length > 0) {
      const indice = valuesUser.clientes[0].permissoes.findIndex(
        (permissao) =>
          permissao.sistemaId === numeroSistema && permissao.menuId === valor
      );

      console.log("vamos ver se presta");
      if (indice !== -1) {
        const arrayLimparMenuExistente = valuesUser.clientes[0].permissoes;
        const arrayExcluirObjetoSemMenu = arrayLimparMenuExistente.filter(
          (permissao) =>
            permissao.sistemaId === numeroSistema && permissao.menuId !== valor
        );
        console.log(arrayLimparMenuExistente);
        console.log("O valor ja existe");
        setValuesUser((prevValues) => ({
          ...prevValues,
          clientes: [
            {
              ...prevValues.clientes[0],
              permissoes: [...arrayExcluirObjetoSemMenu],
            },
          ],
        }));
      } else {
        console.log("ou seja o valor nao existe, passou a existir agora");
        let indiceAtualizacao = valuesUser.clientes[0].permissoes;

        console.log(indiceAtualizacao);
        indiceAtualizacao = indiceAtualizacao.findIndex(
          (item) => item.sistemaId === numeroSistema && item.menuId === ""
        );
        console.log("atualize nesse index" + indiceAtualizacao);

        let arrayFirstAddMenu = valuesUser.clientes[0].permissoes;
        arrayFirstAddMenu = arrayFirstAddMenu.filter(
          (item) => item.sistemaId === numeroSistema && item.menuId !== ""
        );

        if (indiceAtualizacao === -1) {
          setValuesUser((prevValues) => ({
            ...prevValues,
            clientes: [
              {
                ...prevValues.clientes[0],
                permissoes: [
                  ...arrayFirstAddMenu,
                  {
                    //Aqui terá que passar por uma mudanca pra saber qual indice iremos usar, nao será o 0 sempre
                    ...prevValues.clientes[0].permissoes[0],
                    menuId: valor,
                    acoes: [],
                  },
                ],
              },
            ],
          }));
        } else {
          setValuesUser((prevValues) => ({
            ...prevValues,
            clientes: [
              {
                ...prevValues.clientes[0],
                permissoes: [
                  ...arrayFirstAddMenu,
                  {
                    ...prevValues.clientes[0].permissoes[indiceAtualizacao],
                    menuId: valor,
                  },
                ],
              },
            ],
          }));
        }
      }
    }
  };

  //Funcao para gerenciar cada Acao que o usuario poderá ter em cada menu do respectivo sistemaId (adicionar, excluir...)
  const gerenciaAcoesMenus = (valorDaAcao, numeroSistema, numeroMenu) => {
    if (
      valuesUser.clientes[0].permissoes.some(
        (permissao) =>
          permissao.sistemaId === numeroSistema &&
          permissao.menuId === numeroMenu
      )
    ) {
      //Checa se o array permissoes tem algum item
      if (valuesUser.clientes[0].permissoes.length > 0) {
        //Acha em qual indice do array está as informacoes que queremos atualizar
        const indice = valuesUser.clientes[0].permissoes.findIndex(
          (permissao) =>
            permissao.sistemaId === numeroSistema &&
            permissao.menuId === numeroMenu
        );
        console.log("Indice encontrado: " + indice);
        if (indice !== -1) {
          console.log(valuesUser.clientes[0].permissoes[indice]);
          let arrayAdicionaAcao = valuesUser.clientes[0].permissoes;
          console.log("array Adiciona Acao");
          //Checa se não existem acoes no array de acoes no objeto
          if (arrayAdicionaAcao[indice].acoes.length === 0) {
            arrayAdicionaAcao[indice].acoes = [valorDaAcao];
            console.log(arrayAdicionaAcao);
            setValuesUser((prevValues) => ({
              ...prevValues,
              clientes: [
                { ...prevValues.clientes[0], permissoes: arrayAdicionaAcao },
              ],
            }));
          } else {
            //Logica para quando ja existirem acoes no array de acoes no objeto
            if (arrayAdicionaAcao[indice].acoes.includes(valorDaAcao)) {
              console.log("acao ja existe");
              let arrayExcluirAcao = arrayAdicionaAcao;
              arrayExcluirAcao[indice].acoes = arrayExcluirAcao[
                indice
              ].acoes.filter((acao) => acao !== valorDaAcao);
              console.log(arrayExcluirAcao);

              setValuesUser((prevValues) => ({
                ...prevValues,
                clientes: [
                  { ...prevValues.clientes[0], permissoes: arrayExcluirAcao },
                ],
              }));
            } else {
              console.log("acao nao existe");
              arrayAdicionaAcao[indice].acoes = [
                ...arrayAdicionaAcao[indice].acoes,
                valorDaAcao,
              ];

              setValuesUser((prevValues) => ({
                ...prevValues,
                clientes: [
                  { ...prevValues.clientes[0], permissoes: arrayAdicionaAcao },
                ],
              }));
            }
          }
        }
      }
    } else {
      console.log("tem nada aqui nao");
    }
  };

  return (
    <Row className=" d-flex row_form_2 mt-1">
      <Block md={4}>
        <DivInternaBlock>
          <TituloPermissao>Sistema</TituloPermissao>
          {/* Sistemas */}
          <LabelCheck
            label="Gerenciador"
            onChange={(e) => {
              gerenciaSistemas(parseInt(e.target.value), e.target.checked);
              console.log("check");
              gerenciaPermissoesSistemas(parseInt(e.target.value));
            }}
            value={1}
          />
          <LabelCheck
            onChange={(e) => {
              gerenciaSistemas(parseInt(e.target.value), e.target.checked);
              console.log("check");
              gerenciaPermissoesSistemas(parseInt(e.target.value));
            }}
            value={2}
            label="Planejamento"
          />
          <LabelCheck
            onChange={(e) =>
              gerenciaSistemas(parseInt(e.target.value), e.target.checked)
            }
            onClick={(e) => {}}
            value={3}
            label="Contabilidade"
          />
          <LabelCheck
            onChange={(e) =>
              gerenciaSistemas(parseInt(e.target.value), e.target.checked)
            }
            value={1}
            label="Financeiro"
          />
          <LabelCheck
            onChange={() => {
              console.log(arraySistemas);
              console.log(valuesUser);
            }}
            label="Gestor"
          />
          <LabelCheck label="Folha de Pagamento" />
        </DivInternaBlock>
      </Block>
      {/*Menus Gerenciador */}
      {arraySistemas.includes(1) && (
        <Block md={4}>
          <DivInternaBlock>
            <TituloPermissao>Menu Gerenciador</TituloPermissao>
            {menusGerenciador.map((item, index) => (
              <LabelCheck
                onChange={(e) => {
                  gerenciaMenu(parseInt(e.target.value), e.target.checked);
                  gerenciaPermissoesMenusSistemas(parseInt(e.target.value), 1);
                }}
                key={index}
                value={item.value}
                label={item.titulo}
              />
            ))}
          </DivInternaBlock>
        </Block>
      )}
      {/* Acoes Menus Gerenciador */}
      {arraySistemas.includes(1) && (
        <Block md={4}>
          <DivInternaBlock>
            <Carousel
              activeIndex={indexCarouselGerenciadorAcoes}
              onSelect={handleSelectGerenciadorAcoes}
              controls={true}
              interval={null}
              indicators={false}
            >
              {acoesMenusGerenciador.map((item, index) => (
                <Carousel.Item key={index}>
                  <TituloPermissao>
                    Ações Gerenciador - {item.titulo}
                  </TituloPermissao>
                  {item.acoes.map((acao, i) => (
                    <LabelCheck
                      onChange={(e) => {
                        gerenciaMenu(
                          parseInt(e.target.value),
                          e.target.checked
                        );
                        gerenciaAcoesMenus(
                          parseInt(e.target.value),
                          acao.sistemaPertencente,
                          acao.menuPertecente
                        );
                      }}
                      key={i}
                      value={acao.value}
                      label={acao.nomeAcao}
                    />
                  ))}
                </Carousel.Item>
              ))}
            </Carousel>
          </DivInternaBlock>
        </Block>
      )}

      {arraySistemas.includes(2) && (
        <Block md={4}>
          <DivInternaBlock>
            <TituloPermissao>Menu Planejamento</TituloPermissao>
            <LabelCheck
              onChange={(e) =>
                gerenciaMenu(parseInt(e.target.value), e.target.checked)
              }
              value={1}
              label="Dashboard"
            />
            <LabelCheck
              onChange={(e) =>
                gerenciaMenu(parseInt(e.target.value), e.target.checked)
              }
              value={2}
              label="Clientes"
            />
            <LabelCheck
              onChange={(e) =>
                gerenciaMenu(parseInt(e.target.value), e.target.checked)
              }
              value={3}
              label="Usuários"
            />
            <LabelCheck
              onChange={(e) =>
                gerenciaMenu(parseInt(e.target.value), e.target.checked)
              }
              value={4}
              label="Financeiro"
            />
            <LabelCheck
              onChange={(e) =>
                gerenciaMenu(parseInt(e.target.value), e.target.checked)
              }
              value={5}
              label="Suporte"
            />
            <LabelCheck
              onChange={(e) =>
                gerenciaMenu(parseInt(e.target.value), e.target.checked)
              }
              value={6}
              label="teste2"
            />
          </DivInternaBlock>
        </Block>
      )}
      {arraySistemas.includes(2) && (
        <Block md={4}>
          <DivInternaBlock>
            <Carousel
              activeIndex={indexCarouselPlanejamentoAcoes}
              onSelect={handleSelectPlanejamentoAcoes}
              controls={true}
              interval={null}
              indicators={false}
            >
              {acoesValues.map((item, index) => (
                <Carousel.Item key={index}>
                  <TituloPermissao>{item.titulo}</TituloPermissao>
                  {item.valores.map((valor, i) => (
                    <LabelCheck
                      key={i}
                      onChange={(e) =>
                        gerenciaAcoes(
                          parseInt(e.target.value),
                          e.target.checked
                        )
                      }
                      value={valor.value}
                      label={valor.nome}
                    />
                  ))}
                </Carousel.Item>
              ))}
            </Carousel>
          </DivInternaBlock>
        </Block>
      )}

      {arraySistemas.includes(3) && (
        <Block md={4}>
          <DivInternaBlock>
            <Carousel
              activeIndex={indexCarouselPlanejamentoAcoes}
              onSelect={handleSelectPlanejamentoAcoes}
              controls={true}
              interval={null}
              indicators={false}
            >
              {acoesValues.map((item, index) => (
                <Carousel.Item key={index}>
                  <TituloPermissao>{item.titulo}</TituloPermissao>
                  {item.valores.map((valor, i) => (
                    <LabelCheck
                      key={i}
                      onChange={(e) =>
                        gerenciaAcoes(
                          parseInt(e.target.value),
                          e.target.checked
                        )
                      }
                      value={valor.value}
                      label={valor.nome}
                    />
                  ))}
                </Carousel.Item>
              ))}
            </Carousel>
          </DivInternaBlock>
        </Block>
      )}
    </Row>
  );
};

export default CheckBoxesPermissoes;
