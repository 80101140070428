import { useState } from "react";

const useSelectAbas = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Cadastros",
    numeroAba: 0,
  });

  return { abaSelecionada, setAbaSelecionada };
};

export default useSelectAbas;
