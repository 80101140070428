import React from "react";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";

const StyledInputSearch = styled.input`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ced4da;
  outline: none;
  color: #515c70;
  font-weight: 500;
  padding: 6px 12px;
  padding-right: 27px;
`;

const ContainerInputSearch = styled.div`
  position: relative;
`;

const InputSearch = (props) => {
  return (
    <ContainerInputSearch>
      <SearchIcon
        sx={{
          top: "52%",
          transform: "translateY(-50%)",
          right: "4px",
          position: "absolute",
          color: "#515C70",
        }}
      />
      <StyledInputSearch {...props} />
    </ContainerInputSearch>
  );
};

export default InputSearch;
