import React, { useState } from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { NotaFiscal } from "./NotaFiscal";
import { SaldosAba } from "./SaldosAba";
import { OrdemCronologicaAba } from "./OrdemCronologicaAba";
import { EfdReinfAba } from "./EfdReinfAba";

const LiquidacaoDeEmpenho = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Nota Fiscal",
    numeroAba: 0,
  });

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        PaginaSubtitulo={"Cadastro"}
        NomePaginaTitulo={"Liquidação de Empenho - LE"}
      />

      <RowForm>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Empenho"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            label="Liquidação"
            placeholder="Liquidação"
          />
        </CCol>
        <CCol md={2}>
          <InputData />
        </CCol>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput placeholder="Ação" label="Ação" />
        </CCol>
        <CCol md={4}>
          <InputFormatRealBrasileiro iconReal />
        </CCol>
      </RowForm>

      <RowInfosAcao>
        <CCol>
          <p>
            Unidade Orçamentária:{" "}
            <span>02.050 - Secretaria Municipal de Educação</span>
          </p>
          <p>
            Elemento de Despesa:{" "}
            <span>
              3.3.90.39 - OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA
            </span>
          </p>
          <p>
            Subelemento:{" "}
            <span>
              17 - MANUTENÇÃO E CONSERVAÇÃO DE MÁQUINAS E EQUIPAMENTOS
            </span>
          </p>
          <p>
            Fonte de Recursos - CO:{" "}
            <span>
              1.500.1001 - Recursos não Vinculados de Impostos - Identificação
              das despesas com manutenção e desenvolvimento do ensino{" "}
            </span>
          </p>
          <p>
            Modalidade: <span>Ordinário</span>
          </p>
        </CCol>
      </RowInfosAcao>

      <RowForm className="mt-1">
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Responsável pelo Atesto"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={2}>
          <InputData />
        </CCol>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={[
          "Nota Fiscal",
          "Produtos e Aquisição",
          "Ordem Cronológica",
          "EFD-Reinf",
          "Saldos",
        ]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Nota Fiscal" && <NotaFiscal />}
      {abaSelecionada.nomeAba === "Ordem Cronológica" && (
        <OrdemCronologicaAba />
      )}
      {abaSelecionada.nomeAba === "EFD-Reinf" && <EfdReinfAba />}
      {abaSelecionada.nomeAba === "Saldos" && <SaldosAba />}

      <div className="p-3"></div>
    </Container>
  );
};

export default LiquidacaoDeEmpenho;
