import { Document, Packer, PageOrientation } from "docx";
import { DocxHeader } from "../../../../utils/RelatorioDocx/DocxHeader";
import defaultImg64 from "../../../../utils/RelatorioDocx/defaultImg64";
import { DocxTableQDD } from "./DocxTableQDD";
import { DocxFooter } from "../../../../utils/RelatorioDocx/DocxFooter";

export const generateDocxQdd = async (
  { pdfContent },
  title,
  logo,
  pageSize = 20,
  double = false,
  idsSelected
) => {
  const {
    dadosRelatorio,
    optionsHeader,
    columnWidth,
    userData: { currentClient, userAuthData },
    dateRelatorio: { year },
    selectBoxesOptionsAbaOptions
  } = pdfContent;
  const font = "Roboto";

  if (!dadosRelatorio) return null;

  const {
    idsAcaoGovernamentalSelected,
    idsElementoDespesaSelected,
    idsFonteDeRecursoSelected,
    idsFuncaoSelected,
    idsSubfuncaoSelected,
    idsProgramaSelected,
    idsUnidadeOrcamentariaSelected,
    esferaSelected,
  } = idsSelected;

  const filterData = {
    "Ação Governamental": idsAcaoGovernamentalSelected,
    "Elemento da Despesa": idsElementoDespesaSelected,
    "Fonte de Recursos": idsFonteDeRecursoSelected,
    Função: idsFuncaoSelected,
    Subfunção: idsSubfuncaoSelected,
    Programa: idsProgramaSelected,
    "Unidade Orçamentária": idsUnidadeOrcamentariaSelected,
    Esfera: esferaSelected,
  };

  const nameFiltroHeader = Object.keys(filterData)
    .filter((key) => filterData[key].length > 0)
    .join(" - ");

  const doc = new Document({
    sections: [
      {
        properties: {
          page: {
            size: {
              orientation: PageOrientation.PORTRAIT,
            },
            margin: {
              top: 720,
              right: 720,
              bottom: 720,
              left: 720,
            },
          },
        },
        headers: {
          default: DocxHeader({
            logo: logo || defaultImg64,
            nameUnidadeGestora: currentClient.nomeFantasia,
            ano: year,
            title,
            font,
            nameFiltroHeader,
          }),
        },
        children: [
          ...DocxTableQDD({
            columns: optionsHeader,
            data: dadosRelatorio,
            font,
            subTitle: double ? double[0] : "",
            columnWidth: columnWidth,
            idsSelected,
            selectBoxesOptionsAbaOptions
          }),
        ],
        footers: {
          default: DocxFooter({
            userAuthData,
            currentClient,
            font,
          }),
        },
      },
    ],
  });

  const blob = await Packer.toBlob(doc);
  return new Blob([blob], {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });
};
