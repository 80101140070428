import React, { useContext, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";

import { useFetchData } from "../../../../hooks/useFetchData";
import { useFavoritesLocalStorage } from "../../../../hooks/useFavoritesLocalStorage";

import { AuthContext } from "../../../../contexts/AuthContext";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import { OpenedModalsContext } from "../../../../contexts/OpenedModalsContext";

import {
  CCol,
  ContainerToModal,
  RowToModal,
} from "../../../../components/Grid/CCol";
import HeaderCadastros from "./HeaderCadastros";
import PagRelatorioComponente from "../../../../components/ComponenteDeRelatorio/PagRelatorioComponente";
import ButtonSaveModalAutoComplete from "../../../../components/Modais/ButtonSaveModalAutoComplete";
import ModalAutoComplete from "../../../../components/Modais/ModalAutoComplete";
import { PdfQDR } from "./PdfQDR";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

import api from "../../../../utils/api";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { generateFileXls } from "../../../../utils/Gerais/GenerateXls";
import { TestButton } from "../../../../utils/TestButton";
import { generateDocxQdr } from "./generateDocxQdr";

const RelatorioQDR = () => {
  const relatorioName = "Save - Quadro Detalhado Receita";
  const localStorageKey = "save/qdr/favoritos";
  const nameModal = "Favorito QDR";
  const fonteComDeducao = 3;
  const fonteSemDeducao = 1;

  const options = [
    {
      opcao: "Exibir",
      checkBoxes: [
        { label: "Código", value: "codigo", selected: true },
        { label: "Especificação", value: "especificacao", selected: true },
        {
          label: "Valor R$",
          value: "valor",
          selected: true,
        },
      ],
    },
    // {
    //   opcao: "Exibir Fonte De Recurso",
    //   checkBoxes: [
    //     { label: "Exercício", value: "exerciocio", selected: false },
    //     { label: "Fonte de Recurso", value: "fonte de recurso", selected: false },
    //     { label: "Descrição", value: "descrição", selected: false },
    //     {
    //       label: "Valor R$",
    //       value: "valor",
    //       selected: false,
    //     },
    //   ],
    // },
  ];

  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useContext(CurrentYearContext);
  const { userAuthData } = useContext(AuthContext);
  const { setOpenedModals } = useContext(OpenedModalsContext);

  const { data: dataQdrRelatorio } = useFetchData(
    `qdr/relatorio/${currentClient.clienteId}/${currentYear}`
  );

  const { data: dataClientInfos } = useFetchData(
    `cliente/pesquisa/id/${currentClient.clienteId}`
  );

  const [dadosRelatorio, setDadosRelatorio] = useState(null);
  const [readyToPdf, setReadyToPdf] = useState(false);
  const [selectBoxesOptionsAbaPrincipal, setSelectBoxesOptionsAbaPrincipal] =
    useState([]);
  const [pdfData, setPdfData] = useState(null);
  const [idsSelected, setIdsSelected] = useState({});
  const [inputFavoriteValue, setInputFavoriteValue] = useState("");
  const [selectBoxesOptionsAbaOptions, setSelectBoxesOptionsAbaOptions] =
    useState(options);

  const {
    dataFavorites,
    favoritosOptions,
    handleDeleteFavoriteStorage,
    handleSaveFavorite,
  } = useFavoritesLocalStorage(
    selectBoxesOptionsAbaPrincipal,
    localStorageKey,
    setOpenedModals,
    nameModal
  );

  const floatingLabelInputRef = useRef(null);

  const navigate = useNavigate();

  const headerGeral = ["Nr", "Especificações", "Valor R$"];
  const headerGeralComEsfera = ["Nr", "Especificações", "Esfera", "Valor R$"];
  const headerFiltroPorFonte = [
    "Exercício",
    "Fonte De Recurso",
    "Descrição Fonte De Recursos",
    "Valor R$",
  ];
  const headerFiltroFonteEsfera = [
    "Exercício",
    "Fonte De Recurso",
    "Descrição Fonte De Recursos",
    "Esfera",
    "Valor R$",
  ];
  const headerFiltroPorEsfera = ["Esfera", "Valor R$"];

  const uniqueDataFiltered = (data) => {
    const uniqueData = [];
    const seenIds = new Set();

    data.forEach((item) => {
      if (!seenIds.has(item.id)) {
        seenIds.add(item.id);
        uniqueData.push(item);
      }
    });
    return uniqueData;
  };

  useEffect(() => {
    if (
      dataQdrRelatorio &&
      !selectBoxesOptionsAbaPrincipal.some(
        (item) => item.opcao === "Natureza da Receita"
      )
    ) {
      console.log("oi");
      const dataQDR = dataQdrRelatorio.naturezas.filter(
        (item) => item.qdr !== "vazio"
      );

      setSelectBoxesOptionsAbaPrincipal((prev) => [
        ...prev,
        {
          opcao: "Natureza da Receita",
          checkBoxes: dataQDR.map((item) => {
            return {
              label: `${item.nr} - ${item.especificacao}`,
              value: item.id,
              selected: false,
            };
          }),
        },
      ]);
    }
    if (
      dataQdrRelatorio &&
      !selectBoxesOptionsAbaPrincipal.some(
        (item) => item.opcao === "Fonte de Recursos"
      )
    ) {
      console.log("oi");
      const dataQDR = dataQdrRelatorio.naturezas.filter(
        (item) => item.qdr !== "vazio"
      );
      const fontesDeRecursos = dataQDR
        .map((item) => item.qdr.fontesDeRecursos)
        .flat();
      const fontesUnicas = uniqueDataFiltered(fontesDeRecursos);

      setSelectBoxesOptionsAbaPrincipal((prev) => [
        ...prev,
        {
          opcao: "Fonte de Recursos",
          checkBoxes: fontesUnicas.map((item) => ({
            label: `${item.conta} - ${item.titulo}`,
            value: item.id,
            selected: false,
          })),
        },
      ]);
    }
    if (
      dataQdrRelatorio &&
      !selectBoxesOptionsAbaPrincipal.some((item) => item.opcao === "Esfera")
    ) {
      console.log("oi");
      const optionsEsfera = [
        {
          name: "Fiscal",
          id: "01",
        },
        {
          name: "Seguridade",
          id: "02",
        },
      ];

      setSelectBoxesOptionsAbaPrincipal((prev) => [
        ...prev,
        {
          opcao: "Esfera",
          checkBoxes: optionsEsfera.map((item) => ({
            label: item.name,
            value: item.id,
            selected: false,
          })),
        },
      ]);
    }
  }, [dataQdrRelatorio, selectBoxesOptionsAbaPrincipal]);

  const getIdsNaturezasSelected = () => {
    const selectBox = selectBoxesOptionsAbaPrincipal?.find(
      (item) => item.opcao === "Natureza da Receita"
    );
    const selectBoxSelected = selectBox?.checkBoxes.some(
      (item) => item.selected
    );

    if (!selectBoxSelected) {
      return {
        idsNatureza: [],
        naturezaCodigos: [],
      };
    }
    const naturezaIdsSelected = selectBox.checkBoxes
      .filter((item) => item.selected)
      .map((checkBox) => checkBox.value);

    const naturezaSelected = dataQdrRelatorio?.naturezas.filter((item) =>
      naturezaIdsSelected.includes(item.id)
    );

    const naturezaCodigos = naturezaSelected.map((item) => item.nr);

    const idsNatureza = [...new Set(naturezaIdsSelected)];

    return {
      idsNatureza,
      naturezaCodigos,
    };
  };

  const getIdsFontesSelected = () => {
    const selectBox = selectBoxesOptionsAbaPrincipal?.find(
      (item) => item.opcao === "Fonte de Recursos"
    );
    const selectBoxSelected = selectBox?.checkBoxes.some(
      (item) => item.selected
    );

    if (!selectBoxSelected) return [];

    const itensSelecionados = selectBox.checkBoxes
      .filter((item) => item.selected)
      .map((checkBox) => checkBox.value);

    return [...new Set(itensSelecionados)];
  };

  const getEsferaSelected = () => {
    const selectBox = selectBoxesOptionsAbaPrincipal?.find(
      (item) => item.opcao === "Esfera"
    );
    const selectBoxSelected = selectBox?.checkBoxes.some(
      (item) => item.selected
    );

    if (!selectBoxSelected) return [];
    const esferaSelected = selectBox.checkBoxes
      .filter((item) => item.selected)
      .map((checkBox) => checkBox.label);

    return esferaSelected;
  };

  const filtrarReceitas = () => {
    const idsFontesSelected = getIdsFontesSelected();
    const { naturezaCodigos, idsNatureza } = getIdsNaturezasSelected();
    const esferaSelected = getEsferaSelected();

    console.log(esferaSelected);

    setIdsSelected({
      idsFontesSelected,
      idsNatureza,
      esferaSelected,
    });

    if (
      !dataQdrRelatorio?.naturezas.length &&
      !idsFontesSelected.length &&
      !naturezaCodigos.length &&
      !esferaSelected.length
    ) {
      return {
        naturezaIds: [],
        idsFontesFiltrado: [],
        idsEsfera: [],
      };
    }

    const naturezas = dataQdrRelatorio.naturezas.filter(
      (item) => item.qdr !== "vazio"
    );

    const naturezasFiltradas = naturezas.filter((item) => {
      const fontes = item.qdr.fontesDeRecursos.map((fonte) => fonte.id);

      const fontesEsfera = item.qdr.fontesDeRecursos.map(
        (fonte) => fonte.esfera
      );

      const naturezaDaReceita =
        naturezaCodigos.length === 0 || naturezaCodigos.includes(item.nr);

      const fontesDeRecurso =
        idsFontesSelected.length === 0 ||
        fontes.some((fonte) => idsFontesSelected.includes(fonte));

      const fontesDeRecursoEsfera =
        esferaSelected.length === 0 ||
        fontesEsfera.some((fonte) => esferaSelected.includes(fonte));

      return naturezaDaReceita && fontesDeRecurso && fontesDeRecursoEsfera;
    });

    const naturezaIds = naturezasFiltradas.map((item) => item.id);

    const fontesDeRecursosIds = naturezasFiltradas.flatMap((item) =>
      item.qdr.fontesDeRecursos.map((fonte) => fonte.id)
    );

    const idsFontesFiltrado = idsFontesSelected.filter((id) =>
      fontesDeRecursosIds.includes(id)
    );

    return {
      naturezaIds: naturezaCodigos.length ? naturezaIds : [],
      idsFontesFiltrado: idsFontesSelected.length ? idsFontesFiltrado : [],
      idsEsfera: esferaSelected,
    };
  };

  const getDataFiltrosQdr = async () => {
    const { naturezaIds, idsFontesFiltrado, idsEsfera } = filtrarReceitas();
    if (!naturezaIds.length && !idsFontesFiltrado.length && !idsEsfera.length)
      return null;

    const idsNaturezaFormatted = naturezaIds.length
      ? naturezaIds.join(",")
      : "";
    const idsFontesFormatted = idsFontesFiltrado.length
      ? idsFontesFiltrado.join(",")
      : "";

    const idsEsferaFormatted = idsEsfera.length ? idsEsfera.join(",") : "";

    if (!naturezaIds.length && (idsFontesFiltrado.length || idsEsfera.length)) {
      try {
        const dataTitulos = await api.get(
          `qdr/relatorio/fontes-esfera/${currentClient.clienteId}/${currentYear}?fontesId=${idsFontesFormatted}&esferas=${idsEsferaFormatted}`
        );
        return dataTitulos.data;
      } catch (error) {
        console.log("Erro ao buscar fonte de recursos", error);
        return null;
      }
    }

    try {
      const dataTitulos = await api.get(
        `qdr/relatorio/${
          currentClient.clienteId
        }/${currentYear}?naturezaDaReceitaIds=${idsNaturezaFormatted}&fonteDeRecursoIds=${idsFontesFormatted}&esfera=${
          idsEsfera.length > 1 ? "" : idsEsferaFormatted
        }`
      );
      return dataTitulos.data;
      // setDadosRelatorio(dataTitulos.data);
    } catch (error) {
      return null;
      // setDadosRelatorio(null);
    }
  };

  function hasSelectedOption(data) {
    return data[0].checkBoxes.some((checkBox) => checkBox.selected === true);
  }

  const handleGeneratePdf = async () => {
    if (
      hasSelectedOption(selectBoxesOptionsAbaOptions) &&
      hasSelectedOption(selectBoxesOptionsAbaPrincipal)
    ) {
      const dadosRelatorio = await getDataFiltrosQdr();
      setDadosRelatorio(dadosRelatorio);
      setReadyToPdf(true);
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  const handleOpenModal = () => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: nameModal, requisicaoDoModalConcluida: false },
    ]);
  };

  const getReceitas = (dataRelatorio, receitaStartsWith) => {
    const receitas = dataRelatorio
      .filter(
        (natureza) => natureza.qdr !== "vazio" && natureza.qdr?.fontesDeRecursos
      )
      .filter((item) => item.nr.startsWith(receitaStartsWith));

    return receitas;
  };

  const formattedDataTable = (receitas, deducao) => {
    const dataTable = receitas.map((item) => {
      const fontes = item.qdr.fontesDeRecursos
        .filter((fonte) => fonte.tipo === deducao)
        .map(
          (fonteDeducao) => `${fonteDeducao.conta} - ${fonteDeducao.titulo}`
        );

      return {
        "Natureza Da Receita": `${item.nr} - ${item.especificacao}`,
        "Fonte de Recursos": fontes.join(" ; "),
        Valor: `R$ ${FormatValueToLocaleString(parseFloat(item.valor))}`,
      };
    });
    return dataTable;
  };

  const handleGenerateXls = async () => {
    const dadosRelatorio = await getDataFiltrosQdr();
    // setDadosRelatorio(dadosRelatorio);
    // setReadyToXls(true);

    const resolvePromise = new Promise((resolve) => setTimeout(resolve, 1000));
    toast.promise(resolvePromise, {
      pending: "Gerando XLS",
      success: "XLS Gerado Com Sucesso",
      error: "Falha Inesperada ao Gerar XLS",
    });
    const { idsNatureza } = getIdsNaturezasSelected();
    const idsFontesFiltrado = getIdsFontesSelected();
    const idsEsfera = getEsferaSelected();

    console.log(dadosRelatorio);

    if (idsNatureza.length) {
      generateNaturezaDataTableXls(dadosRelatorio);
      return;
    }

    if (idsFontesFiltrado.length) {
      console.log("fontes");
      generateFonteRecursoDataTableXls(dadosRelatorio, idsEsfera);
      return;
    }
    if (idsEsfera.length) {
      console.log("esfera");
      generateEsferaDataTableXls(dadosRelatorio, idsEsfera);
      return;
    }
  };

  const generateNaturezaDataTableXls = (dadosRelatorio) => {
    const receitasCorrentes = getReceitas(dadosRelatorio.naturezas, "1");
    const dataTableReceitasCorrentes = formattedDataTable(
      receitasCorrentes,
      fonteSemDeducao
    );

    const receitasDeCapital = getReceitas(dadosRelatorio.naturezas, "2");
    const dataTableReceitasDeCapital = formattedDataTable(
      receitasDeCapital,
      fonteSemDeducao
    );

    const receitasCorrentesDedutiva = getReceitas(
      dadosRelatorio.naturezasDeducao,
      "1"
    );
    const dataTableReceitasCorrentesDedutiva = formattedDataTable(
      receitasCorrentesDedutiva,
      fonteComDeducao
    );

    const receitasDeCapitalDedutiva = getReceitas(
      dadosRelatorio.naturezasDeducao,
      "2"
    );
    const dataTableReceitasDeCapitalDedutiva = formattedDataTable(
      receitasDeCapitalDedutiva,
      fonteComDeducao
    );

    const tablesNamesRelatorio = [
      "Receitas Correntes",
      "Receitas De Capital",
      "Receitas Correntes - Dedução",
      "Receitas De Capital - Dedução",
    ];
    const dataTables = [
      dataTableReceitasCorrentes,
      dataTableReceitasDeCapital,
      dataTableReceitasCorrentesDedutiva,
      dataTableReceitasDeCapitalDedutiva,
    ].filter((data) => data.length > 0);
    generateFileXls(dataTables, relatorioName, tablesNamesRelatorio);
  };

  const generateFonteRecursoDataTableXls = (dadosRelatorio, idsEsfera) => {
    if (idsEsfera.length) {
      const filtroPorFiscal = idsEsfera.includes("Fiscal");
      const filtroPorSeguridade = idsEsfera.includes("Seguridade");

      const dataTableFonteEsfera = dadosRelatorio.fontes.map((fonte) => ({
        Exercício: fonte.conta[0],
        "Fonte De Recurso": fonte.conta.split(".")[1],
        "Descrição Fonte De Recursos ": fonte.titulo,
        Esfera: `${idsEsfera[0] || ""} ${idsEsfera.length > 1 ? "|" : ""} ${
          idsEsfera[1] || ""
        }`,
        "Valor R$": `${
          filtroPorFiscal ? FormatValueToLocaleString(fonte.totalFiscal) : ""
        } ${idsEsfera.length > 1 ? "|" : ""} ${
          filtroPorSeguridade
            ? FormatValueToLocaleString(fonte.totalSeguridade)
            : ""
        }`,
      }));
      generateFileXls(dataTableFonteEsfera, relatorioName);
      return;
    }

    const dataTableFonte = dadosRelatorio.fontes.map((fonte) => ({
      Exercício: fonte.conta[0],
      "Fonte De Recurso": fonte.conta.split(".")[1],
      "Descrição Fonte De Recursos": fonte.titulo,
      "Valor R$": FormatValueToLocaleString(
        fonte.totalFiscal + fonte.totalSeguridade
      ),
    }));
    generateFileXls(dataTableFonte, relatorioName);
  };

  const generateEsferaDataTableXls = (dadosRelatorio, idsEsfera) => {
    const data = [];

    if (idsEsfera.includes("Fiscal")) {
      data.push({
        Esfera: "Fiscal",
        "Valor R$": FormatValueToLocaleString(
          dadosRelatorio.totais.totalGeralFiscal
        ),
      });
    }

    if (idsEsfera.includes("Seguridade")) {
      data.push({
        Esfera: "Seguridade",
        "Valor R$": FormatValueToLocaleString(
          dadosRelatorio.totais.totalGeralSeguridade
        ),
      });
    }

    if (data.length > 0) {
      generateFileXls(data, relatorioName);
    }
  };

  const handleDeleteFavorite = (optionValue) => {
    const checkBoxesAtualizados = handleDeleteFavoriteStorage(optionValue);
    setSelectBoxesOptionsAbaPrincipal(checkBoxesAtualizados);
    setInputFavoriteValue(null);
  };

  const headerDocx = (idsSelected) => {
    const { idsNatureza, idsFontesFiltrado, idsEsfera } = idsSelected;

    if (idsNatureza.length && idsEsfera.length) {
      return {
        optionsHeader: headerGeralComEsfera,
        columnWidth: [15, 60, 10, 15],
      };
    }

    if (!idsNatureza.length && !idsEsfera.length && idsFontesFiltrado.length) {
      return {
        optionsHeader: headerFiltroPorFonte,
        columnWidth: [10, 10, 65, 15],
      };
    }

    if (!idsNatureza.length && idsEsfera.length && idsFontesFiltrado.length) {
      return {
        optionsHeader: headerFiltroFonteEsfera,
        columnWidth: [10, 10, 55, 10, 15],
      };
    }

    if (!idsNatureza.length && idsEsfera.length && !idsFontesFiltrado.length) {
      return {
        optionsHeader: headerFiltroPorEsfera,
        columnWidth: [50, 50],
      };
    }

    return {
      optionsHeader: headerGeral,
      columnWidth: [15, 70, 15],
    };
  };

  const handleGenerateDocx = async () => {
    const dadosRelatorio = await getDataFiltrosQdr();

    const { idsNatureza } = getIdsNaturezasSelected();
    const idsFontesFiltrado = getIdsFontesSelected();
    const idsEsfera = getEsferaSelected();

    const idsSelected = {
      idsNatureza,
      idsFontesFiltrado,
      idsEsfera,
    };

    const { optionsHeader, columnWidth } = headerDocx(idsSelected);

    const title = "QDR - Quadro Detalhado da Receita";

    const pdfContent = {
      dadosRelatorio,
      columnWidth,
      optionsHeader,
      userData: {
        currentClient,
        userAuthData,
      },
      dateRelatorio: { year: currentYear },
      selectBoxesOptionsAbaOptions,
    };

    if (dadosRelatorio) {
      toast.promise(
        generateDocxQdr(
          { pdfContent },
          title,
          dataClientInfos[0].brasao,
          20,
          [null, null],
          idsSelected
        ).then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = title + ".docx";
          link.click();
          URL.revokeObjectURL(url);
        }),
        {
          pending: "Gerando o documento...",
          success: "Documento gerado com sucesso!",
          error: "Falha ao gerar o documento.",
        }
      );
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  useEffect(() => {
    if (readyToPdf) {
      console.log(idsSelected);

      const pdfData = {
        pdfName: "QDR - Quadro Detalhado da Receita",
        PdfComponent: PdfQDR,
        pdfContent: {
          dadosRelatorio,
          userData: {
            currentClient,
            userAuthData,
            dataClientInfos,
          },
          idsSelected,
          currentYear,
          selectBoxesOptionsAbaOptions,
        },
      };
      setPdfData(pdfData);
      setReadyToPdf(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToPdf, dadosRelatorio]);

  return (
    <>
      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina hide_on_print"
      >
        {/* <PdfQDR pdfContent={PdfData.pdfContent}/> */}
        <ModalAutoComplete
          tituloModal={nameModal}
          Component={
            <ContainerToModal isAModalPage fluid style={{ width: "40vw" }}>
              <RowToModal isAModalPage className="row_form mt-1">
                <CCol md="12">
                  <FloatingLabelInput
                    label={"Nome do Favorito"}
                    placeholder={"Nome do Favorito"}
                    ref={floatingLabelInputRef}
                  />
                </CCol>
              </RowToModal>
              <ButtonSaveModalAutoComplete
                SaveFunction={() =>
                  handleSaveFavorite(floatingLabelInputRef?.current?.value)
                }
                pageTitle={nameModal}
              />
            </ContainerToModal>
          }
        />
        <HeaderCadastros
          PaginaConsulta={"/planejamento/loa/qdr/consulta"}
          NomePaginaTitulo={"QDR - Quadro Detalhado da Receita"}
          PaginaSubtitulo={"Relatório"}
          BotaoNovoAtivo={true}
          BotaoSaveAtivo={false}
          BotaoNovoFunction={() => navigate("/planejamento/loa/qdr/cadastro")}
          OpcoesDeArquivo={true}
          PdfData={pdfData}
          ButtonPDF={handleGeneratePdf}
          ButtonXls={handleGenerateXls}
          ButtonDocx={handleGenerateDocx}
        />
        <ToastContainer />
        {/* <div style={{ height: '200px', width: '100%', backgroundColor: 'red' }}>
          <button onClick={() => getDataFiltrosQdr()}>test</button>
        </div> */}

        <PagRelatorioComponente
          topoEsquerdoOptions={[
            { label: "Relatório", value: null },
            { label: "Relatório", value: 1 },
          ]}
          principalOptions={[
            {
              label: "Natureza da Receita",
              value: "Natureza da Receita",
            },
            {
              label: "Fonte de Recursos",
              value: "Fonte de Recursos",
            },
            {
              label: "Esfera",
              value: "Esfera",
            },
          ]}
          labelsCheckAbaPrincipal={selectBoxesOptionsAbaPrincipal}
          setLabelsCheckAbaPrincipal={setSelectBoxesOptionsAbaPrincipal}
          // handleOptionSelected={handleOptionSelected}
          hasAssinatura={false}
          handleOpenModal={handleOpenModal}
          favoritosOptions={favoritosOptions}
          dataFavorites={dataFavorites}
          handleOptionButton={handleDeleteFavorite}
          inputFavoriteValue={inputFavoriteValue}
          setInputFavoriteValue={setInputFavoriteValue}
          labelsCheckAbaOpcoes={selectBoxesOptionsAbaOptions}
          setLabelsCheckAbaOpcoes={setSelectBoxesOptionsAbaOptions}
          // showOpcoesButton={false}
        />
        <TestButton onClick={() => console.log(dataQdrRelatorio)}>
          QDRDATA
        </TestButton>
      </Container>
    </>
  );
};

export default RelatorioQDR;
