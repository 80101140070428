import React, { useMemo, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../pages/PagesAcessoInterno/Cadastros/Gerenciador_Cadastros_CPF_CNPJ/Cadastros_cpf_cnpj";
import { ButtonIcon } from "../Header/StylesHeader";
import { Icon } from "@iconify/react";
import FloatingLabelInput from "../Grid/FloatingLabelInput";
import { hasAnySelected } from "../../utils/RelatorioDocx/FuncoesRelatorioDocx";

import AbaPrincipalRelatorio from "./AbaPrincipalRelatorio";
import {
  LabelCheckLoa2,
  ParteInferiorContDirMenor,
  ButtonContDirMenor,
  LabelsContMaiorDir,
  TituloColDir,
  ContainerMenorDireita,
  ColMenorDireita,
  ParteInferiorContDirMaior,
  ContainerMaiorDireita,
  ColMaiorDireita,
  MenuTopo,
  MenuTopoMenus,
  ContainerMaiorEsquerda,
  ColMaiorEsquerda,
  ButtonSelectAba,
  LabelCheckLOA,
} from "./StyledCompsRelatorio";
import AbaOpcoesRelatorio from "./AbaOpcoesRelatorio";
import { ReturnDataAtual } from "../../utils/Gerais/ReturnDataAtual";
import { useContext } from "react";
import { CurrentYearContext } from "../../contexts/YearContext";

const PagRelatorioComponente = ({
  principalOptions = [],
  topoEsquerdoOptions = [],
  labelsCheckAbaPrincipal = [],
  labelsCheckAbaOpcoes = [],
  favoritosOptions = [],
  dataFavorites = [],
  setLabelsCheckAbaPrincipal,
  setLabelsCheckAbaOpcoes,
  showOpcoesButton = true,
  showOutrosButton = false,
  hasAssinatura = true,
  hasFavorite = true,
  handleOpenModal,
  handleOptionButton,
  inputFavoriteValue,
  setInputFavoriteValue,
  // handleOptionSelected,
  // dadosRelatorioFiltered = []
  handleYearChange,
  // handleMonthChange
}) => {
  const [abaSelecionada, setAbaSelecionada] = useState(0);
  const { currentYear, setCurrentYear } = useContext(CurrentYearContext);
  const quantityCheckBoxSelected = useMemo(() => {
    if (!labelsCheckAbaPrincipal.length) return 0;
    const selectedItems = labelsCheckAbaPrincipal
      .map((item) => item.checkBoxes.filter((checkBox) => checkBox.selected))
      .flat();
    return selectedItems.length;
  }, [labelsCheckAbaPrincipal]);

  // console.log(dadosRelatorioFiltered);

  const gerenciarSelects = (objeto, option, type) => {
    console.log("objeto e option: ", objeto, option);
    const updateCheckBox = objeto.checkBoxes.map((item) =>
      item.value === option.value ? { ...item, selected: !item.selected } : item
    );

    if (type === 1) {
      const labelsCheckBox = labelsCheckAbaPrincipal.map((item) =>
        item.opcao === objeto.opcao
          ? { ...item, checkBoxes: updateCheckBox }
          : item
      );
      setLabelsCheckAbaPrincipal(labelsCheckBox);
    } else if (type === 2) {
      const labelsCheckBox = labelsCheckAbaOpcoes.map((item) =>
        item.opcao === objeto.opcao
          ? { ...item, checkBoxes: updateCheckBox }
          : item
      );
      setLabelsCheckAbaOpcoes(labelsCheckBox);
    }
  };

  const checkAllBoxes = (objeto, type) => {
    const updateAllCheckBox = objeto.checkBoxes.map((item) => ({
      ...item,
      selected: !item.selected,
    }));

    if (type === 1) {
      const labelsCheckBox = labelsCheckAbaPrincipal.map((item) =>
        item.opcao === objeto.opcao
          ? { ...item, checkBoxes: updateAllCheckBox }
          : item
      );
      setLabelsCheckAbaPrincipal(labelsCheckBox);
    } else if (type === 2) {
      const labelsCheckBox = labelsCheckAbaOpcoes.map((item) =>
        item.opcao === objeto.opcao
          ? { ...item, checkBoxes: updateAllCheckBox }
          : item
      );
      setLabelsCheckAbaOpcoes(labelsCheckBox);
    }
  };

  const handleSelectFavorite = (favoriteName) => {
    console.log(favoriteName);
    const favoriteSelected = dataFavorites.find(
      (fav) => fav.favoriteName === favoriteName
    );
    console.log(favoriteSelected);

    if (!favoriteSelected) return;

    // Atualiza os checkboxes com base no favorito encontrado
    const atualizarCheckboxes = labelsCheckAbaPrincipal.map((group) => {
      // Atualiza os checkBoxes com base nos itens do favorito
      const updatedCheckBoxes = group.checkBoxes.map((checkBox) => {
        // Verifica se o checkBox deve ser marcado como selecionado
        const isSelected = favoriteSelected.favoriteItens.some((item) =>
          item.checkBoxes.some(
            (favCheckBox) => favCheckBox.value === checkBox.value
          )
        );

        return {
          ...checkBox,
          selected: isSelected,
        };
      });

      return {
        ...group,
        checkBoxes: updatedCheckBoxes,
      };
    });
    setLabelsCheckAbaPrincipal(atualizarCheckboxes);
  };

  const getUniqueObjects = (items) => {
    const seen = new Set();
    return items.filter((item) => {
      if (seen.has(item.opcao)) {
        return false;
      }
      seen.add(item.opcao);
      return true;
    });
  };

  return (
    <>
      {/*Form da Página*/}
      <Row style={{ borderRadius: "0 0 5px 5px" }} className="row_form mt-1">
        <Col md={6}>
          <FloatingLabelInput
            new
            tipo="input_select"
            label="Estilo do arquivo..."
            options={topoEsquerdoOptions}
          />
        </Col>
        <Col md={3}>
          <FloatingLabelInput
            new
            tipo="input_select"
            label="Mês"
            disabled
            options={[{ label: "Mes", value: null }]}
          />
        </Col>
        <Col md={3}>
          <FloatingLabelInput
            new
            style={{ color: "#515c70" }}
            tipo="input_select"
            placeholder={currentYear}
            value={currentYear}
            onSelect={(option) => {
              console.log(option.value);
              setCurrentYear(option.value);
              handleYearChange(option.value);
              // handleOptionSelected(option.value)
            }}
            options={[
              {
                label: parseInt(ReturnDataAtual().split("/")[2]),
                value: parseInt(ReturnDataAtual().split("/")[2]),
              },
              {
                label: parseInt(ReturnDataAtual().split("/")[2]) + 1,
                value: parseInt(ReturnDataAtual().split("/")[2]) + 1,
              },
            ]}
          />
        </Col>
        <div className="pt-3"></div>
        <ColMaiorEsquerda lg={6} sm={12}>
          <ContainerMaiorEsquerda>
            <MenuTopo>
              <MenuTopoMenus>
                <ButtonSelectAba
                  onClick={() => setAbaSelecionada(0)}
                  active={abaSelecionada === 0}
                >
                  Principal
                </ButtonSelectAba>
                <ButtonSelectAba
                  onClick={() => setAbaSelecionada(1)}
                  active={abaSelecionada === 1}
                  style={{ display: showOpcoesButton ? "" : "none" }}
                >
                  Opções
                </ButtonSelectAba>
                <ButtonSelectAba
                  active={abaSelecionada === 2}
                  onClick={() => setAbaSelecionada(2)}
                  style={{ display: showOutrosButton ? "" : "none" }}
                >
                  Outros
                </ButtonSelectAba>
              </MenuTopoMenus>
            </MenuTopo>
            {/*A partir daqui é o componente*/}
            {abaSelecionada === 0 && (
              <AbaPrincipalRelatorio
                principalOptions={principalOptions}
                labelsCheckAbaPrincipal={labelsCheckAbaPrincipal}
                setLabelsCheckAbaPrincipal={setLabelsCheckAbaPrincipal}
              />
            )}
            {abaSelecionada === 1 && (
              <AbaOpcoesRelatorio
                setLabelsCheckAbaOpcoes={setLabelsCheckAbaOpcoes}
                labelsCheckAbaOpcoes={labelsCheckAbaOpcoes}
              />
            )}
          </ContainerMaiorEsquerda>
        </ColMaiorEsquerda>
        <Col lg={6} sm={12}>
          <Row>
            <Col md={12}>
              <FloatingLabelInput
                new
                tipo="input_select"
                label="Favoritos"
                placeholder="Favoritos"
                disabled={!hasFavorite}
                value={inputFavoriteValue}
                onChange={(e) => {
                  console.log(e.target.value);
                  setInputFavoriteValue(e.target.value);
                }}
                options={[
                  { label: "Favoritos", value: null },
                  ...favoritosOptions,
                ]}
                onSelect={(option) => {
                  console.log(option.value);
                  // handleOptionSelected(option.value)
                  // setOptionSelectPrincipal(option.value)
                  handleSelectFavorite(option.value);
                  setInputFavoriteValue(option.value);
                }}
                handleOptionButton={handleOptionButton}
              />
            </Col>
            <ColMaiorDireita
              className="mt-3"
              md={12}
              style={{ height: `${hasAssinatura ? "445px" : "657px"}` }}
            >
              <ContainerMaiorDireita
                style={{ height: `${hasAssinatura ? "400px" : "100%"}` }}
              >
                <TituloColDir>
                  <span>Selecionado</span>
                  <ButtonIcon
                    disabled={!hasFavorite}
                    style={{ cursor: `${hasFavorite ? "pointer" : "default"}` }}
                    onClick={handleOpenModal}
                  >
                    <Icon
                      height={24}
                      icon="ph:star-light"
                      style={{ color: "#515c70" }}
                    />
                  </ButtonIcon>
                </TituloColDir>

                <LabelsContMaiorDir>
                  {labelsCheckAbaPrincipal.map((objeto, indexObjeto) => (
                    <React.Fragment key={indexObjeto}>
                      {objeto.checkBoxes.some(
                        (checkBox) => checkBox.selected
                      ) && (
                        <>
                          <p>{objeto.opcao}</p>
                          {objeto.checkBoxes.every(
                            (checkBox) => checkBox.selected === true
                          ) && (
                            <LabelCheckLOA
                              label={"Todos Selecionados"}
                              checked={objeto.checkBoxes.every(
                                (checkBox) => checkBox.selected === true
                              )}
                              onChange={(e) => {
                                checkAllBoxes(objeto, 1);
                              }}
                            />
                          )}
                          {!objeto.checkBoxes.every(
                            (checkBox) => checkBox.selected === true
                          ) &&
                            objeto.checkBoxes
                              .filter((item) => item.selected === true)
                              .map((option, indexOption) => (
                                <LabelCheckLOA
                                  key={indexOption}
                                  title={option.label}
                                  label={`${
                                    option.label.length > 50
                                      ? `${option.label.substring(0, 50)}...`
                                      : option.label
                                  }`}
                                  value={option.value}
                                  checked={option.selected}
                                  onChange={(e) => {
                                    gerenciarSelects(objeto, option, 1);
                                    console.log(e.target.value);
                                  }}
                                />
                              ))}
                        </>
                      )}
                    </React.Fragment>
                  ))}
                  {hasAnySelected(labelsCheckAbaPrincipal) &&
                    getUniqueObjects(labelsCheckAbaOpcoes).map(
                      (objeto, indexObjeto) => (
                        <React.Fragment key={indexObjeto}>
                          {objeto.checkBoxes.some(
                            (checkBox) => checkBox.selected
                          ) && (
                            <>
                              <p>{"Exibir"}</p>
                              {objeto.checkBoxes.every(
                                (checkBox) => checkBox.selected === true
                              ) && (
                                <LabelCheckLOA
                                  label={"Todos Selecionados"}
                                  checked={objeto.checkBoxes.every(
                                    (checkBox) => checkBox.selected === true
                                  )}
                                  onChange={(e) => {
                                    checkAllBoxes(objeto, 2);
                                  }}
                                />
                              )}
                              {!objeto.checkBoxes.every(
                                (checkBox) => checkBox.selected === true
                              ) &&
                                objeto.checkBoxes
                                  .filter((item) => item.selected === true)
                                  .map((option, indexOption) => (
                                    <LabelCheckLOA
                                      key={indexOption}
                                      title={option.label}
                                      label={`${
                                        option.label.length > 50
                                          ? `${option.label.substring(
                                              0,
                                              50
                                            )}...`
                                          : option.label
                                      }`}
                                      value={option.value}
                                      checked={option.selected}
                                      onChange={(e) => {
                                        gerenciarSelects(objeto, option, 2);
                                        console.log(e.target.value);
                                      }}
                                    />
                                  ))}
                            </>
                          )}
                        </React.Fragment>
                      )
                    )}
                </LabelsContMaiorDir>
              </ContainerMaiorDireita>
              <ParteInferiorContDirMaior>
                <p>{`${quantityCheckBoxSelected} itens`}</p>
              </ParteInferiorContDirMaior>
            </ColMaiorDireita>
            {hasAssinatura && (
              <ColMenorDireita className="mt-3" md={12}>
                <ContainerMenorDireita>
                  <TituloColDir>
                    <span>Assinaturas</span>
                  </TituloColDir>
                  <LabelsContMaiorDir className="pt-3">
                    <LabelCheckLoa2 label="Prefeito" />
                    <LabelCheckLoa2 label="Ordenadores" />
                    <LabelCheckLoa2 label="Contador" />
                    <LabelCheckLoa2 label="Diretor Financeiro" />
                  </LabelsContMaiorDir>
                </ContainerMenorDireita>
                <ParteInferiorContDirMenor>
                  <ButtonContDirMenor>
                    Solicitar assinatura
                    <Icon width={27} icon="mdi:contract-sign" color="#515c70" />
                  </ButtonContDirMenor>
                </ParteInferiorContDirMenor>
              </ColMenorDireita>
            )}
          </Row>
        </Col>
      </Row>
      <div className="p-3"></div>
    </>
  );
};

export default PagRelatorioComponente;
