import { Document, Packer, PageOrientation } from "docx";

import { DocxTableQDR } from "./DocxTableQDR";

import defaultImg64 from "../../../../utils/RelatorioDocx/defaultImg64";
import { DocxHeader } from "../../../../utils/RelatorioDocx/DocxHeader";
import { DocxFooter } from "../../../../utils/RelatorioDocx/DocxFooter";

export const generateDocxQdr = async (
  { pdfContent },
  title,
  logo,
  pageSize = 20,
  double = false,
  idsSelected
) => {
  const {
    dadosRelatorio,
    optionsHeader,
    columnWidth,
    userData: { currentClient, userAuthData },
    dateRelatorio: { year },
    selectBoxesOptionsAbaOptions
  } = pdfContent;
  const font = "Roboto";

  if (!dadosRelatorio) return null;

  const { idsNatureza, idsFontesFiltrado, idsEsfera } = idsSelected;
  const textEsfera =
    idsEsfera.length > 1
      ? "- Fiscal e Seguridade"
      : idsEsfera.length
      ? `- ${idsEsfera[0]}`
      : "";

  const filtros = {
    "Natureza da Receita": idsNatureza.length,
    "Fonte de Recurso": idsFontesFiltrado.length,
  };

  const nameFilter = Object.keys(filtros)
    .filter((key) => filtros[key])
    .join(" - ");

  const nameFiltroHeader = `${nameFilter} ${textEsfera}`;

  console.log(selectBoxesOptionsAbaOptions);
  

  const doc = new Document({
    sections: [
      {
        properties: {
          // type: index === 0 ? SectionType.CONTINUOUS : SectionType.NEXT_PAGE,
          page: {
            size: {
              orientation: PageOrientation.PORTRAIT,
            },
            margin: {
              top: 720,
              right: 720,
              bottom: 720,
              left: 720,
            },
          },
        },
        headers: {
          default: DocxHeader({
            logo: logo || defaultImg64,
            nameUnidadeGestora: currentClient.nomeFantasia,
            ano: year,
            title,
            font,
            nameFiltroHeader,
          }),
        },
        children: [
          ...DocxTableQDR({
            columns: optionsHeader,
            data: dadosRelatorio,
            font,
            subTitle: double ? double[0] : "",
            columnWidth: columnWidth,
            codigo: ["1.0.0.0.00.0.0", "2.0.0.0.00.0.0"],
            idsSelected,
            selectBoxesOptionsAbaOptions
          }),
        ],
        footers: {
          default: DocxFooter({
            userAuthData,
            currentClient,
            font,
          }),
        },
      },
    ],
  });

  const blob = await Packer.toBlob(doc);
  return new Blob([blob], {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });
};
