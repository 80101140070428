import React from "react";
import { RowTituloAba } from "../../PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import { BoxSistemas } from "./StyledComponents";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { TestButton } from "../../../../utils/TestButton";

const ComplementacaoClientes = ({ valuesCliente, setValuesCliente }) => {
  // {
  //   label: "10131 - Poder Executivo - Prefeitura Municipal",
  //   value: 0,
  // },
  // {
  //   label: "10132 - Poder Executivo - RPPS Municipal",
  //   value: 1,
  // },
  // {
  //   label: "20231 - Poder Legislativo - Câmara Municipal",
  //   value: 2,
  // },
  const valuePoderOrgao = valuesCliente.poderOrgao
    ? valuesCliente.poderOrgao ===
      "10131 - Poder Executivo - Prefeitura Municipal"
      ? 0
      : valuesCliente.poderOrgao === "10132 - Poder Executivo - RPPS Municipal"
      ? 1
      : valuesCliente.poderOrgao ===
          "20231 - Poder Legislativo - Câmara Municipal" && 2
    : null;
  return (
    <>
      <RowTituloAba>
        <CCol>
          <span>Complementação</span>
        </CCol>
      </RowTituloAba>

      <Row className="row_form_2 mt-1">
        <CCol>
          <PTitulosInputs>Especificação</PTitulosInputs>
          <FormControlListaDeInputs disabled value={"Códigos Intituições"} />
        </CCol>

        <Row style={{ padding: "0px", margin: "0px", marginTop: "16px" }}>
          <CCol>
            <BoxSistemas>
              <RowAninhada>
                <CCol>
                  <PTitulosInputs>TCE</PTitulosInputs>
                  <FormControlListaDeInputs
                    maxLength={6}
                    value={valuesCliente.codigoTCE}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      setValuesCliente((prev) => ({
                        ...prev,
                        codigoTCE: e.target.value,
                      }));
                    }}
                  />
                </CCol>
                <CCol>
                  <PTitulosInputs>Siconfi</PTitulosInputs>
                  <FormControlListaDeInputs
                    maxLength={9}
                    value={valuesCliente.codigoSiconfi}
                    onChange={(e) => {
                      // e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      setValuesCliente((prev) => ({
                        ...prev,
                        codigoSiconfi: e.target.value,
                      }));
                    }}
                  />
                </CCol>
                <CCol>
                  <PTitulosInputs>IBGE</PTitulosInputs>
                  <FormControlListaDeInputs
                    maxLength={7}
                    value={valuesCliente.ibge}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      setValuesCliente((prev) => ({
                        ...prev,
                        ibge: e.target.value,
                      }));
                    }}
                  />
                </CCol>
                <CCol>
                  <PTitulosInputs>Poder e Orgão</PTitulosInputs>
                  <FloatingLabelInput
                    new
                    disabled
                    tipo="input_select"
                    height="30px"
                    placeholder={"Aguardando..."}
                    value={valuePoderOrgao}
                    options={[
                      {
                        label: "Aguardando...",
                        value: null,
                      },
                      {
                        label: "10131 - Poder Executivo - Prefeitura Municipal",
                        value: 0,
                      },
                      {
                        label: "10132 - Poder Executivo - RPPS Municipal",
                        value: 1,
                      },
                      {
                        label: "20231 - Poder Legislativo - Câmara Municipal",
                        value: 2,
                      },
                    ]}
                  />
                </CCol>
              </RowAninhada>
            </BoxSistemas>
          </CCol>
        </Row>
        <TestButton onClick={() => console.log(valuesCliente.poderOrgao)}>
          PoderÓrgão
        </TestButton>
      </Row>
    </>
  );
};

export default ComplementacaoClientes;
