export const ReturnColorContaPCASP = (primeiroDigitoConta) => {
  if (
    parseInt(primeiroDigitoConta) >= 1 &&
    parseInt(primeiroDigitoConta) <= 4
  ) {
    return "#F0F3FF";
  } else if (
    parseInt(primeiroDigitoConta) >= 5 &&
    parseInt(primeiroDigitoConta) <= 6
  ) {
    return "#FFEFFB";
  } else {
    return "#EFFCE5";
  }
};

export const ReturnColorContaPCASPAlternative = (primeiroDigitoConta) => {
  if (
    parseInt(primeiroDigitoConta) >= 1 &&
    parseInt(primeiroDigitoConta) <= 4
  ) {
    return "#C6D9FC";
  } else if (
    parseInt(primeiroDigitoConta) >= 5 &&
    parseInt(primeiroDigitoConta) <= 6
  ) {
    return "#F5D0F5";
  } else {
    return "#cdeeb4";
  }
};
