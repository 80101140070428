import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfInfos } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfHeader } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";

export const RenderPdfAnexoDemoSegCatEcon = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: 130, flexShrink: 1, flexGrow: 1 },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: 150,
      flexShrink: 1,
      flexGrow: 1,
    },
  ];

  const renderTextColumn = (text, style = {}) => (
    <Text
      style={[
        stylesPdf.textTituloColuna,
        style,
        { textTransform: "uppercase", fontSize: 8 },
      ]}
    >
      {text}
    </Text>
  );

  const renderHeader = (styled = {}) => {
    return (
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }, styled]}>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: "auto",
                alignItems: "center",
              },
            ]}
          >
            {renderTextColumn("código")}
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[1]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: "auto",
                alignItems: "center",
              },
            ]}
          >
            {renderTextColumn("especificações")}
          </View>
        </View>

        {dados.contentHeader.map((item, index) => (
          <View key={index} style={[stylesPdf.coluna, styledCol[2]]}>
            <View
              style={[
                stylesPdf.divTituloColuna,
                {
                  textAlign: "center",
                  paddingVertical: 2,
                  height: "auto",
                  alignItems: "center",
                },
              ]}
            >
              {renderTextColumn(item)}
            </View>
          </View>
        ))}
      </View>
    );
  };

  const renderInfos = (codigo, title, values, isSection = false) => {
    const seccoes = codigo.split(".").filter((secao) => parseInt(secao) !== 0);
    console.log(seccoes);

    const codigoIsBold = seccoes.length < 2;
    const codigoFonte = /^[^.]*\.[^.]*$/.test(codigo);
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            backgroundColor: isSection ? "#a6a6a6" : "transparent",
            fontWeight: codigoIsBold || codigoFonte ? "bold" : "normal",
          },
        ]}
      >
        <PdfInfos
          pdfInfo={codigo}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{
            fontSize: 8,
            paddingHorizontal: 4,
            textAlign: codigoFonte ? "right" : "left",
          }}
        />
        <PdfInfos
          pdfInfo={title}
          styledCol={[
            styledCol[1],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{ fontSize: 8, paddingHorizontal: 4 }}
        />
        {values.map((item, index) => (
          <PdfInfos
            key={index}
            pdfInfo={item}
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
              },
            ]}
            styledText={{
              fontSize: 8,
              paddingHorizontal: 4,
              textAlign: "right",
            }}
          />
        ))}
      </View>
    );
  };

  const renderTotal = (typeReceitaTotal, values) => {
    return (
      <View
        wrap={false}
        style={[
          stylesPdf.divInferiorColuna,
          {
            fontWeight: "bold",
            fontSize: 8,
            textTransform: "uppercase",
            color: "white",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: 0,
            paddingHorizontal: 4,
          },
        ]}
      >
        <Text>{typeReceitaTotal}</Text>
        <View style={{ flexDirection: "row" }}>
          {values.map((item, index) => (
            <Text key={index} style={{ width: 66, textAlign: "right" }}>
              {item}
            </Text>
          ))}
        </View>
      </View>
    );
  };

  const nameYearsRelatorio = `Quadriênio ${dados.contentHeader[0]} - ${
    dados.contentHeader[dados.contentHeader.length - 1]
  }`;

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Demonstrativo da Despesa Segundo a Categoria Econômica"}
        anoRelatorio={currentYear}
        plusText={"Anexo II"}
        customRelatorioType="Plano Plurianual"
        dataClientInfos={dataClientInfos}
        customData={customData}
        relatorioYearsPpa={nameYearsRelatorio}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>CF/88 (art. 165º, § 1º, inciso I)</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {renderHeader("RECEITA")}
      </View>
      <View style={stylesPdf.divRelatorio}>
        {renderInfos(
          "3.0.00.00.00",
          "Receitas Correntes ",
          ["25.000,00", "25.000,00", "25.000,00", "25.000,00"],
          true
        )}
        {renderInfos(
          "3.1.00.00.00",
          "Impostos, Taxas e Contribuições de Melhoria",
          ["25.000,00", "25.000,00", "25.000,00", "25.000,00"]
        )}
        {renderInfos("3.1.90.00.00", "Impostos  ", [
          "25.000,00",
          "25.000,00",
          "25.000,00",
          "25.000,00",
        ])}
        {renderInfos("1.500", "Recursos não Vinculados de Impostos ", [
          "25.000,00",
          "25.000,00",
          "25.000,00",
          "25.000,00",
        ])}

        {renderTotal("total", [
          "25.000,00",
          "25.000,00",
          "25.000,00",
          "25.000,00",
        ])}
        <View style={{ marginTop: 10 }}>
          {renderTotal("valores globais", ["25.000,00"])}
        </View>
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
