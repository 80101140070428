import styled from "styled-components";

const InputContainer = styled.div`
  border: 1px solid #cccccc;
  padding: 6px;
  padding-left: 10px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ disable, backgroundColor }) =>
    disable ? "#e9ecef" : backgroundColor ? backgroundColor : ""};
`;

const InputPercentageStyle = styled.input`
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  width: 100%;
  font-weight: 500;
  color: #515c70;
`;

const PercentageIcon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='%23515C70' d='M7.5 11q-1.45 0-2.475-1.025T4 7.5t1.025-2.475T7.5 4t2.475 1.025T11 7.5T9.975 9.975T7.5 11m0-2q.625 0 1.063-.437T9 7.5t-.437-1.062T7.5 6t-1.062.438T6 7.5t.438 1.063T7.5 9m9 11q-1.45 0-2.475-1.025T13 16.5t1.025-2.475T16.5 13t2.475 1.025T20 16.5t-1.025 2.475T16.5 20m0-2q.625 0 1.063-.437T18 16.5t-.437-1.062T16.5 15t-1.062.438T15 16.5t.438 1.063T16.5 18M5.4 20L4 18.6L18.6 4L20 5.4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  width: 24px;
  height: 24px;
`;

export const InputPercentage = ({
  handleDate,
  disable = false,
  backgroundColor,
  ...props
}) => {
  return (
    <InputContainer disable={disable} backgroundColor={backgroundColor}>
      <InputPercentageStyle
        type="text"
        onChange={(e) => handleDate(e.target.value)}
        disabled={disable}
        {...props}
      />
      <PercentageIcon />
    </InputContainer>
  );
};
