import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import {
  CCol,
  ContainerToModal,
  RowToModal,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { ToastContainer, toast } from "react-toastify";
import Orientacoes from "./Orientacoes";
import EstruturaFonteRecursos from "./EstruturaFonteRecursos";
import { contaFonteRecurosAutoPreencher } from "../../../../utils/FormatacaoDeDados/ContaFonteRecursosAutoPreencher";
import { FormatContaFonteRecursos } from "../../../../utils/FormatacaoDeDados/FormatContaFonteRecursos";
import api from "../../../../utils/api";
import { useParams } from "react-router-dom";
import ButtonSaveModalAutoComplete from "../../../../components/Modais/ButtonSaveModalAutoComplete";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";

export const OptionsStyle = styled.span`
  color: #515c70;
  line-height: 35px;
  font-size: 0.8em;
  margin-left: 20px;
  cursor: pointer;
  ${({ selecionado }) =>
    selecionado &&
    `
    font-weight: 600;
    border-bottom: 2px solid #515c70;
  `}
`;

function FonteDeRecursos({ isAModalPage }) {
  const { currentYear } = useCurrentYear();
  const [abaSelecionada, setAbaSelecionada] = useState(0);
  const [arrayEstruturaDaConta, setArrayEstruturaDaConta] = useState([]);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [codigoConta, setCodigoConta] = useState("");
  const { codigo: paramsCodigo } = useParams();
  const [valuesFonteRecursos, setValuesFonteRecursos] = useState({
    fonteId: "",
    naturezaPrefixos: [],
  });
  const [orientacoesAtivo, setOrientacoesAtivo] = useState(false);
  const [atributosConta, setAtributosConta] = useState({
    titulo: "",
    descricao: "",
    funcao: "",
  });
  const [valuesInfosComplementares, setValuesInfosComplementares] = useState({
    portaria: "",
    normaCorrespondente: "",
    status: "",
    funcao: "",
  });

  const [arrayReceitas, setArrayReceitas] = useState([
    // {codigo: "",titulo: "", id: 0}
  ]);

  useEffect(() => {
    if (paramsCodigo && !isAModalPage) {
      setCodigoConta(paramsCodigo);
    }
  }, [paramsCodigo, isAModalPage]);

  const fetchContaFilter = () => {
    setLoadingFetch(true);
    let valorFormated = contaFonteRecurosAutoPreencher(codigoConta);

    const regex = /(\.0+)+$/;
    valorFormated = valorFormated.replace(regex, "");
    console.log(valorFormated);

    api
      .get(`fonteDeRecurso/${currentYear}/estrutura/${valorFormated}`)
      .then((resp) => {
        const data = resp.data;
        console.log(data);

        if (data.length === 0) {
          throw new Error("Array vazio");
        }

        if (data.length >= 1) {
          const newArrayEstruturaConta = data.map((item) => ({
            nivel: item.nivel,
            conta: item.conta,
            titulo: item.titulo,
          }));

          setAtributosConta((prevValues) => ({
            ...prevValues,
            titulo: data[data.length - 1].titulo,
            descricao: data[data.length - 1].descricao,
            funcao: data[data.length - 1].funcao,
          }));

          setValuesFonteRecursos((prev) => ({
            ...prev,
            fonteId: data[data.length - 1].id,
          }));

          setArrayEstruturaDaConta([...newArrayEstruturaConta]);

          if (data.length === 3) {
            const contaData = data[2];
            console.log(contaData);

            if (contaData.co === "3110" || contaData.co === "3120") {
              console.log("entrance");
              setOrientacoesAtivo(true);
            } else {
              console.log("falsanse");
              setOrientacoesAtivo(false);
            }
          } else {
            setOrientacoesAtivo(true);
          }

          setArrayReceitas(
            data[data.length - 1].nrVinculada.map((item) => ({
              codigo: item.nr.substring(0, 10).replace(/\.0\.00|\.00/g, ""),
              titulo: item.especificacao,
              id: item.id,
            }))
          );
        }

        setLoadingFetch(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Algo deu errado. Por favor, tente mais tarde.");
        }
        setOrientacoesAtivo(false);
        setValuesFonteRecursos((prev) => ({
          ...prev,
          fonteId: 0,
        }));
        setLoadingFetch(false);
      });
  };

  useEffect(() => {
    console.log("effect");
    if (
      codigoConta.length > 0 &&
      ![3, 4, 7, 8, 9].includes(codigoConta.length)
    ) {
      fetchContaFilter();
    }

    if (codigoConta.length === 0) {
      setAtributosConta((prevValues) => ({
        ...prevValues,
        titulo: "",
        descricao: "",
      }));

      setValuesInfosComplementares((prevValues) => ({
        ...prevValues,
        ano: "",
        normaCorrespondente: "",
        status: "",
        funcao: "",
      }));
    }
    //eslint-disable-next-line
  }, [codigoConta]);

  const cadastraFonteDeRecursos = () => {
    api
      .post("/fonteDeRecurso/vincular-natureza", valuesFonteRecursos)
      .then(() => {
        toast.success("Dados cadastrados com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Algo deu errado ao realizar este cadastro. Por favor, tente mais tarde ou entre em contato com o suporte."
        );
      });
  };

  return (
    <ContainerToModal
      isAModalPage={isAModalPage}
      id={isAModalPage ? undefined : "pags_responsividade_padding_geral"}
      fluid
      className={
        isAModalPage ? undefined : "container_conteudo conteudo_pagina"
      }
    >
      {!isAModalPage && (
        <>
          <HeaderCadastros
            withRow={true}
            NomePaginaTitulo={"Fonte de Recursos"}
            PaginaSubtitulo={"Cadastro"}
            PaginaConsulta={"/planejamento/cadastro/fonte-de-recursos/consulta"}
            ButtonSaveFunction={() => {
              cadastraFonteDeRecursos();
            }}
            DashboardPage={"/planejamento/cadastro/fonte-de-recursos/dashboard"}
            PaginaRelatorio={
              "/planejamento/cadastro/fonte-de-recurso/relatorio"
            }
          />
          <ToastContainer />
        </>
      )}
      {isAModalPage && (
        <ButtonSaveModalAutoComplete
          SaveFunction={cadastraFonteDeRecursos}
          pageTitle={"Fonte de Recursos"}
        />
      )}
      <RowToModal isAModalPage={isAModalPage} className="row_form mt-1">
        {/* <button onClick={() => console.log(valuesFonteRecursos)}>
          console
        </button> */}
        <CCol md={2}>
          <FloatingLabelInput
            placeholder="Código"
            label="Código"
            value={codigoConta}
            readOnly={loadingFetch ? true : false}
            onChange={(e) => {
              FormatContaFonteRecursos(e.target);
              setCodigoConta(e.target.value);
            }}
          />
        </CCol>
        <CCol md={8}>
          <FloatingLabelInput
            disabled
            placeholder="Título"
            value={atributosConta.titulo}
            label="Título"
          />
        </CCol>
        <CCol>
          {/* <FloatingLabelInput
            tipo="input_select"
            label={"Tipo de Vínculo"}
            options={[
              { label: "Tipo de Vínculo" },
              { label: "Teste", value: 0 },
            ]}
          /> */}
          <FloatingLabelInput
            label={"Tipo de Vínculo" /*"Função"*/}
            value={atributosConta.funcao}
            placeholder={"Tipo de Vínculo" /*"Função"*/}
            disabled
          />
        </CCol>
      </RowToModal>
      <RowToModal
        isAModalPage={isAModalPage}
        className="row_form"
        style={{ marginTop: "-14px" }}
      >
        <CCol md={12}>
          <FloatingLabelInput
            as="textarea"
            disabled
            style={{ height: "38px", minHeight: "38px" }}
            tipo="text_area"
            placeholder="Descrição"
            label="Descrição"
            value={atributosConta.descricao}
          />
        </CCol>
      </RowToModal>
      <RowToModal isAModalPage={isAModalPage}>
        <Col
          className="d-flex align-items-center row_endereco_aba mt-1"
          md={12}
        >
          <OptionsStyle
            selecionado={abaSelecionada === 0}
            onClick={() => setAbaSelecionada(0)}
          >
            Estrutura
          </OptionsStyle>
          <OptionsStyle
            selecionado={abaSelecionada === 1}
            onClick={() => setAbaSelecionada(1)}
          >
            Orientações
          </OptionsStyle>
        </Col>
      </RowToModal>
      {abaSelecionada === 0 && (
        <EstruturaFonteRecursos
          isAModalPage={isAModalPage}
          arrayEstruturaDaConta={arrayEstruturaDaConta}
        />
      )}
      {abaSelecionada === 1 && (
        <Orientacoes
          isAModalPage={isAModalPage}
          valuesFonteRecursos={valuesFonteRecursos}
          setValuesFonteRecursos={setValuesFonteRecursos}
          arrayReceitas={arrayReceitas}
          setArrayReceitas={setArrayReceitas}
          valuesInfosComplementares={valuesInfosComplementares}
          orientacoesAtivo={orientacoesAtivo}
        />
      )}
      {!isAModalPage && <div className="p-3"></div>}
    </ContainerToModal>
  );
}

export default FonteDeRecursos;
