import { Icon } from "@iconify/react/dist/iconify.js";
import { Collapse } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";

const ContainerTable = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
`;

const HeaderTable = styled.div`
  background-color: #515c70;
  border-radius: 5px 5px 0px 0px;
  padding: 15px;
  color: #fff;

  h6 {
    margin: 0px;
    font-size: 15px;
  }
`;

const DropzoneTable = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  background-color: #f5f7f7;
  max-height: 41px;

  & > span,
  & > label {
    margin-left: 10px;
    font-size: 14px;
  }

  & > span {
    color: #949494ba;
    margin-left: 10px;
    font-size: 14px;
  }

  & > label {
    display: flex;
    align-items: center;
    color: #fff;
    background-color: #515c70;
    padding: 3px 10px;
    border-radius: 20px;

    svg {
      margin-left: 10px;
      cursor: pointer;
    }
  }
`;

const BorderContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 0px 0px 5px 5px;
`;

const ColumnsDiv = styled.div`
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  max-height: 403px;
`;

const Column = styled.div`
  display: inline-block;
  vertical-align: top;
  /* border-right: 1px solid #ccc; */
  overflow: auto;
  min-width: 200px;
  resize: horizontal;
`;

const ColumnTitle = styled.div`
  padding: 5px 15px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: #f5f7f7;
  cursor: grab;
`;

const ColumnFilter = styled.div`
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 5px 15px;
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: #f5f7f7;

  > label {
    flex-basis: 100px;
    border-top: 2px solid #aeaeae;
    border-left: 2px solid #aeaeae;
    border-bottom: 2px solid #d5d5d5;
    border-right: 2px solid #d5d5d5;
    font-size: 13px;
    flex-shrink: 1;
    flex-grow: 1;
    background-color: #ebebeb;
    min-height: 22.2px;
  }

  > button {
    background: none;
    border: 0;
  }
`;

const ColumnData = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 2px 15px;
  background-color: ${({ $hovered }) => ($hovered ? "#f3f3f3" : "#fff")};
  transition: background-color 0.1s;
  min-height: 29px;

  > span {
    font-size: 14px;
  }
`;

const GroupRow = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 2px 15px;
  background-color: ${({ $hovered }) => ($hovered ? "#f3f3f3" : "#fff")};
  transition: background-color 0.1s;
  min-height: 29px;
`;

const TotalAmountDisplay = styled.div`
  border-top: 1px solid #ccc;
  padding: 20px;

  article {
    h6 {
      font-size: 14px;
      margin: 0;
    }

    p {
      margin: 0;
    }
  }
`;

const ColumnContent = styled.div`
  overflow-x: hidden;
`;

const ArrowIcon = styled(Icon)`
  transform: ${({ $expanded }) =>
    $expanded ? "rotate(90deg)" : "rotate(0deg)"};
  transition: transform 0.2s;
`;

export const QueryTable = ({ colunas = [], data = [] }) => {
  const [draggedElementText, setDraggedElementText] = useState(null);
  const [draggedElementKey, setDraggedElementKey] = useState(null);
  const [groupBy, setGroupBy] = useState(null);
  const [groupByKey, setGroupByKey] = useState(null);
  const [group, setGroup] = useState([]);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
  const [groupExpandRowIndex, setGroupExpandRowIndex] = useState(-1);
  const columnActionValues = colunas.find((obj) => obj.key === "dataActions");

  const groupValues = (key) => {
    const agrupamentos = data.reduce((acc, obj) => {
      const customKey = obj[key];

      if (!acc[customKey]) {
        acc[customKey] = [];
      }

      acc[customKey].push(obj);

      return acc;
    }, {});

    console.log(agrupamentos);

    setGroup(agrupamentos);
  };

  const defineValueToGroup = () => {
    if (draggedElementText) {
      setGroupBy(draggedElementText);
    }

    if (draggedElementKey) {
      setGroupByKey(draggedElementKey);
    }

    groupValues(draggedElementKey);
  };

  const clearGroupBy = () => {
    setGroupBy(null);
    setGroupByKey(null);
  };

  const handleDragStart = (e) => {
    const text = e.target.innerText;
    const key = e.target.getAttribute("data-key");
    setDraggedElementText(text);
    setDraggedElementKey(key);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    console.log("element value:" + draggedElementText);

    defineValueToGroup();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleExpandGroup = (index) => {
    if (index === groupExpandRowIndex) {
      setGroupExpandRowIndex(-1);
    } else {
      setGroupExpandRowIndex(index);
    }
  };

  const returnRespectiveActions = () => {
    if (Object.keys(group).length > 0 && groupExpandRowIndex !== -1) {
      const dataIds = group[Object.keys(group)[groupExpandRowIndex]].map(
        (item) => item.id
      );
      const respectiveActionsArray = columnActionValues.values.filter((item) =>
        dataIds.includes(item.dataId)
      );

      return respectiveActionsArray || [];
    } else {
      return [];
    }
  };

  return (
    <ContainerTable>
      <HeaderTable>
        <h6>QDR - Quadro Detalhado da Receita</h6>
      </HeaderTable>
      <BorderContainer>
        <DropzoneTable onDrop={handleDrop} onDragOver={handleDragOver}>
          <Icon
            icon="tabler:drag-drop"
            style={{ color: "#949494ba" }}
            fontSize={20}
          />
          {groupBy ? (
            <label>
              {groupBy}
              <Icon
                icon="material-symbols:cancel-outline"
                style={{ color: "#fff" }}
                fontSize={20}
                onClick={clearGroupBy}
              />
            </label>
          ) : (
            <span>Arraste uma coluna aqui para agrupá-la</span>
          )}
        </DropzoneTable>

        <ColumnsDiv>
          {groupByKey && (
            <>
              <Column>
                <ColumnTitle draggable={true} onDragStart={handleDragStart}>
                  <span>Agrupamento</span>
                </ColumnTitle>
                <ColumnFilter>
                  <label></label>
                  <button>
                    <Icon
                      icon="iconoir:filter"
                      style={{ color: "#949494ba" }}
                    />
                  </button>
                </ColumnFilter>
                <ColumnContent onMouseLeave={() => setHoveredRowIndex(-1)}>
                  {Object.keys(group).map((key, index) => (
                    <>
                      <GroupRow
                        key={index}
                        $hovered={index === hoveredRowIndex}
                        onMouseEnter={() => setHoveredRowIndex(index)}
                        onClick={() => handleExpandGroup(index)}
                      >
                        <ArrowIcon
                          $expanded={index === groupExpandRowIndex}
                          icon="eva:arrow-ios-forward-outline"
                        />{" "}
                        {key}
                      </GroupRow>
                      <Collapse in={index === groupExpandRowIndex}>
                        <Column
                          key={index}
                          style={{ width: "100%", minWidth: "100%" }}
                        >
                          <ColumnContent
                            onMouseLeave={() => setHoveredRowIndex(-1)}
                          >
                            {group[key].map((values, index) => (
                              <>
                                <ColumnData
                                  style={{ paddingLeft: "45px" }}
                                  key={index}
                                >
                                  <span>{values[groupByKey]}</span>
                                </ColumnData>
                              </>
                            ))}
                          </ColumnContent>
                        </Column>
                      </Collapse>
                    </>
                    // <React.Fragment key={index}>
                    //   {group[key].map((value, indexValue) => (
                    //     <ColumnData $hovered={false} key={indexValue}>
                    //       <span>{value.naturezaDaReceita}</span>
                    //     </ColumnData>
                    //   ))}
                    // </React.Fragment>
                  ))}
                </ColumnContent>
              </Column>
              {colunas
                .filter(
                  (obj) => obj.key !== groupByKey && obj.key !== "dataActions"
                )
                .map((item, index) => (
                  <Column key={index}>
                    <ColumnTitle
                      draggable={true}
                      onDragStart={handleDragStart}
                      data-key={item.key}
                    >
                      <span>{item.title}</span>
                    </ColumnTitle>
                    <ColumnFilter>
                      <label></label>
                      <button>
                        <Icon
                          icon="iconoir:filter"
                          style={{ color: "#949494ba" }}
                        />
                      </button>
                    </ColumnFilter>
                    <ColumnContent onMouseLeave={() => setHoveredRowIndex(-1)}>
                      {Object.keys(group).map((key, index) => (
                        <>
                          <GroupRow
                            onClick={() => handleExpandGroup(index)}
                            key={index}
                            $hovered={index === hoveredRowIndex}
                            onMouseEnter={() => setHoveredRowIndex(index)}
                          ></GroupRow>
                          <Collapse in={index === groupExpandRowIndex}>
                            <Column
                              key={index}
                              style={{ width: "100%", minWidth: "100%" }}
                            >
                              <ColumnContent
                                onMouseLeave={() => setHoveredRowIndex(-1)}
                              >
                                {group[key].map((values, index) => (
                                  <>
                                    <ColumnData
                                      style={{ paddingLeft: "45px" }}
                                      key={index}
                                    >
                                      <span>{values[item.key]}</span>
                                    </ColumnData>
                                  </>
                                ))}
                              </ColumnContent>
                            </Column>
                          </Collapse>
                        </>
                      ))}
                    </ColumnContent>
                  </Column>
                ))}
              {columnActionValues && (
                <>
                  <Column>
                    <ColumnTitle style={{ cursor: "not-allowed" }}>
                      <span>{columnActionValues.title}</span>
                    </ColumnTitle>
                    <ColumnFilter>
                      <label></label>
                      <button>
                        <Icon
                          icon="iconoir:filter"
                          style={{ color: "#949494ba" }}
                        />
                      </button>
                    </ColumnFilter>
                    <ColumnContent onMouseLeave={() => setHoveredRowIndex(-1)}>
                      {Object.keys(group).map((key, index) => (
                        <>
                          <GroupRow
                            onClick={() => handleExpandGroup(index)}
                            key={index}
                            $hovered={index === hoveredRowIndex}
                            onMouseEnter={() => setHoveredRowIndex(index)}
                          ></GroupRow>
                          <Collapse in={index === groupExpandRowIndex}>
                            <Column
                              key={index}
                              style={{ width: "100%", minWidth: "100%" }}
                            >
                              <ColumnContent
                                onMouseLeave={() => setHoveredRowIndex(-1)}
                              >
                                {returnRespectiveActions()?.map(
                                  (values, index) => (
                                    <>
                                      <ColumnData
                                        style={{ paddingLeft: "45px" }}
                                        key={index}
                                      >
                                        <span>{values.actions}</span>
                                      </ColumnData>
                                    </>
                                  )
                                )}
                              </ColumnContent>
                            </Column>
                          </Collapse>
                        </>
                      ))}
                    </ColumnContent>
                    {/* <ColumnContent onMouseLeave={() => setHoveredRowIndex(-1)}>
                      {columnActionValues.values.map((value, index) => (
                        <ColumnData key={index}>
                          <span>{value}</span>
                        </ColumnData>
                      ))}
                    </ColumnContent> */}
                  </Column>
                </>
              )}
            </>
          )}
          {!groupByKey && (
            <>
              {colunas
                .filter((obj) => obj.key !== "dataActions")
                .map((item, index) => (
                  <Column key={index}>
                    <ColumnTitle
                      draggable={true}
                      onDragStart={handleDragStart}
                      data-key={item.key}
                    >
                      <span>{item.title}</span>
                    </ColumnTitle>
                    <ColumnFilter>
                      <label></label>
                      <button>
                        <Icon
                          icon="iconoir:filter"
                          style={{ color: "#949494ba" }}
                        />
                      </button>
                    </ColumnFilter>
                    <ColumnContent onMouseLeave={() => setHoveredRowIndex(-1)}>
                      {item.values.map((value, index) => (
                        <ColumnData
                          key={index}
                          $hovered={index === hoveredRowIndex}
                          onMouseEnter={() => setHoveredRowIndex(index)}
                        >
                          <span>{value}</span>
                        </ColumnData>
                      ))}
                    </ColumnContent>
                  </Column>
                ))}
              {columnActionValues && (
                <>
                  <Column>
                    <ColumnTitle style={{ cursor: "not-allowed" }}>
                      <span>{columnActionValues.title}</span>
                    </ColumnTitle>
                    <ColumnFilter>
                      <label></label>
                      <button>
                        <Icon
                          icon="iconoir:filter"
                          style={{ color: "#949494ba" }}
                        />
                      </button>
                    </ColumnFilter>
                    <ColumnContent onMouseLeave={() => setHoveredRowIndex(-1)}>
                      {columnActionValues.values.map((value, index) => (
                        <ColumnData
                          key={index}
                          $hovered={index === hoveredRowIndex}
                          onMouseEnter={() => setHoveredRowIndex(index)}
                        >
                          <span>{value.actions}</span>
                        </ColumnData>
                      ))}
                    </ColumnContent>
                  </Column>
                </>
              )}
            </>
          )}
        </ColumnsDiv>

        <TotalAmountDisplay>
          <article>
            <h6>Montante:</h6>
            <p>R$ 41.486.759,52</p>
          </article>
        </TotalAmountDisplay>
      </BorderContainer>
    </ContainerTable>
  );
};
