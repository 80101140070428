import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Container } from "react-bootstrap";
import GlobalStyles from "../../../../../styles/StyledComponents/GlobalStyles";
import { DivPrintOnly } from "../../../../../styles/StyledComponents/StylesRelatorios";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { useGenerateRelatorio } from "../../../../../hooks/useGenerateRelatorio";
import { CurrentClientContext } from "../../../../../contexts/CurrentClientContext";
import HeaderCadastros from "../../../../../components/HeaderCadastros/HeaderCadastros";
import PagRelatorioComponente from "../../../../../components/ComponenteDeRelatorio/PagRelatorioComponente";
import VisualizacaoRelatorioPrograma from "./VisualizacaoRelatorioPrograma";
import { PdfPrograma } from "./PdfPrograma";
import { isBase64 } from "../../../../../utils/Gerais/isBase64";

const RelatorioPrograma = () => {
  const relatorioName = "Relatório Programa";
  const columnWidth = [10, 40, 30, 20];
  const navigate = useNavigate();
  const { currentClient } = useContext(CurrentClientContext);
  const [selectBoxesOptionsAbaPrincipal, setSelectBoxesOptionsAbaPrincipal] =
    useState([]);
  const [selectBoxesOptionsAbaOptions, setSelectBoxesOptionsAbaOptions] =
    useState([
      {
        opcao: "Exibir", //"Programa",
        checkBoxes: [
          { label: "Código", value: "numeroPrograma", selected: true },
          { label: "Título", value: "titulo", selected: true },
          {
            label: "Tipo de Programa",
            value: "tipoPrograma",
            selected: true,
          },
          { label: "Indicadores", value: "indicadores", selected: true },
        ],
      },
    ]);
  const { data: dataPrograma } = useFetchData(
    `programaPlanejamento/cliente/${currentClient.clienteId}`
  );

  const {
    dadosRelatorio,
    dataClientInfos,
    handleGenerateDocx,
    handleGeneratePdf,
    handleGenerateXls,
    handlePrint,
    pdfData,
  } = useGenerateRelatorio(
    selectBoxesOptionsAbaOptions,
    selectBoxesOptionsAbaPrincipal,
    currentClient,
    relatorioName,
    columnWidth
  );

  const getDadosRelatorio = () => {
    const selectBox = selectBoxesOptionsAbaPrincipal?.find(
      (item) => item.opcao === "Programa"
    );

    const selectBoxSelected = selectBox?.checkBoxes.some(
      (item) => item.selected
    );

    if (!selectBoxSelected) return [];

    const itensSelecionados = selectBox.checkBoxes
      .filter((item) => item.selected)
      .map((checkBox) => checkBox.value);

    const dadosSelecionados = dataPrograma?.filter((item) =>
      itensSelecionados.includes(item.id)
    );

    const dadosFiltrados = dadosSelecionados?.map((item) => ({
      numeroPrograma: item.numeroPrograma,
      titulo: item.titulo,
      tipoPrograma: item.tipoDeProgramaTitulo,
      indicadores: item.indicadores.length,
    }));

    return dadosFiltrados;
  };

  const handleGenerateDataXls = () => {
    const dadosRelatorio = getDadosRelatorio();

    const dataTable = dadosRelatorio
      ?.map((item) => {
        const selectedFields = {};
        selectBoxesOptionsAbaOptions[0]?.checkBoxes?.forEach(
          ({ label, value, selected }) => {
            const actualItem = item[value];
            if (selected && !isBase64(actualItem)[0]) {
              selectedFields[label] = actualItem;
            }
          }
        );

        return Object.keys(selectedFields).length > 0 ? selectedFields : null;
      })
      .filter((item) => item);
    if (dataTable.length !== 0) {
      handleGenerateXls(dataTable);
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  useEffect(() => {
    if (
      dataPrograma &&
      !selectBoxesOptionsAbaPrincipal.some((item) => item.opcao === "Programa")
    ) {
      console.log(dataPrograma);

      setSelectBoxesOptionsAbaPrincipal((prev) => [
        ...prev,
        {
          opcao: "Programa",
          checkBoxes: dataPrograma.map((item) => ({
            label: `${item.numeroPrograma} - ${
              item.titulo.length > 40
                ? `${item.titulo.substring(0, 40)}...`
                : item.titulo
            }`,
            value: item.id,
            selected: false,
          })),
        },
      ]);
    }
  }, [dataPrograma, selectBoxesOptionsAbaPrincipal]);

  return (
    <>
      <GlobalStyles orientation={"landscape"} />
      {dadosRelatorio && (
        <DivPrintOnly>
          <VisualizacaoRelatorioPrograma
            dadosRelatorio={dadosRelatorio}
            dataClientInfos={dataClientInfos}
            numeroDaPagina={1}
            totalDePaginas={2}
          />
        </DivPrintOnly>
      )}

      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina hide_on_print"
      >
        <HeaderCadastros
          PaginaConsulta={"/planejamento/cadastro/programa/consulta"}
          NomePaginaTitulo={"Programa"}
          PaginaSubtitulo={"Relatório"}
          BotaoNovoAtivo={true}
          BotaoSaveAtivo={false}
          BotaoNovoFunction={() => navigate("/planejamento/cadastro/programa")}
          OpcoesDeArquivo={true}
          ButtonVisualizar={() => handlePrint(getDadosRelatorio)}
          ButtonPDF={(action) =>
            handleGeneratePdf(PdfPrograma, getDadosRelatorio, action)
          }
          ButtonDocx={() => handleGenerateDocx(getDadosRelatorio)}
          PdfData={pdfData}
          ButtonXls={handleGenerateDataXls}
        />
        <ToastContainer />

        <PagRelatorioComponente
          topoEsquerdoOptions={[
            { label: "Relatório", value: null },
            { label: "Relatório", value: 1 },
          ]}
          principalOptions={[
            {
              label: "Programa",
              value: "Programa",
            },
            // {
            //   label: "Teste",
            //   value: "Teste",
            // },
          ]}
          labelsCheckAbaPrincipal={selectBoxesOptionsAbaPrincipal}
          setLabelsCheckAbaPrincipal={setSelectBoxesOptionsAbaPrincipal}
          labelsCheckAbaOpcoes={selectBoxesOptionsAbaOptions}
          setLabelsCheckAbaOpcoes={setSelectBoxesOptionsAbaOptions}
          hasAssinatura={false}
          hasFavorite={false}
        />
      </Container>
    </>
  );
};

export default RelatorioPrograma;
