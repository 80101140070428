import React from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../../components/ListaDeInputs/IconAdicionar";

const Participantes = () => {
  return (
    <>
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={1}>
            <PTitulosInputs>Nº Licitação</PTitulosInputs>
            <FormControlListaDeInputs />
          </CCol>
          <CCol>
            <PTitulosInputs>Modalidade</PTitulosInputs>
            <FormControlListaDeInputs />
          </CCol>
          <CCol md={1} style={{ width: "17.5em" }}>
            <PTitulosInputs>Participante</PTitulosInputs>
            <FormControlListaDeInputs />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Valor</PTitulosInputs>
            <FormControlListaDeInputs />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Situação</PTitulosInputs>
            <FormControlListaDeInputs />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <IconAdicionar />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
      </Row>
    </>
  );
};

export default Participantes;
