import React from "react";
import { Container } from "react-bootstrap";

export const ContainerPag = ({ children }) => {
  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      {children}
    </Container>
  );
};
