import React, { useState, useEffect, useContext } from "react";
import { Row } from "react-bootstrap";
import { TituloAbaSolo } from "../../../../styles/StyledComponents/LayoutGerais";
import { CCol, CColButtonsAcoes2Icons } from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Icon } from "@iconify/react/dist/iconify.js";
import { especificacoes } from "../Riscos_Fiscais/Especificacoes";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { RowAninhada } from "../../../../components/Grid/CCol";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import api from "../../../../utils/api";
import { TestButton } from "../../../../utils/TestButton";

const Renuncia = ({
  arrayEspecificacoesNominal,
  setArrayEspecificacoesNominal,
  valueEspecificacao,
  setValueEspecificacao,
}) => {
  const { currentYear } = useContext(CurrentYearContext);

  const [values, setValues] = useState({});
  const [optionsNatRec, setOptionsNatRec] = useState([]);
  const [inputNatRec, setInputNatRec] = useState("");
  const [isEditing, setIsEditing] = useState({});
  const [oldValues, setOldValues] = useState({});

  const toggleEdit = (key, open, cancel) => {
    if (open) {
      setOldValues((prev) => ({
        ...prev,
        [key]: values[key],
      }));
    }

    setIsEditing((prevEditing) => ({
      ...prevEditing,
      [key]: !prevEditing[key],
    }));

    if (cancel) {
      setValues((prevValues) => ({
        ...prevValues,
        [key]: oldValues[key],
      }));
    }
  };

  const adicionarEspecificacao = () => {
    if (valueEspecificacao) {
      setValues((prevValues) => ({
        ...prevValues,
        [`${valueEspecificacao}`]: {
          modalidade: "",
          [currentYear - 3]: "",
          [currentYear - 2]: "",
          [currentYear - 1]: "",
          setoresProgramasBeneficiario: "",
          compensacao: "",
        },
      }));
    }
  };

  const handleModalidadeChange = (key, modalidade) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: {
        ...prevValues[key],
        modalidade,
      },
    }));
  };

  useEffect(() => {
    if (currentYear) {
      api
        .get(`/naturezaDaReceita/${currentYear}`)
        .then((response) => {
          const dataListaNatRec = response.data;

          const options = dataListaNatRec.map((item) => ({
            codigo: item.nr,
            titulo: item.especificacao,
            id: item.id,
          }));

          setOptionsNatRec(options); // Atualiza as opções formatadas
        })
        .catch((error) =>
          console.log(
            error.response?.data?.message ||
              "Algo deu errado ao obter os dados de Natureza da Receita. Por favor, tente mais tarde."
          )
        );
    }
  }, [currentYear]);

  return (
    <>
      <Row className="row_form mt-1">
        <TituloAbaSolo>
          Estimativa e Compensação da Renúncia de Receita
        </TituloAbaSolo>
      </Row>
      <Row className="row_form mt-1">
        <CCol>
          <MyAutoComplete
            labelInput="Natureza da Receita"
            inputValue={inputNatRec}
            setInputValue={setInputNatRec}
            style={{ height: "37px" }}
            placeholder="Nome ou Código da natureza da receita"
            options={optionsNatRec}
            onOptionSelect={(option) => setValueEspecificacao(option.label)}
            labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
            iconClearFunction={() => setValueEspecificacao("")}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar onClick={adicionarEspecificacao} />
        </CColButtonsAcoes2Icons>
      </Row>
      <Row className="row_form mt-1">
        {Object.entries(values).map(([key, value], index) => {
          console.log(key, value);
          return (
            <>
              <RowAninhada>
                <CCol>
                  <PTitulosInputs>Especificação</PTitulosInputs>
                  <FloatingLabelInput
                    disabled
                    value={key}
                    style={{ border: "none" }}
                  />
                </CCol>
                <CColButtonsAcoes2Icons md={1}>
                  <PTitulosInputs>Ação</PTitulosInputs>
                  <Icon
                    icon="ic:baseline-delete"
                    color="#E79900"
                    height="28"
                    // onClick={() => deleteEspecificacao(index)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2px",
                      marginTop: "4px",
                    }}
                  />
                </CColButtonsAcoes2Icons>
              </RowAninhada>
              <RowAninhada style={{ marginTop: "15px" }}>
                <CCol md={5}>
                  <PTitulosInputs>Modalidade</PTitulosInputs>
                  <FloatingLabelInput
                    disabled={!isEditing[key]}
                    height={"30px"}
                    placeholder={"Selecione uma Modalidade..."}
                    new={true}
                    tipo="input_select"
                    options={[...especificacoes]}
                    onSelect={(option) => {
                      handleModalidadeChange(key, option.value);
                    }}
                  />
                </CCol>
                <CCol>
                  <PTitulosInputs>{currentYear - 3}</PTitulosInputs>
                  <FormControlListaDeInputs
                    disabled={!isEditing[key]}
                    value={value[currentYear - 3] || ""}
                    onChange={(e) =>
                      setValues((prevValues) => ({
                        ...prevValues,
                        [key]: {
                          ...prevValues[key],
                          [currentYear - 3]: e.target.value,
                        },
                      }))
                    }
                  />
                </CCol>
                <CCol>
                  <PTitulosInputs>{currentYear - 2}</PTitulosInputs>
                  <FormControlListaDeInputs
                    disabled={!isEditing[key]}
                    value={value[currentYear - 2] || ""}
                    onChange={(e) =>
                      setValues((prevValues) => ({
                        ...prevValues,
                        [key]: {
                          ...prevValues[key],
                          [currentYear - 2]: e.target.value,
                        },
                      }))
                    }
                  />
                </CCol>
                <CCol>
                  <PTitulosInputs>{currentYear - 1}</PTitulosInputs>
                  <FormControlListaDeInputs
                    disabled={!isEditing[key]}
                    value={value[currentYear - 1] || ""}
                    onChange={(e) =>
                      setValues((prevValues) => ({
                        ...prevValues,
                        [key]: {
                          ...prevValues[key],
                          [currentYear - 1]: e.target.value,
                        },
                      }))
                    }
                  />
                </CCol>
                <CColButtonsAcoes2Icons md={1}>
                  <PTitulosInputs>Ação</PTitulosInputs>
                  <Icon
                    icon={isEditing[key] ? "fluent:save-24-filled" : "bx:edit"}
                    color={isEditing[key] ? "#008CFF" : "#5971C8"}
                    height="28"
                    onClick={() => toggleEdit(key, !isEditing[key])}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2px",
                      marginTop: "1px",
                    }}
                  />
                  {isEditing[key] && (
                    <Icon
                      icon="ic:baseline-cancel"
                      color="#F24E1E"
                      height="28"
                      onClick={() => toggleEdit(key, false, true)}
                      style={{
                        cursor: "pointer",
                        marginLeft: "2px",
                        marginTop: "1px",
                      }}
                    />
                  )}
                </CColButtonsAcoes2Icons>
              </RowAninhada>
              <RowAninhada style={{ marginTop: "15px" }}>
                <CCol>
                  <PTitulosInputs>
                    Setores /Programas/ Beneficiário
                  </PTitulosInputs>
                  <FormControlListaDeInputs
                    disabled={!isEditing[key]}
                    value={value.setoresProgramasBeneficiario || ""}
                    onChange={(e) =>
                      setValues((prevValues) => ({
                        ...prevValues,
                        [key]: {
                          ...prevValues[key],
                          setoresProgramasBeneficiario: e.target.value,
                        },
                      }))
                    }
                  />
                </CCol>
                <CCol>
                  <PTitulosInputs>Compensação</PTitulosInputs>
                  <FormControlListaDeInputs
                    disabled={!isEditing[key]}
                    value={value.compensacao || ""}
                    onChange={(e) =>
                      setValues((prevValues) => ({
                        ...prevValues,
                        [key]: {
                          ...prevValues[key],
                          compensacao: e.target.value,
                        },
                      }))
                    }
                  />
                </CCol>
                <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
              </RowAninhada>
            </>
          );
        })}
      </Row>
      <TestButton onClick={() => console.log(values)}>Console</TestButton>
    </>
  );
};

export default Renuncia;
