import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfDespesaVinculado = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  
  const styledCol = [
    {
      flexBasis: "170px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "100%",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "170px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "170px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const optionDespesaVinculo = opcoes.find(
    (option) => option.opcao === "Despesa Vinculo"
  );
 
  const renderReceitas = (codigo, titulo, despesa) => {
    const seccoes = (codigo.match(/\./g) || []).length;

    const despesaStyleBold = seccoes > 2;

    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            margin: "0 -3px",
            fontWeight: despesaStyleBold ? "bold" : "normal",
          },
        ]}
      >
        {optionDespesaVinculo?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={codigo || ""}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textAlign: "left",
              },
            ]}
          />
        )}
        {optionDespesaVinculo?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={titulo}
            styledCol={[styledCol[1], { padding: "2px 0", textAlign: "left" }]}
          />
        )}

        {optionDespesaVinculo?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={
              despesa.totalOrdinario
                ? FormatValueToLocaleString(despesa.totalOrdinario)
                : ""
            }
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaVinculo?.checkBoxes[3].selected && (
          <PdfInfos
            pdfInfo={
              despesa.totalVinculo
                ? FormatValueToLocaleString(despesa.totalVinculo)
                : ""
            }
            styledCol={[
              styledCol[3],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderSubTotal = (total) => {
    return (
      <>
        <View
          wrap={false}
          style={[
            stylesPdf.divInferiorColuna,
            {
              fontWeight: "bold",
              fontSize: 10,
              textTransform: "uppercase",
              color: "white",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 0,
              paddingLeft: 10,
            },
          ]}
        >
          <Text>subtotal</Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {total.totalOrdinario
                ? FormatValueToLocaleString(total.totalOrdinario)
                : ""}
            </Text>
            <Text
              style={{
                width: 90,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {total.totalVinculo
                ? FormatValueToLocaleString(total.totalVinculo)
                : ""}
            </Text>
          </View>
        </View>
      </>
    );
  };

  const renderTotal = (total) => {
    return (
      <View
        wrap={false}
        style={[
          stylesPdf.divInferiorColuna,
          {
            fontWeight: "bold",
            fontSize: 10,
            textTransform: "uppercase",
            color: "white",
            flexDirection: "row",
            justifyContent: "space-between",
          },
        ]}
      >
        <Text>total geral</Text>
        <Text>{total}</Text>
      </View>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        plusText={"Anexo VIII "}
        nameRelatorio={"Conforme o Vínculo com os Recursos"}
        nameProjeto={
          "Demonstrativo da Despesa por Funções, Subfunção, Programas, Ações"
        }
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {optionDespesaVinculo?.checkBoxes?.map((option, index) => (
          <PdfColunas
            key={index}
            colName={option.label.toUpperCase()}
            styledCol={styledCol[index]}
            hideBar
          />
        ))}
      </View>
      <React.Fragment>
        <React.Fragment>
          {dados.dataDespesa.map((despesa, unidadeIndex) => (
            <View key={unidadeIndex} break={unidadeIndex > 0}>
              {despesa.infoUnidade.unidades.map((funcao, indexFuncao) => {
                const codigoFuncao = funcao.codigoFuncao;
                return (
                  <React.Fragment key={indexFuncao}>
                    {renderReceitas(codigoFuncao, funcao.tituloFuncao, funcao)}
                    {funcao.subFuncoes.map((subFuncao, indexSubFuncao) => {
                      const codigoSubFuncao = `${codigoFuncao}.${subFuncao.codigoSubFuncao}`;
                      return (
                        <React.Fragment key={indexSubFuncao}>
                          {renderReceitas(
                            codigoSubFuncao,
                            subFuncao.tituloSubFuncao,
                            subFuncao
                          )}
                          {subFuncao.programas.map(
                            (programa, indexPrograma) => {
                              const codigoPrograma = `${codigoSubFuncao}.${programa.codigoPrograma}`;
                              return (
                                <React.Fragment key={indexPrograma}>
                                  {renderReceitas(
                                    codigoPrograma,
                                    programa.tituloPrograma,
                                    programa
                                  )}
                                  {programa.acoes.map((acao, indexAcao) => {
                                    const codigoAcao = `${codigoPrograma}.${acao.nAcao}`;
                                    return (
                                      <React.Fragment key={indexAcao}>
                                        {renderReceitas(
                                          codigoAcao,
                                          acao.tituloAcao,
                                          acao
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </React.Fragment>
                              );
                            }
                          )}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}

              {renderSubTotal(despesa.infoUnidade.totalGeralUnidade)}
            </View>
          ))}

          <View style={{ marginTop: 10 }}>
            {renderTotal(FormatValueToLocaleString(dados.total))}
          </View>
        </React.Fragment>
      </React.Fragment>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
