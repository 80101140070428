//import { calcularTotalReceitasComDeducao } from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
// import api from "../../../../../../utils/api";

import { getUrl } from "../getUrlConsolidado";

export const getDespesaDemonstrativoRegionalizado = async (
  clientes,
  entidadesGestoras,
  ano = 2024
) => {
  const url = getUrl(clientes, entidadesGestoras);
  console.log(url);

  // try {
  //   const response = await api.get(`qdd/relatorio-qdd/${clienteId}/${ano}`);

  //   return response.data;
  // } catch (error) {
  //   console.error("Erro na requisição:", error);
  //   return {
  //     error: "error",
  //     name: "Quadro Detalhado da Despesa - QDD por Modalidade de Aplicação",
  //   };
  // }
  return ["1", ""];
};

// const processAllData = () => {
//   return "";
// };
