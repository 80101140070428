import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";

import { Container } from "react-bootstrap";
import {
  CCol,
  CColSelectTipoDePrograma,
  RowToModal,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import styled from "styled-components";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import { Icon } from "@iconify/react";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import api from "../../../../utils/api";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { OpenedModalsContext } from "../../../../contexts/OpenedModalsContext";
import ModalAutoComplete from "../../../../components/Modais/ModalAutoComplete";
import EixoEstrategico from "../EixoEstrategico/EixoEstrategico";
import ButtonSaveModalAutoComplete from "../../../../components/Modais/ButtonSaveModalAutoComplete";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { DefinirRequisicaoDoModalComoConluida } from "../../../../utils/Gerais/DefinirRequisicaoDoModalComoConcluida";
import Indicadores from "../../Indicadores/Indicadores";
import { CurrentYearContext } from "../../../../contexts/YearContext";

const TituloInticadores = styled.p`
  font-weight: 600;
  color: #515c70;
`;

const CColUnidadeDeMedida = styled(CCol)`
  @media (min-width: 768px) {
    width: 15%;
  }
`;

const CColUnidades = styled(CCol)`
  @media (min-width: 768px) {
    width: 13%;
  }
`;

function Programa({ isAModalPage = false }) {
  const navigate = useNavigate();
  const { id: paramsIdPrograma } = useParams();
  const floatingLabelInputRef = useRef(null);
  const [inputAutocompleteEixo, setInputAutocompleteEixo] = useState("");
  const [inputAutocompleteObjetivo, setInputAutocompleteObjetivo] =
    useState("");
  const [inputAutocompleteIndicador, setInputAutocompleteIndicador] =
    useState("");

  const [optionsInputEixo, setOptionsInputEixo] = useState([]);
  const [optionsInputObjetivo, setOptionsInputObjetivo] = useState([]);
  const [optionsInputIndicador, setOptionsInputIndicador] = useState([]);
  const [tiposDePrograma, setTiposDePrograma] = useState([]);
  const [selectTiposOptions, setSelectTiposOptions] = useState([]);
  const [editValuesVBase, setEditValuesVBase] = useState([]);
  // const [editValuesReferencia, setEditValuesReferencia] = useState([]);
  const [editValuesPPA, setEditValuesPPA] = useState([]);
  const [editing, setEditing] = useState(false);
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useContext(CurrentYearContext);
  const [countClientChange, setCountClientChange] = useState(0);
  const [lengthProgramasPadrao, setLengthProgramasPadrao] = useState(0);

  const { openedModals, setOpenedModals } = useContext(OpenedModalsContext);

  const [valuesPrograma, setValuesPrograma] = useState({
    clienteId: currentClient.clienteId,
    tipoDeProgramaId: null,
    eixoId: null,
    objetivoMilenioId: null,
    objetivos: "",
    publicoAlvo: "",
    titulo: "",
    numeroPrograma: "",
    meta: "",
    indicadorTipoDePrograma: "",
    indicadorPrograma: "",
    dataPrograma: "",
    indicadores: [],
  });

  const [indicadorAdicionar, setIndicadorAdicionar] = useState({
    indicadorProgramaId: null,
    codigo: "",
    descricao: "",
    unidadeMedida: "",
    valorBase: "", //int
    valorFinalPPA: "", //int
    disabled: true,
  });

  const [indicadoresJaCadastrados, setIndicadoresJaCadastrados] = useState([]);

  const [deleteData, setDeleteData] = useState({
    codigoIndicador: "",
  });
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    setCountClientChange((prevValue) => prevValue + 1);
    console.log("passou pelo if do navigate");
    if (countClientChange === 2 && paramsIdPrograma) {
      navigate("/planejamento/cadastro/programa/consulta");
    }
    //eslint-disable-next-line
  }, [currentClient]);

  const getEixoEstrategico = (id) => {
    api
      .get(`eixoEstrategicoPlanejamento/pesquisa/id/${id}`)
      .then((resp) => {
        console.log(resp.data);
        setInputAutocompleteEixo(`${resp.data.codigo} - ${resp.data.titulo}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getObjetivoDoMilenio = (id) => {
    console.log(id);
    api
      .get(`objetivoDoMilenio/pesquisa/id/${id}`)
      .then((resp) => {
        console.log(resp.data);
        setInputAutocompleteObjetivo(
          `${resp.data.codigo} - ${resp.data.descricao}`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchProgramasDoCliente = () => {
    api
      .get(`programaPlanejamento/por-id/${paramsIdPrograma}`)
      .then((resp) => {
        // setProgramasCadastradosArray(resp.data);
        console.log(resp.data);
        selecionaProgramaAtualizar(resp.data);
        getEixoEstrategico(resp.data.eixoId);
        getObjetivoDoMilenio(resp.data.objetivoMilenioId);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Algo deu errado ao consultar os dados desse cadastro. Por favor, tente mais tarde"
        );
      });
  };

  useEffect(() => {
    if (paramsIdPrograma && !isAModalPage) {
      console.log("paramsIdPrograma:" + paramsIdPrograma);
      fetchProgramasDoCliente();
    }

    //eslint-disable-next-line
  }, [paramsIdPrograma, isAModalPage]);

  const selecionaProgramaAtualizar = (programaData) => {
    setValuesPrograma((prevValues) => ({
      ...prevValues,
      clienteId: programaData.clienteId,
      tipoDeProgramaId: programaData.tipoDeProgramaId,
      eixoId: programaData.eixoId,
      objetivoMilenioId: programaData.objetivoMilenioId,
      objetivos: programaData.objetivos,
      publicoAlvo: programaData.publicoAlvo,
      titulo: programaData.titulo,
      numeroPrograma: programaData.numeroPrograma,
      meta: programaData.meta,
      indicadorTipoDePrograma: programaData.indicadorTipoDePrograma,
      indicadorPrograma: programaData.indicadorPrograma,
      dataPrograma: programaData.dataPrograma,
      indicadores: Array.isArray(programaData.indicadores)
        ? programaData.indicadores.map((item) => ({
            indicadorProgramaId: item.indicadorProgramaId,
            codigo: item.codigo,
            descricao: item.descricao,
            unidadeMedida: item.unidadeMedida,
            valorBase: item.valorBase, //int
            valorFinalPPA: item.valorFinalPPA, //int
            disabled: true,
          }))
        : [],
    }));

    setIndicadoresJaCadastrados(
      Array.isArray(programaData.indicadores) ? programaData.indicadores : []
    );
  };

  const fetchTiposDePrograma = () => {
    api
      .get(`tipoDePrograma/por-regiao/${currentClient.endereco.municipio}`)
      .then((resp) => {
        const data = resp.data;
        console.log(data);

        const arrayTiposDeProgramaOptions = data.map((item) => ({
          value: item.id,
          label: `${item.codigo} - ${item.descricao}`,
        }));

        const arrayTiposDeProgramaInfos = data.map((item) => ({
          id: item.id,
          codigo: item.codigo,
          descricao: item.descricao,
          indicador: item.indicador,
        }));

        setSelectTiposOptions(arrayTiposDeProgramaOptions);
        setTiposDePrograma(arrayTiposDeProgramaInfos);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchTiposDePrograma();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (valuesPrograma.tipoDeProgramaId !== null) {
      verificaProgramaIndicador();
      fetchNumeroPrograma();
    }
    //eslint-disable-next-line
  }, [valuesPrograma.tipoDeProgramaId]);

  const fetchNumeroPrograma = () => {
    if (!paramsIdPrograma) {
      console.log("TIPOS DE PROGRAMA: ", tiposDePrograma);
      const { codigo } = tiposDePrograma.find(
        (item) => item.id === valuesPrograma.tipoDeProgramaId
      );

      console.log("fetch do programa id:" + codigo);

      api
        .get(
          `programaPlanejamento/cliente/${currentClient.clienteId}/tipoDePrograma/${codigo}/${currentYear}`
        )
        .then((resp) => {
          console.log(resp);
          const data = resp.data;
          setValuesPrograma((prevValues) => ({
            ...prevValues,
            numeroPrograma: data.proximoNumeroDoPrograma,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const verificaProgramaIndicador = () => {
    const tipoDeProgramaSelecioando = tiposDePrograma.find(
      (item) => item.id === valuesPrograma.tipoDeProgramaId
    );

    if (tipoDeProgramaSelecioando) {
      setValuesPrograma((prevValues) => ({
        ...prevValues,
        indicadorTipoDePrograma: tipoDeProgramaSelecioando.indicador,
        indicadorPrograma: tipoDeProgramaSelecioando.indicador,
      }));
    } else {
      console.log("Não existe");
      if (!valuesPrograma.tipoDeProgramaSelecioando) {
        //toast.error("Algo deu errado ao selecionar o tipo de programa.");
        //dando erro ao entrar 2 vezes
        console.log("Algo deu errado ao selecionar o tipo de programa.");
      }
    }
  };

  const fetchOptionsEixo = (reqDeModal) => {
    api
      .get(
        `eixoEstrategicoPlanejamento/cliente/${currentClient.clienteId}/${currentYear}/filtro?filter=`
      )
      .then((resp) => {
        const data = resp.data;
        console.log(data);

        const arrayOptions = data.map((item) => ({
          id: item.id,
          titulo: item.titulo,
          codigo: item.codigo,
        }));

        console.log(arrayOptions);
        setOptionsInputEixo(arrayOptions);

        if (reqDeModal) {
          const lastPostedData = data.reverse()[0];
          console.log(lastPostedData);

          console.log("reqDeModal");

          setInputAutocompleteEixo(
            `${lastPostedData.codigo} - ${lastPostedData.titulo}`
          );
          setValuesPrograma((prev) => ({
            ...prev,
            eixoId: lastPostedData.id,
          }));

          setOpenedModals((prev) =>
            prev.filter((item) => item.tituloModal !== "Eixo Estratégico")
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchOptionsObjetivo = useCallback(() => {
    const fixedString = inputAutocompleteObjetivo.replace(/^\d+\s*-\s*/, "");
    api
      .get(`objetivoDoMilenio/por-filtro/filtro?filter=${fixedString}`)
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        const arrayOptions = data.map((item) => ({
          id: item.id,
          descricao: item.descricao,
          codigo: item.codigo,
        }));

        setOptionsInputObjetivo(arrayOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [inputAutocompleteObjetivo]);

  const fetchOptionsIndicador = (reqDeModal) => {
    api
      .get(
        `programaPlanejamento/indicador/${currentClient.clienteId}/filtro?filter=`
      )
      .then((resp) => {
        const data = resp.data;
        // const length = data.length - 1;
        console.log(data);
        const options = data.map((item) => ({
          id: item.id,
          codigo: item.codigo,
          descricao: item.descricao,
          unidadeMedida: item.unidadeDeMedida,
        }));
        console.log(options);
        setOptionsInputIndicador(options);

        if (reqDeModal) {
          const lastPostedData = data.reverse()[0];
          console.log(lastPostedData);

          console.log("reqDeModal");

          setInputAutocompleteIndicador(
            `${lastPostedData.codigo} - ${lastPostedData.descricao}`
          );
          setIndicadorAdicionar((prevValues) => ({
            ...prevValues,
            indicadorProgramaId: lastPostedData.id,
            codigo: lastPostedData.codigo,
            descricao: lastPostedData.descricao,
            unidadeMedida: lastPostedData.unidadeDeMedida,
            disabled: true,
          }));

          setOpenedModals((prev) =>
            prev.filter((item) => item.tituloModal !== "Indicadores")
          );
        }
      });
  };

  useEffect(() => {
    fetchOptionsObjetivo();
  }, [fetchOptionsObjetivo]);

  useEffect(() => {
    fetchOptionsEixo();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchOptionsIndicador();

    //eslint-disable-next-line
  }, []);

  const handleAutocompleteEixo = (option) => {
    if (option) {
      console.log(option.id);
      setValuesPrograma((prevValues) => ({
        ...prevValues,
        eixoId: option.id,
      }));
    }
  };

  const handleAutocompleteObjetivo = (option) => {
    if (option) {
      console.log(`id do item: ${option.id}`);
      setValuesPrograma((prevValues) => ({
        ...prevValues,
        objetivoMilenioId: option.id,
      }));
    }
  };

  const handleAutocompleteChangeIndicador = (option) => {
    console.log(option);
    if (option) {
      console.log(`id do indicador: ${option.id}`);
      setIndicadorAdicionar((prevValues) => ({
        ...prevValues,
        indicadorProgramaId: option.id,
        codigo: option.codigo,
        descricao: option.descricao,
        unidadeMedida: option.unidadeMedida,
        disabled: true,
      }));
    }
  };

  const adicionaIndicador = () => {
    let condition = true;
    let indicadorJaExiste = false;
    console.log(indicadorAdicionar);

    valuesPrograma.indicadores.forEach((item) => {
      if (item.codigo === indicadorAdicionar.codigo) {
        condition = false;
        console.log("opa, esse item ja existe no array de indicadores.");
        indicadorJaExiste = true;
      }
    });

    if (condition) {
      Object.keys(indicadorAdicionar).forEach((item) => {
        if (
          indicadorAdicionar[item] === "" ||
          indicadorAdicionar[item] === null
        ) {
          condition = false;
        }
      });
    }

    if (condition) {
      console.log("tudo ok");
      console.log([...valuesPrograma.indicadores, indicadorAdicionar]);
      setValuesPrograma((prevValues) => ({
        ...prevValues,
        indicadores: [...prevValues.indicadores, indicadorAdicionar],
      }));

      limpaIndicadorAdicionar();
    } else {
      if (indicadorJaExiste) {
        toast.warning(
          "Esse indicador ja está associado a esse cadastro de Programa."
        );
      } else {
        toast.warning(
          "Preencha os campos necessarios para adicionar um indicador ao cadastro."
        );
      }
    }
  };

  const limpaIndicadorAdicionar = () => {
    setIndicadorAdicionar({
      indicadorProgramaId: null,
      codigo: "",
      descricao: "",
      unidadeMedida: "",
      valorBase: "0,00", //int
      valorFinalPPA: "0,00", //int
    });

    setInputAutocompleteIndicador("");
  };

  const deletarIndicadorDoArray = (codigo) => {
    console.log("entrou no delete de indicador");
    if (
      paramsIdPrograma &&
      indicadoresJaCadastrados.find((item) => item.codigo === parseInt(codigo))
    ) {
      const deleteId = indicadoresJaCadastrados.find(
        (item) => item.codigo === parseInt(codigo)
      ).id;
      api
        .delete(
          `programaPlanejamento/programa/${paramsIdPrograma}/indicador/${deleteId}`
        )
        .then((resp) => {
          console.log(resp);
          toast.success("Indicador deletado com sucesso!");
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            "Occoreu um erro ao deletar o indicador. Por favor, tente mais tarde."
          );
        });
    }

    let arrayCopia = [...valuesPrograma.indicadores];
    console.log(arrayCopia);

    arrayCopia = arrayCopia.filter((item) => item.codigo !== codigo);

    setValuesPrograma((prevValues) => ({
      ...prevValues,
      indicadores: arrayCopia,
    }));
  };

  const cadastrarPrograma = () => {
    const arrayIndicadoresNew = valuesPrograma.indicadores.map((item) => {
      const { codigo, descricao, disabled, editavel, ...restante } = item;
      return restante;
    });

    const valuesToPost = {
      ...valuesPrograma,
      indicadores: arrayIndicadoresNew,
    };
    console.log(valuesToPost);
    api
      .post(`programaPlanejamento`, valuesToPost)
      .then((resp) => {
        console.log(resp);
        toast.success("Programa cadastrado com sucesso!");
        limparCampos();

        if (isAModalPage) {
          DefinirRequisicaoDoModalComoConluida(
            openedModals,
            setOpenedModals,
            "Programa"
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error(
            "Algo deu errado, por favor, tente mais tarde ou entre em contato com o suporte."
          );
        }
        console.log(error);
      });
  };

  const atualizaPrograma = () => {
    const indicadoresNovos = valuesPrograma.indicadores.map((item) => {
      const { codigo, descricao, disabled, editavel, ...restante } = item;
      return restante;
    });

    console.log(indicadoresNovos);

    const valuesAtualizacao = {
      ...valuesPrograma,
      indicadores: indicadoresNovos,
    };

    console.log(valuesAtualizacao);

    api
      .put(
        `programaPlanejamento/atualiza/programa/${paramsIdPrograma}`,
        valuesAtualizacao
      )
      .then((resp) => {
        console.log(resp.data);
        toast.success("Programa atualizado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Ocorreu um erro ao atualizar o cadastro de Programa. Por favor, tente mais tarde."
        );
      });
  };

  function openModal() {
    setModalOpened(true);
  }

  function closeModal() {
    setModalOpened(false);
  }

  useEffect(() => {
    const initialEditValuesPPA = valuesPrograma.indicadores.map(
      (item) => item.valorFinalPPA
    );
    const initialEditValuesVBase = valuesPrograma.indicadores.map(
      (item) => item.valorBase
    );
    setEditValuesPPA(initialEditValuesPPA);
    setEditValuesVBase(initialEditValuesVBase);
  }, [valuesPrograma.indicadores]);

  const limparCampos = () => {
    setInputAutocompleteEixo("");
    setInputAutocompleteObjetivo("");
    setInputAutocompleteIndicador("");
    setEditValuesVBase([]);
    setEditValuesPPA([]);
    setEditing(false);
    setValuesPrograma({
      clienteId: currentClient.clienteId,
      tipoDeProgramaId: null,
      eixoId: null,
      objetivoMilenioId: null,
      objetivos: "",
      publicoAlvo: "",
      titulo: "",
      numeroPrograma: "",
      meta: "",
      indicadorTipoDePrograma: "",
      indicadorPrograma: null,
      dataPrograma: "",
      indicadores: [],
    });
    setIndicadorAdicionar({
      indicadorProgramaId: null,
      codigo: "",
      descricao: "",
      unidadeMedida: "",
      valorBase: "",
      valorFinalPPA: "",
      disabled: true,
    });
    // setProgramasCadastradosArray([]);
    setIndicadoresJaCadastrados([]);
    // setEixoAtual({
    //   titulo: "",
    //   codigo: "",
    // });
    // setObjetivoMilenioAtual({
    //   titulo: "",
    //   codigo: "",
    // });
    // setContadorEixoObjetivo(0);
    setDeleteData({
      codigoIndicador: "",
    });
    setModalOpened(false);
    // if (floatingLabelInputRef.current) {
    //   floatingLabelInputRef.current.onSelect({ value: null, label: "" });
    //   floatingLabelInputRef.current.onChange({ value: null, label: "" });
    // }
  };

  const getCodigoTipoDeProgramaPadrao = () => {
    api
      .get("tipoDePrograma/padrao")
      .then((resp) => {
        setLengthProgramasPadrao(resp.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCodigoTipoDeProgramaPadrao();
  }, []);

  const handleAddNewEixoEstrategico = () => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: "Eixo Estratégico", requisicaoDoModalConcluida: false },
    ]);
  };

  const handleOpenModalIndicador = () => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: "Indicadores", requisicaoDoModalConcluida: false },
    ]);
  };

  useEffect(() => {
    const modalEixoEstrategico = openedModals.find(
      (item) => item.tituloModal === "Eixo Estratégico"
    );

    const modalIndicadores = openedModals.find(
      (item) => item.tituloModal === "Indicadores"
    );

    if (
      modalEixoEstrategico &&
      modalEixoEstrategico.requisicaoDoModalConcluida
    ) {
      fetchOptionsEixo(true);
    }

    if (modalIndicadores && modalIndicadores.requisicaoDoModalConcluida) {
      fetchOptionsIndicador(true);
    }

    //eslint-disable-next-line
  }, [openedModals]);

  return (
    <Container
      id={isAModalPage ? undefined : "pags_responsividade_padding_geral"}
      fluid
      style={
        isAModalPage
          ? {
              width: "70vw",
              paddingLeft: "0px",
              paddingRight: "0px",
              margin: "0px",
              // backgroundColor: "#f5f5f5",
            }
          : {}
      }
      className={
        isAModalPage ? undefined : "container_conteudo conteudo_pagina"
      }
    >
      <ModalAutoComplete
        tituloModal={"Eixo Estratégico"}
        Component={<EixoEstrategico isAModalPage={true} />}
      />

      <ModalAutoComplete
        tituloModal={"Indicadores"}
        Component={<Indicadores isAModalPage={true} />}
      />

      {!isAModalPage && (
        <HeaderCadastros
          NomePaginaTitulo={"Programa"}
          PaginaSubtitulo={"Cadastro"}
          PaginaRelatorio={"/planejamento/cadastro/programa/relatorio"}
          ButtonSaveFunction={
            paramsIdPrograma ? atualizaPrograma : cadastrarPrograma
          }
          PaginaConsulta={"/planejamento/cadastro/programa/consulta"}
        />
      )}

      {isAModalPage && (
        <ButtonSaveModalAutoComplete
          SaveFunction={cadastrarPrograma}
          pageTitle={"Programa"}
        />
      )}

      <ModalDeleteData
        closeModal={closeModal}
        modalOpened={modalOpened}
        setDataDelete={setDeleteData}
        deleteDataFunction={() =>
          deletarIndicadorDoArray(deleteData.codigoIndicador)
        }
      />

      {!isAModalPage && <ToastContainer />}

      <RowToModal isAModalPage={isAModalPage} className="row_form mt-1">
        {/* <button onClick={() => console.log(valuesPrograma)}>console</button>
        <button
          onClick={() => {
            const arrayNovo = valuesPrograma.indicadores.map((item) => {
              const { codigo, ...restante } = item;
              return restante;
            });

            console.log(arrayNovo);
          }}
        >
          Teste de objeto
        </button> */}

        <CColSelectTipoDePrograma md={2}>
          <FloatingLabelInput
            hasScroll={selectTiposOptions.length > lengthProgramasPadrao}
            new={true}
            ref={floatingLabelInputRef}
            tipo="input_select"
            label="Tipo De Programa"
            placeholder="Tipo de Programa"
            value={valuesPrograma.tipoDeProgramaId}
            onSelect={(option) =>
              setValuesPrograma((prevValues) => ({
                ...prevValues,
                tipoDeProgramaId: parseInt(option.value),
              }))
            }
            options={[
              { label: "Tipo de Programa", value: null },
              ...selectTiposOptions,
            ]}
          />
        </CColSelectTipoDePrograma>
        <CCol>
          <FloatingLabelInput
            placeholder="Título"
            label="Título"
            value={valuesPrograma.titulo}
            onChange={(e) => {
              setValuesPrograma((prevValues) => ({
                ...prevValues,
                titulo: e.target.value,
              }));
            }}
          />
        </CCol>
      </RowToModal>

      <RowToModal
        isAModalPage={isAModalPage}
        className="row_form"
        style={{ marginTop: "-14px" }}
      >
        <CColSelectTipoDePrograma md={2}>
          <FloatingLabelInput
            placeholder="Nº Programa"
            label="Nº Programa"
            maxLength="4"
            onChange={(e) => {
              setValuesPrograma((prev) => ({
                ...prev,
                numeroPrograma: e.target.value.replace(/[^0-9]/g, ""),
              }));
            }}
            value={valuesPrograma.numeroPrograma}
          />
        </CColSelectTipoDePrograma>

        <CCol>
          <FloatingLabelInput
            as="textarea"
            tipo="text_area"
            placeholder="Objetivos"
            label="Objetivos"
            value={valuesPrograma.objetivos}
            onChange={(e) => {
              const newValue = e.target.value;
              if (newValue.length <= 800) {
                setValuesPrograma((prevValues) => ({
                  ...prevValues,
                  objetivos: e.target.value,
                }));
              }
            }}
            style={{ minHeight: "38px", height: "38px" }}
          />
        </CCol>
      </RowToModal>

      <RowToModal isAModalPage={isAModalPage} className="row_form mt-1">
        {/* AutoComplete Eixo Estrategico */}
        <CCol md={12}>
          <MyAutoComplete
            labelInput="Eixo Estratégico"
            placeholder="Eixo Estratégico"
            style={{ height: "37px" }}
            inputValue={inputAutocompleteEixo}
            addNewDataFunction={handleAddNewEixoEstrategico}
            setInputValue={setInputAutocompleteEixo}
            options={optionsInputEixo}
            labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
            onOptionSelect={handleAutocompleteEixo}
            iconClearFunction={() => setInputAutocompleteEixo("")}
          />
        </CCol>
      </RowToModal>

      {/* {paramsIdPrograma && (
        <Row className="row_form" style={{ marginTop: "-14px" }}>
          <CCol md={12}>
            <FloatingLabelInput
              disabled
              value={`${eixoAtual.codigo} - ${eixoAtual.titulo}`}
              placeholder="Eixo Estratégico Atual"
              label="Eixo Estratégico Atual"
            />
          </CCol>
        </Row>
      )} */}

      <RowToModal
        isAModalPage={isAModalPage}
        className="row_form"
        style={{ marginTop: "-14px" }}
      >
        <CCol md={12}>
          <MyAutoComplete
            labelInput="Objetivo do Milênio"
            placeholder="Objetivo do Milênio"
            style={{ height: "37px" }}
            options={optionsInputObjetivo}
            labelFormat={(option) => `${option.codigo} - ${option.descricao}`}
            onOptionSelect={handleAutocompleteObjetivo}
            inputValue={inputAutocompleteObjetivo}
            setInputValue={setInputAutocompleteObjetivo}
            iconClearFunction={() => setInputAutocompleteObjetivo("")}
          />
        </CCol>
      </RowToModal>
      {/* {paramsIdPrograma && (
        <Row className="row_form" style={{ marginTop: "-14px" }}>
          <CCol md={12}>
            <FloatingLabelInput
              disabled
              value={`${objetivoMilenioAtual.codigo} - ${objetivoMilenioAtual.titulo}`}
              placeholder="Objetivo do Milênio Atual"
              label="Objetivo do Milênio Atual"
            />
          </CCol>
        </Row>
      )} */}

      <RowToModal
        isAModalPage={isAModalPage}
        className="row_form_2"
        style={{ marginTop: "-14px" }}
      >
        <CCol>
          <FloatingLabelInput
            style={{ height: "37px" }}
            placeholder="Público Alvo"
            label="Público Alvo"
            value={valuesPrograma.publicoAlvo}
            onChange={(e) => {
              setValuesPrograma((prevValues) => ({
                ...prevValues,
                publicoAlvo: e.target.value,
              }));
            }}
          />
        </CCol>
      </RowToModal>

      <RowToModal
        isAModalPage={isAModalPage}
        className="row_endereco_conteudo p-2 mt-2"
      >
        <span style={{ marginLeft: "-2px" }}>Meta</span>
      </RowToModal>

      <RowToModal
        isAModalPage={isAModalPage}
        className="row_form_2 mt-1"
        style={
          valuesPrograma.indicadorTipoDePrograma === "Sim" ||
          valuesPrograma.indicadorPrograma === "Sim"
            ? { borderRadius: "0px" }
            : { borderRadius: "0px 0px 5px 5px" }
        }
      >
        <CCol>
          <FloatingLabelInput
            placeholder="Meta"
            label="Meta"
            value={valuesPrograma.meta}
            onChange={(e) => {
              const newValue = e.target.value.slice(0, 100);
              setValuesPrograma((prevValues) => ({
                ...prevValues,
                meta: newValue,
              }));
            }}
          />
        </CCol>

        <CCol md={2}>
          <FloatingLabelInput
            new={true}
            tipo="input_select"
            placeholder="Indicador"
            label="Indicador"
            value={valuesPrograma.indicadorPrograma}
            onSelect={(option) => {
              setValuesPrograma((prevValues) => ({
                ...prevValues,
                indicadorPrograma: option.value,
              }));
            }}
            options={[
              { label: "Indicador", value: null },
              { label: "Sim", value: "Sim" },
              { label: "Não", value: "Não" },
            ]}
          />
        </CCol>
      </RowToModal>

      {(valuesPrograma.indicadorTipoDePrograma === "Sim" ||
        valuesPrograma.indicadorPrograma === "Sim") && (
        <React.Fragment>
          <RowToModal isAModalPage={isAModalPage} className="row_form mt-1">
            <CCol style={{ marginTop: "-7px" }}>
              <TituloInticadores>Indicadores</TituloInticadores>
            </CCol>
          </RowToModal>

          {/* 1º linha - Inputs */}
          <RowToModal
            isAModalPage={isAModalPage}
            className="row_form_2"
            style={{ marginTop: "-30px" }}
          >
            <CCol md={1}>
              <PTitulosInputs>Código</PTitulosInputs>
              <FormControlListaDeInputs
                disabled
                value={indicadorAdicionar.codigo}
                radiusborder={
                  valuesPrograma.indicadores.length > 0 && "5px 5px 0px 0px"
                }
              />
            </CCol>
            <CCol md={5}>
              <PTitulosInputs>Descrição ou Código do Indicador</PTitulosInputs>
              <MyAutoComplete
                inputValue={inputAutocompleteIndicador}
                setInputValue={setInputAutocompleteIndicador}
                options={optionsInputIndicador}
                radiusborder={
                  valuesPrograma.indicadores.length > 0
                    ? "5px 5px 0px 0px"
                    : null
                }
                labelFormat={(option) =>
                  `${option.codigo} - ${option.descricao}`
                }
                onOptionSelect={handleAutocompleteChangeIndicador}
                placeholder="Descrição ou Código do Indicador"
                style={{ width: "100%" }}
                iconClearFunction={limpaIndicadorAdicionar}
                addNewDataFunction={handleOpenModalIndicador}
              />
            </CCol>
            <CColUnidadeDeMedida md={1}>
              <PTitulosInputs>Unidade de Medida</PTitulosInputs>
              <div className="input_icon_arrow_dir_formListaDeInputs">
                <FormControlListaDeInputs
                  disabled
                  radiusborder={
                    valuesPrograma.indicadores.length > 0 && "5px 5px 0px 0px"
                  }
                  value={indicadorAdicionar.unidadeMedida}
                />
              </div>
            </CColUnidadeDeMedida>

            <CColUnidades md={2}>
              <PTitulosInputs>Valor Base</PTitulosInputs>
              <InputFormatRealBrasileiro
                radiusborder={
                  valuesPrograma.indicadores.length > 0 && "5px 5px 0px 0px"
                }
                listaDeInputs={true}
                externalValue={indicadorAdicionar.valorBase}
                onChange={(e, valor) => {
                  const valorFormatado = valor.replace(",", ".");
                  console.log(valorFormatado);
                  setIndicadorAdicionar((prevValues) => ({
                    ...prevValues,
                    valorBase: valorFormatado,
                  }));
                }}
              />
              {/* <FormControlListaDeInputs
                radiusborder={
                  valuesPrograma.indicadores.length > 0 && "5px 5px 0px 0px"
                }
                value={indicadorAdicionar.valorBase}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                  setIndicadorAdicionar((prevValues) => ({
                    ...prevValues,
                    valorBase: e.target.value,
                  }));
                  console.log(e.target.value);
                }}
              /> */}
            </CColUnidades>
            <CColUnidades md={1}>
              <PTitulosInputs>Valor Final PPA</PTitulosInputs>
              <InputFormatRealBrasileiro
                radiusborder={
                  valuesPrograma.indicadores.length > 0 && "5px 5px 0px 0px"
                }
                listaDeInputs={true}
                externalValue={indicadorAdicionar.valorFinalPPA}
                onChange={(e, valor) => {
                  const valorFormatado = valor.replace(",", ".");
                  console.log(valorFormatado);
                  setIndicadorAdicionar((prevValues) => ({
                    ...prevValues,
                    valorFinalPPA: valorFormatado,
                  }));
                }}
              />
              {/* <FormControlListaDeInputs
                radiusborder={
                  valuesPrograma.indicadores.length > 0 && "5px 5px 0px 0px"
                }
                value={indicadorAdicionar.valorFinalPPA}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                  setIndicadorAdicionar((prevValues) => ({
                    ...prevValues,
                    valorFinalPPA: e.target.value,
                  }));
                }}
              /> */}
            </CColUnidades>
            <CCol md={1}>
              <PTitulosInputs>Ação</PTitulosInputs>
              <Icon
                icon="ic:baseline-add-box"
                color="#105200"
                height="28"
                style={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginTop: "1px",
                }}
                onClick={() => {
                  adicionaIndicador();
                }}
              />
            </CCol>
          </RowToModal>
        </React.Fragment>
      )}

      {(valuesPrograma.indicadorTipoDePrograma === "Sim" ||
        valuesPrograma.indicadorPrograma === "Sim") &&
        valuesPrograma.indicadores.map((item, index, selfArray) => (
          //2º Coluna
          <RowToModal
            isAModalPage={isAModalPage}
            className="row_form_2"
            style={{ marginTop: "-16px", paddingTop: "0px" }}
            key={index}
          >
            <CCol md={1}>
              <FormControlListaDeInputs
                disabled
                value={item.codigo}
                radiusborder={
                  index === selfArray.length - 1 ? "0px 0px 5px 5px" : "0px"
                }
              />
            </CCol>
            <CCol md={5}>
              <FormControlListaDeInputs
                disabled
                value={item.descricao}
                radiusborder={
                  index === selfArray.length - 1 ? "0px 0px 5px 5px" : "0px"
                }
              />
            </CCol>
            <CColUnidadeDeMedida md={1}>
              <FormControlListaDeInputs
                disabled
                value={item.unidadeMedida}
                radiusborder={
                  index === selfArray.length - 1 ? "0px 0px 5px 5px" : "0px"
                }
              />
            </CColUnidadeDeMedida>

            <CColUnidades md={2}>
              <FormControlListaDeInputs
                disabled={item.disabled}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9.]/g, "");
                  setEditValuesVBase((prevValues) => {
                    const newEditValues = [...prevValues];
                    newEditValues[index] = newValue;
                    return newEditValues;
                  });
                }}
                value={editValuesVBase[index]}
                radiusborder={
                  index === selfArray.length - 1 ? "0px 0px 5px 5px" : "0px"
                }
              />
            </CColUnidades>
            {/* <CCol  md={1}>
              <FormControlListaDeInputs
                disabled={item.disabled}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9.]/g, "");
                  setEditValuesReferencia((prevValues) => {
                    const newEditValues = [...prevValues];
                    newEditValues[index] = newValue;
                    return newEditValues;
                  });
                }}
                value={editValuesReferencia[index]}
                radiusborder={index === selfArray.length - 1 ? "0px 0px 5px 5px" : "0px"}
              />
            </CCol> */}
            <CColUnidades md={2}>
              <FormControlListaDeInputs
                disabled={item.disabled}
                //AQUIIIIIII
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9.]/g, "");
                  setEditValuesPPA((prevValues) => {
                    const newEditValues = [...prevValues];
                    newEditValues[index] = newValue;
                    return newEditValues;
                  });
                }}
                value={editValuesPPA[index]}
                radiusborder={
                  index === selfArray.length - 1 ? "0px 0px 5px 5px" : "0px"
                }
              />
            </CColUnidades>
            <CCol md={1}>
              {!item.editavel ? (
                <>
                  <Icon
                    onClick={() => {
                      if (editing === false) {
                        setEditing(true);
                        console.log(valuesPrograma);
                        item.disabled = false;
                        setValuesPrograma((prevValues) => ({
                          ...prevValues,
                          indicadores: prevValues.indicadores.map((item, i) =>
                            i === index ? { ...item, editavel: true } : item
                          ),
                        }));
                      } else {
                        toast.warning(
                          "Conclua as edições pendentes para poder editar outro indicador."
                        );
                      }
                    }}
                    icon="bx:edit"
                    color="#5971C8"
                    height="26"
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginTop: "3px",
                    }}
                  />
                  <Icon
                    onClick={
                      indicadoresJaCadastrados.some(
                        (objeto) => objeto.codigo === item.codigo
                      )
                        ? () => {
                            openModal();
                            setDeleteData((prevValues) => ({
                              codigoIndicador: item.codigo,
                            }));
                          }
                        : () => deletarIndicadorDoArray(item.codigo)
                    }
                    icon="ic:baseline-delete"
                    color="#E79900"
                    height="26"
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginTop: "1px",
                    }}
                  />
                </>
              ) : (
                <Icon
                  onClick={() => {
                    setEditing(false);
                    item.disabled = true;
                    setValuesPrograma((prevValues) => {
                      const updatedIndicadores = prevValues.indicadores.map(
                        (item, i) => {
                          if (i === index) {
                            return {
                              ...item,
                              valorFinalPPA: editValuesPPA[i],
                              valorBase: editValuesVBase[i],
                              // referencia: editValuesReferencia[i],
                              editavel: false,
                            };
                          }
                          return item;
                        }
                      );
                      return {
                        ...prevValues,
                        indicadores: updatedIndicadores,
                        editavel: false,
                      };
                    });
                  }}
                  icon="fluent:save-24-filled"
                  color="#008CFF"
                  height="26"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "3px",
                  }}
                />
              )}

              <Icon
                onClick={() => {
                  setEditing(false);
                  item.disabled = true;
                  setValuesPrograma((prevValues) => ({
                    ...prevValues,
                    indicadores: prevValues.indicadores.map((item, i) =>
                      i === index ? { ...item, editavel: false } : item
                    ),
                  }));
                }}
                icon="ic:baseline-cancel"
                height="26"
                color="#F24E1E"
                style={{
                  display: !item.editavel ? "none" : "inline-block",
                  cursor: "pointer",
                  marginLeft: "2px",
                  marginRight: "-5px",
                  marginTop: "3px",
                }}
              />
            </CCol>
          </RowToModal>
        ))}
      {!isAModalPage && <div style={{ padding: "11px" }}></div>}
    </Container>
  );
}

export default Programa;
