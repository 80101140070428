import React from "react";
import { CCol } from "../../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import PropostasVencedoras from "./Participantes";
import Contratos from "./Contratos";
import Aditivos from "./Aditivos";
import Principal from "./Principal";
import AbaLicitacao from "./AbaLicitacao";

const ImportarLicitacao = () => {
  return (
    <>
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
          style={{ justifyContent: "left" }}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: 600,
              marginLeft: "10px",
            }}
          >
            Principal
          </span>
        </CCol>
      </Row>
      <Principal />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
          style={{ justifyContent: "left" }}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: 600,
              marginLeft: "10px",
            }}
          >
            Licitação
          </span>
        </CCol>
      </Row>
      <AbaLicitacao />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Participantes
          </span>
        </CCol>
      </Row>
      <PropostasVencedoras />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Contratos
          </span>
        </CCol>
      </Row>
      <Contratos />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Aditivos
          </span>
        </CCol>
      </Row>
      <Aditivos />
      <div style={{ minHeight: "40px" }}> </div>
    </>
  );
};

export default ImportarLicitacao;
