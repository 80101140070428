import React from "react";
import { Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import Localizacao from "./Localizacao";
import Valores from "./Valores";
import EvolucaoObra from "./EvolucaoObra";

const Obra = () => {
  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros NomePaginaTitulo={"Obra"} PaginaSubtitulo={"Cadastro"} />
      <Row className={"row_form mt-1"}>
        <RowAninhada>
          <CCol md={2}>
            <FloatingLabelInput
              label={"Obra Número"}
              placeholder={"Obra Número"}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              tipo="input_select"
              label="Tipo de Obra"
              placeholder="Tipo de Obra"
              options={[{ label: "Tipo de Obra", value: "Tipo de Obra" }]}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              tipo="input_select"
              label="Tipo de Execução"
              placeholder="Tipo de Execução"
              options={[
                { label: "Tipo de Execução", value: "Tipo de Execução" },
              ]}
            />
          </CCol>
          <CCol md={2} style={{ width: "15em" }}>
            <FloatingLabelInput
              label={"Número do Protocolo"}
              placeholder={"Número do Protocolo"}
            />
          </CCol>
        </RowAninhada>
        <RowAninhada className={"mt-3"}>
          <CCol>
            <FloatingLabelInput
              label={"Título da Obra"}
              placeholder={"Título da Obra"}
            />
          </CCol>
        </RowAninhada>
        <RowAninhada className={"mt-3"}>
          <CCol>
            <FloatingLabelInput
              label={"Descrição da Obra"}
              placeholder={"Descrição da Obra"}
            />
          </CCol>
        </RowAninhada>
      </Row>
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Localização
          </span>
        </CCol>
      </Row>
      <Localizacao />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Valores
          </span>
        </CCol>
      </Row>
      <Valores />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Evolução da Obra
          </span>
        </CCol>
      </Row>
      <EvolucaoObra />
      <div style={{ minHeight: "40px" }}> </div>
    </Container>
  );
};

export default Obra;
