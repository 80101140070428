import styled from "styled-components";
import { FormSelect, Row } from "react-bootstrap";
import { Autocomplete, TextField } from "@mui/material";
import { Form } from "react-bootstrap";

export const RowItemListaDeInputs = styled(Row)`
  width: 100%;
  margin: 0px;
  margin-top: -1px;
  padding: 0px;
`;

export const AutocompleteListaDeInputs = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    font-family: "Poppins", sans-serif;
    height: 30px;
    border-radius: ${({ radiusborder }) =>
      radiusborder ? radiusborder : "5px"};
  }
`;

// MuiInputBase-input
// MuiOutlinedInput-input
// MuiInputBase-inputSizeSmall
// MuiAutocomplete-input
// MuiAutocomplete-inputFocused
// css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input

export const TextFieldListaDeInputs = styled(TextField)`
  .MuiInputLabel-root {
    font-family: "Poppins", sans-serif;
    margin-left: 22px;
    font-weight: 500;
    color: #515c70;
    z-index: 0;
  }

  .MuiInputLabel-root.Mui-focused {
    margin-left: 2px;
    color: #515c70;
  }

  .MuiInputLabel-root {
    margin-top: -4px;
    margin-left: 20px;
  }

  .MuiInputLabel-shrink {
    margin-left: 2px;
    color: #515c70;
  }

  .MuiInputBase-input {
    margin-left: 22px;
    margin-top: -4px;
  }

  .MuiInputBase-root {
    fieldset {
      border-color: #cccccc;
    }

    &:hover {
      fieldset {
        border-color: #cccccc;
      }
    }

    &.Mui-focused {
      fieldset {
        border-color: #cccccc;
        border-width: 1px;
      }
    }
  }
`;

export const FormControlListaDeInputs = styled(Form.Control)`
  border-radius: ${({ radiusborder }) => (radiusborder ? radiusborder : "5px")};
  border: ${(props) => {
    if (props.$border) {
      return props.$border;
    } else if (props.$backgroundColor) {
      return `1px solid ${props.$backgroundColor}`;
    } else {
      return "1px solid #ccc";
    }
  }};
  height: 30px;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : null};

  &:focus {
    box-shadow: none;
    border-color: #ccc;
  }

  &:disabled {
    ${({ disabledbg }) =>
      disabledbg ? `background-color: ${disabledbg}` : null};
    ${({ $inputSelecionadoDisabled }) =>
      $inputSelecionadoDisabled ? `background-color: #cbd0d6` : null};
  }
`;

export const PTitulosInputs = styled.p`
  padding: 0px;
  margin-top: -10px;
  margin-bottom: 0px;
  color: #888a8e;
  font-weight: 600;
  font-size: 12px;
`;

export const SpanStyledInfo = styled.span`
  padding: 0px;
  color: #888a8e;
  font-weight: 600;
  font-size: 14px;
`;

export const RowItemContaContabil = styled(Row)`
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  background-color: ${({ backgroundconta }) =>
    backgroundconta ? backgroundconta : null};
  border-radius: 10px;
`;

export const FormSelectListaDeInputs = styled(FormSelect)`
  height: 30px;
  line-height: 1em;
  border-radius: ${({ radiusborder }) => (radiusborder ? radiusborder : "5px")};
  border: 1px solid #cccccc;

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #cccccc;
  }
`;
