import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import {
  ButtonOptionsFirst,
  ButtonOptionsLast,
  ButtonOptionsPages,
  ColButtons,
} from "../../../../styles/StyledComponents/ButtonsSelectPage";
import ParametroLista from "./ParametroLista";
import IndicesLista from "./IndicesLista";

const ConsultaParametroIndices = () => {
  const [paginaAtiva, setPaginaAtiva] = useState(0);
  return (
    <Container
      fluid
      id="pags_responsividade_padding_geral"
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        TituloPagina={"Parâmetros / Índices"}
        SubTituloPagina={"Consulta"}
        botaoNovo={true}
        ToPag={`/planejamento/cadastro/parametro-indices/${paginaAtiva}`}
        PaginaRelatorio={"/planejamento/cadastro/parametro-indices/relatorio"}
      />
      <Row>
        <ColButtons>
          <ButtonOptionsPages>
            <ButtonOptionsFirst
              ativo={paginaAtiva === 0}
              onClick={() => setPaginaAtiva(0)}
            >
              Parâmetros
            </ButtonOptionsFirst>
            <ButtonOptionsLast
              ativo={paginaAtiva === 1}
              onClick={() => setPaginaAtiva(1)}
            >
              Índices
            </ButtonOptionsLast>
          </ButtonOptionsPages>
        </ColButtons>
      </Row>

      {paginaAtiva === 0 && <ParametroLista />}
      {paginaAtiva === 1 && <IndicesLista />}
    </Container>
  );
};

export default ConsultaParametroIndices;
