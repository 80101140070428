import React from "react";
import styled from "styled-components";
import FloatingLabelInput from "../Grid/FloatingLabelInput";
import { Icon } from "@iconify/react/dist/iconify.js";

const InputContainer = styled.div`
  position: relative;

  svg {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const InputEmail = (props) => {
  return (
    <InputContainer>
      <Icon
        icon="material-symbols:mail-outline"
        style={{ color: "#515c70" }}
        height={22}
      />
      <FloatingLabelInput {...props} />
    </InputContainer>
  );
};

export default InputEmail;
