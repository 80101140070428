export const getDemoSegCatEcon = async (
  clienteId,
  entidadesGestorasDoCliente,
  ano = 2024
) => {
  // try {
  //   const response = await api.get(`riscosFiscais/relatorio/${clienteId}`);
  //   return processAllData(response.data);
  // } catch (error) {
  //   console.error("Erro na requisição:", error);
  //   return {
  //     error: "error",
  //     name: "Demonstrativo 1 - Metas Anuais - Anexo Metas Fiscais",
  //   };
  // }
  return {
    contentHeader: getNextFourYears(ano),
  };
};

function getNextFourYears(year) {
  const parsedYear = parseInt(year, 10);
  const yearRange = [];

  for (let i = parsedYear + 1; i <= parsedYear + 4; i++) {
    yearRange.push(i.toString());
  }

  return yearRange;
}
