import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DivTagsModulos, TagModulo } from "./StyledComponentsContaPerfil";

const PInfos = styled.p`
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : "#e8e8e8"};
  margin: 0;
  height: 30px;
  color: ${({ $backgroundColor }) => ($backgroundColor ? "#fff" : "#515c70")};
  display: flex;
  align-items: center;
  padding-right: 155px;
  padding-left: 12px;
  border-radius: 5px;
  position: relative;
  justify-content: space-between;
  font-weight: 500;
`;

const DivArrows = styled.div`
  width: 100%;
  padding: 0px 35px;
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: hidden;
`;

export const PInfosWithArrow = ({ children, $backgroundColor, ...props }) => {
  return (
    <PInfos $backgroundColor={$backgroundColor} {...props}>
      <Icon
        rotate={3}
        height={20}
        icon="material-symbols:arrow-back-ios-rounded"
        style={{
          position: "absolute",
          cursor: "pointer",
          right: "10px",
          top: "1px",
          color: $backgroundColor ? "#fff" : "#515c70",
          ...props.style,
        }}
      />
      {children}
    </PInfos>
  );
};

export const DivModulosWithArrows = ({
  ModulosDoSistema,
  cliente,
  handleChangeModuloSelecionado,
}) => {
  const [translateX, setTranslateX] = useState(0);

  const handleActionArrow = (action) => {
    const numberOfSystems = ModulosDoSistema.length;
    console.log(numberOfSystems);
    const tagsWidth = 152; //pixels
    const maxTranslateNext = tagsWidth * (numberOfSystems - 1) * -1;
    console.log("maxNext", maxTranslateNext);

    if (action === "prev" && translateX < 0) {
      setTranslateX((prev) => prev + tagsWidth);
    }

    if (action === "next" && translateX > maxTranslateNext) {
      setTranslateX((prev) => prev - tagsWidth);
    } else if (action === "next" && translateX === maxTranslateNext) {
      setTranslateX(0);
    }
  };

  useEffect(() => {
    console.log(translateX);
  }, [translateX]);

  return (
    <DivArrows>
      <Icon
        onClick={() => handleActionArrow("prev")}
        rotate={4}
        height={20}
        icon="material-symbols:arrow-back-ios-rounded"
        style={{
          position: "absolute",
          cursor: "pointer",
          left: "0px",
          color: "#515c70",
        }}
      />
      <DivTagsModulos
        style={{
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        {ModulosDoSistema.map((item, index) => (
          <>
            <TagModulo
              key={index}
              $translateX={`${translateX}px`}
              $ativo={
                cliente.permissoes.find(
                  (permissao) => permissao.sistema === item.sistema
                ).sistemaAtivo
              }
              $nomeSistema={item.sistema}
              onClick={() =>
                handleChangeModuloSelecionado(item.sistema, cliente.clienteId)
              }
            >
              {item.sistema}
            </TagModulo>
          </>
        ))}
      </DivTagsModulos>
      <Icon
        onClick={() => handleActionArrow("next")}
        rotate={2}
        height={20}
        icon="material-symbols:arrow-back-ios-rounded"
        style={{
          position: "absolute",
          cursor: "pointer",
          right: "0px",
          color: "#515c70",
        }}
      />
    </DivArrows>
  );
};
