import React, { useContext, useState, useEffect } from "react";
import { CCol, RowAninhada } from "../../../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import { ProgressBarComponent } from "../../../../../../components/Grid/ProgressBarComponent";
import Saldos from "../../../../LDO/Metas_Fiscais/Saldos";
import { CurrentYearContext } from "../../../../../../contexts/YearContext";

export const PrepararLOA = () => {
  const [saldosData, setSaldosData] = useState([]);
  const [percentageInputs, setPercentageInputs] = useState([]);

  const { currentYear } = useContext(CurrentYearContext);

  useEffect(() => {
    setPercentageInputs([
      {
        title: "Verificando dados Orçamentários no Sistema SAVE",
        percentage: 40,
      },
      {
        title: "Importando Tabelas Necessárias para implantação",
        percentage: 40,
      },
      {
        title:
          "Projetando Receita Prevista com índice de inflação de 3,5 para 2025",
        percentage: 40,
      },
      {
        title:
          "Projetando Despesa Fixada com índice de inflação de 3,5 para 2025",
        percentage: 40,
      },
      {
        title:
          "Projetando Despesa Fixada com índice de inflação de 3,5 para 2025",
        percentage: 40,
      },
      {
        title: "Ajustando valores com base nas Fontes de Recursos",
        percentage: 40,
      },
    ]);
  }, []);

  useEffect(() => {
    const updateSaldosData = () => {
      const newSaldosData = [];
      for (let i = -4; i <= 2; i++) {
        const year = currentYear + i;
        const existingData = saldosData.find(
          (obj) => Object.keys(obj)[0] === `${year}`
        );
        newSaldosData.push({
          [year]: existingData
            ? existingData[year]
            : [{ input1: "0" }, { input2: "0" }, { input3: "0" }],
        });
      }
      setSaldosData(newSaldosData);
    };
    updateSaldosData();
    // eslint-disable-next-line
  }, [currentYear]);

  return (
    <>
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
          style={{ justifyContent: "left" }}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: 600,
              marginLeft: "10px",
            }}
          >
            Principal
          </span>
        </CCol>
      </Row>
      <Row className="row_form mt-1">
        {percentageInputs.map((item, index) => {
          return (
            <RowAninhada className={index !== 0 && "mt-3"}>
              <CCol>
                <ProgressBarComponent
                  percentage={item.percentage}
                  label={item.title}
                />
              </CCol>
            </RowAninhada>
          );
        })}
      </Row>
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
          style={{ justifyContent: "left" }}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: 600,
              marginLeft: "10px",
            }}
          >
            Saldos
          </span>
        </CCol>
      </Row>
      <Saldos
        titles={["Receita Prevista", "Despesa Fixada", "Diferença"]}
        data={saldosData}
        nameBefore={"Exercício "}
      />
    </>
  );
};

export default PrepararLOA;
