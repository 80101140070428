import React from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";

const ComplementoAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Complementação</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1">
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="Obra"
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.value} - ${item.label}`}
          />
        </CCol>
        <CCol>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="Convênio"
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.value} - ${item.label}`}
          />
        </CCol>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }} $borderRadiusType={2}>
        <CCol md={6}>
          <MyAutoComplete
            style={{ height: "37px" }}
            labelInput="Dívida Pública"
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.value} - ${item.label}`}
          />
        </CCol>
      </RowForm>
    </>
  );
};

export default ComplementoAba;
