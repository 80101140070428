import axios from "axios";
import { FormatToPascalCase } from "../../../../../utils/FormatacaoDeDados/FormatToPascalCase";
import api from "../../../../../utils/api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const useApi = ({
  setValuesCliente,
  valuesCliente,
  setArrayGestoes,
  formatArraySistemas,
  formatObjectToPost,
  verificaGestaoAtual,
}) => {
  const { id: clienteIdParams } = useParams();

  const fetchValoresCliente = () => {
    api
      .get(`cliente/pesquisa/id/${clienteIdParams}`)
      .then((resp) => {
        const data = resp.data[0];
        const gestaoAtual = verificaGestaoAtual(data.gestao);
        console.log("Gestão atuaaaal:", gestaoAtual);
        console.log(data);

        setValuesCliente((prev) => ({
          ...prev,
          codigoSiconfi: data.codigoSiconfi,
          codigoTCE: data.codigoTCE,
          ibge: data.ibge,
          poder: data.poder,
          gestao: [],
          naturezaJuridica: data.naturezaJuridica,
          tipoNaturezaJuridica: data.tipoNaturezaJuridica,
          unidadeGestoraId: data.unidadeGestora.id,
          pessoaJuridicaId: data.pessoaJuridica.id,
          brasao: data.brasao ? data.brasao.split(",")[1] : "",
        }));

        console.log(gestaoAtual);

        //aqui é caso eu queira listar todas as gestoes

        // setArrayGestoes(
        //   data.gestao.map((item) => ({
        //     gestaoId: item.gestaoId,
        //     pessoaId: item.pessoaId,
        //     dataInicial: item.dataInicial,
        //     dataFim: item.dataFim,
        //     modulos: formatArraySistemas(item.modulosSistema),
        //   }))
        // );

        // aqio é caso eu queira listar apenas a gestao atual
        if (gestaoAtual) {
          setArrayGestoes([
            {
              gestaoId: gestaoAtual.gestaoId,
              pessoaId: gestaoAtual.pessoaId,
              dataInicial: gestaoAtual.dataInicial,
              dataFim: gestaoAtual.dataFim,
              modulos: formatArraySistemas(gestaoAtual.modulosSistema),
            },
          ]);
        }

        // {
        //   pessoaId: 0,
        //   dataInicial: "",
        //   dataFim: "",
        //   sistemas: [],
        // },
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Algo deu errado ao carregar os dados da página. Por favor, tente mais tarde."
        );
      });
  };

  const desativaGestao = async (gestaoId) => {
    try {
      await api.put(`cliente/desativar-gestao/${gestaoId}`);
      toast.success("Gestão desativada com sucesso!");
      fetchValoresCliente();
    } catch (error) {
      console.log(error);
      toast.error("Ocorreu um erro ao desativar a gestão.");
    }
  };

  const resgataDadosCnpj = async (cnpj, unidadeGestora) => {
    try {
      const res = await axios.get(
        `https://brasilapi.com.br/api/cnpj/v1/${cnpj}`
      );

      const res2 = await api.get(`cnpj/filter?filtro=${cnpj}`);

      if (unidadeGestora) {
        setValuesCliente((prev) => ({
          ...prev,
          unidadeGestoraId: res2.data[0].pessoaId,
        }));
      } else {
        setValuesCliente((prev) => ({
          ...prev,
          codigoSiconfi: res.data.natureza_juridica.includes("Legislativo")
            ? res.data.codigo_municipio_ibge + "LG"
            : res.data.codigo_municipio_ibge + "EX",
          poder: res.data.natureza_juridica.includes("Legislativo")
            ? "1 - Legislativo"
            : "2 - Executivo",
          ibge: res.data.codigo_municipio_ibge.toString(),
          regiao: FormatToPascalCase(res.data.municipio),
          naturezaJuridica: res2.data[0].naturezaJuridica,
          pessoaJuridicaId: res2.data[0].pessoaId,
        }));
      }
      console.log(res);
      console.log(res2.data[0].pessoaId);
      //setIdCliente(res2.data[0].pessoaId);
      console.log("Requisição feita!");
      console.log(valuesCliente);
    } catch (err) {
      console.log(err);
    }
  };

  const postValuesCliente = async () => {
    try {
      const valuesToPost = formatObjectToPost();
      // await postImage();

      await api.post("cliente", valuesToPost);

      toast.success("Cliente Cadastrado com sucesso!");
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error(
          "Não foi possivel cadastrar este cliente. Por favor, tente mais tarde."
        );
      }

      console.log(error);
    }
  };

  const putValuesCliente = async () => {
    try {
      const { regiao, brasao, ...valuesToPut } = formatObjectToPost();
      console.log(valuesToPut);

      await api.put(`cliente/atualiza/${clienteIdParams}`, valuesToPut);

      toast.success("Cliente atualizado com sucesso!");
    } catch (error) {
      console.log(error);
      toast.error(
        "Algo deu errado ao atualizar as informações desse cliente. Por favor, tente mais tarde"
      );
    }
  };

  useEffect(() => {
    if (clienteIdParams) {
      fetchValoresCliente();
    }
    //eslint-disable-next-line
  }, [clienteIdParams]);

  return {
    resgataDadosCnpj,
    postValuesCliente,
    fetchValoresCliente,
    putValuesCliente,
    desativaGestao,
  };
};

export default useApi;
