import React from "react";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

const Financeiro = () => {
  return (
    <>
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={2}>
            <FloatingLabelInput
              label={"Nº Conta"}
              placeholder={"Nº Conta"}
              disabled
            />
          </CCol>
          <CCol md={1} style={{ width: "55px" }}>
            <FloatingLabelInput label={"D"} placeholder={"D"} disabled />
          </CCol>
          <CCol>
            <FloatingLabelInput label={"Nome"} placeholder={"Nome"} disabled />
          </CCol>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol>
            <FloatingLabelInput
              label={"Fonte de Recursos"}
              placeholder={"Fonte de Recursos"}
              disabled
            />
          </CCol>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol>
            <FloatingLabelInput
              label={"Valor do Convênio R$"}
              placeholder={"Valor do Convênio R$"}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              label={"Valor de Contrapartida R$"}
              placeholder={"Valor de Contrapartida R$"}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              label={"Valor Liberado R$"}
              placeholder={"Valor Liberado R$"}
              disabled
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              label={"Percentual Liberado %"}
              placeholder={"Percentual Liberado %"}
              disabled
            />
          </CCol>
        </RowAninhada>
      </Row>
    </>
  );
};

export default Financeiro;
