import React from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../../components/ListaDeInputs/IconAdicionar";

const Aditivos = () => {
  return (
    <>
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Nº Contrato</PTitulosInputs>
            <FormControlListaDeInputs />
          </CCol>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Nº Aditivos</PTitulosInputs>
            <FormControlListaDeInputs />
          </CCol>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Data Assinatura</PTitulosInputs>
            <FormControlListaDeInputs />
          </CCol>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Vigência</PTitulosInputs>
            <FormControlListaDeInputs />
          </CCol>
          <CCol>
            <PTitulosInputs>Motivo</PTitulosInputs>
            <FormControlListaDeInputs />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Valor</PTitulosInputs>
            <FormControlListaDeInputs />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <IconAdicionar />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
      </Row>
    </>
  );
};

export default Aditivos;
