const useUtils = () => {
  const returnGestoesAnteriores = (gestaoAtual, gestoes) => {
    let newArray = gestoes.filter(
      (item) =>
        item.dataFim !== gestaoAtual.dataFim &&
        item.dataInicial !== gestaoAtual.dataInicial
    );

    return newArray;
  };

  const verificaGestaoAtual = (gestoes) => {
    console.log(gestoes);
    const now = new Date();
    const dateString = now.toISOString().split("T")[0];
    console.log(dateString);

    const gestaoAtual = gestoes.find((item) => {
      const dataInicial = new Date(item.dataInicial);
      const dataFim = new Date(item.dataFim);
      const dataAtual = new Date(dateString);

      return dataAtual >= dataInicial && dataAtual <= dataFim;
    });

    console.log(gestaoAtual);

    return gestaoAtual;
  };

  const verificaSistemaAtivo = (arrayComOsSistemas, nomeSistema) => {
    let obj = arrayComOsSistemas.find(
      (item) => item.modulo.toLowerCase() === nomeSistema.toLowerCase()
    );

    if (obj && obj.status === 1) {
      return true;
    } else {
      return false;
    }
  };

  return { verificaGestaoAtual, verificaSistemaAtivo, returnGestoesAnteriores };
};

export default useUtils;
