import React, { useEffect, useState } from "react";
import { QueryTable } from "../../../../components/QueryTable";
import { Container } from "react-bootstrap";
import { RowForm } from "../../../../components/Grid/CCol";
import api from "../../../../utils/api";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import { useCurrentClient } from "../../../../hooks/useCurrentClient";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import IconDelete from "../../../../components/ListaDeInputs/IconDelete";
import IconEdit from "../../../../components/ListaDeInputs/IconEdit";

const ConsultaReceitaOrcamentaria = () => {
  const [colunas, setColunas] = useState([]);
  const [data, setData] = useState([]);
  const { currentYear } = useCurrentYear();
  const { currentClient } = useCurrentClient();

  const fetchData = async () => {
    try {
      const resp = await api.get(
        `/guiaDeReceita/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;
      console.log(data);

      const cols = [
        {
          title: "Natureza da Receita",
          key: "naturezaDaReceita",
          values: data.map(
            (item) =>
              `${item.naturezaInfo[0].nr} - ${item.naturezaInfo[0].especificacao}`
          ),
        },
        {
          title: "Tipo de Lançamento",
          key: "tipoDeLancamento",
          values: data.map((item) => item.tipoDeLancamento),
        },
        {
          title: "Data",
          key: "dataCriacao",
          values: data.map((item) => item.dataCriacao),
        },
        {
          title: "Devedor",
          key: "devedor",
          values: data.map(
            (item) =>
              `${item.devedorInfo.cpf} - ${item.devedorInfo.nomeCompleto}`
          ),
        },
        {
          title: "Conta Bancária",
          key: "contaBancaria",
          values: data.map(
            (item) =>
              `${item.contaBancariaInfo.numeroConta}-${item.contaBancariaInfo.digito} - ${item.contaBancariaInfo.nome}`
          ),
        },
        {
          title: "Valor",
          key: "valor",
          values: data.map((item) => FormatValueToLocaleString(item.valor)),
        },
        {
          title: "Ações",
          key: "dataActions",
          values: data.map((item) => ({
            dataId: item.id,
            actions: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2px",
                }}
              >
                <IconAdicionar height={22} onClick={() => alert(item.valor)} />
                <IconDelete height={22} />
                <IconEdit height={22} />
              </div>
            ),
          })),
        },
        // {
        //   title: "Fonte de Recursos",
        //   values: data.map(item =>FormatValueToLocaleString(item.valor))
        // }
      ];

      setColunas(cols);
      setData(
        data.map((item) => ({
          id: item.id,
          naturezaDaReceita: `${item.naturezaInfo[0].nr} - ${item.naturezaInfo[0].especificacao}`,
          tipoDeLancamento: item.tipoDeLancamento,
          dataCriacao: item.dataCriacao,
          devedor: `${item.devedorInfo.cpf} - ${item.devedorInfo.nomeCompleto}`,
          contaBancaria: `${item.contaBancariaInfo.numeroConta}-${item.contaBancariaInfo.digito} - ${item.contaBancariaInfo.nome}`,
          valor: FormatValueToLocaleString(item.valor),
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();

    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <Container
        id={"pags_responsividade_padding_geral"}
        fluid
        className={"container_conteudo conteudo_pagina"}
      >
        <HeaderOptions
          withRow={false}
          ToPag={"/contabilidade/receita/guia-de-receita"}
          SubTituloPagina={"Consulta"}
          TituloPagina={"Receita Orçamentária"}
        />
        <RowForm $borderRadiusType={3}>
          <QueryTable colunas={colunas} data={data} />
        </RowForm>
        <div className="p-3"></div>
      </Container>
    </div>
  );
};

export default ConsultaReceitaOrcamentaria;
