import React from "react";
import { Row } from "react-bootstrap";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

const Localizacao = () => {
  return (
    <Row className={"row_form mt-1"}>
      <RowAninhada>
        <CCol md={2} style={{ width: "10em" }}>
          <FloatingLabelInput label={"Código"} placeholder={"Código"} />
        </CCol>
        <CCol>
          <FloatingLabelInput disabled label={"Nome"} placeholder={"Nome"} />
        </CCol>
        <CCol md={1}>
          <FloatingLabelInput label={""} placeholder={""} />
        </CCol>
        <CCol md={1} style={{ width: "25em" }}>
          <FloatingLabelInput label={""} placeholder={""} />
        </CCol>
      </RowAninhada>
      <RowAninhada className={"mt-3"}>
        <CCol>
          <FloatingLabelInput
            disabled
            label={"Bairro"}
            placeholder={"Bairro"}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            disabled
            label={"Cidade"}
            placeholder={"Cidade"}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            label={"Estado"}
            placeholder={"Estado"}
          />
        </CCol>
      </RowAninhada>
    </Row>
  );
};

export default Localizacao;
