import React from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

export const TransferenciaRecebidaAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Transferência Recebida</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1">
        <CCol md={8}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Tipo Alteração"
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            disabled
            placeholder="Natureza Jurídica"
            label="Natureza Jurídica"
          />
        </CCol>
      </RowForm>
      <RowForm $borderRadiusType={2} style={{ marginTop: "-15px" }}>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Tipo de Transferência"
          />
        </CCol>
      </RowForm>
    </>
  );
};
