import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Container } from "react-bootstrap";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { CurrentClientContext } from "../../../../../contexts/CurrentClientContext";
import { DivPrintOnly } from "../../../../../styles/StyledComponents/StylesRelatorios";
import GlobalStyles from "../../../../../styles/StyledComponents/GlobalStyles";
import HeaderCadastros from "../../../../../components/HeaderCadastros/HeaderCadastros";
import PagRelatorioComponente from "../../../../../components/ComponenteDeRelatorio/PagRelatorioComponente";
import { PdfParametroIndice } from "./PdfParametroIndice";
import { CurrentYearContext } from "../../../../../contexts/YearContext";
import {
  processarDadosRelatorio,
  getSelected,
  hasAnySelected,
  getSelectedWithValues,
} from "../../../../../utils/RelatorioDocx/FuncoesRelatorioDocx";
import { generateDocxBlob } from "./UniqueDocx/DocxRelatorioParametrosIndices";
import { isBase64 } from "../../../../../utils/Gerais/isBase64";
import { useGenerateRelatorio } from "../../../../../hooks/useGenerateRelatorio";

const RelatorioParametroIndice = () => {
  const { currentYear } = useContext(CurrentYearContext);
  const { currentClient } = useContext(CurrentClientContext);
  const { userAuthData } = useContext(AuthContext);
  const { data: dataIndices } = useFetchData(
    `/indices/${currentClient.clienteId}`
  );

  const { data: dataParametros } = useFetchData(
    `/parametros/${currentClient.clienteId}`
  );
  const [year, setYear] = useState(currentYear);
  const [selectBoxesOptionsAbaOptions, setSelectBoxesOptionsAbaOptions] =
    useState([]);
  const [selectBoxesOptionsAbaPrincipal, setSelectBoxesOptionsAbaPrincipal] =
    useState([]);
  const [dadosRelatorio, setDadosRelatorio] = useState(null);
  const [month, setMonth] = useState("");
  const navigate = useNavigate();
  const [pdfData, setPdfData] = useState(null);
  const { data: dataClientInfos } = useFetchData(
    `cliente/pesquisa/id/${currentClient.clienteId}`
  );
  const relatorioName = "Relatório Parametros - Indices";

  const { handleGenerateXls } = useGenerateRelatorio(
    selectBoxesOptionsAbaOptions,
    selectBoxesOptionsAbaPrincipal,
    currentClient,
    relatorioName
  );

  const handleGeneratePdf = () => {
    if (
      hasAnySelected(selectBoxesOptionsAbaOptions) &&
      hasAnySelected(selectBoxesOptionsAbaPrincipal)
    ) {
      if (dadosRelatorio) {
        console.log("pdf gerado");
        const pdfData = {
          pdfName: "Relatório Indicadores",
          PdfComponent: PdfParametroIndice,
          pdfContent: {
            dadosRelatorio,
            opcoes: selectBoxesOptionsAbaOptions,
            relatorioName,
            userData: {
              currentClient,
              userAuthData,
              dataClientInfos,
            },
            dateRelatorio,
          },
        };
        setPdfData(pdfData);
      }
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  useEffect(() => {
    if (!dataParametros || !dataIndices) return;

    const processEntries = (data, excludeKey) =>
      Array.from(
        new Set(Object.keys(data).filter((key) => key !== excludeKey))
      ).sort((a, b) => a - b);

    const anosParametros = processEntries(dataParametros);
    const anosIndices = processEntries(dataIndices, "fonte");
    console.log(
      "anosParametros: ",
      anosParametros,
      "anosIndices: ",
      anosIndices
    );

    const parametrosLabelsMap = {
      parametrosPibRealPlanejamento: "PIB Real (crescimento % anual)",
      parametrosProjecaoPibPlanejamento: "Projeção do PIB - R$ milhares",
      parametrosRealizacaoPibRealPlanejamento:
        "Realização do PIB - R$ milhares",
    };

    const indicesLabelsMap = {
      indiceTaxaDeJurosPlanejamento: "Taxa de Juros (% anual)",
      indiceTaxaDeCambioPlanejamento: "Taxa de Câmbio (US$ - final do ano)",
      indiceInflacaoPlanejamento: "Inflação (% anual)",
    };

    const createOption = (opcao, data, labelsMap) => ({
      opcao,
      checkBoxes: data.map((item, index) => ({
        label: labelsMap[item] || item,
        value: index,
        selected: false,
      })),
    });

    setSelectBoxesOptionsAbaPrincipal((prev) => {
      const updatedOptions = prev.reduce((acc, option) => {
        if (option.opcao === "Parametros") {
          acc.push(
            createOption(
              "Parametros",
              Object.keys(parametrosLabelsMap),
              parametrosLabelsMap
            )
          );
        } else if (option.opcao === "Indices") {
          acc.push(
            createOption(
              "Indices",
              Object.keys(indicesLabelsMap),
              indicesLabelsMap
            )
          );
        } else {
          acc.push(option);
        }
        return acc;
      }, []);

      if (!prev.some((option) => option.opcao === "Parametros")) {
        updatedOptions.push(
          createOption(
            "Parametros",
            Object.keys(parametrosLabelsMap),
            parametrosLabelsMap
          )
        );
      }

      if (!prev.some((option) => option.opcao === "Indices")) {
        updatedOptions.push(
          createOption(
            "Indices",
            Object.keys(indicesLabelsMap),
            indicesLabelsMap
          )
        );
      }

      return JSON.stringify(prev) !== JSON.stringify(updatedOptions)
        ? updatedOptions
        : prev;
    });
  }, [dataParametros, dataIndices]);

  useEffect(() => {
    console.log(
      "selectBoxesOptionsAbaPrincipal: ",
      selectBoxesOptionsAbaPrincipal
    );

    if (
      selectBoxesOptionsAbaPrincipal.length > 0 &&
      dataParametros &&
      dataIndices &&
      selectBoxesOptionsAbaPrincipal[0].checkBoxes
    ) {
      const itensSelecionados = selectBoxesOptionsAbaPrincipal[0].checkBoxes
        .filter((item) => item.selected)
        .map((checkBox) => checkBox.value);

      console.log("itensSelecionados: ", itensSelecionados);

      // Adapta a obtenção das opções selecionadas
      const selectedOptions = selectBoxesOptionsAbaPrincipal.reduce(
        (acc, { opcao, checkBoxes }) => {
          const selectedKeys = checkBoxes
            .filter(({ selected }) => selected)
            .map(({ label }) => label);
          if (selectedKeys.length > 0) {
            acc[opcao] = selectedKeys;
          }
          return acc;
        },
        {}
      );
      console.log("selectedOptions: ", selectedOptions);

      // Mapeamento de chaves para labels para dataParametros
      const parametrosLabelsMap = {
        parametrosPibRealPlanejamento: "PIB Real (crescimento % anual)",
        parametrosProjecaoPibPlanejamento: "Projeção do PIB - R$ milhares",
        parametrosRealizacaoPibRealPlanejamento:
          "Realização do PIB - R$ milhares",
      };

      // Mapeamento de chaves para labels para dataIndices
      const indicesLabelsMap = {
        indiceTaxaDeJurosPlanejamento: "Taxa de Juros (% anual)",
        indiceTaxaDeCambioPlanejamento: "Taxa de Câmbio (US$ - final do ano)",
        indiceInflacaoPlanejamento: "Inflação (% anual)",
      };

      const parametrosAgrupados = {};
      Object.entries(dataParametros).forEach(([key, dados]) => {
        const keyLabel = parametrosLabelsMap[key];
        const isKeyValid = Object.values(selectedOptions).some((array) =>
          array.includes(keyLabel)
        );

        if (isKeyValid) {
          const anos = dados.anos;
          const anoValores = anos.reduce((acc, { ano, valor }) => {
            acc[`ano${ano}`] =
              key === "parametrosPibRealPlanejamento"
                ? `${valor} %`
                : `R$ ${valor}`;
            return acc;
          }, {});
          const final = { titulo: parametrosLabelsMap[key], ...anoValores };

          parametrosAgrupados[key] = final;
        }
      });

      const indicesAgrupados = {};
      Object.entries(dataIndices).forEach(([key, dados]) => {
        const keyLabel = indicesLabelsMap[key];
        const isKeyValid = Object.values(selectedOptions).some((array) =>
          array.includes(keyLabel)
        );

        if (isKeyValid) {
          const anos = dados.anos;
          const anoValores = anos.reduce((acc, { ano, valor }) => {
            acc[`ano${ano}`] =
              key === "indiceTaxaDeCambioPlanejamento"
                ? `US$ ${valor}`
                : `${valor} %`;
            return acc;
          }, {});
          const final = { titulo: indicesLabelsMap[key], ...anoValores };

          indicesAgrupados[key] = final;
        }
      });

      const parametrosFinal = Object.values(parametrosAgrupados);
      const indicesFinal = Object.values(indicesAgrupados);

      console.log("parametrosFinal: ", parametrosFinal);
      console.log("indicesFinal: ", indicesFinal);

      setDadosRelatorio({
        parametros: parametrosFinal,
        indices: indicesFinal,
      });
    }
  }, [selectBoxesOptionsAbaPrincipal, dataParametros, dataIndices]);

  const handlePrint = () => {
    window.print();
  };

  const handleYearChange = (newYear) => {
    setYear(newYear);
  };

  const handleMonthChange = (newMonth) => {
    setMonth(newMonth);
  };

  const dateRelatorio = {
    year,
    month,
  };

  useEffect(() => {
    setYear(currentYear);
  }, [currentYear]);

  useEffect(() => {
    updateSelectBoxesOptionsAbaOptions();
    // eslint-disable-next-line
  }, [year]);

  const handleGenerateDataXls = () => {
    const dataTable1 = dadosRelatorio.parametros
      ?.map((item) => {
        const selectedFields = {};
        selectBoxesOptionsAbaOptions[0]?.checkBoxes?.forEach(
          ({ label, value, selected }) => {
            const actualItem = item[value];
            if (selected && !isBase64(actualItem)[0]) {
              selectedFields[label] = actualItem;
            }
          }
        );
        return Object.keys(selectedFields).length > 0 ? selectedFields : null;
      })
      .filter((item) => item);
    console.log("DATAAA: ", dataTable1);

    const dataTable2 = dadosRelatorio.indices
      ?.map((item) => {
        const selectedFields = {};
        selectBoxesOptionsAbaOptions[1]?.checkBoxes?.forEach(
          ({ label, value, selected }) => {
            const actualItem = item[value];
            if (selected && !isBase64(actualItem)[0]) {
              selectedFields[label] = actualItem;
            }
          }
        );
        return Object.keys(selectedFields).length > 0 ? selectedFields : null;
      })
      .filter((item) => item);
    console.log("DATAAA: ", dataTable2);
    const relatorioCompleto = [dataTable1, dataTable2].filter(
      (data) => data.length > 0
    );
    if (relatorioCompleto.length !== 0) {
      handleGenerateXls(
        relatorioCompleto,
        ["Relatório - Parâmetros", "Relatório - Índices"],
        [
          "Título",
          `${selectBoxesOptionsAbaOptions[0].checkBoxes[1].label}`,
          `${selectBoxesOptionsAbaOptions[0].checkBoxes[2].label}`,
          `${selectBoxesOptionsAbaOptions[0].checkBoxes[3].label}`,
          `${selectBoxesOptionsAbaOptions[0].checkBoxes[4].label}`,
          `${selectBoxesOptionsAbaOptions[0].checkBoxes[5].label}`,
          `${selectBoxesOptionsAbaOptions[0].checkBoxes[6].label}`,
        ]
      );
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  const handleGenerateDocx = async () => {
    const title = "Parametros e Indices";
    const pdfContent = {
      dadosRelatorio: [
        processarDadosRelatorio(
          dadosRelatorio.parametros,
          selectBoxesOptionsAbaOptions,
          0
        ),
        processarDadosRelatorio(
          dadosRelatorio.indices,
          selectBoxesOptionsAbaOptions,
          1
        ),
      ],
      columnWidth: [
        getSelectedWithValues(
          selectBoxesOptionsAbaOptions[0],
          [28, 12, 12, 12, 12, 12, 12]
        ),
        getSelectedWithValues(
          selectBoxesOptionsAbaOptions[1],
          [28, 12, 12, 12, 12, 12, 12]
        ),
      ],
      opcoes: [
        getSelected(selectBoxesOptionsAbaOptions, 0),
        getSelected(selectBoxesOptionsAbaOptions, 1),
      ],
      userData: {
        currentClient,
        userAuthData,
      },
      dateRelatorio: { year: currentYear },
    };

    if (
      hasAnySelected(selectBoxesOptionsAbaOptions) &&
      hasAnySelected(selectBoxesOptionsAbaPrincipal)
    ) {
      toast.promise(
        generateDocxBlob({ pdfContent }, title, dataClientInfos[0].brasao, 20, [
          "Parametros",
          "Indices",
        ]).then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = title + ".docx";
          link.click();
          URL.revokeObjectURL(url);
        }),
        {
          pending: "Gerando o documento...",
          success: "Documento gerado com sucesso!",
          error: "Falha ao gerar o documento.",
        }
      );
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  const updateSelectBoxesOptionsAbaOptions = () => {
    setSelectBoxesOptionsAbaOptions([
      {
        opcao: "Exibir",
        checkBoxes: [
          {
            label: "Título",
            value: "titulo",
            selected: true,
          },
          {
            label: `${parseInt(year - 3)}`,
            value: `ano${parseInt(year - 3)}`,
            selected: true,
          },
          {
            label: `${parseInt(year - 2)}`,
            value: `ano${parseInt(year - 2)}`,
            selected: true,
          },
          {
            label: `${parseInt(year - 1)}`,
            value: `ano${parseInt(year - 1)}`,
            selected: true,
          },
          {
            label: `${year}`,
            value: `ano${year}`,
            selected: true,
          },
          {
            label: `${parseInt(year + 1)}`,
            value: `ano${parseInt(year + 1)}`,
            selected: true,
          },
          {
            label: `${parseInt(year + 2)}`,
            value: `ano${parseInt(year + 2)}`,
            selected: true,
          },
        ],
      },
      {
        opcao: "Exibir",
        checkBoxes: [
          {
            label: "Título",
            value: "titulo",
            selected: true,
          },
          {
            label: `${parseInt(year - 3)}`,
            value: `ano${parseInt(year - 3)}`,
            selected: true,
          },
          {
            label: `${parseInt(year - 2)}`,
            value: `ano${parseInt(year - 2)}`,
            selected: true,
          },
          {
            label: `${parseInt(year - 1)}`,
            value: `ano${parseInt(year - 1)}`,
            selected: true,
          },
          {
            label: `${year}`,
            value: `ano${year}`,
            selected: true,
          },
          {
            label: `${parseInt(year + 1)}`,
            value: `ano${parseInt(year + 1)}`,
            selected: true,
          },
          {
            label: `${parseInt(year + 2)}`,
            value: `ano${parseInt(year + 2)}`,
            selected: true,
          },
        ],
      },
    ]);
  };

  return (
    <>
      <GlobalStyles orientation={"portrait"} />
      <DivPrintOnly>
        {/* <VizualizacaoRelatorioIndicadores
          dadosRelatorio={dadosRelatorio}
          dateRelatorio={dateRelatorio}
          dataClientInfos={dataClientInfos}
          numeroDaPagina={1}
          totalDePaginas={2}
        /> */}
      </DivPrintOnly>

      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina hide_on_print"
      >
        <HeaderCadastros
          PaginaConsulta={"/planejamento/cadastro/parametro-indices/consulta"}
          NomePaginaTitulo={"Parametros e Indices"}
          PaginaSubtitulo={"Relatório"}
          BotaoNovoAtivo={true}
          BotaoSaveAtivo={false}
          BotaoNovoFunction={() =>
            navigate("/planejamento/cadastro/parametro-indices/0")
          }
          OpcoesDeArquivo={true}
          ButtonVisualizar={handlePrint}
          ButtonPDF={handleGeneratePdf}
          ButtonDocx={handleGenerateDocx}
          PdfData={pdfData}
          ButtonXls={handleGenerateDataXls}
        />
        <ToastContainer />

        <PagRelatorioComponente
          topoEsquerdoOptions={[
            { label: "Relatório", value: null },
            { label: "Relatório", value: 1 },
          ]}
          principalOptions={[
            {
              label: "Parametros",
              value: "Parametros",
            },
            {
              label: "Indices",
              value: "Indices",
            },
          ]}
          labelsCheckAbaPrincipal={selectBoxesOptionsAbaPrincipal}
          setLabelsCheckAbaPrincipal={setSelectBoxesOptionsAbaPrincipal}
          labelsCheckAbaOpcoes={selectBoxesOptionsAbaOptions}
          setLabelsCheckAbaOpcoes={setSelectBoxesOptionsAbaOptions}
          hasAssinatura={false}
          hasFavorite={false}
          handleMonthChange={handleMonthChange}
          handleYearChange={handleYearChange}
        />
      </Container>
    </>
  );
};
export default RelatorioParametroIndice;
