import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";
import { mockedData } from "./MockDataDespesaContabilidade";

export const getDespesaCompatibilidade = async (
  clientes,
  entidadesGestoras,
  ano = 2024
) => {
  try {
    const url = getUrl(clientes, entidadesGestoras);
    console.log("Passou de url com: ", url);
    const response1 = await api.get(
      `qdr/relatorio/${url.idsUrl}/${ano}?consolidado=1`
    );
    return processAllData(response1.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Anexo I - Demonstração da Receita e Despesa segundo as Categorias Econômicas",
    };
  }
};

const normalizeString = (str) => {
  if (str) {
    const lowerStr = str.toLowerCase();
    const noAccents = lowerStr.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const trimmed = noAccents.trim();
    const singleSpaced = trimmed.replace(/\s+/g, " ");

    return singleSpaced.endsWith(".")
      ? singleSpaced.slice(0, -1)
      : singleSpaced;
  }
};

const processAllData = (dataQdrRelatorio) => {
  let mockData = JSON.parse(JSON.stringify(mockedData));
  const getReceita = () => {
    const part1 = () => {
      let encontrouReceitasDeCapital = false;
      let totalPloaReceitasCorrentes = 0;

      const regexPermitidoNaturezas =
        /^(\d)\.(0)\.(0)\.(0)\.(00)\.(0)\.(0)$|^(\d)\.(\d)\.(0)\.(0)\.(00)\.(0)\.(0)$/;

      const data =
        dataQdrRelatorio?.naturezas
          ?.filter((item) => {
            if (encontrouReceitasDeCapital) return false;
            if (regexPermitidoNaturezas.test(item.nr)) {
              console.log(
                "DEPOIS DE RECEITAS ENCONTRADAS: ",
                encontrouReceitasDeCapital,
                item
              );
              const tituloNormalizado = normalizeString(item.especificacao);

              if (tituloNormalizado === "receitas correntes") {
                // Ignorar "Receitas Correntes"
                totalPloaReceitasCorrentes = parseFloat(item.valor);
                return false;
              }

              if (tituloNormalizado === "receitas de capital") {
                encontrouReceitasDeCapital = true;
                return false;
              }

              return true;
            }
            return false;
          })
          .map((item) => {
            const totalPloa = parseFloat(item.valor);
            const totalLdo = parseFloat("25000");
            const diferencaTotal = totalPloa - totalLdo;
            const diferencaPercentual = (
              totalPloa !== 0 ? (diferencaTotal / totalPloa) * 100 : 0
            ).toFixed(2);

            return {
              codigo: item.nr,
              titulo: item.especificacao,
              despesaInfos: {
                totalPloa,
                totalLdo,
                diferenca: {
                  TotalReal: diferencaTotal,
                  porcentagem: diferencaPercentual,
                },
              },
            };
          }) || {};

      const filteredData = data.sort((a, b) => {
        if (a.codigo < b.codigo) return -1;
        if (a.codigo > b.codigo) return 1;
        return 0;
      });

      return {
        total: totalPloaReceitasCorrentes,
        data: filteredData,
      };
    };

    const part2 = () => {
      let valorReceitasCapital = 0; // Inicializa dentro da função
      const regexPermitidoNaturezas =
        /^(\d)\.(0)\.(0)\.(0)\.(00)\.(0)\.(0)$|^(\d)\.(\d)\.(0)\.(0)\.(00)\.(0)\.(0)$/;

      let encontrouReceitasDeCapital = false;

      const data =
        dataQdrRelatorio?.naturezas
          ?.filter((item) => {
            const especificacaoNormalizada = normalizeString(
              item.especificacao
            );
            if (especificacaoNormalizada === "receitas de capital") {
              encontrouReceitasDeCapital = true;
              valorReceitasCapital = parseFloat(item.valor); // Atualiza valor
              console.log(
                "ENCONTRAMOS RECEITAS DE CAPITAL: ",
                valorReceitasCapital,
                item.valor
              );
            }
            return (
              encontrouReceitasDeCapital &&
              regexPermitidoNaturezas.test(item.nr)
            );
          })
          .map((item) => {
            const totalPloa = parseFloat(item.valor);
            const totalLdo = parseFloat("25000");
            const diferencaTotal = totalPloa - totalLdo;
            const diferencaPercentual = (
              totalPloa !== 0 ? (diferencaTotal / totalPloa) * 100 : 0
            ).toFixed(2);

            if (normalizeString(item.especificacao) !== "receitas de capital") {
              return {
                codigo: item.nr,
                titulo: item.especificacao,
                despesaInfos: {
                  totalPloa,
                  totalLdo,
                  diferenca: {
                    TotalReal: diferencaTotal,
                    porcentagem: diferencaPercentual,
                  },
                },
              };
            } else {
              return null;
            }
          }) || {};

      const filteredData = data
        .filter((item) => item !== null)
        .sort((a, b) => {
          if (a.codigo < b.codigo) return -1;
          if (a.codigo > b.codigo) return 1;
          return 0;
        });

      return { data: filteredData, total: valorReceitasCapital }; // Retorna também o total
    };

    const receitasCorrentes = part1();
    const receitasDeCapital = part2();

    return {
      receitasCorrentes: {
        totalPLoa: receitasCorrentes.total,
        data: receitasCorrentes.data,
      },
      receitasDeCapital: {
        totalPLoa: receitasDeCapital.total,
        data: receitasDeCapital.data,
      },
    };
  };

  const final = getReceita();

  mockData[0].infoUnidade.receitas[0].receitaInfos.totalPloa =
    final.receitasCorrentes.totalPLoa;
  mockData[0].infoUnidade.receitas[0].despesas = final.receitasCorrentes.data;

  mockData[0].infoUnidade.receitas[1].receitaInfos.totalPloa =
    final.receitasDeCapital.totalPLoa;
  mockData[0].infoUnidade.receitas[1].despesas = final.receitasDeCapital.data;

  console.log("mockData:", mockData);
  return {
    data: mockData,
  };
};
