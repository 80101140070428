import React from "react";
import { Row } from "react-bootstrap";
import { CCol } from "../../../components/Grid/CCol";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";

const EstruturaPcasp = ({ valuesPCASP }) => {
  return (
    <>
      <Row className="row_endereco_conteudo p-2">
        <span>Detalhamento</span>
      </Row>

      <Row className="row_form mt-1">
        <CCol md={1}>
          <FloatingLabelInput
            disabled
            value={valuesPCASP.idIc}
            label="IdIc"
            placeholder="IdIc"
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            value={valuesPCASP.naturezaDeSaldo}
            label="Natureza de Saldo"
            placeholder="Natureza de Saldo"
          />
        </CCol>
        <CCol md={4}>
          <FloatingLabelInput
            disabled
            value={valuesPCASP.infoComplementar}
            label="Info Complementar"
            placeholder="Info Complementar"
          />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput
            disabled
            value={valuesPCASP.nivelDetalhado}
            label="Nível Detalhado"
            placeholder="Nível Detalhado"
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            value={valuesPCASP.status}
            label="Status"
            placeholder="Status"
          />
        </CCol>
      </Row>

      <Row className="row_form_2" style={{ marginTop: "-14px" }}></Row>
    </>
  );
};

export default EstruturaPcasp;
