import React, { useContext } from "react";
import { CurrentClientContext } from "../../../../../contexts/CurrentClientContext";

import * as RS from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/stylesVisualizacaoRelatorio";

import { AuthContext } from "../../../../../contexts/AuthContext";
import { HeaderRelatorio } from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/HeaderRelatorio";
import { ColunasRelatorio } from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/ColunasRelatorio";
import { InfosRelatorio } from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/InfosRelatorio";
import { RodapeRelatorio } from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/RodapeRelatorio";

const VisualizacaoRelatorioFuncaoSubFuncao = ({
  dadosRelatorio,
  numeroDaPagina,
  totalDePaginas,
  dataClientInfos
}) => {
  const { currentClient } = useContext(CurrentClientContext);
  const { userAuthData } = useContext(AuthContext);

  if (!dadosRelatorio) return null;

  const colNamesFuncao = ["Código", "Tipo de Vínculo", "Informação"];
  const colNamesSubfuncao = ["Código", "Função", "Informação"];
  const styledCol = [
    { flexBasis: "125px", flexShrink: 0 },
    { flexBasis: "300px", flexShrink: 0 },
    { flexBasis: "100%", flexShrink: 1 },
  ];

  return (
    <>
      <RS.ContainerRelatorio>
        <RS.PageHeader>
          <HeaderRelatorio
            currentClient={currentClient}
            relatorioName={"Função/SubFunção"}
            dataClientInfos={dataClientInfos}
          />
        </RS.PageHeader>

        {!dadosRelatorio?.funcao?.length > 0 &&
          !dadosRelatorio?.subfuncao?.length > 0 && (
            <>
              <RS.DivExterna>
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <td>
                        <RS.PageHeaderSpace />
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <RS.DivRelatorio>
                          <RS.Colunas>
                            {colNamesFuncao.map((item, index) => (
                              <ColunasRelatorio
                                key={index}
                                colName={item}
                                styledCol={styledCol[index]}
                                textBar={
                                  item === colNamesFuncao[0] ? "Função" : ""
                                }
                              />
                            ))}
                          </RS.Colunas>
                          <RS.InfosColunas>
                            {dadosRelatorio?.funcao?.map((item) => (
                              <React.Fragment key={item.id}>
                                <RS.Colunas>
                                  <InfosRelatorio
                                    infoRelatorio={item.valor}
                                    styledCol={styledCol[0]}
                                  />
                                  <InfosRelatorio
                                    infoRelatorio={item.nome}
                                    styledCol={styledCol[1]}
                                  />
                                  <InfosRelatorio
                                    infoRelatorio={item.descricao}
                                    styledCol={styledCol[2]}
                                  />
                                </RS.Colunas>
                                <RS.LineInfosRelatorio />
                              </React.Fragment>
                            ))}
                            <RS.DivInferiorColuna></RS.DivInferiorColuna>
                          </RS.InfosColunas>
                        </RS.DivRelatorio>
                      </td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <td>
                        <RS.PageFooterSpace />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </RS.DivExterna>
              {/* ==================================== */}
              <RS.DivExterna style={{ pageBreakBefore: "always" }}>
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <td>
                        <RS.PageHeaderSpace />
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <RS.DivRelatorio>
                          <RS.Colunas>
                            {colNamesSubfuncao.map((item, index) => (
                              <ColunasRelatorio
                                key={index}
                                colName={item}
                                styledCol={styledCol[index]}
                                textBar={
                                  item === colNamesSubfuncao[0]
                                    ? "Subfunção"
                                    : ""
                                }
                              />
                            ))}
                          </RS.Colunas>
                          <RS.InfosColunas>
                            {dadosRelatorio?.subfuncao?.map((item) => (
                              <React.Fragment key={item.id}>
                                <RS.Colunas>
                                  <InfosRelatorio
                                    infoRelatorio={item.valor}
                                    styledCol={styledCol[0]}
                                  />
                                  <InfosRelatorio
                                    infoRelatorio={item.nome}
                                    styledCol={styledCol[1]}
                                  />
                                  <InfosRelatorio
                                    infoRelatorio={item.descricao}
                                    styledCol={styledCol[2]}
                                  />
                                </RS.Colunas>
                                <RS.LineInfosRelatorio />
                              </React.Fragment>
                            ))}
                            <RS.DivInferiorColuna></RS.DivInferiorColuna>
                          </RS.InfosColunas>
                        </RS.DivRelatorio>
                      </td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <td>
                        <RS.PageFooterSpace />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </RS.DivExterna>
            </>
          )}

        {dadosRelatorio?.funcao?.length > 0 && (
          <>
            <RS.DivExterna>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <td>
                      <RS.PageHeaderSpace />
                    </td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <RS.DivRelatorio>
                        <RS.Colunas>
                          {colNamesFuncao.map((item, index) => (
                            <ColunasRelatorio
                              key={index}
                              colName={item}
                              styledCol={styledCol[index]}
                              textBar={
                                item === colNamesFuncao[0] ? "Função" : ""
                              }
                            />
                          ))}
                        </RS.Colunas>
                        <RS.InfosColunas>
                          {dadosRelatorio?.funcao?.map((item) => (
                            <React.Fragment key={item.id}>
                              <RS.Colunas>
                                <InfosRelatorio
                                  infoRelatorio={item.valor}
                                  styledCol={styledCol[0]}
                                />
                                <InfosRelatorio
                                  infoRelatorio={item.nome}
                                  styledCol={styledCol[1]}
                                />
                                <InfosRelatorio
                                  infoRelatorio={item.descricao}
                                  styledCol={styledCol[2]}
                                />
                              </RS.Colunas>
                              <RS.LineInfosRelatorio />
                            </React.Fragment>
                          ))}
                          <RS.DivInferiorColuna></RS.DivInferiorColuna>
                        </RS.InfosColunas>
                      </RS.DivRelatorio>
                    </td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td>
                      <RS.PageFooterSpace />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </RS.DivExterna>
          </>
        )}

        {dadosRelatorio?.subfuncao?.length > 0 && (
          <>
            <RS.DivExterna
              style={{
                pageBreakBefore:
                  dadosRelatorio?.funcao?.length > 0 ? "always" : "auto",
              }}
            >
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <td>
                      <RS.PageHeaderSpace />
                    </td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <RS.DivRelatorio>
                        <RS.Colunas>
                          {colNamesSubfuncao.map((item, index) => (
                            <ColunasRelatorio
                              key={index}
                              colName={item}
                              styledCol={styledCol[index]}
                              textBar={
                                item === colNamesSubfuncao[0] ? "Subfunção" : ""
                              }
                            />
                          ))}
                        </RS.Colunas>
                        <RS.InfosColunas>
                          {dadosRelatorio?.subfuncao?.map((item) => (
                            <React.Fragment key={item.id}>
                              <RS.Colunas>
                                <InfosRelatorio
                                  infoRelatorio={item.valor}
                                  styledCol={styledCol[0]}
                                />
                                <InfosRelatorio
                                  infoRelatorio={item.nome}
                                  styledCol={styledCol[1]}
                                />
                                <InfosRelatorio
                                  infoRelatorio={item.descricao}
                                  styledCol={styledCol[2]}
                                />
                              </RS.Colunas>
                              <RS.LineInfosRelatorio />
                            </React.Fragment>
                          ))}
                          <RS.DivInferiorColuna></RS.DivInferiorColuna>
                        </RS.InfosColunas>
                      </RS.DivRelatorio>
                    </td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td>
                      <RS.PageFooterSpace />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </RS.DivExterna>
          </>
        )}

        <RS.PageFooter>
          <RodapeRelatorio
            currentClient={currentClient}
            userAuthData={userAuthData}
          />
        </RS.PageFooter>
      </RS.ContainerRelatorio>
    </>
  );
};

export default VisualizacaoRelatorioFuncaoSubFuncao;
