import React, { useEffect, useState } from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";

const Aditivos = ({ addAditivos, values }) => {
  const [defValues, setDefValues] = useState({
    nAditivos: values.length,
    dataAssinatura: "",
    dataVigencia: "",
    motivo: "",
    valor: 0,
  });

  const addValues = () => {
    const hasEmptyValues = Object.values(defValues).some(
      (value) => value === "" || value === null || value === undefined
    );

    if (!hasEmptyValues) {
      const novoAditivo = {
        ...defValues,
        nAditivos: defValues.nAditivos,
      };

      addAditivos(novoAditivo);
      setDefValues({
        nAditivos: defValues.nAditivos + 1,
        dataAssinatura: "",
        dataVigencia: "",
        motivo: "",
        valor: 0,
      });
    }
  };

  useEffect(() => {
    setDefValues({
      nAditivos: values.length,
      dataAssinatura: "",
      dataVigencia: "",
      motivo: "",
      valor: 0,
    });
  }, [values]);

  return (
    <>
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Nº Aditivos</PTitulosInputs>
            <FormControlListaDeInputs
              disabled
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              value={defValues.nAditivos}
              // onChange={(e) => setDefValues((prevValues) => ({
              //   ...prevValues,
              //   nAditivos: e.target.value
              // }))}
            />
          </CCol>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Data Assinatura</PTitulosInputs>
            <FormControlListaDeInputs
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              value={defValues.dataAssinatura}
              onChange={(e) =>
                setDefValues((prevValues) => {
                  let input = e.target.value.replace(/\D/g, "");
                  if (input.length > 8) input = input.slice(0, 8);
                  if (input.length > 4) {
                    input = input.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
                  } else if (input.length > 2) {
                    input = input.replace(/(\d{2})(\d{2})/, "$1/$2");
                  }

                  return {
                    ...prevValues,
                    dataAssinatura: input,
                  };
                })
              }
            />
          </CCol>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Data Vigência</PTitulosInputs>
            <FormControlListaDeInputs
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              value={defValues.dataVigencia}
              onChange={(e) =>
                setDefValues((prevValues) => {
                  let input = e.target.value.replace(/\D/g, "");
                  if (input.length > 8) input = input.slice(0, 8);
                  if (input.length > 4) {
                    input = input.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
                  } else if (input.length > 2) {
                    input = input.replace(/(\d{2})(\d{2})/, "$1/$2");
                  }

                  return {
                    ...prevValues,
                    dataVigencia: input,
                  };
                })
              }
            />
          </CCol>
          <CCol>
            <PTitulosInputs>Motivo</PTitulosInputs>
            <FormControlListaDeInputs
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              value={defValues.motivo}
              onChange={(e) =>
                setDefValues((prevValues) => ({
                  ...prevValues,
                  motivo: e.target.value,
                }))
              }
            />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Valor</PTitulosInputs>
            <FormControlListaDeInputs
              radiusborder={values.length > 0 ? "5px 5px 0 0" : "5px"}
              value={defValues.valor}
              onChange={(e) => {
                let input = e.target.value;
                input = input.replace(/[^0-9,]/g, "");
                if (input.includes(",")) {
                  const [inteiro, centavos] = input.split(",");
                  input = `${inteiro},${centavos.slice(0, 2)}`;
                }
                const partes = input.split(",");
                let numero = partes[0];
                numero = numero.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                input = partes.length > 1 ? `${numero},${partes[1]}` : numero;
                setDefValues((prevValues) => ({
                  ...prevValues,
                  valor: input,
                }));
              }}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <IconAdicionar onClick={() => addValues()} />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
        {/* - Dados - */}
        {values.map((item, index) => {
          const border = index === values.length - 1 ? "0 0 5px 5px" : "0";
          return (
            <RowAninhada>
              <CCol md={1} style={{ width: "9em" }}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={values[index].nAditivos}
                />
              </CCol>
              <CCol md={1} style={{ width: "9em" }}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={values[index].dataAssinatura}
                />
              </CCol>
              <CCol md={1} style={{ width: "9em" }}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={values[index].dataVigencia}
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={values[index].motivo}
                />
              </CCol>
              <CCol md={2}>
                <FormControlListaDeInputs
                  disabled
                  radiusborder={border}
                  value={values[index].valor}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                {/* <IconAdicionar /> */}
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          );
        })}
      </Row>
    </>
  );
};

export default Aditivos;
