import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  ButtonOptionsFirst,
  ButtonOptionsPages,
  ColButtons,
  ButtonOptionsBetween,
} from "../../../../styles/StyledComponents/ButtonsSelectPage";
import Receita from "./Receita";
import Nominal from "./Nominal";
import { ReturnArrayAnos } from "./returnArrayAnos";
import PatrimonioLiquido from "./PatrimonioLiquido";
import Despesa from "./Despesa";
import Divida from "./Divida";
import AlienacoesInvestimentos from "./AlienacoesInvestimentos";
import Atuarial from "./Atuarial";
import Renuncia from "./Renuncia";
import Expansao from "./Expansao";
import Notas from "./Notas";

const MetasFiscais = () => {
  const [botaoAtivo, setBotaoAtivo] = useState(0);
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const arrayAnos = ReturnArrayAnos();

  const save = () => {
    setSaveButtonStatus(true);
  };

  //Estados da pagina NOMINAL

  const [arrayEspecificacoesNominal, setArrayEspecificacoesNominal] = useState([
    {
      especificacao: "teste",
      prevista: arrayAnos.map((ano) => ({
        ano: ano,
        valor: 0,
      })),
      realizada: arrayAnos.map((ano) => ({
        ano: ano,
        valor: 0,
      })),
    },
  ]);
  const [valueEspecificacao, setValueEspecificacao] = useState("");

  //Estados da pagina Patrimonio Liquido

  // const [valuesPatrimonio,setValuesPatrimonio] = useState()

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"LDO"}
        PaginaSubtitulo={"Metas Fiscais"}
        withRow={false}
        hidePages
        ButtonSaveFunction={() => save()}
      />

      <Row>
        {/* <button onClick={ReturnArrayAnos}>ssadaa</button> */}
        <ColButtons md={12}>
          <ButtonOptionsPages>
            <ButtonOptionsFirst
              ativo={botaoAtivo === 0}
              onClick={() => setBotaoAtivo(0)}
              style={{ padding: "0px 20px" }}
            >
              Receita
            </ButtonOptionsFirst>
            <ButtonOptionsBetween
              ativo={botaoAtivo === 1}
              onClick={() => setBotaoAtivo(1)}
            >
              Despesa
            </ButtonOptionsBetween>
            <ButtonOptionsBetween
              ativo={botaoAtivo === 2}
              onClick={() => setBotaoAtivo(2)}
            >
              Nominal
            </ButtonOptionsBetween>
            <ButtonOptionsBetween
              ativo={botaoAtivo === 3}
              onClick={() => setBotaoAtivo(3)}
            >
              Patrimônio Líquido
            </ButtonOptionsBetween>
            <ButtonOptionsBetween
              ativo={botaoAtivo === 4}
              onClick={() => setBotaoAtivo(4)}
            >
              Dívida
            </ButtonOptionsBetween>
            <ButtonOptionsBetween
              ativo={botaoAtivo === 5}
              onClick={() => setBotaoAtivo(5)}
            >
              Alienações
            </ButtonOptionsBetween>
            <ButtonOptionsBetween
              ativo={botaoAtivo === 6}
              onClick={() => setBotaoAtivo(6)}
            >
              Atuarial
            </ButtonOptionsBetween>
            <ButtonOptionsBetween
              ativo={botaoAtivo === 7}
              onClick={() => setBotaoAtivo(7)}
            >
              Renuncia
            </ButtonOptionsBetween>
            <ButtonOptionsBetween
              ativo={botaoAtivo === 8}
              onClick={() => setBotaoAtivo(8)}
            >
              Expansão
            </ButtonOptionsBetween>
            <ButtonOptionsBetween
              ativo={botaoAtivo === 9}
              onClick={() => setBotaoAtivo(9)}
            >
              Notas
            </ButtonOptionsBetween>
          </ButtonOptionsPages>
        </ColButtons>
      </Row>
      {botaoAtivo === 0 && (
        <Receita
          arrayEspecificacoesNominal={arrayEspecificacoesNominal}
          setArrayEspecificacoesNominal={setArrayEspecificacoesNominal}
          setValueEspecificacao={setValueEspecificacao}
          valueEspecificacao={valueEspecificacao}
        />
      )}
      {botaoAtivo === 1 && <Despesa />}
      {botaoAtivo === 2 && (
        <Nominal
          arrayEspecificacoesNominal={arrayEspecificacoesNominal}
          setArrayEspecificacoesNominal={setArrayEspecificacoesNominal}
          setValueEspecificacao={setValueEspecificacao}
          valueEspecificacao={valueEspecificacao}
        />
      )}

      {botaoAtivo === 3 && (
        <PatrimonioLiquido
          saveButtonStatus={saveButtonStatus}
          setSaveButtonStatus={setSaveButtonStatus}
        />
      )}
      {botaoAtivo === 4 && (
        <Divida
          arrayEspecificacoesNominal={arrayEspecificacoesNominal}
          setArrayEspecificacoesNominal={setArrayEspecificacoesNominal}
          setValueEspecificacao={setValueEspecificacao}
          valueEspecificacao={valueEspecificacao}
        />
      )}
      {botaoAtivo === 5 && (
        <AlienacoesInvestimentos
          arrayEspecificacoesNominal={arrayEspecificacoesNominal}
          setArrayEspecificacoesNominal={setArrayEspecificacoesNominal}
          setValueEspecificacao={setValueEspecificacao}
          valueEspecificacao={valueEspecificacao}
        />
      )}
      {botaoAtivo === 6 && (
        <Atuarial
          arrayEspecificacoesNominal={arrayEspecificacoesNominal}
          setArrayEspecificacoesNominal={setArrayEspecificacoesNominal}
          setValueEspecificacao={setValueEspecificacao}
          valueEspecificacao={valueEspecificacao}
        />
      )}
      {botaoAtivo === 7 && (
        <Renuncia
          arrayEspecificacoesNominal={arrayEspecificacoesNominal}
          setArrayEspecificacoesNominal={setArrayEspecificacoesNominal}
          setValueEspecificacao={setValueEspecificacao}
          valueEspecificacao={valueEspecificacao}
        />
      )}
      {botaoAtivo === 8 && (
        <Expansao
          arrayEspecificacoesNominal={arrayEspecificacoesNominal}
          setArrayEspecificacoesNominal={setArrayEspecificacoesNominal}
          setValueEspecificacao={setValueEspecificacao}
          valueEspecificacao={valueEspecificacao}
        />
      )}
      {botaoAtivo === 9 && (
        <Notas
          arrayEspecificacoesNominal={arrayEspecificacoesNominal}
          setArrayEspecificacoesNominal={setArrayEspecificacoesNominal}
          setValueEspecificacao={setValueEspecificacao}
          valueEspecificacao={valueEspecificacao}
        />
      )}
      <div style={{ minHeight: "40px" }}>{""}</div>
    </Container>
  );
};

export default MetasFiscais;
