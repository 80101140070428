import React from "react";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useFetchData } from "../../../../hooks/useFetchData";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol } from "../../../../components/Grid/CCol";
import Dashboard from "../../../../components/ComponentesDeDashboard/Dashboard";
import { useNavigate } from "react-router-dom";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";

export const DashboardNatDesp = () => {
  const { currentYear } = useCurrentYear();
  const navigate = useNavigate();
  const { data: dataElementosDesp } = useFetchData(
    `/naturezaDaDespesa/${currentYear}`
  );
  const [quantidadeElementosDesp, setQuantidadeElementosDesp] = useState("0");
  const [box1, setBox1] = useState([]);
  const options = [
    { label: "Quantidade", value: null },
    { label: "Quantidade", value: 1 },
  ];

  useEffect(() => {
    if (dataElementosDesp) {
      if (dataElementosDesp.length > 0) {
        setQuantidadeElementosDesp(
          dataElementosDesp.length < 10
            ? "0" + dataElementosDesp.length
            : dataElementosDesp.length
        );
      }
    }
  }, [dataElementosDesp]);

  useEffect(() => {
    console.log("aqui: ", quantidadeElementosDesp);
    if (quantidadeElementosDesp > 0) {
      setBox1({
        boxTitle: "Quantidade",
        boxSubtitle: "Registros",
        boxValue: quantidadeElementosDesp,
      });
    }
  }, [quantidadeElementosDesp]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Natureza da Despesa"}
        PaginaSubtitulo={"Dashboard"}
        PaginaConsulta={"/planejamento/cadastro/natureza-da-despesa/consulta"}
        //PaginaRelatorio={""}
        BotaoNovoFunction={() =>
          navigate("/planejamento/cadastro/natureza-da-despesa")
        }
        BotaoSaveAtivo={false}
        PaginaRelatorio={"/planejamento/cadastro/natureza-da-despesa/relatorio"}
      />
      <Row className="row_form_2 mt-1" style={{ padding: "20px 10px" }}>
        <CCol>
          <Dashboard box1={box1} options={options} />
        </CCol>
        <CCol>
          <Dashboard />
        </CCol>
      </Row>
      <div className="p-3"></div>
    </Container>
  );
};

export default DashboardNatDesp;
