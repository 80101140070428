import React from "react";
import { RowTituloAba } from "./StyledComponentsContaPerfil";
import { CCol } from "../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import InputFile from "../../../styles/StyledComponents/InputFileStyled";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";
import { CColButtonsAcoes } from "../../../components/Grid/CCol";
import { Icon } from "@iconify/react";

const AbaDocumentosUsuario = ({ setValuesUser, valuesUser }) => {
  return (
    <>
      <RowTituloAba>
        <CCol>
          <span>Documentos</span>
        </CCol>
      </RowTituloAba>

      <Row className="row_form mt-1 mb-1">
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            options={[{ label: "Documentos", value: "teste" }]}
            label="Documentos"
          />
        </CCol>
        <CColButtonsAcoes md={1}>
          <Icon
            icon="ic:baseline-add-box"
            style={{ cursor: "pointer", marginTop: "3px" }}
            color="#105200"
            width="30"
            height="30"
          />
        </CColButtonsAcoes>
      </Row>

      <Row className="row_form_2 mt-1">
        <CCol>
          <InputFile
            placeholder="CPF do Usuário"
            onChange={(e) => {
              console.log(e.target.files);
              setValuesUser((prevValues) => ({
                ...prevValues,
                cpfAnexo: e.target.files[0].name,
              }));
            }}
            arquivo={valuesUser.cpfAnexo}
          />
        </CCol>

        <CColButtonsAcoes md={1}>
          <Icon
            icon="ic:baseline-delete"
            style={{ cursor: "pointer", marginTop: "5px" }}
            color="#E79900"
            width="30"
            height="30"
          />
        </CColButtonsAcoes>
      </Row>
    </>
  );
};

export default AbaDocumentosUsuario;
