import React from "react";
import { CCol, ContainerToModal } from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { Row } from "react-bootstrap";

const CPFCNPJ = () => {
  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Cadastro"}
        PaginaSubtitulo={"CPF / CNPJ"}
        RowTitle={"CPF / CNPJ"}
        BotaoSaveAtivo={false}
        BotaoNovoAtivo={false}
      />

      <Row className="row_form mt-1">
        <CCol>CPF / CNPJ</CCol>
      </Row>
      <div style={{ minHeight: "40px" }}> </div>
    </ContainerToModal>
  );
};

export default CPFCNPJ;
