import styled from "styled-components";
import Modal from "react-modal";
import { Icon } from "@iconify/react";

export const ModalStyled = styled(Modal)`
  width: 40%;
  padding: 30px;
  position: absolute;
  background-color: white;
  border-radius: 10px;
  right: 29%;
  top: 25%;
  z-index: 999;
  outline: none;

  @media (max-width: 575.98px) {
    width: 70%;
    left: 20%;
  }

  @media (max-width: 767.98px) {
    width: 55%;
    left: 25%;
  }

  @media (max-width: 991.98px) {
    width: 41%;
    left: 30%;
  }

  h6 {
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
  }

  button {
    outline: none;
    box-shadow: none;
    border: none;
    padding: 5px;
    width: 70px;
    margin: 10px 5px;
    border-radius: 6px;
    color: white;
  }

  .icon_alert_modal {
    animation-name: ${({ color }) =>
      color === "red" ? "in-off-red" : "in-off"};
    color: ${({ color }) => (color === "red" ? "red" : "#f8d079")};
    animation-duration: 0.8s;
    animation-direction: alternate;
    animation-delay: 0s;
    animation-iteration-count: infinite;
  }

  button.sim_button {
    background-color: #238c59;
  }

  button.nao_button {
    background-color: red;
  }

  p {
    font-size: 1.2rem;
    text-align: center;
    padding-left: 10px;
    padding-bottom: 7px;
    color: #515c70;
    margin-bottom: 0;
  }

  @keyframes in-off {
    50% {
      color: #f8d079;
    }

    100% {
      color: #fae7bf;
    }
  }

  @keyframes in-off-red {
    50% {
      color: #ff0000;
    }

    100% {
      color: #ac6565;
    }
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const DivIconModal = styled.div`
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const IconAlertModal = styled(Icon)`
  animation-name: ${({ color }) => (color === "red" ? "in-off-red" : "in-off")};
  color: ${({ color }) => (color === "red" ? "red" : "#f8d079")};
  animation-duration: 0.8s;
  animation-direction: alternate;
  animation-delay: 0s;
  animation-iteration-count: infinite;

  @keyframes in-off {
    50% {
      color: #f8d079;
    }

    100% {
      color: #fae7bf;
    }
  }

  @keyframes in-off-red {
    50% {
      color: #ff0000;
    }

    100% {
      color: #d1bebe;
    }
  }
`;
