import {
  Table,
  TableRow,
  TableCell,
  Paragraph,
  WidthType,
  TextRun,
  TableLayoutType,
  BorderStyle,
} from "docx";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { calcularTotalUnidade } from "./calcularTotalUnidade";

export const DocxTableQDD = ({
  columns,
  data,
  font = "Times New Roman",
  subTitle = "",
  piece = 0,
  columnWidth = [10, 50, 40],
  idsSelected,
  selectBoxesOptionsAbaOptions,
}) => {
  const {
    idsAcaoGovernamentalSelected,
    idsElementoDespesaSelected,
    idsFonteDeRecursoSelected,
    idsFuncaoSelected,
    idsSubfuncaoSelected,
    idsProgramaSelected,
    idsUnidadeOrcamentariaSelected,
    esferaSelected,
  } = idsSelected;

  const isOptionSelected = (option) =>
    selectBoxesOptionsAbaOptions[0].checkBoxes.some(
      (item) => item.value === option && item.selected
    );

  const options = {
    codigo: isOptionSelected("codigo"),
    titulo: isOptionSelected("descricao"),
    valor: isOptionSelected("valor"),
    classificacao: isOptionSelected("classificacao"),
  };

  const createTableCell = (
    columnIndex,
    alignment = "left",
    showOption = true
  ) =>
    new TableCell({
      width: {
        size: columnWidth[columnIndex],
        type: WidthType.PERCENTAGE,
      },
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: showOption ? columns[columnIndex] : "",
              bold: true,
              color: "FFFFFF",
              size: 10 * 2,
              font: font,
            }),
          ],
          spacing: {
            before: 250,
            after: 250,
          },
          indent: {
            left: 75,
          },
          alignment,
        }),
      ],
      shading: {
        fill: "515c70",
      },
    });

  const headerGeral = () => {
    const cells = [];

    cells.push(
      createTableCell(0, "left", options.codigo || options.classificacao)
    );
    cells.push(createTableCell(1, "left", options.titulo));
    cells.push(createTableCell(2, "right", options.valor));

    return cells;
  };

  const headerGeralComEsfera = () => {
    const cells = [];
    cells.push(
      createTableCell(0, "left", options.codigo || options.classificacao)
    );
    cells.push(createTableCell(1, "left", options.titulo));
    cells.push(createTableCell(2, "left"));
    cells.push(createTableCell(3, "right", options.valor));

    return cells;
  };

  const headerFiltroPorFonte = () => {
    const cells = [];

    cells.push(createTableCell(0, "left", options.codigo));
    cells.push(createTableCell(1, "left", options.codigo));
    cells.push(createTableCell(2, "left", options.titulo));
    cells.push(createTableCell(3, "right", options.valor));

    return cells;
  };

  const headerFiltroFonteEsfera = () => {
    const cells = [];

    cells.push(createTableCell(0, "left", options.codigo));
    cells.push(createTableCell(1, "left", options.codigo));
    cells.push(createTableCell(2, "left", options.titulo));
    cells.push(createTableCell(3, "left"));
    cells.push(createTableCell(4, "right", options.valor));

    return cells;
  };

  const headerFiltroPorEsfera = () => {
    const cells = [];

    cells.push(createTableCell(0));
    cells.push(createTableCell(1, "right"));

    return cells;
  };

  const headerDocx = () => {
    const filters = [
      idsAcaoGovernamentalSelected,
      idsElementoDespesaSelected,
      idsFuncaoSelected,
      idsSubfuncaoSelected,
      idsProgramaSelected,
      idsUnidadeOrcamentariaSelected,
    ];

    const filtroFonte =
      idsFonteDeRecursoSelected.length &&
      !esferaSelected.length &&
      filters.every((arr) => arr.length === 0);

    const filtroFonteEsfera =
      idsFonteDeRecursoSelected.length &&
      esferaSelected.length &&
      filters.every((arr) => arr.length === 0);

    const filtroEsfera =
      esferaSelected.length > 0 &&
      !idsFonteDeRecursoSelected.length &&
      filters.every((arr) => arr.length === 0);

    const filtroGeralComEsfera =
      esferaSelected.length > 0 && idsElementoDespesaSelected.length > 0;

    if (filtroFonte) {
      return [...headerFiltroPorFonte()];
    }

    if (filtroFonteEsfera) {
      return [...headerFiltroFonteEsfera()];
    }

    if (filtroEsfera) {
      return [...headerFiltroPorEsfera()];
    }

    if (filtroGeralComEsfera) {
      return [...headerGeralComEsfera()];
    }

    return [...headerGeral()];
  };

  // const headerCells = columns.map((header, index) => {
  //   return new TableCell({
  //     width: {
  //       size: columnWidth[index],
  //       type: WidthType.PERCENTAGE,
  //     },
  //     children: [
  //       new Paragraph({
  //         children: [
  //           new TextRun({
  //             text: header,
  //             bold: true,
  //             color: "FFFFFF",
  //             size: 10 * 2,
  //             font: font,
  //           }),
  //         ],
  //         spacing: {
  //           before: 250,
  //           after: 250,
  //         },
  //         alignment:
  //           index === columns.length - 1
  //             ? "right"
  //             : header === "Natureza Da Despesa"
  //             ? "center"
  //             : "start",
  //         indent: {
  //           left: index === 0 ? 75 : 0,
  //         },
  //       }),
  //     ],
  //     shading: {
  //       fill: "515c70",
  //     },
  //   });
  // });

  const renderInfosUnidadeOrcamentaria = (qdd) => {
    return [
      new TableCell({
        children: [
          ...(idsUnidadeOrcamentariaSelected.length
            ? [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: options.codigo
                        ? ` ${qdd.codigoUnidadeOrcamentaria}`
                        : "",
                      size: 9 * 2,
                      font: font,
                      bold: true,
                    }),
                  ],
                  spacing: {
                    after: 50,
                  },
                  shading: {
                    fill: "a6a6a6",
                  },
                }),
              ]
            : []),
          ...qdd.acoes.flatMap((acao) => [
            ...(idsAcaoGovernamentalSelected.length
              ? [...renderInfosCodigoAcaoGovernamental(acao)]
              : []),
            ...acao.despesaFixadaQDD.flatMap((ficha) => [
              ...(idsFonteDeRecursoSelected.length
                ? [renderInfosCodigoFonteRecurso(ficha.fonteDeRecurso.conta)]
                : []),
              ...ficha.fichasQDD.flatMap((natureza) =>
                idsElementoDespesaSelected.length
                  ? [renderInfosCodigoNaturezaReceita(natureza)]
                  : []
              ),
            ]),
          ]),
        ],
      }),

      new TableCell({
        children: [
          ...(idsUnidadeOrcamentariaSelected.length
            ? [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: options.titulo ? qdd.tituloUnidadeOrcamentaria : "",
                      size: 9 * 2,
                      font: font,
                      bold: true,
                    }),
                  ],
                  spacing: {
                    after: 50,
                  },
                  shading: {
                    fill: "a6a6a6",
                  },
                }),
              ]
            : []),
          ...qdd.acoes.flatMap((acao) => [
            ...(idsAcaoGovernamentalSelected.length
              ? [renderInfosTituloReceita(options.titulo ? acao.titulo : "")]
              : []),
            ...(idsAcaoGovernamentalSelected.length
              ? [
                  renderInfosTituloReceita(
                    options.titulo ? acao.objetivos : "",
                    false
                  ),
                ]
              : []),
            ...acao.despesaFixadaQDD.flatMap((ficha) => [
              ...(idsFonteDeRecursoSelected.length
                ? [
                    renderInfosTituloReceita(
                      options.titulo ? ficha.fonteDeRecurso.titulo : "",
                      false
                    ),
                  ]
                : []),
              ...ficha.fichasQDD.flatMap((natureza) =>
                idsElementoDespesaSelected.length
                  ? [
                      renderInfosTituloReceita(
                        options.titulo
                          ? `${natureza.naturezaDaDespesa.nome}`
                          : ""
                      ),
                    ]
                  : []
              ),
            ]),
          ]),
        ],
      }),
      ...(esferaSelected.length && idsElementoDespesaSelected.length
        ? [
            new TableCell({
              children: [
                ...(idsUnidadeOrcamentariaSelected.length
                  ? [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                            size: 9 * 2,
                            font: font,
                            bold: true,
                          }),
                        ],
                        spacing: {
                          after: 50,
                        },
                        shading: {
                          fill: "a6a6a6",
                        },
                      }),
                    ]
                  : []),
                ...qdd.acoes.flatMap((acao) => [
                  ...(idsAcaoGovernamentalSelected.length
                    ? [renderInfosTituloReceita("")]
                    : []),
                  ...(idsAcaoGovernamentalSelected.length
                    ? [renderInfosTituloReceita("")]
                    : []),
                  ...acao.despesaFixadaQDD.flatMap((ficha) => [
                    ...(idsFonteDeRecursoSelected.length
                      ? [renderInfosTituloReceita("")]
                      : []),
                    ...ficha.fichasQDD.flatMap((natureza) =>
                      esferaSelected.length && idsElementoDespesaSelected.length
                        ? [renderInfosTituloReceita(`${natureza.esfera}`)]
                        : []
                    ),
                  ]),
                ]),
              ],
            }),
          ]
        : []),
      new TableCell({
        children: [
          ...(idsUnidadeOrcamentariaSelected.length
            ? [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: options.valor
                        ? `${FormatValueToLocaleString(
                            parseFloat(qdd.totalAcoesDaUnidade)
                          )}`
                        : "",
                      size: 9 * 2,
                      font: font,
                      bold: true,
                    }),
                  ],
                  spacing: {
                    after: 50,
                  },
                  alignment: "right",
                  shading: {
                    fill: "a6a6a6",
                  },
                }),
              ]
            : []),
          ...qdd.acoes.flatMap((acao) => [
            ...(idsAcaoGovernamentalSelected.length
              ? [...renderInfosValueAcaoGovernamental(acao.valorTotalDaAcao)]
              : []),
            ...acao.despesaFixadaQDD.flatMap((ficha) => [
              ...(idsFonteDeRecursoSelected.length
                ? [
                    renderInfosValueReceita(
                      options.valor ? ficha.totalPorFonte : "",
                      false
                    ),
                  ]
                : []),
              ...ficha.fichasQDD.flatMap((natureza) =>
                idsElementoDespesaSelected.length
                  ? [
                      renderInfosValueReceita(
                        options.valor ? natureza.valor : ""
                      ),
                    ]
                  : []
              ),
            ]),
          ]),
        ],
      }),
    ];
  };

  const renderInfosCodigoAcaoGovernamental = (acao) => {
    return [
      new Paragraph({
        children: [
          new TextRun({
            text: options.classificacao
              ? `${acao.funcao.valor}.${acao.subFuncao.valor}.${acao.programaPlanejamentoId.numeroPrograma}`
              : "",
            size: 9 * 2,
            font: font,
          }),
          new TextRun({
            text: options.codigo ? ` ${acao.nAcao}` : "",
            size: 9 * 2,
            font: font,
            bold: true,
          }),
        ],
        spacing: {
          before: 50,
          after: 50,
        },
        indent: {
          left: 75,
        },
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: "",
            size: 9 * 2,
            font: font,
          }),
        ],
        spacing: {
          before: 50,
          after: 50,
        },
        indent: {
          left: 75,
        },
      }),
    ];
  };

  const renderInfosValueAcaoGovernamental = (value) => {
    return [
      new Paragraph({
        children: [
          new TextRun({
            text: options.valor
              ? `${FormatValueToLocaleString(parseFloat(value))}`
              : "",
            size: 9 * 2,
            font: font,
            bold: true,
          }),
        ],
        spacing: {
          before: 50,
          after: 50,
        },
        alignment: "right",
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: "",
            size: 9 * 2,
            font: font,
          }),
        ],
        spacing: {
          before: 50,
          after: 50,
        },
        indent: {
          left: 75,
        },
      }),
    ];
  };

  const renderInfosCodigoFonteRecurso = (conta) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: options.codigo ? conta : "",
          size: 9 * 2,
          font: font,
        }),
      ],
      spacing: {
        before: 50,
        after: 50,
      },
      alignment: "right",
      indent: { right: 230 },
    });
  };

  const renderInfosTituloReceita = (titulo, styleBold = true) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: titulo,
          size: 9 * 2,
          font: font,
          bold: styleBold,
        }),
      ],
      spacing: {
        before: 50,
        after: 50,
      },
    });
  };

  const renderInfosValueReceita = (value, styleBold = true) => {
    return new Paragraph({
      children: [
        new TextRun({
          text:
            value !== ""
              ? `${FormatValueToLocaleString(parseFloat(value))}`
              : "",
          size: 9 * 2,
          font: font,
          bold: styleBold,
        }),
      ],
      spacing: {
        before: 50,
        after: 50,
      },
      alignment: "right",
    });
  };

  const renderInfosCodigoNaturezaReceita = (natureza) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: options.codigo
            ? `${natureza.numeroFicha}   ${natureza.naturezaDaDespesa.codigo}`
            : "",
          size: 9 * 2,
          font: font,
          bold: true,
        }),
      ],
      spacing: {
        before: 50,
        after: 50,
      },
    });
  };

  const renderInfosFiltroPorFonteRecursos = (fonte) => {
    const filtroPorFiscal = esferaSelected.includes("Fiscal");
    const filtroPorSeguridade = esferaSelected.includes("Seguridade");

    return new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: options.codigo ? `${fonte.conta[0]}` : "",
                  size: 9 * 2,
                  font: font,
                }),
              ],
              spacing: {
                before: 50,
                after: 50,
              },
              indent: {
                left: 75,
              },
            }),
          ],
        }),
        new TableCell({
          children: [
            renderInfosTituloReceita(
              options.codigo ? `${fonte.conta.split(".")[1]}` : "",
              false
            ),
          ],
        }),
        new TableCell({
          children: [
            renderInfosTituloReceita(options.titulo ? fonte.titulo : "", false),
          ],
        }),
        ...(esferaSelected.length > 0
          ? [
              new TableCell({
                children: [
                  ...(filtroPorFiscal ? [renderInfosEsfera("Fiscal")] : []),
                  ...(filtroPorSeguridade
                    ? [renderInfosEsfera("Seguridade")]
                    : []),
                ],
              }),
            ]
          : []),
        ...(esferaSelected.length > 0
          ? [
              new TableCell({
                children: [
                  ...(filtroPorFiscal
                    ? [
                        renderInfosValueReceita(
                          options.valor ? fonte.totalFiscal : ""
                        ),
                      ]
                    : []),
                  ...(filtroPorSeguridade
                    ? [
                        renderInfosValueReceita(
                          options.valor ? fonte.totalSeguridade : ""
                        ),
                      ]
                    : []),
                ],
              }),
            ]
          : []),

        ...(!filtroPorFiscal && !filtroPorSeguridade
          ? [
              new TableCell({
                children: [
                  renderInfosValueReceita(
                    options.valor
                      ? fonte.totalFiscal + fonte.totalSeguridade
                      : ""
                  ),
                ],
              }),
            ]
          : []),
      ],
    });
  };

  const renderInfosEsfera = (esferaName) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: esferaName,
          size: 9 * 2,
          font: font,
        }),
      ],
      spacing: {
        before: 50,
        after: 50,
      },
      indent: {
        left: 75,
      },
    });
  };

  const renderInfosFiltroPorEsfera = (esfera) => {
    const filtroPorFiscal = esferaSelected.includes("Fiscal");
    const filtroPorSeguridade = esferaSelected.includes("Seguridade");

    return new TableRow({
      children: [
        new TableCell({
          children: [
            ...(filtroPorFiscal ? [renderInfosEsfera("Fiscal")] : []),
            ...(filtroPorSeguridade ? [renderInfosEsfera("Seguridade")] : []),
          ],
        }),
        new TableCell({
          children: [
            ...(filtroPorFiscal
              ? [renderInfosValueReceita(esfera.totalGeralFiscal)]
              : []),
            ...(filtroPorSeguridade
              ? [renderInfosValueReceita(esfera.totalGeralSeguridade)]
              : []),
          ],
        }),
      ],
    });
  };

  const renderGeralInfos = (qdd) => {
    return new TableRow({
      children: [...renderInfosUnidadeOrcamentaria(qdd)],
    });
  };

  const renderTotalGeral = (total) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: options.valor
            ? `TOTAL R$ ${FormatValueToLocaleString(total)}`
            : "",
          size: 9 * 2,
          font: font,
          bold: true,
        }),
      ],
      spacing: {
        before: 50,
      },
      alignment: "right",
      indent: {
        right: 75,
      },
    });
  };

  const createEmptyRow = (color) =>
    new TableRow({
      children: columns.map(
        () =>
          new TableCell({
            children: [
              new Paragraph({
                children: [new TextRun({ text: "", font: font })],
              }),
            ],
            shading: {
              fill: color,
            },
          })
      ),
    });

  const dataTableRows = () => {
    const filters = [
      idsAcaoGovernamentalSelected,
      idsElementoDespesaSelected,
      idsFuncaoSelected,
      idsSubfuncaoSelected,
      idsProgramaSelected,
      idsUnidadeOrcamentariaSelected,
    ];

    const filtroFonte =
      idsFonteDeRecursoSelected.length &&
      filters.every((arr) => arr.length === 0);

    const filtroEsfera =
      esferaSelected.length > 0 &&
      !idsFonteDeRecursoSelected.length &&
      filters.every((arr) => arr.length === 0);

    if (filtroFonte) {
      console.log("filtro Por Fonte");
      const fontes = data.fontes.length
        ? data.fontes.map((fonte) => renderInfosFiltroPorFonteRecursos(fonte))
        : [];

      const tableRows = [
        new TableRow({
          children: headerDocx(),
          tableHeader: true,
        }),
        ...fontes,
        createEmptyRow("515c70"),
      ];
      const totalParagraph = renderTotalGeral(Number(data.totais.totalGeral));
      return { tableRows, totalParagraph };
    }

    if (filtroEsfera) {
      console.log("filtro Por Fonte e esfera");
      const tableRows = [
        new TableRow({
          children: headerDocx(),
          tableHeader: true,
        }),
        renderInfosFiltroPorEsfera(data.totais),
        createEmptyRow("515c70"),
      ];
      const totalParagraph = renderTotalGeral(Number(data.totais.totalGeral));
      return { tableRows, totalParagraph };
    }

    const dataRowsFiltroGeral = data.length
      ? data.map((item) => renderGeralInfos(item))
      : [];
    const tableRows = [
      new TableRow({
        children: headerDocx(),
        tableHeader: true,
      }),
      ...dataRowsFiltroGeral,
      createEmptyRow("515c70"),
    ];
    const totalReceitas = calcularTotalUnidade(data);

    const totalParagraph = renderTotalGeral(totalReceitas);
    return { tableRows, totalParagraph };
  };

  const { tableRows, totalParagraph } = dataTableRows();

  return [
    new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      layout: TableLayoutType.FIXED,
      rows: tableRows,
      borders: {
        top: { style: BorderStyle.NONE, size: 0, space: 0 },
        bottom: { style: BorderStyle.NONE, size: 0, space: 0 },
        left: { style: BorderStyle.NONE, size: 0, space: 0 },
        right: { style: BorderStyle.NONE, size: 0, space: 0 },
        insideHorizontal: { style: BorderStyle.NONE, size: 0, space: 0 },
        insideVertical: { style: BorderStyle.NONE, size: 0, space: 0 },
      },
    }),
    totalParagraph,
  ];
};
