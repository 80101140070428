import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import { Row } from "react-bootstrap";
import { CCol } from "../../../../components/Grid/CCol";
//import { TituloTable } from "../../../../components/TableConsulta/TableConsulta";
import { FormControlListaDeInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import LdoMenu from "./LdoMenu";

export const TituloTable = styled.span`
  color: #888a8e;
  font-weight: 600;
  font-size: 12px;
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const TitleBox = styled.div`
  display: flex;
  position: absolute;
  background-color: ${(props) =>
    props.disabled
      ? "#555555"
      : !props.color
      ? "#555555"
      : props.color === 1
      ? "#19CE71"
      : props.color === 2
      ? "#F881A9"
      : props.color === 3
      ? "#669DFC"
      : "#555555"};
  width: fit-content;
  padding: 0 30px 0 17px;
  height: 30px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  padding-left: ${(props) => props.paddingLeft};
  background-color: ${(props) =>
    props.disabled
      ? "#e0e0df"
      : !props.color
      ? "#e0e0df"
      : props.color === 1
      ? "#CCFFE5"
      : props.color === 2
      ? "#F8DFE8"
      : props.color === 3
      ? "#CADCFC"
      : "#e0e0df"};
  border-radius: 50px;
  overflow: hidden;
  margin: 10px 0;
  height: 30px;
`;

const ProgressBarFiller = styled.div`
  height: 100%;
  width: ${(props) => (props.disabled ? 0 : props.percentage)}%;
  background-color: ${(props) =>
    props.disabled
      ? "#c5c5c5"
      : !props.color
      ? "#c5c5c5"
      : props.color === 1
      ? "#A5FFD1"
      : props.color === 2
      ? "#F8B0C9"
      : props.color === 3
      ? "#98BDFC"
      : "#c5c5c5"};
  border-radius: 50px;
  text-align: right;
  transition: width 0.2s ease-in;
`;

const MenuBox = styled.div`
  width: 100%;
  height: ${(props) => (props.opened ? "600px" : "0")};
  overflow: hidden;
  transition: height 0.5s ease;
`;

const Percentage = styled.div`
  position: absolute;
  right: 40px;
  color: ${(props) =>
    props.disabled
      ? "#555555"
      : !props.color
      ? "#555555"
      : props.color === 1
      ? "#19CE71"
      : props.color === 2
      ? "#F881A9"
      : props.color === 3
      ? "#669DFC"
      : "#555555"};
`;

const ProgressBarWithMenu = ({
  color,
  title = "Loading...",
  titlesAndFields = [
    { title: "Tipo", md: 1, leftPadding: "", rightPadding: "0" },
    { title: "Documento", md: 2, leftPadding: "0", rightPadding: "0" },
    { title: "Mês", md: 1, leftPadding: "0", rightPadding: "" },
    { title: "Crédito", md: 2, leftPadding: "", rightPadding: "" },
    { title: "Atualização", md: 2, leftPadding: "", rightPadding: "" },
    { title: "Anulação", md: 2, leftPadding: "", rightPadding: "" },
    { title: "Diferença", md: 2, leftPadding: "", rightPadding: "" },
  ],
  data = [
    {
      tipo: "Decreto",
      documento: "0001/2022",
      mes: "Janeiro",
      credito: "Suplementação",
      atualizacao: "2852000,00",
      anulacao: "2852000,00",
      diferenca: "365850,00",
    },
  ],
  inputValues,
  setInputValues,
  type = "default",
  setPercentage,
  percentage,
  paddingLeft,
  disabled,
}) => {
  const [opened, setOpened] = useState(false);

  const formatTitle = (title) => {
    return title.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleInputChange = (index, field, value) => {
    const updatedValues = inputValues.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setInputValues(updatedValues);
  };

  const handleOpenClose = () => {
    setOpened(!opened);
  };

  return (
    <>
      <ProgressBarWrapper>
        <ProgressBarContainer
          color={color}
          disabled={disabled}
          paddingLeft={paddingLeft}
        >
          <ProgressBarFiller
            percentage={percentage}
            color={color}
            disabled={disabled}
          />
        </ProgressBarContainer>
        <TitleBox
          color={color}
          disabled={disabled}
          onClick={() => !disabled && handleOpenClose()}
        >
          <Icon
            rotate={!opened ? 3 : 0}
            width={40}
            height={40}
            icon="iconamoon:arrow-down-2-light"
          />
          <span>{title}</span>
        </TitleBox>
        <Percentage color={color} disabled={disabled}>
          {disabled ? 0 : percentage}%
        </Percentage>
      </ProgressBarWrapper>
      <MenuBox opened={opened}>
        {type === "default" && (
          <>
            <Row style={{ padding: "0 10px" }}>
              {titlesAndFields.map((item, index) => (
                <CCol
                  key={index}
                  md={item.md}
                  style={{
                    paddingLeft: index === 0 ? "5px" : item.leftPadding,
                    paddingRight:
                      index === titlesAndFields.length - 1
                        ? "5px"
                        : item.rightPadding,
                  }}
                >
                  <TituloTable>{item.title}</TituloTable>
                </CCol>
              ))}
            </Row>
            {Array.isArray(inputValues) &&
              inputValues.map((data, dataIndex) => (
                <Row style={{ padding: "0 10px" }} key={dataIndex}>
                  {titlesAndFields.map((item, index) => (
                    <CCol
                      key={index}
                      md={item.md}
                      style={{
                        paddingLeft: index === 0 ? "5px" : item.leftPadding,
                        paddingRight:
                          index === titlesAndFields.length - 1
                            ? "5px"
                            : item.rightPadding,
                      }}
                    >
                      <FormControlListaDeInputs
                        style={{
                          backgroundColor: "transparent",
                          borderRadius: "0",
                        }}
                        value={
                          inputValues[dataIndex][
                            formatTitle(item.title.toLowerCase())
                          ]
                        }
                        onChange={(e) =>
                          handleInputChange(
                            dataIndex,
                            item.title.toLowerCase(),
                            e.target.value
                          )
                        }
                      />
                    </CCol>
                  ))}
                </Row>
              ))}
          </>
        )}
        {type === "ldo" && (
          <>
            <LdoMenu setPercentage={setPercentage} />
          </>
        )}
      </MenuBox>
    </>
  );
};

export default ProgressBarWithMenu;
