import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { CCol } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

export const EstruturaTitulos = styled.p`
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 600;
  color: #96989c;
`;

export const EstruturaTitulosDescricao = styled.p`
  color: #515c70;
  font-size: 16px;
`;

const CadastroFuncao = ({ values, setValues, useParamsValor }) => {
  return (
    <div>
      <Row className="row_form mt-1">
        <CCol md={2}>
          <FloatingLabelInput
            maxLength={2}
            type="text"
            disabled={useParamsValor !== undefined}
            value={values.valor}
            placeholder="Código"
            label="Código"
            onChange={(e) => {
              const cleanedInput = e.target.value.replace(/[^0-9]/g, "");
              setValues((prevValues) => ({
                ...prevValues,
                valor: cleanedInput,
              }));
              e.target.value = cleanedInput;
            }}
          />
        </CCol>
        <CCol md={10}>
          <FloatingLabelInput
            type="text"
            placeholder="Título"
            value={values.nome}
            label="Título"
            onChange={(e) => {
              setValues((prevValues) => ({
                ...prevValues,
                nome: e.target.value,
              }));
            }}
          />
        </CCol>
        <div className="pt-3"></div>
        <CCol md={12}>
          <FloatingLabelInput
            type="text"
            placeholder="Descrição"
            value={values.descricao}
            label="Descrição"
            onChange={(e) => {
              setValues((prevValues) => ({
                ...prevValues,
                descricao: e.target.value,
              }));
            }}
          />
        </CCol>
      </Row>
      <Row className="row_endereco_aba mt-1">
        <CCol
          className="d-flex align-items-center"
          style={{ height: "40px" }}
          md={12}
        >
          <span style={{ marginBottom: "-2px" }} className="span_endereco">
            Estrutura
          </span>
        </CCol>
      </Row>

      <Row>
        <Col
          className="d-flex align-items-center p-2 row_endereco_conteudo"
          md={12}
        >
          <span style={{ marginLeft: "20px" }}>Estrutura</span>
        </Col>
      </Row>

      <Row className=" d-flex row_form_2 mt-1" style={{ padding: "10px 20px" }}>
        <Row
          style={{
            borderBottom: "1px solid #dfdfe0",
            margin: "0",
            padding: "0",
          }}
        >
          <CCol md={3}>
            <EstruturaTitulos>Estrutura</EstruturaTitulos>
          </CCol>
          <CCol md={2}>
            <EstruturaTitulos>Código</EstruturaTitulos>
          </CCol>
          <CCol md={7}>
            <EstruturaTitulos>Título</EstruturaTitulos>
          </CCol>
        </Row>
        <Row
          style={{
            margin: "0",
            marginTop: "5px",
            padding: "0",
          }}
        >
          <CCol md={3}>
            <EstruturaTitulosDescricao>
              1º Nível - Função
            </EstruturaTitulosDescricao>
          </CCol>
          <CCol md={2}>
            <EstruturaTitulosDescricao>
              {values.valor || "00"}
            </EstruturaTitulosDescricao>
          </CCol>
          <CCol md={7}>
            <EstruturaTitulosDescricao>
              {values.nome || "Título"}
            </EstruturaTitulosDescricao>
          </CCol>
        </Row>
      </Row>
    </div>
  );
};

export default CadastroFuncao;
