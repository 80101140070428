import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Container } from "react-bootstrap";
import api from "../../../utils/api";
import { CurrentClientContext } from "../../../contexts/CurrentClientContext";
import styled from "styled-components";
import { FormatValueToLocaleString } from "../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { ReturnColorContaPCASPAlternative } from "../../../utils/Gerais/ReturnColorContaPCASP";
import { Search } from "@mui/icons-material";
import { useCurrentYear } from "../../../hooks/useCurrentYear";

const PcaspTable = styled.div`
  padding-left: 5px;
  overflow-x: scroll;
`;

const PcaspRow = styled.div`
  margin-top: -5px;
  display: flex;
  gap: 10px;
  border-bottom: 2px solid #ccc;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};

  & > p {
    flex-basis: 10rem;
    font-family: "Space Mono", monospace;
    font-weight: 500;
    margin: 0;
    flex-grow: 0;
    flex-shrink: 0;
    border-left: 2px solid #ccc;
  }

  & > p.grow {
    flex-grow: 1;
    flex-basis: 20rem;
  }

  & > p.arrowDiv {
    flex-basis: 1rem;
  }
`;

const Titles = styled.div`
  width: 3180px;
  margin-top: 10px;
  margin-left: 5px;
  display: flex;
  gap: 10px;
  border-bottom: 2px solid #ccc;
  border-top: 2px solid #ccc;

  & > h6 {
    flex-basis: 10rem;
    height: 100%;
    font-size: 12px;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    border-left: 2px solid #ccc;
    padding: 5px 0px;
  }

  & > h6.grow {
    flex-grow: 1;
    flex-basis: 20rem;
  }

  & > h6.arrowDiv {
    flex-basis: 1rem;
  }
`;

const TitlesContainer = styled.div`
  overflow-x: hidden;
`;

const CollapsePcaspRow = styled.div`
  max-height: ${({ $opened }) => ($opened ? "auto" : "0px")};
  overflow-y: hidden;
`;

const TableContent = styled.div`
  width: 3180px;
`;

const TableSearchContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 10px;
`;

const InputSearchContainer = styled.div`
  position: relative;

  & > svg {
    position: absolute;
    right: 3px;
    top: 2px;
  }
`;

const TestPcasp = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();
  const contasPai = [1, 2, 3, 4, 5, 6, 7, 8];
  const [openedContaIndex, setOpenedeContaIndex] = useState(-1);

  //refs
  const titlesRef = useRef(null);
  const contentRef = useRef(null);

  //states
  const [contasPcasp, setContasPcasp] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await api.get(
        `pcasp/${currentClient.clienteId}/${currentYear}/todos?limit=10000`
      );
      console.log(response.data);
      const contas = response.data.pcasp;
      console.log(contas);
      setContasPcasp(contas);
    } catch (error) {
      console.log(error);
    }
  }, [currentYear, currentClient.clienteId]);

  const handleOpenConta = (indexConta) => {
    if (openedContaIndex === indexConta) {
      setOpenedeContaIndex(-1);
    } else {
      setOpenedeContaIndex(indexConta);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const titlesDiv = titlesRef.current;
    const tableDiv = contentRef.current;

    const handleScroll = (source, target) => {
      target.scrollLeft = source.scrollLeft;
    };

    const scrollTable = () => handleScroll(tableDiv, titlesDiv);
    const scrollTitles = () => handleScroll(titlesDiv, tableDiv);

    if (titlesDiv && tableDiv) {
      tableDiv.addEventListener("scroll", scrollTable);
      titlesDiv.addEventListener("scroll", scrollTitles);
    }

    return () => {
      titlesDiv.removeEventListener("scroll", scrollTitles);
      tableDiv.removeEventListener("scroll", scrollTable);
    };
  }, []);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <TableSearchContainer>
        <InputSearchContainer>
          <Search /> <input type="text" />
        </InputSearchContainer>
      </TableSearchContainer>

      <TitlesContainer ref={titlesRef}>
        <Titles>
          <h6 className="arrowDiv">.</h6>
          <h6 style={{ flexBasis: "8rem" }}>Cód. Reduzido</h6>
          <h6>Conta</h6>
          <h6>Conta Corrente</h6>
          <h6 className="grow">Título</h6>
          <h6>Saldo Anterior</h6>
          <h6>Débito</h6>
          <h6>Crédito</h6>
          <h6>Saldo Atual</h6>
          <h6 className="grow">Nautureza de Saldo</h6>
          <h6 className="grow">Nível de Detalhamento</h6>
          <h6 className="grow">Indicador de Superavit Financeiro</h6>
          <h6>IDLC</h6>
          <h6 className="grow">Infos Complementares</h6>
          <h6>Status</h6>
        </Titles>
      </TitlesContainer>

      <PcaspTable ref={contentRef}>
        <TableContent>
          {contasPai.map((numero, index) => {
            const itemPai = contasPcasp.filter(
              (item) => parseInt(item.conta[0]) === numero
            )[0];

            const contasFilhas = contasPcasp.filter(
              (item, index) => parseInt(item.conta[0]) === numero && index !== 0
            );

            return (
              <React.Fragment key={index}>
                <PcaspRow
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenConta(index)}
                  $bgColor={ReturnColorContaPCASPAlternative(itemPai?.conta[0])}
                >
                  <p className="arrowDiv">{">"}</p>
                  <p style={{ flexBasis: "8rem" }}>-</p>
                  <p>{itemPai?.conta}</p>
                  <p>-</p>
                  <p className="grow">{itemPai?.titulo}</p>
                  <p>-</p>
                  <p>
                    {FormatValueToLocaleString(itemPai?.debitoDaContaPcasp)}
                  </p>
                  <p>
                    {FormatValueToLocaleString(itemPai?.creditoDaContaPcasp)}
                  </p>
                  <p>{FormatValueToLocaleString(itemPai?.saldoDaContaPcasp)}</p>
                  <p className="grow">{itemPai?.naturezaDeSaldo}</p>
                  <p className="grow">{itemPai?.nivelDetalhado}</p>
                  <p className="grow">
                    {itemPai?.indicadorDoSuperavitFinanceiro}
                  </p>
                  <p>{itemPai?.idlc}</p>
                  <p className="grow">{itemPai?.infoComplementar}</p>
                  <p>{itemPai?.status}</p>
                </PcaspRow>
                <CollapsePcaspRow $opened={openedContaIndex === index}>
                  {contasFilhas.map((contaFilha, indexContaFilha) => (
                    <PcaspRow key={indexContaFilha}>
                      <p className="arrowDiv">{">"}</p>
                      <p style={{ flexBasis: "8rem" }}>-</p>
                      <p>{contaFilha?.conta}</p>
                      <p>-</p>
                      <p className="grow">{contaFilha?.titulo}</p>
                      <p>-</p>
                      <p>
                        {FormatValueToLocaleString(
                          contaFilha?.debitoDaContaPcasp
                        )}
                      </p>
                      <p>
                        {FormatValueToLocaleString(
                          contaFilha?.creditoDaContaPcasp
                        )}
                      </p>
                      <p>
                        {FormatValueToLocaleString(
                          contaFilha?.saldoDaContaPcasp
                        )}
                      </p>
                      <p className="grow">{contaFilha?.naturezaDeSaldo}</p>
                      <p className="grow">{contaFilha?.nivelDetalhado}</p>
                      <p className="grow">
                        {contaFilha?.indicadorDoSuperavitFinanceiro}
                      </p>
                      <p>{contaFilha?.idlc}</p>
                      <p className="grow">{contaFilha?.infoComplementar}</p>
                      <p>{contaFilha?.status}</p>
                    </PcaspRow>
                  ))}
                </CollapsePcaspRow>
              </React.Fragment>
            );
          })}
        </TableContent>
      </PcaspTable>
    </Container>
  );
};

export default TestPcasp;
