import {
  Table,
  TableRow,
  TableCell,
  Paragraph,
  WidthType,
  TextRun,
  TableLayoutType,
  BorderStyle,
} from "docx";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { calcularTotalReceitas } from "./calcularReceitas";

export const DocxTableQDR = ({
  columns,
  data,
  font = "Times New Roman",
  subTitle = "",
  piece = 0,
  columnWidth = [10, 50, 40],
  codigo,
  idsSelected,
  selectBoxesOptionsAbaOptions,
}) => {
  const fonteComDeducao = 3;
  const fonteSemDeducao = 1;
  const { idsNatureza, idsFontesFiltrado, idsEsfera } = idsSelected;

  // console.log(selectBoxesOptionsAbaOptions);

  // const showOptionsSelected = (option) => {
  //   const optionSelected = selectBoxesOptionsAbaOptions[0].checkBoxes.some(
  //     (item) => item.value === option && item.selected
  //   );
  //   return optionSelected;
  // };

  // const showCodigo = showOptionsSelected("codigo");
  // const showTitulo = showOptionsSelected("especificacao");
  // const showValue = showOptionsSelected("valor");

  // console.log(showCodigo);
  // console.log(showTitulo);
  // console.log(showValue);

  // const headerGeral = () => {
  //   return [
  //     ...(showCodigo ?
  //       [new TableCell({
  //         width: {
  //           size: columnWidth[0],
  //           type: WidthType.PERCENTAGE,
  //         },
  //         children: [
  //           new Paragraph({
  //             children: [
  //               new TextRun({
  //                 text: columns[0],
  //                 bold: true,
  //                 color: "FFFFFF",
  //                 size: 10 * 2,
  //                 font: font,
  //               }),
  //             ],
  //             spacing: {
  //               before: 250,
  //               after: 250,
  //             },
  //             indent: {
  //               left: 75,
  //             },
  //           }),
  //         ],
  //         shading: {
  //           fill: "515c70",
  //         },
  //       })]: []
  //     ),
  //     ...(showTitulo ?
  //       [new TableCell({
  //         width: {
  //           size: columnWidth[1],
  //           type: WidthType.PERCENTAGE,
  //         },
  //         children: [
  //           new Paragraph({
  //             children: [
  //               new TextRun({
  //                 text: columns[1],
  //                 bold: true,
  //                 color: "FFFFFF",
  //                 size: 10 * 2,
  //                 font: font,
  //               }),
  //             ],
  //             spacing: {
  //               before: 250,
  //               after: 250,
  //             },
  //             indent: {
  //               left: 75,
  //             },
  //           }),
  //         ],
  //         shading: {
  //           fill: "515c70",
  //         },
  //       })]: []
  //     ),
  //     ...(showValue ?
  //       [new TableCell({
  //         width: {
  //           size: columnWidth[2],
  //           type: WidthType.PERCENTAGE,
  //         },
  //         children: [
  //           new Paragraph({
  //             children: [
  //               new TextRun({
  //                 text: columns[2],
  //                 bold: true,
  //                 color: "FFFFFF",
  //                 size: 10 * 2,
  //                 font: font,
  //               }),
  //             ],
  //             spacing: {
  //               before: 250,
  //               after: 250,
  //             },
  //             indent: {
  //               left: 75,
  //             },
  //             alignment: 'right'
  //           }),
  //         ],
  //         shading: {
  //           fill: "515c70",
  //         },
  //       })]: []
  //     ),

  //   ];
  // };

  const isOptionSelected = (option) =>
    selectBoxesOptionsAbaOptions[0].checkBoxes.some(
      (item) => item.value === option && item.selected
    );

  const options = {
    codigo: isOptionSelected("codigo"),
    titulo: isOptionSelected("especificacao"),
    valor: isOptionSelected("valor"),
  };

  console.log(options.codigo);
  console.log(options.titulo);
  console.log(options.valor);

  console.log(columns);
  console.log(columnWidth);

  const createTableCell = (
    columnIndex,
    alignment = "left",
    showOption = true
  ) =>
    new TableCell({
      width: {
        size: columnWidth[columnIndex],
        type: WidthType.PERCENTAGE,
      },
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: showOption ? columns[columnIndex] : "",
              bold: true,
              color: "FFFFFF",
              size: 10 * 2,
              font: font,
            }),
          ],
          spacing: {
            before: 250,
            after: 250,
          },
          indent: {
            left: 75,
          },
          alignment,
        }),
      ],
      shading: {
        fill: "515c70",
      },
    });

  const headerGeral = () => {
    const cells = [];

    cells.push(createTableCell(0, "left", options.codigo));
    cells.push(createTableCell(1, "left", options.titulo));
    cells.push(createTableCell(2, "right", options.valor));
    // if (options.codigo) cells.push(createTableCell(0));
    // if (options.titulo) cells.push(createTableCell(1));
    // if (options.valor) cells.push(createTableCell(2, "right"));

    return cells;
  };

  const headerGeralComEsfera = () => {
    const cells = [];
    cells.push(createTableCell(0, "left", options.codigo));
    cells.push(createTableCell(1, "left", options.titulo));
    cells.push(createTableCell(2, "left"));
    cells.push(createTableCell(3, "right", options.valor));
    // if (options.codigo) cells.push(createTableCell(0));
    // if (options.titulo) cells.push(createTableCell(1));
    // cells.push(createTableCell(2));
    // if (options.valor) cells.push(createTableCell(3, "right"));

    return cells;
  };

  const headerFiltroPorFonte = () => {
    const cells = [];

    cells.push(createTableCell(0, "left", options.codigo));
    cells.push(createTableCell(1, "left", options.codigo));
    cells.push(createTableCell(2, "left", options.titulo));
    cells.push(createTableCell(3, "right", options.valor));
    // if (options.codigo) cells.push(createTableCell(0));
    // if (options.codigo) cells.push(createTableCell(1));
    // cells.push(createTableCell(2));
    // if (options.valor) cells.push(createTableCell(3, "right"));

    return cells;
  };

  const headerFiltroFonteEsfera = () => {
    const cells = [];

    cells.push(createTableCell(0, "left", options.codigo));
    cells.push(createTableCell(1, "left", options.codigo));
    cells.push(createTableCell(2, "left", options.titulo));
    cells.push(createTableCell(3, "left"));
    cells.push(createTableCell(4, "right", options.valor));
    // if (options.codigo) cells.push(createTableCell(0));
    // if (options.codigo) cells.push(createTableCell(1));
    // if (options.titulo) cells.push(createTableCell(2));
    // cells.push(createTableCell(3));
    // if (options.valor) cells.push(createTableCell(4, "right"));

    return cells;
  };

  const headerFiltroPorEsfera = () => {
    const cells = [];

    cells.push(createTableCell(0));
    cells.push(createTableCell(1, "right"));

    return cells;
  };

  const headerDocx = () => {
    const { idsNatureza, idsFontesFiltrado, idsEsfera } = idsSelected;

    if (idsNatureza.length && idsEsfera.length) {
      return [...headerGeralComEsfera()];
    }

    if (!idsNatureza.length && !idsEsfera.length && idsFontesFiltrado.length) {
      return [...headerFiltroPorFonte()];
    }

    if (!idsNatureza.length && idsEsfera.length && idsFontesFiltrado.length) {
      return [...headerFiltroFonteEsfera()];
    }

    if (!idsNatureza.length && idsEsfera.length && !idsFontesFiltrado.length) {
      return [...headerFiltroPorEsfera()];
    }

    return [...headerGeral()];
  };

  // const headerCells = columns.map((header, index) => {
  //   return new TableCell({
  //     width: {
  //       size: columnWidth[index],
  //       type: WidthType.PERCENTAGE,
  //     },
  //     children: [
  //       new Paragraph({
  //         children: [
  //           new TextRun({
  //             text: header,
  //             bold: true,
  //             color: "FFFFFF",
  //             size: 10 * 2,
  //             font: font,
  //           }),
  //         ],
  //         spacing: {
  //           before: 250,
  //           after: 250,
  //         },
  //         alignment: index === columns.length - 1 ? "right" : "start",
  //         indent: {
  //           left: index === 0 ? 75 : 0,
  //         },
  //       }),
  //     ],
  //     shading: {
  //       fill: "515c70",
  //     },
  //   });
  // });

  const renderSectionDeducao = () => {
    const totalDeducao = FormatValueToLocaleString(
      data.naturezasDeducao[0].valor
    );

    return new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `Contribuição para`,
                  size: 9 * 2,
                  font: font,
                  bold: true,
                }),
              ],
              spacing: {
                before: 50,
                after: 50,
              },
              indent: {
                left: 75,
              },
            }),
          ],
          shading: {
            fill: "a6a6a6",
          },
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `Formação do FUNDEB - DEDUÇÃO`,
                  size: 9 * 2,
                  font: font,
                  bold: true,
                }),
              ],
              spacing: {
                before: 50,
                after: 50,
              },
            }),
          ],
          shading: {
            fill: "a6a6a6",
          },
        }),
        ...(columns.length > 3
          ? [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "",
                        size: 9 * 2,
                        font: font,
                        bold: true,
                      }),
                    ],
                    spacing: {
                      before: 50,
                      after: 50,
                    },
                  }),
                ],
                shading: {
                  fill: "a6a6a6",
                },
              }),
            ]
          : []),

        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: options.valor ? totalDeducao : "",
                  size: 9 * 2,
                  font: font,
                  bold: true,
                }),
              ],
              spacing: {
                before: 50,
                after: 50,
              },
              alignment: "right",
            }),
          ],
          shading: {
            fill: "a6a6a6",
          },
        }),
      ],
    });
  };

  const renderInfosNatureza = (natureza, deducao) => {
    const naturezaSelected =
      idsNatureza.includes(natureza.id) && !idsFontesFiltrado.length;
    console.log(naturezaSelected);

    const sectionReceita = codigo.includes(natureza.nr);

    const codigoFonteRecursoParagraph =
      natureza.qdr !== "vazio" && idsFontesFiltrado.length > 0
        ? natureza.qdr.fontesDeRecursos
            .filter((fonte) => fonte.tipo === deducao)
            .map((fonte) => renderCodigoFonteRecursos(fonte))
        : [];

    const tituloFonteRecursoParagraph =
      natureza.qdr !== "vazio" && idsFontesFiltrado.length > 0
        ? natureza.qdr.fontesDeRecursos
            .filter((fonte) => fonte.tipo === deducao)
            .map((fonte) => renderTituloFonteRecurso(fonte))
        : [];

    const valorFonteRecursoParagraph =
      natureza.qdr !== "vazio" && idsFontesFiltrado.length > 0
        ? natureza.qdr.fontesDeRecursos
            .filter((fonte) => fonte.tipo === deducao)
            .map((fonte) => renderValorFonteRecurso(fonte))
        : [];

    const esferaFonteRecursoColuna =
      natureza.qdr !== "vazio" && idsEsfera.length > 0
        ? natureza.qdr.fontesDeRecursos
            .filter((fonte) => fonte.tipo === deducao)
            .map((fonte) => renderEsferaFonteRecurso(fonte))
        : [];

    return new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: options.codigo ? natureza.nr : "",
                  size: 9 * 2,
                  font: font,
                  bold: naturezaSelected || sectionReceita,
                }),
              ],
              spacing: {
                before: 50,
                after: 50,
              },
              indent: {
                left: 75,
              },
            }),
            ...(options.codigo ? [...codigoFonteRecursoParagraph] : [])
            ,
          ],
          shading: {
            fill: sectionReceita ? "a6a6a6" : "ffffff",
          },
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: options.titulo ? natureza.especificacao : "",
                  size: 9 * 2,
                  font: font,
                  bold: naturezaSelected || sectionReceita,
                }),
              ],
              spacing: {
                before: 50,
                after: 50,
              },
            }),
            ...(options.titulo ? [...tituloFonteRecursoParagraph] : []),
          ],
          shading: {
            fill: sectionReceita ? "a6a6a6" : "ffffff",
          },
        }),
        ...(idsNatureza.length > 0 &&
        idsFontesFiltrado > 0 &&
        idsEsfera.length > 0
          ? [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "",
                        size: 9 * 2,
                        font: font,
                        bold: naturezaSelected || sectionReceita,
                      }),
                    ],
                    spacing: {
                      before: 50,
                      after: 50,
                    },
                  }),
                  ...esferaFonteRecursoColuna,
                ],
                shading: {
                  fill: sectionReceita ? "a6a6a6" : "ffffff",
                },
              }),
            ]
          : idsNatureza.length > 0 && idsEsfera.length > 0
          ? [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: naturezaSelected
                          ? `${idsEsfera[0]} ${idsEsfera[1]}`
                          : "",
                        size: 9 * 2,
                        font: font,
                        bold: naturezaSelected || sectionReceita,
                      }),
                    ],
                    spacing: {
                      before: 50,
                      after: 50,
                    },
                  }),
                ],
                shading: {
                  fill: sectionReceita ? "a6a6a6" : "ffffff",
                },
              }),
            ]
          : []),

        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: options.valor
                    ? FormatValueToLocaleString(natureza.valor)
                    : "",
                  size: 9 * 2,
                  font: font,
                  bold: naturezaSelected || sectionReceita,
                }),
              ],
              spacing: {
                before: 50,
                after: 50,
              },
              alignment: "right",
            }),
            ...(options.valor ?[...valorFonteRecursoParagraph] : []),
          ],
          shading: {
            fill: sectionReceita ? "a6a6a6" : "ffffff",
          },
        }),
      ],
    });
  };

  const renderCodigoFonteRecursos = (fonte) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: `${fonte.ficha}    ${fonte.conta}`,
          size: 9 * 2,
          font: font,
          bold: true,
        }),
      ],
      spacing: {
        before: 50,
        after: 50,
      },
      indent: {
        left: 400,
      },
    });
  };

  const renderTituloFonteRecurso = (fonte) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: fonte.titulo,
          size: 9 * 2,
          font: font,
          bold: true,
        }),
      ],
      spacing: {
        before: 50,
        after: 50,
      },
    });
  };

  const renderValorFonteRecurso = (fonte) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: FormatValueToLocaleString(fonte.valorPrevisto) || "",
          size: 9 * 2,
          font: font,
          bold: true,
        }),
      ],
      spacing: {
        before: 50,
        after: 50,
      },
      alignment: "right",
    });
  };

  const renderEsferaFonteRecurso = (fonte) => {
    console.log(fonte.esfera);

    return new Paragraph({
      children: [
        new TextRun({
          text: fonte.esfera || "",
          size: 9 * 2,
          font: font,
          bold: true,
        }),
      ],
      spacing: {
        before: 50,
        after: 50,
      },
    });
  };

  const renderInfosFiltroPorFonteRecursos = (fonte) => {
    const filtroPorFiscal = idsEsfera.includes("Fiscal");
    const filtroPorSeguridade = idsEsfera.includes("Seguridade");

    return new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: options.codigo ? `${fonte.conta[0]}` : "",
                  size: 9 * 2,
                  font: font,
                }),
              ],
              spacing: {
                before: 50,
                after: 50,
              },
              indent: {
                left: 75,
              },
            }),
          ],
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: options.codigo ? `${fonte.conta.split(".")[1]}` : "",
                  size: 9 * 2,
                  font: font,
                }),
              ],
              spacing: {
                before: 50,
                after: 50,
              },
            }),
          ],
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: options.titulo ? fonte.titulo : "",
                  size: 9 * 2,
                  font: font,
                }),
              ],
              spacing: {
                before: 50,
                after: 50,
              },
            }),
          ],
        }),
        ...(idsEsfera.length > 0
          ? [
              new TableCell({
                children: [
                  ...(filtroPorFiscal
                    ? [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Fiscal",
                              size: 9 * 2,
                              font: font,
                            }),
                          ],
                          spacing: {
                            before: 50,
                            after: 50,
                          },
                        }),
                      ]
                    : []),
                  ...(filtroPorSeguridade
                    ? [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Seguridade",
                              size: 9 * 2,
                              font: font,
                            }),
                          ],
                          spacing: {
                            before: 50,
                            after: 50,
                          },
                        }),
                      ]
                    : []),
                ],
              }),
            ]
          : []),
        ...(idsEsfera.length > 0
          ? [
              new TableCell({
                children: [
                  ...(filtroPorFiscal
                    ? [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: options.valor
                                ? FormatValueToLocaleString(fonte.totalFiscal)
                                : "",
                              size: 9 * 2,
                              font: font,
                            }),
                          ],
                          spacing: {
                            before: 50,
                            after: 50,
                          },
                          alignment: "right",
                        }),
                      ]
                    : []),
                  ...(filtroPorSeguridade
                    ? [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: options.valor
                                ? FormatValueToLocaleString(
                                    fonte.totalSeguridade
                                  )
                                : "",
                              size: 9 * 2,
                              font: font,
                            }),
                          ],
                          spacing: {
                            before: 50,
                            after: 50,
                          },
                          alignment: "right",
                        }),
                      ]
                    : []),
                ],
              }),
            ]
          : []),

        ...(!filtroPorFiscal && !filtroPorSeguridade
          ? [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: options.valor
                          ? FormatValueToLocaleString(
                              fonte.totalFiscal + fonte.totalSeguridade
                            )
                          : "",
                        size: 9 * 2,
                        font: font,
                      }),
                    ],
                    spacing: {
                      before: 50,
                      after: 50,
                    },
                    alignment: "right",
                  }),
                ],
              }),
            ]
          : []),
      ],
    });
  };

  const renderInfosFiltroPorEsfera = (esfera) => {
    const filtroPorFiscal = idsEsfera.includes("Fiscal");
    const filtroPorSeguridade = idsEsfera.includes("Seguridade");

    return new TableRow({
      children: [
        new TableCell({
          children: [
            ...(filtroPorFiscal
              ? [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Fiscal",
                        size: 9 * 2,
                        font: font,
                      }),
                    ],
                    spacing: {
                      before: 50,
                      after: 50,
                    },
                    indent: {
                      left: 75,
                    },
                  }),
                ]
              : []),
            ...(filtroPorSeguridade
              ? [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Seguridade",
                        size: 9 * 2,
                        font: font,
                      }),
                    ],
                    spacing: {
                      before: 50,
                      after: 50,
                    },
                    indent: {
                      left: 75,
                    },
                  }),
                ]
              : []),
          ],
        }),
        new TableCell({
          children: [
            ...(filtroPorFiscal
              ? [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: FormatValueToLocaleString(
                          parseFloat(esfera.totalGeralFiscal)
                        ),
                        size: 9 * 2,
                        font: font,
                      }),
                    ],
                    spacing: {
                      before: 50,
                      after: 50,
                    },
                    alignment: "right",
                  }),
                ]
              : []),
            ...(filtroPorSeguridade
              ? [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: FormatValueToLocaleString(
                          parseFloat(esfera.totalGeralSeguridade)
                        ),
                        size: 9 * 2,
                        font: font,
                      }),
                    ],
                    spacing: {
                      before: 50,
                      after: 50,
                    },
                    alignment: "right",
                  }),
                ]
              : []),
          ],
        }),
      ],
    });
  };

  const renderTotalGeral = (total) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: options.valor
            ? `TOTAL R$ ${FormatValueToLocaleString(total)}`
            : "",
          size: 9 * 2,
          font: font,
          bold: true,
        }),
      ],
      spacing: {
        before: 50,
      },
      alignment: "right",
      indent: {
        right: 75,
      },
    });
  };

  const createEmptyRow = (color) =>
    new TableRow({
      children: columns.map(
        () =>
          new TableCell({
            children: [
              new Paragraph({
                children: [new TextRun({ text: "", font: font })],
              }),
            ],
            shading: {
              fill: color,
            },
          })
      ),
    });

  const dataTableRows = () => {
    if (idsNatureza.length) {
      const dataRowsNaturezaSemDeducao = data.naturezas.length
        ? data.naturezas.map((natureza) =>
            renderInfosNatureza(natureza, fonteSemDeducao)
          )
        : [];
      const dataRowsNaturezaComDeducao = data.naturezasDeducao.length
        ? data.naturezasDeducao.map((natureza) =>
            renderInfosNatureza(natureza, fonteComDeducao)
          )
        : [];
      const tableRows = [
        new TableRow({
          children: headerDocx(),
          tableHeader: true,
        }),
        ...dataRowsNaturezaSemDeducao,
        ...(data.naturezasDeducao.length ? [renderSectionDeducao()] : []),
        ...dataRowsNaturezaComDeducao,
        createEmptyRow("515c70"),
      ];
      return tableRows;
    }
    if (idsFontesFiltrado.length) {
      const fontes = data.fontes.length
        ? data.fontes.map((fonte) => renderInfosFiltroPorFonteRecursos(fonte))
        : [];

      console.log(fontes);

      const tableRows = [
        new TableRow({
          children: headerDocx(),
          tableHeader: true,
        }),
        ...fontes,
        createEmptyRow("515c70"),
      ];
      return tableRows;
    }

    if (idsEsfera.length) {
      const tableRows = [
        new TableRow({
          children: headerDocx(),
          tableHeader: true,
        }),
        renderInfosFiltroPorEsfera(data.totais),
        createEmptyRow("515c70"),
      ];
      return tableRows;
    }
  };

  const totalGeral = () => {
    if (idsNatureza.length) {
      return renderTotalGeral(parseFloat(calcularTotalReceitas(data, codigo)));
    }

    if (idsFontesFiltrado.length) {
      return renderTotalGeral(parseFloat(data.totais.totalGeral));
    }

    if (idsEsfera.length) {
      return renderTotalGeral(parseFloat(data.totais.totalGeral));
    }
  };

  const tableRows = dataTableRows();
  const totalParagraph = totalGeral();

  // return new Table({
  //   width: {
  //     size: 100,
  //     type: WidthType.PERCENTAGE,
  //   },
  //   layout: TableLayoutType.FIXED,
  //   rows: tableRows,
  //   borders: {
  //     top: { style: BorderStyle.NONE, size: 0, space: 0 },
  //     bottom: { style: BorderStyle.NONE, size: 0, space: 0 },
  //     left: { style: BorderStyle.NONE, size: 0, space: 0 },
  //     right: { style: BorderStyle.NONE, size: 0, space: 0 },
  //     insideHorizontal: { style: BorderStyle.NONE, size: 0, space: 0 },
  //     insideVertical: { style: BorderStyle.NONE, size: 0, space: 0 },
  //   },
  // });

  return [
    new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      layout: TableLayoutType.FIXED,
      rows: tableRows,
      borders: {
        top: { style: BorderStyle.NONE, size: 0, space: 0 },
        bottom: { style: BorderStyle.NONE, size: 0, space: 0 },
        left: { style: BorderStyle.NONE, size: 0, space: 0 },
        right: { style: BorderStyle.NONE, size: 0, space: 0 },
        insideHorizontal: { style: BorderStyle.NONE, size: 0, space: 0 },
        insideVertical: { style: BorderStyle.NONE, size: 0, space: 0 },
      },
    }),
    totalParagraph,
  ];
};
