import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getDespesaVinculado = async (
  clientes,
  entidadesGestoras,
  ano = 2024
) => {
  const url = getUrl(clientes, entidadesGestoras);
  try {
    const response = await api.get(
      `qdd/relatorio/agregado/${url.idsUrl}/${ano}?porAcao=1&vinculoRecurso=1&${url.filterConsolidado}&anexo=1`
    );

    return formattedAllDespesas(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Anexo VIII - Demonstrativo da Despesa por Funções, Subfunções, Programas, Ações Conforme o Vínculo com os Recursos",
    };
  }
};

const converterObjectToArray = (unidades) => {
  console.log(unidades);

  const unidadesArray = Object.values(unidades).map((funcao) => ({
    ...funcao,
    subFuncoes: Object.values(funcao.subFuncoes).map((subFuncao) => ({
      ...subFuncao,
      programas: Object.values(subFuncao.programas).map((programa) => ({
        ...programa,
        acoes: Object.values(programa.acoes),
      })),
    })),
  }));
  console.log(unidadesArray);
  return ordenarRelatorio(unidadesArray);
};

const ordenarRelatorio = (unidades) => {
  console.log(unidades);

  const unidadesOrdenadas = unidades
    .sort((a, b) => a.codigoFuncao.localeCompare(b.codigoFuncao))
    .map((unidade) => ({
      ...unidade,
      subFuncoes: unidade.subFuncoes
        .sort((a, b) => a.codigoSubFuncao.localeCompare(b.codigoSubFuncao))
        .map((subFuncao) => ({
          ...subFuncao,
          programas: subFuncao.programas
            .sort((a, b) => a.codigoPrograma.localeCompare(b.codigoPrograma))
            .map((programa) => ({
              ...programa,
              acoes: programa.acoes.sort((a, b) =>
                a.nAcao.localeCompare(b.nAcao)
              ),
            })),
        })),
    }));

  console.log(unidadesOrdenadas);
  return unidadesOrdenadas;
};

const totalGeral = (unidades) => {
  const total = unidades.reduce((acc, unidade) => {
    const totalUnidade = unidade.infoUnidade.totalGeralUnidade.total;
    return (acc += totalUnidade);
  }, 0);
  console.log(total);

  return total;
};

const formattedAllDespesas = (dataQddRelatorioOrgaoUnidade) => {
  console.log(dataQddRelatorioOrgaoUnidade);

  const dadosFormatados = Object.keys(dataQddRelatorioOrgaoUnidade).map(
    (key) => {
      const unidade = dataQddRelatorioOrgaoUnidade[key];
      return {
        infoUnidade: {
          idUnidade: unidade.clienteInfo[0].id,
          tipoNaturezaJuridica: unidade.clienteInfo[0].tipoNaturezaJuridica,
          unidadeGestora: unidade.clienteInfo[0].pessoaJuridica.nomeFantasia,
          totalGeralUnidade: {
            total: unidade.totalVinculoRecursosCliente,
            totalVinculo: unidade.totalVinculoCliente,
            totalOrdinario: unidade.totalOrdinarioCliente,
          },
          unidades: converterObjectToArray(unidade.relatorio),
        },
      };
    }
  );
  console.log(dadosFormatados);

  // dadosFormatados.sort((a, b) => {
  //   const tipoA = parseInt(a.infoUnidade.tipoNaturezaJuridica[0]);
  //   const tipoB = parseInt(b.infoUnidade.tipoNaturezaJuridica[0]);
  //   return tipoA - tipoB;
  // });
  const total = totalGeral(dadosFormatados);

  return {
    dataDespesa: dadosFormatados,
    total,
  };
};
