import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Container } from "react-bootstrap";
import VizualizacaoRelatorioDiretriz from "../../Diretriz/Relatorio/VisualizacaoRelatorioDiretriz";
import GlobalStyles from "../../../../../styles/StyledComponents/GlobalStyles";
import { DivPrintOnly } from "../../../../../styles/StyledComponents/StylesRelatorios";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { CurrentClientContext } from "../../../../../contexts/CurrentClientContext";
import HeaderCadastros from "../../../../../components/HeaderCadastros/HeaderCadastros";
import PagRelatorioComponente from "../../../../../components/ComponenteDeRelatorio/PagRelatorioComponente";
import { PdfCpfCnpj } from "./PdfCpfCnpj";
import { useGenerateRelatorio } from "../../../../../hooks/useGenerateRelatorio";
import { isBase64 } from "../../../../../utils/Gerais/isBase64";

const RelatorioCpfCnpj = () => {
  const navigate = useNavigate();
  const relatorioName = "Relatório CPF / CNPJ";
  const columnWidth = [10, 40, 25, 25];
  const { currentClient } = useContext(CurrentClientContext);
  const [selectBoxesOptionsAbaPrincipal, setSelectBoxesOptionsAbaPrincipal] =
    useState([]);
  const [selectBoxesOptionsAbaOptions, setSelectBoxesOptionsAbaOptions] =
    useState([
      {
        opcao: "Exibir", //"Diretriz",
        checkBoxes: [
          { label: "Código", value: "pessoaId", selected: true },
          { label: "Nome", value: "nomeCompleto", selected: true },
          { label: "CPF/CNPJ", value: "cpf", selected: true },
          { label: "Endereço", value: "endereco", selected: true },
        ],
      },
    ]);
  const { data: dataCpf } = useFetchData(
    `/cpf/por-cliente/${currentClient.clienteId}`
  );

  const {
    dadosRelatorio,
    dataClientInfos,
    handleGenerateDocx,
    handleGeneratePdf,
    handleGenerateXls,
    handlePrint,
    pdfData,
  } = useGenerateRelatorio(
    selectBoxesOptionsAbaOptions,
    selectBoxesOptionsAbaPrincipal,
    currentClient,
    relatorioName,
    columnWidth
  );

  const getDadosRelatorio = () => {
    const selectBox = selectBoxesOptionsAbaPrincipal?.find(
      (item) => item.opcao === "CPF / CNPJ"
    );

    const selectBoxSelected = selectBox?.checkBoxes.some(
      (item) => item.selected
    );

    if (!selectBoxSelected) return [];

    const itensSelecionados = selectBox.checkBoxes
      .filter((item) => item.selected)
      .map((checkBox) => checkBox.value);

    const dadosSelecionados = dataCpf?.filter((item) =>
      itensSelecionados.includes(item.pessoaId)
    );

    const formatarEndereco = (endereco) => {
      const { logradouro, municipio, estado } = endereco;
      return `${municipio} - ${estado} - ${logradouro}`;
    };

    const dadosSelecionadosFinais = dadosSelecionados.map((pessoa) => ({
      ...pessoa,
      endereco: formatarEndereco(pessoa.Endereco),
    }));

    return dadosSelecionadosFinais;
  };

  const handleGenerateDataXls = () => {
    const dadosRelatorio = getDadosRelatorio();

    const dataTable = dadosRelatorio
      ?.map((item) => {
        const selectedFields = {};
        selectBoxesOptionsAbaOptions[0]?.checkBoxes?.forEach(
          ({ label, value, selected }) => {
            const actualItem = item[value];
            if (selected && !isBase64(actualItem)[0]) {
              selectedFields[label] = actualItem;
            }
          }
        );

        return Object.keys(selectedFields).length > 0 ? selectedFields : null;
      })
      .filter((item) => item);
    console.log(dataTable);
    if (dataTable.length !== 0) {
      handleGenerateXls(dataTable);
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  useEffect(() => {
    if (
      dataCpf &&
      !selectBoxesOptionsAbaPrincipal.some(
        (item) => item.opcao === "CPF / CNPJ"
      )
    ) {
      console.log(dataCpf);

      setSelectBoxesOptionsAbaPrincipal((prev) => [
        ...prev,
        {
          opcao: "CPF / CNPJ",
          checkBoxes: dataCpf.map((item) => ({
            label: item.nomeCompleto,
            value: item.pessoaId,
            selected: false,
          })),
        },
      ]);
    }
    // eslint-disable-next-line
  }, [dataCpf]);

  return (
    <>
      <GlobalStyles orientation={"landscape"} />
      <DivPrintOnly>
        <VizualizacaoRelatorioDiretriz
          dadosRelatorio={dadosRelatorio}
          dataClientInfos={dataClientInfos}
          numeroDaPagina={1}
          totalDePaginas={2}
        />
      </DivPrintOnly>

      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina hide_on_print"
      >
        <HeaderCadastros
          PaginaConsulta={"/planejamento/cadastro/cadastros-cpf-cnpj/consulta"}
          NomePaginaTitulo={"CPF / CNPJ"}
          PaginaSubtitulo={"Relatório"}
          BotaoNovoAtivo={true}
          BotaoSaveAtivo={false}
          BotaoNovoFunction={() =>
            navigate("/planejamento/cadastro/cadastros-cpf-cnpj")
          }
          OpcoesDeArquivo={true}
          ButtonVisualizar={() => handlePrint(getDadosRelatorio)}
          ButtonPDF={(action) =>
            handleGeneratePdf(PdfCpfCnpj, getDadosRelatorio, action)
          }
          ButtonDocx={() => handleGenerateDocx(getDadosRelatorio)}
          PdfData={pdfData}
          ButtonXls={handleGenerateDataXls}
        />
        <ToastContainer />

        <PagRelatorioComponente
          topoEsquerdoOptions={[
            { label: "Relatório", value: null },
            { label: "Relatório", value: 1 },
          ]}
          principalOptions={[
            {
              label: "CPF / CNPJ",
              value: "CPF / CNPJ",
            },
            // {
            //   label: "Teste",
            //   value: "Teste",
            // },
          ]}
          labelsCheckAbaPrincipal={selectBoxesOptionsAbaPrincipal}
          setLabelsCheckAbaPrincipal={setSelectBoxesOptionsAbaPrincipal}
          labelsCheckAbaOpcoes={selectBoxesOptionsAbaOptions}
          setLabelsCheckAbaOpcoes={setSelectBoxesOptionsAbaOptions}
          hasAssinatura={false}
          hasFavorite={false}
        />
      </Container>
    </>
  );
};

export default RelatorioCpfCnpj;
