import styled from "styled-components";
import { RowAninhada, RowForm } from "../Grid/CCol";

export const RowStyled = styled(RowAninhada)`
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 5px 0px;

  p {
    margin: 3px;
    color: #888a8e;
    font-weight: 500;
    font-size: 14px;
  }

  p > span {
    color: #515c70;
  }
`;

export const RowInfosAcao = ({ children, ...props }) => {
  return (
    <RowForm
      {...props}
      style={{
        marginTop: "-14px",
        ...props.style,
        paddingRight: 17,
        paddingLeft: 17,
      }}
    >
      <RowStyled style={props.contentStyle}>{children}</RowStyled>
    </RowForm>
  );
};
