export const especificacoes = [
  {
    label: "Demandas Judiciais - PASSIVOS CONTINGENTES",
    value: "Demandas Judiciais - PASSIVOS CONTINGENTES",
  },
  {
    label: "Dívidas em Processo de Reconhecimento - PASSIVOS CONTINGENTES",
    value: "Dívidas em Processo de Reconhecimento - PASSIVOS CONTINGENTES",
  },
  {
    label: "Avais e Garantias Concedidas - PASSIVOS CONTINGENTES",
    value: "Avais e Garantias Concedidas - PASSIVOS CONTINGENTES",
  },
  {
    label: "Assunção de Passivos - PASSIVOS CONTINGENTES",
    value: "Assunção de Passivos - PASSIVOS CONTINGENTES",
  },
  {
    label: "Assistências Diversas - PASSIVOS CONTINGENTES",
    value: "Assistências Diversas - PASSIVOS CONTINGENTES",
  },
  {
    label: "Outros Passivos Contingentes - PASSIVOS CONTINGENTES",
    value: "Outros Passivos Contingentes - PASSIVOS CONTINGENTES",
  },
  {
    label: "Frustração de Arrecadação - DEMAIS RISCOS FISCAIS PASSIVOS",
    value: "Frustração de Arrecadação - DEMAIS RISCOS FISCAIS PASSIVOS",
  },
  {
    label: "Restituição de Tributos a Maior - DEMAIS RISCOS FISCAIS PASSIVOS",
    value: "Restituição de Tributos a Maior - DEMAIS RISCOS FISCAIS PASSIVOS",
  },
  {
    label: "Discrepância de Projeções - DEMAIS RISCOS FISCAIS PASSIVOS",
    value: "Discrepância de Projeções - DEMAIS RISCOS FISCAIS PASSIVOS",
  },
  {
    label: "Outros Riscos Fiscais - DEMAIS RISCOS FISCAIS PASSIVOS",
    value: "Outros Riscos Fiscais - DEMAIS RISCOS FISCAIS PASSIVOS",
  },
];

const data = {
  demandasJudiciais: [
    {
      label:
        "Indexação e Controles de Preços - Demandas Judiciais Contra a Atividade Reguladora do Estado",
      value:
        "Indexação e Controles de Preços - Demandas Judiciais Contra a Atividade Reguladora do Estado",
    },
    {
      label:
        "De Ordem Tributária e Previdenciária- Demandas Judiciais Contra a Atividade Reguladora do Estado",
      value:
        "De Ordem Tributária e Previdenciária- Demandas Judiciais Contra a Atividade Reguladora do Estado",
    },
    {
      label:
        "Outras Demandas Judiciais- Demandas Judiciais Contra a Atividade Reguladora do Estado",
      value:
        "Outras Demandas Judiciais- Demandas Judiciais Contra a Atividade Reguladora do Estado",
    },
    {
      label: "Demandas Judiciais Contra Empresas Estatais Dependentes",
      value: "Demandas Judiciais Contra Empresas Estatais Dependentes",
    },
    {
      label:
        "Privatizações, Liquidação ou Extinção de Órgãos ou Empresas - Demandas Judiciais Contra a Administração Direta e Indireta",
      value:
        "Privatizações, Liquidação ou Extinção de Órgãos ou Empresas - Demandas Judiciais Contra a Administração Direta e Indireta",
    },
    {
      label:
        "Reajustes Salariais- Demandas Judiciais Contra a Administração Direta e Indireta",
      value:
        "Reajustes Salariais- Demandas Judiciais Contra a Administração Direta e Indireta",
    },
    {
      label: "Demandas Trabalhistas Contra o Ente Federativo",
      value: "Demandas Trabalhistas Contra o Ente Federativo",
    },
    {
      label: "Outras Demandas Judiciais",
      value: "Outras Demandas Judiciais",
    },
  ],
  dividasProcessoRec: [],
  avaisGarantiasConc: [],
  assuncaoPassivos: [
    {
      label: "De Órgãos da Administração Direta ou Indireta",
      value: "De Órgãos da Administração Direta ou Indireta",
    },
    {
      label: "De Entidades Privadas",
      value: "De Entidades Privadas",
    },
    {
      label: "De Regime Próprio de Previdência Social dos Servidores Públicos",
      value: "De Regime Próprio de Previdência Social dos Servidores Públicos",
    },
    {
      label: "De Entes da Federação",
      value: "De Entes da Federação",
    },
    {
      label: "De Regime Geral de Previdência Social",
      value: "De Regime Geral de Previdência Social",
    },
    {
      label: "De Banco Central",
      value: "De Banco Central",
    },
    {
      label: "De Assunção de Outros Passivos",
      value: "De Assunção de Outros Passivos",
    },
  ],
  assitenciaDiversas: [],
  outrosPassivosCont: [],
  frustracaodDeArrec: [],
  restituicaoTribuMaior: [],
  discrepanciaDeProj: [
    {
      label: "Taxa de Crescimento Econômico",
      value: "Taxa de Crescimento Econômico",
    },
    {
      label: "Taxa de Inflação",
      value: "Taxa de Inflação",
    },
    {
      label: "Taxa de Câmbio",
      value: "Taxa de Câmbio",
    },
    {
      label: "Taxa de Juros",
      value: "Taxa de Juros",
    },
    {
      label: "Salário Mínimo",
      value: "Salário Mínimo",
    },
    {
      label: "Outros Indicadores",
      value: "Outros Indicadores",
    },
  ],
  outrosRiscosFiscais: [],
};

// prettier-ignore
export const descricao = {
  "Demandas Judiciais - PASSIVOS CONTINGENTES": data.demandasJudiciais,
  "Dívidas em Processo de Reconhecimento - PASSIVOS CONTINGENTES": data.dividasProcessoRec,
  "Avais e Garantias Concedidas - PASSIVOS CONTINGENTES": data.avaisGarantiasConc,
  "Assunção de Passivos - PASSIVOS CONTINGENTES": data.assuncaoPassivos,
  "Assistências Diversas - PASSIVOS CONTINGENTES": data.assitenciaDiversas,
  "Outros Passivos Contingentes - PASSIVOS CONTINGENTES": data.outrosPassivosCont,
  "Frustração de Arrecadação - DEMAIS RISCOS FISCAIS PASSIVOS": data.frustracaodDeArrec,
  "Restituição de Tributos a Maior - DEMAIS RISCOS FISCAIS PASSIVOS": data.restituicaoTribuMaior,
  "Discrepância de Projeções - DEMAIS RISCOS FISCAIS PASSIVOS": data.discrepanciaDeProj,
  "Outros Riscos Fiscais - DEMAIS RISCOS FISCAIS PASSIVOS": data.outrosRiscosFiscais,
};
