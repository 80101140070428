import { FormatValueToLocaleString } from "../../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import {
  calcularTotalReceitas,
  calcularTotalReceitasComDeducao,
} from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getReceitasQdr = async (
  clientes,
  entidadesGestoras,
  ano = 2024
) => {
  const url = getUrl(clientes, entidadesGestoras);

  try {
    const response = await api.get(
      `qdr/relatorio/${url.idsUrl}/${ano}?${url.filterConsolidado}`
    );
    console.log("response.data: ", response.data);
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Quadro Detalhado da Receita - QDR",
    };
  }
};

const orderFonts = (naturezas) => {
  return naturezas.map((natureza) => {
    const { qdr } = natureza;

    if (qdr && qdr !== "vazio" && qdr.fontesDeRecursos) {
      qdr.fontesDeRecursos.sort((a, b) => a.conta.localeCompare(b.conta));
    }

    return natureza;
  });
};

export const processAllData = (dataQdrRelatorio) => {
  const codigosSectionRelatorio = ["1.0.0.0.00.0.0", "2.0.0.0.00.0.0"];
  const fonteComDeducao = 3;
  const fonteSemDeducao = 1;

  const processItem = (item, deducao) => {
    const resultado = [
      {
        codigo: item.nr,
        especificacao: item.especificacao,
        valor: item.valor,
      },
    ];

    if (
      item.qdr &&
      item.qdr.fontesDeRecursos &&
      item.qdr.fontesDeRecursos.length > 0
    ) {
      const recursos = item.qdr.fontesDeRecursos
        .map((recurso) => {
          if (recurso.tipo === deducao) {
            return {
              codigo: `${recurso.ficha}    ${recurso.conta}`,
              especificacao: recurso.titulo,
              valor: recurso.valorPrevisto,
            };
          }
          return null;
        })
        .filter((recurso) => recurso !== null);
      resultado.push(...recursos);
    }

    return resultado;
  };

  const naturezas = orderFonts(dataQdrRelatorio.naturezas)
    .map((natureza) =>
      processItem(natureza, fonteSemDeducao, dataQdrRelatorio.naturezas)
    )
    .flat();

  const naturezasDeducao = orderFonts(dataQdrRelatorio.naturezasDeducao)
    .map((natureza) => processItem(natureza, fonteComDeducao))
    .flat();
  const dadosFiltrados = { naturezas, naturezasDeducao };

  const totalReceitas = calcularTotalReceitas(
    dataQdrRelatorio,
    codigosSectionRelatorio
  );

  const totalReceitasDeducao = calcularTotalReceitasComDeducao(
    dataQdrRelatorio,
    codigosSectionRelatorio
  );

  return {
    data: dadosFiltrados,
    sum: FormatValueToLocaleString(totalReceitas),
    totalReceitasDeducao,
  };
};
