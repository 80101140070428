import { useContext } from "react";
import * as RS from "./stylesVisualizacaoRelatorio";
import { CurrentYearContext } from "../../../contexts/YearContext";

export const HeaderRelatorio = ({
  currentClient,
  relatorioName,
  dataClientInfos = null,
}) => {
  const { currentYear } = useContext(CurrentYearContext);

  return (
    <RS.DivExternaCabecalhoRelatorio>
      <RS.DivInternaCabecalho>
        <RS.DivImageCabecalho>
          <img
            alt="brasão do municipio"
            src={dataClientInfos ? dataClientInfos[0]?.brasao : ""}
          />
        </RS.DivImageCabecalho>

        <RS.DivInfosCabecalho>
          <div>
            <RS.TitleCabecalho
              style={{ textTransform: "uppercase", fontSize: '11pt' }}
            >{`MUNICÍPIO DE ${currentClient.endereco.municipio} - ${currentClient.endereco.estado}`}</RS.TitleCabecalho>
            <p>Relatório de verificação</p>
            <h5>{relatorioName}</h5>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p>Anual {currentYear}</p>
          </div>
        </RS.DivInfosCabecalho>
      </RS.DivInternaCabecalho>
      {/* <RS.DivInternaCabecalho
        style={{
          display: "flex",
          justifyContent: "end"
        }}
      >
        <p>Anual {anoRelatorio}</p>
      </RS.DivInternaCabecalho> */}
    </RS.DivExternaCabecalhoRelatorio>
  );
};
