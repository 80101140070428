export const ReturnColorSistemasLista = (sistema) => {
  switch (sistema.toLowerCase()) {
    case "gerenciador":
      return "#515C70";
    case "planejamento":
      return "#f6991e";
    case "contabilidade":
      return "#da499a";
    case "pagamentos":
      return "#89c454";
    case "gestor":
      return "#2f817b";
    case "folha":
      return "#586dba";
    default:
      return null;
  }
};
