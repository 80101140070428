import React from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowForm,
} from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

export const FonteDeRecursosAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Fonte de Recursos / CO</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1">
        <CCol>
          <PTitulosInputs>Fonte de Recursos + CO</PTitulosInputs>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
          />
        </CCol>
        <CCol md={1}>
          <PTitulosInputs>%</PTitulosInputs>
          <FormControlListaDeInputs />
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Valor</PTitulosInputs>
          <InputFormatRealBrasileiro listaDeInputs />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar />
        </CColButtonsAcoes2Icons>
      </RowForm>

      <RowForm $borderRadiusType={2} style={{ marginTop: "-14px" }}>
        <CCol
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <p style={{ margin: 0, color: "#515c70", fontWeight: 600 }}>
            Valor Total
          </p>
        </CCol>
        <CCol md={1}>
          <FloatingLabelInput
            disabled
            value="100%"
            style={{ border: "none", borderRadius: 10, textAlign: "center" }}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: 10, textAlign: "end" }}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
      </RowForm>
    </>
  );
};
