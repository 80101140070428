import React from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowForm,
} from "../../../../components/Grid/CCol";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";

const ProdutoEAquisicaoAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Produto e Aquisição</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1" $borderRadiusType={2}>
        <CCol md={6}>
          <PTitulosInputs>Produto e Aquisição</PTitulosInputs>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.value} - ${item.label}`}
          />
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Unidade de Medida</PTitulosInputs>
          <FloatingLabelInput
            height="30px"
            tipo="input_select"
            new
            options={[{ label: "teste", value: "1" }]}
          />
        </CCol>
        <CCol md={1}>
          <PTitulosInputs>Quantidade</PTitulosInputs>
          <FormControlListaDeInputs />
        </CCol>
        <CCol>
          <PTitulosInputs>Valor</PTitulosInputs>
          <InputFormatRealBrasileiro listaDeInputs />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar />
        </CColButtonsAcoes2Icons>
      </RowForm>
    </>
  );
};

export default ProdutoEAquisicaoAba;
