import { Page, View, Document } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import React from "react";

const ITEMS_PER_PAGE = 20;

export const PdfFonteDeRecurso = ({ pdfContent }) => {
  const {
    dadosRelatorio,
    opcoes,
    relatorioName,
    userData: { currentClient, userAuthData, dataClientInfos },
    currentYear,
  } = pdfContent;

  if (!dadosRelatorio) return null;

  const colNames = ["E", "FR", "CO", "Título", "Função", "Vínculo"];
  const styledCol = [
    { flexBasis: "50px", flexShrink: 0, flexGrow: 1 },
    { flexBasis: "75px", flexShrink: 0, flexGrow: 1 },
    { flexBasis: "75px", flexShrink: 0, flexGrow: 1 },
    { flexBasis: "70%", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "35%", flexShrink: 0, flexGrow: 1 },
  ];

  const totalPages = Math.ceil(dadosRelatorio.length / ITEMS_PER_PAGE);

  return (
    <Document title={relatorioName}>
      {Array.from({ length: totalPages }, (_, pageIndex) => (
        <Page
          key={pageIndex}
          orientation="portrait"
          size={"A4"}
          style={stylesPdf.page}
        >
          <PdfHeader
            currentClient={currentClient}
            nameRelatorio={"Fontes de Recurso"}
            anoRelatorio={currentYear}
            dataClientInfos={dataClientInfos}
          />
          <View style={[stylesPdf.colunas, { marginTop: "8px" }]} fixed>
            {opcoes[0]?.checkBoxes?.map(
              (each, index) =>
                each.selected && (
                  <PdfColunas
                    key={index}
                    colName={colNames[index]}
                    styledCol={styledCol[index]}
                  />
                )
            )}
          </View>
          <View style={stylesPdf.divRelatorio}>
            <View>
              {dadosRelatorio
                .slice(
                  pageIndex * ITEMS_PER_PAGE,
                  pageIndex * ITEMS_PER_PAGE + ITEMS_PER_PAGE
                )
                .map((item) => (
                  <React.Fragment key={item.funcaoId}>
                    <View style={stylesPdf.colunas}>
                      {opcoes[0]?.checkBoxes[0].selected && (
                        <PdfInfos
                          pdfInfo={item.e ? item.e : ""}
                          styledCol={styledCol[0]}
                        />
                      )}
                      {opcoes[0]?.checkBoxes[1].selected && (
                        <PdfInfos
                          pdfInfo={item.fr ? item.fr : ""}
                          styledCol={styledCol[1]}
                        />
                      )}
                      {opcoes[0]?.checkBoxes[2].selected && (
                        <PdfInfos
                          pdfInfo={item.co ? item.co : ""}
                          styledCol={styledCol[2]}
                        />
                      )}
                      {opcoes[0]?.checkBoxes[3].selected && (
                        <PdfInfos
                          pdfInfo={item.titulo ? item.titulo : ""}
                          styledCol={styledCol[3]}
                        />
                      )}
                      {opcoes[0]?.checkBoxes[4].selected && (
                        <PdfInfos
                          pdfInfo={item.descricao ? item.descricao : ""}
                          styledCol={styledCol[4]}
                        />
                      )}
                      {opcoes[0]?.checkBoxes[5].selected && (
                        <PdfInfos
                          pdfInfo={item.funcao ? item.funcao : ""}
                          styledCol={styledCol[5]}
                        />
                      )}
                    </View>
                    <View
                      style={{
                        height: "1px",
                        width: "100%",
                        backgroundColor: "#a6a6a6",
                      }}
                    ></View>
                  </React.Fragment>
                ))}
              <View style={stylesPdf.divInferiorColuna}></View>
            </View>
          </View>
          <PdfRodape
            currentClient={currentClient}
            userAuthData={userAuthData}
          />
        </Page>
      ))}
    </Document>
  );
};
