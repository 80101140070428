import React from "react";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import Switch from "../../../../components/Grid/Switch";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { LabelCheckLoa2 } from "../../../../components/ComponenteDeRelatorio/StyledCompsRelatorio";
import { InputDateRelatorio } from "../../../../components/Inputs/InputDateRelatorio";

const Geral = () => {
  return (
    <>
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol>
            <Switch size={0.75} label={"Fundo ou Clube de Investimento"} />
          </CCol>
          <CCol>
            <Switch size={0.75} label={"Décimo Terceiro"} />
          </CCol>
          <CCol>
            <Switch size={0.75} label={"Rendimento Recebido Acumulante"} />
          </CCol>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol>
            <FloatingLabelInput label={"Deduções"} placeholder={"Deduções"} />
          </CCol>
          <CCol>
            <FloatingLabelInput
              label={"Rendimentos Isentos"}
              placeholder={"Rendimentos Isentos"}
            />
          </CCol>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol>
            <FloatingLabelInput label={"Tributos"} placeholder={"Tributos"} />
          </CCol>
          <CCol>
            <FloatingLabelInput
              label={"Bloco de Aplicação"}
              placeholder={"Bloco de Aplicação"}
            />
          </CCol>
        </RowAninhada>
        <RowAninhada
          style={{ marginTop: "15px", display: "flex", alignItems: "center" }}
        >
          <CCol>
            <LabelCheckLoa2 paddingDisabled label={"Pessoa Física"} />
          </CCol>
          <CCol>
            <Switch size={0.75} label={"País Exterior PF"} />
          </CCol>
          <CCol>
            <LabelCheckLoa2 paddingDisabled label={"Pessoa Jurídica"} />
          </CCol>
          <CCol>
            <Switch size={0.75} label={"País Exterior PJ"} />
          </CCol>
          <CCol>
            <InputDateRelatorio
              label={"Início Vigência"}
              placeholder={"Início Vigência"}
            />
          </CCol>
          <CCol>
            <InputDateRelatorio
              label={"Fim Vigência"}
              placeholder={"Fim Vigência"}
            />
          </CCol>
        </RowAninhada>
      </Row>
    </>
  );
};

export default Geral;
