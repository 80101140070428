import React, { useEffect, useState } from "react";
import { CCol } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import { FormatDateToBR } from "../../../../utils/FormatacaoDeDados/FormatDateToBR";

function FormCpfPlanejamento({ cpfValid, values, setValues, paramsValor }) {
  const [nomeEnabled, setNomeEnabled] = useState(false);
  //----------------------

  useEffect(() => {
    setNomeEnabled(cpfValid);
  }, [cpfValid]);

  return (
    <>
      <CCol>
        <FloatingLabelInput
          placeholder="Nome completo"
          value={values.nomeCompleto}
          label="Nome"
          onChange={(e) => {
            setValues({ ...values, nomeCompleto: e.target.value });
          }}
          disabled={!nomeEnabled}
        />
      </CCol>

      <CCol md={2}>
        <InputData
          disabled={paramsValor ? true : false}
          externalValue={values.dataCriacao.substring(0, 10)}
          onInput={(e) => {
            console.log(e.target.value);
            setValues({
              ...values,
              dataCriacao: FormatDateToBR(e.target.value),
            });
          }}
        />
      </CCol>
    </>
  );
}

export default FormCpfPlanejamento;
