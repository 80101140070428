import React from "react";
import ImportarLicitacao from "./ImportarLicitacao/ImportarLicitacao";

export const Importar = ({ option = null }) => {
  return (
    <>
      {/* {option === "2.0" && <ImportarLOA />} */}
      {option === "2.1" && <ImportarLicitacao />}
    </>
  );
};

export default Importar;
