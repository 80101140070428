import React from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowAninhada, RowForm } from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import styled from "styled-components";

const FormLista = styled(FormControlListaDeInputs)`
  border: none;
  font-weight: 600;
  font-size: 14px;

  :disabled {
    background-color: #e8e8e8;
    font-weight: 600;
    font-size: 14px;
  }
`;

const PTitulosSaldos = styled.p`
  margin: 0;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #515c70;
`;

const SaldosAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Saldos</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1">
        <CCol>
          <PTitulosSaldos>Saldo Anterior</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>Empenhado</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>Liquidado</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>Pago</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>Saldo Atualizado</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
      </RowForm>

      <RowForm className="mt-1">
        <CCol>
          <PTitulosInputs>Estágio da Despesa</PTitulosInputs>
          <FormLista
            radiusborder={"0px"}
            disabled
            value="1516 - Nota de Empenho "
          />
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Data</PTitulosInputs>
          <FormLista radiusborder={"0px"} disabled value="19/08/2024" />
        </CCol>
        <CCol md={3}>
          <PTitulosInputs>Valor</PTitulosInputs>
          <FormLista
            radiusborder={"0px"}
            style={{ textAlign: "end" }}
            disabled
            value="30.000,00"
          />
        </CCol>

        <RowAninhada>
          <CCol>
            <FormLista
              radiusborder={"0px"}
              disabled
              value="1516 - Nota de Empenho "
            />
          </CCol>
          <CCol md={2}>
            <FormLista radiusborder={"0px"} disabled value="19/08/2024" />
          </CCol>
          <CCol md={3}>
            <FormLista
              radiusborder={"0px"}
              style={{ textAlign: "end" }}
              disabled
              value="30.000,00"
            />
          </CCol>
        </RowAninhada>
      </RowForm>
    </>
  );
};

export default SaldosAba;
