import React from "react";
import {
  CCol,
  ContainerToModal,
  RowToModal,
} from "../../../components/Grid/CCol";
import HeaderCadastros from "../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../components/Inputs/MyAutoComplete";
import styled from "styled-components";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const EVBCadastro = () => {
  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Eventos Bancários - EVB"}
        PaginaSubtitulo={"Cadastro"}
      />

      <RowToModal className="row_form mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput label="Número" placeholder="Número" disabled />
        </CColNumero>
        <CCol>
          <FloatingLabelInput label="Título" placeholder="Título" />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol>
          <MyAutoComplete
            labelInput="Conta Bancária"
            placeholder="Conta Bancária"
            options={[{ label: "Teste", value: 1 }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
            style={{ height: "38px" }}
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: 1 }]}
            label="Indicador de Lançamento"
            placeholder="Indicador de Lançamento"
          />
        </CCol>
        <CCol md={6}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: 1 }]}
            label="Identificador de Categoria"
            placeholder="Identificador de Categoria"
          />
        </CCol>
        <CCol>
          <MyAutoComplete
            labelInput="Documento Padrão"
            placeholder="Documento Padrão"
            options={[{ label: "Teste", value: 1 }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
            style={{ height: "38px" }}
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol>
          <FloatingLabelInput
            label="Natureza da Receita"
            placeholder="Natureza da Receita"
            disabled
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol>
          <FloatingLabelInput label="Devedor" placeholder="Devedor" disabled />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol>
          <FloatingLabelInput
            tipo="text_area"
            voiceInput
            as="textarea"
            label="Descrição Padrão"
            placeholder="Descrição Padrão"
            style={{ minHeight: "98px" }}
          />
        </CCol>
      </RowToModal>
    </ContainerToModal>
  );
};

export default EVBCadastro;
