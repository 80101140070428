import { Paragraph, TextRun, Footer, AlignmentType, BorderStyle } from "docx";

export const DocxFooter = ({ userAuthData, currentClient, font }) => {
  return new Footer({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            text: `Impresso por ${userAuthData.nome.toUpperCase()} em ${new Date().toLocaleString(
              "pt-BR"
            )}`,
            size: 7 * 2,
            break: 1,
            font: font,
          }),
        ],
        alignment: AlignmentType.RIGHT,
      }),
      new Paragraph({
        children: [],
        border: {
          top: {
            style: BorderStyle.SINGLE,
            size: 2,
            space: 1,
          },
        },
        spacing: {
          before: 100,
        },
        alignment: AlignmentType.CENTER,
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: `Fonte: Save – Soluções Tecnológicas – www.savest.com.br, STTRANS - PATOS - PB CNPJ: ${currentClient.cnpj}`,
            size: 7 * 2,
            font: font,
          }),
        ],
      }),
    ],
  });
};
