import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
import { InvisibleCheckbox } from "../Header/StylesHeader";
// import SideBarGeral from "../SideBar/SideBarGeral";
// import SideBarGeralPlanejamento from "../SideBar/SideBar_Planejamento/SideBarGeral_Planejamento";
import { SidebarExpandidaContext } from "../../contexts/SidebarExpandidaContext";
import { DivHideOnPrint } from "../../styles/StyledComponents/StylesRelatorios";
import SideBarComponent from "../SideBarComponent/SideBarComponent";
import ContabilidadeNav from "../SideBarComponent/ContabilidadeNav";
import GerenciadorNav from "../SideBarComponent/GerenciadorNav";
import PlanejamentoNav from "../SideBarComponent/PlanejamentoNav";

function Estrutura() {
  const location = useLocation();
  const { setSidebarExpandida, sidebarExpandida } = useContext(
    SidebarExpandidaContext
  );
  const showSidebarPlanejamento = location.pathname.includes("/planejamento");
  const showSidebarContabilidade = location.pathname.includes("/contabilidade");
  const showSidebarAcessoInterno =
    location.pathname.includes("/acesso-interno") ||
    location.pathname.includes("/home-test");

  const handleChecked = (e) => {
    console.log(
      "estado da sidebar eeeeeeeeeeeeeeeeeeeeeeeeeeeeeee " + e.target.checked
    );
    setSidebarExpandida(e.target.checked);
  };

  useEffect(() => {
    console.log("valor da sidebar expandida: " + sidebarExpandida);
  }, [sidebarExpandida]);

  return (
    <>
      <DivHideOnPrint>
        <InvisibleCheckbox
          id="check"
          checked={sidebarExpandida}
          onChange={handleChecked}
        />

        {/* Renderiza o SideBarGeral_Planejamento apenas se a URL contiver "/planejamento" */}
        {showSidebarPlanejamento && (
          <>
            {/* <SideBarGeralPlanejamento checkSidebar={sidebarExpandida} /> */}
            <SideBarComponent
              checkSidebar={sidebarExpandida}
              nomeDoModulo={"planejamento"}
              NavComponent={<PlanejamentoNav checkSidebar={sidebarExpandida} />}
            />
          </>
        )}
        {/* Renderiza o SideBarGeral apenas se a URL contiver "/acesso-interno"*/}
        {showSidebarAcessoInterno && window.location.pathname !== "/" && (
          <>
            {/* <SideBarGeral checkSidebar={sidebarExpandida} /> */}
            <SideBarComponent
              checkSidebar={sidebarExpandida}
              nomeDoModulo={"gerenciador"}
              NavComponent={<GerenciadorNav checkSidebar={sidebarExpandida} />}
            />
          </>
        )}
        {window.location.pathname !== "/" && <Header />}
        {showSidebarContabilidade && (
          <SideBarComponent
            checkSidebar={sidebarExpandida}
            nomeDoModulo={"contabilidade"}
            NavComponent={<ContabilidadeNav checkSidebar={sidebarExpandida} />}
          />
        )}
      </DivHideOnPrint>
    </>
  );
}

export default Estrutura;
