import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfDespesaOrgaoUnidade = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    {
      flexBasis: "150px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "100%",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "180px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "180px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "180px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const optionDespesaClassificacao = opcoes.find(
    (option) => option.opcao === "Demonstrativo"
  );

  const renderReceitas = (
    codigo,
    titulo,
    unidadeDespesas,
    styledBold = false
  ) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            fontWeight: styledBold ? "bold" : "normal",
            margin: "0 -3px",
          },
        ]}
      >
        {optionDespesaClassificacao?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={codigo}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textAlign: "left",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={titulo}
            styledCol={[styledCol[1], { padding: "2px 0", textAlign: "left" }]}
          />
        )}

        {optionDespesaClassificacao?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={
              unidadeDespesas.totalEspeciais
                ? FormatValueToLocaleString(unidadeDespesas.totalEspeciais)
                : ""
            }
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[3].selected && (
          <PdfInfos
            pdfInfo={
              unidadeDespesas.totalProjetos
                ? FormatValueToLocaleString(unidadeDespesas.totalProjetos)
                : ""
            }
            styledCol={[
              styledCol[3],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[4].selected && (
          <PdfInfos
            pdfInfo={
              unidadeDespesas.totalAtividades
                ? FormatValueToLocaleString(unidadeDespesas.totalAtividades)
                : ""
            }
            styledCol={[
              styledCol[4],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderUnidade = (codigo, titulo, unidade = true) => {
    return (
      <View
        fixed
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            backgroundColor: unidade ? "#D0CECE" : "#a6a6a6",
            fontWeight: "bold",
            margin: "0 -3px",
          },
        ]}
      >
        {optionDespesaClassificacao?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={codigo}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={titulo ? titulo.toUpperCase() : ""}
            styledCol={[
              styledCol[1],
              {
                padding: "2px 0",
                textTransform: "capitalize",
                textAlign: "left",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[3].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[3],
              {
                padding: "2px 0",
                textAlign: "center",
              },
            ]}
          />
        )}
        {optionDespesaClassificacao?.checkBoxes[4].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[4],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderTotal = (total, customName = "TOTAL") => {
    return (
      <>
        <View
          style={[
            stylesPdf.divInferiorColuna,
            {
              fontWeight: "bold",
              fontSize: 10,
              textTransform: "uppercase",
              color: "white",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingRight: 0,
            },
          ]}
        >
          <Text>{customName}</Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                width: 81,
                textAlign: "right",
                paddingRight: 7,
              }}
            >
              {total.totalEspeciaisUnidade
                ? FormatValueToLocaleString(total.totalEspeciaisUnidade)
                : ""}
            </Text>
            <Text
              style={{
                width: 81,
                textAlign: "right",
                paddingRight: 7,
              }}
            >
              {total.totalProjetosUnidade
                ? FormatValueToLocaleString(total.totalProjetosUnidade)
                : ""}
            </Text>
            <Text
              style={{
                width: 81,
                textAlign: "right",
                paddingRight: 7,
              }}
            >
              {total.totalAtividadesUnidade
                ? FormatValueToLocaleString(total.totalAtividadesUnidade)
                : ""}
            </Text>
          </View>
        </View>
        <View />
      </>
    );
  };

  const renderSubTotal = (textTotalType, total) => {
    return (
      <View
        wrap={false}
        style={[
          stylesPdf.divInferiorColuna,
          {
            fontWeight: "bold",
            fontSize: 10,
            textTransform: "uppercase",
            color: "white",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: 7,
          },
        ]}
      >
        <Text>{textTotalType}</Text>
        <Text>{total}</Text>
      </View>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        plusText={"Anexo VI"}
        nameRelatorio={"Programa de Trabalho por Órgão e Unidade Orçamentária"}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {optionDespesaClassificacao?.checkBoxes?.map((option, index) => (
          <PdfColunas
            key={index}
            colName={option.label.toUpperCase()}
            styledCol={styledCol[index]}
            hideBar
          />
        ))}
      </View>
      <React.Fragment>
        <React.Fragment>
          {dados.dataOrgao.map((unidade, unidadeIndex) => (
            <React.Fragment key={unidadeIndex}>
              <Text break={unidadeIndex > 0}></Text>
              {unidade.unidades.map(
                (unidadeOrcamentaria, indexUnidadeOrcamentaria) => (
                  <View
                    key={indexUnidadeOrcamentaria}
                    break={indexUnidadeOrcamentaria > 0}
                  >
                    {renderUnidade(
                      unidade.clientId,
                      unidade.unidadeGestora,
                      // unidade.poderOrgao,
                      false
                    )}
                    {renderUnidade(
                      unidadeOrcamentaria.codigoUnidadeOrcamentaria,
                      unidadeOrcamentaria.tituloUnidadeOrcamentaria
                    )}
                    {unidadeOrcamentaria.relatorio.map(
                      (funcao, indexFuncao) => {
                        const codigoFuncao = funcao.codigoFuncao;
                        return (
                          <React.Fragment key={indexFuncao}>
                            {renderReceitas(
                              codigoFuncao,
                              funcao.tituloFuncao,
                              funcao
                            )}
                            {funcao.subFuncoes.map(
                              (subFuncao, indexSubFuncao) => {
                                const codigoSubFuncao = `${codigoFuncao}.${subFuncao.codigoSubFuncao}`;
                                return (
                                  <React.Fragment key={indexSubFuncao}>
                                    {renderReceitas(
                                      codigoSubFuncao,
                                      subFuncao.tituloSubFuncao,
                                      subFuncao
                                    )}
                                    {subFuncao.programas.map(
                                      (programa, indexPrograma) => {
                                        const codigoPrograma = `${codigoSubFuncao}.${programa.codigoPrograma}`;
                                        return (
                                          <React.Fragment key={indexPrograma}>
                                            {renderReceitas(
                                              codigoPrograma,
                                              programa.tituloPrograma,
                                              programa,
                                              true
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </React.Fragment>
                                );
                              }
                            )}
                          </React.Fragment>
                        );
                      }
                    )}
                    {renderTotal(unidadeOrcamentaria)}
                    <View style={{ marginTop: 10 }}>
                      {renderSubTotal(
                        "subTotal",
                        FormatValueToLocaleString(
                          unidadeOrcamentaria.totalDaUnidade
                        )
                      )}
                    </View>
                  </View>
                )
              )}
            </React.Fragment>
          ))}

          <View style={{ marginTop: 10 }}>
            {renderSubTotal(
              "total geral",
              FormatValueToLocaleString(dados.total)
            )}
          </View>
        </React.Fragment>
      </React.Fragment>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
