import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import {
  CCol,
  CColParametrosIndicesInput,
  CColButtonAdicionarParametrosIndices,
} from "../../../../components/Grid/CCol";
import { anoAtual, arrayAnos } from "./ArraysParametrosIndices";
import ExpandableButtons from "../../../../components/ConjuntoButtonsForm/ExpandableButtons";
import { toast, ToastContainer } from "react-toastify";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import api from "../../../../utils/api";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { useSearchParams } from "react-router-dom";

const ParametroCadastro = forwardRef((props, ref) => {
  const { currentClient } = useContext(CurrentClientContext);
  const [valuesPibReal, setValuesPibReal] = useState(new Array(6).fill(null));
  const [valuesProjecaoPib, setValuesProjecaoPib] = useState(
    new Array(6).fill(null)
  );
  const [valuesRealizacaoPib, setValuesRealizacaoPib] = useState(
    new Array(6).fill(null)
  );
  const [searchParams] = useSearchParams();

  const queryParams = searchParams.get("query");

  const ListSearchParams = [
    "pibRealPlanejamento",
    "projecaoPibPlanejamento",
    "realizacaoPibPlanejamento",
  ];

  const [valoresInputs, setValoresInputs] = useState({
    primeiraFila: [
      { ano: arrayAnos[0], valor: "" },
      { ano: arrayAnos[1], valor: "" },
      { ano: arrayAnos[2], valor: "" },
      { ano: arrayAnos[3], valor: "" },
      { ano: arrayAnos[4], valor: "" },
      { ano: arrayAnos[5], valor: "" },
    ],
    segundaFila: [
      { ano: arrayAnos[0], valor: "" },
      { ano: arrayAnos[1], valor: "" },
      { ano: arrayAnos[2], valor: "" },
      { ano: arrayAnos[3], valor: "" },
      { ano: arrayAnos[4], valor: "" },
      { ano: arrayAnos[5], valor: "" },
    ],
    terceiraFila: [
      { ano: arrayAnos[0], valor: "" },
      { ano: arrayAnos[1], valor: "" },
      { ano: arrayAnos[2], valor: "" },
      { ano: arrayAnos[3], valor: "" },
      { ano: arrayAnos[4], valor: "" },
      { ano: arrayAnos[5], valor: "" },
    ],
  });

  const preencheValores = () => {
    api.get(`parametros/${currentClient.clienteId}`).then((resp) => {
      console.log("resp do get");
      console.log(resp.data);

      //Adicionando valores da primeira fileira
      setValoresInputs((prevValores) => ({
        ...prevValores,
        primeiraFila:
          resp.data.parametrosPibRealPlanejamento.anos.length > 0
            ? resp.data.parametrosPibRealPlanejamento.anos.filter(
                (item) =>
                  item !== undefined &&
                  item !== undefined &&
                  arrayAnos.includes(item.ano)
              )
            : [...prevValores.primeiraFila],
        segundaFila:
          resp.data.parametrosProjecaoPibPlanejamento.anos.length > 0
            ? resp.data.parametrosProjecaoPibPlanejamento.anos.filter(
                (item) =>
                  item !== undefined &&
                  item !== undefined &&
                  arrayAnos.includes(item.ano)
              )
            : [...prevValores.segundaFila],
        terceiraFila:
          resp.data.parametrosRealizacaoPibRealPlanejamento.anos.length > 0
            ? resp.data.parametrosRealizacaoPibRealPlanejamento.anos.filter(
                (item) =>
                  item !== undefined &&
                  item !== undefined &&
                  arrayAnos.includes(item.ano)
              )
            : [...prevValores.terceiraFila],
      }));
    });
  };

  useEffect(() => {
    preencheValores();
    //eslint-disable-next-line
  }, []);

  useImperativeHandle(ref, () => ({
    saveAllParametros,
  }));

  const saveAllParametros = async () => {
    const valuesPibRealFiltered = valuesPibReal.filter((item) => item !== null);
    const valuesProjecaoPibFiltered = valuesProjecaoPib.filter(
      (item) => item !== null
    );
    const valuesRealizacaoPibFiltered = valuesRealizacaoPib.filter(
      (item) => item !== null
    );

    console.log(valuesPibRealFiltered);
    console.log(valuesProjecaoPibFiltered);
    console.log(valuesRealizacaoPibFiltered);

    if (valuesPibRealFiltered.length) {
      const pathName = "pibReal";
      const taxaDeJurosData = await getParametrosValues(pathName);
      const anosExistentes = taxaDeJurosData.map((item) => item.ano);
      const { valuesToPost, valuesToPut } = getValuesToPostAndPut(
        valuesPibRealFiltered,
        anosExistentes
      );

      if (valuesToPut.length) atualizaParametros(pathName, valuesToPut);
      if (valuesToPost.length) cadastroParametros(pathName, valuesToPost);
    }
    if (valuesProjecaoPibFiltered.length) {
      const pathName = "projecaoPib";
      const taxaDeCambioData = await getParametrosValues(pathName);
      const anosExistentes = taxaDeCambioData.map((item) => item.ano);
      const { valuesToPost, valuesToPut } = getValuesToPostAndPut(
        valuesProjecaoPibFiltered,
        anosExistentes
      );

      if (valuesToPut.length) atualizaParametros(pathName, valuesToPut);
      if (valuesToPost.length) cadastroParametros(pathName, valuesToPost);
    }
    if (valuesRealizacaoPibFiltered.length) {
      const pathName = "realizacaoPibReal";
      const inflacaoData = await getParametrosValues(pathName);
      const anosExistentes = inflacaoData.map((item) => item.ano);
      const { valuesToPost, valuesToPut } = getValuesToPostAndPut(
        valuesRealizacaoPibFiltered,
        anosExistentes
      );

      if (valuesToPut.length) atualizaParametros(pathName, valuesToPut);
      if (valuesToPost.length) cadastroParametros(pathName, valuesToPost);
    }
  };

  const getParametrosValues = (parametroPath) => {
    return api
      .get(`parametros/${parametroPath}/${currentClient.clienteId}`)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  };

  const atualizaParametros = (parametroPath, parametroValues) => {
    api
      .put(
        `parametros/${parametroPath}/atualiza/${currentClient.clienteId}`,
        parametroValues
      )
      .then(() => {
        toast.success("Valores atualizados com sucesso");
      })
      .catch(() => {
        toast.error("Algo deu errado. Por favor, tente mais tarde");
      });
  };

  const cadastroParametros = (parametroPath, parametroValues) => {
    api
      .post(`parametros/${parametroPath}`, parametroValues)
      .then(() => {
        toast.success("Valores cadastrados com sucesso");
      })
      .catch(() => {
        toast.error("Algo deu errado. Por favor, tente mais tarde");
      });
  };

  const getValuesToPostAndPut = (parametroValues, anosExistentes) => {
    const valuesToPost = parametroValues.filter(
      (item) => !anosExistentes.includes(item.ano)
    );
    const valuesToPut = parametroValues.filter((item) =>
      anosExistentes.includes(item.ano)
    );
    return {
      valuesToPost,
      valuesToPut,
    };
  };

  const adicionarParametros = (tipoParametro) => {
    const urlPath = ["pibReal", "projecaoPib", "realizacaoPibReal"];
    let values = [];

    switch (tipoParametro) {
      case 0:
        values = valuesPibReal;
        break;
      case 1:
        values = valuesProjecaoPib;
        break;
      case 2:
        values = valuesRealizacaoPib;
        break;
      default:
        values = [];
        break;
    }

    values = values.filter((item) => item !== null);
    console.log(values);

    console.log(values.length);
    let valuesJaExistentes = [];

    api
      .get(`parametros/${urlPath[tipoParametro]}/${currentClient.clienteId}`)
      .then((resp) => {
        valuesJaExistentes = resp.data;
        console.log(valuesJaExistentes);

        if (
          values.filter(
            (item) =>
              item !== null &&
              item.valor !== "" &&
              item.valor !== null &&
              item.valor !== undefined
          ).length === 0
        ) {
          throw new Error("No values to post.");
        }

        //Verificacao pra saber quais valores vao pro PUT e quais vao pro POST

        //Valores dos anos mandados pelo usuario
        let valuesAnos = values.map((item) => item.ano);

        //Valores dos anos ja existentes recebidos pelo get
        let valuesExistentesAnos = valuesJaExistentes.map((item) => item.ano);
        console.log(valuesAnos);
        console.log(valuesExistentesAnos);

        //Definindos as datas que ainda nao existem registro no banco de dados (POST)
        let valuesPost = valuesAnos.filter(
          (ano) => !valuesExistentesAnos.includes(ano)
        );

        console.log("valores para postar");
        console.log(valuesPost);

        valuesPost = values
          .filter((item) => item !== null && valuesPost.includes(item.ano))
          .filter(
            (item) =>
              item !== null &&
              item.valor !== "" &&
              item.valor !== null &&
              item.valor !== undefined
          );
        console.log("valores para postar 2");
        console.log(valuesPost);
        //----------------------------------------------------------------------

        //Definindos os valores que JA existem no banco de dados (PUT)
        let valuesPut = values
          .filter(
            (item) => item !== null && valuesExistentesAnos.includes(item.ano)
          )
          .filter(
            (item) =>
              item !== null &&
              item.valor !== "" &&
              item.valor !== null &&
              item.valor !== undefined
          );

        console.log("valores para atualizar");
        console.log(valuesPut);

        if (values.length > 0) {
          if (valuesPost.length > 0) {
            api
              .post(`parametros/${urlPath[tipoParametro]}`, valuesPost)
              .then((resp) => {
                console.log(resp);
                toast.success("Valores cadastrados com sucesso");
              })
              .catch((error) => {
                console.log(error);
                console.log(
                  valuesPost.filter(
                    (item) =>
                      item !== null &&
                      item.valor !== "" &&
                      item.valor !== null &&
                      item.valor !== undefined
                  )
                );
                console.log("erro no post");
                toast.error("Algo deu errado. Por favor, tente mais tarde");
              });
          }

          if (valuesPut.length > 0) {
            console.log(
              `parametros/${urlPath[tipoParametro]}/atualiza/${currentClient.clienteId}`
            );
            api
              .put(
                `parametros/${urlPath[tipoParametro]}/atualiza/${currentClient.clienteId}`,
                valuesPut
              )
              .then((resp) => {
                console.log(resp);
                toast.success("Valores atualizados com sucesso");
                console.log(valuesPut);
              })
              .catch((error) => {
                console.log(error);
                console.log("erro no put");
                console.log(valuesPut);
                toast.error("Algo deu errado. Por favor, tente mais tarde");
              });
          }
        } else {
          throw new Error("No values to post.");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.message === "No values to post.") {
          toast.error(
            "Altere pelo menos um campo dessa linha pra adicionar ou atualizar os valores."
          );
        } else {
          console.log(error);
          toast.error(
            "Estamos tendo problemas com essa página no momento. Por favor, tente mais tarde."
          );
        }
      });
  };

  /* - Função Antiga
  const atualizaValoresInputs = (valor, tipoParametro, ano) => {
    let fileira = "";
    let fileiraAtualizada = [];
    console.log("passou aqui ta");

    switch (tipoParametro) {
      case 0:
        fileira = "primeiraFila";
        break;
      case 1:
        fileira = "segundaFila";
        break;
      case 2:
        fileira = "terceiraFila";
        break;
      default:
        fileira = "";
        break;
    }

    // if (valoresInputs[fileira].length >= 0) {
    console.log("passou aqui dizendo que é maior que zero");
    fileiraAtualizada = valoresInputs[fileira];
    let indexAtualizacao = fileiraAtualizada.findIndex(
      (item) => item && item.ano === ano
    );

    if (indexAtualizacao === -1) {
      console.log("passou aqui dizendo que o index é igual a -1");
      console.log(fileiraAtualizada);
      let indexAddData = arrayAnos.indexOf(ano);
      let arrayAddData = valoresInputs[fileira];
      arrayAddData[indexAddData] = { ano: ano, valor: valor };
      setValoresInputs((prevValues) => ({
        ...prevValues,
        [fileira]: arrayAddData,
      }));
    }

    if (indexAtualizacao !== -1) {
      console.log("passou aqui dizendo que o index é DIFERENTE de -1");
      console.log("atualizando");
      fileiraAtualizada[indexAtualizacao].valor = valor;
      setValoresInputs((prevValues) => ({
        ...prevValues,
        [fileira]: fileiraAtualizada,
      }));
    }
    // }
  };
  */

  const atualizaValoresInputs = (valor, tipoParametro, ano) => {
    setValoresInputs((prevValores) => {
      const fileira = getFileira(tipoParametro);
      const fileiraAtualizada = [...prevValores[fileira]];

      const indexAtualizacao = fileiraAtualizada.findIndex(
        (item) => item && item.ano === ano
      );

      if (indexAtualizacao !== -1) {
        fileiraAtualizada[indexAtualizacao].valor = valor;
      } else {
        fileiraAtualizada.push({ ano: ano, valor: valor });
      }

      return { ...prevValores, [fileira]: fileiraAtualizada };
    });
  };

  const getFileira = (tipoParametro) => {
    switch (tipoParametro) {
      case 0:
        return "primeiraFila";
      case 1:
        return "segundaFila";
      case 2:
        return "terceiraFila";
      default:
        return "";
    }
  };

  useEffect(() => {
    console.log("Valores do valoresInputs");
    console.log(valoresInputs);
    console.log("item correspondente");
    if (valoresInputs.segundaFila.length > 0) {
      console.log(
        valoresInputs.segundaFila.find((item) => item && item.ano === 2022)
      );
    }
  }, [valoresInputs]);

  const formattedValueInput = (value) => {
    if (value) {
      const valueFormatted = (parseFloat(value) / 100).toFixed(2);
      return FormatValueToLocaleString(Number(valueFormatted));
    }
    return "";
  };

  const titleStyles = {
    color: "#515c70",
    backgroundColor: "#E8E8E8",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingLeft: "14px",
    paddingRight: "14px",
    borderRadius: "8px",
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    fontWeight: "bold",
    position: "relative",
  };

  const inputValue = (index, ano, fila) => {
    return fila.length > 0 &&
      fila[index] !== null &&
      fila.find((item) => item && item.ano === ano)
      ? `${
          Number(
            fila
              .find((item) => item && item.ano === ano)
              .valor.replace(/\D/g, "")
          )
            ? "R$ " +
              formattedValueInput(
                fila
                  .find((item) => item && item.ano === ano)
                  .valor.replace(/\D/g, "")
              )
            : ""
        }`
      : "";
  };

  return (
    <>
      <ToastContainer />
      {/* <button onClick={() => console.log(valoresInputs)}>console</button> */}
      <Row
        className="row_form mt-1"
        style={{ borderRadius: "0px 0px 5px 5px" }}
      >
        <CCol md={12} style={titleStyles} className="mb-2">
          PIB Real (Crescimento % anual)
        </CCol>
        {arrayAnos.map((ano, index, selfArray) => (
          <React.Fragment key={index}>
            <CColParametrosIndicesInput
              key={index}
              md={2}
              style={{ marginTop: "5px" }}
            >
              <FloatingLabelInput
                label={ano}
                placeholder={`${ano} %`}
                disabled={
                  queryParams ? queryParams !== ListSearchParams[0] : false
                }
                value={
                  valoresInputs.primeiraFila.length > 0 &&
                  valoresInputs.primeiraFila[index] !== null &&
                  valoresInputs.primeiraFila.find(
                    (item) => item && item.ano === ano
                  )
                    ? `${
                        Number(
                          valoresInputs.primeiraFila
                            .find((item) => item && item.ano === ano)
                            .valor.replace(/\D/g, "")
                        )
                          ? formattedValueInput(
                              valoresInputs.primeiraFila
                                .find((item) => item && item.ano === ano)
                                .valor.replace(/\D/g, "")
                            ) + " %"
                          : ""
                      }`
                    : ""
                }
                onChange={(e) => {
                  const value = formattedValueInput(
                    e.target.value.replace(/\D/g, "")
                  )
                    .replace(/\./g, "")
                    .replace(",", ".");
                  let arrayAtualizado = valuesPibReal;
                  arrayAtualizado[index] = {
                    clienteId: currentClient.clienteId,
                    ano: ano,
                    valor: Number(value) ? value : "",
                  };
                  setValuesPibReal(arrayAtualizado);
                  atualizaValoresInputs(Number(value) ? value : "", 0, ano);

                  const newCursorPos = e.target.value.length - 1;
                  window.requestAnimationFrame(() => {
                    e.target.setSelectionRange(newCursorPos, newCursorPos);
                  });
                }}
              />
            </CColParametrosIndicesInput>
            {index === selfArray.length - 1 && (
              <CColButtonAdicionarParametrosIndices
                style={{
                  marginTop: "7px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <ExpandableButtons
                  saveFunction={() => adicionarParametros(0)}
                  active={queryParams === ListSearchParams[0]}
                  iconsDisabled={
                    queryParams ? queryParams !== ListSearchParams[0] : true
                  }
                />{" "}
              </CColButtonAdicionarParametrosIndices>
            )}
          </React.Fragment>
        ))}

        <CCol
          style={{ border: "1px solid #F2F3F8", marginBottom: "15px" }}
          className="mt-2"
          md={12}
        ></CCol>
        <CCol md={12} style={titleStyles} className="mb-2">
          Projeção do PIB - R$ milhares
        </CCol>
        {arrayAnos.map((ano, index, selfArray) => (
          <React.Fragment key={index}>
            <CColParametrosIndicesInput
              key={index}
              md={2}
              style={{ marginTop: "5px" }}
            >
              <FloatingLabelInput
                label={ano}
                placeholder={`${ano} R$`}
                disabled={
                  queryParams ? queryParams !== ListSearchParams[1] : false
                }
                value={inputValue(index, ano, valoresInputs.segundaFila)}
                onChange={(e) => {
                  const value = formattedValueInput(
                    e.target.value.replace(/\D/g, "")
                  )
                    .replace(/\./g, "")
                    .replace(",", ".");
                  let arrayAtualizado = valuesProjecaoPib;
                  arrayAtualizado[index] = {
                    clienteId: currentClient.clienteId,
                    ano: ano,
                    valor: Number(value) ? value : "",
                  };
                  setValuesProjecaoPib(arrayAtualizado);
                  atualizaValoresInputs(Number(value) ? value : "", 1, ano);
                }}
              />
            </CColParametrosIndicesInput>
            {index === selfArray.length - 1 && (
              <CColButtonAdicionarParametrosIndices
                style={{
                  marginTop: "7px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <ExpandableButtons
                  saveFunction={() => adicionarParametros(1)}
                  active={queryParams === ListSearchParams[1]}
                  iconsDisabled={
                    queryParams ? queryParams !== ListSearchParams[1] : true
                  }
                />{" "}
              </CColButtonAdicionarParametrosIndices>
            )}
          </React.Fragment>
        ))}
        <CCol
          style={{ border: "1px solid #F2F3F8", marginBottom: "15px" }}
          className="mt-2"
          md={12}
        ></CCol>
        <CCol md={12} style={titleStyles} className="mb-2">
          Realização do PIB - R$ milhares
        </CCol>
        {arrayAnos.map((ano, index, selfArray) => (
          <React.Fragment key={index}>
            <CColParametrosIndicesInput
              key={index}
              md={2}
              style={{ marginTop: "5px" }}
            >
              <FloatingLabelInput
                label={ano}
                placeholder={`${ano} R$`}
                disabled={
                  queryParams
                    ? ano >= anoAtual || queryParams !== ListSearchParams[2]
                    : ano >= anoAtual
                }
                value={
                  ano >= anoAtual
                    ? ""
                    : inputValue(index, ano, valoresInputs.terceiraFila)
                }
                onChange={(e) => {
                  const value = formattedValueInput(
                    e.target.value.replace(/\D/g, "")
                  )
                    .replace(/\./g, "")
                    .replace(",", ".");
                  let arrayAtualizado = valuesRealizacaoPib;
                  arrayAtualizado[index] = {
                    clienteId: currentClient.clienteId,
                    ano: ano,
                    valor: Number(value) ? value : "",
                  };
                  setValuesRealizacaoPib(arrayAtualizado);
                  atualizaValoresInputs(Number(value) ? value : "", 2, ano);
                }}
              />
            </CColParametrosIndicesInput>
            {index === selfArray.length - 1 && (
              <CColButtonAdicionarParametrosIndices
                style={{
                  marginTop: "7px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <ExpandableButtons
                  saveFunction={() => adicionarParametros(2)}
                  active={searchParams.get("query") === ListSearchParams[2]}
                  iconsDisabled={
                    queryParams ? queryParams !== ListSearchParams[2] : true
                  }
                />{" "}
              </CColButtonAdicionarParametrosIndices>
            )}
          </React.Fragment>
        ))}
      </Row>
    </>
  );
});

export default ParametroCadastro;

export const FloatingDiv = styled.div`
  font-size: 15px;
  margin-bottom: 4px;
  font-weight: bold;
  color: #747e92;
  position: absolute;
  top: -22px;
  left: 6px;
`;
