import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { PdfInfos } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";

const mockDataReceitas = [
  {
    title: "Alienação de Bens Móveis",
    values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
  },
  {
    title: "Alienação de Bens Imóveis ",
    values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
  },
  {
    title: "Alienação de Bens Intangíveis",
    values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
  },
  {
    title: "Rendimentos de Aplicações Financeiras",
    values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
  },
];

const mockDataDespesas = [
  {
    subSectionTitle: "DESPESAS DE CAPITAL",
    subSectionData: [
      {
        title: "Investimentos ",
        values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
      },
      {
        title: "Inversões Financeiras",
        values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
      },
      {
        title: "Amortização da Dívida ",
        values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
      },
    ],
  },
  {
    subSectionTitle: "DESPESAS CORRENTES DOS REGIMES DE PREVIDÊNCIA ",
    subSectionData: [
      {
        title: "Regime Geral de Previdência Social ",
        values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
      },
      {
        title: "Regime Próprio de Previdência dos Servidores ",
        values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
      },
    ],
  },
];

export const RenderPdfDemoOrigemAplicacao = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: 250,
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const renderTextColumn = (text, style = {}) => (
    <Text style={[stylesPdf.textTituloColuna, { fontSize: 8 }, style]}>
      {text}
    </Text>
  );
  const renderHeader = (titleHeader, dataHeader) => {
    return (
      <>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                height: 24,
                paddingVertical: 2,
                justifyContent: "center",
                alignItems: "center",
              },
            ]}
          >
            {renderTextColumn(titleHeader, { textTransform: "uppercase" })}
          </View>
        </View>
        {dataHeader.map((item, index) => (
          <React.Fragment key={index}>
            <View style={[stylesPdf.coluna, styledCol[1]]}>
              <View
                style={[
                  stylesPdf.divTituloColuna,
                  {
                    textAlign: "center",
                    height: 24,
                    paddingVertical: 2,
                    justifyContent: "space-between",
                    alignItems: "center",
                  },
                ]}
              >
                {renderTextColumn(item.year)}
                {renderTextColumn(item.content.text)}
              </View>
            </View>
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderSection = (titulo, subSection = false) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            backgroundColor: subSection ? "#D9D9D9" : "#a6a6a6",
            fontWeight: "bold",
          },
        ]}
      >
        <PdfInfos
          pdfInfo={titulo}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{ fontSize: 8, textTransform: "uppercase" }}
        />
      </View>
    );
  };

  const renderInfos = (title, values) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
          },
        ]}
        fixed
      >
        <PdfInfos
          pdfInfo={title}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
              textAlign: "left",
            },
          ]}
          styledText={{ fontSize: 8 }}
        />
        {values.map((value, index) => (
          <PdfInfos
            key={index}
            pdfInfo={value}
            styledCol={[
              styledCol[1],
              {
                padding: "2px 0",
                textAlign: "center",
              },
            ]}
            styledText={{ paddingHorizontal: 0, fontSize: 8 }}
          />
        ))}
      </View>
    );
  };

  
  return (
    <Page size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={
          "Origem e Aplicação dos Recursos Obtidos com a Alienação de Ativos"
        }
        anoRelatorio={currentYear}
        plusText={"Anexo de Metas Fiscais"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: 8,
          padding: "0 4px",
        }}
      >
        <Text>AMF - Demonstrativo 5 (LRF, art. 4º, § 2º, inciso III)</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
        {renderHeader(
          "receitas realizadas",
          dados.contentHeader.headerReceitaDespesa
        )}
      </View>
      <View style={stylesPdf.divRelatorio}>
        {renderSection("receitas de capital – alienação de ativos (i)")}
        {mockDataReceitas.map((item, index) => (
          <React.Fragment key={index}>
            {renderInfos(item.title, item.values)}
          </React.Fragment>
        ))}
        <View
          style={{
            height: 1,
            width: "100%",
            backgroundColor: "#000",
            marginBottom: 20,
          }}
        />
      </View>

      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
        {renderHeader(
          "despesas realizadas",
          dados.contentHeader.headerReceitaDespesa
        )}
      </View>
      <View style={stylesPdf.divRelatorio}>
        {renderSection("APLICAÇÃO DOS RECURSOS DA ALIENAÇÃO DE ATIVOS (II)")}
        {mockDataDespesas.map((item, index) => (
          <React.Fragment key={index}>
            {renderSection(item.subSectionTitle, true)}
            {item.subSectionData.map((dataSubSection, subIndex) => (
              <React.Fragment key={subIndex}>
                {renderInfos(dataSubSection.title, dataSubSection.values)}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        <View
          style={{
            height: 1,
            width: "100%",
            backgroundColor: "#000",
            marginBottom: 20,
          }}
        />
      </View>

      <View
        style={[
          stylesPdf.colunas,
          { backgroundColor: "#515c70" },
        ]}
      >
        {renderHeader("saldo", dados.contentHeader.headerSaldo)}
      </View>

      <View style={stylesPdf.divRelatorio}>
        <View
          style={[
            stylesPdf.colunas,
            {
              fontWeight: "bold",
            },
          ]}
        >
          <PdfInfos
            pdfInfo={"valor (III)"}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
              },
            ]}
            styledText={{ fontSize: 8, textTransform: "uppercase" }}
          />
        </View>
        <View style={{ height: 1, width: "100%", backgroundColor: "#000" }} />
        <Text
          style={{
            fontWeight: "bold",
            marginLeft: 8,
            marginTop: 2,
            fontSize: 8,
          }}
        >
          FONTE: Secretaria de Finanças
        </Text>
      </View>

      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
