import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";

import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfDespesaDemonstrativoPrograma = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: "110px", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    {
      flexBasis: "110px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const optionDespesaDemonstrativo = opcoes.find(
    (option) => option.opcao === "Quadro Detalhado da Receita - QDR"
  );

  const renderReceitas = (acaoKey, acao, forceBold = false) => {
    return (
        <View
          style={[
            stylesPdf.colunas,
            {
              gap: 0,
              margin: "0 -3px",
              fontWeight: forceBold ? "bold" : "normal",
            },
          ]}
        >
          {optionDespesaDemonstrativo?.checkBoxes[0].selected && (
            <PdfInfos
              pdfInfo={acaoKey || ""}
              styledCol={[
                styledCol[0],
                {
                  padding: "2px 0",
                  textAlign: "left",
                  flexBasis: 70,
                },
              ]}
            />
          )}
          {optionDespesaDemonstrativo?.checkBoxes[1].selected && (
            <PdfInfos
              pdfInfo={acao.tituloPrograma || ""}
              styledCol={[
                styledCol[1],
                { padding: "2px 0", textAlign: "left" },
              ]}
            />
          )}

          {optionDespesaDemonstrativo?.checkBoxes[2].selected && (
            <PdfInfos
              pdfInfo={
                acao.valorTotal
                  ? FormatValueToLocaleString(acao.valorTotal)
                  : ""
              }
              styledCol={[
                styledCol[2],
                {
                  padding: "2px 0",
                  textAlign: "right",
                },
              ]}
            />
          )}
        </View>
    );
  };

  const renderUnidade = (titulo, total) => {
    console.log(titulo);
    console.log(total);

    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            backgroundColor: "#a6a6a6",
            fontWeight: "bold",
            margin: "0 -3px",
          },
        ]}
        fixed
      >
        {optionDespesaDemonstrativo?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={titulo}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textTransform: "uppercase",
                flexBasis: 300,
                textAlign: "left",
              },
            ]}
          />
        )}
        {optionDespesaDemonstrativo?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[1],
              {
                padding: "2px 0",
                textTransform: "capitalize",
                textAlign: "left",
              },
            ]}
          />
        )}
        {optionDespesaDemonstrativo?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={FormatValueToLocaleString(total)}
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
                flexBasis: 150,
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderTotal = (typeTextTotal, total) => {
    return (
      <View
        wrap={false}
        style={[
          stylesPdf.divInferiorColuna,
          {
            fontWeight: "bold",
            fontSize: 10,
            textTransform: "uppercase",
            color: "white",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 10,
            margin: "0 -3px",
          },
        ]}
      >
        <Text>{typeTextTotal}</Text>
        <Text>{total}</Text>
      </View>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Demonstrativo da Despesa por Programas de Governo"}
        anoRelatorio={currentYear}
        plusText={"vazio"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {optionDespesaDemonstrativo?.checkBoxes?.map((option, index) => (
          <PdfColunas
            key={index}
            colName={option.label.toUpperCase()}
            styledCol={styledCol[index]}
            hideBar
          />
        ))}
      </View>
      <React.Fragment>
        <React.Fragment>
          {dados.filterProgramas.map((programa, index) => {
            return (
              <View key={index} break={index > 0}>
                {renderUnidade(programa.tipoPrograma, programa.total)}
                {Object.keys(programa.Programas).map(
                  (programaKey, programaIndex) => {
                    const programaGovernamental =
                      programa.Programas[programaKey];
                    return (
                      <React.Fragment key={programaIndex}>
                        {renderReceitas(
                          programaKey,
                          programaGovernamental,
                          true
                        )}
                        {renderReceitas("", {
                          tituloPrograma: "Objetivo",
                          valorTotal: null,
                        })}
                      </React.Fragment>
                    );
                  }
                )}
                {renderTotal(
                  "subtotal",
                  FormatValueToLocaleString(programa.total)
                )}
              </View>
            );
          })}
          {dados.total &&
            renderTotal("total geral", FormatValueToLocaleString(dados.total))}
        </React.Fragment>
      </React.Fragment>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
