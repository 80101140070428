import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { PdfInfos } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";

export const RenderPdfDemoAvaliacao = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: "200px", flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: "100%",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const dataHeader = [
    { text: "Metas Previstas \nem 2023 \n(a)", width: 100 },
    { text: "% PIB", width: 70 },
    { text: "% RCL", width: 70 },
    { text: "Metas Previstas \nem 2023 \n(b)", width: 100 },
    { text: "% PIB", width: 70 },
    { text: "% RCL", width: 70 },
  ];

  const dataSection = [
    { info: "111.956.089,64", width: 100 },
    { info: "378.286,116", width: 70 },
    { info: "105,20", width: 70 },
    { info: "111.956.089,64", width: 100 },
    { info: "378.286,116", width: 70 },
    { info: "355.140,225", width: 70 },
  ];

  const namesSection = [
    { titulo: "Receita Total", color: true },
    { titulo: "Receitas Primárias (I)" },
    { titulo: "Despesa Total", color: true },
    { titulo: "Despesas Primárias (II)" },
    { titulo: "Resultado Primário (III) = (I – II)", color: true },
    { titulo: "Dívida Pública Consolidada (DC)", textBold: false },
    { titulo: "Dívida Consolidada Líquida (DCL)", textBold: false },
    { titulo: "Resultado Nominal", color: true },
  ];

  const renderTextColumn = (text, style = {}) => (
    <Text style={[stylesPdf.textTituloColuna, style,{fontSize:8}]}>{text}</Text>
  );

  const renderHeader = () => {
    return (
      <>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View style={[stylesPdf.divTituloColuna, { textAlign: "center" }]}>
            <Text
              style={[
                stylesPdf.textTituloColuna,
                { textTransform: "uppercase",fontSize:8 },
              ]}
            >
              especificações
            </Text>
          </View>
        </View>

        <View style={[stylesPdf.coluna, styledCol[1]]}>
          <View style={[stylesPdf.divTituloColuna]}>
            <View
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                height: "100%",
              }}
            >
              {dataHeader.map(({ text, width }, index) => (
                <React.Fragment key={index}>
                  {renderTextColumn(text, { width })}
                </React.Fragment>
              ))}
              <View
                style={[
                  {
                    alignItems: "center",
                    marginRight: 10,
                    width: 150,
                  },
                ]}
              >
                {renderTextColumn("Variação")}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingLeft: 10,
                  }}
                >
                  {renderTextColumn("Valor \n(c) = (b-a)")}
                  {renderTextColumn("% \n(c/a) x 100")}
                </View>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  };

  const renderHeaderParametro = () => {
    return (
      <>
        <View style={[stylesPdf.coluna, { width: "100%" }]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              { textAlign: "center", height: 38 },
            ]}
          >
            <Text style={[stylesPdf.textTituloColuna,{fontSize:8,textTransform: 'uppercase'}]}>Parâmetros</Text>
          </View>
        </View>

        <View style={[stylesPdf.coluna, { width: "100%" }]}>
          <View style={[stylesPdf.divTituloColuna, { height: 38 }]}>
            <View
              style={{
                height: "100%",
                justifyContent: "center",
              }}
            >
              <View
                style={[
                  {
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "space-around",
                  },
                ]}
              >
                <Text
                  style={[
                    stylesPdf.textTituloColuna,
                    { textTransform: "uppercase" ,fontSize:8},
                  ]}
                >
                  período
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingHorizontal: 24,
                  }}
                >
                  <Text style={[stylesPdf.textTituloColuna,{fontSize:8}]}>
                    Valor Previsto 2023
                  </Text>
                  <Text style={[stylesPdf.textTituloColuna,{fontSize:8}]}>
                    Valor Realizado 2023
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  };

  const renderSection = (titulo, sectionColor = false, textBold = true) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            backgroundColor: sectionColor ? "#a6a6a6" : "transparent",
            fontWeight: textBold ? "bold" : "normal",
          },
        ]}
      >
        <PdfInfos
          pdfInfo={titulo}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{fontSize:8}}
        />

        <View
          style={[
            stylesPdf.coluna,
            styledCol[1],
            {
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            },
          ]}
        >
          {dataSection.map(({ info, width }, index) => (
            <PdfInfos
              key={index}
              pdfInfo={info}
              styledCol={[{ padding: "2px 0", width }]}
              styledText={{ paddingHorizontal: 0,fontSize:8 }}
            />
          ))}

          <View
            style={[
              {
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 28,
                width: 150,
                marginRight: 10,
              },
            ]}
          >
            <Text
              style={[stylesPdf.textInfosRelatorio, { paddingHorizontal: 0 ,fontSize:8}]}
            >
              - 25.633.237,73
            </Text>
            <Text
              style={[stylesPdf.textInfosRelatorio, { paddingHorizontal: 0 ,fontSize:8}]}
            >
              - 165,4182
            </Text>
          </View>
        </View>
      </View>
    );
  };

  return (
    <Page orientation="landscape" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={
          "Avaliação do Cumprimento das Metas Fiscais do Exercício Anterior"
        }
        anoRelatorio={currentYear}
        plusText={"Anexo de Metas Fiscais"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>AMF - Demonstrativo 2 (LRF, art. 4º, § 2º, inciso I)</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
        {renderHeader()}
      </View>
      <View style={stylesPdf.divRelatorio}>
        {namesSection.map(({ titulo, color, textBold }, index) => (
          <React.Fragment key={index}>
            {renderSection(titulo, color, textBold)}
          </React.Fragment>
        ))}
        <Text style={{ fontWeight: "bold", marginLeft: 8,fontSize:8 }}>
          FONTE: Secretaria de Finanças
        </Text>
      </View>

      <View style={[stylesPdf.colunas, { marginTop: 18 }]}>
        {renderHeaderParametro()}
      </View>

      <View
        style={{
          backgroundColor: "#eeeeee",
          width: "100%",
        }}
      >
        <PdfInfos
          pdfInfo={"PIB Nominal"}
          styledCol={[
            {
              padding: "2px 0",
            },
          ]}
          styledText={{ fontWeight: "bold" }}
        />
      </View>
      <PdfInfos
        pdfInfo={"Receita Corrente Líquida – RCL"}
        styledCol={[
          {
            padding: "2px 0",
          },
        ]}
        styledText={{ fontWeight: "bold",fontSize:8 }}
      />

      <View
        style={{
          width: "100%",
          height: 1,
          backgroundColor: "#000",
          marginVertical: 2,
        }}
      />
      <Text style={{ fontWeight: "bold", marginLeft: 8,fontSize:8 }}>
        FONTE: Banco Central – Relatório Focus de abril 2024
      </Text>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
