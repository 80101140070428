import { Row } from "react-bootstrap";
import styled from "styled-components";
import { CCol } from "../../components/Grid/CCol";

//------- Row de selecao de abas inferiores -----------

export const RowAbaInferior = styled(Row)`
  background-color: white;
  border-radius: 0 0 5px 5px;
  margin-top: 4px;
`;

export const CColAbaInferior = styled(CCol)`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const OptionAbaInferior = styled.span`
  color: #515c70;
  line-height: 35px;
  font-size: 0.8em;
  cursor: pointer;
  margin-left: 20px;
  ${({ selecionado }) =>
    selecionado &&
    `
    font-weight: 600;
    border-bottom: 2px solid #515c70;
  `}
`;

//-----------------------------------------------------
