import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { PdfInfos } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";

const mockDataReceitas = {
  sectionTitle: "RECEITAS CORRENTES (I)",
  subSection: [
    {
      subSectionTitle: "Receita de Contribuições dos Segurados",
      subSectionData: [
        {
          subSectionTitleInfo: "Ativo",
          subSectionValues: ["1.000.000,00", "1.000.000,00", "1.000.000,00"],
        },
        {
          subSectionTitleInfo: "Inativo ",
          subSectionValues: ["1.000.000,00", "1.000.000,00", "1.000.000,00"],
        },
        {
          subSectionTitleInfo: "Pensionista ",
          subSectionValues: ["1.000.000,00", "1.000.000,00", "1.000.000,00"],
        },
      ],
    },
    {
      subSectionTitle: "Receita de Contribuições Patronais",
      subSectionData: [
        {
          subSectionTitleInfo: "Ativo",
          subSectionValues: ["1.000.000,00", "1.000.000,00", "1.000.000,00"],
        },
        {
          subSectionTitleInfo: "Inativo ",
          subSectionValues: ["1.000.000,00", "1.000.000,00", "1.000.000,00"],
        },
        {
          subSectionTitleInfo: "Pensionista ",
          subSectionValues: ["1.000.000,00", "1.000.000,00", "1.000.000,00"],
        },
      ],
    },
  ],
};
const mockDataReceitasReparticao = {
  sectionTitle: "RECEITAS CORRENTES (VII)",
  subSection: [
    {
      subSectionTitle: "Receita de Contribuições dos Segurados",
      subSectionData: [
        {
          subSectionTitleInfo: "Ativo",
          subSectionValues: ["1.000.000,00", "1.000.000,00", "1.000.000,00"],
        },
        {
          subSectionTitleInfo: "Inativo ",
          subSectionValues: ["1.000.000,00", "1.000.000,00", "1.000.000,00"],
        },
        {
          subSectionTitleInfo: "Pensionista ",
          subSectionValues: ["1.000.000,00", "1.000.000,00", "1.000.000,00"],
        },
      ],
    },
    {
      subSectionTitle: "Receita de Contribuições Patronais",
      subSectionData: [
        {
          subSectionTitleInfo: "Ativo",
          subSectionValues: ["1.000.000,00", "1.000.000,00", "1.000.000,00"],
        },
        {
          subSectionTitleInfo: "Inativo ",
          subSectionValues: ["1.000.000,00", "1.000.000,00", "1.000.000,00"],
        },
        {
          subSectionTitleInfo: "Pensionista ",
          subSectionValues: ["1.000.000,00", "1.000.000,00", "1.000.000,00"],
        },
      ],
    },
  ],
};

const mockDataReceitasCapital = {
  sectionTitle: "RECEITAS DE CAPITAL (III)",
  sectionInfos: [
    {
      title: "Alienação de Bens, Direitos e Ativos ",
      values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
    },
    {
      title: "Amortização de Empréstimos ",
      values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
    },
    {
      title: "Outras Receitas de Capital ",
      values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
    },
  ],
};
const mockDataReceitasCapitalReparticao = {
  sectionTitle: "RECEITAS DE CAPITAL (VIII)",
  sectionInfos: [
    {
      title: "Alienação de Bens, Direitos e Ativos ",
      values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
    },
    {
      title: "Amortização de Empréstimos ",
      values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
    },
    {
      title: "Outras Receitas de Capital ",
      values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
    },
  ],
};
const mockDataDespesasPrevidenciarias = [
  {
    sectionTitle: "Benefícios",
    sectionInfos: [
      {
        sectionTextInfo: "Aposentadorias",
        sectionValuesInfo: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
      },
      {
        sectionTextInfo: "Pensões por Morte ",
        sectionValuesInfo: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
      },
    ],
  },
  {
    sectionTitle: "Outras Despesas Previdenciárias ",
    sectionInfos: [
      {
        sectionTextInfo: "Compensação Financeira entre os Regimes ",
        sectionValuesInfo: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
      },
      {
        sectionTextInfo: "Demais Despesas Previdenciárias",
        sectionValuesInfo: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
      },
    ],
  },
];

const mockDataBensDireitos = [
  {
    title: "Caixa e Equivalentes de Caixa ",
    values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
  },
  {
    title: "Investimentos e Aplicações ",
    values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
  },
  {
    title: "Outro Bens e Direitos ",
    values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
  },
];
const mockDataAportes = [
  {
    title: "Plano de Amortização - Contribuição Patronal Suplementar ",
    values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
  },
  {
    title: "Plano de Amortização - Aporte Periódico de Valores Predefinidos ",
    values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
  },
  {
    title: "Outros Aportes para o RPPS",
    values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
  },
];

const mockDataAportesReparticao = [
  {
    title: "Recursos para Cobertura de Insuficiências Financeiras ",
    values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
  },
  {
    title: "Recursos para Formação de Reserva ",
    values: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
  },
];

const mockDataDespesasAdmin = [
  {
    sectionTitle: "Despesas Correntes (XIII)",
    sectionInfos: [
      {
        sectionTextInfo: "Pessoal e Encargos Sociais ",
        sectionValuesInfo: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
      },
      {
        sectionTextInfo: "Demais Despesas Correntes",
        sectionValuesInfo: ["1.000.00,00", "1.000.00,00", "1.000.00,00"],
      },
    ],
  },
  {
    sectionTitle: "Despesas de Capital (XIV)",
    sectionInfos: [],
  },
];

const dataHeaderCapitalizacao = [
  "Receitas \nPrevidenciárias \n(a)",
  "Receitas \nPrevidenciárias \n(b)",
  "Resultado \nPrevidenciário \n(c) = (a-b)",
  "Saldo Financeiro \ndo Exercício  \n(d) = (d Exercício Anterior) + (c)",
];

const textNota = [
  "1 Como a Portaria MPS 746/2011 determina que os recursos provenientes desses aportes devem permanecer aplicados, no mínimo, por 5 (cinco) anos, essa receita não deverá compor o total das receitas previdenciárias do período de apuração. ",
  "2 O resultado previdenciário poderá ser apresentada por meio da diferença entre previsão da receita e a dotação da despesa e entre a receita realizada e a despesa liquidada (do 1º ao 5º bimestre) e a despesa empenhada (no 6º bimestre) ",
];

export const RenderPdfDemoAvaliacaoSituacao = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: 200,
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];
  const styledColHeaderCapitalizacao = [
    { flexBasis: 250, flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: "100%",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const renderTextColumn = (text, style = {}) => (
    <Text style={[stylesPdf.textTituloColuna, { fontSize: 8 }, style]}>
      {text}
    </Text>
  );

  const renderTextInfosRelatorio = (text, styled = {}) => {
    return (
      <Text style={[stylesPdf.textInfosRelatorio, { fontSize: 8 }, styled]}>
        {text}
      </Text>
    );
  };

  const renderTextSectionRelatorio = (title) => {
    return (
      <View style={{ width: "100%", alignItems: "center", marginVertical: 5 }}>
        {renderTextInfosRelatorio(title, {
          textTransform: "uppercase",
          paddingHorizontal: 0,
          fontWeight: "bold",
        })}
      </View>
    );
  };

  const renderLineDivider = (styled = {}) => {
    return (
      <View
        style={[
          {
            height: 1,
            width: "100%",
            backgroundColor: "#000",
          },
          styled,
        ]}
      />
    );
  };

  const renderHeader = (title, breakPage = false, styled = {}) => {
    return (
      <View
        style={[
          stylesPdf.divTituloColuna,
          {
            textAlign: "center",
            height: 24,
            alignItems: "center",
            width: "100%",
          },
          styled,
        ]}
        break={breakPage}
      >
        {renderTextColumn(title, { textTransform: "uppercase" })}
      </View>
    );
  };

  const renderHeaderCapitalizacao = () => {
    return (
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
        <View style={[stylesPdf.coluna, styledColHeaderCapitalizacao[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              { textAlign: "center", height: 58 },
            ]}
          >
            {renderTextColumn("exercício", { textTransform: "uppercase" })}
          </View>
        </View>

        <View style={[stylesPdf.coluna, styledColHeaderCapitalizacao[1]]}>
          <View style={[stylesPdf.divTituloColuna, { height: 58 }]}>
            <View
              style={{
                alignItems: "center",
                width: "100%",
                gap: 2,
              }}
            >
              {renderTextColumn(
                "FUNDO EM CAPITALIZAÇÃO (PLANO PREVIDENCIÁRIO) "
              )}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {dataHeaderCapitalizacao.map((item, index) => (
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      width:
                        index === dataHeaderCapitalizacao.length - 1
                          ? "100%"
                          : "80%",
                    }}
                    key={index}
                  >
                    {renderTextColumn(item)}
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderHeaderSection = (title, years = [], styled = {}) => {
    return (
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }, styled]}>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                height: "auto",
                paddingVertical: 2,
                justifyContent: "center",
                paddingLeft: 8,
              },
            ]}
          >
            {renderTextColumn(title, { textTransform: "uppercase" })}
          </View>
        </View>
        {years.map((year, index) => (
          <React.Fragment key={index}>
            <View style={[stylesPdf.coluna, styledCol[1]]}>
              <View
                style={[
                  stylesPdf.divTituloColuna,
                  {
                    height: "auto",
                    paddingVertical: 2,
                    alignItems: "center",
                  },
                ]}
              >
                {renderTextColumn(year)}
              </View>
            </View>
          </React.Fragment>
        ))}
      </View>
    );
  };

  const renderSection = (titulo, sectionBg = true) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            backgroundColor: sectionBg ? "#a6a6a6" : "transparent",
            fontWeight: "bold",
          },
        ]}
      >
        <PdfInfos
          pdfInfo={titulo}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{
            fontSize: 8,
            textTransform: sectionBg ? "uppercase" : "capitalize",
          }}
        />
      </View>
    );
  };

  const renderInfos = (title, values, infosTextBold = false) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
          },
        ]}
      >
        <PdfInfos
          pdfInfo={title}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
              textAlign: "left",
            },
          ]}
          styledText={{
            fontSize: 8,
            fontWeight: infosTextBold ? "bold" : "normal",
          }}
        />
        {values.map((value, index) => (
          <PdfInfos
            key={index}
            pdfInfo={value}
            styledCol={[
              styledCol[1],
              {
                padding: "2px 0",
                textAlign: "center",
              },
            ]}
            styledText={{
              paddingHorizontal: 0,
              fontSize: 8,
              fontWeight: infosTextBold ? "bold" : "normal",
            }}
          />
        ))}
      </View>
    );
  };

  return (
    <Page size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={
          "Avaliação da Situação Financeira e Atuarial do Regime Próprio de Previdência dos Servidores"
        }
        anoRelatorio={currentYear}
        plusText={"Anexo de Metas Fiscais"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: 8,
          padding: "0 4px",
        }}
      >
        <Text>
          AMF - Demonstrativo 6 (LRF, art. 4º, § 2º, inciso IV, alínea “a”)
        </Text>
        <Text>R$ 1,00</Text>
      </View>
      {renderHeader(
        "RECEITAS E DESPESAS PREVIDENCIÁRIAS DO REGIME PRÓPRIO DE PREVIDÊNCIA DOS SERVIDORES - RPPS"
      )}
      {renderTextSectionRelatorio(
        "FUNDO EM CAPITALIZAÇÃO (PLANO PREVIDENCIÁRIO) "
      )}
      {renderHeaderSection(
        "RECEITAS PREVIDENCIÁRIAS - RPPS (FUNDO EM CAPITALIZAÇÃO)",
        dados.contentHeader
      )}
      <View style={stylesPdf.divRelatorio}>
        {renderSection(mockDataReceitas.sectionTitle)}
        {mockDataReceitas.subSection.map((item, index) => (
          <React.Fragment key={index}>
            {renderSection(item.subSectionTitle, false)}
            {item.subSectionData.map((subSection, index) => (
              <React.Fragment key={index}>
                {renderInfos(
                  subSection.subSectionTitleInfo,
                  subSection.subSectionValues
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        {renderSection(mockDataReceitasCapital.sectionTitle)}
        {mockDataReceitasCapital.sectionInfos.map((item, index) => (
          <React.Fragment key={index}>
            {renderInfos(item.title, item.values, true)}
          </React.Fragment>
        ))}
        {renderHeaderSection(
          "TOTAL DAS RECEITAS DO FUNDO EM CAPITALIZAÇÃO - (IV) = (I + III - II)"
        )}

        {renderHeaderSection(
          "DESPESAS PREVIDENCIÁRIAS - RPPS (FUNDO EM CAPITALIZAÇÃO) ",
          dados.contentHeader,
          { marginTop: 10 }
        )}
        {mockDataDespesasPrevidenciarias.map((item, index) => (
          <React.Fragment key={index}>
            {renderSection(item.sectionTitle, false)}
            {item.sectionInfos.map((sectionInfo, index) => (
              <React.Fragment key={index}>
                {renderInfos(
                  sectionInfo.sectionTextInfo,
                  sectionInfo.sectionValuesInfo
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        {renderHeaderSection(
          "TOTAL DAS DESPESAS DO FUNDO EM CAPITALIZAÇÃO (V)"
        )}
        {renderHeaderSection(
          "RESULTADO PREVIDENCIÁRIO - FUNDO EM CAPITALIZAÇÃO (VI) = (IV – V)²",
          [],
          { marginTop: 10 }
        )}
        {renderHeaderSection(
          "RECURSOS RPPS ARRECADADOS EM EXERCÍCIOS ANTERIORES",
          dados.contentHeader,
          { marginTop: 10 }
        )}
        {renderTextInfosRelatorio("valor", {
          textTransform: "uppercase",
          marginVertical: 2,
          fontWeight: "bold",
        })}
        {renderLineDivider()}
        {renderHeaderSection(
          "RESERVA ORÇAMENTÁRIA DO RPPS",
          dados.contentHeader,
          { marginTop: 10 }
        )}
        {renderTextInfosRelatorio("valor", {
          textTransform: "uppercase",
          marginVertical: 2,
          fontWeight: "bold",
        })}
        {renderLineDivider()}
        {renderHeaderSection(
          "APORTES DE RECURSOS PARA O FUNDO EM CAPITALIZAÇÃO DO RPPS ",
          dados.contentHeader,
          { marginTop: 10 }
        )}
        {mockDataAportes.map((item, index) => (
          <React.Fragment key={index}>
            {renderInfos(item.title, item.values, true)}
          </React.Fragment>
        ))}
        {renderLineDivider()}
        {renderHeaderSection(
          "BENS E DIREITOS DO RPPS (FUNDO EM CAPITALIZAÇÃO) ",
          dados.contentHeader,
          { marginTop: 10 }
        )}
        {mockDataBensDireitos.map((item, index) => (
          <React.Fragment key={index}>
            {renderInfos(item.title, item.values, true)}
          </React.Fragment>
        ))}
      </View>
      {renderLineDivider()}

      {renderHeader(
        "RECEITAS E DESPESAS PREVIDENCIÁRIAS DO REGIME PRÓPRIO DE PREVIDÊNCIA DOS SERVIDORES - RPPS",
        true
      )}

      {renderTextSectionRelatorio("FUNDO EM REPARTIÇÃO (PLANO FINANCEIRO)")}
      {renderHeaderSection(
        "RECEITAS PREVIDENCIÁRIAS - RPPS (FUNDO EM REPARTIÇÃO) ",
        dados.contentHeader
      )}
      <View style={stylesPdf.divRelatorio}>
        {renderSection(mockDataReceitasReparticao.sectionTitle)}
        {mockDataReceitasReparticao.subSection.map((item, index) => (
          <React.Fragment key={index}>
            {renderSection(item.subSectionTitle, false)}
            {item.subSectionData.map((subSection, index) => (
              <React.Fragment key={index}>
                {renderInfos(
                  subSection.subSectionTitleInfo,
                  subSection.subSectionValues
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        {renderSection(mockDataReceitasCapitalReparticao.sectionTitle)}
        {mockDataReceitasCapitalReparticao.sectionInfos.map((item, index) => (
          <React.Fragment key={index}>
            {renderInfos(item.title, item.values, true)}
          </React.Fragment>
        ))}
        {renderHeaderSection(
          "TOTAL DAS RECEITAS DO FUNDO EM REPARTIÇÃO (IX) = (VII + VIII)"
        )}

        {renderHeaderSection(
          "DESPESAS PREVIDENCIÁRIAS - RPPS (FUNDO EM REPARTIÇÃO) ",
          dados.contentHeader,
          { marginTop: 10 }
        )}
        {mockDataDespesasPrevidenciarias.map((item, index) => (
          <React.Fragment key={index}>
            {renderSection(item.sectionTitle, false)}
            {item.sectionInfos.map((sectionInfo, index) => (
              <React.Fragment key={index}>
                {renderInfos(
                  sectionInfo.sectionTextInfo,
                  sectionInfo.sectionValuesInfo
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        {renderHeaderSection("TOTAL DAS DESPESAS DO FUNDO EM REPARTIÇÃO (X)")}
        {renderHeaderSection(
          "RESULTADO PREVIDENCIÁRIO - FUNDO EM REPARTIÇÃO (XI) = (IX – X)²",
          [],
          { marginTop: 10 }
        )}
        {renderHeaderSection(
          "APORTES DE RECURSOS PARA O FUNDO EM REPARTIÇÃO DO RPPS ",
          dados.contentHeader,
          { marginTop: 10 }
        )}
        {mockDataAportesReparticao.map((item, index) => (
          <React.Fragment key={index}>
            {renderInfos(item.title, item.values, true)}
          </React.Fragment>
        ))}
        {renderHeaderSection(
          "BENS E DIREITOS DO RPPS (FUNDO EM REPARTIÇÃO)",
          dados.contentHeader,
          { marginTop: 10 }
        )}
        {mockDataBensDireitos.map((item, index) => (
          <React.Fragment key={index}>
            {renderInfos(item.title, item.values, true)}
          </React.Fragment>
        ))}
      </View>
      {renderHeader(
        "ADMINISTRAÇÃO DO REGIME PRÓPRIO DE PREVIDÊNCIA DOS SERVIDORES - RPPS",
        true
      )}

      {renderHeaderSection(
        "RECEITAS DA ADMINISTRAÇÃO - RPPS",
        dados.contentHeader,
        { marginTop: 10 }
      )}
      {renderInfos("Receitas Correntes", ["100,00", "100,00", "100,00"], true)}
      {renderHeaderSection("TOTAL DAS RECEITAS DA ADMINISTRAÇÃO RPPS - (XII)")}

      {renderHeaderSection(
        "DESPESAS DA ADMINISTRAÇÃO - RPPS",
        dados.contentHeader,
        { marginTop: 10 }
      )}

      {mockDataDespesasAdmin.map((item, index) => (
        <React.Fragment key={index}>
          {renderSection(item.sectionTitle, false)}
          {item.sectionInfos.map((info, index) => (
            <React.Fragment key={index}>
              {renderInfos(info.sectionTextInfo, info.sectionValuesInfo)}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}

      {renderHeaderSection(
        "TOTAL DAS DESPESAS DA ADMINISTRAÇÃO RPPS (XV) = (XIII + XIV)"
      )}
      {renderHeaderSection(
        "RESULTADO DA ADMINISTRAÇÃO RPPS (XVI) = (XII – XV)²",
        [],
        { marginTop: 10 }
      )}

      {renderHeaderSection(
        "BENS E DIREITOS DO RPPS - ADMINISTRAÇÃO DO RPPS ",
        dados.contentHeader,
        { marginTop: 10 }
      )}
      {mockDataBensDireitos.map((item, index) => (
        <React.Fragment key={index}>
          {renderInfos(item.title, item.values, true)}
        </React.Fragment>
      ))}
      <renderLineDivider />

      {renderHeader("BENEFÍCIOS PREVIDENCIÁRIOS MANTIDOS PELO TESOURO", true)}

      {renderHeaderSection(
        "RECEITAS PREVIDENCIÁRIAS (BENEFÍCIOS MANTIDOS PELO TESOURO)",
        dados.contentHeader,
        { marginTop: 10 }
      )}
      {renderInfos("Contribuições dos Servidores ", [
        "100,00",
        "100,00",
        "100,00",
      ])}
      {renderInfos("Demais Receitas Previdenciárias", [
        "100,00",
        "100,00",
        "100,00",
      ])}
      {renderHeaderSection(
        "TOTAL DAS RECEITAS (BENEFÍCIOS MANTIDOS PELO TESOURO) (XVII) "
      )}
      {renderHeaderSection(
        "RESULTADO DOS BENEFÍCIOS MANTIDOS PELO TESOURO (XIX) = (XVII - XVIII)²",
        [],
        { marginTop: 10 }
      )}

      {renderHeader(
        "PROJEÇÃO ATUARIAL DO REGIME PRÓPRIO DE PREVIDÊNCIA DOS SERVIDORES",
        true,
        { marginBottom: 10 }
      )}
      {renderHeaderCapitalizacao()}
      {renderLineDivider({ marginTop: 10 })}
      <Text
        style={{
          fontWeight: "bold",
          marginLeft: 8,
          marginTop: 2,
          fontSize: 8,
        }}
      >
        FONTE: Secretaria de Finanças
      </Text>
      <PdfInfos
        pdfInfo={"NOTA:"}
        styledCol={[
          {
            padding: "2px 0",
            textAlign: "left",
          },
        ]}
        styledText={{
          fontSize: 8,
        }}
      />
      {textNota.map((item, index) => (
        <React.Fragment key={index}>
          <PdfInfos
            pdfInfo={item}
            styledCol={[
              {
                padding: "2px 0",
              },
            ]}
            styledText={{
              fontSize: 8,
            }}
          />
        </React.Fragment>
      ))}
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
