export const FormatCep = (input) => {
  console.log("teste");
  const cepClean = input.value.replace(/[^0-9]/g, "");

  let cepFormatado = "";

  for (let i = 0; i < cepClean.length; i++) {
    cepFormatado += cepClean[i];
    if (i === 4) {
      cepFormatado += "-";
    }
  }

  input.value = cepFormatado;
};

export const FormatCepValue = (value) => {
  const cepClean = value.replace(/[^0-9]/g, "");
  console.log(cepClean.length);

  let cepFormatado = "";

  if (cepClean.length > 5) {
    for (let i = 0; i < cepClean.length; i++) {
      cepFormatado += cepClean[i];
      if (i === 4) {
        cepFormatado += "-";
      }
    }
  } else {
    return cepClean;
  }

  return cepFormatado;
};
