import { createContext, useState } from "react";

//1 - Criando Contexto

export const SidebarExpandidaContext = createContext();

//2 - Criando Provider

export const SidebarExpandidaProvider = ({ children }) => {
  const [sidebarExpandida, setSidebarExpandida] = useState(false);

  return (
    <SidebarExpandidaContext.Provider
      value={{ sidebarExpandida, setSidebarExpandida }}
    >
      {children}
    </SidebarExpandidaContext.Provider>
  );
};
