import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import styled from "styled-components";

const DivLoadingComponent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ containerHeight }) => containerHeight && { height: containerHeight }}
`;

const LoadingComponent = ({ size, containerHeight }) => {
  return (
    <DivLoadingComponent containerHeight={containerHeight}>
      <Icon
        icon="line-md:loading-loop"
        height={size}
        style={{ color: "#515c70" }}
      />
    </DivLoadingComponent>
  );
};

export default LoadingComponent;
