import styled from "styled-components";

export const BoxSistemas = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 10px;
`;

export const DivModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  p {
    margin: 0;
  }
`;

export const ButtonModal = styled.button`
  outline: none;
  box-shadow: none;
  border: none;
  padding: 5px;
  width: 70px;
  margin: 10px 5px;
  border-radius: 6px;
  color: white;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "#000")};
  transition: opacity 0.2s;

  :hover {
    opacity: 0.7;
  }
`;
