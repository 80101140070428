import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getDespesaDemonstrativoOrgaoFuncao = async (
  clientes,
  entidadesGestoras,
  ano = 2024
) => {
  const url = getUrl(clientes, entidadesGestoras);

  // TODO: remover o idsUrl quando subir a rota
  try {
    const response = await api.get(
      `qdd/relatorio/agregado/${url.idsUrl}/${ano}?porAcao=1&anexo=1`
    );
    console.log("response.data: ", response.data);
    return formattedAllDespesas(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Anexo IX - Demonstrativo da Despesa por Órgãos e Funções",
    };
  }
};

function sortByCodigoFuncao(data) {
  const dataArray = Object.values(data);

  dataArray.sort((a, b) => {
    return a.codigoFuncao.localeCompare(b.codigoFuncao);
  });
  console.log(dataArray);

  return dataArray;
}

const calcularTotalGeral = (entidades) => {
  const total = entidades.reduce((acc, entidade) => {
    const totalProjetosCliente =
      entidade.infoUnidade.totalGeralUnidade.totalProjetosCliente;
    const totalAtividadesCliente =
      entidade.infoUnidade.totalGeralUnidade.totalAtividadesCliente;
    const totalEspeciaisCliente =
      entidade.infoUnidade.totalGeralUnidade.totalEspeciaisCliente;
    const totalEntidade =
      totalProjetosCliente + totalAtividadesCliente + totalEspeciaisCliente;

    return (acc += totalEntidade);
  }, 0);
  return total;
};

const formattedAllDespesas = (dataQddRelatorioOrgaoUnidade) => {
  const dadosFormatados = Object.keys(dataQddRelatorioOrgaoUnidade).map(
    (key) => {
      const unidade = dataQddRelatorioOrgaoUnidade[key];
      return {
        infoUnidade: {
          idUnidade: unidade.clienteInfo[0].poderOrgao.match(/\d+/g).join('') || "",
          tipoNaturezaJuridica: unidade.clienteInfo[0].tipoNaturezaJuridica,
          unidadeGestora: unidade.clienteInfo[0].pessoaJuridica.nomeFantasia,
          totalGeralUnidade: {
            totalProjetosCliente: unidade.totalProjetosCliente,
            totalAtividadesCliente: unidade.totalAtividadesCliente,
            totalEspeciaisCliente: unidade.totalEspeciaisCliente,
          },
          unidades: sortByCodigoFuncao(unidade.relatorio),
        },
      };
    }
  );

  dadosFormatados.sort((a, b) => {
    const tipoA = parseInt(a.infoUnidade.tipoNaturezaJuridica[0]);
    const tipoB = parseInt(b.infoUnidade.tipoNaturezaJuridica[0]);
    return tipoA - tipoB;
  });
  console.log(dadosFormatados);

  const total = calcularTotalGeral(dadosFormatados);

  return {
    dataDespesa: dadosFormatados,
    total,
  };
};
