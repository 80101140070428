import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { TituloAbaSolo } from "../../../../styles/StyledComponents/LayoutGerais";
import { CCol, CColButtonsAcoes2Icons } from "../../../../components/Grid/CCol";
import { PTitulosInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Icon } from "@iconify/react/dist/iconify.js";
import { especificacoes } from "../Riscos_Fiscais/Especificacoes";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import { RowAninhada } from "../../../../components/Grid/CCol";
//import { ReturnArrayAnos } from "./returnArrayAnos";

const Notas = ({
  arrayEspecificacoesNominal,
  setArrayEspecificacoesNominal,
  valueEspecificacao,
  setValueEspecificacao,
}) => {
  const [especificacao, setEspecificacao] = useState([
    "Receita Administrada pela RFP - Crescimento Real da Atividade Econômica",
  ]);
  const [editModes, setEditModes] = useState([false]);
  const [valores, setValores] = useState([""]);
  const [valorEdicao, setValorEdicao] = useState([""]);

  const adicionarEspecificacao = () => {
    if (valueEspecificacao) {
      setEspecificacao((prevValues) => [...prevValues, valueEspecificacao]);
      setValores((prevValues) => [...prevValues, ""]);
      setEditModes((prevModes) => [...prevModes, false]);
    }
  };

  const toggleEditMode = (index) => {
    setValorEdicao((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = valores[index];
      return newValues;
    });

    setEditModes((prevModes) => {
      const newModes = [...prevModes];
      newModes[index] = !newModes[index];
      return newModes;
    });
  };

  const saveChanges = (index) => {
    setEditModes((prevModes) => {
      const newModes = [...prevModes];
      newModes[index] = false;
      return newModes;
    });
  };

  const cancelEdit = (index) => {
    setEditModes((prevModes) => {
      const newModes = [...prevModes];
      newModes[index] = false;
      return newModes;
    });

    setValores((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = valorEdicao[index];
      return newValues;
    });
  };

  const handleValorChange = (index, value) => {
    setValores((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = value;
      return newValues;
    });
  };

  const removerEspecificacao = (index) => {
    setEspecificacao((prevValues) => {
      const newValues = [...prevValues];
      newValues.splice(index, 1);
      return newValues;
    });
    setValores((prevValues) => {
      const newValues = [...prevValues];
      newValues.splice(index, 1);
      return newValues;
    });
    setEditModes((prevModes) => {
      const newModes = [...prevModes];
      newModes.splice(index, 1);
      return newModes;
    });
  };

  return (
    <>
      <Row className="row_form mt-1">
        <TituloAbaSolo>Notas Explicativas</TituloAbaSolo>
      </Row>
      <Row
        className="row_form mt-1"
        style={
          arrayEspecificacoesNominal.length === 0
            ? { borderRadius: "0px 0px 5px 5px" }
            : {}
        }
      >
        <CCol>
          <FloatingLabelInput
            placeholder="Demonstrativos"
            new={true}
            label={"Demonstrativos"}
            tipo="input_select"
            options={[...especificacoes]}
            onSelect={(option) => setValueEspecificacao(option.value)}
            value={valueEspecificacao}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar onClick={adicionarEspecificacao} />
        </CColButtonsAcoes2Icons>
      </Row>
      {especificacao.map((value, index) => {
        const isEditing = editModes[index];
        return (
          <>
            <React.Fragment key={index}>
              <Row className="row_form mt-1">
                <CCol>
                  <PTitulosInputs style={{ marginTop: "0px" }}>
                    Especificação
                  </PTitulosInputs>
                  <FloatingLabelInput
                    disabled
                    value={especificacao[index]}
                    style={{ border: "none" }}
                    height={"30px"}
                  />
                </CCol>
                <CColButtonsAcoes2Icons md={1}>
                  <Icon
                    icon="ic:baseline-delete"
                    color="#E79900"
                    height="28"
                    style={{
                      cursor: "pointer",
                      marginLeft: "2px",
                      marginTop: "10px",
                    }}
                    onClick={() => removerEspecificacao(index)}
                  />
                </CColButtonsAcoes2Icons>
                <RowAninhada style={{ paddingTop: "5px" }}>
                  <CCol>
                    <PTitulosInputs style={{ marginTop: "8px" }}>
                      Valor
                    </PTitulosInputs>
                    <div style={{ position: "relative" }}>
                      <FloatingLabelInput
                        style={{
                          width: "100%",
                          borderColor: "#ccc",
                          borderRadius: "5px",
                          minHeight: "80px",
                          paddingRight: "30px",
                          paddingLeft: "5px",
                        }}
                        as="textarea"
                        tipo="text_area"
                        overflowY={"auto"}
                        placeholder={"Descrição"}
                        disabled={!isEditing}
                        value={valores[index]}
                        onChange={(e) =>
                          handleValorChange(index, e.target.value)
                        }
                      />
                      <Icon
                        icon="heroicons:microphone-solid"
                        color="#b9b9b9"
                        height="24"
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "12px",
                          cursor: "pointer",
                        }}
                        onClick={() => console.log("Mic!")}
                      />
                    </div>
                  </CCol>
                  <CColButtonsAcoes2Icons md={1} style={{ marginTop: "17px" }}>
                    <PTitulosInputs>Ação</PTitulosInputs>
                    {isEditing ? (
                      <>
                        <Icon
                          icon="fluent:save-24-filled"
                          color="#008CFF"
                          height="26"
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            marginTop: "3px",
                          }}
                          onClick={() => saveChanges(index)}
                        />
                        <Icon
                          icon="ic:baseline-cancel"
                          height="26"
                          color="#F24E1E"
                          style={{
                            cursor: "pointer",
                            marginLeft: "2px",
                            marginRight: "-5px",
                            marginTop: "3px",
                          }}
                          onClick={() => cancelEdit(index)}
                        />
                      </>
                    ) : (
                      <Icon
                        icon="bx:edit"
                        color="#5971C8"
                        height="26"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "3px",
                        }}
                        onClick={() => toggleEditMode(index)}
                      />
                    )}
                  </CColButtonsAcoes2Icons>
                </RowAninhada>
              </Row>
            </React.Fragment>
          </>
        );
      })}
    </>
  );
};

export default Notas;
