import * as RS from "./stylesVisualizacaoRelatorio"
// import * as RS from "../../../../../styles/StyledComponents/StylesRelatorios"

export const ColunasRelatorio = ({colName, textBar='', styledCol}) => {
  return (
    <RS.Coluna style={styledCol}>
      <RS.DivTituloColuna>
        <h6>{colName}</h6>
      </RS.DivTituloColuna>
      <RS.SubDivColunas>
        {textBar && <RS.TextBar >{textBar}</RS.TextBar>}
      </RS.SubDivColunas>
    </RS.Coluna>
  )
}