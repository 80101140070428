import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { PdfInfos } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";

export const RenderPdfDemoMargenExpansao = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    {
      flexBasis: "100%",
      flexShrink: 1,
      flexGrow: 1,
    },
  ];

  const renderTextColumn = (text, style = {}) => (
    <Text
      style={[
        stylesPdf.textTituloColuna,
        { textTransform: "uppercase", fontSize: 8 },
        style,
      ]}
    >
      {text}
    </Text>
  );
  const renderHeader = () => {
    return (
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                height: "auto",
                paddingVertical: 2,
                justifyContent: "flex-end",
              },
            ]}
          >
            {renderTextColumn("EVENTOS")}
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                height: "auto",
                paddingVertical: 2,
                justifyContent: "flex-end",
              },
            ]}
          >
            {renderTextColumn(`Valor Previsto para ${dados.contentHeader}`, {
              textTransform: "capitalize",
            })}
          </View>
        </View>
      </View>
    );
  };

  const renderHeaderSection = (title, styled) => {
    return (
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }, styled]}>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                height: "auto",
                paddingVertical: 2,
                paddingLeft: 4,
              },
            ]}
          >
            {renderTextColumn(title)}
          </View>
        </View>
      </View>
    );
  };

  const renderInfos = (title, styled = {}) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            fontWeight: "bold",
          },
          styled,
        ]}
      >
        <PdfInfos
          pdfInfo={title}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
              textAlign: "left",
            },
          ]}
          styledText={{ fontSize: 8 }}
        />
      </View>
    );
  };

  const renderSection = (title, sectionColor = false, textBold = true) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            backgroundColor: sectionColor ? "#a6a6a6" : "transparent",
            fontWeight: textBold ? "bold" : "normal",
          },
        ]}
      >
        <PdfInfos
          pdfInfo={title}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{ fontSize: 8, textAlign: "left", paddingHorizontal: 4 }}
        />
      </View>
    );
  };

  return (
    <Page size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={
          "Margem de Expansão das Despesas Obrigatórias de Caráter Continuado"
        }
        anoRelatorio={currentYear}
        plusText={"Anexo de Metas Fiscais"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: 8,
          padding: "0 4px",
        }}
      >
        <Text>AMF - Demonstrativo 8 (LRF, art. 4º, § 2º, inciso V)</Text>
        <Text>R$ 1,00</Text>
      </View>

      {renderHeader(true)}

      {renderSection("Aumento Permanente da Receita")}
      {renderInfos("(-) Transferências Constitucionais", {
        fontWeight: "normal",
      })}
      {renderInfos("(-) Transferências ao Fundeb", { fontWeight: "normal" })}
      {renderSection("Saldo Final do Aumento Permanente de Receita (I) ", true)}
      <View style={{ marginTop: 10 }}>
        {renderSection("Redução Permanente de Despesa (II) ", true)}
      </View>

      {renderHeaderSection("Margem Bruta (III) = (I+II) ", { marginTop: 10 })}

      {renderSection("Saldo Utilizado da Margem Bruta (IV)")}
      {renderInfos("Novas DOCC", {
        fontWeight: "normal",
      })}
      {renderInfos("Novas DOCC geradas por PPP", { fontWeight: "normal" })}
      {renderHeaderSection(
        "Margem Líquida de Expansão de DOCC (V) = (III-IV) ",
        { marginTop: 10 }
      )}

      <Text
        style={{ fontWeight: "bold", marginLeft: 4, marginTop: 2, fontSize: 8 }}
      >
        FONTE: Secretaria de Finanças
      </Text>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
