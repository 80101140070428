import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { FormControlListaDeInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import { SpanStyledInfo } from "../../../../styles/StyledComponents/ListaDeInputs";
import { Icon } from "@iconify/react/dist/iconify.js";
import styled from "styled-components";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";

export const StyledCCol = styled(CCol)`
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ScrollableSection = ({ rowsInfos = [], inputs = [], setInputs }) => {
  const [editedInputs, setEditedInputs] = useState(() =>
    Array.isArray(inputs) ? inputs.map((row) => ({ ...row })) : []
  );
  const [editMode, setEditMode] = useState(() =>
    Array.isArray(inputs)
      ? Array.from({ length: inputs.length }, () => false)
      : []
  );

  const toggleEditMode = (rowIndex) => {
    const newEditMode = [...editMode];
    newEditMode[rowIndex] = !editMode[rowIndex];
    setEditMode(newEditMode);
  };

  const handleInputChange = (rowIndex, fieldName, value) => {
    const newEditedInputs = [...editedInputs];
    newEditedInputs[rowIndex] = {
      ...newEditedInputs[rowIndex],
      [fieldName]: value,
    };
    setEditedInputs(newEditedInputs);
  };

  const handleSaveChanges = (rowIndex) => {
    const newInputs = [...inputs];
    newInputs[rowIndex] = editedInputs[rowIndex];
    setInputs(newInputs);

    const newEditMode = [...editMode];
    newEditMode[rowIndex] = false;
    setEditMode(newEditMode);
  };

  const handleCancelEdit = (rowIndex) => {
    const newEditMode = [...editMode];
    newEditMode[rowIndex] = false;
    setEditMode(newEditMode);

    const newEditedInputs = [...editedInputs];
    newEditedInputs[rowIndex] = inputs[rowIndex];
    setEditedInputs(newEditedInputs);
  };

  if (!Array.isArray(inputs) || inputs.length === 0) {
    return <p>Nenhum dado disponível</p>;
  }

  return (
    <>
      <Row className="row_form mt-1" style={{ height: "400px" }}>
        <StyledCCol>
          {inputs.map((row, rowIndex) => (
            <RowAninhada
              key={rowIndex}
              style={{
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              {Object.keys(row).map((fieldName, colIndex) => (
                <CCol
                  key={colIndex}
                  md={2}
                  style={{ display: "inline-block", minWidth: "150px" }}
                >
                  {rowIndex === 0 && rowsInfos[colIndex] && (
                    <SpanStyledInfo
                      style={{
                        flexGrow: 1,
                        display: "block",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                        overflow: "visible",
                      }}
                    >
                      {rowsInfos[colIndex].title}
                    </SpanStyledInfo>
                  )}
                  {rowsInfos[colIndex]?.type === "money" ? (
                    <InputFormatRealBrasileiro
                      externalValue={
                        editedInputs[rowIndex][fieldName] > 0
                          ? editedInputs[rowIndex][fieldName]
                          : "0,00"
                      }
                      disabled={!editMode[rowIndex]}
                      onChange={(e, valor) => {
                        const valorNumerico = parseFloat(
                          valor.replace(/,/g, ".")
                        );
                        handleInputChange(rowIndex, fieldName, valorNumerico);
                      }}
                      style={{
                        height: "30px",
                        marginBottom:
                          rowIndex === inputs.length - 1 ? "10px" : "0",
                      }}
                      radiusborder={
                        rowIndex === 0
                          ? "5px 5px 0 0"
                          : rowIndex === inputs.length - 1
                          ? "0 0 5px 5px"
                          : "0"
                      }
                    />
                  ) : (
                    <FormControlListaDeInputs
                      value={editedInputs[rowIndex][fieldName] || ""}
                      disabled={!editMode[rowIndex]}
                      onChange={(e) =>
                        handleInputChange(rowIndex, fieldName, e.target.value)
                      }
                      style={{
                        height: "30px",
                        marginBottom:
                          rowIndex === inputs.length - 1 ? "10px" : "0",
                      }}
                      radiusborder={
                        rowIndex === 0
                          ? "5px 5px 0 0"
                          : rowIndex === inputs.length - 1
                          ? "0 0 5px 5px"
                          : "0"
                      }
                    />
                  )}
                </CCol>
              ))}
            </RowAninhada>
          ))}
        </StyledCCol>
        <CColButtonsAcoes2Icons md={1}>
          {inputs.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              {rowIndex === 0 && (
                <SpanStyledInfo
                  style={{
                    flexGrow: 1,
                    display: "block",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    overflow: "visible",
                  }}
                >
                  Ação
                </SpanStyledInfo>
              )}
              {!editMode[rowIndex] ? (
                <Icon
                  icon="bx:edit"
                  color="#5971C8"
                  height="26"
                  onClick={() => toggleEditMode(rowIndex)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: rowIndex !== 0 ? "4px" : "2px",
                  }}
                />
              ) : (
                <>
                  <Icon
                    icon="fluent:save-24-filled"
                    height="26"
                    color="#008CFF"
                    onClick={() => handleSaveChanges(rowIndex)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginTop: rowIndex !== 0 ? "4px" : "2px",
                    }}
                  />
                  <Icon
                    icon="ic:baseline-cancel"
                    height="26"
                    color="#F24E1E"
                    onClick={() => handleCancelEdit(rowIndex)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2px",
                      marginTop: rowIndex !== 0 ? "4px" : "2px",
                    }}
                  />
                </>
              )}
              {!editMode[rowIndex] && (
                <Icon
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="26"
                  onClick={() => console.log("Delete clicked")}
                  style={{
                    cursor: "pointer",
                    marginLeft: "2px",
                    marginTop: rowIndex !== 0 ? "4px" : "2px",
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </CColButtonsAcoes2Icons>
      </Row>
    </>
  );
};

export default ScrollableSection;
