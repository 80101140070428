import { useContext } from "react";
import { CurrentClientContext } from "../contexts/CurrentClientContext";

export const useCurrentClient = () => {
  const context = useContext(CurrentClientContext);

  if (!context) {
    throw new Error(
      "CurrentClientContext deve ser usado dentro de um CurrentClientProvider"
    );
  }

  return context;
};
