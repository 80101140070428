import React from "react";
import {
  ButtonExit,
  UserSubMenuContainer,
  UserSubMenuContent,
  UserSubMenuLink,
} from "../Header/StylesHeader";
import { Icon } from "@iconify/react";
//import ZoomControls from "./ZoomControls";
import { useLocation, useNavigate } from "react-router-dom";

function SubMenuPerfil() {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const navigate = useNavigate();

  const handleFullscreen = () => {
    const elem = document.documentElement;

    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    console.log(path);
  };

  const handleFinishSession = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <UserSubMenuContainer>
      {/*<ZoomControls />*/}
      <UserSubMenuContent>
        <li>
          <UserSubMenuLink to={`#`}>
            {/* icon="mdi:user" */}
            <Icon icon="solar:moon-bold" color="#515c70" width="20" />
            Dark
          </UserSubMenuLink>
        </li>
        <li>
          <UserSubMenuLink onClick={() => handleFullscreen()}>
            <Icon
              icon={
                !document.fullscreenElement
                  ? "ic:baseline-fullscreen"
                  : "ic:baseline-fullscreen-exit"
              }
              color="#515c70"
              width="25.5"
              style={{ marginLeft: "-3px", marginRight: "7px" }}
            />
            Tela Cheia
          </UserSubMenuLink>
        </li>
        <li>
          <UserSubMenuLink to={`#`}>
            {/* icon="material-symbols:settings" */}
            <Icon icon="fa6-solid:user-gear" color="#515c70" width="20" />
            Cliente
          </UserSubMenuLink>
        </li>
        <li>
          <UserSubMenuLink
            to={`/${
              path === "home-test" ? "acesso-interno" : path
            }/usuario/meu-perfil`}
          >
            {/* icon="mdi:user" */}
            <Icon icon="mage:scan-user-fill" color="#515c70" width="20" />
            Usuário
          </UserSubMenuLink>
        </li>
        <li>
          {/* ic:sharp-question-mark */}
          <UserSubMenuLink to={"/"}>
            <Icon
              icon="material-symbols:support-agent-sharp"
              color="#515c70"
              width="20"
            />
            Suporte
          </UserSubMenuLink>
        </li>
        <li>
          {/* mdi:shutdown */}
          <ButtonExit onClick={handleFinishSession}>
            <Icon
              icon="el:off"
              color="#515c70"
              width="16"
              style={{ margin: "0 10.8px 0 3px" }}
            />
            Sair
          </ButtonExit>
        </li>
      </UserSubMenuContent>
    </UserSubMenuContainer>
  );
}

export default SubMenuPerfil;
