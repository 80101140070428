export const FormatValueToLocaleString = (numero) => {
  // Configuração de opções para o formato brasileiro
  const float = parseFloat(numero);

  const opcoes = {
    style: "decimal",
    maximumFractionDigits: 2, // Número máximo de casas decimais
    minimumFractionDigits: 2, // Número mínimo de casas decimais
    useGrouping: true, // Usar separador de milhares
  };

  if (!numero) return "0,00";

  // Formata o número de acordo com as opções
  return float.toLocaleString("pt-BR", opcoes);
};
