import React from "react";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { InputDateRelatorio } from "../../../../components/Inputs/InputDateRelatorio";

const Geral = () => {
  return (
    <>
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={3}>
            <FloatingLabelInput
              label={"Número Contrato"}
              placeholder={"Número Contrato"}
            />
          </CCol>
          <CCol>
            <InputDateRelatorio
              label={"Início Vigência"}
              placeholder={"Início Vigência"}
            />
          </CCol>
          <CCol>
            <InputDateRelatorio
              label={"Fim Vigência"}
              placeholder={"Fim Vigência"}
            />
          </CCol>
          <CCol>
            <InputDateRelatorio
              label={"Assinatura"}
              placeholder={"Assinatura"}
            />
          </CCol>
          <CCol>
            <InputDateRelatorio
              label={"Publicação"}
              placeholder={"Publicação"}
            />
          </CCol>
        </RowAninhada>
      </Row>
    </>
  );
};

export default Geral;
