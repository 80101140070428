export const accountCategories = [
  {
    label:
      "1.1.3.8.1.00.00 - OUTROS CRÉDITOS A RECEBER E VALORES A CURTO PRAZO - CONSOLIDAÇÃO",
    value: 1,
    code: "1.1.3.8.1.00.00",
    highlightOptionPrevious: false,
    subOptions: [
      {
        label:
          "1.1.3.8.1.03.00 - VALORES A RECUPERAR DECORRENTES DE INDENIZAÇÕES POR SINISTROS PAGOS",
        value: 1,
        code: "1.1.3.8.1.03.00",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "862",
            value: 1,
            label: "Recursos de Depósitos de Terceiros",
          },
        ],
      },
      {
        label:
          "1.1.3.8.1.06.00 - VALORES EM TRÂNSITO REALIZÁVEIS A CURTO PRAZO",
        value: 2,
        code: "1.1.3.8.1.06.00",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "862",
            value: 1,
            label: "Recursos de Depósitos de Terceiros",
          },
        ],
      },
      {
        label:
          "1.1.3.8.1.08.00 - CRÉDITOS A RECEBER POR REEMBOLSO DE SALÁRIO FAMÍLIA PAGO",
        value: 3,
        code: "1.1.3.8.1.08.00",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label:
          "1.1.3.8.1.09.00 - CRÉDITOS A RECEBER POR REEMBOLSO DE SALÁRIO MATERNIDADE PAGO",
        value: 4,
        code: "1.1.3.8.1.09.00",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "1.1.3.8.1.10.00 - AUXÍLIO NATALIDADE PAGO A RECUPERAR",
        value: 5,
        code: "1.1.3.8.1.10.00",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label:
          "1.1.3.8.1.11.00 - CRÉDITOS A RECEBER POR REEMBOLSO DE AUXÍLIO DOENÇA E ACIDENTES PAGOS",
        value: 6,
        code: "1.1.3.8.1.11.00",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label:
          "1.1.3.8.1.99.00 - OUTROS CRÉDITOS A RECEBER E VALORES DE CURTO PRAZO",
        value: 7,
        code: "1.1.3.8.1.99.00",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "862",
            value: 1,
            label: "Recursos de Depósitos de Terceiros",
          },
        ],
      },
    ],
  },
  {
    label: "2.1.2.1.1.02.00 - EMPRÉSTIMOS INTERNOS - EM CONTRATOS",
    value: 2,
    code: "2.1.2.1.1.02.00",
    highlightOptionPrevious: false,
    subOptions: [
      {
        label: "2.1.2.1.1.02.05 - ANTECIPAÇÃO DA RECEITA ORÇAMENTÁRIA",
        value: 1,
        code: "2.1.2.1.1.02.05",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "862",
            value: 1,
            label: "Recursos de Depósitos de Terceiros",
          },
        ],
      },
    ],
  },
  {
    label: "2.1.8.8.1.00.00 - VALORES RESTITUÍVEIS - CONSOLIDAÇÃO",
    value: 3,
    code: "2.1.8.8.1.00.00",
    highlightOptionPrevious: false,
    subOptions: [
      {
        label: "2.1.8.8.1.01.03 - ENCARGOS SOCIAIS - OUTRAS ENTIDADES",
        value: 1,
        code: "2.1.8.8.1.01.03",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.1.01.10 - PENSÃO ALIMENTÍCIA",
        value: 2,
        code: "2.1.8.8.1.01.10",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label:
          "2.1.8.8.1.01.13 - RETENÇÕES - ENTIDADES REPRESENTATIVAS DE CLASSES",
        value: 3,
        code: "2.1.8.8.1.01.13",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.1.01.14 - RETENÇÕES - PLANOS DE SEGUROS",
        value: 4,
        code: "2.1.8.8.1.01.14",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.1.01.15 - RETENÇÕES - EMPRÉSTIMOS E FINANCIAMENTOS",
        value: 5,
        code: "2.1.8.8.1.01.15",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.1.01.16 - RETENÇÃO RELATIVA A VALE TRANSPORTE",
        value: 6,
        code: "2.1.8.8.1.01.16",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.1.01.17 - RETENÇÃO RELATIVA A VALE ALIMENTAÇÃO",
        value: 7,
        code: "2.1.8.8.1.01.17",

        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.1.01.99 - OUTROS CONSIGNATARIOS",
        value: 8,
        code: "2.1.8.8.1.01.99",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
    ],
  },
  {
    label: "2.1.8.8.1.01.00 - CONSIGNAÇÕES",
    value: 4,
    code: "2.1.8.8.1.01.00",
    highlightOptionPrevious: true,
    subOptions: [
      {
        label: "2.1.8.8.1.01.03 - ENCARGOS SOCIAIS - OUTRAS ENTIDADES",
        value: 1,
        code: "2.1.8.8.1.01.03",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.1.01.10 - PENSÃO ALIMENTÍCIA",
        value: 2,
        code: "2.1.8.8.1.01.10",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label:
          "2.1.8.8.1.01.13 - RETENÇÕES - ENTIDADES REPRESENTATIVAS DE CLASSES",
        value: 3,
        code: "2.1.8.8.1.01.13",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.1.01.14 - RETENÇÕES - PLANOS DE SEGUROS",
        value: 4,
        code: "2.1.8.8.1.01.14",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.1.01.15 - RETENÇÕES - EMPRÉSTIMOS E FINANCIAMENTOS",
        value: 5,
        code: "2.1.8.8.1.01.15",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.1.01.16 - RETENÇÃO RELATIVA A VALE TRANSPORTE",
        value: 6,
        code: "2.1.8.8.1.01.16",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.1.01.17 - RETENÇÃO RELATIVA A VALE ALIMENTAÇÃO",
        value: 7,
        code: "2.1.8.8.1.01.17",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.1.01.99 - OUTROS CONSIGNATARIOS",
        value: 8,
        code: "2.1.8.8.1.01.99",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
    ],
  },
  {
    label: "2.1.8.8.2.00.00 - VALORES RESTITUÍVEIS - INTRA OFSS",
    value: 5,
    code: "2.1.8.8.2.00.00",
    highlightOptionPrevious: false,
    subOptions: [
      {
        label:
          "2.1.8.8.2.01.01 - RPPS - RETENÇÕES SOBRE VENCIMENTOS E VANTAGENS",
        value: 1,
        code: "2.1.8.8.2.01.01",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.2.01.04 - IMPOSTO SOBRE A RENDA RETIDO NA FONTE - IRRF",
        value: 2,
        code: "2.1.8.8.2.01.04",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.2.01.08 - ISS",
        value: 3,
        code: "2.1.8.8.2.01.08",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.2.01.99 - OUTRAS CONSIGNAÇÕES",
        value: 4,
        code: "2.1.8.8.2.01.99",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
    ],
  },
  {
    label: "2.1.8.8.2.01.00 - CONSIGNAÇÕES",
    value: 6,
    code: "2.1.8.8.2.01.00",
    highlightOptionPrevious: true,
    subOptions: [
      {
        label:
          "2.1.8.8.2.01.01 - RPPS - RETENÇÕES SOBRE VENCIMENTOS E VANTAGENS",
        value: 1,
        code: "2.1.8.8.2.01.01",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.2.01.04 - IMPOSTO SOBRE A RENDA RETIDO NA FONTE - IRRF",
        value: 2,
        code: "2.1.8.8.2.01.04",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.2.01.08 - ISS",
        value: 3,
        code: "2.1.8.8.2.01.08",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
      {
        label: "2.1.8.8.2.01.99 - OUTRAS CONSIGNAÇÕES",
        value: 4,
        code: "2.1.8.8.2.01.99",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
    ],
  },
  {
    label: "2.1.8.8.3.00.00 - VALORES RESTITUÍVEIS - INTER OFSS - UNIÃO",
    value: 7,
    code: "2.1.8.8.3.00.00",
    highlightOptionPrevious: false,
    subOptions: [
      {
        label: "2.1.8.8.3.01.02 - CONTRIBUIÇÃO AO RGPS",
        value: 1,
        code: "2.1.8.8.3.01.02",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
    ],
  },
  {
    label: "2.1.8.8.3.01.00 - CONSIGNAÇÕES",
    value: 8,
    code: "2.1.8.8.3.01.00",
    highlightOptionPrevious: true,
    subOptions: [
      {
        label: "2.1.8.8.3.01.02 - CONTRIBUIÇÃO AO RGPS",
        value: 1,
        code: "2.1.8.8.3.01.02",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "869",
            value: 1,
            label: "Outros Recursos Extraorçamentários",
          },
        ],
      },
    ],
  },
  {
    label: "2.1.8.8.1.04.00 - DEPÓSITOS NÃO JUDICIAIS",
    value: 9,
    code: "2.1.8.8.1.04.00",
    highlightOptionPrevious: false,
    subOptions: [
      {
        label: "2.1.8.8.1.04.01 - DEPÓSITOS E CAUÇÕES",
        value: 1,
        code: "2.1.8.8.1.04.01",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "862",
            value: 1,
            label: "Recursos de Depósitos de Terceiros",
          },
        ],
      },
      {
        label: "2.1.8.8.1.04.99 - OUTROS DEPOSITOS",
        value: 2,
        code: "2.1.8.8.1.04.99",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "862",
            value: 1,
            label: "Recursos de Depósitos de Terceiros",
          },
        ],
      },
    ],
  },
  {
    label: "2.1.8.8.1.99.00 - OUTROS VALORES RESTITUÍVEIS",
    value: 10,
    highlightOptionPrevious: false,
    subOptions: [
      {
        label: "",
        value: 0,
        code: "",
        fontesDeRecursos: [
          {
            exercicio: "1",
            codigo: "862",
            value: 1,
            label: "Recursos de Depósitos de Terceiros",
          },
        ],
      },
    ],
  },
];

export const indexItemsApplyBgPreviousOptions = accountCategories
  .map((item, index) => {
    if (item.highlightOptionPrevious) return index;

    return "";
  })
  .filter((item) => item);
