import React, { useContext, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../components/HeaderCadastros/HeaderCadastros";
import RowSelecaoAbasInferior from "../../../components/Grid/RowSelecaoAbasInferior";
import { CColImgUsuarioPagConta } from "./PagCadastroDeUsuario";
import { LabelSelectFileImgUser } from "../../../styles/StyledComponents/InputsPersonalizados";
import { CCol, RowAninhada } from "../../../components/Grid/CCol";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";
import InputEmail from "../../../components/Inputs/InputEmail";
import { AuthContext } from "../../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { FormatCpfPaste } from "../../../utils/FormatacaoDeDados/FormtCpfPaste";

const PerfilDoUsuario = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Configurações",
    numeroAba: 0,
  });
  const { userAuthData } = useContext(AuthContext);
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const navigate = useNavigate();

  //   const debug = () => {
  //     console.log(userAuthData);
  //   };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Meu Perfil"}
        PaginaSubtitulo={"Informações"}
        BotaoNovoFunction={() => navigate(`/${path}/usuario/cadastro`)}
      />
      {/* <button onClick={debug}>debug</button> */}

      <Row className="row_form mt-1">
        <CColImgUsuarioPagConta
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LabelSelectFileImgUser>
            <input id="imgUser" type="file" accept="image/*" />
            <div>
              <img src={userAuthData.fotoPerfil} alt="Foto do Usuário" />
            </div>
          </LabelSelectFileImgUser>
        </CColImgUsuarioPagConta>

        <CCol>
          <RowAninhada>
            <CCol>
              <FloatingLabelInput
                label={"Nome - CPF"}
                disabled
                value={`${userAuthData.nome} - ${FormatCpfPaste(
                  userAuthData.cpf
                )}`}
              />
            </CCol>
          </RowAninhada>
          <RowAninhada style={{ marginTop: "16px" }}>
            <CCol>
              <InputEmail
                label={"@ E-mail"}
                placeholder="@ E-mail"
                value={userAuthData.email}
                disabled
              />
            </CCol>
            <CCol md={3}>
              <Button
                style={{ padding: "0px", height: "100%", width: "100%" }}
                variant="success"
              >
                Gerar Nova Senha
              </Button>
            </CCol>
          </RowAninhada>
        </CCol>
      </Row>

      <RowSelecaoAbasInferior
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
        abas={["Configurações"]}
      />
    </Container>
  );
};

export default PerfilDoUsuario;
