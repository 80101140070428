import React, { forwardRef } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";

export const InputFileStyled = styled.input`
  display: none;

  &:focus + label,
  & + label:hover {
  }

  & + label {
    /* "hand" cursor */
  }

  & + label span {
  }
`;

const LabelInputFile = styled.label`
  height: ${({ $height }) => ($height ? $height : "38px")};
  font-weight: 500;
  padding-left: 12px;
  text-align: center;
  color: #515c70;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
  transition: all 0.2s;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: rgba(228, 247, 253, 0.4);
  }

  span {
    padding-left: 30px;
  }
`;

const InputFile = forwardRef(({ $height, arquivo, ...props }, inputRef) => {
  return (
    <>
      <LabelInputFile style={props.style} $height={$height}>
        <Icon
          style={{ marginRight: "10px", position: "absolute" }}
          icon="ic:outline-cloud-upload"
          color="#515c70"
          width="20"
        />
        <InputFileStyled ref={inputRef} type="file" {...props} />
        <span>{arquivo ? arquivo : props.placeholder}</span>
      </LabelInputFile>
    </>
  );
});

export default InputFile;
