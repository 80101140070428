import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../../components/Grid/InputSvg";
import { ToastContainer, toast } from "react-toastify";
import { DivAction } from "../../../../components/TableConsulta/TableConsulta";
import { NavLink } from "react-router-dom";
import api from "../../../../utils/api";
import defaultImage from "./ImgsObjetivosDoMilenio/Default_Image.png";

function ConsultaObjetivosDoMilenio() {
  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itensFiltrados, setItensFiltrados] = useState([]);
  const [loadingLista, setLoadingLista] = useState(true);
  const [imgUrls, setImgUrls] = useState({});

  axios.defaults.headers.common["Authorization"] = null;

  const fetchData = () => {
    setLoadingLista(true);
    api
      .get("objetivoDoMilenio")
      .then((response) => {
        console.log(response.data);
        setItens(response.data);
        setLoadingLista(false);
      })
      .catch((error) =>
        toast.error(
          "Algo deu errado ao carregar a lista. Por favor, tente mais tarde"
        )
      );
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const loadImages = async () => {
      const urls = {};
      for (const item of itens) {
        if (item.img) {
          const response = await fetch(item.img);
          const blob = await response.blob();
          urls[item.codigo] = URL.createObjectURL(blob);
        }
      }
      setImgUrls(urls);
    };

    if (itens.length > 0) {
      loadImages();
    }
  }, [itens]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = itens.filter((item) => {
      return (
        item.descricao.toLowerCase().includes(value.toLowerCase()) ||
        item.codigo.includes(value)
      );
    });
    setItensFiltrados(filteredItems);
    setCurrentPage(0);
  };

  //   const deleteCliente = (id) => {
  //     axios
  //       .delete(`https://api.savest.com.br/cliente/${id}`)
  //       .then(() => {
  //         toast.success("Cliente deletado com sucesso!");
  //         fetchData();
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         if (error.response.status === 400) {
  //           toast.error(error.response.data.message);
  //         } else {
  //           toast.error("Algo deu errado. Por favor, tente mais tarde.");
  //         }
  //       });
  //   };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <ToastContainer />
      <HeaderOptions
        withRow={true}
        botaoNovo={true}
        TituloPagina={"Objetivos da ONU"}
        SubTituloPagina={"Consulta"}
        PaginaRelatorio={"/planejamento/cadastro/objetivos-da-onu/relatorio"}
        ToPag={"/planejamento/cadastro/objetivos-da-onu"}
        DashboardPage={"/planejamento/cadastro/objetivos-da-onu/dashboard"}
      />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loadingLista ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loadingLista ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Logo</TituloTable>
              <TituloTable>Código</TituloTable>
              <TituloTable>De-Para</TituloTable>
              <TituloTable>Descrição</TituloTable>
              <TituloTable>Objetivo</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                    >
                      <img
                        src={
                          imgUrls[item.codigo]
                            ? imgUrls[item.codigo]
                            : defaultImage
                        }
                        alt={`logo do objetivo do milenio: ${item.descricao}`}
                        width={50}
                      />
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                    >
                      {item.codigo}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                    >
                      {item.dePara}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                      title={item.descricao}
                    >
                      {item.descricao.length > 40
                        ? item.descricao.substring(0, 40) + "..."
                        : item.descricao}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                      title={item.objetivo}
                    >
                      {item.objetivo.length > 40
                        ? item.objetivo.substring(0, 40) + "..."
                        : item.objetivo}
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      <DivAction>
                        <NavLink
                          to={`/planejamento/cadastro/objetivos-da-onu/visualizar/${item.id}`}
                        >
                          <Icon
                            icon="bx:edit"
                            color="#5971C8"
                            height="22"
                            style={{
                              marginBottom: "8px",
                              marginTop: "-6px",
                              marginRight: "9px",
                            }}
                          />
                        </NavLink>
                      </DivAction>
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                    >
                      <img
                        src={
                          imgUrls[item.codigo]
                            ? imgUrls[item.codigo]
                            : defaultImage
                        }
                        alt={`logo do objetivo do milenio: ${item.descricao}`}
                        width={50}
                      />
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                    >
                      {item.codigo}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                    >
                      {item.dePara}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                      title={item.descricao}
                    >
                      {item.descricao.length > 40
                        ? item.descricao.substring(0, 40) + "..."
                        : item.descricao}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                      title={item.objetivo}
                    >
                      {item.objetivo.length > 40
                        ? item.objetivo.substring(0, 40) + "..."
                        : item.objetivo}
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      <DivAction>
                        <NavLink
                          to={`/planejamento/cadastro/objetivos-da-onu/visualizar/${item.codigo}`}
                        >
                          <Icon
                            icon="bx:edit"
                            color="#5971C8"
                            height="22"
                            style={{
                              marginBottom: "8px",
                              marginTop: "-6px",
                              marginRight: "9px",
                            }}
                          />
                        </NavLink>
                      </DivAction>
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConsultaObjetivosDoMilenio;
