import React from "react";
import { Row, Table } from "react-bootstrap";
import styled from "styled-components";
import { CCol } from "../../../../components/Grid/CCol";

export const TituloTable = styled.th`
  color: #888a8e;
  font-weight: 600;
  font-size: 12px;
`;

export const BodyTable = styled.tbody`
  color: #515c70;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

function EstruturaDespesa({ arrayEstruturaDaConta }) {
  console.log(arrayEstruturaDaConta)
  return (
    <div>
      <Row>
        <CCol
          className="d-flex align-items-center p-2 row_endereco_conteudo"
          md={12}
        >
          <span className="mx-4">Estrutura</span>
        </CCol>
      </Row>
      <Row className="row_form mt-1">
        <Table responsive>
          <thead>
            <tr>
              <TituloTable>Estrutura</TituloTable>
              <TituloTable>Conta</TituloTable>
              <TituloTable>Título</TituloTable>
            </tr>
          </thead>
          <BodyTable>
            {arrayEstruturaDaConta.map((item) => (
              <tr>
                <td>{item.nivel}</td>
                <td>{item.conta}</td>
                <td>{item.titulo}</td>
              </tr>
            ))}
          </BodyTable>
        </Table>
      </Row>
    </div>
  );
}

export default EstruturaDespesa;
