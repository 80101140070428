import * as React from "react";

const IconModulosHeader = (props) => {
  const fillColor = props.ativo ? "currentColor" : undefined;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={39.785}
      height={39.788}
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "optimizeQuality",
        fillRule: "evenodd",
        clipRule: "evenodd",
        height: "25",
      }}
      viewBox="0 0 40.76 40.76"
      {...props}
    >
      <path
        d="M4.96 0h13.6v18.7H0V4.57C.11 1.7 2.3.04 4.96-.01z"
        style={{
          fill: fillColor || "#f89c32",
        }}
      />
      <path
        d="M35.8 0H22.2v18.7h18.56V4.57C40.65 1.7 38.46.04 35.8-.01z"
        style={{
          fill: fillColor || "#297772",
        }}
      />
      <path
        d="M4.96 40.76h13.6v-18.7H0v14.13c.11 2.87 2.3 4.53 4.96 4.58z"
        style={{
          fill: fillColor || "#dd529c",
        }}
      />
      <path
        d={
          props.savebot
            ? "M28.29 40.76h13.5v-18.7H22.2v18.7h6.09z"
            : "M28.29 40.76h6.42v-6.11h6.05v-6.48h-6.05v-6.11h-6.42v6.11h-6.05v6.48h6.05v6.11zM28.29 37.7"
        }
        style={{
          fill: fillColor || "#8ec65c",
          fillRule: "nonzero",
        }}
      />
    </svg>
  );
};

export default IconModulosHeader;
