import React, { useState } from "react";
import styled from "styled-components";

const SwitchContainer = styled.div`
  width: ${(props) => 50 * props.sizeMultiplier}px;
  height: ${(props) => 26 * props.sizeMultiplier}px;
  background-color: ${(props) => (props.isOn ? "green" : "#ccc")};
  border-radius: ${(props) => 26 * props.sizeMultiplier}px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

const Toggle = styled.div`
  width: ${(props) => 22 * props.sizeMultiplier}px;
  height: ${(props) => 22 * props.sizeMultiplier}px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: ${(props) => 2 * props.sizeMultiplier}px;
  left: ${(props) =>
    props.isOn ? 26 * props.sizeMultiplier : 2 * props.sizeMultiplier}px;
  transition: left 0.3s ease;
`;

const Text = styled.text`
  margin-left: 5px;
  color: #6c7585;
  font-size: 1em;
  font-weight: 500;
`;

const Switch = ({ size = 1, label }) => {
  const [isOn, setIsOn] = useState(false);

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <SwitchContainer
        isOn={isOn}
        onClick={() => setIsOn(!isOn)}
        sizeMultiplier={size}
      >
        <Toggle isOn={isOn} sizeMultiplier={size} />
      </SwitchContainer>
      <Text>{label}</Text>
    </div>
  );
};

export default Switch;
