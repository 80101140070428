import { useEffect, useState } from "react";

const useAbas = ({ sistema }) => {
  const [arrayDeAbas, setArrayDeAbas] = useState([]);
  const [abaSelecionada, setAbaSelecionada] = useState({});

  useEffect(() => {
    switch (sistema) {
      case "Gerenciador":
        setArrayDeAbas(["Sistemas", "Configurações"]);
        setAbaSelecionada({ nomeAba: "Sistemas", numeroAba: 0 });
        break;
      // case "Planejamento":
      //   setArrayDeAbas(["Configurações"]);
      //   setAbaSelecionada({ nomeAba: "Configurações", numeroAba: 0 });
      //   break;
      default:
        setArrayDeAbas([]);
    }
  }, [sistema]);

  return { arrayDeAbas, setArrayDeAbas, abaSelecionada, setAbaSelecionada };
};

export default useAbas;
