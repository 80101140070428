import api from "../api";

export const FormatInternUserObject = async (usuario) => {
  try {
    const response = await api.get("cliente");
    const data = response.data;
    console.log(data);

    const formattedUser = {
      ...usuario,
      clientes: data.map((item) => {
        const {
          brasao,
          dataCriacao,
          dataUpdate,
          gestao,
          pessoaJuridica,
          unidadeGestora,
          id,
          ...resto
        } = item;

        return {
          ...resto,
          clienteId: id,
          cnpj: pessoaJuridica.cnpj,
          nomeFantasia: pessoaJuridica.nomeFantasia,
          pessoaJuridicaId: pessoaJuridica.id,
          unidadeGestora: {
            ...unidadeGestora,
          },
          endereco: {
            ...unidadeGestora.endereco,
          },
        };
      }),
    };

    return formattedUser;
  } catch (error) {
    console.log(error);
  }
};
