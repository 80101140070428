import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  NavLinkButtonNovo,
  StyledHeaderPag,
  StyledIcon,
  NavLinkButtonsPags,
} from "../Header/StylesHeader";
import { useNavigate } from "react-router-dom";

function HeaderOptions({
  withRow,
  botaoNovo,
  TituloPagina,
  SubTituloPagina,
  PaginaRelatorio,
  ToPag,
  DashboardPage,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && (event.key === "r" || event.key === "R")) {
        if (PaginaRelatorio) {
          navigate(PaginaRelatorio);
        }
      }
      if (event.altKey && (event.key === "g" || event.key === "G")) {
        if (DashboardPage) {
          navigate(DashboardPage);
        }
      }
      if (event.altKey && (event.key === "n" || event.key === "N")) {
        if (ToPag) {
          navigate(ToPag);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [DashboardPage, PaginaRelatorio, ToPag, navigate]);

  return (
    <div>
      <Row>
        <StyledHeaderPag md={12}>
          <div className="d-flex space_between titulo_conteudo">
            <div>
              <span className="cor_letra_primaria fw-bold">
                {TituloPagina}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                >
                  <g transform="rotate(180 12 12)">
                    <path
                      fill="currentColor"
                      d="M15.125 21.1L6.7 12.7q-.15-.15-.213-.325T6.425 12q0-.2.062-.375T6.7 11.3l8.425-8.425q.35-.35.875-.35t.9.375q.375.375.375.875t-.375.875L9.55 12l7.35 7.35q.35.35.35.863t-.375.887q-.375.375-.875.375t-.875-.375Z"
                    />
                  </g>
                </svg>
              </span>
              <span className="cor_letra_secundaria">{SubTituloPagina}</span>
            </div>

            <div className="header_conteudo">
              <NavLinkButtonsPags
                $active={window.location.pathname.includes("/consulta")}
                title="Alt + C"
              >
                <StyledIcon
                  $active={window.location.pathname.includes("/consulta")}
                  width={35}
                  icon="simple-line-icons:magnifier"
                />
              </NavLinkButtonsPags>

              <NavLinkButtonsPags
                $active={window.location.pathname.includes("/relatorio")}
                to={PaginaRelatorio}
                title="Alt + R"
              >
                <StyledIcon
                  style={{ marginRight: "-1px" }}
                  width={35}
                  icon="simple-line-icons:notebook"
                />
              </NavLinkButtonsPags>

              <NavLinkButtonsPags
                to={DashboardPage}
                $active={window.location.pathname.includes("/dashboard")}
                title="Alt + G"
              >
                <StyledIcon
                  style={{ marginRight: "-1px" }}
                  width={35}
                  icon="cil:chart"
                />
              </NavLinkButtonsPags>

              {!botaoNovo && botaoNovo !== undefined && (
                <Button disabled variant="success" title="Novo">
                  Novo
                </Button>
              )}

              {(botaoNovo || botaoNovo === undefined) && (
                <NavLinkButtonNovo to={ToPag} variant="success" title="Alt + N">
                  Novo
                </NavLinkButtonNovo>
              )}

              <Button disabled variant="primary" title="Save">
                Save
              </Button>
            </div>
          </div>
        </StyledHeaderPag>
      </Row>

      {withRow && (
        <Row>
          <Col
            className="d-flex align-items-center p-2 header_body_conteudo"
            md={12}
          >
            <span
              className="mx-3"
              style={{ fontSize: "16px", fontWeight: "600" }}
            >
              {TituloPagina}
            </span>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default HeaderOptions;
