import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { PdfInfos } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";

export const RenderPdfDemoEvolucaoPatrimonio = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: 200,
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const renderTextColumn = (text, style = {}) => (
    <Text
      style={[
        stylesPdf.textTituloColuna,
        style,
        { textTransform: "uppercase", fontSize: 8 },
      ]}
    >
      {text}
    </Text>
  );
  const renderHeader = (titleFirstYear = false) => {
    return (
      <>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                height:!titleFirstYear ? "auto" : 32,
                paddingVertical: 2,
                justifyContent: 'flex-end'
              },
            ]}
          >
            {renderTextColumn("PATRIMÔNIO LÍQUIDO")}
          </View>
        </View>
        {dados.contentHeader.map((year, index) => (
          <React.Fragment key={index}>
            <View style={[stylesPdf.coluna, styledCol[1]]}>
              <View
                style={[
                  stylesPdf.divTituloColuna,
                  {
                    textAlign: "center",
                    height:!titleFirstYear ? "auto" : 32,
                    paddingVertical: 2,
                    justifyContent: 'flex-end'
                  },
                ]}
              >
                {/* {renderTextColumn( titleFirstYear ? `${`regime previdenciário \n${year}` }` : `${year}`)} */}
                {index === 0 && titleFirstYear &&
                  renderTextColumn("regime \nprevidenciário", {
                    position: "absolute", top:0, textAlign: 'center',
                  })}
                {renderTextColumn(year)}
              </View>
            </View>
            <View style={[stylesPdf.coluna, styledCol[1]]}>
              <View
                style={[
                  stylesPdf.divTituloColuna,
                  {
                    textAlign: "center",
                    height:!titleFirstYear ? "auto" : 32,
                    paddingVertical: 2,
                    justifyContent: 'flex-end'
                  },
                ]}
              >
                {renderTextColumn("%")}
              </View>
            </View>
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderInfos = () => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            fontWeight: "bold",
          },
        ]}
        fixed
      >
        <PdfInfos
          pdfInfo={"Patrimônio / Capital"}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
              textAlign: "left",
            },
          ]}
          styledText={{fontSize:8}}
        />
        {Array.from({ length: 6 }).map((_, index) => (
          <PdfInfos
            key={index}
            pdfInfo={"1.000.000,00"}
            styledCol={[
              styledCol[1],
              {
                padding: "2px 0",
                textAlign: "center",
              },
            ]}
            styledText={{ paddingHorizontal: 0,fontSize:8 }}
          />
        ))}
      </View>
    );
  };

  const renderTotal = () => {
    return (
      <>
        <View
          wrap={false}
          style={[
            stylesPdf.divInferiorColuna,
            {
              fontWeight: "bold",
              fontSize: 8,
              textTransform: "uppercase",
              color: "white",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: 0,
              paddingHorizontal: 0,
              paddingLeft: 10,
            },
          ]}
        >
          <Text>total</Text>
          <View style={{ flexDirection: "row" }}>
            {Array.from({ length: 6 }).map((_, index) => (
              <Text
                key={index}
                style={{
                  width: 64,
                  textAlign: "center",
                }}
              >
                1.000.000,00
              </Text>
            ))}
          </View>
        </View>
      </>
    );
  };

  return (
    <Page size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Evolução do Patrimônio Líquido "}
        anoRelatorio={currentYear}
        plusText={"Anexo de Metas Fiscais"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: 8,
          padding: "0 4px",
        }}
      >
        <Text>AMF - Demonstrativo 4 (LRF, art. 4º, § 2º, inciso III)</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
        {renderHeader()}
      </View>
      <View style={stylesPdf.divRelatorio}>
        {renderInfos()}
        {renderInfos()}
        {renderInfos()}
      </View>
      {renderTotal()}

      <View
        style={[
          stylesPdf.colunas,
          { backgroundColor: "#515c70", marginTop: 20 },
        ]}
      >
        {renderHeader(true)}
      </View>
      <View style={stylesPdf.divRelatorio}>
        {renderInfos()}
        {renderInfos()}
        {renderInfos()}
      </View>
      {renderTotal()}
      <Text style={{ fontWeight: "bold", marginLeft: 8 ,marginTop: 2, fontSize:8}}>
        FONTE: Secretaria de Finanças
      </Text>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
