import React from "react";
import {
  CCol,
  ContainerToModal,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Row } from "react-bootstrap";
import Contrato from "./Contrato";
import Financeiro from "./Financeiro";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";

const Convenio = () => {
  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Convênio"}
        PaginaSubtitulo={"Cadastro"}
        BotaoSaveAtivo={false}
        BotaoNovoAtivo={false}
      />
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={2}>
            <FloatingLabelInput label={"Código"} placeholder={"Código"} />
          </CCol>
          <CCol>
            <FloatingLabelInput
              label={"Programa Governamental"}
              placeholder={"Programa Governamental"}
            />
          </CCol>
          <CCol md={3}>
            <FloatingLabelInput
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              tipo="input_select"
              label="Tipo de vínculo"
              placeholder="Tipo de vínculo"
              options={[{ label: "Tipo de vínculo", value: "Tipo de vínculo" }]}
            />
          </CCol>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol md={3}>
            <FloatingLabelInput
              label={"Número do Convênio"}
              placeholder={"Número do Convênio"}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput label={"Descrição"} placeholder={"Descrição"} />
          </CCol>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol>
            <MyAutoComplete
              labelInput={"Concedente"}
              options={[{ label: "Concedente", value: "Concedente" }]}
              labelFormat={(option) => `${option.label} - ${option.value}`}
              style={{ height: "38px" }}
            />
          </CCol>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol>
            <FloatingLabelInput
              tipo="text_area"
              as="textarea"
              label="Objeto"
              placeholder="Objeto"
              style={{ minHeight: "98px" }}
            />
          </CCol>
        </RowAninhada>
      </Row>
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Contrato
          </span>
        </CCol>
      </Row>
      <Contrato />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Financeiro
          </span>
        </CCol>
      </Row>
      <Financeiro />
      <div style={{ minHeight: "40px" }}> </div>
    </ContainerToModal>
  );
};

export default Convenio;
