import React from "react";
import Modal from "react-modal";
import {
  ModalStyled,
  DivIconModal,
  IconAlertModal,
  ButtonsDiv,
} from "../../styles/StyledComponents/ModalStyled";

const ModalDeleteData = ({
  modalOpened,
  closeModal,
  deleteDataFunction,
  setDataDelete,
}) => {
  Modal.setAppElement("#root");
  return (
    <ModalStyled
      isOpen={modalOpened}
      onRequestClose={closeModal}
      overlayClassName="modal-overlay"
      contentLabel="Example Modal"
    >
      <DivIconModal>
        <IconAlertModal
          width={100}
          icon="fluent:error-circle-20-regular"
          color="red"
          hexcolor="#ff0000"
        />
      </DivIconModal>

      <h6>Tem certeza que deseja deletar permanentemente esses dados?</h6>
      <p>Continuar e deletar os dados</p>
      <ButtonsDiv>
        <button
          className="sim_button"
          onClick={() => {
            deleteDataFunction();
            closeModal();
          }}
        >
          Sim
        </button>
        <button
          className="nao_button"
          onClick={() => {
            closeModal();
            setDataDelete({});
          }}
        >
          Não
        </button>
      </ButtonsDiv>
    </ModalStyled>
  );
};

export default ModalDeleteData;
