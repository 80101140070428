import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm, RowToModal } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import styled from "styled-components";
import { FonteDeRecursosAba } from "./FonteDeRecursosAba";
import {
  accountCategories,
  indexItemsApplyBgPreviousOptions,
} from "./accountsPCASP";
import api from "../../../../utils/api";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import { OptionsStyle } from "../../../Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/NaturezaDaReceita";
import { ToastContainer } from "react-toastify";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

export const QDRDE = () => {
  const { currentYear } = useCurrentYear();

  // const [abaSelecionada, setAbaSelecionada] = useState({
  //   nomeAba: "Fonte de Recursos / CO",
  //   numeroAba: 0,
  // });

  const [optionNaturezaSaldo, setOptionNaturezaSaldo] = useState("");
  const [optionAccountSelected, setOptionAccountSelected] = useState("");
  const [subOptionAccountSelected, setSubOptionAccountSelected] =
    useState(null);
  // const [resetInfosFonteRecurso, setResetInfosFonteRecurso] = useState(false);
  const [listFontesDeRecursos, setListFontesDeRecursos] = useState([
    { fonteId: null, valor: "", data: "", label: "" },
  ]);

  const [infosSubOptionAccount, setInfosSubOptionAccount] = useState({
    id: "",
    tituloNumero: "",
    titulo: "",
    funcao: "",
    naturezaDeSaldo: "",
    fontes: [],
  });

  const getOptionsSubCategory = (valueOptionAccount) => {
    const options = accountCategories.find(
      (item) => item.value === valueOptionAccount && item.subOptions.length
    );

    if (!options) {
      setSubOptionAccountSelected(null);
      setOptionNaturezaSaldo(null);
      setInfosSubOptionAccount({
        id: "",
        tituloNumero: "",
        titulo: "",
        funcao: "",
        naturezaDeSaldo: "",
      });
      return [];
    }

    const hasInfosSubOptions = options.subOptions.every(
      (item) => item.value === 0
    );

    if (hasInfosSubOptions) {
      setSubOptionAccountSelected(null);
      setOptionNaturezaSaldo(null);
      setInfosSubOptionAccount({
        ...infosSubOptionAccount,
        naturezaDeSaldo: "",
        funcao: "",
      });
    }

    return options.subOptions;
  };

  const optionsSubCategory = useMemo(() => {
    return optionAccountSelected
      ? getOptionsSubCategory(optionAccountSelected)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionAccountSelected]);

  const getInfosSubCategory = useCallback(
    async (optionSubCategory) => {
      const subCategorySelected = optionsSubCategory.find(
        (item) => item.value === optionSubCategory
      );

      if (!subCategorySelected) return;

      try {
        const resp = await api.get(
          `pcasp/1/ano/${currentYear}/filtro?filter=${subCategorySelected.code}`
        );
        const data = resp.data;
        const infosSubCategory = data.pcasp.find(
          (item) => item.conta === subCategorySelected.code
        );
        /* //TODO: Por enquanto a natureza saldo vem somente uma string no back mas em algum momento iŕa vir em array */
        if (infosSubCategory) {
          setInfosSubOptionAccount({
            id: infosSubCategory.id,
            titulo: infosSubCategory.titulo,
            funcao: infosSubCategory.funcao,
            naturezaDeSaldo: infosSubCategory.naturezaDeSaldo,
            tituloNumero: infosSubCategory.tituloNumero,
            fontes: subCategorySelected.fontesDeRecursos,
          });
          setOptionNaturezaSaldo(infosSubCategory.id);
          setListFontesDeRecursos([
            { fonteId: null, valor: "", data: "", label: "" },
          ]);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [currentYear, optionsSubCategory]
  );

  useEffect(() => {
    if (subOptionAccountSelected) {
      getInfosSubCategory(subOptionAccountSelected);
    }
  }, [getInfosSubCategory, subOptionAccountSelected]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"QDRDE"}
        PaginaSubtitulo={"Cadastro"}
        RowTitle={"Quadro Detalhado da Receita e Despesa Extra"}
      />
      <ToastContainer />
      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput
            disabled
            placeholder="Número"
            label="Número"
            value={infosSubOptionAccount.tituloNumero}
          />
        </CColNumero>
        <CCol>
          <FloatingLabelInput placeholder="Título" label="Título" />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            placeholder="Categoria da Conta - PCASP"
            new
            tipo="input_select"
            label="Categoria da Conta - PCASP"
            value={optionAccountSelected}
            onSelect={(option) => {
              setOptionAccountSelected(option.value);
              setSubOptionAccountSelected(1);
              // setResetInfosFonteRecurso(true);
            }}
            options={accountCategories}
            indexOptionsApplyBgPrevious={indexItemsApplyBgPreviousOptions}
          />
        </CCol>

        <CCol md={3}>
          <FloatingLabelInput
            placeholder="Natureza do Saldo"
            new
            disabled
            value={optionNaturezaSaldo}
            tipo="input_select"
            label="Natureza do Saldo"
            onSelect={(option) => setOptionNaturezaSaldo(option.value)}
            options={[
              {
                label: "Natureza do Saldo",
                value: null,
              },
              {
                label: infosSubOptionAccount.naturezaDeSaldo,
                value: infosSubOptionAccount.naturezaDeSaldo
                  ? infosSubOptionAccount.id
                  : null,
              },
            ]}
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <FloatingLabelInput
          placeholder="Item"
          new
          tipo="input_select"
          label="Item"
          disabled={
            optionsSubCategory.every((item) => item.value === 0) ||
            !optionsSubCategory.length ||
            subOptionAccountSelected === null
          }
          value={subOptionAccountSelected}
          onSelect={(option) => {
            setSubOptionAccountSelected(option.value);
            console.log(option.value);
          }}
          options={[{ label: "Item", value: null }, ...optionsSubCategory]}
        />
      </RowForm>
      {infosSubOptionAccount.funcao && (
        <RowInfosAcao>
          <CCol>
            <p>
              <span>{infosSubOptionAccount.funcao}</span>
            </p>
          </CCol>
        </RowInfosAcao>
      )}

      {/* <RowSelecaoAbasInferior
        abas={["Fonte de Recursos / CO"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      /> */}
      <RowToModal
        style={{ backgroundColor: "#fff", borderRadius: "0px 0px 5px 5px" }}
        className={"mt-1"}
      >
        <CCol style={{ display: "flex", alignItems: "center", padding: "0px" }}>
          <OptionsStyle selecionado>Fonte de Recursos / CO</OptionsStyle>
          {/* <OptionsStyle
            selecionado={abaSelecionada === 1}
            onClick={() => setAbaSelecionada(1)}
          >
            Outras Informações
          </OptionsStyle> */}
        </CCol>
      </RowToModal>
      <FonteDeRecursosAba
        fontesSubOptions={infosSubOptionAccount.fontes}
        listFontesDeRecursos={listFontesDeRecursos}
        setListFontesDeRecursos={setListFontesDeRecursos}
      />
      {/* {abaSelecionada.nomeAba === "Fonte de Recursos / CO" && (
        <FonteDeRecursosAba />
      )} */}
    </Container>
  );
};
