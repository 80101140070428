import React from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowForm,
} from "../../../../components/Grid/CCol";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
// import { useFetchData } from "../../../../hooks/useFetchData";

export const FonteDeRecursosAba = () => {
  // const {data: FonteRecList} = useFetchData("")

  return (
    <>
      <RowTituloAba>
        <div>
          <span>Fonte de Recursos / CO</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1" $borderRadiusType={2}>
        <CCol md={2}>
          <PTitulosInputs>Guia</PTitulosInputs>
          <FormControlListaDeInputs />
        </CCol>
        <CCol>
          <PTitulosInputs>Fonte de Recursos + CO</PTitulosInputs>
          <MyAutoComplete
            options={[{ codigo: "teste", titulo: "teste" }]}
            labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
          />
        </CCol>
        <CCol md={1}>
          <PTitulosInputs>%</PTitulosInputs>
          <FormControlListaDeInputs />
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Valor</PTitulosInputs>
          <FormControlListaDeInputs />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar />
        </CColButtonsAcoes2Icons>
      </RowForm>
    </>
  );
};
