

export const getDemoMargenExpansao = async (
  clienteId,
  entidadesGestorasDoCliente,
  ano = 2024
) => {
  return {
    contentHeader: ano,
  };
};
