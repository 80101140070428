export const tiposDeObjeto = [
  {
    label: "0 - Obras e Serviços de Engenharia",
    value: "0 - Obras e Serviços de Engenharia",
  },
  { label: "1 - Compras e Serviços", value: "1 - Compras e Serviços" },
  { label: "2 - Alienação", value: "2 - Alienação" },
];

export const modalidades = [
  { label: "1 - Concorrência", value: "1 - Concorrência" },
  { label: "2 - Tomada de Preços", value: "2 - Tomada de Preços" },
  { label: "3 - Convite", value: "3 - Convite" },
  { label: "4 - Concurso", value: "4 - Concurso" },
  { label: "5 - Leilão", value: "5 - Leilão" },
  { label: "6 - Dispensa por Valor", value: "6 - Dispensa por Valor" },
  {
    label: "7 - Dispensa por outros motivos",
    value: "7 - Dispensa por outros motivos",
  },
  { label: "8 - Inexigível", value: "8 - Inexigível" },
  { label: "9 - Sem Licitação", value: "9 - Sem Licitação" },
  { label: "10 - Pregão Eletrônico", value: "10 - Pregão Eletrônico" },
  { label: "11 - Pregão Presencial", value: "11 - Pregão Presencial" },
  {
    label: "12 - Adesão a Registro de Preço",
    value: "12 - Adesão a Registro de Preço",
  },
  { label: "13 - Chamada Pública", value: "13 - Chamada Pública" },
  {
    label: "14 - RDC - Regime Diferenciado de Contratações Públicas",
    value: "14 - RDC - Regime Diferenciado de Contratações Públicas",
  },
  { label: "15 - Lei Nº 13.303/2016", value: "15 - Lei Nº 13.303/2016" },
  {
    label: "16 - Lei Nº 13.303/2016 (Art. 29 ou 30)",
    value: "16 - Lei Nº 13.303/2016 (Art. 29 ou 30)",
  },
  {
    label: "17 - Internacional (GN 2349-9)",
    value: "17 - Internacional (GN 2349-9)",
  },
  {
    label: "18 - Internacional (GN 2350-9)",
    value: "18 - Internacional (GN 2350-9)",
  },
  {
    label:
      "19 - Contratação Emergencial de Organização (Art. 12, II da Lei Nº 9.454/2011)",
    value:
      "19 - Contratação Emergencial de Organização (Art. 12, II da Lei Nº 9.454/2011)",
  },
  {
    label: "20 - Dispensa COVID-19 (Art. 4º da Lei 13.979/2020)",
    value: "20 - Dispensa COVID-19 (Art. 4º da Lei 13.979/2020)",
  },
  {
    label: "21 - Dispensa (Lei 14.133/21)",
    value: "21 - Dispensa (Lei 14.133/21)",
  },
  {
    label: "22 - Inexigibilidade (Lei 14.133/21)",
    value: "22 - Inexigibilidade (Lei 14.133/21)",
  },
  {
    label: "23 - Concorrência (Lei 14.133/21)",
    value: "23 - Concorrência (Lei 14.133/21)",
  },
  {
    label: "24 - Pregão (Lei 14.133/21)",
    value: "24 - Pregão (Lei 14.133/21)",
  },
  {
    label: "25 - Concurso (Lei 14.133/21)",
    value: "25 - Concurso (Lei 14.133/21)",
  },
  {
    label: "26 - Pregão (Medida Provisória 1.047/21)",
    value: "26 - Pregão (Medida Provisória 1.047/21)",
  },
  {
    label: "27 - Dispensa (Medida Provisória 1.047/21)",
    value: "27 - Dispensa (Medida Provisória 1.047/21)",
  },
  {
    label: "28 - Internacional Não Competitiva",
    value: "28 - Internacional Não Competitiva",
  },
  {
    label: "29 - Licitação Internacional Competitiva",
    value: "29 - Licitação Internacional Competitiva",
  },
  {
    label: "30 - Credenciamento - (Lei Nº 14.133/2021)",
    value: "30 - Credenciamento - (Lei Nº 14.133/2021)",
  },
  {
    label: "31 - Concorrência - Publicidade (Lei Nº 12.232/2010)",
    value: "31 - Concorrência - Publicidade (Lei Nº 12.232/2010)",
  },
  {
    label: "32 - Diálogo Competitivo - (Lei Nº 14.133/2021)",
    value: "32 - Diálogo Competitivo - (Lei Nº 14.133/2021)",
  },
  {
    label:
      "33 - Alienação de bens, dispensada a licitação - (Lei Nº 14.133/2021)",
    value:
      "33 - Alienação de bens, dispensada a licitação - (Lei Nº 14.133/2021)",
  },
  {
    label: "34 - Leilão - (Lei Nº 14.133/2021)",
    value: "34 - Leilão - (Lei Nº 14.133/2021)",
  },
  {
    label: "35 - Adesão a Ata de Registro de Preços - (Lei Nº 14.133/2021)",
    value: "35 - Adesão a Ata de Registro de Preços - (Lei Nº 14.133/2021)",
  },
  {
    label: "36 - Licitação Credenciamento - (Lei Nº 8.666/93)",
    value: "36 - Licitação Credenciamento - (Lei Nº 8.666/93)",
  },
];
