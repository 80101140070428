import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { CSSTransition } from "react-transition-group";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import api from "../../../../utils/api";
import defaultImage from "./ImgsObjetivosDoMilenio/Default_Image.png";

const ObjetivosDoMilenio = () => {
  const [valuesObjetivo, setValuesObjetivo] = useState({
    codigo: "",
    descricao: "",
    dePara: "",
    objetivo: "",
    tce: "",
    img: "",
  });

  const [currentImg, setCurrentImg] = useState(null);
  const [codes, setCodes] = useState([]);
  const [codeHasFetched, setCodeHasFetched] = useState(false);
  const { codigo: paramsCodigo } = useParams();
  const imgToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImgClick = () => {
    console.log("Deu pra clicar");
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.addEventListener("change", (event) => {
      const file = event.target.files[0];
      const maxSize = 10 * 1024;

      if (file) {
        if (file.size > maxSize) {
          toast.warn("A imagem deve ter no máximo 10KB");
          return;
        }
        if (file.type.startsWith("image/")) {
          if (file.type === "image/svg+xml") {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
              const svgString = reader.result;
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              const svg = new Blob([svgString], { type: "image/svg+xml" });
              const url = URL.createObjectURL(svg);
              const img = new Image();
              img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                canvas.toBlob((blob) => {
                  const jpgFile = new File(
                    [blob],
                    file.name.replace(/\.svg$/, ".jpg"),
                    {
                      type: "image/jpeg",
                    }
                  );
                  handleImageUpload(jpgFile);
                  URL.revokeObjectURL(url);
                }, "image/jpeg");
              };
              img.src = url;
            };
          } else {
            handleImageUpload(file);
          }
        } else {
          toast.warn("Tipo não suportado");
        }
      } else {
        console.log("Nenhum arquivo selecionado");
      }
    });

    input.click();
  };

  const handleImageUpload = async (file) => {
    if (file) {
      const base64String = await imgToBase64(file);
      setValuesObjetivo((prevValues) => ({
        ...prevValues,
        img: base64String,
      }));
    }
  };

  const base64ToImgUrl = (base64String) => {
    const blob = fetch(base64String)
      .then((res) => res.blob())
      .catch((err) => {
        console.error("Error creating blob from base64 string:", err);
        return null;
      });

    return blob
      .then((blob) => URL.createObjectURL(blob))
      .catch((err) => {
        console.error("Error creating object URL from blob:", err);
        return null;
      });
  };

  useEffect(() => {
    if (valuesObjetivo.codigo) {
      setValuesObjetivo((prevValues) => ({
        ...prevValues,
        tce: parseInt(valuesObjetivo.codigo),
      }));
    }
  }, [valuesObjetivo.codigo]);

  useEffect(() => {
    const fetchCodes = () => {
      api
        .get(`/objetivoDoMilenio`)
        .then((response) => {
          const codes = response.data.map((item) => item.codigo);
          console.log(codes);
          setCodes(codes);
        })
        .catch((error) => {
          console.error("Erro ao buscar os códigos:", error);
        });
    };
    fetchCodes();
  }, []);

  useEffect(() => {
    const findNextCode = () => {
      let code = 1;
      while (codes.includes(code.toString().padStart(2, "0"))) {
        code++;
      }
      const newCode = code.toString().padStart(2, "0");
      setValuesObjetivo((prevValues) => ({
        ...prevValues,
        codigo: newCode,
      }));
      setCodeHasFetched(true);
    };

    if (codes.length > 0 && !valuesObjetivo.codigo && !codeHasFetched) {
      findNextCode();
    }
  }, [codes, valuesObjetivo.codigo, codeHasFetched]);

  useEffect(() => {
    console.log("valuesObjetivo: ", valuesObjetivo);
    if (valuesObjetivo.img) {
      const generateImageUrl = async () => {
        const imgUrl = await base64ToImgUrl(valuesObjetivo.img);
        setCurrentImg(imgUrl);
      };

      generateImageUrl();
    }
  }, [valuesObjetivo]);

  const fetchData = () => {
    api.get(`objetivoDoMilenio/pesquisa/id/${paramsCodigo}`).then((resp) => {
      console.log(resp.data);
      setValuesObjetivo((prevValues) => ({
        ...prevValues,
        codigo: resp.data?.codigo,
        descricao: resp.data?.descricao,
        dePara: resp.data?.dePara,
        objetivo: resp.data?.objetivo,
        tce: resp.data?.tce,
        img: resp.data?.img,
      }));
    });
  };

  useEffect(() => {
    if (paramsCodigo) {
      fetchData();
    }
    //eslint-disable-next-line
  }, [paramsCodigo]);

  const cadastraObjetivo = () => {
    const valuesToSend = valuesObjetivo;
    valuesToSend.img = valuesToSend.img.replace(
      /^data:image\/(png|jpg|jpeg|svg\+xml);base64,/,
      ""
    );
    if (paramsCodigo) {
      api
        .put(`objetivoDoMilenio/${paramsCodigo}`, valuesToSend)
        .then((response) => {
          console.log(response);
          toast.success("Dados atualizados com sucesso!");
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 400) {
            toast.error(
              "Cadastro inválido, certifique-se de preencher os campos corretamente e de que esse cadastro ainda não exista na tabela."
            );
          } else {
            toast.error(
              "Ocorreu um erro ao atualizar os dados. Por favor, tente mais tarde"
            );
          }
        });
    } else {
      if (codes.includes(valuesObjetivo.codigo)) {
        toast.warn("Já existe um objetivo com o mesmo código inserido!");
      } else {
        console.log("aq o que envia: ", valuesToSend);
        api
          .post("objetivoDoMilenio/", valuesToSend)
          .then((response) => {
            console.log(response);
            toast.success("Dados cadastrados com sucesso!");
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.status === 400) {
              toast.error(
                "Cadastro inválido, certifique-se de preencher os campos corretamente e de que esse cadastro ainda não exista na tabela."
              );
            } else {
              toast.error(
                "Ocorreu um erro ao cadastrar os dados. Por favor, tente mais tarde"
              );
            }
          });
      }
    }
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        withRow={true}
        optionsButton={false}
        ButtonSaveFunction={cadastraObjetivo}
        NomePaginaTitulo={"Objetivos da ONU"}
        PaginaSubtitulo={"Vizualizar"}
        BotaoSaveAtivo={true}
        BotaoNovoAtivo={false}
        PaginaConsulta={"/planejamento/cadastro/objetivos-da-onu/consulta"}
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        <CCol style={{ minWidth: "156px" }} md={2}>
          <div
            style={{
              height: "150px",
              borderRadius: "5px",
            }}
          >
            <CSSTransition
              in={currentImg || defaultImage}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <img
                src={currentImg ? currentImg : defaultImage}
                height={150}
                style={{
                  transform: "scaleX(1.05)",
                  marginLeft: "2px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                alt={""}
                onClick={() => handleImgClick()}
              />
            </CSSTransition>
          </div>
        </CCol>
        <CCol md={10}>
          <Row>
            <CCol md={2}>
              <FloatingLabelInput
                maxLength={2}
                label="Código"
                //disabled={true}
                value={valuesObjetivo.codigo && valuesObjetivo.codigo}
                placeholder="Código"
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  setValuesObjetivo((prevValues) => ({
                    ...prevValues,
                    codigo: e.target.value,
                  }));
                }}
              />
            </CCol>
            <CCol md={8}>
              <FloatingLabelInput
                label="Descrição"
                placeholder="Descrição"
                value={valuesObjetivo.descricao}
                //disabled={true}
                onChange={(e) =>
                  setValuesObjetivo((prevValues) => ({
                    ...prevValues,
                    descricao: e.target.value,
                  }))
                }
              />
            </CCol>
            <CCol md={2}>
              <FloatingLabelInput
                label="De-Para"
                placeholder="De-Para"
                value={valuesObjetivo.dePara}
                maxLength={2}
                //disabled={true}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  setValuesObjetivo((prevValues) => ({
                    ...prevValues,
                    dePara: e.target.value,
                  }));
                }}
              />
            </CCol>
            <div className="pt-3"></div>
            <CCol md={12}>
              <FloatingLabelInput
                as="textarea"
                tipo="text_area"
                overflowY={"auto"}
                //disabled={true}
                value={valuesObjetivo.objetivo}
                maxLength={2000}
                label="Objetivo"
                placeholder="Objetivo"
                onChange={(e) => {
                  setValuesObjetivo((prevValues) => ({
                    ...prevValues,
                    objetivo: e.target.value,
                  }));
                }}
                style={{ minHeight: "96px" }}
              />
            </CCol>
          </Row>
        </CCol>
      </Row>
    </Container>
  );
};

export default ObjetivosDoMilenio;
