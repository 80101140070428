import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfInfos } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { FormatValueToLocaleString } from "../../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { PdfHeader } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";

export const RenderPdfAnexoPrioridade = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: 150, flexShrink: 1, flexGrow: 1 },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: 150,
      flexShrink: 1,
      flexGrow: 1,
    },
  ];

  const renderTextColumn = (text, style = {}) => (
    <Text
      style={[
        stylesPdf.textTituloColuna,
        style,
        { textTransform: "uppercase", fontSize: 8 },
      ]}
    >
      {text}
    </Text>
  );
  const renderHeader = (styled = {}) => {
    return (
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }, styled]}>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: "auto",
                alignItems: "center",
              },
            ]}
          >
            {renderTextColumn("código")}
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[1]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: "auto",
                alignItems: "center",
              },
            ]}
          >
            {renderTextColumn("descrição")}
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[2]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: "auto",
                alignItems: "center",
              },
            ]}
          >
            {renderTextColumn("valor")}
          </View>
        </View>
      </View>
    );
  };

  const renderInfos = (codigo, title, value, isSection = false) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            backgroundColor: isSection ? "#a6a6a6" : "transparent",
            fontWeight: isSection ? "bold" : "normal",
          },
        ]}
      >
        <PdfInfos
          pdfInfo={codigo}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{ fontSize: 8, paddingHorizontal: 4 }}
        />
        <PdfInfos
          pdfInfo={title}
          styledCol={[
            styledCol[1],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{ fontSize: 8, paddingHorizontal: 4 }}
        />
        <PdfInfos
          pdfInfo={value}
          styledCol={[
            styledCol[2],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{
            fontSize: 8,
            paddingHorizontal: 4,
            textAlign: "right",
          }}
        />
      </View>
    );
  };

  const renderTotal = (typeReceitaTotal, total = 10000) => {
    return (
      <View
        wrap={false}
        style={[
          stylesPdf.divInferiorColuna,
          {
            fontWeight: "bold",
            fontSize: 8,
            textTransform: "uppercase",
            color: "white",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: 0,
            paddingHorizontal: 4,
          },
        ]}
      >
        <Text>{typeReceitaTotal}</Text>
        <Text>{FormatValueToLocaleString(total)}</Text>
      </View>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Prioridades e Metas da Administração Pública"}
        anoRelatorio={currentYear}
        plusText={"Anexo IX"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {renderHeader("RECEITA")}
      </View>
      <View style={stylesPdf.divRelatorio}>
        {renderInfos("01.010", "Câmara Municipal", "25.000,00", true)}
        {renderInfos("01.010", "Legislativa  ", "25.000,00")}
        {renderInfos("01.010", "Ação Legislativa ", "25.000,00")}
        {renderInfos(
          "01.010",
          "Gestão com igualdade, equidade e justiça social para todos ",
          "25.000,00"
        )}
        {renderTotal("total")}
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
