import React from "react";
import { CCol, RowAninhada } from "../../../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import InputDataNoYear from "../../../../../../components/Grid/InputDataNoYear";

export const CalendarioAcoesPrazos = () => {
  const boxes = [
    "1 - Encaminhamento do PLDO (Integrado pelo Anexo de Riscos Fiscais)",
    "2 - Devolução para sanção da LDO",
    "3 - Encaminhamento do PLOA",
    "4 - Devolução para sanção da LOA",
    "5 - Realizar ajustes de procedimentos contábeis patrimoniais (dívida ativa, depreciação, provisões, dívida fundada etc.)",
    "6 - Realizar o envio da Prestação de Contas Anual para o Tribunal de Contas",
    "7 - Realizar o envio da MSC mensal de dezembro ao Siconfi, com a devida conferência e homologação do RREO e RGF no Siconfi",
    "8 - Realizar o envio da MSC de encerramento para o Siconfi",
    "9 - Realizar o envio da Declaração das Contas Anuais (DCA) para o Siconfi",
    "10 - Enviar SADIPEM",
  ];

  return (
    <>
      <Row>
        <RowAninhada className="mt-3">
          <CCol
            className="d-flex align-items-center p-2 header_body_conteudo"
            md={12}
            style={{ justifyContent: "left" }}
          >
            <span
              style={{
                fontSize: "16.5px",
                fontWeight: 600,
                marginLeft: "10px",
              }}
            >
              Principal
            </span>
          </CCol>
        </RowAninhada>
        <RowAninhada
          className="row_form mt-1"
          style={{ padding: "15px 25px 15px 15px" }}
        >
          <RowAninhada>
            {/* Title */}
            <CCol md={1} style={{ flexGrow: 1 }}>
              <Title align={"left"}>Ações</Title>
            </CCol>
            <CCol md={2}>
              <Title align={"left"}>Prazos Sugeridos Até</Title>
            </CCol>
          </RowAninhada>
          {boxes.map((item, index) => {
            return (
              <>
                <RowAninhada
                  className={index === 0 && "mt-2"}
                  style={{
                    paddingBottom: "5px",
                    borderBottom:
                      index !== boxes.length - 1 && "2px solid #F2F3F8",
                    marginBottom: "5px",
                    alignItems: "center",
                  }}
                >
                  <CCol
                    md={1}
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Text>{item}</Text>
                  </CCol>
                  <CCol
                    md={2}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "block",
                        width: "100%",
                      }}
                    >
                      <InputDataNoYear />
                    </div>
                  </CCol>
                </RowAninhada>
              </>
            );
          })}
        </RowAninhada>
      </Row>
    </>
  );
};

export default CalendarioAcoesPrazos;

const Title = styled.p`
  text-align: ${(props) => props.align || "center"};
  margin-top: 0;
  padding: 0px;
  margin-bottom: 0px;
  color: #888a8e;
  font-weight: 600;
  font-size: 14px;
`;

const Text = styled.p`
  font-size: 1em;
  align-items: center;
  margin: 0;
`;
