import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import { CCol, CColButtonsAcoes2Icons } from "../../../../components/Grid/CCol";
import { InputDateRelatorio } from "../../../../components/Inputs/InputDateRelatorio";
import { InputPercentage } from "../../../../components/Inputs/InputPercentage";
import { InputUpload } from "../../../../components/Inputs/InputUpload";

const FitCCol = styled(Col).attrs({ md: 1 })`
  --bs-gutter-x: 17px;
  --bs-gutter-y: 0;
  width: fit-content;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  accent-color: #2f7716;
  width: 16px;
  height: 16px;
`;

const Forms = styled.div`
  font-weight: 600;
  padding: 0 10px;
  margin-top: 5px;
`;

const MenuBox = styled.div`
  width: 100%;
  height: ${(props) => (props.opened ? "120px" : "75px")};
  overflow-x: hidden;
  overflow-y: ${(props) => (props.opened ? "scroll" : "hidden")};
  transition: height 0.3s ease-in-out;
  scrollbar-gutter: stable;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const LdoMenu = ({ setPercentage }) => {
  const [openedFontesRec, setOpenedFontesRec] = useState(false);

  const OpenCloseFontesRec = () => {
    setOpenedFontesRec((prev) => !prev);
  };

  const handleCheckboxClick = (e) => {
    const isChecked = e.target.checked;
    setPercentage((prevPercentage) => prevPercentage + (isChecked ? 20 : -20));
  };

  return (
    <Forms>
      <Row>
        <CCol
          md={1}
          style={{
            width: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox onClick={handleCheckboxClick} />
        </CCol>
        <FitCCol>
          <span>Percentual de inflação mínimo</span>
        </FitCCol>
      </Row>
      <Row>
        <CCol
          md={1}
          style={{
            width: "40px",
          }}
        >
          {""}
        </CCol>
        <FitCCol>
          <span>Inflação (% Anual para 2025)</span>
        </FitCCol>
        <FitCCol>
          <span>5,12%</span>
        </FitCCol>
        <FitCCol>
          <span>Percentual atingido</span>
        </FitCCol>
        <FitCCol>
          <span style={{ color: "#4d8b38" }}>6,12%</span>
        </FitCCol>
      </Row>
      <hr />
      <Row>
        <CCol
          md={1}
          style={{
            width: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox onClick={handleCheckboxClick} />
        </CCol>
        <FitCCol>
          <span>Comparativo Receita x Despesa</span>
        </FitCCol>
      </Row>
      <Row>
        <CCol
          md={1}
          style={{
            width: "40px",
          }}
        >
          {""}
        </CCol>
        <FitCCol>
          <span>Receita R$ 45.850.725,00</span>
        </FitCCol>
        <FitCCol>
          <span>Despesa R$ 45.850.725,00</span>
        </FitCCol>
        <FitCCol>
          <span>Diferença R$ 00,00</span>
        </FitCCol>
      </Row>
      <hr />
      <Row style={{ paddingRight: "2px" }}>
        <CCol
          md={1}
          style={{
            width: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox onClick={handleCheckboxClick} />
        </CCol>
        <CCol
          md={1}
          style={{ width: "230px", display: "flex", alignItems: "center" }}
        >
          <span>Fontes de Recursos</span>
          <Icon
            onClick={OpenCloseFontesRec}
            rotate={openedFontesRec ? 0 : 3}
            width={30}
            height={30}
            icon="iconamoon:arrow-down-2-light"
            style={{ cursor: "pointer" }}
          />
        </CCol>
        <CCol md={1} style={{ display: "flex", alignItems: "center" }}>
          <span>Receita</span>
        </CCol>
        <CCol style={{ display: "flex", alignItems: "center" }}>
          <span>R$ 45.850.725,00</span>
        </CCol>
        <CCol md={1} style={{ display: "flex", alignItems: "center" }}>
          <span>Despesa</span>
        </CCol>
        <CCol style={{ display: "flex", alignItems: "center" }}>
          <span>R$ 45.850.725,00</span>
        </CCol>
        <CCol md={1} style={{ display: "flex", alignItems: "center" }}>
          <span>Diferença</span>
        </CCol>
        <CCol style={{ display: "flex", alignItems: "center" }}>
          <span>R$ 00,00</span>
        </CCol>
      </Row>
      <MenuBox opened={openedFontesRec}>
        {Array.from({ length: 10 }, (_, index) => (
          <Row key={index}>
            <CCol
              md={1}
              style={{
                width: "40px",
                display: "flex",
                alignItems: "center",
              }}
            ></CCol>
            <CCol
              md={1}
              style={{
                width: "230px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>1500 - Recursos Livres...</span>
            </CCol>
            <CCol md={1}></CCol>
            <CCol style={{ display: "flex", alignItems: "center" }}>
              <span>R$ 150.000,00</span>
            </CCol>
            <CCol md={1}></CCol>
            <CCol style={{ display: "flex", alignItems: "center" }}>
              <span>R$ 150.000,00</span>
            </CCol>
            <CCol md={1}></CCol>
            <CCol style={{ display: "flex", alignItems: "center" }}>
              <span>R$ 00,00</span>
            </CCol>
          </Row>
        ))}
      </MenuBox>
      <hr />
      <Row>
        <CCol
          md={1}
          style={{
            width: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox onClick={handleCheckboxClick} />
        </CCol>
        <FitCCol>
          <span>Datas, Prazos (Conforme Lei Orgânica) e Índices</span>
        </FitCCol>
      </Row>
      <Row style={{ marginTop: "3px" }}>
        <CCol
          md={1}
          style={{
            width: "40px",
          }}
        >
          {""}
        </CCol>
        <CCol md={9}>
          <Row>
            <CCol md={2}>
              <InputDateRelatorio backgroundColor={"#fff"} />
            </CCol>
            <CCol
              style={{
                width: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>Projeto de Lei da LDO</span>
            </CCol>
            <CCol md={2}>
              <InputDateRelatorio backgroundColor={"#fff"} />
            </CCol>
            <CCol
              style={{
                width: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>Lei da LDO</span>
            </CCol>
            <CCol md={2}>
              <InputPercentage backgroundColor={"#fff"} />
            </CCol>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <CCol md={2}>
              <InputDateRelatorio backgroundColor={"#fff"} />
            </CCol>
            <CCol
              style={{
                width: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>Projeto de Lei da LOA</span>
            </CCol>
            <CCol md={2}>
              <InputDateRelatorio backgroundColor={"#fff"} />
            </CCol>
            <CCol
              style={{
                width: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>Lei da LOA</span>
            </CCol>
            <CCol md={2}>{""}</CCol>
          </Row>
        </CCol>
        <CCol>
          <span>
            Percentual de Crédito Adicional Autorizado pelo poder Legislativo
          </span>
        </CCol>
      </Row>
      <hr />
      <Row style={{ paddingRight: "2px" }}>
        <CCol
          md={1}
          style={{
            width: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox onClick={handleCheckboxClick} />
        </CCol>
        <CCol style={{ display: "flex", alignItems: "center" }}>
          <span>Anexar Recibo de Protocolo de Envio</span>
        </CCol>
      </Row>
      <Row>
        <CCol
          md={1}
          style={{
            width: "40px",
          }}
        >
          {""}
        </CCol>
        <CCol>
          <InputUpload backgroundColor={"#fff"} />
        </CCol>
        <CColButtonsAcoes2Icons
          md={1}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Icon
            icon="bx:edit"
            color="#5971C8"
            height="30"
            style={{
              cursor: "pointer",
            }}
          />
        </CColButtonsAcoes2Icons>
      </Row>
    </Forms>
  );
};

export default LdoMenu;
