import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CCol, CColButtonsAcoes } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import {
  AutocompleteListaDeInputs,
  FormControlListaDeInputs,
  PTitulosInputs,
  RowItemListaDeInputs,
  TextFieldListaDeInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import { Icon } from "@iconify/react";
// import { FormatContaPCASP } from "../../../../utils/FormatacaoDeDados/FormatContaPCASP";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { toast } from "react-toastify";
import { useFetchData } from "../../../../hooks/useFetchData";
import api from "../../../../utils/api";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
// import { FormatContaPCASPAutoComplete } from "../../../../utils/FormatacaoDeDados/FormatContaPCASPAutoComplete";

function ProcedimentosDespesa({
  valuesNaturezaDaDespesa,
  setValuesNaturezaDaDespesa,
}) {
  const { currentYear } = useCurrentYear();
  const { currentClient } = useContext(CurrentClientContext);
  const { data: dataContasPCASP } = useFetchData(
    `/pcasp/${currentClient.clienteId}/${currentYear}/todos?limit=10000`
  );
  const [optionsContasPCASP, setOptionsContasPCASP] = useState([]);

  useEffect(() => {
    if (dataContasPCASP) {
      setOptionsContasPCASP(
        dataContasPCASP.pcasp
          .filter((item) => item.conta[10] !== "0" || item.conta[11] !== "0")
          .map((conta) => ({
            titulo: conta.titulo,
            codigo: conta.conta.substring(0, 12),
          }))
      );
    }
  }, [dataContasPCASP]);

  const deletarDadosDeLancamento = (
    codigoConta,
    indexProcedimento,
    numeroDoPar
  ) => {
    let registrosSemValorPorPar = 0;
    let indexLimpezaDados = [
      ...valuesNaturezaDaDespesa.procedimentos[indexProcedimento].registros,
    ].findIndex((item) => item.contaContabil === codigoConta);

    let valuesLancamentoDeletado = { ...valuesNaturezaDaDespesa };
    valuesLancamentoDeletado.procedimentos[indexProcedimento].registros[
      indexLimpezaDados
    ] = {
      ...valuesLancamentoDeletado.procedimentos[indexProcedimento].registros[
        indexLimpezaDados
      ],
      contaContabil: "",
      titulo: "",
      origem: "",
      naturezaDaInfo: "",
    };

    valuesLancamentoDeletado.procedimentos[indexProcedimento].registros
      .filter((item) => item.par === numeroDoPar)
      .forEach((item) => {
        if (item.contaContabil === "") {
          registrosSemValorPorPar++;
        }
      });

    if (registrosSemValorPorPar === 2) {
      valuesLancamentoDeletado.procedimentos[indexProcedimento].registros =
        valuesLancamentoDeletado.procedimentos[
          indexProcedimento
        ].registros.filter((item) => item.par !== numeroDoPar);
    }

    console.log(valuesLancamentoDeletado);

    setValuesNaturezaDaDespesa({ ...valuesLancamentoDeletado });
  };

  const adicionaNovoLancamento = (passo) => {
    if (valuesNaturezaDaDespesa.naturezaDaDespesaId !== 0) {
      const indexInsercao = valuesNaturezaDaDespesa.procedimentos.findIndex(
        (item) => item.passo === passo
      );
      const tamanhoArrayDeRegistros =
        valuesNaturezaDaDespesa.procedimentos[indexInsercao].registros.length;
      let numeroDoPar = 1;

      if (tamanhoArrayDeRegistros > 0) {
        numeroDoPar =
          valuesNaturezaDaDespesa.procedimentos[indexInsercao].registros[
            tamanhoArrayDeRegistros - 1
          ].par + 1;
      }

      const estruturaLancamentoPar = [
        {
          contaContabil: "",
          titulo: "",
          origem: "",
          naturezaDaInfo: "",
          par: numeroDoPar,
        },
        {
          contaContabil: "",
          titulo: "",
          origem: "",
          naturezaDaInfo: "",
          par: numeroDoPar,
        },
      ];
      console.log(estruturaLancamentoPar);

      let naturezaValuesInsertNovosLancamentos = { ...valuesNaturezaDaDespesa };
      naturezaValuesInsertNovosLancamentos.procedimentos[
        indexInsercao
      ].registros.push(...estruturaLancamentoPar);

      console.log(naturezaValuesInsertNovosLancamentos);

      setValuesNaturezaDaDespesa(naturezaValuesInsertNovosLancamentos);
    } else {
      toast.warning(
        "Procedimentos só podem ser adicionados quando uma natureza da despesa for especificada."
      );
    }
  };

  const preencheCamposLancamento = (
    value,
    indexProcedimento,
    indexRegistro
  ) => {
    let naturezaValuesPreencheCampos = { ...valuesNaturezaDaDespesa };

    naturezaValuesPreencheCampos.procedimentos[indexProcedimento].registros[
      indexRegistro
    ].contaContabil = value.codigo;

    console.log(
      "PREENCHE CAMPOS: ",
      naturezaValuesPreencheCampos.procedimentos[indexProcedimento].registros[
        indexRegistro
      ]
    );

    let naturezaInformacao = "";

    if (parseInt(value.codigo[0]) >= 1 && parseInt(value.codigo[0]) <= 4) {
      naturezaInformacao = "Patrimonial";
    } else if (
      parseInt(value.codigo[0]) >= 5 &&
      parseInt(value.codigo[0]) <= 6
    ) {
      naturezaInformacao = "Orçamentária";
    } else {
      naturezaInformacao = "Controle";
    }

    if (value.codigo[0] !== "0") {
      api
        .get(
          `pcasp/${currentClient.clienteId}/ano/${currentYear}/filtro?filter=${value.codigo}`
        )
        .then((resp) => {
          const data = resp.data.pcasp;
          const filteredWithTitle = data.find(
            (item) => item.titulo === value.titulo
          );
          console.log("OQ O FILTERED TEM", filteredWithTitle);
          if (filteredWithTitle) {
            let valuesCampos = {
              ...naturezaValuesPreencheCampos.procedimentos[indexProcedimento]
                .registros[indexRegistro],
              naturezaDaInfo: naturezaInformacao,
              origem:
                filteredWithTitle.NaturezaDeSaldo === "Devedora" ? "D" : "C",
              titulo: filteredWithTitle.titulo,
            };

            console.log("E ESSE?: ", valuesCampos);
            naturezaValuesPreencheCampos.procedimentos[
              indexProcedimento
            ].registros[indexRegistro] = valuesCampos;
            setValuesNaturezaDaDespesa({ ...naturezaValuesPreencheCampos });
          } else {
            toast.warning(
              "Não existe nenhuma conta com essa combinação númerica"
            );
          }
        });
    } else {
      let valuesCampos = {
        ...naturezaValuesPreencheCampos.procedimentos[indexProcedimento]
          .registros[indexRegistro],
        naturezaDaInfo: "",
        origem: "",
        titulo: "",
      };

      naturezaValuesPreencheCampos.procedimentos[indexProcedimento].registros[
        indexRegistro
      ] = valuesCampos;

      setValuesNaturezaDaDespesa({ ...naturezaValuesPreencheCampos });
    }
  };

  const selecionarCorInputNatInformacao = (valor) => {
    if (valor >= 1 && valor <= 4) {
      return "#C6D9FC";
    } else if (valor >= 5 && valor <= 6) {
      return "#F5D0F5";
    } else if (valor >= 7 && valor <= 8) {
      return "#cdeeb4";
    } else {
      return null;
    }
  };

  const handleInputNatureza = (valor, indexProcedimento, indexRegistro) => {
    console.log(valor);
    let naturezaValuesPreencheCampos = { ...valuesNaturezaDaDespesa };

    naturezaValuesPreencheCampos.procedimentos[indexProcedimento].registros[
      indexRegistro
    ].contaContabil = valor;
  };

  const handleAutocompleteChange = (
    option,
    indexProcedimento,
    indexRegistro
  ) => {
    console.log(option);
    console.log(indexProcedimento, indexRegistro);
    preencheCamposLancamento(option, indexProcedimento, indexRegistro);
  };

  return (
    <>
      <Row>
        <Col
          className="d-flex align-items-center p-2 row_endereco_conteudo"
          md={12}
        >
          <span className="mx-4">PCASP</span>
        </Col>
      </Row>

      <Row className="row_form mt-1">
        <CCol>
          <FloatingLabelInput
            style={{ border: "none" }}
            disabled
            value="1 - No momento da abertura do Orçamento"
          />
        </CCol>
        <CColButtonsAcoes md={1}>
          {valuesNaturezaDaDespesa.procedimentos.find(
            (item) => item.passo === 1
          ).registros.length === 0 && (
            <Icon
              onClick={() => adicionaNovoLancamento(1)}
              icon="ic:baseline-add-box"
              color="#105200"
              height="28"
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "4px",
              }}
            />
          )}
        </CColButtonsAcoes>
      </Row>

      {valuesNaturezaDaDespesa.procedimentos[0].registros.length > 0 && (
        <Row className="row_form" style={{ marginTop: "-10px" }}>
          <CCol md={8}>
            <PTitulosInputs>Conta Corrente</PTitulosInputs>
          </CCol>
          <CCol md={1}>
            <PTitulosInputs>Origem</PTitulosInputs>
          </CCol>
          <CCol>
            <PTitulosInputs>Natureza da Informação</PTitulosInputs>
          </CCol>
          <CColButtonsAcoes md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
          </CColButtonsAcoes>
        </Row>
      )}

      {/* ------------------------------------------------------------------------- */}
      <Row
        className="row_form"
        style={{
          marginTop: "-10px",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        {valuesNaturezaDaDespesa.procedimentos[0].registros.map(
          (item, index, selfArray) => (
            <RowItemListaDeInputs key={index}>
              <CCol md={8}>
                <MyAutoComplete
                  placeholder={"Código da conta ou Título"}
                  inputValue={
                    valuesNaturezaDaDespesa.procedimentos[0].registros[index]
                      .contaContabil !== "" &&
                    `${valuesNaturezaDaDespesa.procedimentos[0].registros[index].contaContabil} - ${valuesNaturezaDaDespesa.procedimentos[0].registros[index].titulo}`
                  }
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  iconClearFunction={() => console.log("cleared")}
                  options={optionsContasPCASP}
                  onOptionSelect={(item) => {
                    handleAutocompleteChange(item, 0, index);
                  }}
                  labelFormat={(option) =>
                    `${option.codigo} - ${option.titulo}`
                  }
                />
              </CCol>
              <CCol md={1}>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.origem}
                  disabled
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  disabledbg={selecionarCorInputNatInformacao(
                    parseInt(item.contaContabil[0])
                  )}
                  value={item.naturezaDaInfo}
                  disabled
                />
              </CCol>
              <CColButtonsAcoes md={1}>
                <Icon
                  onClick={() => {
                    deletarDadosDeLancamento(item.contaContabil, 0, item.par);
                  }}
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes>
              {(index + 1) % 2 === 0 ? <div className="p-2"></div> : null}
            </RowItemListaDeInputs>
          )
        )}
      </Row>

      {/* Passo 2 */}
      <Row className="row_form">
        <CCol>
          <FloatingLabelInput
            style={{ border: "none" }}
            disabled
            value="2 - No momento da Despesa Orçamentaria"
          />
        </CCol>
        <CColButtonsAcoes md={1}>
          <Icon
            onClick={() => adicionaNovoLancamento(2)}
            icon="ic:baseline-add-box"
            color="#105200"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              marginTop: "4px",
            }}
          />
        </CColButtonsAcoes>
      </Row>

      {valuesNaturezaDaDespesa.procedimentos[1].registros.length > 0 && (
        <Row className="row_form" style={{ marginTop: "-10px" }}>
          <CCol md={8}>
            <PTitulosInputs>Conta Corrente</PTitulosInputs>
          </CCol>
          <CCol md={1}>
            <PTitulosInputs>Origem</PTitulosInputs>
          </CCol>
          <CCol>
            <PTitulosInputs>Natureza da Informação</PTitulosInputs>
          </CCol>
          <CColButtonsAcoes md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
          </CColButtonsAcoes>
        </Row>
      )}
      {/* ------------------------------------------------------------------------- */}
      <Row
        className="row_form"
        style={{
          marginTop: "-10px",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        {valuesNaturezaDaDespesa.procedimentos[1].registros.map(
          (item, index, selfArray) => (
            <RowItemListaDeInputs key={index}>
              <CCol md={8}>
                <MyAutoComplete
                  placeholder={"Código da conta ou Título"}
                  inputValue={
                    valuesNaturezaDaDespesa.procedimentos[1].registros[index]
                      .contaContabil !== "" &&
                    `${valuesNaturezaDaDespesa.procedimentos[1].registros[index].contaContabil} - ${valuesNaturezaDaDespesa.procedimentos[1].registros[index].titulo}`
                  }
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  iconClearFunction={() => console.log("cleared")}
                  options={optionsContasPCASP}
                  onOptionSelect={(item) => {
                    handleAutocompleteChange(item, 1, index);
                  }}
                  labelFormat={(option) =>
                    `${option.codigo} - ${option.titulo}`
                  }
                />
              </CCol>
              <CCol md={1}>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.origem}
                  disabled
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.naturezaDaInfo}
                  disabledbg={selecionarCorInputNatInformacao(
                    parseInt(item.contaContabil[0])
                  )}
                  disabled
                />
              </CCol>
              <CColButtonsAcoes md={1}>
                <Icon
                  onClick={() => {
                    deletarDadosDeLancamento(item.contaContabil, 1, item.par);
                  }}
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes>
              {(index + 1) % 2 === 0 ? <div className="p-2"></div> : null}
            </RowItemListaDeInputs>
          )
        )}
      </Row>

      {/* Passo 3 */}
      <Row className="row_form">
        <CCol>
          <FloatingLabelInput
            style={{ border: "none" }}
            disabled
            value="3 - No momento da ocorrência do fato gerador"
          />
        </CCol>
        <CColButtonsAcoes md={1}>
          <Icon
            onClick={() => adicionaNovoLancamento(3)}
            icon="ic:baseline-add-box"
            color="#105200"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              marginTop: "4px",
            }}
          />
        </CColButtonsAcoes>
      </Row>
      {valuesNaturezaDaDespesa.procedimentos[2].registros.length > 0 && (
        <Row className="row_form" style={{ marginTop: "-10px" }}>
          <CCol md={8}>
            <PTitulosInputs>Conta Corrente</PTitulosInputs>
          </CCol>
          <CCol md={1}>
            <PTitulosInputs>Origem</PTitulosInputs>
          </CCol>
          <CCol>
            <PTitulosInputs>Natureza da Informação</PTitulosInputs>
          </CCol>
          <CColButtonsAcoes md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
          </CColButtonsAcoes>
        </Row>
      )}
      {/* ------------------------------------------------------------------------- */}
      <Row
        className="row_form"
        style={{
          marginTop: "-10px",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        {valuesNaturezaDaDespesa.procedimentos[2].registros.map(
          (item, index, selfArray) => (
            <RowItemListaDeInputs key={index}>
              <CCol md={8}>
                <MyAutoComplete
                  placeholder={"Código da conta ou Título"}
                  inputValue={
                    valuesNaturezaDaDespesa.procedimentos[2].registros[index]
                      .contaContabil !== "" &&
                    `${valuesNaturezaDaDespesa.procedimentos[2].registros[index].contaContabil} - ${valuesNaturezaDaDespesa.procedimentos[2].registros[index].titulo}`
                  }
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  iconClearFunction={() => console.log("cleared")}
                  options={optionsContasPCASP}
                  onOptionSelect={(item) => {
                    handleAutocompleteChange(item, 2, index);
                  }}
                  labelFormat={(option) =>
                    `${option.codigo} - ${option.titulo}`
                  }
                />
              </CCol>
              <CCol md={1}>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.origem}
                  disabled
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.naturezaDaInfo}
                  disabled
                  disabledbg={selecionarCorInputNatInformacao(
                    parseInt(item.contaContabil[0])
                  )}
                />
              </CCol>
              <CColButtonsAcoes md={1}>
                <Icon
                  onClick={() => {
                    deletarDadosDeLancamento(item.contaContabil, 2, item.par);
                  }}
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes>
              {(index + 1) % 2 === 0 ? <div className="p-2"></div> : null}
            </RowItemListaDeInputs>
          )
        )}
      </Row>

      {/* Passo 4 */}
      <Row className="row_form">
        <CCol>
          <FloatingLabelInput
            style={{ border: "none" }}
            disabled
            value="4 - No momento da liquidação da despesa orçamentária"
          />
        </CCol>
        <CColButtonsAcoes md={1}>
          <Icon
            onClick={() => adicionaNovoLancamento(4)}
            icon="ic:baseline-add-box"
            color="#105200"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              marginTop: "4px",
            }}
          />
        </CColButtonsAcoes>
      </Row>

      {valuesNaturezaDaDespesa.procedimentos[3].registros.length > 0 && (
        <Row className="row_form" style={{ marginTop: "-10px" }}>
          <CCol md={8}>
            <PTitulosInputs>Conta Corrente</PTitulosInputs>
          </CCol>
          <CCol md={1}>
            <PTitulosInputs>Origem</PTitulosInputs>
          </CCol>
          <CCol>
            <PTitulosInputs>Natureza da Informação</PTitulosInputs>
          </CCol>
          <CColButtonsAcoes md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
          </CColButtonsAcoes>
        </Row>
      )}
      {/* ------------------------------------------------------------------------- */}
      <Row
        className="row_form"
        style={{
          marginTop: "-10px",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        {valuesNaturezaDaDespesa.procedimentos[3].registros.map(
          (item, index, selfArray) => (
            <RowItemListaDeInputs key={index}>
              <CCol md={8}>
                <MyAutoComplete
                  placeholder={"Código da conta ou Título"}
                  inputValue={
                    valuesNaturezaDaDespesa.procedimentos[3].registros[index]
                      .contaContabil !== "" &&
                    `${valuesNaturezaDaDespesa.procedimentos[3].registros[index].contaContabil} - ${valuesNaturezaDaDespesa.procedimentos[3].registros[index].titulo}`
                  }
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  iconClearFunction={() => console.log("cleared")}
                  options={optionsContasPCASP}
                  onOptionSelect={(item) => {
                    handleAutocompleteChange(item, 3, index);
                  }}
                  labelFormat={(option) =>
                    `${option.codigo} - ${option.titulo}`
                  }
                />
              </CCol>
              <CCol md={1}>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.origem}
                  disabled
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.naturezaDaInfo}
                  disabled
                  disabledbg={selecionarCorInputNatInformacao(
                    parseInt(item.contaContabil[0])
                  )}
                />
              </CCol>
              <CColButtonsAcoes md={1}>
                <Icon
                  onClick={() => {
                    deletarDadosDeLancamento(item.contaContabil, 3, item.par);
                  }}
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes>
              {(index + 1) % 2 === 0 ? <div className="p-2"></div> : null}
            </RowItemListaDeInputs>
          )
        )}
      </Row>

      {/* Passo 5 */}
      <Row className="row_form">
        <CCol>
          <FloatingLabelInput
            style={{ border: "none" }}
            disabled
            value="5 - No momento do pagamento da despesa orçamentária"
          />
        </CCol>
        <CColButtonsAcoes md={1}>
          <Icon
            onClick={() => adicionaNovoLancamento(5)}
            icon="ic:baseline-add-box"
            color="#105200"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              marginTop: "4px",
            }}
          />
        </CColButtonsAcoes>
      </Row>

      {valuesNaturezaDaDespesa.procedimentos[4].registros.length > 0 && (
        <Row className="row_form" style={{ marginTop: "-10px" }}>
          <CCol md={8}>
            <PTitulosInputs>Conta Corrente</PTitulosInputs>
          </CCol>
          <CCol md={1}>
            <PTitulosInputs>Origem</PTitulosInputs>
          </CCol>
          <CCol>
            <PTitulosInputs>Natureza da Informação</PTitulosInputs>
          </CCol>
          <CColButtonsAcoes md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
          </CColButtonsAcoes>
        </Row>
      )}
      {/* ------------------------------------------------------------------------- */}
      <Row
        className="row_form"
        style={{
          marginTop: "-10px",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        {valuesNaturezaDaDespesa.procedimentos[4].registros.map(
          (item, index, selfArray) => (
            <RowItemListaDeInputs key={index}>
              <CCol md={8}>
                <AutocompleteListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  freeSolo
                  id="cnpj-autocomplete"
                  disableClearable
                  options={optionsContasPCASP}
                  onInputChange={(e, newValue) => {
                    handleInputNatureza(newValue, 4, index);
                  }}
                  onChange={(event, newValue) =>
                    handleAutocompleteChange(event, newValue, 4, index)
                  }
                  getOptionLabel={(option) => `${option.codigo}`}
                  renderInput={(params) => (
                    <TextFieldListaDeInputs
                      {...params}
                      placeholder="Código da conta PCASP"
                      inputProps={{
                        ...params.inputProps,
                        maxLength: 12,
                        value:
                          valuesNaturezaDaDespesa.procedimentos[4].registros[
                            index
                          ].contaContabil,
                      }}
                      size="small"
                    />
                  )}
                />
              </CCol>
              <CCol md={1}>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.origem}
                  disabled
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.naturezaDaInfo}
                  disabled
                  disabledbg={selecionarCorInputNatInformacao(
                    parseInt(item.contaContabil[0])
                  )}
                />
              </CCol>
              <CColButtonsAcoes md={1}>
                <Icon
                  onClick={() => {
                    deletarDadosDeLancamento(item.contaContabil, 4, item.par);
                  }}
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes>
              {(index + 1) % 2 === 0 ? <div className="p-2"></div> : null}
            </RowItemListaDeInputs>
          )
        )}
      </Row>
    </>
  );
}

export default ProcedimentosDespesa;
