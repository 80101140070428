import styled, { css } from "styled-components";

export const LocalSelectContainer = styled.div`
  background-color: #fafafa;
  box-shadow: 0px 4px 16px -6px rgba(0, 0, 0, 0.75);
  min-width: 298px;
  position: absolute;
  z-index: 999;
  top: 42px;
  color: #515c70;
  padding: 0 8px 8px;
  border-radius: 0 0 10px 10px;
  margin-left: 20px;
`;

export const PTitulo = styled.p`
  white-space: nowrap;
  padding: 9px 20px;
  margin-bottom: 0px;
  font-weight: 600;
`;

export const ContainerItems = styled.div`
  max-height: 305px;
  ::-webkit-scrollbar {
    width: 2px; /* Largura da barra de rolagem */
  }

  /* Estilizando o track (trilha) da barra de rolagem */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Cor de fundo do track */
  }

  /* Estilizando o thumb (ponteiro) da barra de rolagem */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Cor do thumb */
  }

  /* Estilizando o thumb ao passar o mouse por cima */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Cor do thumb ao passar o mouse por cima */
  }
  /* overflow-y: auto; */
  ${({ hasScroll }) =>
    hasScroll &&
    css`
      overflow-y: auto;
    `}
`;
export const ButtonItem = styled.button`
  display: flex;
  align-items: center;
  color: #515c70;
  padding: 9px 20px;
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  font-weight: 500;
  border-top: 1px solid #ededed;
  &:hover {
    background-color: #d9d9d980;
  }
  ${({ selectedButton }) =>
    selectedButton &&
    css`
      background-color: #d9d9d980;
    `}
`;

export const SelectBar = styled.button`
  width: 100%;
  border-color: rgba(255, 255, 255, 1);
  height: 40px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px #9399a4;
  margin-right: 20px;
  font-size: 16px;
  color: #515c70;
  font-weight: 600;
  text-transform: uppercase;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' transform='rotate(270)' viewBox='0 0 24 24'%3E%3Cpath fill='%23515c70' d='M12.727 3.687a1 1 0 1 0-1.454-1.374l-8.5 9a1 1 0 0 0 0 1.374l8.5 9.001a1 1 0 1 0 1.454-1.373L4.875 12z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) calc(50% - 1px);
  background-size: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: none;
`;

export const CidadeGestoraSelectContainer = styled(LocalSelectContainer)`
  width: 98%;
  top: 50px;
  margin-left: 0px;
`;
