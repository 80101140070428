import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { TituloAbaSolo } from "../../../../styles/StyledComponents/LayoutGerais";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import { PTitulosInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Icon } from "@iconify/react";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import { TestButton } from "../../../../utils/TestButton";

const Expansao = ({
  arrayEspecificacoesNominal,
  setArrayEspecificacoesNominal,
  valueEspecificacao,
  setValueEspecificacao,
}) => {
  const selectTiposOptions = [
    {
      value: "Aumento Permanente da Receita",
      label: "Aumento Permanente da Receita",
    },
    {
      value: "(-) Transferências Constitucionais",
      label: "(-) Transferências Constitucionais",
    },
    {
      value: "(-) Transferências ao Fundeb",
      label: "(-) Transferências ao Fundeb",
    },
    {
      value: "Redução Permanente de Despesa",
      label: "Redução Permanente de Despesa",
    },
    { value: "Novas DOCC", label: "Novas DOCC" },
    {
      value: "Novas DOCC geradas por PPP",
      label: "Novas DOCC geradas por PPP",
    },
  ];

  const [values, setValues] = useState([
    {
      especificacao: "Receita Administrada pela RFP",
      valor: "",
      editMode: false,
    },
  ]);

  const adicionarEspecificacao = () => {
    if (valueEspecificacao) {
      setValues((prev) => [
        ...prev,
        { especificacao: valueEspecificacao, valor: "", editMode: false },
      ]);
    }
  };

  const toggleEditMode = (index) => {
    setValues((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, editMode: !item.editMode } : item
      )
    );
  };

  const saveChanges = (index) => {
    setValues((prev) =>
      prev.map((item, i) => (i === index ? { ...item, editMode: false } : item))
    );
  };

  const handleValorChange = (index, value) => {
    setValues((prev) =>
      prev.map((item, i) => (i === index ? { ...item, valor: value } : item))
    );
  };

  const removerEspecificacao = (index) => {
    setValues((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <>
      <Row className="row_form mt-1">
        <TituloAbaSolo>
          Margem de Expansão das Despesas Obrigatórias de Caráter Continuado
        </TituloAbaSolo>
      </Row>
      <Row
        className="row_form mt-1"
        style={
          arrayEspecificacoesNominal.length === 0
            ? { borderRadius: "0px 0px 5px 5px" }
            : {}
        }
      >
        <CCol>
          <FloatingLabelInput
            placeholder="Eventos"
            new={true}
            label={"Eventos"}
            tipo="input_select"
            options={[...selectTiposOptions]}
            onSelect={(option) => setValueEspecificacao(option.value)}
            value={valueEspecificacao}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar onClick={adicionarEspecificacao} />
        </CColButtonsAcoes2Icons>
      </Row>

      {values.map((item, index) => {
        const isEditing = item.editMode;
        return (
          <React.Fragment key={index}>
            <Row className="row_form mt-1">
              <RowAninhada style={{ paddingRight: "0" }}>
                <CCol>
                  <PTitulosInputs style={{ marginTop: "0px" }}>
                    Especificação
                  </PTitulosInputs>
                  <FloatingLabelInput
                    disabled
                    value={item.especificacao}
                    style={{ border: "none" }}
                    height={"30px"}
                  />
                </CCol>
                <CCol>
                  <PTitulosInputs style={{ marginTop: "0px" }}>
                    Valor
                  </PTitulosInputs>
                  <FloatingLabelInput
                    disabled={!isEditing}
                    height={"30px"}
                    value={item.valor}
                    onChange={(e) => handleValorChange(index, e.target.value)}
                  />
                </CCol>
                <CColButtonsAcoes2Icons md={1} style={{ marginTop: "8px" }}>
                  <PTitulosInputs>Ação</PTitulosInputs>
                  {isEditing ? (
                    <>
                      <Icon
                        icon="fluent:save-24-filled"
                        color="#008CFF"
                        height="26"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "3px",
                        }}
                        onClick={() => saveChanges(index)}
                      />
                      <Icon
                        icon="ic:baseline-cancel"
                        height="26"
                        color="#F24E1E"
                        style={{
                          cursor: "pointer",
                          marginLeft: "2px",
                          marginRight: "-5px",
                          marginTop: "3px",
                        }}
                        onClick={() => toggleEditMode(index)}
                      />
                    </>
                  ) : (
                    <Icon
                      icon="bx:edit"
                      color="#5971C8"
                      height="26"
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        marginTop: "3px",
                      }}
                      onClick={() => toggleEditMode(index)}
                    />
                  )}
                  {!isEditing && (
                    <Icon
                      icon="ic:baseline-delete"
                      color="#E79900"
                      height="28"
                      style={{
                        cursor: "pointer",
                        marginLeft: "2px",
                        marginTop: "3px",
                      }}
                      onClick={() => removerEspecificacao(index)}
                    />
                  )}
                </CColButtonsAcoes2Icons>
              </RowAninhada>
            </Row>
          </React.Fragment>
        );
      })}

      <TestButton onClick={() => console.log(values)}>console</TestButton>
    </>
  );
};

export default Expansao;
