import React, { useState } from "react";
import {
  CCol,
  ContainerToModal,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import Contrato from "./Contrato";
import Atualizacao from "./Atualizacao";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";

export const RowInfosAcao = styled(Row)`
  align-content: baseline;
  min-height: ${({ minHeight }) => minHeight || "80px"};
  margin: 0px;
  border-radius: 10px;
  background-color: #ebeaea;
  padding: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  resize: vertical;
  border-radius: 5px;
  border: 1px solid #ccc;
  position: relative;

  span {
    color: #73767e;
  }

  p {
    margin: 0px;
    margin-left: 10px;
    color: #515c70;
    font-weight: 500;
    font-size: 15.5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span + p {
    display: inline;
  }
`;

const DividaPublica = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    numeroAba: 0,
  });

  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Dívida Pública"}
        PaginaSubtitulo={"Cadastro"}
        RowTitle={"CDP - Cadastro da Dívida Pública"}
        BotaoSaveAtivo={false}
        BotaoNovoAtivo={false}
      />
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={2}>
            <FloatingLabelInput label={"CPD"} placeholder={"CPD"} />
          </CCol>
          <CCol md={3}>
            <FloatingLabelInput
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              tipo="input_select"
              label="Tipo de Contrato"
              placeholder="Tipo de Contrato"
              options={[
                { label: "Tipo de Contrato", value: "Tipo de Contrato" },
              ]}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              tipo="input_select"
              label="Tipo de Dívida"
              placeholder="Tipo de Dívida"
              options={[{ label: "Tipo de Dívida", value: "Tipo de Dívida" }]}
            />
          </CCol>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol>
            <FloatingLabelInput
              tipo="text_area"
              as="textarea"
              label="Descrição / Finalidade"
              placeholder="Descrição / Finalidade"
              style={{ minHeight: "98px" }}
            />
          </CCol>
        </RowAninhada>
      </Row>
      <div style={{ minHeight: "13px" }}></div>

      <Row className="row_form mt-1">
        <CCol md={3}>
          <FloatingLabelInput
            new={true}
            holdSelect
            style={{
              color: "#515c70",
            }}
            tipo="input_select"
            label="Tipo de Credor"
            placeholder="Tipo de Credor"
            options={[{ label: "Tipo de Credor", value: "Tipo de Credor" }]}
          />
        </CCol>
        <CCol>
          <MyAutoComplete
            labelInput={"Credor"}
            options={[{ label: "Credor", value: "Credor" }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
            style={{ height: "38px" }}
          />
        </CCol>
      </Row>

      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Contrato
          </span>
        </CCol>
      </Row>
      <Contrato />
      <>
        <Row className="mt-3">
          <CCol
            className="d-flex align-items-center p-2 header_body_conteudo"
            md={12}
            style={{ justifyContent: "left" }}
          >
            <span
              style={{
                fontSize: "16.5px",
                fontWeight: abaSelecionada.numeroAba === 0 ? 600 : 400,
                marginLeft: "10px",
                cursor: "pointer",
              }}
              onClick={() =>
                setAbaSelecionada((prevValues) => ({
                  ...prevValues,
                  numeroAba: 0,
                }))
              }
            >
              Atualização
            </span>
            <span
              style={{
                fontSize: "16.5px",
                fontWeight: abaSelecionada.numeroAba === 1 ? 600 : 500,
                cursor: "pointer",
                marginLeft: "35px",
              }}
              onClick={() =>
                setAbaSelecionada((prevValues) => ({
                  ...prevValues,
                  numeroAba: 1,
                }))
              }
            >
              Procedimentos Contábeis
            </span>
            <span
              style={{
                fontSize: "16.5px",
                fontWeight: abaSelecionada.numeroAba === 2 ? 600 : 500,
                cursor: "pointer",
                marginLeft: "35px",
              }}
              onClick={() =>
                setAbaSelecionada((prevValues) => ({
                  ...prevValues,
                  numeroAba: 2,
                }))
              }
            >
              Saldos
            </span>
          </CCol>
        </Row>
        {abaSelecionada.numeroAba === 0 && <Atualizacao />}
      </>
      <div style={{ minHeight: "40px" }}> </div>
    </ContainerToModal>
  );
};

export default DividaPublica;
