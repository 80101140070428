import React from "react";
import {
  CCol,
  ContainerToModal,
  RowToModal,
} from "../../../components/Grid/CCol";
import HeaderCadastros from "../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";
import InputFormatRealBrasileiro from "../../../components/Grid/InputFormatRealBrasileiro";
import MyAutoComplete from "../../../components/Inputs/MyAutoComplete";
import styled from "styled-components";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const EVACadastro = () => {
  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Eventos Agendados - EVA"}
        PaginaSubtitulo={"Cadastro"}
      />

      <RowToModal className="row_form mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput placeholder="Número" label="Número" disabled />
        </CColNumero>
        <CCol md={3}>
          <FloatingLabelInput
            new={true}
            tipo="input_select"
            label="Indicador de Lançamento"
            placeholder="Indicador de Lançamento"
            options={[
              { label: "Indicador de Lançamento", value: "" },
              { label: "Teste", value: "Teste" },
            ]}
          />
        </CCol>
        <CCol>
          <MyAutoComplete
            labelInput={"Documento Padrão"}
            placeholder="Documento Padrão"
            style={{ height: "38px" }}
            options={[{ label: "Teste", value: 1 }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            tipo="input_select"
            new={true}
            label="Recorrência"
            placeholder="Recorrência"
            options={[
              { label: "Recorrência", value: "" },
              { label: "Teste", value: "Teste" },
            ]}
          />
        </CCol>
        <CCol md={1}>
          <FloatingLabelInput
            new={true}
            tipo="input_select"
            label="Dia"
            placeholder="Dia"
            options={[
              { label: "Dia", value: "" },
              { label: "Teste", value: "Teste" },
            ]}
          />
        </CCol>
        <CCol md={2}>
          <InputFormatRealBrasileiro />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol>
          <FloatingLabelInput label="Ficha" placeholder="Ficha" disabled />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol>
          <FloatingLabelInput
            label="Fornecedor"
            placeholder="Fornecedor"
            disabled
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form_2" style={{ marginTop: "-15px" }}>
        <CCol>
          <FloatingLabelInput
            tipo="text_area"
            voiceInput
            as="textarea"
            label="Descrição Padrão"
            placeholder="Descrição Padrão"
            style={{ minHeight: "98px" }}
          />
        </CCol>
      </RowToModal>
    </ContainerToModal>
  );
};

export default EVACadastro;
