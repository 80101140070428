import React from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import { PTitulosInputs } from "../../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../../components/ListaDeInputs/IconAdicionar";
import { InputDateRelatorio } from "../../../../../components/Inputs/InputDateRelatorio";
import { ProgressBarComponent } from "../../../../../components/Grid/ProgressBarComponent";

const Principal = () => {
  return (
    <>
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Mês Início</PTitulosInputs>
            <InputDateRelatorio
              label={"Mês Início"}
              placeholder={"Mês Início"}
              little
            />
          </CCol>
          <CCol md={1} style={{ width: "9em" }}>
            <PTitulosInputs>Mês Final</PTitulosInputs>
            <InputDateRelatorio
              label={"Mês Final"}
              placeholder={"Mês Final"}
              little
            />
          </CCol>
          <CCol>
            <PTitulosInputs> &nbsp; </PTitulosInputs>
            <ProgressBarComponent
              height={30}
              percentage={40}
              label={"Processando dados Licitação"}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <IconAdicionar />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
      </Row>
    </>
  );
};

export default Principal;
