import React, { useEffect, useState } from "react";
import axios from "axios";

import { CCol } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Row } from "react-bootstrap";
import { FormatCep } from "../../../../utils/FormatacaoDeDados/FormatCEP";
import { FormatNumeroCelular } from "../../../../utils/FormatacaoDeDados/FormatNumeroCelular";

function EnderecoCpf({ values, setValues }) {
  const [teclaPressionada, setTeclaPressionada] = useState(0);
  const [cepFormatado, setCepFormatado] = useState("");
  const handleCepChange = () => {
    console.log(values.Endereco.cep);
    axios
      .get(`https://brasilapi.com.br/api/cep/v1/${values.Endereco.cep}`)
      .then((response) => {
        const { street, neighborhood, city, state } = response.data;
        setValues((prevValues) => ({
          ...prevValues,
          Endereco: {
            ...prevValues.Endereco,
            logradouro: street,
            bairro: neighborhood,
            municipio: city,
            estado: state,
          },
        }));
      })
      .catch((error) => {
        console.error("Erro ao consultar o CEP:", error);
      });
  };

  useEffect(() => {
    if (values.Endereco.cep.length === 8) {
      handleCepChange();
      console.log("bora");
    }
    let cepFormatted = "";

    for (let i = 0; i < values.Endereco.cep.length; i++) {
      cepFormatted += values.Endereco.cep[i];
      if (i === 4 && cepFormatado.length > 6) {
        cepFormatted += "-";
      }
    }

    setCepFormatado(cepFormatted);
    //eslint-disable-next-line
  }, [values.Endereco.cep]);

  return (
    <>
      <Row>
        <CCol
          className="d-flex align-items-center row_endereco_aba mt-1"
          md={12}
        >
          <span className="span_endereco">Endereço</span>
        </CCol>
      </Row>
      <Row>
        <CCol
          className="d-flex align-items-center p-2 row_endereco_conteudo"
          md={12}
        >
          <span className="span_endereco_titulo">Endereço</span>
        </CCol>
      </Row>
      <Row className="row_form_2 mt-1">
        <CCol md={2}>
          <FloatingLabelInput
            id="campo_cep"
            type="text"
            maxLength="9"
            value={cepFormatado}
            onKeyDown={(e) => setTeclaPressionada(e.keyCode)}
            placeholder="CEP"
            label="CEP"
            required
            onChange={(e) => {
              if (teclaPressionada !== 8) {
                FormatCep(e.target);
              }

              setValues({
                ...values,
                Endereco: {
                  ...values.Endereco,
                  cep: e.target.value.replace(/[^0-9]/g, ""),
                },
              });
            }}
          />
        </CCol>
        <CCol md={5}>
          <FloatingLabelInput
            disabled
            type="text"
            value={values.Endereco.logradouro}
            onChange={(e) =>
              setValues({
                ...values,
                Endereco: {
                  ...values.Endereco,
                  logradouro: e.target.value,
                },
              })
            }
            placeholder="Logradouro"
            label="Logradouro"
          />
        </CCol>
        <CCol md={1}>
          <FloatingLabelInput
            type="text"
            placeholder="N°"
            label="N°"
            value={values.Endereco.numero}
            onChange={(e) =>
              setValues({
                ...values,
                Endereco: {
                  ...values.Endereco,
                  numero: e.target.value,
                },
              })
            }
          />
        </CCol>
        <CCol md={4}>
          <FloatingLabelInput
            type="text"
            placeholder="Complemento"
            label="Complemento"
            value={values.Endereco.complemento}
            required
            onChange={(e) =>
              setValues({
                ...values,
                Endereco: {
                  ...values.Endereco,
                  complemento: e.target.value,
                },
              })
            }
          />
        </CCol>
        <div className="pt-3"></div>
        <CCol md={6}>
          <FloatingLabelInput
            disabled
            type="text"
            value={values.Endereco.bairro}
            onChange={(e) =>
              setValues({
                ...values,
                Endereco: {
                  ...values.Endereco,
                  bairro: e.target.value,
                },
              })
            }
            placeholder="Bairro"
            label="Bairro"
          />
        </CCol>
        <CCol md={4}>
          <FloatingLabelInput
            disabled
            type="text"
            value={values.Endereco.municipio}
            onChange={(e) =>
              setValues({
                ...values,
                Endereco: {
                  ...values.Endereco,
                  municipio: e.target.value,
                },
              })
            }
            placeholder="Municipio"
            label="Municipio"
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            type="text"
            disabled
            value={values.Endereco.estado}
            onChange={(e) =>
              setValues({
                ...values,
                Endereco: {
                  ...values.Endereco,
                  estado: e.target.value,
                },
              })
            }
            placeholder="Estado"
            label="Estado"
          />
        </CCol>
        <div className="pt-3"></div>
        <CCol md={3}>
          <FloatingLabelInput
            type="text"
            placeholder="N. Telefone"
            maxLength = "15"
            label="N. Telefone"
            value={values.Contato.telefone}
            required
            onChange={(e) => {
              if (teclaPressionada !== 8) {
                e.target.value = FormatNumeroCelular(e.target.value);
              }
              setValues({
                ...values,
                Contato: {
                  ...values.Contato,
                  telefone: e.target.value,
                },
              })
            }}
          />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput
            type="text"
            placeholder="N. Celular"
            maxLength="15"
            label="N. Celular"
            value={values.Contato.celular}
            onKeyDown={(e) => setTeclaPressionada(e.keyCode)}
            required
            onChange={(e) => {
              if (teclaPressionada !== 8) {
                e.target.value = FormatNumeroCelular(e.target.value);
              }
              setValues({
                ...values,
                Contato: {
                  ...values.Contato,
                  celular: e.target.value,
                },
              });
            }}
          />
        </CCol>
        <CCol md={6}>
          <FloatingLabelInput
            type="text"
            placeholder="E-mail"
            label="E-mail"
            value={values.Contato.email}
            required
            onChange={(e) =>
              setValues({
                ...values,
                Contato: {
                  ...values.Contato,
                  email: e.target.value,
                },
              })
            }
          />
        </CCol>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </>
  );
}

export default EnderecoCpf;
