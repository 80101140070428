import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { PdfInfos } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";

const mockData = [
  {
    section: "receita total",
    data: {
      subSection: {
        title: "Receitas Primárias (I)",
        values: [
          ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
        ],
      },
      infos: [
        {
          title: "Receitas Primárias Correntes",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
        {
          title: "Receitas Primárias Correntes",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
        {
          title: "Impostos, Taxas e Contribuições de Melhoria ",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
        {
          title: "Transferências Correntes ",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
        {
          title: "Demais Receitas Primárias Correntes ",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
        {
          title: "Receitas Primárias de Capital ",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
      ],
    },
  },
  {
    section: "despesa total",
    data: {
      subSection: {
        title: "Despesas Primárias (II) ",
        values: [
          ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
        ],
      },
      infos: [
        {
          title: "Despesas Primárias Correntes ",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
        {
          title: "Pessoal e Encargos Sociais ",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
        {
          title: "Outras Despesas Correntes ",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
        {
          title: "Transferências Correntes ",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
        {
          title: "Despesas Primárias de Capital ",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
        {
          title: "Pagamento de Restos a Pagar de Despesas Primárias",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
      ],
    },
  },
  {
    section: "Resultado Primário (III) = (I – II)",
    data: {
      subSection: {
        title: "",
        values: [],
      },
      infos: [
        {
          title: "Juros Encargos e Variações Monetárias Ativos (IV)",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
        {
          title: "Juros Encargos e Variações Monetárias Passivos (V) ",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
        {
          title: "Dívida Pública Consolidada",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
        {
          title: "Dívida Consolidada Líquida ",
          values: [
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
            ["1.000.000,00", "1.000.000,00", "1.000.000,00", "1.000.000,00"],
          ],
        },
      ],
    },
  },
  {
    section: "Resultado Nominal (VI) = (III – (IV + V) ",
    data: {
      subSection: {
        title: "",
        values: [],
      },
      infos: [],
    },
  },
];

export const RenderPdfDemoMetasAnuais = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: 180, flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: "100%",
      flexShrink: 1,
      flexGrow: 1,
    },
  ];

  const renderTextColumn = (text, style = {}) => (
    <Text
      style={[
        stylesPdf.textTituloColuna,
        style,
        { textAlign: "center", fontSize: 8 },
      ]}
    >
      {text}
    </Text>
  );

  const renderHeader = () => {
    return (
      <>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              { textAlign: "center", height: 42 },
            ]}
          >
            {renderTextColumn("especificações", { textTransform: "uppercase" })}
          </View>
        </View>

        <View style={[stylesPdf.coluna, styledCol[1]]}>
          <View style={[stylesPdf.divTituloColuna, { height: 42 }]}>
            <View
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                height: "100%",
                gap: 4,
              }}
            >
              {dados.contentHeader.map((dataHeader, index) => (
                <View
                  key={index}
                  style={{
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {renderTextColumn(dataHeader.year)}
                  <View
                    key={index}
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {dataHeader.content.map((data, index) => (
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          width: index > 1 ? "80%" : "100%",
                        }}
                        key={index}
                      >
                        {renderTextColumn(data.text)}
                      </View>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>
      </>
    );
  };

  const renderHeaderParametro = () => {
    return (
      <>
        <View style={[stylesPdf.coluna, { width: "100%" }]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              { textAlign: "center", height: 38 },
            ]}
          >
            {renderTextColumn("variáveis", { textTransform: "uppercase" })}
          </View>
        </View>

        <View style={[stylesPdf.coluna, { width: "100%" }]}>
          <View style={[stylesPdf.divTituloColuna, { height: 38 }]}>
            <View
              style={{
                height: "100%",
                justifyContent: "center",
              }}
            >
              <View
                style={[
                  {
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "space-around",
                  },
                ]}
              >
                {renderTextColumn("período", { textTransform: "uppercase" })}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingHorizontal: 24,
                  }}
                >
                  {dados.contentHeader.map((data, index) => (
                    <React.Fragment key={index}>
                      {renderTextColumn(data.year)}
                    </React.Fragment>
                  ))}
                </View>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  };

  const renderSection = (title) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            backgroundColor: "#a6a6a6",
            fontWeight: "bold",
          },
        ]}
      >
        <PdfInfos
          pdfInfo={title}
          styledCol={[
            {
              padding: "2px 0",
              width: "100%",
            },
          ]}
          styledText={{ textTransform: "capitalize", fontSize: 8 }}
        />
      </View>
    );
  };

  const renderInfos = (title, values, textBold = false) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            fontWeight: textBold ? "bold" : "normal",
          },
        ]}
      >
        <PdfInfos
          pdfInfo={title}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{ fontSize: 8 }}
        />
        <View
          style={[
            stylesPdf.coluna,
            styledCol[1],
            { flexDirection: "row", gap: 4 },
          ]}
        >
          {values.map((value, index) => (
            <View
              key={index}
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {value.map((item, itemIndex) => (
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: itemIndex > 1 ? "80%" : "100%",
                  }}
                  key={itemIndex}
                >
                  <Text
                    style={[
                      stylesPdf.textInfosRelatorio,
                      {
                        fontSize: 8,
                        textAlign: "center",
                        paddingHorizontal: 0,
                      },
                    ]}
                  >
                    {item}
                  </Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      </View>
    );
  };

  return (
    <Page orientation="landscape" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Metas Anuais"}
        anoRelatorio={currentYear}
        plusText={"Anexo de Metas Fiscais"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>AMF – Demonstrativo 1 (LRF, art 4º, § 1º)</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
        {renderHeader()}
      </View>
      <View style={stylesPdf.divRelatorio}>
        {mockData.map((data, index) => (
          <React.Fragment key={index}>
            {renderSection(data.section)}
            {!!data.data.subSection.title &&
              renderInfos(
                data.data.subSection.title,
                data.data.subSection.values,
                true
              )}
            {data.data.infos.map((item, index) => (
              <React.Fragment key={index}>
                {renderInfos(item.title, item.values)}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        <Text style={{ fontWeight: "bold", marginLeft: 8, fontSize: 8 }}>
          FONTE: Secretaria de Finanças
        </Text>
      </View>
      {/* TODO: remover o break ou fixar o header */}
      <View style={[stylesPdf.colunas, { marginTop: 18 }]} break>
        {renderHeaderParametro()}
      </View>

      <View
        style={{
          backgroundColor: "#eeeeee",
          width: "100%",
        }}
      >
        <PdfInfos
          pdfInfo={"PIB real (crescimento % anual) "}
          styledCol={[
            {
              padding: "2px 0",
            },
          ]}
          styledText={{ fontWeight: "bold" }}
        />
      </View>
      <PdfInfos
        pdfInfo={
          "Taxa real de juros implícito sobre a dívida líquida do governo (média % anual)"
        }
        styledCol={[
          {
            padding: "2px 0",
          },
        ]}
        styledText={{ fontWeight: "bold" }}
      />

      <View
        style={{
          width: "100%",
          height: 1,
          backgroundColor: "#000",
          marginVertical: 2,
        }}
      />
      <Text style={{ fontWeight: "bold", marginLeft: 8, fontSize: 8 }}>
        FONTE: Banco Central – Relatório Focus de abril 2024
      </Text>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
