import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  ColButtons,
  ButtonOptionsFirst,
  ButtonOptionsPages,
  ButtonOptionsLast,
} from "../../../../styles/StyledComponents/ButtonsSelectPage";
import CadastroFuncao from "./CadastroFuncao";
import CadastroSubfuncao from "./CadastroSubfuncao";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import api from "../../../../utils/api";

const FuncaoSubfuncao = () => {
  const { valor: paramsValor, id } = useParams();
  const [botaoAtivo, setBotaoAtivo] = useState(
    paramsValor !== undefined && paramsValor.length === 3 ? 1 : 0
  );
  const [valuesFuncaoSubfuncao, setValuesFuncaoSubfuncao] = useState({
    valor: "",
    nome: "",
    descricao: "",
  });

  const limpaValuesFuncaoSubfuncao = () => {
    setValuesFuncaoSubfuncao((prevValues) => ({
      ...prevValues,
      valor: "",
      nome: "",
      descricao: "",
    }));
  };

  useEffect(() => {
    limpaValuesFuncaoSubfuncao();
  }, [botaoAtivo]);

  useEffect(() => {
    console.log(paramsValor);
    if (paramsValor !== undefined) {
      preencheDadosAtualizacao();
    }
    //eslint-disable-next-line
  }, [paramsValor]);

  const preencheDadosAtualizacao = () => {
    if (paramsValor.length === 3) {
      api
        .get(`subfuncao/porvalor/${paramsValor}`)
        .then((response) => {
          const data = response.data;
          setValuesFuncaoSubfuncao((prevValues) => ({
            ...prevValues,
            valor: data.valor,
            descricao: data.descricao,
            nome: data.nome,
          }));
        })
        .catch((error) => console.log(error));
    } else {
      api
        .get(`funcao/porvalor/${paramsValor}`)
        .then((response) => {
          const data = response.data;
          setValuesFuncaoSubfuncao((prevValues) => ({
            ...prevValues,
            valor: data.valor,
            descricao: data.descricao,
            nome: data.nome,
          }));
        })
        .catch((error) => console.log(error));
    }
  };

  const atualizarFuncaoSubfuncao = () => {
    if (paramsValor.length === 3) {
      api
        .put(`subfuncao/atualiza/${id}`, {
          nome: valuesFuncaoSubfuncao.nome,
          descricao: valuesFuncaoSubfuncao.descricao,
        })
        .then((response) => {
          console.log(response);
          toast.success("Subfunção atualizada com sucesso!");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      api
        .put(`funcao/atualiza/${id}`, {
          nome: valuesFuncaoSubfuncao.nome,
          descricao: valuesFuncaoSubfuncao.descricao,
        })
        .then((response) => {
          console.log(response);
          toast.success("Função atualizada com sucesso!");
        })
        .catch((error) => console.log(error));
    }
  };

  const cadastrarFuncaoSubfuncao = () => {
    if (botaoAtivo === 0) {
      if (valuesFuncaoSubfuncao.valor.length === 2) {
        api
          .post("funcao", valuesFuncaoSubfuncao)
          .then(() => {
            toast.success("Função cadastrada com sucesso!");
            limpaValuesFuncaoSubfuncao();
          })
          .catch((error) => {
            if (error.response.status === 400) {
              toast.error(error.response.data.message);
            } else {
              toast.error(
                "Algo deu errado ao cadastrar a Função. Por favor, tente mais tarde"
              );
            }
          });
      } else {
        toast.error("O código de Subfuncao deve ter no mínimo 2 digitos");
      }
    }

    if (botaoAtivo === 1) {
      if (valuesFuncaoSubfuncao.valor.length === 3) {
        api
          .post("subfuncao", valuesFuncaoSubfuncao)
          .then(() => {
            toast.success("Subfunção cadastrada com sucesso!");
            limpaValuesFuncaoSubfuncao();
          })
          .catch((error) => {
            if (error.response.status === 400) {
              toast.error(error.response.data.message);
            } else {
              toast.error(
                "Algo deu errado ao cadastrar a Função. Por favor, tente mais tarde"
              );
            }
          });
      } else {
        toast.error("O código de Subfuncao deve ter no mínimo 3 digitos");
      }
    }
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        withRow={false}
        ButtonSaveFunction={
          paramsValor !== undefined
            ? atualizarFuncaoSubfuncao
            : cadastrarFuncaoSubfuncao
        }
        NomePaginaTitulo={botaoAtivo === 0 ? "Função" : "Subfução"}
        PaginaSubtitulo={paramsValor ? "Visualizar" : "Cadastro"}
        PaginaConsulta={"/acesso-interno/cadastro/funcao-subfuncao/consulta"}
      />
      <ToastContainer />
      <Row>
        <ColButtons md={12}>
          <ButtonOptionsPages>
            <ButtonOptionsFirst
              disabled={paramsValor !== undefined}
              ativo={botaoAtivo === 0}
              onClick={() => setBotaoAtivo(0)}
            >
              Função
            </ButtonOptionsFirst>
            <ButtonOptionsLast
              disabled={paramsValor !== undefined}
              ativo={botaoAtivo === 1}
              onClick={() => setBotaoAtivo(1)}
            >
              Subfunção
            </ButtonOptionsLast>
          </ButtonOptionsPages>
        </ColButtons>
      </Row>
      {botaoAtivo === 0 && (
        <CadastroFuncao
          values={valuesFuncaoSubfuncao}
          setValues={setValuesFuncaoSubfuncao}
          useParamsValor={paramsValor}
        />
      )}
      {botaoAtivo === 1 && (
        <CadastroSubfuncao
          values={valuesFuncaoSubfuncao}
          setValues={setValuesFuncaoSubfuncao}
          useParamsValor={paramsValor}
        />
      )}
    </Container>
  );
};

export default FuncaoSubfuncao;
