export const FormatContaFonteRecursos = (input) => {
  const cleanedValue = input.value.replace(/[^0-9]/g, "");
  const mask = "#.###.####";
  let formatValue = "";
  let index = 0;

  for (let i = 0; i < mask.length && index < cleanedValue.length; i++) {
    if (mask[i] === "#") {
      formatValue += cleanedValue[index];
      index++;
    } else {
      formatValue += mask[i];
    }
  }

  input.value = formatValue;
};
