import React, { useState, useContext, useEffect } from "react";
import { Row } from "react-bootstrap";
import { CCol } from "../../../../components/Grid/CCol";
import { TituloAbaSolo } from "../../../../styles/StyledComponents/LayoutGerais";
import ScrollableSection from "./ScrollableSection";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import Saldos from "./Saldos";
import { TestButton } from "../../../../utils/TestButton";

const Despesa = () => {
  const [rowsInfos, setRowsInfos] = useState();
  const [saldosData, setSaldosData] = useState();
  const { currentYear } = useContext(CurrentYearContext);
  useEffect(() => {
    const anoAtual = currentYear;
    setRowsInfos([
      {
        key: 1,
        title: "UG",
      },
      {
        key: 2,
        title: "Natureza da Receita",
      },
      {
        key: 3,
        title: "Fonte de Recursos",
      },
      {
        key: 4,
        title: "Esfera",
      },
      {
        key: 5,
        title: Number(anoAtual) - 4 + " - Previsão",
        type: "money",
      },
      {
        key: 6,
        title: Number(anoAtual) - 4 + " - Arrecadada",
        type: "money",
      },
      {
        key: 7,
        title: Number(anoAtual) - 3 + " - Previsão",
        type: "money",
      },
      {
        key: 8,
        title: Number(anoAtual) - 3 + " Arrecadada",
        type: "money",
      },
      {
        key: 9,
        title: Number(anoAtual) - 2 + " - Previsão",
        type: "money",
      },
      {
        key: 10,
        title: Number(anoAtual) - 2 + " - Arrecadada",
        type: "money",
      },
      {
        key: 11,
        title: Number(anoAtual) - 1 + " - Previsão",
        type: "money",
      },
      {
        key: 12,
        title: Number(anoAtual) - 1 + " - Arrecadada",
        type: "money",
      },
      {
        key: 13,
        title: Number(anoAtual) + " - Previsão",
        type: "money",
      },
      {
        key: 14,
        title: Number(anoAtual) + 1 + " - Previsão",
        type: "money",
      },
      {
        key: 15,
        title: Number(anoAtual) + 1 + " - Arrecadada",
        type: "money",
      },
      {
        key: 16,
        title: Number(anoAtual) + 2 + " - Previsão",
        type: "money",
      },
      {
        key: 17,
        title: Number(anoAtual) + 2 + " - Arrecadada",
        type: "money",
      },
    ]);
    setSaldosData([
      {
        [`Exercício ${anoAtual - 4}`]: [
          { input1: "0" },
          { input2: "0" },
          { input3: "0" },
        ],
      },
      {
        [`Exercício ${anoAtual - 3}`]: [
          { input1: "0" },
          { input2: "0" },
          { input3: "0" },
        ],
      },
      {
        [`Exercício ${anoAtual - 2}`]: [
          { input1: "0" },
          { input2: "0" },
          { input3: "0" },
        ],
      },
      {
        [`Exercício ${anoAtual - 1}`]: [
          { input1: "0" },
          { input2: "0" },
          { input3: "0" },
        ],
      },
      {
        [`Exercício ${anoAtual}`]: [
          { input1: "0" },
          { input2: "0" },
          { input3: "0" },
        ],
      },
      {
        [`Exercício ${anoAtual + 1}`]: [
          { input1: "0" },
          { input2: "0" },
          { input3: "0" },
        ],
      },
      {
        [`Exercício ${anoAtual + 2}`]: [
          { input1: "0" },
          { input2: "0" },
          { input3: "0" },
        ],
      },
    ]);
  }, [currentYear]);

  const initialData = [
    {
      UG: "1",
      Acao: "AcaoTest 1",
      FonteRecursos: "FontRecTest 1",
      ElementoDespesa: "ElementoDesp 1",
      Esfera: "Fiscal",
      Fixada2020: "100000",
      Realizada2020: "75000",
      Fixada2021: "120000",
      Realizada2021: "80000",
      Fixada2022: "130000",
      Realizada2022: "85000",
      Fixada2023: "140000",
      Realizada2023: "90000",
      Fixada2024: "150000",
      Fixada2025: "160000",
      Realizada2025: "110000",
      Fixada2026: "170000",
      Realizada2026: "120000",
    },
    {
      UG: "2",
      Acao: "AcaoTest 2",
      FonteRecursos: "FontRecTest 2",
      ElementoDespesa: "ElementoDesp 2",
      Esfera: "Seguridade",
      Previsao2020: "90000",
      Arrecadada2020: "60000",
      Previsao2021: "100000",
      Arrecadada2021: "65000",
      Previsao2022: "110000",
      Arrecadada2022: "70000",
      Previsao2023: "120000",
      Arrecadada2023: "75000",
      Previsao2024: "130000",
      Previsao2025: "140000",
      Arrecadada2025: "95000",
      Previsao2026: "150000",
      Arrecadada2026: "100000",
    },
  ];

  const [inputs, setInputs] = useState(initialData);

  const handleInputChange = (rowIndex, colIndex, value) => {
    const newInputs = [...inputs];
    newInputs[rowIndex][colIndex] = value;
    setInputs(newInputs);
  };

  return (
    <>
      <Row className="row_form mt-1">
        <TituloAbaSolo>Quadro Detalhado da Despesa</TituloAbaSolo>
      </Row>
      <ScrollableSection
        rowsInfos={rowsInfos}
        inputs={inputs}
        setInputs={setInputs}
        handleInputChange={handleInputChange}
      />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Saldos
          </span>
        </CCol>
      </Row>
      <Saldos
        titles={["Receita Prevista", "Despesa Fixada", "Despesa Fixada"]}
        data={saldosData}
      />
      <TestButton onClick={() => console.log(inputs, saldosData)}>
        console
      </TestButton>
    </>
  );
};

export default Despesa;
