import React, { useContext } from "react";
import { Row } from "react-bootstrap";
import { CCol } from "../Grid/CCol";
import styled from "styled-components";
import { Icon } from "@iconify/react/dist/iconify.js";
import { OpenedModalsContext } from "../../contexts/OpenedModalsContext";

const RowHeaderModal = styled(Row)`
  background-color: #fff;
  box-sizing: border-box;
  height: 54px;
  padding: 8px 10px;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  position: absolute;
  top: -47px;
  width: 100%;
  margin: 0;

  span {
    font-weight: 600;
    color: #515c70;
  }
`;

const ButtonExitModal = styled.button`
  border-radius: 50%;
  border: none;
  background-color: #515c70;
  transition: all 0.2s ease-out;
  color: #fff;

  &:hover {
    background-color: #818da5;
  }
`;

const ContainerButtonExit = styled.div`
  background-color: #fff;
  position: absolute;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 0px 0px 5px;
  top: ${({ indexModal, lengthArray }) => {
    const multiplicador = lengthArray - indexModal;

    const valor = (multiplicador * 57).toString() + "px";

    return valor;
  }};
  width: 50px;
  left: -45px;
`;

const HeaderModalAutoComplete = ({ Titulo }) => {
  const { openedModals, setOpenedModals } = useContext(OpenedModalsContext);

  const handleButtonExit = () => {
    setOpenedModals((prev) =>
      [...prev].filter((item) => item.tituloModal !== Titulo)
    );
  };

  return (
    <RowHeaderModal>
      <CCol>
        <ContainerButtonExit
          indexModal={openedModals.findIndex(
            (item) => item.tituloModal === Titulo
          )}
          lengthArray={openedModals.length - 1}
        >
          <ButtonExitModal onClick={handleButtonExit}>
            <Icon icon="iconoir:cancel" height={28} />
          </ButtonExitModal>
        </ContainerButtonExit>

        <span>{Titulo}</span>
      </CCol>
    </RowHeaderModal>
  );
};

export default HeaderModalAutoComplete;
