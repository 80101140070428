import { Page, View, Document } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import React from "react";

export const PdfObjetivosMilenio = ({ pdfContent }) => {
  const {
    dadosRelatorio,
    opcoes,
    relatorioName,
    userData: { currentClient, userAuthData, dataClientInfos },
    currentYear,
  } = pdfContent;

  if (!dadosRelatorio) return null;
  const colNames = ["Imagem", "Código", "De-Para", "Descrição", "Objetivos"];
  const styledCol = [
    { flexBasis: "125px", flexShrink: 0, flexGrow: 1 },
    { flexBasis: "125px", flexShrink: 0, flexGrow: 1 },
    { flexBasis: "125px", flexShrink: 0, flexGrow: 1 },
    { flexBasis: "500px", flexShrink: 0, flexGrow: 1 },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
  ];
  return (
    <Document title={relatorioName}>
      <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
        <PdfHeader
          currentClient={currentClient}
          nameRelatorio={"Objetivos da ONU"}
          anoRelatorio={currentYear}
          dataClientInfos={dataClientInfos}
        />
        <View style={[stylesPdf.colunas, { marginTop: "8px" }]} fixed>
          {opcoes[0]?.checkBoxes?.map(
            (each, index) =>
              each.selected && (
                <PdfColunas
                  key={index}
                  colName={colNames[index]}
                  styledCol={styledCol[index]}
                />
              )
          )}
        </View>
        <View style={stylesPdf.divRelatorio}>
          <View>
            {dadosRelatorio?.map((item) => (
              <React.Fragment key={item.id}>
                <View key={item.id} style={stylesPdf.colunas}>
                  {opcoes[0]?.checkBoxes[0].selected && (
                    <PdfInfos
                      pdfInfo={item.img ? item.img : ""}
                      styledCol={styledCol[0]}
                    />
                  )}
                  {opcoes[0]?.checkBoxes[1].selected && (
                    <PdfInfos
                      pdfInfo={item.codigo ? item.codigo : ""}
                      styledCol={styledCol[1]}
                    />
                  )}
                  {opcoes[0]?.checkBoxes[2].selected && (
                    <PdfInfos
                      pdfInfo={item.dePara ? item.dePara : ""}
                      styledCol={styledCol[2]}
                    />
                  )}
                  {opcoes[0]?.checkBoxes[3].selected && (
                    <PdfInfos
                      pdfInfo={item.descricao ? item.descricao : ""}
                      styledCol={styledCol[3]}
                    />
                  )}
                  {opcoes[0]?.checkBoxes[4].selected && (
                    <PdfInfos
                      pdfInfo={item.objetivo ? item.objetivo : ""}
                      styledCol={styledCol[4]}
                    />
                  )}
                </View>
                <View
                  style={{
                    height: "1px",
                    width: "100%",
                    backgroundColor: "#a6a6a6",
                  }}
                ></View>
              </React.Fragment>
            ))}
            <View style={stylesPdf.divInferiorColuna}></View>
          </View>
        </View>
        <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
      </Page>
    </Document>
  );
};
