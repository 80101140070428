import { Document, Packer, PageOrientation, SectionType } from "docx";
import defaultImg64 from "./defaultImg64";
import { DocxHeader } from "./DocxHeader";
import { DocxFooter } from "./DocxFooter";
import { DocxTable } from "./DocxTable";

export const generateDocxBlob = async (
  { pdfContent },
  title,
  logo,
  pageSize = 20,
  double = false
) => {
  const {
    dadosRelatorio,
    opcoes,
    columnWidth,
    userData: { currentClient, userAuthData },
    dateRelatorio: { year },
  } = pdfContent;
  const font = "Roboto";

  if (!dadosRelatorio) return null;

  const dataBlocks = [];
  const dataBlocks2 = [];
  if (!double) {
    for (let i = 0; i < dadosRelatorio.length; i += pageSize) {
      dataBlocks.push(dadosRelatorio.slice(i, i + pageSize));
    }
  } else {
    for (let i = 0; i < dadosRelatorio[0].length; i += pageSize) {
      dataBlocks.push(dadosRelatorio[0].slice(i, i + pageSize));
    }
    for (let i = 0; i < dadosRelatorio[1].length; i += pageSize) {
      dataBlocks2.push(dadosRelatorio[1].slice(i, i + pageSize));
    }
  }

  const sections = dataBlocks.map((dataBlock, index) => ({
    properties: {
      type: index === 0 ? SectionType.CONTINUOUS : SectionType.NEXT_PAGE,
      page: {
        size: {
          orientation: PageOrientation.PORTRAIT,
        },
        margin: {
          top: 720,
          right: 720,
          bottom: 720,
          left: 720,
        },
      },
    },
    headers: {
      default: DocxHeader({
        logo: logo || defaultImg64,
        nameUnidadeGestora: currentClient.nomeFantasia,
        // municipio: currentClient.endereco.municipio,
        // estado: currentClient.endereco.estado,
        ano: year,
        title,
        font,
      }),
    },
    children: [
      DocxTable({
        columns: !double ? opcoes : opcoes[0],
        data: dataBlock,
        font,
        subTitle: double ? double[0] : "",
        columnWidth: !double ? columnWidth : columnWidth[0],
      }),
    ],
    footers: {
      default: DocxFooter({
        userAuthData,
        currentClient,
        font,
      }),
    },
  }));
  const sections2 = dataBlocks2?.map((dataBlock, index) => ({
    properties: {
      type: SectionType.NEXT_PAGE,
      page: {
        size: {
          orientation: PageOrientation.PORTRAIT,
        },
        margin: {
          top: 720,
          right: 720,
          bottom: 720,
          left: 720,
        },
      },
    },
    headers: {
      default: DocxHeader({
        logo: logo || defaultImg64,
        nameUnidadeGestora: currentClient.nomeFantasia,
        // municipio: currentClient.endereco.municipio,
        // estado: currentClient.endereco.estado,
        ano: year,
        title,
        font,
      }),
    },
    children: [
      DocxTable({
        columns: opcoes[1],
        data: dataBlock,
        font,
        subTitle: double[1],
        piece: 1,
        columnWidth: columnWidth[1],
      }),
    ],
    footers: {
      default: DocxFooter({
        userAuthData,
        currentClient,
        font,
      }),
    },
  }));

  const doc = new Document({
    sections: [...sections, ...(double ? sections2 : [])],
  });

  const blob = await Packer.toBlob(doc);
  return new Blob([blob], {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });
};
