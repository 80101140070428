import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { CCol } from "../../../components/Grid/CCol";
import { PTitulosInputs } from "../../../styles/StyledComponents/ListaDeInputs";
import { RowListaPdc } from "../../../styles/StyledComponents/ListaPdcPcasp";

const PlanoDeContasPcasp = ({ arrayConta }) => {
  const [corDaLinha, setCorDaLinha] = useState("");

  useEffect(() => {
    if (arrayConta.length > 0) {
      if (
        parseInt(arrayConta[0].conta[0]) >= 1 &&
        parseInt(arrayConta[0].conta[0]) <= 4
      ) {
        setCorDaLinha("#F0F3FF");
      } else if (
        parseInt(arrayConta[0].conta[0]) >= 5 &&
        parseInt(arrayConta[0].conta[0]) <= 6
      ) {
        setCorDaLinha("#FFEFFB");
      } else {
        setCorDaLinha("#EFFCE5");
      }
    }
  }, [arrayConta]);

  return (
    <>
      <Row className="row_endereco_conteudo p-2">
        <span>Estrutura</span>
      </Row>

      <Row className="row_form mt-1">
        <CCol md={3}>
          <PTitulosInputs>Nível</PTitulosInputs>
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Conta</PTitulosInputs>
        </CCol>
        <CCol md={5}>
          <PTitulosInputs>Título</PTitulosInputs>
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Natureza</PTitulosInputs>
        </CCol>
      </Row>

      <Row className="row_form_2">
        {arrayConta.map((item, index, selfArray) => (
          <RowListaPdc
            background={corDaLinha}
            style={index === 0 ? { marginTop: "-20px" } : { marginTop: "8px" }}
            key={index}
          >
            <CCol md={3}>
              <h6>{item.nivel}</h6>
            </CCol>
            <CCol md={2}>
              <h6>{item.conta}</h6>
            </CCol>
            <CCol md={5}>
              <h6>{item.titulo}</h6>
            </CCol>
            <CCol md={2}>
              <h6>{item.natureza}</h6>
            </CCol>
          </RowListaPdc>
        ))}
      </Row>
    </>
  );
};

export default PlanoDeContasPcasp;
