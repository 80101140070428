import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
// import { mockedData } from "../utils/Loa/MockDataDespesaContabilidade";

export const RenderPdfDespesaCompatibilidade = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const mockData = dados.data;
  const styledCol = [
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: "150px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "150px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "250px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const renderHeader = (headerName) => {
    return (
      <>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              { paddingHorizontal: 8, textAlign: "center" },
            ]}
          >
            <Text style={stylesPdf.textTituloColuna}>{headerName}</Text>
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[1]]}>
          <View style={[stylesPdf.divTituloColuna]}>
            <Text style={[stylesPdf.textTituloColuna, { marginLeft: "15px" }]}>
              LDO
            </Text>
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[2]]}>
          <View style={[stylesPdf.divTituloColuna]}>
            <Text style={[stylesPdf.textTituloColuna, { marginLeft: "15px" }]}>
              PLOA
            </Text>
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[3]]}>
          <View style={[stylesPdf.divTituloColuna, { paddingHorizontal: 8 }]}>
            <View>
              <Text
                style={[
                  stylesPdf.textTituloColuna,
                  { borderBottomColor: "#FFF", borderBottomWidth: 1 },
                ]}
              >
                DIFERENÇA
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingHorizontal: 2,
                }}
              >
                <Text
                  style={[stylesPdf.textTituloColuna, { marginLeft: "12px" }]}
                >
                  R$
                </Text>
                <Text
                  style={[stylesPdf.textTituloColuna, { marginRight: "2px" }]}
                >
                  %
                </Text>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  };

  const renderOrcamentos = (orcamento) => {
    console.log("AQUI O ORCAMENTO PURO: ", orcamento);
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
          },
        ]}
      >
        <PdfInfos
          pdfInfo={orcamento.titulo}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
            },
          ]}
        />
        <PdfInfos
          pdfInfo={FormatValueToLocaleString(
            parseFloat(orcamento.despesaInfos.totalLdo)
          )}
          styledCol={[styledCol[1], { padding: "2px 0", textAlign: "right" }]}
        />
        <PdfInfos
          pdfInfo={FormatValueToLocaleString(
            parseFloat(orcamento.despesaInfos.totalPloa)
          )}
          styledCol={[
            styledCol[2],
            {
              padding: "2px 0",
              textAlign: "right",
            },
          ]}
        />
        <View
          style={[
            styledCol[3],
            {
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 0,
              paddingVertical: 2,
            },
          ]}
          wrap={false}
        >
          <Text style={stylesPdf.textInfosRelatorio}>
            {FormatValueToLocaleString(
              parseFloat(orcamento.despesaInfos.diferenca.TotalReal)
            )}
          </Text>
          <Text style={stylesPdf.textInfosRelatorio}>
            {orcamento.despesaInfos.diferenca.porcentagem}
          </Text>
        </View>
      </View>
    );
  };

  const renderOrcamentoSection = (orcamentoInfo) => {
    console.log("AQUI O ORCAMENTOINFO: ", orcamentoInfo);
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            backgroundColor: "#a6a6a6",
            fontWeight: "bold",
          },
        ]}
      >
        <PdfInfos
          pdfInfo={orcamentoInfo.nameReceita || ""}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
            },
          ]}
        />

        <PdfInfos
          pdfInfo={FormatValueToLocaleString(
            parseFloat(orcamentoInfo.totalLdo)
          )}
          styledCol={[styledCol[1], { padding: "2px 0", textAlign: "right" }]}
        />
        <PdfInfos
          pdfInfo={FormatValueToLocaleString(
            parseFloat(orcamentoInfo.totalPloa)
          )}
          styledCol={[
            styledCol[2],
            {
              padding: "2px 0",
              textAlign: "right",
            },
          ]}
        />
        <View
          style={[
            styledCol[3],
            {
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 0,
              paddingVertical: 2,
            },
          ]}
          wrap={false}
        >
          <Text style={stylesPdf.textInfosRelatorio}>
            {FormatValueToLocaleString(
              parseFloat(orcamentoInfo.diferenca.TotalReal)
            )}
          </Text>
          <Text style={stylesPdf.textInfosRelatorio}>
            {orcamentoInfo.diferenca.porcentagem}
          </Text>
        </View>
      </View>
    );
  };

  const renderTotal = (typeReceitaTotal, totalPloa = 10000) => {
    return (
      <>
        <View
          wrap={false}
          style={[
            stylesPdf.divInferiorColuna,
            {
              fontWeight: "bold",
              fontSize: 10,
              textTransform: "uppercase",
              color: "white",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 10,
              margin: 0,
              paddingHorizontal: 0,
              paddingLeft: 10,
            },
          ]}
        >
          <Text>{`${typeReceitaTotal}`}</Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                width: 77,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {FormatValueToLocaleString(10000)}
            </Text>
            <Text
              style={{
                width: 77,
                textAlign: "right",
                paddingRight: 8,
              }}
            >
              {FormatValueToLocaleString(totalPloa)}
            </Text>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: 127,
              }}
            >
              <Text
                style={{
                  textAlign: "right",
                  paddingRight: 8,
                }}
              >
                {FormatValueToLocaleString(10000)}
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  paddingRight: 8,
                }}
              >
                {FormatValueToLocaleString(10000)}
              </Text>
            </View>
          </View>
        </View>
      </>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Orçamentos com os Objetivos e Metas"}
        nameProjeto={"Demonstrativo da Compatibilidade da Programação dos"}
        anoRelatorio={currentYear}
        plusText={"vazio"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>LRF art. 5º, Inciso I</Text>
        <Text>R$ 1,00</Text>
      </View>
      {/* <View style={[stylesPdf.colunas]} fixed>
        {renderHeader("RECEITA")}
      </View> */}
      <View style={stylesPdf.divRelatorio}>
        <View>
          {mockData.map((unidade, unidadeIndex) => (
            <React.Fragment key={unidadeIndex}>
              <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
                {renderHeader("RECEITA")}
              </View>
              {unidade.infoUnidade.receitas.map((receita, receitaIndex) => (
                <React.Fragment key={receitaIndex}>
                  {renderOrcamentoSection(receita.receitaInfos)}
                  {receita.despesas.map((despesa, despesaIndex) => (
                    <React.Fragment key={despesaIndex}>
                      {renderOrcamentos(despesa)}
                    </React.Fragment>
                  ))}
                  {receita.receitaInfos.nameReceita ===
                    "Receitas de Capital ( II )" &&
                    renderTotal(
                      "receitas total( III ) = ( I ) + ( II )",
                      parseFloat(
                        unidade.infoUnidade.receitas[0].receitaInfos.totalPloa
                      ) +
                        parseFloat(
                          unidade.infoUnidade.receitas[1].receitaInfos.totalPloa
                        )
                    )}
                  {receita.receitaInfos.nameReceita ===
                    "Receitas Financeiras ( IV )" &&
                    renderTotal("receitas total( V ) = ( III ) + ( IV )")}
                </React.Fragment>
              ))}
              <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
                {renderHeader("DESPESA")}
              </View>
              {unidade.infoUnidade.despesas.map((despesas, receitaIndex) => (
                <React.Fragment key={receitaIndex}>
                  {renderOrcamentoSection(despesas.receitaInfos)}
                  {despesas.despesas.map((despesa, despesaIndex) => (
                    <React.Fragment key={despesaIndex}>
                      {renderOrcamentos(despesa)}
                    </React.Fragment>
                  ))}
                  {despesas.receitaInfos.nameReceita ===
                    "Reserva de Contingência ( III )" &&
                    renderTotal(
                      "despesas total( IV ) = ( I ) + ( II ) + ( III )"
                    )}
                  {despesas.receitaInfos.nameReceita ===
                    "Despesas Financeiras ( V )" &&
                    renderTotal("despesas primárias( VI ) = ( IV ) - ( V )")}
                </React.Fragment>
              ))}
              <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }]}>
                {renderHeader("ESPECIFICAÇÃO")}
              </View>
              {unidade.infoUnidade.especificacoes.map(
                (receita, receitaIndex) => (
                  <React.Fragment key={receitaIndex}>
                    {renderOrcamentoSection(receita.receitaInfos)}
                    {receita.despesas.map((despesa, despesaIndex) => (
                      <React.Fragment key={despesaIndex}>
                        {renderOrcamentos(despesa)}
                      </React.Fragment>
                    ))}
                    {receita.receitaInfos.nameReceita === "Despesas Total" &&
                      renderTotal(
                        "RESULTADO PRIMÁRIO ( III ) = ( I ) - ( II )"
                      )}
                  </React.Fragment>
                )
              )}
              <View style={{ marginBottom: 5 }} />
            </React.Fragment>
          ))}
        </View>
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
