import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  position: relative;
`;

const StyledInputVoiceToText = styled.input`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ced4da;
  outline: none;
  color: #515c70;
  font-weight: 500;
  padding: 6px 12px;
`;

const ContainerIconVoiceInput = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const InputVoiceToText = (props) => {
  return (
    <InputContainer>
      <ContainerIconVoiceInput>
        <Icon icon="bi:mic-fill" style={{ color: "#515c70" }} />
      </ContainerIconVoiceInput>
      <StyledInputVoiceToText style={props.style} />
    </InputContainer>
  );
};

export default InputVoiceToText;
