import React, { useContext, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, CColCodigoTipoProg } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { toast, ToastContainer } from "react-toastify";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { useParams } from "react-router-dom";
import api from "../../../../utils/api";

const TipoDePrograma = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const [valuesTipoDePrograma, setValuesTipoDePrograma] = useState({
    cliente_id: currentClient.clienteId,
    codigo: "",
    descricao: "",
    indicador: "",
  });
  const [tipoDeProgramaId, setTipoDeProgramaId] = useState("");
  const [codigosTipoDeProgramaPadrao, setCodigosTipoDeProgramaPadrao] =
    useState([]);

  const { codigo: paramsCodigo } = useParams();

  const fetchData = () => {
    api
      .get(
        `tipoDePrograma/por-regiao/${currentClient.endereco.municipio}/${paramsCodigo}`
      )
      .then((resp) => {
        const data = resp.data[0];
        setValuesTipoDePrograma((prevValues) => ({
          ...prevValues,
          codigo: data.codigo,
          descricao: data.descricao,
          indicador: data.indicador,
        }));

        setTipoDeProgramaId(data.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCodigoTipoDeProgramaPadrao = () => {
    api
      .get("tipoDePrograma/padrao")
      .then((resp) => {
        const codigoPadrao = resp.data.map(
          (tipoProgramaPadrao) => tipoProgramaPadrao.codigo
        );
        setCodigosTipoDeProgramaPadrao(codigoPadrao);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (paramsCodigo) {
      fetchData();
      getCodigoTipoDeProgramaPadrao();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValuesTipoDePrograma((prevValues) => ({
      ...prevValues,
      cliente_id: currentClient.clienteId,
    }));
  }, [currentClient]);

  const cadastrarValorCustom = () => {
    console.log(valuesTipoDePrograma);
    api
      .post("tipoDePrograma/custom", valuesTipoDePrograma)
      .then((response) => {
        console.log(response);
        toast.success("Dados cadastrados com sucesso!");
        setValuesTipoDePrograma((prevValues) => ({
          ...prevValues,
          codigo: "",
          descricao: "",
          indicador: "",
        }));
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu errado");
      });
  };

  // const cadastrarValorPadrao = () => {
  //   const valuesPadrao = {
  //     codigo: valuesTipoDePrograma.codigo,
  //     descricao: valuesTipoDePrograma.descricao,
  //     indicador: valuesTipoDePrograma.indicador,
  //   };

  //   console.log(valuesPadrao);
  //   api
  //     .post("tipoDePrograma/padrao", valuesPadrao)
  //     .then((response) => {
  //       console.log(response);
  //       toast.success("Dados cadastrados com sucesso!");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error("Algo deu errado");
  //     });
  // };

  const atualizarValor = () => {
    const valorPut = {
      descricao: valuesTipoDePrograma.descricao,
      indicador: valuesTipoDePrograma.indicador,
    };

    console.log(valorPut);
    console.log("atualizando valor");
    api
      .put(
        `tipoDePrograma/custom/${currentClient.clienteId}/${paramsCodigo}/${tipoDeProgramaId}`,
        valorPut
      )
      .then((resp) => {
        console.log(resp);
        toast.success("Dados atualizados com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu errado ao atualizar os dados");
      });
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        withRow={true}
        ButtonSaveFunction={
          paramsCodigo ? atualizarValor : cadastrarValorCustom
        }
        BotaoSaveAtivo={
          !codigosTipoDeProgramaPadrao.includes(Number(paramsCodigo))
        }
        NomePaginaTitulo={"Tipo de Programa"}
        PaginaSubtitulo={paramsCodigo ? "Editar" : "Cadastro"}
        PaginaConsulta={"/planejamento/cadastro/tipo-de-programa/consulta"}
        DashboardPage={"/planejamento/cadastro/tipo-de-programa/dashboard"}
        PaginaRelatorio={"/planejamento/cadastro/tipo-de-programa/relatorio"}
      />
      <ToastContainer />
      <Row className="row_form mt-1" style={{ borderRadius: "0 0 5px 5px" }}>
        <CColCodigoTipoProg md={2}>
          <FloatingLabelInput
            placeholder="Código"
            label="Código"
            maxLength="1"
            disabled={paramsCodigo ? true : false}
            value={valuesTipoDePrograma.codigo}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
              setValuesTipoDePrograma((prevValues) => ({
                ...prevValues,
                codigo: e.target.value,
              }));
            }}
          />
        </CColCodigoTipoProg>
        <CCol>
          <FloatingLabelInput
            placeholder="Descrição"
            label="Descrição"
            disabled={codigosTipoDeProgramaPadrao.includes(
              Number(paramsCodigo)
            )}
            value={valuesTipoDePrograma.descricao}
            onChange={(e) => {
              setValuesTipoDePrograma((prevValues) => ({
                ...prevValues,
                descricao: e.target.value,
              }));
            }}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            new={true}
            tipo="input_select"
            label="Indicador"
            placeholder="Indicador"
            value={valuesTipoDePrograma.indicador}
            onSelect={(option) =>
              setValuesTipoDePrograma((prevValues) => ({
                ...prevValues,
                indicador: option.value,
              }))
            }
            options={[
              { label: "Indicador", value: null },
              { label: "Sim", value: "Sim" },
              { label: "Não", value: "Não" },
            ]}
            disabled={codigosTipoDeProgramaPadrao.includes(
              Number(paramsCodigo)
            )}
          />
        </CCol>

        {/* <CCol md={12}>
          <button
            style={{ width: "100%", marginTop: "10px" }}
            onClick={() => cadastrarValorPadrao()}
          >
            Cadastrar Valor Padrão
          </button>
        </CCol> */}
      </Row>
    </Container>
  );
};

export default TipoDePrograma;
