import React from "react";
import styled from "styled-components";

const SwitchLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 35px;
  padding: 10px 2px;
  height: 20px;
  border-radius: 25px;
  background-color: ${({ $checked }) => ($checked ? "#2F7716" : "#b1b0b6")};
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  pointer-events: ${({ $switchDesativado }) =>
    $switchDesativado ? "none" : "auto"};

  input[type="checkbox"] {
    display: none;
  }

  & div {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    height: 14px;
    width: 14px;
    transform: translateX(${({ $checked }) => ($checked ? "16px" : "0px")});
    transition: all 0.5s ease-in-out;
  }
`;

const SwitchButton = ({ $checked, $switchDesativado, ...props }) => {
  return (
    <SwitchLabel
      $checked={$checked}
      $switchDesativado={$switchDesativado}
      {...props}
    >
      <div></div>
    </SwitchLabel>
  );
};

export default SwitchButton;
