import styled from "styled-components";
import { Col } from "react-bootstrap";
import { Form } from "react-bootstrap";

export const ColMaiorEsquerda = styled(Col)`
  /* height: 755px; */
`;

export const ContainerOptions = styled(Col)`
  padding: 0 10px;
  margin-top: 10px;
  max-height: calc(100% - 136px);
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ContainerMaiorEsquerda = styled.div`
  background-color: #fcfcfc;
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 755px;
  padding-top: 10px;
`;

export const FormControlStyledDir = styled(Form.Control)`
  background: transparent;

  :focus {
    outline: none;
    box-shadow: none;
    border-color: #ced4da;
    background: transparent;
  }
`;

export const LabelCheckLOA = styled(Form.Check)`
  color: #6c7585;
  font-weight: 500;
  padding: 5px 0 5px 28px;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 2px solid #f2f3f8;
  display: flex;
  align-items: flex-start;
  gap: 4px;
  white-space: nowrap;
  overflow: hidden;

  input[type="checkbox"] {
    background-color: #f7f7f7;
    border-radius: 2px;
    border: 2px solid #515c70;
    /* margin-left: 5px; */
    /* margin-right: 7px; */
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  input[type="checkbox"]:checked {
    background-color: green;
    border-color: green;
  }
`;

export const ButtonIconComBorda = styled.button`
  width: 100%;
  height: 38px;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #ced4da;
`;

export const LabelCheckLoa2 = styled(Form.Check)`
  color: #6c7585;
  font-weight: 500;
  padding: ${(props) => (props.paddingDisabled ? null : "0px 0 5px 0")};
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: flex-start;
  gap: 4px;

  input[type="checkbox"] {
    background-color: #f7f7f7;
    border-radius: 2px;
    border: 2px solid #515c70;
    margin-left: 0px;
    /* margin-right: 7px; */
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  input[type="checkbox"]:checked {
    background-color: green;
    border-color: green;
  }
`;

export const ColMaiorDireita = styled(Col)`
  height: 445px;
`;

export const ContainerMaiorDireita = styled.div`
  background-color: #fcfcfc;
  border: 1px solid #ced4da;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  height: 400px;
`;

export const ColMenorDireita = styled(Col)`
  /* height: 240px; */
  height: 100%;
`;

export const ContainerMenorDireita = styled.div`
  background-color: #fcfcfc;
  border: 1px solid #ced4da;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  /* height: 195px; */
  padding-top: 10px;
`;

export const ParteInferiorContDirMaior = styled.div`
  background-color: #fcfcfc;
  border: 1px solid #ced4da;
  border-radius: 0 0 5px 5px;
  height: 45px;
  padding-top: 10px;

  p {
    padding-left: 12px;
    color: #515c70;
  }
`;

export const ParteInferiorContDirMenor = styled.div`
  background-color: #f2f3f8;
  border: 1px solid #ced4da;
  border-radius: 0 0 5px 5px;
  height: 45px;
  padding-top: 10px;
  text-align: center;

  p {
    padding-left: 12px;
    color: #515c70;
  }

  svg {
    margin-left: 5px;
  }
`;

export const ButtonContDirMenor = styled.button`
  background: transparent;
  border: none;
  color: #515c70;
`;

export const MenuTopo = styled.div`
  display: flex;
  border-bottom: 1px solid #ced4da;
  padding-bottom: 5px;

  a {
    margin-right: 30px;
    color: #515c70;
    padding-bottom: 5px;
    text-decoration: none;
  }
`;

export const ButtonSelectAba = styled.button`
  color: #515c70;
  border: none;
  margin-right: 30px;
  background-color: transparent;
  padding-bottom: 5px;
  margin-bottom: -5px;
  ${({ active }) =>
    active ? "border-bottom: 2px solid #515c70; font-weight: 600" : ""};
`;

export const TituloColDir = styled.div`
  border-bottom: 1px solid #ced4da;
  padding: 7px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    padding-left: 12px;
    font-weight: bold;
    color: #515c70;
  }
`;

export const MenuTopoMenus = styled.div`
  padding-left: 12px;
`;

export const LabelsContMaiorDir = styled.div`
  max-height: calc(100% - 40px);
  overflow-y: auto;
  padding-left: 12px;

  p {
    margin: 2px 0 0 0;
    font-weight: 500;
    color: #515c70;
  }
`;

export const TituloAbaOpcoes = styled.h6`
  color: #515c70;
  border-bottom: 1px solid #ced4da;
  padding-bottom: 5px;
`;
