import styled from "styled-components";

export const DivPrintOnly = styled.div`
  display: none;

  /* @page {
    margin: 15px;
    size: 21cm 29.7cm ; A4 em orientação retrato
  } */

  @media print {
    display: block;
  }
`;

export const RodapeRelatorio = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0px;
  width: 100%;
  font-size: 12px;

  p > span {
    font-weight: 600;
  }
`;

export const InfosDaImpressao = styled.div`
  display: flex;
  justify-content: end;

  p > span {
    text-transform: uppercase;
    font-weight: 400;
  }
`;

export const InfosGeraisRodape = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #000;
`;

export const DivHideOnPrint = styled.div`
  visibility: visible;

  @media print {
    visibility: hidden;
  }
`;

export const DivPageBreakPrint = styled.div`
  @media print {
    page-break-after: always;
  }
`;

export const FirstDivPageBreakPrint = styled.div`
  @media print {
    page-break-before: always;
  }
`;

export const DivExterna = styled.div`
  /* border: 1px solid red; */
  position: relative;
  z-index: 100;
  width: 100%;

  overflow-x: hidden;
  background-color: #f2f3f8;

  @media print {
    height: 100vh;
    box-sizing: border-box;
    left: -210px;
    width: ${({orientation}) => orientation === 'portrait' ? '1080px' : '1540px'};
    background-color: #fff;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

export const DivRelatorio = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const Colunas = styled.div`
  display: flex;
  gap: 2px;
`;

export const DivTituloColuna = styled.div`
  background-color: #515c70;
  text-align: center;
  padding: 30px;

  h6 {
    white-space: nowrap;
    margin: 0;
    padding: 0;
    color: #fff;
  }
`;

export const SubDivColunas = styled.div`
  background-color: #a6a6a6;
  padding: 3px 0px;
  min-height: 24px;
  max-height: 24px;

  h6 {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-size: 12px;
  }
  @media print {
    h6 {
      font-size: 0.95em;
    }
  }
`;

export const DivInfosDoRelatorio = styled.div`
  padding: 0px 20px 0px 20px;
  padding-right: 4px;

  p {
    margin: 0;
    padding: 0;
  }
`;

export const Coluna = styled.div`
  flex-shrink: 0;
`;

export const InfosColunas = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivInferiorColuna = styled.div`
  min-height: 15px;
  background-color: #515c70;
  padding: 2px 10px 2px 10px;

  h6 {
    margin: 0;
    padding: 0;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
  }

  @media print {
    h6 {
      font-size: 0.95em;
    }
  }
`;

export const DivExternaCabecalhoRelatorio = styled.div`
  margin-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #000;
`;

export const DivInternaCabecalho = styled.div`
  display: flex;
  align-items: center;

  h4 {
    text-transform: uppercase;
    margin: 0;
  }

  p {
    margin: 0px;
    padding: 0px;
  }

  h5 {
    margin: 0px;
    padding: 0px;
  }
`;

export const DivImageCabecalho = styled.div`
  max-width: 115px;
  max-height: 115px;
  flex-basis: 150px;
  flex-shrink: 0;
  overflow: hidden;
  margin-right: 20px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivInfosCabecalho = styled.div`
  flex-basis: 600px;
  flex-shrink: 0;
`;
