import React, { useContext } from "react";
import styled from "styled-components";
import { OpenedModalsContext } from "../../contexts/OpenedModalsContext";
import HeaderModalAutoComplete from "./HeaderModalAutoComplete";

const ModalFadeDiv = styled.div`
  opacity: ${({ modalOpened }) => (modalOpened ? "1" : "0")};
  pointer-events: ${({ modalOpened }) => (modalOpened ? "all" : "none")};
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 100;
  transition: 0.2s opacity;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  align-items: center;
`;

const ModalContainer = styled.div`
  opacity: ${({ modalOpened }) => (modalOpened ? "1" : "0")};
  pointer-events: ${({ modalOpened }) => (modalOpened ? "all" : "none")};
  position: absolute;
  top: 0;
  z-index: 100;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding-top: 7px;
  margin-top: 100px;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  /* width: 100vw; */
  /* transition: 0.2s opacity; */
`;

const ModalDivPaddingInferior = styled.div`
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: -5px;
  border-radius: 0px 0px 5px 5px;
`;

const ModalAutoComplete = ({ Component, tituloModal }) => {
  const { openedModals } = useContext(OpenedModalsContext);

  return (
    <>
      <ModalFadeDiv
        modalOpened={openedModals.some(
          (item) => item.tituloModal === tituloModal
        )}
      >
        <ModalContainer
          modalOpened={openedModals.some(
            (item) => item.tituloModal === tituloModal
          )}
        >
          <HeaderModalAutoComplete Titulo={tituloModal} />
          {Component}
          <ModalDivPaddingInferior></ModalDivPaddingInferior>
        </ModalContainer>
      </ModalFadeDiv>
    </>
  );
};

export default ModalAutoComplete;
