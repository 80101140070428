import { Row } from "react-bootstrap";
import styled from "styled-components";

export const RowInterna = styled(Row)`
  margin: 0;
  padding: 0;
`;

export const BoxCinzaTexto = styled.p`
  background-color: #e8e8e8;
  border-radius: 5px;
  padding: 5px 10px;
  color: #515c70;
  font-weight: 600;
`;

export const TextAreaCinza = styled.textarea`
  width: 100%;
  background-color: #e9ecef;
  line-height: 25px;
  padding: 10px;
  border-radius: 5px;
  color: #515c70;
  font-weight: 600;
  border: none;
  height: 40px;
  min-height: 40px;
  font-size: 14px;
  overflow-y: hidden;
`;

export const TituloAbaSolo = styled.h6`
  color: #515c70;
  padding: 0;
  padding-left: 11px;
  margin: 0;
  font-weight: 600;
`;
