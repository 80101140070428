import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

const calculateTotalUnidade = (naturezasConsolidadas) => {

  if (!naturezasConsolidadas.length) return 0;

  const total = naturezasConsolidadas.reduce((acc, natureza) => {
    const seccoes = natureza.codigo
      .split(".")
      .filter((secao) => parseInt(secao) !== 0);

    if (seccoes.length !== 1) return acc;
    return (acc += parseFloat(natureza.valor));
  }, 0);
  return total;
};

const calcularTotalGeralUnidadeGestora = (unidadesGestoras) => {
  const total = unidadesGestoras.reduce((acc, unidade) => {
    return (acc += unidade.total);
  }, 0);
  return total;
};

export const getDespesaDemonstrativoUnidade = async (
  clienteId,
  entidadesGestoras,
  ano = 2024
) => {
  const url = getUrl(clienteId, entidadesGestoras);

  try {
    const response = await api.get(
      `naturezaDaDespesa/relatorio/unidades/${url.idsUrl}/${ano}?${url.filterConsolidado}`
    );

    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Demonstrativo da Despesa por Unidade Orçamentária",
    };
  }
};

export const processAllData = (dataUnidades) => {

  const dataUnidadeGestoras = Object.keys(
    dataUnidades.contasAtualizadas?.relatorios || {}
  ).map((key) => {
    const unidadeData = dataUnidades.contasAtualizadas.relatorios[key];

    return {
      id: unidadeData.unidadeInfo.unidadeGestoraId,
      codigo: unidadeData.unidadeInfo.codigo,
      especificacao: unidadeData.unidadeInfo.titulo,
      total: calculateTotalUnidade(unidadeData.naturezasConsolidadas),
    };
  });

  dataUnidadeGestoras.sort((unidadeA, unidadeB) => {
    const unidadeGestoraA = parseFloat(unidadeA.codigo.replace(".", ""));
    const unidadeGestoraB = parseFloat(unidadeB.codigo.replace(".", ""));
    return unidadeGestoraA - unidadeGestoraB;
  });

  const totalReceitas = calcularTotalGeralUnidadeGestora(dataUnidadeGestoras);

  return {
    data: dataUnidadeGestoras,
    sum: totalReceitas,
  };
};
