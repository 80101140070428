import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import styled from "styled-components";

const ModalContainer = styled.div`
  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ modalOpened }) => (modalOpened ? "1" : "0")};
  visibility: ${({ modalOpened }) => (modalOpened ? "visible" : "hidden")};
  transition: all 0.3s;
`;

const ModalBox = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;

  h4 {
    text-align: center;
  }

  p {
    max-width: 730px;
    text-align: center;
    color: #515c70;
  }

  button {
    color: #fff;
    font-weight: 500;
    background-color: #fec338;
    border: none;
    border-radius: 5px;
    padding: 7px;
    transition: all 0.2s;
  }

  button:hover {
    background-color: #e4aa21;
    color: #fff;
  }
`;

const ModalAviso = ({ modalOpened, setModalOpened, msg, tituloModal }) => {
  return (
    <>
      <ModalContainer modalOpened={modalOpened}>
        <ModalBox>
          <Icon
            icon="clarity:warning-line"
            width={100}
            style={{ color: "#FFC94A" }}
          />
          <h4>{tituloModal}</h4>
          <p>{msg}</p>
          <button onClick={() => setModalOpened(false)} variant="warning">
            Continuar
          </button>
        </ModalBox>
      </ModalContainer>
    </>
  );
};

export default ModalAviso;
