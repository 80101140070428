import { Icon } from "@iconify/react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import SetaSelect from "../../assets/Icons/arrow-back-ios-rounded.svg";
import "../../styles/Css/Home/home_test.css";

const TituloSup = styled.p`
  font-weight: 700;
  color: #515c70;
`;

const ColTituloSup = styled(Col)`
  margin-top: 20px;
  margin-left: -10px;
`;

const RowTutorial = styled(Row)`
  background-color: white;
  border-radius: 5px;
  padding: 20px;
`;

const RowConteudoTutorial = styled(Row)`
  background-color: #c6d9fc;
  border-radius: 5px;
  width: 100%;
  margin: 0px;
  padding: 22px 17px;
  z-index: 0;
  height: 344px;
`;

const DivTopoPassos = styled.div`
  display: flex;
  align-items: end;
`;

const ColStepsTutorial = styled(Col)``;

const DivTituloPasso = styled.div`
  margin-right: 29px;
  margin-bottom: -5px;
  h1,
  h3 {
    color: #515c70;
  }

  h1 {
    font-size: 2.2em;
    font-weight: 700;
  }

  h3 {
    font-weight: 400;
    font-size: 1.17em;
    line-height: 1px;
  }
`;

const DivExemplosTutorial = styled.div`
  margin-top: 7px;

  h4 {
    color: white;
  }

  p {
    line-height: 15px;
    color: #515c70;
    font-weight: 600;
  }
`;

const DivSelectTutorial = styled.div`
  width: 172px;
  height: 38px;
  border-radius: 5px;
  background: white url(${SetaSelect}) 7px 8px no-repeat;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`;

const BoxSistemaTutorial = styled.div`
  display: flex;
  align-items: center;
  padding-left: 65px;

  ${(props) => {
    if (props.siststyle === "contabilidade") {
      return "background: linear-gradient(96.1deg, #F0A8E9 45.18%, rgba(240, 168, 233, 0) 116.26%);";
    } else if (props.siststyle === "planejamento") {
      return "background: linear-gradient(94.25deg, #F9B48D 46.54%, rgba(251, 118, 44, 0) 113.11%);";
    } else {
      return "background: linear-gradient(98.92deg, #91C788 43.22%, rgba(145, 199, 136, 0) 115%);";
    }
  }}
  box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 15px;
  width: 279px;
  height: 32px;
  border-radius: 10px;

  p {
    color: white;
    margin-top: 16px;
    margin-left: 10px;
  }
`;

const ColInfoSup = styled(Col)`
  box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-radius: 17px;
  margin-top: -50px;
  z-index: 0;
  padding: 0 50px;
`;

const DivTituloSuporte = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border-bottom: 1px solid #f2f3f8;

  h1 {
    color: #515c70;
    font-size: 25px;
    font-weight: 700;
  }
`;

const ParagraphSuporte = styled.p`
  color: #515c70;
`;

const ColContatos = styled(Col)`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 80px;
`;

const DivContato = styled.div`
  text-align: center;
`;

function HomeTest() {
  return (
    <Container
      id="pags_responsividade_margin_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <Row>
        <ColTituloSup md={12}>
          <TituloSup>Suporte</TituloSup>
        </ColTituloSup>
      </Row>

      <RowTutorial>
        <RowConteudoTutorial>
          <ColStepsTutorial md={3}>
            <DivTopoPassos>
              <DivTituloPasso>
                <h1>1º Passo</h1>
                <h3 style={{ fontSize: "1.15em" }}>Clique no botão</h3>
              </DivTituloPasso>
              <Icon
                height={27}
                style={{
                  transform: "scale(1.4,1.3)",
                  marginRight: "14px",
                }}
                icon="ic:baseline-place"
                color="#515c70"
              />
            </DivTopoPassos>
            <DivExemplosTutorial>
              <h4>escolha a cidade</h4>
              <p>Patos - PB</p>
              <p>Patos - PB</p>
              <p>Patos - PB</p>
            </DivExemplosTutorial>
          </ColStepsTutorial>
          <ColStepsTutorial md={5}>
            <DivTopoPassos>
              <DivTituloPasso>
                <h1>2º Passo</h1>
                <h3>Clique no botão</h3>
              </DivTituloPasso>
              <DivSelectTutorial></DivSelectTutorial>
            </DivTopoPassos>
            <DivExemplosTutorial>
              <h4>escolha a entidade gestora</h4>
              <p>Prefeitura Municipal de Patos</p>
              <p>Instituto de Previdência dos Servidores Municipais</p>
              <p>Câmara Municipal de Patos</p>
            </DivExemplosTutorial>
          </ColStepsTutorial>
          <ColStepsTutorial md={4}>
            <DivTopoPassos>
              <DivTituloPasso>
                <h1>3º Passo</h1>
                <h3>Clique no botão</h3>
              </DivTituloPasso>
              <Icon
                height={25}
                style={{
                  transform: "scale(1.4,1.3)",
                  marginRight: "14px",
                }}
                icon="icon-park-solid:system"
                color="#515c70"
              />
            </DivTopoPassos>
            <DivExemplosTutorial>
              <h4>escolha o sistema</h4>
              <BoxSistemaTutorial siststyle="planejamento">
                <Icon icon="mdi:clipboard-outline" color="white" height={25} />
                <p>Planejamento</p>
              </BoxSistemaTutorial>
              <BoxSistemaTutorial siststyle="contabilidade">
                <Icon
                  icon="material-symbols:donut-small-outline"
                  color="white"
                  height={25}
                />
                <p>Contabilidade</p>
              </BoxSistemaTutorial>
              <BoxSistemaTutorial>
                <Icon icon="mdi:chart-finance" color="white" height={25} />
                <p>Financeiro</p>
              </BoxSistemaTutorial>
            </DivExemplosTutorial>
          </ColStepsTutorial>
        </RowConteudoTutorial>
        <ColInfoSup md={{ span: 8, offset: 2 }}>
          <DivTituloSuporte>
            <h1>Suporte</h1>
          </DivTituloSuporte>
          <ParagraphSuporte>
            Nossos sistemas são explicativos, mas ainda assim você pode precisar
            de suporte. Felizmente, oferecemos várias opções para ajudá-lo a
            obter suporte quando necessário. Você pode escolher a opção que
            melhor se adapte às suas necessidades e preferências.
          </ParagraphSuporte>
        </ColInfoSup>
        <ColContatos md={{ span: 8, offset: 2 }}>
          <DivContato>
            <Icon width={60} icon="logos:whatsapp-icon" />
            <p>Whatsapp</p>
          </DivContato>
          <DivContato>
            <Icon
              width={60}
              icon="material-symbols:mail-outline"
              color="#f2d22a"
            />
            <p>
              E-mail:
              <br /> suporte@savest.com.br
            </p>
          </DivContato>
          <DivContato>
            <Icon width={60} icon="mingcute:cellphone-line" color="#515c70" />
            <p>
              Telefone:
              <br /> 0800-123-12345
            </p>
          </DivContato>
        </ColContatos>
      </RowTutorial>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default HomeTest;
