import api from "../../../../../../utils/api";
import { getUrl } from "../getUrlConsolidado";

export const getDespesaClassificacao = async (
  clientes,
  entidadesGestoras,
  ano = 2024
) => {
  const url = getUrl(clientes, entidadesGestoras);

  try {
    const response = await api.get(
      `qdd/relatorio/agregado/${url.idsUrl}/${ano}?${url.filterConsolidado}&anexo=1`
    );

    return formattedAllDespesas(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
    return {
      error: "error",
      name: "Anexo V - Despesa por Classificação Funcional Programática Função Subfunção Programa Ações",
    };
  }
};

const calcularTotalGeral = (data) => {
  return data.reduce((totalGeral, item) => {
    const unidades = item.infoUnidade.unidades;
    const totalPorUnidade = Object.keys(unidades).reduce(
      (total, unidadeKey) => {
        const unidade = unidades[unidadeKey];
        return total + unidade.totalDaUnidade;
      },
      0
    );
    return totalGeral + totalPorUnidade;
  }, 0);
};

const formattedAllDespesas = (dataQddRelatorioOrgaoUnidade) => {
  console.log(dataQddRelatorioOrgaoUnidade);

  const dadosFormatados = Object.keys(dataQddRelatorioOrgaoUnidade).map(
    (key) => {
      const unidade = dataQddRelatorioOrgaoUnidade[key];
      return {
        infoUnidade: {
          unidades: unidade.unidadesOrcamentarias,
        },
      };
    }
  );

  const ordenarPorCodigoUnidadeOrcamentaria = (obj) => {
    return Object.values(obj).sort(
      (a, b) =>
        parseFloat(a.codigoUnidadeOrcamentaria.replace(".", "")) -
        parseFloat(b.codigoUnidadeOrcamentaria.replace(".", ""))
    );
  };

  dadosFormatados[0].infoUnidade.unidades = ordenarPorCodigoUnidadeOrcamentaria(
    dadosFormatados[0].infoUnidade.unidades
  );

  const totalEntidades = calcularTotalGeral(dadosFormatados);
  const orderJson = (data) => {
    // Verifica se data é um array
    if (!Array.isArray(data)) {
      console.error("Data não é um array.");
      console.log("EU SOU DATA: ", data);
      return data; // Retorne data como está, se não for um array
    }

    // Itera sobre cada item no array de data
    data.forEach((item, index) => {
      if (!item?.infoUnidade?.unidades) {
        console.error(
          `Estrutura de dados inválida ou unidades não definidas no item ${index}.`
        );
        console.log("EU SOU ITEM: ", item);
        return;
      }

      item.infoUnidade.unidades.forEach((unidade) => {
        console.log(
          `Agora é a vez de codigoUnidadeOrcamentaria: ${unidade.codigoUnidadeOrcamentaria}`
        );

        // Ordena as funções
        unidade.relatorio = Object.keys(unidade.relatorio)
          .sort((a, b) => a.localeCompare(b))
          .reduce((acc, key) => {
            console.log(`Agora é a vez de codigoFuncao: ${key}`);
            const funcao = unidade.relatorio[key];

            // Ordena as subfunções
            funcao.subFuncoes = Object.keys(funcao.subFuncoes)
              .sort((a, b) => a.localeCompare(b))
              .reduce((subAcc, subKey) => {
                console.log(`Agora é a vez de codigoSubFuncao: ${subKey}`);
                const subFuncao = funcao.subFuncoes[subKey];

                // Ordena os programas
                subFuncao.programas = Object.keys(subFuncao.programas)
                  .sort((a, b) => a.localeCompare(b))
                  .reduce((progAcc, progKey) => {
                    console.log(`Agora é a vez de codigoPrograma: ${progKey}`);
                    const programa = subFuncao.programas[progKey];

                    // Ordena as ações
                    programa.acoes = Object.keys(programa.acoes)
                      .sort((a, b) => a.localeCompare(b))
                      .reduce((acaoAcc, acaoKey) => {
                        console.log(`Agora é a vez de nAcao: ${acaoKey}`);
                        acaoAcc[acaoKey] = programa.acoes[acaoKey];
                        return acaoAcc;
                      }, {});

                    progAcc[progKey] = programa;
                    return progAcc;
                  }, {});

                subAcc[subKey] = subFuncao;
                return subAcc;
              }, {});

            acc[key] = funcao;
            return acc;
          }, {});
      });
    });

    return data;
  };

  const orderedData = orderJson(dadosFormatados);

  console.log("dadosFormatados: ", dadosFormatados, "E", orderedData);

  return {
    dataDespesa: orderedData,
    total: totalEntidades,
  };
};
