import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MakeADeepCopyArray } from "../../../../../utils/Gerais/MakeADeepCopyArray";
import { NormalizeDate } from "../../../../../utils/Gerais/NormalizeDate";
import { returnDate } from "../../../../../utils/Gerais/ReturnDate";

const useGestao = (arraySistemas, setValuesClientes, valuesCliente) => {
  const [gestaoAdicionar, setGestaoAdicionar] = useState({
    pessoaId: 0,
    dataInicial: returnDate(),
    dataFim: "",
    modulos: [...arraySistemas],
  });
  const [arrayGestoes, setArrayGestoes] = useState([
    // {
    //   pessoaId: 0,
    //   dataInicial: "",
    //   dataFim: "",
    //   sistemas: [],
    // },
  ]);
  const [gestaoToDelete, setGestaoToDelete] = useState();
  const [gestaoToDeactivate, setGestaoToDeactivate] = useState({
    id: 0,
  });
  const [modalDeleteGestaoOpened, setModalDeleteGestaoOpened] = useState(false);

  const [modalDesativaGestaoOpened, setModalDesativaGestaoOpened] =
    useState(false);

  const adicionarPessoaNaGestao = (option) => {
    setGestaoAdicionar((prev) => ({
      ...prev,
      pessoaId: option.id,
    }));
  };

  const adicionarNovaGestao = () => {
    const nenhumValorVazio = ![
      gestaoAdicionar.pessoaId,
      gestaoAdicionar.dataInicial,
      gestaoAdicionar.dataFim,
    ].some((item) => item === 0 || item === undefined || item === "");

    if (!nenhumValorVazio) {
      toast.error(
        "Preencha todos os campos corretamente para adicionar uma nova gestão."
      );

      return;
    }

    if (valuesCliente.gestao.length > 0) {
      toast.error(
        "Só é possivel manter uma gestão ativa por vez. Desative a gestão atual para cadastrar uma nova gestão."
      );
      return;
    }

    let dataAtual = NormalizeDate(new Date());
    const dataInicial = new Date(gestaoAdicionar.dataInicial);
    const dataFim = new Date(gestaoAdicionar.dataFim);
    console.log(dataInicial < dataAtual);
    console.log(dataAtual);
    console.log(dataInicial);
    console.log(dataFim);
    console.log(gestaoAdicionar.dataInicial);

    const datasSaoConflitantes = valuesCliente.gestao.some((item) => {
      const itemDataFim = new Date(item.dataFim);

      console.log(itemDataFim, dataInicial);

      return dataInicial <= itemDataFim;
    });

    if (datasSaoConflitantes) {
      toast.error("Já existe uma gestão ativa para este periodo de tempo.");
      return;
    }

    // if (dataInicial < dataAtual) {
    //   toast.error("A data inicial não pode ser menor do que a data atual.");
    //   return;
    // }

    if (dataFim <= dataInicial) {
      toast.error("A data final não pode ser menor ou igual a data inicial.");
      return;
    }

    setValuesClientes((prev) => ({
      ...prev,
      gestao: [...prev.gestao, gestaoAdicionar],
    }));
  };

  const deleteGestao = () => {
    setArrayGestoes((prev) =>
      prev.filter((item, index) => index !== gestaoToDelete.index)
    );
  };

  const closeModalDeleteGestao = () => {
    setModalDeleteGestaoOpened(false);
  };

  const openModalDeleteGestao = () => {
    setModalDeleteGestaoOpened(true);
  };

  const handleChangeGestaoDate = (index, campo, valor) => {
    setValuesClientes((prev) => {
      let newArrayGestoes = prev.gestao;

      newArrayGestoes[index][campo] = valor;

      return {
        ...prev,
        gestao: newArrayGestoes,
      };
    });
  };

  useEffect(() => {
    setValuesClientes((prev) => ({
      ...prev,
      gestao: MakeADeepCopyArray(arrayGestoes),
    }));

    //eslint-disable-next-line
  }, [arrayGestoes]);

  return {
    arrayGestoes,
    gestaoAdicionar,
    setGestaoAdicionar,
    adicionarNovaGestao,
    setGestaoToDelete,
    deleteGestao,
    adicionarPessoaNaGestao,
    closeModalDeleteGestao,
    openModalDeleteGestao,
    modalDeleteGestaoOpened,
    setArrayGestoes,
    handleChangeGestaoDate,
    modalDesativaGestaoOpened,
    setModalDesativaGestaoOpened,
    gestaoToDeactivate,
    setGestaoToDeactivate,
  };
};

export default useGestao;
