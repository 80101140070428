import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { paginateDataRelatorio } from "../../utils/paginateDataRelatorio";

export const RenderPdfDespesaCatEconOrgao = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const pages = paginateDataRelatorio(dados.data);

  const codigoSectionRelatorio = "3.0.00.00.00";

  const colNames = [
    "Código",
    "Especificações",
    "Desdobramento",
    "Grupo da Natureza",
    "Categoria Econômica",
  ];
  const styledCol = [
    { flexBasis: "150px", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    {
      flexBasis: "225px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "160px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "right",
      paddingRight: 2,
    },
    {
      flexBasis: "160px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const optionDespesaCatEconOrgao = opcoes.find(
    (option) =>
      option.opcao === "Despesa Segundo as Categorias Econômicas - Órgão"
  );

  const normalizeString = (str) => {
    return str.endsWith(".") ? str.slice(0, -1) : str;
  };

  const renderReceitas = (natureza, key) => {
    // const naturezaSection = codigosSectionRelatorio.includes(natureza.codigo);
    const seccoes = natureza.codigo
      .split(".")
      .filter((secao) => parseInt(secao) !== 0);

    const fonteRecursoStyleBold =
      natureza.codigo < codigoSectionRelatorio.length;
    const despesaStyleBold = seccoes.length <= 2;
    const despesaSection = seccoes.length === 1;

    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            backgroundColor: despesaSection ? "#a6a6a6" : "transparent",
            fontWeight:
              despesaStyleBold | fonteRecursoStyleBold ? "bold" : "normal",
            marginHorizontal: -3,
          },
        ]}
        key={`${key} - ${natureza.codigo}`}
      >
        {optionDespesaCatEconOrgao?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={natureza.codigo || ""}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaCatEconOrgao?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={normalizeString(natureza.especificacao) || ""}
            styledCol={[styledCol[1], { padding: "2px 0", textAlign: "left" }]}
          />
        )}
        {optionDespesaCatEconOrgao?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={
              natureza.desdobramento
                ? FormatValueToLocaleString(parseFloat(natureza.desdobramento))
                : ""
            }
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
                flexBasis: "160px",
                flexShrink: 1,
                flexGrow: 1,
              },
            ]}
          />
        )}
        {optionDespesaCatEconOrgao?.checkBoxes[3].selected && (
          <PdfInfos
            pdfInfo={
              natureza.grupoNatureza
                ? FormatValueToLocaleString(parseFloat(natureza.grupoNatureza))
                : ""
            }
            styledCol={[
              styledCol[3],
              {
                padding: "2px 0",
                textAlign: "right",
                fontWeight: "bold",
              },
            ]}
          />
        )}
        {optionDespesaCatEconOrgao?.checkBoxes[4].selected && (
          <PdfInfos
            pdfInfo={
              natureza.catEcon
                ? FormatValueToLocaleString(parseFloat(natureza.catEcon))
                : ""
            }
            styledCol={[
              styledCol[4],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderUnidade = (codigo, titulo, unidade = true) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
            backgroundColor: unidade ? "#D0CECE" : "#a6a6a6",
            fontWeight: "bold",
            marginBottom: unidade ? 10 : 0,
            marginHorizontal: -3,
          },
        ]}
        fixed
      >
        {optionDespesaCatEconOrgao?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={codigo}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaCatEconOrgao?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={titulo.toUpperCase() || ""}
            styledCol={[
              styledCol[1],
              {
                padding: "2px 0",
                textTransform: "capitalize",
                textAlign: "left",
              },
            ]}
          />
        )}
        {optionDespesaCatEconOrgao?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
        {optionDespesaCatEconOrgao?.checkBoxes[3].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[3],
              {
                padding: "2px 0",
                textAlign: "center",
              },
            ]}
          />
        )}
        {optionDespesaCatEconOrgao?.checkBoxes[4].selected && (
          <PdfInfos
            pdfInfo={""}
            styledCol={[
              styledCol[4],
              {
                padding: "2px 0",
                textAlign: "right",
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderTotal = (textTotalType, total) => {
    return (
      <View
        wrap={false}
        style={[
          stylesPdf.divInferiorColuna,
          {
            fontWeight: "bold",
            fontSize: 10,
            textTransform: "uppercase",
            color: "white",
            flexDirection: "row",
            justifyContent: "space-between",
            // marginBottom: 10,
          },
        ]}
      >
        <Text>{textTotalType}</Text>
        <Text>{total}</Text>
      </View>
    );
  };

  return pages.map((pageData, pageIndex) => (
    <Page
      key={pageIndex}
      orientation="portrait"
      size={"A4"}
      style={[stylesPdf.page, { paddingBottom: 35 }]}
    >
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={
          "Despesa Segundo a Categoria Econômica por Órgão e Unidade Orçamentária"
        }
        plusText={"Anexo II"}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {optionDespesaCatEconOrgao?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames[index].toUpperCase()}
                styledCol={styledCol[index]}
                hideBar
              />
            )
        )}
      </View>
      <React.Fragment>
        <React.Fragment>
          {pageData.map((unidade, unidadeIndex) => (
            <View key={unidadeIndex} break={unidadeIndex > 0}>
              {renderUnidade(unidade.codigo, unidade.titulo, false)}
              {renderUnidade(
                unidade.infoUnidade.codigo,
                unidade.infoUnidade.especificacao
              )}
              {unidade.infoUnidade.despesas.map((despesa, indexDespesa) =>
                renderReceitas(despesa, indexDespesa)
              )}
              {renderTotal(
                "subtotal",
                FormatValueToLocaleString(unidade.infoUnidade.totalUnidade)
              )}
            </View>
          ))}

          {pageIndex === pages.length - 1 && (
            <View style={{ marginTop: 10 }}>
              {renderTotal("total geral", dados.sum)}
            </View>
          )}
        </React.Fragment>
      </React.Fragment>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  ));
};
