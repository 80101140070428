import React, { useRef, useState } from "react";
import styled from "styled-components";
import { FormatDateToNA } from "../../utils/FormatacaoDeDados/FormatDateToNA";
import { LabelStyled } from "../Inputs/MyAutoComplete";

export const StyledInputDate = styled.input`
  background: transparent;
  border: ${(props) => {
    if (props.$border) {
      return props.$border;
    } else if (props.$backgroundColor) {
      return `1px solid ${props.$backgroundColor}`;
    } else {
      return "1px solid #ccc";
    }
  }};
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : null};
  padding: 6px;
  padding-left: 10px;
  outline: none;
  width: 100%;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 500;
  color: #515c70;
  ${({ height }) => (height !== undefined ? `height: ${height};` : null)};
  ${({ radiusborder }) =>
    radiusborder ? `border-radius: ${radiusborder};` : "5px"};

  ::-webkit-calendar-picker-indicator {
    display: none;
  }

  :disabled {
    background-color: ${({ $disabledBackgroundColor }) =>
      $disabledBackgroundColor ? $disabledBackgroundColor : "#e9ecef"};
    border: 1px solid #ccc;
  }
`;

const InputDataNoYear = React.forwardRef((props, ref) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState(
    props.externalValue ? FormatDateToNA(props.externalValue) : ""
  );

  const handleInputChange = (e) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/\D/g, "");

    if (inputValue.length >= 3) {
      inputValue = inputValue.slice(0, 2) + "/" + inputValue.slice(2, 4);
    }

    setValue(inputValue);
  };

  return (
    <div style={{ position: "relative" }}>
      <LabelStyled
        active={value.length > 0}
        style={value.length === 0 ? { opacity: "0" } : { opacity: "1" }}
      >
        {props.label ? props.label : ""}
      </LabelStyled>
      <StyledInputDate
        ref={ref ? ref : inputRef}
        type="text"
        maxLength={5}
        placeholder="dd/mm"
        onChange={handleInputChange}
        value={value}
        {...props}
      />
    </div>
  );
});

export default InputDataNoYear;
