import * as RS from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/stylesVisualizacaoRelatorio";

import React, { useContext } from "react";

import { CurrentClientContext } from "../../../../../contexts/CurrentClientContext";
import { AuthContext } from "../../../../../contexts/AuthContext";

import { RodapeRelatorio } from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/RodapeRelatorio";
import { InfosRelatorio } from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/InfosRelatorio";
import { ColunasRelatorio } from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/ColunasRelatorio";
import { HeaderRelatorio } from "../../../../../components/ComponenteDeRelatorio/VisualizacaoRelatorio/HeaderRelatorio";

const VizualizacaoRelatorioUnidade = ({
  dadosRelatorio,
  numeroDaPagina,
  totalDePaginas,
  dataClientInfos
}) => {
  const { currentClient } = useContext(CurrentClientContext);
  const { userAuthData } = useContext(AuthContext);

  
  const colNamesUnidades = [
    "Código",
    "Unidade Orçamentária",
    "Tipo de Natureza Jurídica",
    "Tipo de Vínculo",
  ];
  const styledCol = [
    { flexBasis: "125px", flexShrink: 0 },
    { flexBasis: "100%", flexShrink: 1 },
    { flexBasis: "300px", flexShrink: 0 },
    { flexBasis: "200px", flexShrink: 0 },
  ];

  return (
    <RS.ContainerRelatorio>
      <RS.PageHeader>
        <HeaderRelatorio
          currentClient={currentClient}
          relatorioName={"Unidade Orçamentária"}
          dataClientInfos={dataClientInfos}
        />
      </RS.PageHeader>

      <RS.DivExterna>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <td>
                <RS.PageHeaderSpace />

                <RS.Colunas style={{ height: "88px" }}>
                  {colNamesUnidades.map((item, index) => (
                    <ColunasRelatorio
                      key={index}
                      colName={item}
                      styledCol={styledCol[index]}
                    />
                  ))}
                </RS.Colunas>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <RS.DivRelatorio>
                  {/* <RS.Colunas>
                    {colNamesPrograma.map((item, index) => (
                      <ColunasRelatorio
                        key={index}
                        colName={item}
                        styledCol={styledCol[index]}
                      />
                    ))}
                  </RS.Colunas> */}
                  <div
                    style={{ marginTop: "10px", backgroundColor: "red" }}
                  ></div>
                  <RS.InfosColunas>
                    {dadosRelatorio?.map((item) => (
                      <React.Fragment key={item.unidade.id}>
                        <RS.Colunas>
                          <InfosRelatorio
                            infoRelatorio={item.unidade.codigo}
                            styledCol={styledCol[0]}
                          />
                          <InfosRelatorio
                            infoRelatorio={item.unidade.titulo}
                            styledCol={styledCol[1]}
                          />
                          <InfosRelatorio
                            infoRelatorio={item.unidade.naturezaJuridica}
                            styledCol={styledCol[2]}
                          />
                          <InfosRelatorio
                            infoRelatorio={item.unidade.funcao}
                            styledCol={styledCol[3]}
                          />
                        </RS.Colunas>
                        <RS.LineInfosRelatorio />
                      </React.Fragment>
                    ))}
                    <RS.DivInferiorColuna></RS.DivInferiorColuna>
                  </RS.InfosColunas>
                </RS.DivRelatorio>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <RS.PageFooterSpace />
              </td>
            </tr>
          </tfoot>
        </table>
      </RS.DivExterna>
      <RS.PageFooter>
        <RodapeRelatorio
          currentClient={currentClient}
          userAuthData={userAuthData}
        />
      </RS.PageFooter>
    </RS.ContainerRelatorio>
  );
};

export default VizualizacaoRelatorioUnidade;
