import React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";

import { Icon } from "@iconify/react";

const IconesFormUsuario = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin: 1px 0 8px;

  button {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;

    &.edit {
      margin-bottom: 4px;
    }
  }
`;

export const ButtonIcon = styled.button`
  border: none;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
`;

function ConjuntoButtonsForm({ FuncaoIconAdicionar, ActiveAdd, style }) {
  return (
    <IconesFormUsuario style={style}>
      <ButtonIcon
        style={{
          padding: "3px 0",
        }}
        onClick={
          ActiveAdd
            ? FuncaoIconAdicionar
            : () => {
                toast.warning("Adicione um dado valido para adicionar.");
              }
        }
      >
        <Icon
          icon="ic:baseline-add-box"
          color="#105200"
          width="30"
          height="30"
        />
      </ButtonIcon>
    </IconesFormUsuario>
  );
}

export default ConjuntoButtonsForm;
