import React from "react";
import PrepararLDO from "./PrepararLDO/PrepararLDO";
import PrepararLOA from "./PrepararLOA/PrepararLOA";

export const Preparar = ({ option = null }) => {
  return (
    <>
      {option === "1.0" && <PrepararLDO />}
      {option === "1.1" && <PrepararLOA />}
    </>
  );
};

export default Preparar;
