import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfInfos } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { FormatValueToLocaleString } from "../../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { PdfHeader } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfRodape } from "../../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";

export const RenderPdfAnexoCompatibilidade = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: 200, flexShrink: 1, flexGrow: 1 },
    { flexBasis: "90%", flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: 150,
      flexShrink: 1,
      flexGrow: 1,
    },
    {
      flexBasis: 150,
      flexShrink: 1,
      flexGrow: 1,
    },
    {
      flexBasis: 300,
      flexShrink: 1,
      flexGrow: 1,
    },
  ];

  const renderTextColumn = (text, style = {}) => (
    <Text
      style={[
        stylesPdf.textTituloColuna,
        style,
        { textTransform: "uppercase", fontSize: 8 },
      ]}
    >
      {text}
    </Text>
  );
  const renderHeader = (styled = {}) => {
    return (
      <View style={[stylesPdf.colunas, { backgroundColor: "#515c70" }, styled]}>
        <View style={[stylesPdf.coluna, styledCol[0]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: 42
              },
            ]}
          >
            {renderTextColumn("código")}
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[1]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: 42
              },
            ]}
          >
            {renderTextColumn("descrição")}
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[2]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: 42
              },
            ]}
          >
            {renderTextColumn("ldo")}
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[3]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: 42
              },
            ]}
          >
            {renderTextColumn("ppa")}
          </View>
        </View>
        <View style={[stylesPdf.coluna, styledCol[4]]}>
          <View
            style={[
              stylesPdf.divTituloColuna,
              {
                textAlign: "center",
                paddingVertical: 2,
                height: 42
              },
            ]}
          >
            {renderTextColumn("diferença")}
            <View
              style={{
                height: 1,
                backgroundColor: "#FFF",
                width: "100%",
                marginVertical: 4,
              }}
            />
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 8,
              }}
            >
              {renderTextColumn("R$")}
              {renderTextColumn("%")}
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderInfos = (codigo, title, value, isSection = false) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            backgroundColor: isSection ? "#a6a6a6" : "transparent",
            fontWeight: isSection ? "bold" : "normal",
          },
        ]}
      >
        <PdfInfos
          pdfInfo={codigo}
          styledCol={[
            styledCol[0],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{ fontSize: 8, paddingHorizontal: 0, paddingLeft: 4 }}
        />
        <PdfInfos
          pdfInfo={title}
          styledCol={[
            styledCol[1],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{ fontSize: 8, paddingHorizontal: 0, paddingLeft: 4 }}
        />
        <PdfInfos
          pdfInfo={value}
          styledCol={[
            styledCol[2],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{
            fontSize: 8,
            paddingHorizontal: 0,
            textAlign: "center",
          }}
        />
        <PdfInfos
          pdfInfo={value}
          styledCol={[
            styledCol[3],
            {
              padding: "2px 0",
            },
          ]}
          styledText={{
            fontSize: 8,
            paddingHorizontal: 0,
            textAlign: "center",
          }}
        />
        <View
          style={[
            styledCol[4],
            { flexDirection: "row", justifyContent: "space-between" },
          ]}
        >
          <PdfInfos
            pdfInfo={value}
            styledCol={[
              {
                padding: "2px 0",
                width: "100%",
              },
            ]}
            styledText={{
              fontSize: 8,
              paddingHorizontal: 0,
              paddingLeft: 4,
              textAlign: "left",
            }}
          />
          <PdfInfos
            pdfInfo={value}
            styledCol={[
              {
                padding: "2px 0",
                width: "100%",
              },
            ]}
            styledText={{
              fontSize: 8,
              paddingHorizontal: 0,
              textAlign: "right",
              paddingRight: 4,
            }}
          />
        </View>
      </View>
    );
  };

  const renderTotal = (typeReceitaTotal, total = 10000) => {
    return (
      <>
        <View
          wrap={false}
          style={[
            stylesPdf.divInferiorColuna,
            {
              fontWeight: "bold",
              fontSize: 8,
              textTransform: "uppercase",
              color: "white",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 10,
              margin: 0,
              paddingHorizontal: 0,
              paddingLeft: 4,
            },
          ]}
        >
          <Text>{`${typeReceitaTotal}`}</Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                width: 64,
                textAlign: "center",
                paddingRight: 4,
              }}
            >
              {FormatValueToLocaleString(total)}
            </Text>
            <Text
              style={{
                width: 64,
                textAlign: "center",
                paddingRight: 4,
              }}
            >
              {FormatValueToLocaleString(total)}
            </Text>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: 130,
                paddingHorizontal: 4,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "left",
                }}
              >
                {FormatValueToLocaleString(total)}
              </Text>
              <Text
                style={{
                  width: "100%",
                  textAlign: "right",
                }}
              >
                {FormatValueToLocaleString(total)}
              </Text>
            </View>
          </View>
        </View>
      </>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Compatibilidade da LDO com o Plano Plurianual - PPA "}
        anoRelatorio={currentYear}
        plusText={"Anexo X"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {renderHeader("RECEITA")}
      </View>
      <View style={stylesPdf.divRelatorio}>
        {renderInfos("01.010", "Câmara Municipal", "25.000,00", true)}
        {renderInfos("01.010", "Legislativa  ", "25.000,00")}
        {renderInfos("01.010", "Ação Legislativa ", "25.000,00")}
        {renderInfos(
          "01.010",
          "Gestão com igualdade, equidade e justiça social para todos ",
          "25.000,00"
        )}
        {renderTotal("total")}
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
