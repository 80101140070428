export const ModulosDoSistema = [
  {
    sistema: "Gerenciador",
    sistemaAtivo: false,
    menus: [
      {
        menu: "Clientes",
        menuAtivo: false,
        acoes: [
          { acao: "Novo", acaoAtiva: false },
          { acao: "Editar", acaoAtiva: false },
          { acao: "Excluir", acaoAtiva: false },
        ],
      },
      {
        menu: "Usuários",
        menuAtivo: false,
        acoes: [
          { acao: "Novo", acaoAtiva: false },
          { acao: "Editar", acaoAtiva: false },
          { acao: "Excluir", acaoAtiva: false },
        ],
      },
      {
        menu: "Financeiro",
        menuAtivo: false,
        acoes: [
          { acao: "Novo", acaoAtiva: false },
          { acao: "Editar", acaoAtiva: false },
          { acao: "Excluir", acaoAtiva: false },
        ],
      },
      {
        menu: "Cadastros",
        menuAtivo: false,
        acoes: [
          { acao: "Novo", acaoAtiva: false },
          { acao: "Editar", acaoAtiva: false },
          { acao: "Excluir", acaoAtiva: false },
        ],
      },
      {
        menu: "SaveBot",
        menuAtivo: false,
        acoes: [],
      },
      {
        menu: "Importar",
        menuAtivo: false,
        acoes: [],
      },
      {
        menu: "Exportar",
        menuAtivo: false,
        acoes: [],
      },
      {
        menu: "Novos Usuários",
        menuAtivo: false,
        acoes: [],
      },
    ],
  },
  {
    sistema: "Planejamento",
    sistemaAtivo: false,
    menus: [
      {
        menu: "LOA",
        menuAtivo: false,
        acoes: [
          { acao: "Novo", acaoAtiva: false },
          { acao: "Editar", acaoAtiva: false },
          { acao: "Excluir", acaoAtiva: false },
        ],
      },
      {
        menu: "LDO",
        menuAtivo: false,
        acoes: [
          { acao: "Novo", acaoAtiva: false },
          { acao: "Editar", acaoAtiva: false },
          { acao: "Excluir", acaoAtiva: false },
        ],
      },
      {
        menu: "PPA",
        menuAtivo: false,
        acoes: [
          { acao: "Novo", acaoAtiva: false },
          { acao: "Editar", acaoAtiva: false },
          { acao: "Excluir", acaoAtiva: false },
        ],
      },
      {
        menu: "Cadastro",
        menuAtivo: false,
        acoes: [
          { acao: "Novo", acaoAtiva: false },
          { acao: "Editar", acaoAtiva: false },
          { acao: "Excluir", acaoAtiva: false },
        ],
      },
      {
        menu: "Início",
        menuAtivo: false,
        acoes: [],
      },
      {
        menu: "PCASP",
        menuAtivo: false,
        acoes: [],
      },
      {
        menu: "Relatórios",
        menuAtivo: false,
        acoes: [],
      },
      {
        menu: "Leis e Documentos",
        menuAtivo: false,
        acoes: [],
      },
      {
        menu: "Exportar",
        menuAtivo: false,
        acoes: [],
      },
      {
        menu: "Importar",
        menuAtivo: false,
        acoes: [],
      },
      {
        menu: "Novos Usuários",
        menuAtivo: false,
        acoes: [],
      },
    ],
  },
  {
    sistema: "Contabilidade",
    sistemaAtivo: false,
    menus: [],
  },
  {
    sistema: "Pagamentos",
    sistemaAtivo: false,
    menus: [],
  },
  {
    sistema: "Gestor",
    sistemaAtivo: false,
    menus: [],
  },
  {
    sistema: "Folha",
    sistemaAtivo: false,
    menus: [],
  },
];
