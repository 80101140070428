export const titulosEstendido = [
  // {
  //   titulo: "Cód. Reduzido",
  //   expandido: false,
  // },
  {
    titulo: "Conta",
    expandido: false,
  },
  {
    titulo: "Conta Corrente",
    expandido: false,
  },
  {
    titulo: "Título",
    expandido: true,
  },
  {
    titulo: "Saldo Anterior",
    expandido: false,
  },
  {
    titulo: "Débito",
    expandido: false,
  },
  {
    titulo: "Crédito",
    expandido: false,
  },
  {
    titulo: "Saldo Atual",
    expandido: false,
  },
  {
    titulo: "Natureza de Saldo",
    expandido: true,
  },
  {
    titulo: "Nível de Detalhamento",
    expandido: true,
  },
  {
    titulo: "Indicador de Superavit Financeiro",
    expandido: true,
  },
  {
    titulo: "IDLC",
    expandido: false,
  },
  {
    titulo: "Infos Complementares",
    expandido: true,
  },
  {
    titulo: "Status",
    expandido: false,
  },
];

export const titulosMinimizado = [
  {
    titulo: "Conta",
    expandido: false,
  },
  {
    titulo: "Título",
    expandido: true,
  },
  {
    titulo: "Saldo Anterior",
    expandido: false,
  },
  {
    titulo: "Débito",
    expandido: false,
  },
  {
    titulo: "Crédito",
    expandido: false,
  },
  {
    titulo: "Saldo Atual",
    expandido: false,
  },
];

export const chavesDoObjetoExpandido = [
  // {
  //   titulo: "codReduzido",
  //   expandido: false,
  // },
  {
    titulo: "conta",
    expandido: false,
  },
  {
    titulo: "contaCorrente",
    expandido: false,
  },
  {
    titulo: "titulo",
    expandido: true,
  },
  {
    titulo: "saldoAnterior",
    expandido: false,
  },
  {
    titulo: "debitoDaContaPcasp",
    expandido: false,
  },
  {
    titulo: "creditoDaContaPcasp",
    expandido: false,
  },
  {
    titulo: "saldoDaContaPcasp",
    expandido: false,
  },
  {
    titulo: "naturezaDeSaldo",
    expandido: true,
  },
  {
    titulo: "nivelDetalhado",
    expandido: true,
  },
  {
    titulo: "indicadorDoSuperavitFinanceiro",
    expandido: true,
  },
  {
    titulo: "idIc",
    expandido: false,
  },
  {
    titulo: "infoComplementar",
    expandido: true,
  },
  {
    titulo: "status",
    expandido: false,
  },
];

export const chavesDoObjetoMinimizado = [
  {
    titulo: "conta",
    expandido: false,
  },
  {
    titulo: "titulo",
    expandido: true,
  },
  {
    titulo: "saldoAnterior",
    expandido: false,
  },
  {
    titulo: "debitoDaContaPcasp",
    expandido: false,
  },
  {
    titulo: "creditoDaContaPcasp",
    expandido: false,
  },
  {
    titulo: "saldoDaContaPcasp",
    expandido: false,
  },
];
