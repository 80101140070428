
export const getAnexoCompatibilidade = async (
  clienteId,
  entidadesGestorasDoCliente,
  ano = 2024
) => {
  // try {
  //   const response = await api.get(`riscosFiscais/relatorio/${clienteId}`);
  //   return processAllData(response.data);
  // } catch (error) {
  //   console.error("Erro na requisição:", error);
  //   return {
  //     error: "error",
  //     name: "Demonstrativo 1 - Metas Anuais - Anexo Metas Fiscais",
  //   };
  // }
  return ['']
};
