import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { Button, Row } from "react-bootstrap";
import { CCol, RowAninhada } from "../../../components/Grid/CCol";
import { Icon } from "@iconify/react/dist/iconify.js";
import styled from "styled-components";
import RegistroDePrazos from "./TarefasPrazos/RegistroDePrazos";
import ProgressCard from "./TarefasPrazos/ProgressCard";
import DailyCard from "./TarefasPrazos/DailyCard";
import ProgressBarWithMenu from "./TarefasPrazos/ProgressBarWithMenu";
import ModalAutoComplete from "../../../components/Modais/ModalAutoComplete";
import { OpenedModalsContext } from "../../../contexts/OpenedModalsContext";
import { ToastContainer } from "react-toastify";
import { useCurrentYear } from "../../../hooks/useCurrentYear";

const TarefasPrazos = () => {
  const { currentYear } = useCurrentYear();
  const { openedModals, setOpenedModals } = useContext(OpenedModalsContext);
  const dailyCardsRef = useRef(null);
  const currentDate = useMemo(() => new Date(), []);
  const [percentage1, setPercentage1] = useState(0);
  const [percentage2, setPercentage2] = useState(0);
  const [data, setData] = useState();
  const [month, setMonth] = useState();
  const [progressData, setProgressData] = useState([
    { number: 1, month: "Janeiro", percentage: 100 },
    { number: 2, month: "Fevereiro", percentage: 100 },
    { number: 3, month: "Março", percentage: 100 },
    { number: 4, month: "Abril", percentage: 100 },
    { number: 5, month: "Maio", percentage: 100 },
    { number: 6, month: "Junho", percentage: 100 },
    { number: 7, month: "Julho", percentage: 100 },
    { number: 8, month: "Agosto", percentage: 100 },
    { number: 9, month: "Setembro", percentage: 91 },
    { number: 10, month: "Outubro", percentage: 48 },
    { number: 11, month: "Novembro", percentage: 0 },
    { number: 12, month: "Dezembro", percentage: 0 },
  ]);
  const [buttonOption, setButtonOption] = useState("Diária");
  const [conciliacaoBancaria, setConciliacaoBancaria] = useState([
    {
      conta: "18.148-X",
      titulo: "FPM - Fundo de Participação dos Municípios",
      contabil: "2852,00",
      conciliacao: "2852,00",
      financeiro: "2852,00",
      diferenca: "365850,00",
    },
  ]);
  /*
  const [atualizacaoOrcamentaria, setAtualizacaoOrcamentaria] = useState([
    {
      tipo: "Decreto",
      documento: "0001/2022",
      mes: "Janeiro",
      credito: "Suplementação",
      atualizacao: "2852000,00",
      anulacao: "2852000,00",
      diferenca: "365850,00",
    },
  ]);
  */

  const content1 = [
    {
      title: "Conta",
      md: 1,
      leftPadding: "",
      rightPadding: "0",
    },
    {
      title: "Título",
      md: 3,
      leftPadding: "0",
      rightPadding: "",
    },
    {
      title: "Contábil",
      md: 2,
      leftPadding: "",
      rightPadding: "",
    },
    {
      title: "Conciliação",
      md: 2,
      leftPadding: "",
      rightPadding: "",
    },
    {
      title: "Financeiro",
      md: 2,
      leftPadding: "",
      rightPadding: "",
    },
    {
      title: "Diferença",
      md: 2,
      leftPadding: "",
      rightPadding: "",
    },
  ];

  const changeData = (number) => {
    if (buttonOption[0] === "D") {
      setData(data + number);
    } else {
      if (month + number === 12) {
        setMonth(0);
        setData(data + number);
      } else if (month + number === -1) {
        setMonth(11);
        setData(data + number);
      } else {
        setMonth(month + number);
      }
    }
  };

  const scrollLeft = () => {
    if (dailyCardsRef.current) {
      dailyCardsRef.current.scrollTo({
        left: dailyCardsRef.current.scrollLeft - 800,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (dailyCardsRef.current) {
      dailyCardsRef.current.scrollTo({
        left: dailyCardsRef.current.scrollLeft + 800,
        behavior: "smooth",
      });
    }
  };

  const randomizeProgressData = () => {
    const newProgressData = [
      {
        number: 1,
        month: "Janeiro",
        percentage: Math.floor(Math.random() * 101),
      },
      {
        number: 2,
        month: "Fevereiro",
        percentage: Math.floor(Math.random() * 101),
      },
      {
        number: 3,
        month: "Março",
        percentage: Math.floor(Math.random() * 101),
      },
      {
        number: 4,
        month: "Abril",
        percentage: Math.floor(Math.random() * 101),
      },
      {
        number: 5,
        month: "Maio",
        percentage: Math.floor(Math.random() * 101),
      },
      {
        number: 6,
        month: "Junho",
        percentage: Math.floor(Math.random() * 101),
      },
      {
        number: 7,
        month: "Julho",
        percentage: Math.floor(Math.random() * 101),
      },
      {
        number: 8,
        month: "Agosto",
        percentage: Math.floor(Math.random() * 101),
      },
      {
        number: 9,
        month: "Setembro",
        percentage: Math.floor(Math.random() * 101),
      },
      {
        number: 10,
        month: "Outubro",
        percentage: Math.floor(Math.random() * 101),
      },
      {
        number: 11,
        month: "Novembro",
        percentage: Math.floor(Math.random() * 101),
      },
      {
        number: 12,
        month: "Dezembro",
        percentage: Math.floor(Math.random() * 101),
      },
    ];

    setProgressData(newProgressData);
  };

  const handleOpenNewModal = (titulo) => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: titulo, requisicaoDoModalConcluida: false },
    ]);
  };

  useEffect(() => {
    setData(currentDate.getFullYear() - 1);
    setMonth(currentDate.getMonth());
  }, [currentDate]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.altKey &&
        openedModals.length === 0 &&
        (event.key === "n" || event.key === "N")
      ) {
        handleOpenNewModal("Tarefas e Prazos");
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line
  }, [openedModals.length]);

  const buttonClick = () => {
    if (buttonOption[0] === "D") {
      setButtonOption("Mensal");
    } else {
      setButtonOption("Diária");
    }
  };

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber);
    return date.toLocaleString("pt-BR", { month: "long" });
  }

  return (
    <>
      <ModalAutoComplete
        tituloModal={"Tarefas e Prazos"}
        Component={<RegistroDePrazos isAModalPage={true} />}
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        <CCol xs={"auto"}>
          <Button
            style={{
              backgroundColor: "#ffffff",
              color: "#515C70",
              borderColor: "#d4d4d4",
              width: "80px",
            }}
            onClick={() => buttonClick()}
          >
            {buttonOption}
          </Button>
        </CCol>
        <CCol
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon
            width={25}
            icon="material-symbols:arrow-back-ios-rounded"
            onClick={() => {
              changeData(-1);
              randomizeProgressData();
            }}
            cursor={"pointer"}
          />
          <div
            style={{
              margin: "0 10px",
              fontWeight: "600",
              fontSize: "20px",
              width: "180px",
              textAlign: "center",
            }}
          >
            {buttonOption[0] === "M" && (
              <>
                {getMonthName(month)}
                {" - "}
              </>
            )}
            {data}
          </div>
          <Icon
            width={25}
            icon="material-symbols:arrow-back-ios-rounded"
            rotate="180deg"
            onClick={() => {
              changeData(1);
              randomizeProgressData();
            }}
            cursor={"pointer"}
          />
        </CCol>
        <CCol xs={"auto"}>
          <Button
            variant="success"
            onClick={() => handleOpenNewModal("Tarefas e Prazos")}
            style={{ marginRight: "10px" }}
          >
            Novo
          </Button>
        </CCol>
      </Row>
      {data < currentDate.getFullYear() ? (
        <Row className="row_form mt-1">
          {buttonOption === "Diária" ? (
            <>
              {progressData.map((item, index) => (
                <CCol
                  md={3}
                  key={item.number}
                  style={{ marginTop: index > 3 ? "15px" : "0" }}
                >
                  <ProgressCard
                    number={item.number}
                    month={item.month}
                    percentage={item.percentage}
                    onClick={() => {
                      setMonth(item.number - 1);
                      buttonClick();
                      console.log(
                        "mês: ",
                        month,
                        "ano: ",
                        data,
                        "dias: ",
                        new Date(data, month + 1, 0).getDate()
                      );
                    }}
                  />
                </CCol>
              ))}
            </>
          ) : (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Icon
                  width={40}
                  icon="material-symbols:arrow-back-ios-rounded"
                  cursor={"pointer"}
                  onClick={() => scrollLeft()}
                />
                <div
                  ref={dailyCardsRef}
                  style={{
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    display: "flex",
                    width: "fit-content",
                  }}
                >
                  {Array.from(
                    { length: new Date(data, month + 1, 0).getDate() },
                    (_, index) => (
                      <CCol key={index}>
                        <DailyCard percentage={100} day={index + 1} />
                      </CCol>
                    )
                  )}
                </div>
                <Icon
                  width={40}
                  icon="material-symbols:arrow-back-ios-rounded"
                  rotate="180deg"
                  cursor={"pointer"}
                  onClick={() => scrollRight()}
                />
              </div>
              <RowAninhada
                className="mt-2"
                style={{ backgroundColor: "#F2F3F8", borderRadius: "10px" }}
              >
                <CCol style={{ padding: "10px" }}>
                  <span
                    style={{
                      fontWeight: "500",
                      margin: "20px 0 0 10px",
                    }}
                  >
                    Diagnóstico
                  </span>
                </CCol>
                <hr style={{ borderWidth: "3px", borderColor: "#D9D9D9" }} />
                <Row>
                  <CCol style={{ margin: "0 10px" }}>
                    <ProgressBarWithMenu
                      title={"LDO - Lei de Diretrizes Orçamentária"}
                      color={1}
                      type={"ldo"}
                      paddingLeft={"350px"}
                      percentage={percentage1}
                      setPercentage={setPercentage1}
                    />
                  </CCol>
                </Row>
                <Row>
                  <CCol style={{ margin: "0 10px" }}>
                    <ProgressBarWithMenu
                      title={"LDO - Lei de Diretrizes Orçamentária"}
                      color={1}
                      type={"ldo"}
                      paddingLeft={"350px"}
                      percentage={percentage2}
                      setPercentage={setPercentage2}
                      disabled={percentage1 < 100}
                    />
                  </CCol>
                </Row>
                <Row>
                  <CCol style={{ margin: "0 10px" }}>
                    <ProgressBarWithMenu
                      title={"Conciliação Bancária"}
                      color={1}
                      titlesAndFields={content1}
                      inputValues={conciliacaoBancaria}
                      setInputValues={setConciliacaoBancaria}
                      paddingLeft={"250px"}
                      percentage={0}
                      setPercentage={setPercentage2}
                      disabled={percentage2 < 100}
                    />
                  </CCol>
                </Row>
              </RowAninhada>
            </>
          )}
        </Row>
      ) : (
        data === parseInt(currentYear) && (
          <Row className="row_form mt-1">
            <CCol>
              <WarningAlert text={"Prazo final da LDO"} data={"15 de abr"} />
            </CCol>
          </Row>
        )
      )}
      <div>{"\u00A0"}</div>
    </>
  );
};

export default TarefasPrazos;

const Warning = styled.div`
  display: flex;
  height: 25px;
  width: 100%;
  background-color: #e79900;
  border-radius: 25px;
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const WarningAlert = ({ text, data }) => {
  return (
    <>
      <Warning>
        <div>{text}</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/default-avatar.png"
            alt="pfp"
            width={20}
            height={20}
            style={{ margin: "0 5px" }}
          />
          <img
            src="https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/default-avatar.png"
            alt="pfp"
            width={20}
            height={20}
            style={{ margin: "0 5px" }}
          />
          <Icon
            icon="mdi:clock-outline"
            color="#ffffff"
            width={22}
            height={22}
            style={{ margin: "0 10px" }}
          />
          <span>{data}</span>
          <input type="checkbox" style={{ marginLeft: "20px" }} />
        </div>
      </Warning>
    </>
  );
};
