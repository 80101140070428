import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./style.css";
import { Icon } from "@iconify/react";
import {
  DivSist,
  DivTitulo,
  Titulo,
  SideBarDireita,
  LinkModulo,
  DivSelectListArrangement,
  DivSistGrid,
  SystemContainer,
} from "./StylesSideBarSist";
import { FluentGrid24Filled, TypcnThList } from "./SVGIcons";
import { SplitArrayIntoChunks } from "../../utils/Gerais/SplitArrayIntoChunks";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { CurrentClientContext } from "../../contexts/CurrentClientContext";
import { userTypes } from "../../pages/PagesAcessoInterno/Cadastros/CadastroMaster/utils/permissoes";

function SideBarSistemas({ openSideSistemas, setOpenSideSistemas }) {
  const ref = useRef();
  const [listArrangement, setListArrangement] = useState("list"); //list or grid
  const { currentClient } = useContext(CurrentClientContext);
  const { permissoesDoUsuario } = useContext(AuthContext);
  const { userAuthData } = useContext(AuthContext);

  const verifyUserType = useCallback(
    (permissoesDoClienteAtual) => {
      if (userAuthData.cnpj) {
        const userTypeId = userAuthData.cnpj[0].tipoUsuarioId;
        return userTypes
          .find((item) => item.value === userTypeId)
          .label.toLowerCase();
      } else {
        return permissoesDoClienteAtual?.tipoDeUsuario?.toLowerCase();
      }
    },
    [userAuthData]
  );

  const checkSystemPermission = useCallback(
    (systemName) => {
      if (Object.keys(currentClient).length > 0) {
        const currentClientId = currentClient.clienteId;
        const currentClientInfos = permissoesDoUsuario.find(
          (permissao) => permissao.clienteId === currentClientId
        );
        const currentClientPermissions = currentClientInfos?.permissoes;
        const currentUserType = verifyUserType(currentClientPermissions);

        if (currentUserType === "master") {
          return true;
        }

        return currentClientPermissions?.some(
          (permissao) =>
            permissao.sistema.toLowerCase() === systemName.toLowerCase()
        );
      }
    },
    [currentClient, permissoesDoUsuario, verifyUserType]
  );

  const systems = useMemo(
    () => [
      {
        name: "Gerenciamento",
        backgroundColor: "#515c70",
        ativo: checkSystemPermission("gerenciador"),
        path: "/home-test",
        icon: "fa-solid:user-cog",
      },
      {
        name: "Planejamento",
        backgroundColor: "#F6991E",
        ativo: checkSystemPermission("planejamento"),
        path: "/planejamento/inicio",
        icon: "streamline:interface-content-book-edit-pencil-content-write-notebook-book-edit-composition-creation",
      },
      {
        name: "Contabilidade",
        backgroundColor: "#da499a",
        ativo: checkSystemPermission("contabilidade"),
        path: "/contabilidade/receita/guia-de-receita",
        icon: "carbon:calculation-alt",
      },
      {
        name: "Pagamentos",
        backgroundColor: "#89C454",
        ativo: checkSystemPermission("pagamentos"),
        path: "/planejamento/inicio",
        icon: "map:bank",
      },
      {
        name: "Gestor",
        backgroundColor: "#2f817b",
        ativo: checkSystemPermission("gestor"),
        path: "/planejamento/inicio",
        icon: "grommet-icons:map",
      },
      {
        name: "Folha",
        backgroundColor: "#586dba",
        ativo: checkSystemPermission("folha"),
        path: "/planejamento/inicio",
        icon: "raphael:people",
      },
      {
        name: "Transparência",
        backgroundColor: "#74BEE9",
        ativo: checkSystemPermission("transparência"),
        path: "/planejamento/inicio",
        icon: "mdi:world",
      },
      {
        name: "Estoque",
        backgroundColor: "#C0956A",
        ativo: checkSystemPermission("estoque"),
        path: "/planejamento/inicio",
        icon: "teenyicons:box-solid",
      },
      {
        name: "Patrimônio",
        backgroundColor: "#CF5002",
        ativo: checkSystemPermission("patrimônio"),
        path: "/planejamento/inicio",
        icon: "mdi:home-city",
      },
      {
        name: "Convênio",
        backgroundColor: "#2C786F",
        ativo: checkSystemPermission("convênio"),
        path: "/planejamento/inicio",
        icon: "fluent:document-folder-20-filled",
      },
      {
        name: "Tributos",
        backgroundColor: "#8A6D7D",
        ativo: checkSystemPermission("tributos"),
        path: "/planejamento/inicio",
        icon: "uiw:area-chart",
      },
    ],
    [checkSystemPermission]
  );

  const handleClickOutOfSideBar = useCallback(
    (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        openSideSistemas
      ) {
        console.log("opa");
        setOpenSideSistemas(false);
      }
    },
    [setOpenSideSistemas, openSideSistemas]
  );

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutOfSideBar);

    return () =>
      window.removeEventListener("mousedown", handleClickOutOfSideBar);
  }, [handleClickOutOfSideBar]);

  return (
    <SideBarDireita
      ref={ref}
      openSideSistemas={!openSideSistemas}
      className="sidebar-container"
    >
      <DivTitulo>
        <Titulo>Sistemas</Titulo>
        <DivSelectListArrangement>
          {/* <button onClick={() => console.log(systems)}>console</button> */}
          <button onClick={() => setListArrangement("list")}>
            <TypcnThList width={24} $active={listArrangement === "list"} />
          </button>

          <button onClick={() => setListArrangement("grid")}>
            <FluentGrid24Filled
              $active={listArrangement === "grid"}
              width={24}
            />
          </button>
        </DivSelectListArrangement>
      </DivTitulo>

      {listArrangement === "list" && (
        <DivSist>
          {systems
            .filter((system) => system.ativo)
            .map((item) => (
              <LinkModulo
                to={item.path}
                $backgroundColor={item.backgroundColor}
              >
                <Icon icon={item.icon} width="24" />
                <span>{item.name}</span>
              </LinkModulo>
            ))}
        </DivSist>
      )}
      {listArrangement === "grid" && (
        <DivSistGrid>
          {SplitArrayIntoChunks(
            systems.filter((system) => system.ativo),
            2
          ).map((pair, pairIndex) => (
            <div key={pairIndex}>
              {pair.map((item, itemIndex) => (
                <Link key={itemIndex} to={item.path}>
                  <SystemContainer $backgroundColor={item.backgroundColor}>
                    <div>
                      <Icon icon={item.icon} />
                    </div>
                    <p>{item.name}</p>
                  </SystemContainer>
                </Link>
              ))}
            </div>
          ))}
        </DivSistGrid>
      )}
    </SideBarDireita>
  );
}

export default SideBarSistemas;
