import { Page, View, Document, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import React from "react";

const renderPageContent = (dados, styledCol, opcao, currentYear) => {
  return (
    <>
      <View style={stylesPdf.divRelatorio}>
        <View>
          {dados.map((item, index) => (
            <React.Fragment key={item.ano || index}>
              <View style={stylesPdf.colunas}>
                {opcao?.checkBoxes[0].selected && (
                  <PdfInfos
                    pdfInfo={item.titulo ? item.titulo : ""}
                    styledCol={styledCol[0]}
                  />
                )}

                {Array.from({ length: 6 }, (_, i) => {
                  const year = currentYear - 3 + i;
                  const checkboxIndex = i + 1;
                  const selected = opcao?.checkBoxes[checkboxIndex]?.selected;
                  const pdfInfo = item[`ano${year}`] ? item[`ano${year}`] : "";

                  return (
                    selected && (
                      <PdfInfos
                        key={`ano${year}`}
                        pdfInfo={pdfInfo}
                        styledCol={styledCol[checkboxIndex]}
                      />
                    )
                  );
                })}
              </View>
              <View
                style={{
                  height: "1px",
                  width: "100%",
                  backgroundColor: "#a6a6a6",
                }}
              ></View>
            </React.Fragment>
          ))}
        </View>
      </View>
    </>
  );
};

export const PdfParametroIndice = ({ pdfContent }) => {
  const {
    dadosRelatorio,
    opcoes,
    relatorioName,
    userData: { currentClient, userAuthData, dataClientInfos },
    dateRelatorio: { year },
  } = pdfContent;

  const colNames = [
    "Titulo",
    parseInt(year - 3),
    parseInt(year - 2),
    parseInt(year - 1),
    year,
    parseInt(year + 1),
    parseInt(year + 2),
  ];
  const styledCol = [
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "250px", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "250px", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "250px", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "250px", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "250px", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "250px", flexShrink: 1, flexGrow: 1 },
  ];

  if (!dadosRelatorio) return null;

  const parametros = dadosRelatorio?.parametros || [];
  const indices = dadosRelatorio?.indices || [];
  const parametrosExists = parametros.length !== 0;
  const indicesExists = indices.length !== 0;

  return (
    <Document title={relatorioName}>
      <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
        <PdfHeader
          currentClient={currentClient}
          nameRelatorio={"Parametros/Indices"}
          anoRelatorio={year}
          dataClientInfos={dataClientInfos}
        />
        <View style={stylesPdf.divRelatorio}>
          <View style={[stylesPdf.colunas, { marginTop: "8px" }]} fixed>
            {opcoes[0]?.checkBoxes?.map(
              (each, index) =>
                each.selected && (
                  <PdfColunas
                    key={index}
                    colName={colNames[index]}
                    styledCol={styledCol[index]}
                    hideBar={true}
                  />
                )
            )}
          </View>
          {parametrosExists && (
            <>
              <View style={[stylesPdf.subDivColunas, { margin: "0 -3px" }]}>
                <Text style={stylesPdf.textBar}>{"Parâmetros"}</Text>
              </View>
              {renderPageContent(parametros, styledCol, opcoes[0], year)}
            </>
          )}
          {indicesExists && (
            <>
              <View style={[stylesPdf.subDivColunas, { margin: "0 -3px" }]}>
                <Text style={stylesPdf.textBar}>{"Indices"}</Text>
              </View>
              {renderPageContent(indices, styledCol, opcoes[1], year)}
            </>
          )}
          <View style={stylesPdf.divInferiorColuna}></View>
        </View>
        <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
      </Page>
    </Document>
  );
};
