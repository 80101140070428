import React, { useContext, useEffect, useState } from "react";
import {
  CCol,
  ContainerToModal,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Row } from "react-bootstrap";
import Participantes from "./Participantes";
import Contratos from "./Contratos";
import { InputDateRelatorio } from "../../../../components/Inputs/InputDateRelatorio";
import { OptionsStyle } from "../../../Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/NaturezaDaReceita";
import Aditivos from "./Aditivos";
import { tiposDeObjeto } from "./menus";
import { modalidades } from "./menus";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import { ToastContainer } from "react-toastify";
import api from "../../../../utils/api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const Licitacao = () => {
  const { numero: numeroLicitacao } = useParams();
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useContext(CurrentYearContext);

  const [abaSelecionada, setAbaSelecionada] = useState(0);
  const [values, setValues] = useState({
    clienteId: currentClient.clienteId,
    numero: "",
    ano: currentYear,
    anoLicitacao: "",
    modalidade: null,
    objeto: "",
    valor: "",
    homologacao: "",
    tipoObjeto: "",
    participantes: [],
    contratos: [],
    aditivos: [],
  });

  const addParticipante = (novoParticipante) => {
    setValues((prevValues) => ({
      ...prevValues,
      participantes: [...prevValues.participantes, novoParticipante],
    }));
  };
  const addContratos = (novoContrato) => {
    setValues((prevValues) => ({
      ...prevValues,
      contratos: [...prevValues.contratos, novoContrato],
    }));
  };
  const addAditivos = (novoAditivo) => {
    setValues((prevValues) => ({
      ...prevValues,
      aditivos: [...prevValues.aditivos, novoAditivo],
    }));
  };

  const fetchData = async () => {
    const formatDateToDDMMYYYY = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    try {
      const resp = await api.get(`/licitacaoContabilidade/${numeroLicitacao}`);
      const data = resp.data;

      // Formatar as datas
      const formattedData = {
        ...data,
        homologacao: formatDateToDDMMYYYY(data.homologacao),
        participantes: data.participantes.map((part) => ({
          ...part,
          data_criacao: formatDateToDDMMYYYY(part.data_criacao),
          data_atualizacao: formatDateToDDMMYYYY(part.data_atualizacao),
        })),
        contratos: data.contratos.map((contract) => ({
          ...contract,
          dataAssinatura: formatDateToDDMMYYYY(contract.dataAssinatura),
          dataVigencia: formatDateToDDMMYYYY(contract.dataVigencia),
          data_criacao: formatDateToDDMMYYYY(contract.data_criacao),
          data_atualizacao: formatDateToDDMMYYYY(contract.data_atualizacao),
        })),
        aditivos: data.aditivos.map((aditivo) => ({
          ...aditivo,
          dataAssinatura: formatDateToDDMMYYYY(aditivo.dataAssinatura),
          dataVigencia: formatDateToDDMMYYYY(aditivo.dataVigencia),
          data_criacao: formatDateToDDMMYYYY(aditivo.data_criacao),
          data_atualizacao: formatDateToDDMMYYYY(aditivo.data_atualizacao),
        })),
      };

      setValues(formattedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (numeroLicitacao) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [numeroLicitacao]);

  const postData = async () => {
    const valuesToPost = values;
    console.log(valuesToPost);
    try {
      await api.post("licitacaoContabilidade", valuesToPost);
      toast.success("Licitação cadastrada com sucesso.");
      setValues({
        clienteId: currentClient.clienteId,
        ano: currentYear,
        numero: "",
        anoLicitacao: "",
        modalidade: null,
        objeto: "",
        valor: "",
        homologacao: "",
        tipoObjeto: "",
        participantes: [],
        contratos: [],
        aditivos: [],
      });
    } catch (error) {
      toast.error(error.response.data.error || "Erro ao cadastrar a licitação");
      console.log(error);
    }
  };

  const putData = async () => {
    const valuesToPost = values;
    console.log(valuesToPost);
    try {
      await api.put(`licitacaoContabilidade/${numeroLicitacao}`, valuesToPost);
      toast.success("Licitação atualizada com sucesso.");
    } catch (error) {
      toast.error("Erro ao cadastrar a licitação");
      console.log(error);
    }
  };

  const saveData = () => {
    if (numeroLicitacao) {
      putData();
    } else {
      postData();
    }
  };

  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <ToastContainer />
      <HeaderCadastros
        PaginaConsulta={"/contabilidade/cadastros/licitacao/consulta"}
        NomePaginaTitulo={"Licitação"}
        PaginaSubtitulo={"Cadastro"}
        ButtonSaveFunction={saveData}
        BotaoNovoAtivo={false}
      />
      <Row className="row_form mt-1">
        <RowAninhada>
          <CCol md={2}>
            <FloatingLabelInput
              disabled={numeroLicitacao}
              label={"Número"}
              placeholder={"Número"}
              value={values.numero}
              onChange={(e) => {
                setValues((prevValues) => ({
                  ...prevValues,
                  numero: e.target.value,
                }));
              }}
            />
          </CCol>
          <CCol md={1} style={{ width: "8em" }}>
            <InputDateRelatorio
              label={"Ano"}
              placeholder={"Ano"}
              value={values.anoLicitacao}
              onChange={(e) => {
                const input = e.target.value;
                if (input.length <= 4) {
                  setValues((prevValues) => ({
                    ...prevValues,
                    anoLicitacao: e.target.value,
                  }));
                }
              }}
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              tipo="input_select"
              label="Modalidade"
              placeholder="Modalidade"
              value={values.modalidade}
              options={[{ label: "Modalidade", value: null }, ...modalidades]}
              onSelect={(option) => {
                setValues((prevValues) => ({
                  ...prevValues,
                  modalidade: option.value,
                }));
              }}
            />
          </CCol>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "15px" }}>
          <CCol>
            <FloatingLabelInput
              tipo="text_area"
              as="textarea"
              label="Objeto"
              placeholder="Objeto"
              style={{ minHeight: "98px" }}
              value={values.objeto}
              onChange={(e) => {
                setValues((prevValues) => ({
                  ...prevValues,
                  objeto: e.target.value,
                }));
              }}
            />
          </CCol>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "10px" }}>
          <CCol md={1} style={{ width: "16em" }}>
            <FloatingLabelInput
              label={"R$"}
              placeholder={"R$"}
              value={values.valor}
              onChange={(e) => {
                let input = e.target.value;
                input = input.replace(/[^0-9,]/g, "");
                if (input.includes(",")) {
                  const [inteiro, centavos] = input.split(",");
                  input = `${inteiro},${centavos.slice(0, 2)}`;
                }
                const partes = input.split(",");
                let numero = partes[0];
                numero = numero.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                input = partes.length > 1 ? `${numero},${partes[1]}` : numero;
                setValues((prevValues) => ({
                  ...prevValues,
                  valor: input,
                }));
              }}
            />
          </CCol>
          <CCol md={2}>
            <InputDateRelatorio
              label={"Homologação"}
              placeholder={"Homologação"}
              value={values.homologacao}
              onChange={(e) =>
                setValues((prevValues) => {
                  let input = e.target.value.replace(/\D/g, "");
                  if (input.length > 8) input = input.slice(0, 8);
                  if (input.length > 4) {
                    input = input.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
                  } else if (input.length > 2) {
                    input = input.replace(/(\d{2})(\d{2})/, "$1/$2");
                  }

                  return {
                    ...prevValues,
                    homologacao: input,
                  };
                })
              }
            />
          </CCol>
          <CCol>
            <FloatingLabelInput
              new={true}
              holdSelect
              style={{
                color: "#515c70",
              }}
              tipo="input_select"
              placeholder="Tipo do Objeto"
              value={values.tipoObjeto}
              options={[
                { label: "Tipo do Objeto", value: null },
                ...tiposDeObjeto,
              ]}
              onSelect={(option) => {
                setValues((prevValues) => ({
                  ...prevValues,
                  tipoObjeto: option.value,
                }));
              }}
            />
          </CCol>
        </RowAninhada>
      </Row>
      <Row
        style={{ backgroundColor: "#fff", borderRadius: "0px 0px 5px 5px" }}
        className={"mt-1"}
      >
        <CCol style={{ display: "flex", alignItems: "center", padding: "0px" }}>
          <OptionsStyle
            selecionado={abaSelecionada === 0}
            onClick={() => setAbaSelecionada(0)}
          >
            Propostas, Contratos e Adivitos
          </OptionsStyle>
          {/* <OptionsStyle
            selecionado={abaSelecionada === 1}
            onClick={() => setAbaSelecionada(1)}
          >
            Fonte de Recursos
          </OptionsStyle> */}
        </CCol>
      </Row>
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Participantes
          </span>
        </CCol>
      </Row>
      <Participantes
        addParticipante={addParticipante}
        values={values.participantes}
      />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Contratos
          </span>
        </CCol>
      </Row>
      <Contratos addContratos={addContratos} values={values.contratos} />
      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Aditivos
          </span>
        </CCol>
      </Row>
      <Aditivos addAditivos={addAditivos} values={values.aditivos} />
      <div style={{ minHeight: "40px" }}> </div>
    </ContainerToModal>
  );
};

export default Licitacao;
