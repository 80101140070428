import * as React from "react";
const SaveLogoMinimalized = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={57.029}
    height={57.029}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
      transform: "scale(1.8)",
    }}
    viewBox="0 0 355.95 355.95"
    {...props}
  >
    <path
      d="M66.21 170.72H0V48.89C0 21.88 21.88 0 48.87 0h121.85v64.98h-53.8c-27.89 0-50.71 22.82-50.71 50.71v55.03z"
      style={{
        fill: "#e3874f",
      }}
    />
    <path
      d="M170.72 318.86v36.92H48.87C21.88 355.78 0 333.9 0 306.91V185.24H66.21v52.85c0 27.89 22.82 50.71 50.71 50.71h53.8v30.07z"
      style={{
        fill: "#dd529c",
      }}
    />
    <path
      d="M307.07 355.95H185.24v-67.17h54.07c27.89 0 50.7-22.82 50.7-50.71v-52.85h65.94v121.83c0 27.01-21.88 48.89-48.89 48.89z"
      style={{
        fill: "#8ec65c",
      }}
    />
    <path
      d="M318.68 170.72h-28.67v-55.03c0-27.89-22.82-50.71-50.7-50.71h-54.07V0h121.83c27.01 0 48.89 21.88 48.89 48.89v121.83h-37.27z"
      style={{
        fill: "#2f817b",
      }}
    />
    <path
      d="M180.03 268.27c-16.62 0-31.51-2.13-44.68-6.37-13.17-4.25-23.69-10.54-31.57-18.87-7.88-8.33-12.03-18.36-12.47-30.09h58.93c.87 6.63 3.78 11.69 8.74 15.17 4.96 3.48 11.44 5.23 19.42 5.23 8.2 0 14.68-1.49 19.43-4.46 4.75-2.97 7.12-7.1 7.12-12.37 0-4.42-1.89-8.07-5.67-10.96-3.78-2.89-8.42-5.27-13.92-7.14s-13.33-4-23.47-6.37c-14.68-3.57-26.66-7.14-35.94-10.71-9.28-3.57-17.27-8.84-23.96-15.81-6.69-6.97-10.04-16.06-10.04-27.28 0-16.66 7.66-29.71 22.99-39.14 15.33-9.43 35.29-14.15 59.9-14.15 25.04 0 45.22 4.72 60.55 14.15 15.32 9.43 23.53 22.57 24.61 39.4h-59.9c-.43-5.78-3.13-10.33-8.09-13.64-4.97-3.31-11.33-4.97-19.1-4.97-6.69 0-12.09 1.4-16.19 4.21-4.1 2.8-6.15 6.84-6.15 12.11 0 5.78 3.45 10.29 10.36 13.51 6.91 3.23 17.7 6.71 32.38 10.45 14.68 3.91 26.61 7.65 35.78 11.22 9.17 3.57 17.11 8.75 23.8 15.56 6.69 6.8 10.04 15.55 10.04 26.26 0 10.2-3.29 19.46-9.87 27.79-6.58 8.33-16.14 14.96-28.66 19.89s-27.31 7.4-44.36 7.4z"
      style={{
        fill: "#515c70",
        fillRule: "nonzero",
      }}
    />
  </svg>
);
export default SaveLogoMinimalized;
