import React from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import styled from "styled-components";
import InputSearch from "../../../../components/Inputs/InputSearch";

const PTitulosSaldos = styled.p`
  margin: 0;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #515c70;
`;

export const EfdReinfAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>EFD-Reinf</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1">
        <CCol md={2}>
          <InputData />
        </CCol>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Natureza dos Rendimentos"
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Classificação do Serviço"
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }} $borderRadiusType={2}>
        <CCol>
          <PTitulosSaldos>Base de Cálculo</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>Alíquota do IR %</PTitulosSaldos>
          <InputSearch
            value="4,80%"
            style={{
              borderRadius: "10px",
              textAlign: "end",
              paddingRight: "30px",
            }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>INSS Retido</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>INSS Retido Subcontratados</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>Saldo a Pagar</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
      </RowForm>
    </>
  );
};
