import React from "react";
import { Row } from "react-bootstrap";
import { CCol } from "../../../../../components/Grid/CCol";
import styled from "styled-components";
import { Icon } from "@iconify/react/dist/iconify.js";

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  accent-color: #2f7716;
  width: 16px;
  height: 16px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  max-width: 300px;
  height: 16px;
  background-color: #e8e8e8;
  border-radius: 5px;
  margin-right: 10px;
`;

const ProgressBarProgress = styled.div`
  background-color: #f87c4c;
  height: 100%;
  width: ${(props) => (props.progress ? props.progress : 0)}%;
  border-radius: 5px;
`;

const ProgressBar = ({ progress }) => {
  return (
    <ProgressBarContainer>
      <ProgressBarProgress progress={progress} />
    </ProgressBarContainer>
  );
};

const Principal = () => {
  const values = [
    {
      title: "Empenho",
      data: "18/01/2024",
      progress: 91,
      value: "7.727,88",
      registry: 256,
    },
    {
      title: "Estorno",
      data: "18/01/2024",
      progress: 91,
      value: "0,00",
      registry: 256,
    },
    {
      title: "Estorno Pagamento",
      data: "18/01/2024",
      progress: 91,
      value: "0,00",
      registry: 256,
    },
    {
      title: "Liquidação",
      data: "18/01/2024",
      progress: 91,
      value: "10.335,32",
      registry: 256,
    },
    {
      title: "Pagamentos",
      data: "18/01/2024",
      progress: 91,
      value: "32.237,32",
      registry: 256,
    },
    {
      title: "Retenção",
      data: "18/01/2024",
      progress: 91,
      value: "560,42",
      registry: 256,
    },
    {
      title: "Estorno Liquidação",
      data: "18/01/2024",
      progress: 91,
      value: "0,00",
      registry: 256,
    },
    {
      title: "Estorno Pagamentos",
      data: "18/01/2024",
      progress: 91,
      value: "0,00",
      registry: 256,
    },
    {
      title: "Receita Orçamentária",
      data: "18/01/2024",
      progress: 91,
      value: "222.991,27",
      registry: 256,
    },
  ];

  return (
    <>
      {values.map((item, index) => (
        <Row key={index}>
          <CCol
            md={1}
            style={{
              width: "fit-content",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              icon="iconamoon:arrow-right-2-light"
              width={26}
              style={{ marginRight: "5px", cursor: "pointer" }}
            />
            <Checkbox />
          </CCol>
          <CCol
            md={5}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>
              {item.title}{" "}
              <span
                style={{
                  marginLeft: "5px",
                  color: "#B1B0B6",
                  fontWeight: "300",
                }}
              >
                Enviado em: {item.data}
              </span>
            </span>
          </CCol>
          <CCol
            style={{
              JustifyContent: "right",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ProgressBar progress={item.progress} />{" "}
            <span style={{ color: "#f87c4c", fontWeight: "600" }}>
              {item.progress}%
            </span>
          </CCol>
          <CCol
            style={{
              justifyContent: "right",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>Total: R$ {item.value}</span>
          </CCol>
          <CCol
            md={1}
            style={{
              JustifyContent: "right",
              display: "flex",
              alignItems: "center",
              width: "fit-content",
            }}
          >
            <span>
              Registros:{" "}
              <span style={{ marginLeft: "15px" }}>{item.registry}</span>
            </span>
          </CCol>
        </Row>
      ))}
    </>
  );
};

export default Principal;
