import styled from "styled-components";
import { Col } from "react-bootstrap";

export const ButtonOptionsPages = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: -12px;
`;

export const ButtonOptionsFirst = styled.button`
  background: #ffffff;
  color: #515c70;
  font-weight: ${(props) => (props.ativo ? "600" : "500")};
  font-size: 16px;
  padding: 14px 16px;
  border: none;
  height: 100%;
  line-height: 0px;
  border-radius: 10px 0px 0px 0px;
  ${(props) =>
    props.ativo
      ? `
    background-color: #515c70;
    color: #F7F7F7;
  `
      : ""}
`;

export const ButtonOptionsBetween = styled.button`
  background: #ffffff;
  color: #515c70;
  font-weight: ${(props) => (props.ativo ? "600" : "500")};
  font-size: 16px;
  padding: 14px 16px;
  border: none;
  height: 100%;
  line-height: 0px;
  ${(props) =>
    props.ativo
      ? `
    background-color: #515c70;
    color: #F7F7F7;
  `
      : ""}
`;

export const ButtonOptionsLast = styled.button`
  background: #ffffff;
  color: #515c70;
  font-weight: ${(props) => (props.ativo ? "600" : "500")};
  font-size: 16px;
  padding: 14px 16px;
  border: none;
  height: 100%;
  line-height: 0px;
  border-radius: 0px 10px 0px 0px;
  ${(props) =>
    props.ativo
      ? `
    background-color: #515c70;
    color: #F7F7F7;
  `
      : ""}
`;

export const ColButtons = styled(Col)`
  border-radius: 10px 10px 0px 0px;
  background-color: white;
  height: 40px;
`;
