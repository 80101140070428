const dataAtual = new Date();
export const anoAtual = dataAtual.getFullYear();

export const arrayAnos = [
  anoAtual - 3,
  anoAtual - 2,
  anoAtual - 1,
  anoAtual,
  anoAtual + 1,
  anoAtual + 2,
];
