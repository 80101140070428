import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";

const IconDelete = (props) => {
  return (
    <Icon
      icon="ic:baseline-delete"
      color="#E79900"
      height="26"
      {...props}
      onClick={props.disabled ? null : props.onClick}
      style={{
        cursor: props.disabled ? "auto" : "pointer",
        opacity: props.disabled ? "0.5" : "1",
        ...props.style,
      }}
    />
  );
};

export default IconDelete;
