import styled from "styled-components";
import "./style.css";
import { Link, NavLink } from "react-router-dom";

export const InvisibleCheckboxSist = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

export const SideBarDireita = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 250px; /* ou outro valor desejado */
  background: rgba(250, 250, 250, 0.95); /* ou outra cor desejada */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
  margin-top: 55px;
  z-index: 1;
  transition: 1s;
  margin-right: ${(props) => (props.openSideSistemas ? "-260px" : "0px")};
`;

export const Titulo = styled.h5`
  font-weight: 700;
  font-size: 16px;
  color: #515c70;
  margin: 0;
`;

export const DivTitulo = styled.div`
  border-bottom: 2px solid #efefef;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DivSelectListArrangement = styled.div`
  display: flex;
  button {
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    margin-left: 8px;
  }

  svg {
    margin: 0;
  }
`;

export const DivSist = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;

  svg {
    color: #fff;
  }
`;

export const DivSistGrid = styled.div`
  display: flex;
  flex-direction: column;
  height: 620px;
  padding: 0px 10px;
  padding-bottom: 13px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  & > div {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }

  & > div > a {
    text-decoration: none;
    flex-basis: 50%;

    :hover {
      opacity: 0.5;
    }
  }
`;

export const SystemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    background-color: ${({ $backgroundColor }) =>
      $backgroundColor ? $backgroundColor : "#515c70"};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    width: 78px;
    height: 78px;
    border-radius: 10px;
  }

  svg {
    color: #fff;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  p {
    margin: 0;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: #515c70;
  }
`;

export const LabelP = styled.label`
  background: #f6991e;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  width: 224px;
  height: 32px;
  margin-top: 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  padding-top: 4px;

  :hover {
    opacity: 0.5;
  }
`;

export const LabelC = styled.label`
  background: #da499a;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  width: 224px;
  height: 32px;
  margin-top: 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  padding-top: 4px;

  :hover {
    opacity: 0.5;
  }
`;

export const LabelF = styled.label`
  background: #89c454;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  width: 224px;
  height: 32px;
  margin-top: 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  padding-top: 4px;
  text-align: left;

  :hover {
    opacity: 0.5;
  }
`;

export const LabelG = styled.label`
  background: #515c70;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  width: 224px;
  height: 32px;
  margin-top: 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  padding-top: 4px;

  :hover {
    opacity: 0.5;
  }
`;

export const LabelGestor = styled.label`
  background: #2f817b;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  width: 224px;
  height: 32px;
  margin-top: 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  padding-top: 4px;

  :hover {
    opacity: 0.5;
  }
`;

export const LabelFolhaRH = styled.label`
  background: #586dba;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  width: 224px;
  height: 32px;
  margin-top: 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  padding-top: 4px;

  :hover {
    opacity: 0.5;
  }
`;

export const LinkModulo = styled(Link)`
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : "#ddd"};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  align-items: center;
  padding-left: ${({ $paddingLeft }) => ($paddingLeft ? $paddingLeft : "45px")};
  border-radius: 10px;
  width: 224px;
  height: 32px;
  margin-top: 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  text-decoration: none;
  color: #fff;

  :hover {
    opacity: 0.5;
    color: #fff;
  }
`;

export const LinkA = styled(NavLink)`
  text-decoration: none;
  color: #ffff;

  :hover {
    color: #ffff;
  }
`;

export const LinkF = styled(NavLink)`
  margin-left: 42px;
  text-decoration: none;
  color: #ffff;
  :hover {
    color: #ffff;
  }
`;
