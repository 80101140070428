import React, { useContext, useEffect, useState } from "react";
import {
  CCol,
  ContainerToModal,
  RowForm,
  RowToModal,
} from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import styled from "styled-components";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { OptionsStyle } from "../../../Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/NaturezaDaReceita";
import HistoricoAba from "./HistoricoAba";
import FonteDeRecursosAba from "./FonteDeRecursosAba";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import api from "../../../../utils/api";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { toast, ToastContainer } from "react-toastify";
import AutoCompleteReceitaOrcamentaria from "../../../../components/Inputs/AutoCompleteReceitaOrcamentaria";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";

const CColNumeroAndEVB = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const CColRemessa = styled(CCol)`
  @media (min-width: 768px) {
    width: 20%;
  }
`;

const ReceitaOrcamentaria = () => {
  //contexts
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();

  const dataAtual = ReturnDataAtual()
    .replace(/\//g, "-")
    .split("-")
    .reverse()
    .join("-");
  //states
  const [valuesReceitaOrcamentaria, setValuesReceitaOrcamentaria] = useState({
    clienteId: currentClient.clienteId,
    naturezaId: null,
    contaBancariaId: null,
    dataCriacao: dataAtual,
    tipoDeLancamento: 0,
    descricao: "",
    devedorId: null,
    docFinanceiro: "",
    valor: 0,
    fontes: [],
  });
  const [descricaoNatRec, setDescricaoNatRec] = useState("");
  const [fontesDeRecursos, setFontesDeRecursos] = useState([
    // {
    //   clienteId: 0,
    //   fonteId: 0,
    //   valor: 0
    // }
  ]);
  const [fonteDeRecursoAdicionar, setFonteDeRecursoAdicionar] = useState({
    clienteId: currentClient.clienteId,
    fonteId: 0,
    valor: 0,
  });
  const [inputFonteRec, setInputFonteRec] = useState("");
  const [abaSelecionada, setAbaSelecionada] = useState(0);
  const [optionsCaixaEquivalenteDeCaixa, setOptionsCaixaEquivalenteDeCaixa] =
    useState([]);
  const [
    filteredOptionsCaixaEquivalenteDeCaixa,
    setFilteredOptionsCaixaEquivalente,
  ] = useState([]);
  const [optionsNaturezaDaReceita, setOptionsNaturezaDaReceita] = useState([]);
  const [optionsDevedor, setOptionsDevedor] = useState([]);
  const [optionsFontesDeRecursos, setOptionsFontesDeRecursos] = useState([]);
  const [filteredOptionsFonteRec, setFilteredOptionsFonteRec] = useState([]);

  //states dos autocompletes
  const [inputNatRec, setInputNatRec] = useState("");
  const [inputDevedor, setInputDevedor] = useState("");
  const [inputCaixa, setInputCaixa] = useState("");

  useEffect(() => {
    setValuesReceitaOrcamentaria((prev) => ({
      ...prev,
      fontes: [...fontesDeRecursos],
    }));
  }, [fontesDeRecursos]);

  const fetchFontesDeRecursos = async () => {
    try {
      const resp = await api.get(`fonteDeRecurso/por-ano/${currentYear}`);
      const data = resp.data;
      const dataFilter = data.filter((item) => item.conta.length > 4);

      setOptionsFontesDeRecursos(
        dataFilter.map((item) => ({
          id: item.id,
          conta: item.conta,
          titulo: item.titulo,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContas = async () => {
    try {
      const resp = await api.get(
        `contaBancaria/cliente/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;

      console.log(data);
      setOptionsCaixaEquivalenteDeCaixa(
        data.map((item) => ({
          id: item.id,
          nome: item.nome,
          numero: item.numeroConta + "-" + item.digito,
          fontesDeRecursos: item.fontesDeRecursos,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNaturezasDaReceita = async () => {
    try {
      const resp = await api.get(
        `naturezaDaReceita/vinculo-qdr/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;
      console.log(data);

      const arrayDeNaturezas = data.map((item) => ({
        id: item.id,
        codigo: item.nr,
        titulo: item.titulo,
        descricao: item.descricao,
        fichas: [...item.fontes],
      }));

      let arrayDeFichas = [];

      data.forEach((item) => {
        const { fontes } = item;

        fontes.forEach((ficha) => {
          const object = {
            pill: "Ficha",
            id: item.id,
            codigo: item.nr,
            titulo: item.titulo,
            descricao: item.descricao,
            ficha: {
              ...ficha,
            },
          };

          arrayDeFichas.push(object);
        });
      });

      console.log("arrayDeNaturezas");
      console.log(arrayDeNaturezas);
      console.log("arrayDeFichas");
      console.log(arrayDeFichas);

      setOptionsNaturezaDaReceita([...arrayDeFichas, ...arrayDeNaturezas]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOptionsDevedor = async () => {
    try {
      const resp = await api.get(`cpf/por-cliente/${currentClient.clienteId}`);
      const data = resp.data;

      setOptionsDevedor(
        data.map((item) => ({
          id: item.pessoaId,
          nome: item.nomeCompleto,
          cpf: FormatCpfPaste(item.cpf),
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Object.keys(currentClient).length > 0 && currentYear) {
      fetchContas();
      fetchNaturezasDaReceita();
      fetchOptionsDevedor();
      fetchFontesDeRecursos();
    }
    //eslint-disable-next-line
  }, [currentClient, currentYear]);

  useEffect(() => {
    if (inputNatRec.length === 0) {
      setValuesReceitaOrcamentaria((prev) => ({
        ...prev,
        naturezaId: null,
        contaBancariaId: null,
        valor: "0,00",
      }));
      setInputCaixa("");
      setFilteredOptionsFonteRec([]);
      setFontesDeRecursos([]);
      setDescricaoNatRec("");
    }

    if (inputCaixa.length === 0) {
      setValuesReceitaOrcamentaria((prev) => ({
        ...prev,
        contaBancariaId: null,
        valor: "0,00",
      }));
      setFilteredOptionsFonteRec([]);
      setFontesDeRecursos([]);
      setFonteDeRecursoAdicionar((prev) => ({
        ...prev,
        fonteId: 0,
      }));
      setInputFonteRec("");
    }

    if (inputDevedor.length === 0) {
      setValuesReceitaOrcamentaria((prev) => ({
        ...prev,
        devedorId: null,
      }));
    }
  }, [inputNatRec, inputCaixa, inputDevedor]);

  const handleSelectNatRec = (item) => {
    setValuesReceitaOrcamentaria((prev) => ({
      ...prev,
      naturezaId: item.id,
    }));
    setDescricaoNatRec(item.descricao);

    if (item.ficha) {
      console.log(item.ficha.fonteId);
      setFilteredOptionsCaixaEquivalente(
        optionsCaixaEquivalenteDeCaixa.filter((element) =>
          element.fontesDeRecursos.some(
            (fonte) => fonte.fonteId === item.ficha.fonteId
          )
        )
      );

      setValuesReceitaOrcamentaria((prev) => ({
        ...prev,
        tipoDeLancamento: item.ficha.tipo,
      }));
    } else {
      setValuesReceitaOrcamentaria((prev) => ({
        ...prev,
        tipoDeLancamento: 1,
      }));

      setFilteredOptionsCaixaEquivalente(
        optionsCaixaEquivalenteDeCaixa.filter((element) =>
          element.fontesDeRecursos.some((fonte) =>
            item.fichas.some((ficha) => ficha.fonteId === fonte.fonteId)
          )
        )
      );

      const fontesIds = item.fichas.map((ficha) => ficha.fonteId);
      const options = optionsFontesDeRecursos.filter((fonte) =>
        fontesIds.includes(fonte.id)
      );

      setFilteredOptionsFonteRec([...options]);
    }
  };

  const handleCaixaEquivalenteDeCaixaSelect = (item) => {
    setValuesReceitaOrcamentaria((prev) => ({
      ...prev,
      contaBancariaId: item.id,
    }));

    console.log(item.fontesDeRecursos);

    if (item.fontesDeRecursos.length === 1) {
      const fonteDeRecurso = item.fontesDeRecursos[0];

      const option = optionsFontesDeRecursos.find(
        (fonte) => fonte.id === fonteDeRecurso.fonteId
      );

      setFilteredOptionsFonteRec([option]);
      setFontesDeRecursos([
        {
          clienteId: currentClient.clienteId,
          fonteId: fonteDeRecurso.fonteId,
          valor: "0,00",
        },
      ]);
    } else if (item.fontesDeRecursos.length > 1) {
      const fontesIds = item.fontesDeRecursos.map((fonte) => fonte.fonteId);
      const options = optionsFontesDeRecursos.filter((option) =>
        fontesIds.includes(option.id)
      );

      setFilteredOptionsCaixaEquivalente([...options]);
    }
  };

  const handleSelectDevedor = (item) => {
    setValuesReceitaOrcamentaria((prev) => ({
      ...prev,
      devedorId: item.id,
    }));
  };

  const handleChangeValorReceita = (_, valor) => {
    console.log(valor);
    setValuesReceitaOrcamentaria((prev) => ({
      ...prev,
      valor: valor,
    }));

    if (fontesDeRecursos.length === 1) {
      const fonte = fontesDeRecursos[0];
      fonte.valor = valor;
      console.log(fonte);
      setFontesDeRecursos([fonte]);
    }
  };

  const cadastraReceita = async () => {
    const arrayFontesToPost = valuesReceitaOrcamentaria.fontes.map((item) => ({
      ...item,
      valor: parseFloat(item.valor.replace(/,/g, ".")),
    }));
    const valuesToPost = {
      ...valuesReceitaOrcamentaria,
      valor: parseFloat(valuesReceitaOrcamentaria.valor.replace(/,/g, ".")),
      fontes: [...arrayFontesToPost],
    };

    console.log(valuesToPost);

    try {
      await api.post(`guiaDeReceita`, valuesToPost);

      limpaValoresDoCadastro();

      toast.success("Receita cadastrada com sucesso.");
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Algo deu errado. Por favor, tente mais tarde.");
      }
    }
  };

  const limpaValoresDoCadastro = () => {
    setValuesReceitaOrcamentaria({
      clienteId: currentClient.clienteId,
      naturezaId: null,
      contaBancariaId: null,
      dataCriacao: dataAtual,
      tipoDeLancamento: 0,
      descricao: "",
      devedorId: null,
      docFinanceiro: "",
      valor: 0,
      fontes: [],
    });

    setInputCaixa("");
    setInputDevedor("");
    setInputFonteRec("");
    setInputNatRec("");
  };

  // const debug = () => {
  //   // console.log(optionsFontesDeRecursos);
  //   // console.log(dataAtual);
  //   // console.log(optionsNaturezaDaReceita);
  //   // console.log(optionsCaixaEquivalenteDeCaixa);
  //   console.log(fontesDeRecursos);
  //   console.log(filteredOptionsFonteRec);
  //   console.log(valuesReceitaOrcamentaria);
  // };

  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <ToastContainer />
      <HeaderCadastros
        PaginaConsulta={"/contabilidade/receita/guia-de-receita/consulta"}
        NomePaginaTitulo={"Orçamentário"}
        PaginaSubtitulo={"Receita"}
        ButtonSaveFunction={cadastraReceita}
        RowTitle={"Receita Orçamentária"}
      />

      <RowToModal className="row_form mt-1">
        {/* <button onClick={debug}>debug</button> */}
        <CColNumeroAndEVB md={1}>
          <FloatingLabelInput placeholder="Número" label="Número" disabled />
        </CColNumeroAndEVB>
        <CColNumeroAndEVB md={1}>
          <MyAutoComplete
            labelInput={"EVB"}
            options={[{ label: "Teste", value: "teste" }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
            style={{ height: "38px" }}
          />
        </CColNumeroAndEVB>
        <CCol>
          <AutoCompleteReceitaOrcamentaria
            inputValue={inputNatRec}
            setInputValue={setInputNatRec}
            labelInput={"Natureza da Receita"}
            options={optionsNaturezaDaReceita}
            labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
            style={{ height: "38px" }}
            onOptionSelect={handleSelectNatRec}
          />
        </CCol>
      </RowToModal>
      {descricaoNatRec !== "" && (
        <RowForm style={{ marginTop: "-15px" }}>
          <CCol>
            <p
              style={{
                backgroundColor: "#F2F2F2",
                padding: "10px",
                borderRadius: "5px",
                color: "#515c70",
                fontWeight: "500",
              }}
            >
              {descricaoNatRec}
            </p>
          </CCol>
        </RowForm>
      )}

      <RowToModal
        className="row_form"
        style={{ marginTop: `${descricaoNatRec !== "" ? "-30px" : "-15px"}` }}
      >
        <CColRemessa md={1}>
          <FloatingLabelInput placeholder="Remessa" label="Remessa" disabled />
        </CColRemessa>
        <CCol md={7}>
          <FloatingLabelInput
            placeholder="Tipo de lançamento"
            new
            value={valuesReceitaOrcamentaria.tipoDeLancamento}
            tipo="input_select"
            label="Tipo de lançamento"
            onSelect={(option) =>
              setValuesReceitaOrcamentaria((prev) => ({
                ...prev,
                tipoDeLancamento: parseInt(option.value),
              }))
            }
            options={[
              { label: "1 - Lançamento de Receita", value: 1 },
              {
                label: "3 - Dedução de Receita do Fundeb",
                value: 3,
              },
              {
                label: "4 - Dedução de Receita de Rendimentos de Investimentos",
                value: 4,
              },
              {
                label: "5 - Outras Deduções de Receita",
                value: 5,
              },
            ]}
          />
        </CCol>
        <CCol>
          <InputData
            externalValue={valuesReceitaOrcamentaria.dataCriacao}
            onChange={(e) =>
              setValuesReceitaOrcamentaria((prev) => ({
                ...prev,
                dataCriacao: e.target.value,
              }))
            }
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol>
          <MyAutoComplete
            inputValue={inputDevedor}
            setInputValue={setInputDevedor}
            onOptionSelect={handleSelectDevedor}
            labelInput={"Devedor"}
            options={optionsDevedor}
            labelFormat={(option) => `${option.cpf} - ${option.nome}`}
            style={{ height: "37px" }}
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol md={8}>
          <MyAutoComplete
            inputValue={inputCaixa}
            setInputValue={setInputCaixa}
            labelInput={"Caixa e Equivalente de Caixa"}
            disabled={!valuesReceitaOrcamentaria.naturezaId}
            options={filteredOptionsCaixaEquivalenteDeCaixa}
            onOptionSelect={handleCaixaEquivalenteDeCaixaSelect}
            labelFormat={(option) => `${option.numero} - ${option.nome}`}
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            placeholder="Doc Financeiro"
            label="Doc Financeiro"
            value={valuesReceitaOrcamentaria.docFinanceiro}
            onChange={(e) =>
              setValuesReceitaOrcamentaria((prev) => ({
                ...prev,
                docFinanceiro: e.target.value,
              }))
            }
          />
        </CCol>
        <CCol md={2}>
          <InputFormatRealBrasileiro
            disabled={
              !(
                valuesReceitaOrcamentaria.contaBancariaId &&
                valuesReceitaOrcamentaria.naturezaId
              )
            }
            externalValue={valuesReceitaOrcamentaria.valor}
            onChange={handleChangeValorReceita}
          />
        </CCol>
      </RowToModal>

      <RowToModal
        style={{ backgroundColor: "#fff", borderRadius: "0px 0px 5px 5px" }}
        className={"mt-1"}
      >
        <CCol style={{ display: "flex", alignItems: "center", padding: "0px" }}>
          <OptionsStyle
            selecionado={abaSelecionada === 0}
            onClick={() => setAbaSelecionada(0)}
          >
            Histórico
          </OptionsStyle>
          <OptionsStyle
            selecionado={abaSelecionada === 1}
            onClick={() => setAbaSelecionada(1)}
          >
            Fonte de Recursos
          </OptionsStyle>
        </CCol>
      </RowToModal>

      {abaSelecionada === 0 && (
        <HistoricoAba
          setValuesReceitaOrcamentaria={setValuesReceitaOrcamentaria}
          valuesReceitaOrcamentaria={valuesReceitaOrcamentaria}
        />
      )}
      {abaSelecionada === 1 && (
        <FonteDeRecursosAba
          contaBancariaId={valuesReceitaOrcamentaria.contaBancariaId}
          naturezaId={valuesReceitaOrcamentaria.naturezaId}
          setFontesDeRecursos={setFontesDeRecursos}
          fontesDeRecursos={fontesDeRecursos}
          filteredOptionsFonteRec={filteredOptionsFonteRec}
          valorTotalReceita={parseFloat(valuesReceitaOrcamentaria.valor)}
          fonteDeRecursoAdicionar={fonteDeRecursoAdicionar}
          setFonteDeRecursoAdicionar={setFonteDeRecursoAdicionar}
          inputFonteRec={inputFonteRec}
          setInputFonteRec={setInputFonteRec}
        />
      )}
    </ContainerToModal>
  );
};

export default ReceitaOrcamentaria;
