import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SaveLogoExpanded from "../../components/SvgComponents/SaveLogoExpanded";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "react-router-dom";

const MainContainer = styled.div`
  width: 100vw;
  position: absolute;
  left: 0;
`;

const Head = styled.div`
  height: 70px;
`;

const DivImg = styled.div`
  padding-top: 10px;
  margin-left: 105px;
`;

const FormContainer = styled.div`
  box-sizing: border-box;
  padding: 70px;
`;

const FormRecoverPassword = styled.form`
  margin-top: 18px;
  width: 100%;
  max-width: 500px;
  padding: 27px 20px 31px 20px;
  background-color: #eaeaf6;
  border-radius: 5px;
  margin: auto;

  h3 {
    margin-top: 14px;
    text-align: center;
    color: #515c70;
    font-weight: 600;
    font-size: 23px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FlexInputs = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  input {
    min-width: 0;
    flex-basis: 45px;
    height: 45px;
    font-size: 30px;
    text-align: center;
    color: #515c70;
  }
`;

const ButtonSendCode = styled.button`
  border: none;
  background-color: #515c70;
  outline: none;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  border-radius: 5px;
  padding: 10px;
  margin-top: 18px;
  width: 100%;
`;

const PagRecuperacaoDeSenha = () => {
  const [verificationCode, setVerificationCode] = useState(Array(6).fill(""));
  const inputFocusedIndex = useRef(null);
  const inputRefs = useRef([]);

  const handleKeyDown = useCallback(
    (event) => {
      if (inputFocusedIndex.current !== null) {
        if (event.key === "ArrowLeft") {
          if (inputFocusedIndex.current > 0) {
            inputFocusedIndex.current -= 1;
            setTimeout(() => {
              const prevInput = inputRefs.current[inputFocusedIndex.current];
              prevInput.focus();
              prevInput.setSelectionRange(
                prevInput.value.length,
                prevInput.value.length
              );
            }, 0);
          }
        } else if (event.key === "ArrowRight") {
          if (inputFocusedIndex.current < verificationCode.length - 1) {
            inputFocusedIndex.current += 1;
            setTimeout(() => {
              const nextInput = inputRefs.current[inputFocusedIndex.current];
              nextInput.focus();
              nextInput.setSelectionRange(
                nextInput.value.length,
                nextInput.value.length
              );
            }, 0);
          }
        }
      }
    },
    [verificationCode]
  );

  const handlePaste = () => {
    navigator.clipboard.readText().then((text) => {
      console.log(text);
    });
  };

  const handleChange = (e, index) => {
    console.log(e.target.value);
    const valor = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    setVerificationCode((prev) => {
      const newArray = [...prev];
      newArray[index] = valor;
      return newArray;
    });

    if (valor.length > 0 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
      inputFocusedIndex.current += 1;
    }
  };

  // const debug = () => {
  //   console.log(inputRefs.current);
  // };

  useEffect(() => {
    // Adiciona o ouvinte de eventos de teclado
    window.addEventListener("keydown", handleKeyDown);

    // Remove o ouvinte de eventos ao desmontar o componente
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <MainContainer>
      <Head>
        <DivImg>
          <Link to="/">
            <SaveLogoExpanded />
          </Link>
        </DivImg>
      </Head>
      <FormContainer>
        {/* <button onClick={debug}>console</button> */}
        <FormRecoverPassword onSubmit={handleSubmit}>
          <IconContainer>
            <Icon
              icon="mdi:security-lock"
              style={{ color: "#515c70" }}
              height={85}
            />
          </IconContainer>
          <h3>Digite o código enviado para o seu email</h3>

          <FlexInputs>
            {verificationCode.map((item, index) => (
              <input
                ref={(el) => (inputRefs.current[index] = el)}
                key={index}
                type="text"
                maxLength={1}
                onFocus={(e) => {
                  inputFocusedIndex.current = index;
                }}
                onBlur={() => (inputFocusedIndex.current = null)}
                onPaste={handlePaste}
                value={verificationCode[index]}
                onChange={(e) => handleChange(e, index)}
              />
            ))}
          </FlexInputs>

          <ButtonSendCode>Verificar Código</ButtonSendCode>
        </FormRecoverPassword>
      </FormContainer>
    </MainContainer>
  );
};

export default PagRecuperacaoDeSenha;
