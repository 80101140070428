import { createContext, useState } from "react";

//1 - Criando contexto

export const CurrentClientContext = createContext();

//2 - Criando Provider

export const CurrentClientProvider = ({ children }) => {
  const [currentClient, setCurrentClient] = useState({});

  return (
    <CurrentClientContext.Provider value={{ currentClient, setCurrentClient }}>
      {children}
    </CurrentClientContext.Provider>
  );
};
