import { useEffect, useRef, useState } from "react";
import * as RS from "../../../../../components/ComponenteDeRelatorio/StyledCompsRelatorio";
import { Col } from "react-bootstrap";

import { LabelCheckLoa2 } from "../../../../../components/ComponenteDeRelatorio/StyledCompsRelatorio";
import FloatingLabelInput from "../../../../../components/Grid/FloatingLabelInput";

export const OptionsRelatorio = ({
  principalOptions = [],
  labelsCheckAbaPrincipal = [],
  setLabelsCheckAbaPrincipal,
  setAbaSelecionada,
  abaSelecionada,
  showOpcoesButton = false,
  showOutrosButton = false,
  entidadeCamaraSelected = false,
  setSelected,
  entidadesGestorasDoCliente = [],
}) => {
  const [optionSelectPrincipal, setOptionSelectPrincipal] = useState(
    principalOptions.find((item) => item.value !== "")?.value
  );
  const [checkBoxTodosSelecionados, setCheckBoxTodosSelecionados] =
    useState(false);
  const floatingLabelInputRef = useRef(null);

  const gerenciarSelects = (indexDaLabel) => {
    const indexOpcao = labelsCheckAbaPrincipal.findIndex(
      (item) => item.opcao === optionSelectPrincipal
    );

    console.log(indexOpcao);

    console.log(indexDaLabel);

    let newArray = [...labelsCheckAbaPrincipal];

    newArray[indexOpcao].checkBoxes[indexDaLabel].selected =
      !newArray[indexOpcao].checkBoxes[indexDaLabel].selected;

    console.log(newArray);

    setLabelsCheckAbaPrincipal([...newArray]);
  };

  const checkAllBoxes = (checked) => {
    console.log(checked);
    const indexOpcao = labelsCheckAbaPrincipal.findIndex(
      (item) => item.opcao === optionSelectPrincipal
    );

    let newArray = [...labelsCheckAbaPrincipal];

    if (checked) {
      newArray[indexOpcao].checkBoxes.forEach((item) => {
        item.selected =
          entidadeCamaraSelected && item.typeRelatorio === "receita"
            ? false
            : true;
      });

      console.log(newArray);
    } else {
      newArray[indexOpcao].checkBoxes.forEach((item) => {
        item.selected = false;
      });

      console.log(newArray);
    }

    setLabelsCheckAbaPrincipal(newArray);
  };

  useEffect(() => {
    if (entidadeCamaraSelected) {
      const newArray = labelsCheckAbaPrincipal.map((item) => {
        return {
          ...item,
          checkBoxes: item.checkBoxes.map((option) => {
            if (option.typeRelatorio === "receita") {
              return { ...option, selected: false };
            }
            return option;
          }),
        };
      });

      setLabelsCheckAbaPrincipal(newArray);
    }
    // eslint-disable-next-line
  }, [entidadeCamaraSelected]);

  useEffect(() => {
    if (entidadesGestorasDoCliente) {
      const newArray = labelsCheckAbaPrincipal.map((item) => {
        return {
          ...item,
          checkBoxes: item.checkBoxes.map((option) => {
            if (consolidado(option.label, entidadesGestorasDoCliente)) {
              return { ...option, selected: false };
            }
            return option;
          }),
        };
      });

      setLabelsCheckAbaPrincipal(newArray);
    }
    // eslint-disable-next-line
  }, [entidadesGestorasDoCliente]);

  useEffect(() => {
    if (principalOptions.length === 1) {
      setOptionSelectPrincipal(principalOptions[0].value);
      setSelected(principalOptions[0].value);
    }
  }, [principalOptions, setSelected]);

  useEffect(() => {
    if (optionSelectPrincipal) {
      if (entidadeCamaraSelected) {
        setCheckBoxTodosSelecionados(
          labelsCheckAbaPrincipal
            .find((item) => item.opcao === optionSelectPrincipal)
            ?.checkBoxes.filter(
              (checkBox) => checkBox.typeRelatorio !== "receita"
            )
            .every((option) => option.selected && entidadeCamaraSelected)
        );
        return;
      }
      setCheckBoxTodosSelecionados(
        labelsCheckAbaPrincipal
          .find((item) => item.opcao === optionSelectPrincipal)
          ?.checkBoxes.every((option) => option.selected)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionSelectPrincipal, labelsCheckAbaPrincipal]);
  console.log(labelsCheckAbaPrincipal);

  const consolidado = (label, obj) => {
    const consolidadoList = [
      "Anexo I - Demonstração da Receita e Despesa segundo as Categorias Econômicas",
      "Demonstrativo Regionalizado do Efeito sobre Receitas e Despesa",
      "Demonstrativo da Compatibilidade da Programação dos Orçamentos com os Objetivos e Metas",
    ];
    const labelIn = consolidadoList.includes(label);
    const everySelected = obj[0].checkBoxes.every(
      (checkBox) => checkBox.selected === true
    );
    const correctLength = obj[0].checkBoxes.length > 1;
    const isCorrect = correctLength ? !everySelected : true;
    const result = labelIn && isCorrect;
    return result;
  };

  // const consolidado = (label, obj) => {
  //   const consolidadoList = [
  //     "Anexo I - Demonstração da Receita e Despesa segundo as Categorias Econômicas",
  //     "Demonstrativo Regionalizado do Efeito sobre Receitas e Despesa",
  //     "Demonstrativo da Compatibilidade da Programação dos Orçamentos com os Objetivos e Metas",
  //   ];
  //   const everySelected = obj.every((item) =>
  //     item.checkBoxes.every((checkBox) => checkBox.selected === true)
  //   );
  //   const labelIn = consolidadoList.includes(label);
  //   const correctLength = obj[0].checkBoxes.length > 1;
  //   const result = everySelected && labelIn && correctLength;
  //   console.log(
  //     label,
  //     labelIn,
  //     obj,
  //     everySelected,
  //     correctLength,
  //     " resultado final: ",
  //     result
  //   );
  //   return result;
  // };

  return (
    <>
      <Col style={{ marginTop: "10px" }} md={12}>
        <FloatingLabelInput
          new
          ref={floatingLabelInputRef}
          tipo="input_select"
          label="Relatório por..."
          onChange={(e) => {
            console.log(e.target.value);
            setOptionSelectPrincipal(e.target.value);
          }}
          value={optionSelectPrincipal}
          options={[
            { label: "Relatório por...", value: null },
            ...principalOptions,
          ]}
          onSelect={(option) => {
            setSelected(option.value);
            setOptionSelectPrincipal(option.value);
          }}
        />
      </Col>
      <RS.ContainerMaiorEsquerda className="mt-3" style={{ height: "92%" }}>
        <RS.MenuTopo>
          <RS.MenuTopoMenus>
            <RS.ButtonSelectAba
              onClick={() => setAbaSelecionada(0)}
              active={abaSelecionada === 0}
            >
              Principal
            </RS.ButtonSelectAba>
            <RS.ButtonSelectAba
              onClick={() => setAbaSelecionada(1)}
              active={abaSelecionada === 1}
              style={{ display: showOpcoesButton ? "" : "none" }}
            >
              Opções
            </RS.ButtonSelectAba>
            <RS.ButtonSelectAba
              active={abaSelecionada === 2}
              onClick={() => setAbaSelecionada(2)}
              style={{ display: showOutrosButton ? "" : "none" }}
            >
              Outros
            </RS.ButtonSelectAba>
          </RS.MenuTopoMenus>
        </RS.MenuTopo>

        <Col
          style={{
            marginTop: "10px",
            maxHeight: "calc(100% - 136px)",
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingBottom: "12px",
          }}
          md={12}
        >
          {labelsCheckAbaPrincipal.find(
            (item) => item.opcao === optionSelectPrincipal
          )?.checkBoxes.length > 0 && (
            <LabelCheckLoa2
              label={"Marcar Todos"}
              checked={checkBoxTodosSelecionados}
              onChange={(e) => {
                checkAllBoxes(e.target.checked);
              }}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                padding: "5px 0 5px 5px",
              }}
            />
          )}

          {labelsCheckAbaPrincipal
            .find((item) => item.opcao === optionSelectPrincipal)
            ?.checkBoxes.map((option, index) => (
              <div
                key={index}
                style={{
                  opacity:
                    (entidadeCamaraSelected &&
                      option.typeRelatorio === "receita") ||
                    consolidado(option.label, entidadesGestorasDoCliente)
                      ? 0.5
                      : 1,
                  pointerEvents:
                    entidadeCamaraSelected && option.typeRelatorio === "receita"
                      ? "none"
                      : "auto",
                }}
              >
                <LabelCheckLoa2
                  title={option.label}
                  label={`${
                    option.label.length > 65
                      ? `${option.label.substring(0, 65)}...`
                      : option.label
                  }`}
                  value={option.value}
                  checked={option.selected}
                  disabled={
                    (entidadeCamaraSelected &&
                      option.typeRelatorio === "receita") ||
                    consolidado(option.label, entidadesGestorasDoCliente)
                  }
                  onChange={(e) => {
                    if (
                      !(
                        (entidadeCamaraSelected &&
                          option.typeRelatorio === "receita") ||
                        consolidado(option.label, entidadesGestorasDoCliente)
                      )
                    ) {
                      gerenciarSelects(index);
                      console.log(e.target.value);
                    }
                  }}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    padding: "5px 0 5px 5px",
                    ...option.style,
                  }}
                />
              </div>
            ))}
        </Col>
      </RS.ContainerMaiorEsquerda>
    </>
  );
};
