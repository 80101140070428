import React from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

export const RetencaoAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Retenção</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1">
        <CCol>
          <FloatingLabelInput
            disabled
            style={{ border: "none" }}
            placeholder="Pagamento"
            label="Pagamento"
          />
        </CCol>
      </RowForm>
      <RowForm $borderRadiusType={2} style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            disabled
            style={{ border: "none" }}
            placeholder="Fonte de Recurso Pagamento"
            label="Fonte de Recurso Pagamento"
          />
        </CCol>
      </RowForm>
    </>
  );
};
