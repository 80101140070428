import React, { useRef } from "react";
import { CCol, CColButtonsAcoes, RowForm } from "../../../components/Grid/CCol";
import {
  BoxOptionsSistemas,
  LabelCheckStyled,
  RowSelecaoSistemas,
  RowTituloAba,
} from "./StyledComponentsContaPerfil";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../styles/StyledComponents/ListaDeInputs";
import MyAutoComplete from "../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";
import { DivModulosWithArrows, PInfosWithArrow } from "./ComponentsPagConta";
import SwitchButton from "../../../components/Inputs/SwitchButton";
import InputFile from "../../../styles/StyledComponents/InputFileStyled";
import { ModulosDoSistema } from "../../../utils/DadosGerais/ModulosDoSistema";
import { toast } from "react-toastify";
import { MakeADeepCopyArray } from "../../../utils/Gerais/MakeADeepCopyArray";
import {
  tiposDeUsuario,
  tiposDeUsuarioInterno,
} from "./data/dataPagCadatroDeUsuario";
import { pdfToBase64 } from "../../../utils/Gerais/PdfToBase64";
import IconEdit from "../../../components/ListaDeInputs/IconEdit";
import IconAdicionar from "../../../components/ListaDeInputs/IconAdicionar";
import DivCentralizeIcon from "../../../components/ListaDeInputs/DivCentralizeIcon";
import { FormatCnpjPaste } from "../../../utils/FormatacaoDeDados/FormatCnpjPaste";

const CadastroListaClientes = ({
  optionsClientes,
  valoresParaAdicionarCliente,
  setValoresParaAdicionarCliente,
  valuesUser,
  setValuesUser,
  modulosSelecionadosDosClientes,
  setModulosSelecionadosDosClientes,
  inputAutocompleteClientes,
  setInputAutocompleteClientes,
  moduloDoSistema,
  documentoAutorizativo,
  setDocumentoAutorizativo,
  currentClient,
}) => {
  const inputFileRef = useRef();

  const findIndexPermissao = (cliente) => {
    const clienteId = cliente.clienteId;
    const moduloSelecionado = modulosSelecionadosDosClientes.find(
      (obj) => obj.clienteId === clienteId
    ).moduloSelecionado;

    return cliente.permissoes.findIndex(
      (obj) => obj.sistema === moduloSelecionado
    );
  };

  const gerenciaModulosHabilitadosPreviamente = (objetoCliente) => {
    const sistemaAtualAcessado = moduloDoSistema.toLowerCase();

    const arrayComTodasAsPermissoesAtivas = objetoCliente.permissoes.map(
      (permissao) => {
        const menusEAcoes = permissao.menus.map((menu) => ({
          ...menu,
          menuAtivo: true,
          acoes: menu.acoes.map((acao) => ({
            ...acao,
            acaoAtiva: true,
          })),
        }));

        return {
          ...permissao,
          sistemaAtivo: true,
          menus: menusEAcoes,
        };
      }
    );

    const sistemasAtivosNoCliente = optionsClientes.find(
      (cliente) => cliente.id === objetoCliente.clienteId
    ).sistemasAtivos;

    console.log(sistemasAtivosNoCliente);

    if (objetoCliente.usuarioTipoId === 1) {
      //Quando o usuário for do tipo Master
      objetoCliente.permissoes = arrayComTodasAsPermissoesAtivas;
    } else if (
      objetoCliente.usuarioTipoId === 2 ||
      objetoCliente.usuarioTipoId === 4
    ) {
      //Quando o usuário for do tipo Gestor ou Administrador
      if (sistemaAtualAcessado === "gerenciador") {
        objetoCliente.permissoes = arrayComTodasAsPermissoesAtivas.filter(
          (permissao) => sistemasAtivosNoCliente.includes(permissao.sistema)
        );
      } else {
        objetoCliente.permissoes = arrayComTodasAsPermissoesAtivas.filter(
          (permissao) =>
            permissao.sistema.toLowerCase() === sistemaAtualAcessado
        );
      }
    } else {
      if (sistemaAtualAcessado === "gerenciador") {
        objetoCliente.permissoes = ModulosDoSistema.filter((modulo) =>
          sistemasAtivosNoCliente.includes(modulo.sistema)
        );
      } else {
        objetoCliente.permissoes = ModulosDoSistema.filter(
          (modulo) => modulo.sistema.toLowerCase() === sistemaAtualAcessado
        );
      }

      console.log(objetoCliente);
    }
  };

  const adicionaClienteAoUsuario = () => {
    console.log(valoresParaAdicionarCliente);

    let todosValoresPreenchidos = Object.values(
      valoresParaAdicionarCliente
    ).every((valor) => valor !== "");

    let clienteAindaNaoAdicionadoNoArray = valuesUser.clientes.every(
      (item) => item.clienteId !== valoresParaAdicionarCliente.clienteId
    );

    if (todosValoresPreenchidos && clienteAindaNaoAdicionadoNoArray) {
      const novoObjetoCliente = {
        clienteId: valoresParaAdicionarCliente.clienteId,
        clienteAtivo: 1,
        autorizacaoAnexo: "",
        nomeArq: "",
        usuarioTipoId: valoresParaAdicionarCliente.tipoDeUsuario,
        permissoes: JSON.parse(JSON.stringify(ModulosDoSistema)),
        openedOptions: valoresParaAdicionarCliente.openedOptions,
      };
      const newArrayClientes = [novoObjetoCliente, ...valuesUser.clientes];

      gerenciaModulosHabilitadosPreviamente(novoObjetoCliente);

      console.log(newArrayClientes);

      setValuesUser((prev) => ({
        ...prev,
        clientes: [...newArrayClientes],
      }));

      setModulosSelecionadosDosClientes((prev) => [
        ...prev,
        {
          clienteId: valoresParaAdicionarCliente.clienteId,
          moduloSelecionado: novoObjetoCliente.permissoes[0].sistema,
        },
      ]);

      // const objetoAutorizacoes = {
      //   clienteId: valoresParaAdicionarCliente.clienteId,
      //   autorizacoes: ModulosDoSistema.map((modulo) => ({
      //     nomeSistema: modulo.sistema,
      //     autorizacao: "",
      //   })),
      // };

      // setAutorizacoesDosClientes((prev) => [...prev, objetoAutorizacoes]);
    } else if (!todosValoresPreenchidos) {
      toast.error("Preencha todos os campos.");
    } else {
      toast.error(
        "Esse cliente ja está adicionado a lista de clientes vinculados a esse usuário."
      );
    }
  };

  const handleAutocompleteChange = (newValue) => {
    console.log(newValue.id);
    setValoresParaAdicionarCliente((prevValues) => ({
      ...prevValues,
      clienteId: newValue.id,
    }));
  };

  const splitArrayMenusWithoutActionsIntoChunks = (array, chunkSize) => {
    let chunksArray = [];

    if (!array || array.length === 0) {
      return chunksArray;
    }

    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      chunksArray.push(chunk);
    }
    console.log(chunksArray);
    return chunksArray;
  };

  const handleToggleOptionsCliente = (indexCliente) => {
    console.log(valuesUser.clientes[indexCliente]);
    let newArray = valuesUser.clientes;
    newArray[indexCliente].openedOptions =
      !newArray[indexCliente].openedOptions;

    setValuesUser((prev) => ({
      ...prev,
      clientes: newArray,
    }));
  };

  const ativaAcaoDoMenu = (indexCliente, indexMenu, indexAcao) => {
    const newArray = MakeADeepCopyArray(valuesUser.clientes);
    console.log(newArray);
    const indexPermissao = findIndexPermissao(newArray[indexCliente]);

    newArray[indexCliente].permissoes[indexPermissao].menus[indexMenu].acoes[
      indexAcao
    ].acaoAtiva =
      !newArray[indexCliente].permissoes[indexPermissao].menus[indexMenu].acoes[
        indexAcao
      ].acaoAtiva;

    setValuesUser((prev) => ({
      ...prev,
      clientes: newArray,
    }));
  };

  const ativaMenu = (indexCliente, indexMenu) => {
    const newArray = MakeADeepCopyArray(valuesUser.clientes);
    const indexPermissao = findIndexPermissao(newArray[indexCliente]);

    newArray[indexCliente].permissoes[indexPermissao].menus[
      indexMenu
    ].menuAtivo =
      !newArray[indexCliente].permissoes[indexPermissao].menus[indexMenu]
        .menuAtivo;

    setValuesUser((prev) => ({
      ...prev,
      clientes: newArray,
    }));
  };

  const ativaMenuSemAcoes = (indexCliente, nomeMenu) => {
    const findIndexMenu = (arrayMenus) => {
      return arrayMenus.findIndex((menu) => menu.menu === nomeMenu);
    };

    const newArray = MakeADeepCopyArray(valuesUser.clientes);
    const indexPermissao = findIndexPermissao(newArray[indexCliente]);
    const indexMenu = findIndexMenu(
      newArray[indexCliente].permissoes[indexPermissao].menus
    );

    newArray[indexCliente].permissoes[indexPermissao].menus[
      indexMenu
    ].menuAtivo =
      !newArray[indexCliente].permissoes[indexPermissao].menus[indexMenu]
        .menuAtivo;

    setValuesUser((prev) => ({
      ...prev,
      clientes: newArray,
    }));
  };

  // const handleChangeAutorizacaoDoCliente = (
  //   objDeAutorizacaoDoCliente,
  //   file
  // ) => {
  //   console.log(objDeAutorizacaoDoCliente);
  //   console.log(file);
  //   const newArray = [...autorizacoesDosClientes];
  //   const indexCliente = newArray.findIndex(
  //     (item) => item.clienteId === objDeAutorizacaoDoCliente.clienteId
  //   );
  //   const indexAutorizacao = findIndexAutorizacaoDosSistemas(
  //     objDeAutorizacaoDoCliente
  //   );

  //   newArray[indexCliente].autorizacoes[indexAutorizacao].autorizacao = file;

  //   setAutorizacoesDosClientes(newArray);
  // };

  // const debug = () => {
  //   console.log(valuesUser);
  //   console.log(documentoAutorizativo);
  //   console.log(valuesUser.fotoPerfil.replace(/'/g, ""));
  //   console.log(currentClient);
  // };

  const handleSwitchModulo = (indexCliente) => {
    const newArray = valuesUser.clientes;
    const indexPermissao = findIndexPermissao(newArray[indexCliente]);

    newArray[indexCliente].permissoes[indexPermissao].sistemaAtivo =
      !newArray[indexCliente].permissoes[indexPermissao].sistemaAtivo;

    // console.log(newArray);

    setValuesUser((prev) => ({
      ...prev,
      clientes: [...newArray],
    }));
  };

  const handleDocAutorizativoDoCliente = async (indexCliente, file) => {
    const newArray = valuesUser.clientes;

    const base64Pdf = await pdfToBase64(file);
    console.log(base64Pdf);

    newArray[indexCliente].nomeArq = file.name;
    newArray[indexCliente].autorizacaoAnexo = "base64Pdf";

    console.log(newArray);

    setValuesUser((prev) => ({
      ...prev,
      clientes: [...newArray],
    }));
  };

  const handleChangeModuloSelecionado = (nomeSistema, clienteId) => {
    setModulosSelecionadosDosClientes((prev) => {
      let newArray = [...prev];
      let index = prev.findIndex((obj) => obj.clienteId === clienteId);

      newArray[index].moduloSelecionado = nomeSistema;

      return newArray;
    });
  };

  const handleEditInputFile = () => {
    inputFileRef.current.click();
  };

  return (
    <>
      <RowTituloAba>
        <CCol>
          <span>Cadastro do Usuário</span>
        </CCol>
      </RowTituloAba>
      {/* <button onClick={debug}>debug</button> */}

      <RowForm className="mt-1">
        <CCol>
          <PTitulosInputs>Cliente</PTitulosInputs>
          {moduloDoSistema === "Gerenciador" ? (
            <MyAutoComplete
              inputValue={inputAutocompleteClientes}
              setInputValue={setInputAutocompleteClientes}
              options={optionsClientes}
              onOptionSelect={handleAutocompleteChange}
              labelFormat={(option) => `${option.nome} - ${option.cnpj}`}
            />
          ) : (
            <FormControlListaDeInputs
              disabled
              value={`${currentClient.nomeFantasia} - ${FormatCnpjPaste(
                currentClient.cnpj
              )}`}
            />
          )}
        </CCol>
        <CCol md={3}>
          <PTitulosInputs>Tipo de Usuário</PTitulosInputs>
          <FloatingLabelInput
            tipo="input_select"
            new
            value={valoresParaAdicionarCliente.tipoDeUsuario}
            options={
              moduloDoSistema === "Gerenciador"
                ? tiposDeUsuario
                : tiposDeUsuarioInterno
            }
            height={"30px"}
            onSelect={(option) =>
              setValoresParaAdicionarCliente((prev) => ({
                ...prev,
                tipoDeUsuario: parseInt(option.value),
              }))
            }
          />
        </CCol>
        <CColButtonsAcoes md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <DivCentralizeIcon>
            <IconAdicionar onClick={() => adicionaClienteAoUsuario()} />
          </DivCentralizeIcon>
        </CColButtonsAcoes>
      </RowForm>
      {/* <button onClick={() => console.log(valuesUser)}>console</button> */}
      {valuesUser.clientes
        .filter((cliente) => cliente.clienteAtivo === 1)
        .map((cliente, indexCliente, arrayClientes) => (
          <React.Fragment key={indexCliente}>
            <RowForm
              $borderRadiusType={indexCliente === arrayClientes.length - 1 && 2}
              style={{
                paddingTop: "0px",
              }}
            >
              <CCol>
                <PInfosWithArrow
                  style={{ cursor: "pointer" }}
                  onClick={() => handleToggleOptionsCliente(indexCliente)}
                >
                  <div>{`${
                    optionsClientes.find(
                      (item) => item.id === cliente.clienteId
                    )?.nome
                  } - ${
                    optionsClientes.find(
                      (item) => item.id === cliente.clienteId
                    )?.cnpj
                  }`}</div>
                  <div>{`${
                    tiposDeUsuario.find(
                      (obj) => obj.value === cliente.usuarioTipoId
                    ).value
                  } - ${
                    tiposDeUsuario.find(
                      (obj) => obj.value === cliente.usuarioTipoId
                    ).label
                  }`}</div>
                </PInfosWithArrow>
              </CCol>
            </RowForm>

            {cliente.openedOptions && (
              <>
                <RowForm
                  style={{
                    paddingTop: "0",
                    paddingBottom: "0",
                    marginTop: "-6px",
                  }}
                >
                  <CCol>
                    <InputFile
                      // disabled={![null, undefined, ""].includes(cliente.nomeArq)}
                      ref={inputFileRef}
                      style={{ height: "32px" }}
                      placeholder="Documento Autorizativo"
                      accept=".pdf"
                      onChange={(e) => {
                        handleDocAutorizativoDoCliente(
                          indexCliente,
                          e.target.files[0]
                        );
                      }}
                      arquivo={cliente.nomeArq}
                    />
                  </CCol>
                  <CColButtonsAcoes md={1}>
                    <DivCentralizeIcon>
                      <IconEdit
                        style={{ marginLeft: "2px" }}
                        onClick={handleEditInputFile}
                      />
                    </DivCentralizeIcon>
                  </CColButtonsAcoes>
                </RowForm>
                <RowForm>
                  <CCol style={{ overflowX: "hidden" }}>
                    <DivModulosWithArrows
                      ModulosDoSistema={cliente.permissoes.map((item) => ({
                        sistema: item.sistema,
                      }))}
                      cliente={cliente}
                      handleChangeModuloSelecionado={
                        handleChangeModuloSelecionado
                      }
                    />
                  </CCol>
                  <CColButtonsAcoes
                    md={1}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <SwitchButton
                      style={{ position: "absolute", right: "15px" }}
                      $checked={
                        cliente.permissoes[findIndexPermissao(cliente)]
                          .sistemaAtivo
                      }
                      onClick={() => handleSwitchModulo(indexCliente)}
                    />
                  </CColButtonsAcoes>
                </RowForm>

                <RowForm
                  $borderRadiusType={
                    indexCliente === arrayClientes.length - 1 && 2
                  }
                  style={{ marginTop: "-15px", padding: "15px 17px" }}
                >
                  <RowSelecaoSistemas>
                    {cliente.permissoes[findIndexPermissao(cliente)]?.menus
                      .filter((menu) => menu.acoes.length > 0)
                      .map((menu, indexMenu, selfArray) => (
                        <CCol md={2} style={{ padding: "0px" }}>
                          <BoxOptionsSistemas>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ fontWeight: "600", color: "#515c70" }}
                              >
                                {menu.menu}
                              </span>
                              <SwitchButton
                                onClick={() =>
                                  ativaMenu(indexCliente, indexMenu)
                                }
                                $checked={menu.menuAtivo}
                              />
                            </div>
                            <div style={{ marginTop: "5px" }}>
                              {menu.acoes.map((acao, indexAcao) => (
                                <LabelCheckStyled
                                  key={indexAcao}
                                  checked={acao.acaoAtiva}
                                  label={acao.acao}
                                  onChange={() =>
                                    ativaAcaoDoMenu(
                                      indexCliente,
                                      indexMenu,
                                      indexAcao
                                    )
                                  }
                                />
                              ))}
                            </div>
                          </BoxOptionsSistemas>
                        </CCol>
                      ))}
                    {splitArrayMenusWithoutActionsIntoChunks(
                      cliente.permissoes[
                        findIndexPermissao(cliente)
                      ]?.menus.filter(
                        (menu, indexMenu) => menu.acoes.length === 0
                      ),
                      5
                    ).map((menus, indexMenus) => (
                      <CCol>
                        {menus.map((menu, indexMenu) => (
                          <LabelCheckStyled
                            onChange={() =>
                              ativaMenuSemAcoes(indexCliente, menu.menu)
                            }
                            checked={menu.menuAtivo}
                            label={menu.menu}
                          />
                        ))}
                      </CCol>
                    ))}

                    {/* <InputFile
                    arquivo={
                      autorizacoesDosClientes.find(
                        (obj) => obj.clienteId === cliente.clienteId
                      )?.autorizacoes[
                        findIndexAutorizacaoDosSistemas(
                          autorizacoesDosClientes.find(
                            (obj) => obj.clienteId === cliente.clienteId
                          )
                        )
                      ].autorizacao.name
                    }
                    onChange={(e) =>
                      handleChangeAutorizacaoDoCliente(
                        autorizacoesDosClientes.find(
                          (obj) => obj.clienteId === cliente.clienteId
                        ),
                        e.target.files[0]
                      )
                    }
                    
                  /> */}
                  </RowSelecaoSistemas>
                </RowForm>
              </>
            )}
          </React.Fragment>
        ))}
    </>
  );
};

export default CadastroListaClientes;
