import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowAninhada, RowForm } from "../../../../components/Grid/CCol";
import InputData from "../../../../components/Grid/InputData";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { useFetchData } from "../../../../hooks/useFetchData";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import styled from "styled-components";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import HistoricoAba from "./HistoricoAba";
import ProdutoEAquisicaoAba from "./ProdutoEAquisicaoAba";
import ComplementoAba from "./ComplementoAba";
import SaldosAba from "./SaldosAba";

const CColNumeroAndEVB = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const RowInfosAcao = styled(RowAninhada)`
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 5px 0px;

  p {
    margin: 3px;
    color: #888a8e;
    font-weight: 500;
    font-size: 14px;
  }

  p > span {
    color: #515c70;
  }
`;

const NotaDeEmpenho = () => {
  const { data: cpfList } = useFetchData(`pessoas`);
  const [optionsCpf, setOptionsCpf] = useState([]);
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });

  useEffect(() => {
    if (cpfList) {
      console.log(cpfList[0].dados.cpf);
      setOptionsCpf(
        cpfList.map((item) => ({
          cpf: item.dados.cpf ? FormatCpfPaste(item.dados.cpf) : "sem cpf",
          nome: item.dados.nomeCompleto,
          id: item.dados.id,
        }))
      );
    }
  }, [cpfList]);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Nota de Empenho"}
        PaginaSubtitulo={"Cadastro"}
      />

      <RowForm className="mt-1">
        <CColNumeroAndEVB md={1}>
          <FloatingLabelInput disabled label="Número" placeholder="Número" />
        </CColNumeroAndEVB>
        <CCol>
          <MyAutoComplete
            labelInput="Fornecedor"
            options={optionsCpf}
            labelFormat={(item) => `${item.nome} - ${item.cpf}`}
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            tipo="input_select"
            new
            placeholder="Licitação"
            options={[{ label: "Sim", value: "Sim" }]}
          />
        </CCol>
        <CCol md={2}>
          <InputData />
        </CCol>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            labelInput="Ficha"
            options={optionsCpf}
            labelFormat={(item) => `${item.nome} - ${item.cpf}`}
            style={{ height: "37px" }}
          />
        </CCol>
      </RowForm>

      <RowForm
        style={{ marginTop: "-14px", paddingRight: 17, paddingLeft: 17 }}
      >
        <RowInfosAcao>
          <CCol md={7}>
            <p>
              Unidade Orçamentária:{" "}
              <span>02.050 - Secretaria Municipal de Educação</span>
            </p>
            <p>
              Programa:{" "}
              <span>
                2025 - Manutenção dos Serviços da Secretaria Municipal de
                Educação
              </span>
            </p>
            <p>
              Elemento de Despesa:{" "}
              <span>
                3.3.90.39 - OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA
              </span>
            </p>
            <p>
              Fonte de Recursos:{" "}
              <span>500 - Recursos não Vinculados de Impostos</span>
            </p>
          </CCol>
          <CCol>
            <p>
              Função: <span>08 - Assistência Social</span>
            </p>
            <p>
              Subfunção: <span>244 - Assistência Comunitária</span>
            </p>
            <p>
              Exercício - CNP: <span>1 - Recursos do Exercício Corrente</span>
            </p>
          </CCol>
        </RowInfosAcao>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol md={8}>
          <FloatingLabelInput
            tipo="input_select"
            new
            placeholder="Exercício / Fonte de Recursos - FR / Complementação Orçamentária -CO"
            options={[{ label: "Teste", value: "Teste" }]}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            placeholder="Subelemento de Despesa"
            options={[{ label: "Teste", value: "Teste" }]}
          />
        </CCol>
      </RowForm>

      <RowForm
        style={{ marginTop: "-14px", paddingRight: 17, paddingLeft: 17 }}
      >
        <RowInfosAcao>
          <CCol>
            <p style={{ color: "#515c70" }}>
              Compreende/Registra as variações patrimoniais diminutivas
              proveniente da utilização de serviços de terceiros não
              classificados em itens...
            </p>
          </CCol>
        </RowInfosAcao>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol md={4}>
          <FloatingLabelInput
            tipo="input_select"
            new
            placeholder="Tipo de Meta"
            options={[{ label: "Teste", value: "Teste" }]}
          />
        </CCol>
        <CCol md={4}>
          <FloatingLabelInput
            tipo="input_select"
            new
            placeholder="Tipo de Empenho"
            options={[{ label: "Teste", value: "Teste" }]}
          />
        </CCol>
        <CCol>
          <InputFormatRealBrasileiro iconReal />
        </CCol>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={["Histórico", "Produto e Aquisição", "Complemento", "Saldos"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Histórico" && <HistoricoAba />}
      {abaSelecionada.nomeAba === "Produto e Aquisição" && (
        <ProdutoEAquisicaoAba />
      )}
      {abaSelecionada.nomeAba === "Complemento" && <ComplementoAba />}
      {abaSelecionada.nomeAba === "Saldos" && <SaldosAba />}

      <div className="p-3"></div>
    </Container>
  );
};

export default NotaDeEmpenho;
