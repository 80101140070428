import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const IconNavbarSubtitulo = styled(Icon)`
  position: relative;
  left: -13px;
  top: -1px;
  ${({ checkSidebar }) => (!checkSidebar ? "" : "left: 0px")}
`;

const NavbarSubtituloIcon = ({ nomeDoModulo = "", checkSidebar }) => {
  const [subtituloInfos, setSubtituloInfos] = useState({
    icon: "",
    titulo: "",
  });

  useEffect(() => {
    if (nomeDoModulo) {
      handleModulo();
    }

    //eslint-disable-next-line
  }, [nomeDoModulo]);

  const handleModulo = () => {
    let icon = "";
    let titulo = "";

    switch (nomeDoModulo.toLowerCase()) {
      case "gerenciador":
        icon = "fa-solid:user-cog";
        titulo = "Gerenciador";
        break;
      case "planejamento":
        icon =
          "streamline:interface-content-book-edit-pencil-content-write-notebook-book-edit-composition-creation";
        titulo = "Planejamento";
        break;
      case "contabilidade":
        icon = "carbon:calculation-alt";
        //icon = "material-symbols:donut-small-outline";
        titulo = "Contabilidade";
        break;
      default:
        icon = "fa-solid:user-cog";
        titulo = "Gerenciador";
        break;
    }

    setSubtituloInfos((prev) => ({
      ...prev,
      icon,
      titulo,
    }));
  };

  return (
    <>
      <IconNavbarSubtitulo
        checkSidebar={checkSidebar}
        icon={subtituloInfos.icon}
        color="white"
        width="24"
        height="24"
      />
      <span style={{ fontSize: "16px", top: "1px" }}>
        {subtituloInfos.titulo}
      </span>
    </>
  );
};

export default NavbarSubtituloIcon;
