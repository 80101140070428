import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { CCol } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { ToastContainer, toast } from "react-toastify";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import Procedimentos from "./Procedimentos";
import EstruturaReceita from "./EstruturaReceita";
import { FormatContaNatReceita } from "../../../../utils/FormatacaoDeDados/FormatContaNatReceita";
import { contaNatReceitaAutoPreencher } from "../../../../utils/FormatacaoDeDados/ContaNatRececeitaAutoPreencher";
import api from "../../../../utils/api";
import { useParams } from "react-router-dom";
import DesdobramentoNatRec from "./DesdobramentoNatRec";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";

export const OptionsStyle = styled.span`
  color: #515c70;
  line-height: 35px;
  font-size: 0.8em;
  margin-left: 20px;
  cursor: pointer;
  ${({ selecionado }) =>
    selecionado &&
    `
    font-weight: 600;
    border-bottom: 2px solid #515c70;
  `}
`;

function NaturezaDaReceita() {
  const { currentYear } = useCurrentYear();
  const { codigo: paramsCodigo } = useParams();
  const [abaSelecionada, setAbaSelecionada] = useState(0);
  const [arrayEstruturaDaConta, setArrayEstruturaDaConta] = useState([]);
  const [teclaPressionada, setTeclaPressionada] = useState(0);
  const { currentClient } = useContext(CurrentClientContext);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [codigoConta, setCodigoConta] = useState("");
  const [atributosConta, setAtributosConta] = useState({
    descricao: "",
    funcao: "",
    valorizavel: "",
  });
  const [valuesNaturezaDaReceita, setValuesNaturezaDaReceita] = useState({
    naturezaDaReceitaId: 0,
    clienteId: currentClient.clienteId,
    procedimentos: [
      {
        passo: 1,
        registros: [],
      },
      {
        passo: 2,
        registros: [],
      },
      {
        passo: 3,
        registros: [],
      },
      {
        passo: 4,
        registros: [],
      },
    ],
    desdobramento: [],
  });
  const [arrayDesdobramento, setArrayDesdobramento] = useState([
    // {
    //   codigo: "0",
    //   titulo: "teste",
    // },
  ]);

  useEffect(() => {
    if (paramsCodigo) {
      setCodigoConta(paramsCodigo.slice(0, 14));
    }
  }, [paramsCodigo]);

  const adicionarNaturezaDaReceita = () => {
    let valuesToPut = arrayDesdobramento.map((item) => {
      const { disabled, ...resto } = item;

      return resto;
    });

    console.log(valuesToPut);

    api
      .put("naturezaDaReceita/desdobramento/atualiza", valuesToPut)
      .then((resp) => {
        toast.success("Desdobramentos criados!");
        limpaValoresNaturezaDaReceita();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Algo deu errado, por favor, tente mais tarde.");
        }
      });
  };

  const fetchContaFilter = () => {
    setLoadingFetch(true);
    const valorFormated = contaNatReceitaAutoPreencher(codigoConta);
    console.log(valorFormated);

    api
      .get(
        `naturezaDaReceita/${currentClient.clienteId}/valoresEstrutura/${currentYear}/${valorFormated}`
      )
      .then((resp) => {
        const data = resp.data.valorTransformado;
        console.log(data);

        if (data.length === 0) {
          setValuesNaturezaDaReceita((prevValues) => ({
            ...prevValues,
            naturezaDaReceitaId: 0,
          }));
          throw new Error("Array vazio");
        } else {
          const arrayLength = data.length;
          const contaAtual = data[arrayLength - 1];
          console.log(contaAtual.naturezaDaReceita.nr);
          console.log(contaAtual.desdobramentos);
          const novoArrayEstruturaDaConta = data.map((item) => ({
            nivel: item.nivel,
            conta: item.naturezaDaReceita.nr,
            titulo: item.naturezaDaReceita.especificacao,
          }));

          console.log(novoArrayEstruturaDaConta);
          setArrayEstruturaDaConta(novoArrayEstruturaDaConta);
          setAtributosConta((prevValues) => ({
            ...prevValues,
            descricao: contaAtual.naturezaDaReceita.especificacao,
            funcao: contaAtual.naturezaDaReceita.descricao,
            valorizavel: contaAtual.naturezaDaReceita.valorizavel,
          }));
          setValuesNaturezaDaReceita((prev) => ({
            ...prev,
            naturezaDaReceitaId: contaAtual.naturezaDaReceita.id,
          }));

          if (contaAtual.desdobramentos.length > 0) {
            setArrayDesdobramento(
              contaAtual.desdobramentos.map((item) => ({
                id: item.id,
                clienteId: item.clienteId,
                naturezaId: item.naturezaId,
                codigo: item.codigo,
                titulo: item.titulo,
                disabled: true,
                ano: currentYear,
              }))
            );
          }

          if (contaAtual.procedimentos.Procedimento1) {
            const dataPar1 = contaAtual.procedimentos.Procedimento1.Par1;

            let procedimentosValues = [
              ...valuesNaturezaDaReceita.procedimentos,
            ];
            procedimentosValues[0].registros = [
              {
                contaContabil: dataPar1[0].contaContabil,
                titulo: dataPar1[0].titulo,
                origem: dataPar1[0].origem,
                naturezaDaInfo: dataPar1[0].naturezaDaInfo,
                par: 1,
              },
              {
                contaContabil: dataPar1[1].contaContabil,
                titulo: dataPar1[1].titulo,
                origem: dataPar1[1].origem,
                naturezaDaInfo: dataPar1[1].naturezaDaInfo,
                par: 1,
              },
            ];

            setValuesNaturezaDaReceita((prev) => ({
              ...prev,
              procedimentos: [...procedimentosValues],
            }));
          } else {
            setValuesNaturezaDaReceita((prev) => ({
              ...prev,
              procedimentos: [
                {
                  passo: 1,
                  registros: [],
                },
                {
                  passo: 2,
                  registros: [],
                },
                {
                  passo: 3,
                  registros: [],
                },
                {
                  passo: 4,
                  registros: [],
                },
              ],
            }));
          }
        }

        setLoadingFetch(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.message === "Array vazio") {
          toast.error(
            "Não foi encontrado nenhum registro com essa combinação númerica"
          );
        } else {
          toast.error("Algo deu errado. Por favor, tente mais tarde.");
        }
        setLoadingFetch(false);
      });
  };

  useEffect(() => {
    console.log("effect");
    if (codigoConta.length > 0 && codigoConta.length !== 9) {
      fetchContaFilter();
    }

    if (codigoConta.length === 0) {
      setValuesNaturezaDaReceita((prevValues) => ({
        ...prevValues,
        naturezaDaReceitaId: 0,
      }));

      setAtributosConta((prevValues) => ({
        ...prevValues,
        descricao: "",
        funcao: "",
        valorizavel: "",
      }));
    }
    //eslint-disable-next-line
  }, [codigoConta]);

  const deletaItemEstrutura = () => {
    console.log(`valor que o caba tem que deletar: ${codigoConta}`);
    let novoArray = [...arrayEstruturaDaConta];
    const contaComparativa = contaNatReceitaAutoPreencher(codigoConta);
    if (novoArray.some((item) => item.conta === contaComparativa)) {
      novoArray.pop();
      console.log(novoArray);
      setArrayEstruturaDaConta(novoArray);
    } else {
      console.log("esse dado nn existe no array");
    }
  };

  const limpaValoresNaturezaDaReceita = () => {
    setValuesNaturezaDaReceita((prevValues) => ({
      ...prevValues,
      naturezaDaReceitaId: 0,
      clienteId: currentClient.clienteId,
      procedimentos: [
        {
          passo: 1,
          registros: [],
        },
        {
          passo: 2,
          registros: [],
        },
        {
          passo: 3,
          registros: [],
        },
        {
          passo: 4,
          registros: [],
        },
        {
          passo: 5,
          registros: [],
        },
      ],
    }));

    setCodigoConta("");

    setArrayEstruturaDaConta([]);

    setArrayDesdobramento([]);
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        withRow={true}
        NomePaginaTitulo={"Natureza da Receita"}
        PaginaSubtitulo={"Cadastro"}
        PaginaConsulta={"/planejamento/cadastro/natureza-da-receita/consulta"}
        DashboardPage={"/planejamento/cadastro/natureza-da-receita/dashboard"}
        ButtonSaveFunction={() => {
          adicionarNaturezaDaReceita();
        }}
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        <CCol md={2}>
          <FloatingLabelInput
            placeholder="Código"
            label="Código"
            value={codigoConta}
            readOnly={loadingFetch ? true : false}
            onKeyDown={(e) => {
              if (
                e.keyCode === 8 &&
                e.target.selectionStart < e.target.value.length
              ) {
                e.preventDefault(); // Impede a exclusão
              }

              setTeclaPressionada(e.keyCode);
            }}
            onChange={(e) => {
              FormatContaNatReceita(e.target);
              setCodigoConta(e.target.value);
              if (
                teclaPressionada === 8 &&
                e.target.selectionStart >= e.target.value.length
              ) {
                deletaItemEstrutura();
              }
            }}
          />
        </CCol>
        <CCol md={8}>
          <FloatingLabelInput
            disabled
            placeholder="Descrição"
            value={atributosConta.descricao}
            label="Descrição"
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            placeholder="Valorizavel"
            value={atributosConta.valorizavel}
            label="Valorizavel"
          />
        </CCol>
      </Row>
      <Row className="row_form" style={{ marginTop: "-14px" }}>
        <CCol md={12}>
          <FloatingLabelInput
            as="textarea"
            style={{ height: "38px", minHeight: "38px" }}
            tipo={"text_area"}
            disabled
            placeholder="Função"
            label="Função"
            value={atributosConta.funcao}
          />
        </CCol>
      </Row>
      <Row>
        <Col
          className="d-flex align-items-center row_endereco_aba mt-1"
          md={12}
        >
          <OptionsStyle
            selecionado={abaSelecionada === 0}
            onClick={() => setAbaSelecionada(0)}
          >
            Estrutura
          </OptionsStyle>
          {atributosConta.valorizavel === "SIM" && (
            <OptionsStyle
              selecionado={abaSelecionada === 1}
              onClick={() => setAbaSelecionada(1)}
            >
              Procedimentos Contábeis
            </OptionsStyle>
          )}

          {codigoConta.length === 14 &&
            codigoConta[13] === "1" &&
            atributosConta.valorizavel === "SIM" && (
              <OptionsStyle
                selecionado={abaSelecionada === 2}
                onClick={() => setAbaSelecionada(2)}
              >
                Desdobramento
              </OptionsStyle>
            )}
        </Col>
      </Row>
      {abaSelecionada === 0 && (
        <EstruturaReceita arrayEstruturaDaConta={arrayEstruturaDaConta} />
      )}
      {abaSelecionada === 1 && (
        <Procedimentos
          valuesNaturezaDaReceita={valuesNaturezaDaReceita}
          setValuesNaturezaDaReceita={setValuesNaturezaDaReceita}
          atributosConta={atributosConta}
        />
      )}
      {abaSelecionada === 2 && (
        <DesdobramentoNatRec
          arrayDesdobramento={arrayDesdobramento}
          setArrayDesdobramento={setArrayDesdobramento}
          valuesNaturezaDaReceita={valuesNaturezaDaReceita}
          clienteId={currentClient.clienteId}
        />
      )}
    </Container>
  );
}

export default NaturezaDaReceita;
