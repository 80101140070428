import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

const limiteDeFavoritos = 10;

export const useFavoritesLocalStorage = (selectBoxesOptionsAbaPrincipal,localStorageKey, setOpenedModals, nameModal) => {
  const [favoritosOptions, setFavoritosOptions] = useState([]);
  const [dataFavorites, setDataFavorites] = useState([]);

  const selectedBoxChecked = () => {
    const selectedBox = selectBoxesOptionsAbaPrincipal
      .map((item) => ({
        opcao: item.opcao,
        checkBoxes: item.checkBoxes.filter((checkbox) => checkbox.selected),
      }))
      .filter((item) => item.checkBoxes.length > 0);
    return selectedBox;
  };

  const getFavoriteLocalStorage = useCallback(() => {
    console.log('chamou getFavoriteLocalStorage');
    
    const data = localStorage.getItem(localStorageKey);
    const favorites = data ? JSON.parse(data) : [];

    console.log(favorites);
    return favorites;
  },[localStorageKey])

  const setFavoritesLocalStorage = (favoriteList) => {
    const favoritesLocalStorage = getFavoriteLocalStorage();
    console.log(favoritesLocalStorage);

    if (favoritesLocalStorage.length >= limiteDeFavoritos) {
      toast.error("Você atingiu o limite de 10 favoritos");
      return;
    }

    if (!favoritesLocalStorage.length) {
      localStorage.setItem(localStorageKey, JSON.stringify([favoriteList]));
      const newOptions = favoritesLocalStorage.map((item) => ({
        label: item.favoriteName,
        value: item.favoriteName,
      }));
      setFavoritosOptions(newOptions);
      toast.success("Favorito salvo com sucesso");
      setOpenedModals((prev) =>
        prev.filter((item) => item.tituloModal !== nameModal)
      );
      return;
    }

    const favoriteNameHasExists = favoritesLocalStorage.some(
      (item) => item.favoriteName === favoriteList.favoriteName
    );

    if (favoriteNameHasExists) {
      toast.error("Já existe um favorito com esse nome");
      return;
    }

    const newFavoriteList = [...favoritesLocalStorage, favoriteList];
    const newOptions = newFavoriteList.map((item) => ({
      label: item.favoriteName,
      value: item.favoriteName,
    }));
    setFavoritosOptions(newOptions);
    localStorage.setItem(localStorageKey, JSON.stringify(newFavoriteList));
    toast.success("Favorito salvo com sucesso");
    setOpenedModals((prev) =>
      prev.filter((item) => item.tituloModal !== nameModal)
    );
  };

  const handleSaveFavorite = (value) => {
    
    console.log(value);
    if (!value) {
      console.log("sem valor input");
      toast.info("Informe o nome do favorito");
      return;
    }

    const selectedBoxes = selectedBoxChecked();
    console.log(selectedBoxes);
    if (!selectedBoxes.length) {
      toast.info("Selecione itens para favoritar");
      console.log("selecione itens para favoritar");
      return;
    }

    const favorite = {
      favoriteName: value,
      favoriteItens: selectedBoxes,
    };
    console.log(favorite);
    setFavoritesLocalStorage(favorite);
  };

  const atualizarCheckboxes = (optionValue) => {
    const favoriteSelected = dataFavorites.find(
      (item) => item.favoriteName === optionValue
    );

    const checkBoxes = selectBoxesOptionsAbaPrincipal.map((item) => {
      const correspondingFavorite = favoriteSelected.favoriteItens.find(
        (favItem) => favItem.opcao === item.opcao
      );

      if (correspondingFavorite) {
        const updatedCheckBoxes = item.checkBoxes.map((checkBox) => {
          const isFavoriteCheckBox = correspondingFavorite.checkBoxes.some(
            (favCheckBox) =>
              favCheckBox.label === checkBox.label &&
              favCheckBox.value === checkBox.value
          );

          return {
            ...checkBox,
            selected: isFavoriteCheckBox ? false : checkBox.selected,
          };
        });

        return {
          ...item,
          checkBoxes: updatedCheckBoxes,
        };
      }

      return item;
    });
    return checkBoxes;
  };

  const handleDeleteFavoriteStorage = (optionValue) => {
    const optionsFavoriteFiltered = dataFavorites.filter(
      (item) => item.favoriteName !== optionValue
    );

    const newOptionsFavorites = optionsFavoriteFiltered.map((item) => ({
      label: item.favoriteName,
      value: item.favoriteName,
    }));

    setDataFavorites(optionsFavoriteFiltered);
    setFavoritosOptions(newOptionsFavorites);

    localStorage.setItem(
      localStorageKey,
      JSON.stringify(optionsFavoriteFiltered)
    );

    const ckeckBoxesAtualizados = atualizarCheckboxes(optionValue);
    toast.success("Favorito deletado com sucesso");
    return ckeckBoxesAtualizados
  };

  useEffect(() => {
    const data = getFavoriteLocalStorage();
    if (data.length) {
      console.log(data);
      setDataFavorites(data);
      const newOptionsFavorites = data.map((item) => ({
        label: item.favoriteName,
        value: item.favoriteName,
      }));
      console.log(newOptionsFavorites);
      setFavoritosOptions(newOptionsFavorites);
    }
  }, [getFavoriteLocalStorage]);
  
  return {
    handleSaveFavorite,
    favoritosOptions,
    dataFavorites,
    handleDeleteFavoriteStorage
  }
}