import { Text, View } from "@react-pdf/renderer";
import { stylesPdf } from "./StyledPdfRelatorio";

export const PdfColunas = ({
  colName,
  textBar = "",
  styledCol,
  hideBar = false,
}) => {
  return (
    <View style={[stylesPdf.coluna, styledCol, { margin: "0 -3px" }]}>
      <View style={[stylesPdf.divTituloColuna,{paddingHorizontal: 8}]}>
        <Text
          style={[
            stylesPdf.textTituloColuna,
            // { paddingLeft: styledCol.textAlign ? 0 : "10px" },
          ]}
        >
          {colName}
        </Text>
      </View>
      {!hideBar && (
        <View style={stylesPdf.subDivColunas}>
          {textBar && <Text style={stylesPdf.textBar}>{textBar}</Text>}
        </View>
      )}
    </View>
  );
};
