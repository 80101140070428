import React, { useContext } from "react";
import { Row } from "react-bootstrap";
import { CCol } from "../../../components/Grid/CCol";
import { Icon } from "@iconify/react/dist/iconify.js";
import styled from "styled-components";
import IconModulosHeader from "../../../components/SvgComponents/IconModulosHeader";
import { AuthContext } from "../../../contexts/AuthContext";

const ChatInput = styled.input`
  width: 100%;
  padding: 10px 30px 10px 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
`;

const InputContainer = styled.div`
  position: relative;
`;

const Savebot = () => {
  const { userAuthData } = useContext(AuthContext);
  const nameUser =
    Object.keys(userAuthData).length > 0
      ? userAuthData.nome.split(" ")[0]
      : "N/D";
  return (
    <>
      <Row className="row_form mt-1">
        <Row style={{ minHeight: "400px" }}>
          <CCol
            style={{
              marginLeft: "120px",
              marginRight: "120px",
              marginTop: "40px",
              fontWeight: "600",
            }}
          >
            <IconModulosHeader
              savebot
              width={28}
              height={28}
              style={{
                transform: "translate(0px, 0px)",
                marginLeft: "8px",
                marginRight: "15px",
              }}
            />
            <span>Olá {nameUser}, como posso te ajudar?</span>
          </CCol>
        </Row>
      </Row>
      <Row
        className="row_form mt-1"
        style={{ paddingLeft: "80px", paddingRight: "80px" }}
      >
        <CCol
          md={1}
          style={{ width: "fit-content" }}
          className="d-flex align-items-center"
        >
          <IconModulosHeader
            savebot
            width={28}
            height={28}
            style={{
              transform: "translate(0px, 0px)",
              marginLeft: "8px",
              marginRight: "15px",
            }}
          />
          <span style={{ fontSize: "18px", color: "#6C7585" }}>SaveBot</span>
        </CCol>
        <CCol>
          <InputContainer>
            <ChatInput type="text" placeholder="Mensagem" />
            <IconContainer>
              <Icon
                icon="gg:arrow-up-r"
                style={{
                  transform: "scaleX(2.00) scaleY(2.00)",
                  marginRight: "20px",
                  color: "#B1B0B6",
                  cursor: "pointer",
                }}
              />
              <Icon
                icon="heroicons:microphone-solid"
                style={{
                  transform: "scaleX(1.80) scaleY(1.80)",
                  color: "#B1B0B6",
                  cursor: "pointer",
                }}
              />
            </IconContainer>
          </InputContainer>
        </CCol>
      </Row>
    </>
  );
};

export default Savebot;
