import React from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";

export const ReceitaExtraAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Receita Extra</span>
        </div>
      </RowTituloAba>

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol md={2}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Exercício"
          />
        </CCol>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Guia da Receita"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            label="Saldo da Guia"
            placeholder="Saldo da Guia"
          />
        </CCol>
      </RowForm>
    </>
  );
};
