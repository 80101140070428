export const formatValue = (valor) => {
  if (valor) {
    let valorNumerico = Math.abs(parseFloat(valor));
    if (isNaN(valorNumerico)) return null;
    let valorStr = valorNumerico.toFixed(2).toString();
    let [parteInteira, parteDecimal] = valorStr.split(".");
    parteInteira = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return `${parteInteira},${parteDecimal}`;
  } else {
    return null;
  }
};
