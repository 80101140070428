export const contaNatReceitaAutoPreencher = (value) => {
  const valueContaCleaned = value.replace(/[^0-9]/g, "");
  const mask = "#.#.#.#.##.#.#";
  let contaFormated = "";
  let index = 0;

  for (let i = 0; i < mask.length && index <= valueContaCleaned.length; i++) {
    if (index < valueContaCleaned.length) {
      if (mask[i] === "#") {
        contaFormated += valueContaCleaned[index];
        index++;
      } else {
        contaFormated += mask[i];
      }
    } else {
      const restante = mask.substring(i).replace(/#/g, "0");
      contaFormated += restante;
      index++;
    }
  }

  return contaFormated;
};
// export const contaNatReceitaAutoPreencher = (value) => {
//   const valueContaCleaned = value.replace(/[^0-9]/g, "");
//   const mask = "#.#.#.#.##.#.#";
//   let contaFormated = "";
//   let index = 0;

//   for (let i = 0; i < mask.length && index <= valueContaCleaned.length; i++) {
//     if (index < valueContaCleaned.length) {
//       if (mask[i] === "#") {
//         if (mask[i + 1] === "#" && mask[i - 1] === '.') {
//           // Substitui os dois dígitos de uma vez
//           contaFormated += valueContaCleaned.substring(index, index + 2);
//           index += 2;
//           i++;
//         } else {
//           contaFormated += valueContaCleaned[index];
//           index++;
//         }
//       } else {
//         contaFormated += mask[i];
//       }
//     } else {
//       const restante = mask.substring(i).replace(/#/g, "0");
//       contaFormated += restante;
//       index++;
//     }
//   }

//   return contaFormated;
// };