import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { LabelCheckLOA } from "./StyledCompsRelatorio";
import InputSearch from "../Inputs/InputSearch";
import FloatingLabelInput from "../Grid/FloatingLabelInput";

const AbaOpcoesRelatorio = ({
  labelsCheckAbaOpcoes,
  setLabelsCheckAbaOpcoes,
}) => {
  const [allSelected, setAllSelected] = useState(false);
  const [options, setOptions] = useState([]);
  const [valueInputSearch, setValueInputSearch] = useState("");
  const [optionSelectOptions, setOptionSelectOptions] = useState(null);

  const gerenciarSelects = (opcaoString, indexCheckbox) => {
    let newArray = labelsCheckAbaOpcoes.map((item) => {
      if (item.opcao === opcaoString) {
        return {
          ...item,
          checkBoxes: item.checkBoxes.map((checkbox, idx) =>
            idx === indexCheckbox
              ? { ...checkbox, selected: !checkbox.selected }
              : checkbox
          ),
        };
      }
      return item;
    });

    setLabelsCheckAbaOpcoes(newArray);
  };

  const checkAllBoxes = (checked) => {
    console.log(checked);

    let newArray = [...labelsCheckAbaOpcoes];

    newArray.forEach((item) => {
      item.checkBoxes.forEach((checkbox) => {
        if (item.opcao === optionSelectOptions) {
          checkbox.selected = checked;
        }
      });
    });

    console.log(newArray);
    setLabelsCheckAbaOpcoes(newArray);
  };

  useEffect(() => {
    const allChecked = labelsCheckAbaOpcoes.every((option) =>
      option.checkBoxes.every((checkbox) =>
        option.opcao === optionSelectOptions ? checkbox.selected : true
      )
    );

    setAllSelected(allChecked);
  }, [labelsCheckAbaOpcoes, optionSelectOptions]);

  useEffect(() => {
    if (labelsCheckAbaOpcoes) {
      const newOptions = labelsCheckAbaOpcoes.reduce((acc, item) => {
        if (!acc.find((option) => option.label === item.opcao)) {
          acc.push({ label: item.opcao, value: item.opcao });
        }
        return acc;
      }, []);
      setOptions(newOptions);
    }
  }, [labelsCheckAbaOpcoes]);

  useEffect(() => {
    if (options && !optionSelectOptions) {
      setOptionSelectOptions(options.find((item) => item.value !== "")?.value);
    }
  }, [optionSelectOptions, options]);

  return (
    <>
      <Col
        style={{
          padding: "0 10px",
          marginTop: "10px",
        }}
        md={12}
      >
        <FloatingLabelInput
          new
          tipo="input_select"
          label="Opção por..."
          options={[{ label: "Opção por...", value: null }, ...options]}
          value={optionSelectOptions}
          onSelect={(option) => {
            console.log(option.value);
            setOptionSelectOptions(option.value);
          }}
        />
        <InputSearch
          value={valueInputSearch}
          onChange={(e) => setValueInputSearch(e.target.value)}
          style={{ marginTop: "10px" }}
        />
        <LabelCheckLOA
          label={"Todos Selecionados"}
          checked={allSelected}
          onChange={(e) => {
            checkAllBoxes(e.target.checked);
          }}
        />
        {options?.map(
          (option, index) =>
            optionSelectOptions === labelsCheckAbaOpcoes[index].opcao && (
              <React.Fragment key={index}>
                {labelsCheckAbaOpcoes[index].checkBoxes
                  .filter((checkBox) =>
                    checkBox.label
                      .toLowerCase()
                      .includes(valueInputSearch.toLowerCase())
                  )
                  .map((checkBox, checkBoxIndex) => (
                    <LabelCheckLOA
                      key={checkBoxIndex}
                      label={
                        labelsCheckAbaOpcoes.length === options.length
                          ? checkBox.label
                          : checkBox.label ===
                            labelsCheckAbaOpcoes[index + 1].checkBoxes[
                              checkBoxIndex
                            ].label
                          ? checkBox.label
                          : `${checkBox.label} / ${
                              labelsCheckAbaOpcoes[index + 1].checkBoxes[
                                checkBoxIndex
                              ].label
                            }`
                      }
                      value={checkBox.value}
                      checked={checkBox.selected}
                      onChange={(e) => {
                        gerenciarSelects(option.label, checkBoxIndex);
                        console.log(e.target.value);
                      }}
                    />
                  ))}
              </React.Fragment>
            )
        )}
      </Col>
    </>
  );
};

export default AbaOpcoesRelatorio;
