import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../../components/Grid/InputSvg";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
// import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import { useFetchData } from "../../../../hooks/useFetchData";
import { Link } from "react-router-dom";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";

function ConsultaFonteRecursos() {
  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itensFiltrados, setItensFiltrados] = useState([]);
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();

  const { data: dataArrayFontesDeRecurso, loading } = useFetchData(
    `/fonteDeRecurso/por-ano/${currentYear}`,
    currentClient.clienteId
  );

  axios.defaults.headers.common["Authorization"] = null;

  // const [modalOpened, setModalOpened] = useState(false);
  // const [dataDelete, setDataDelete] = useState({});

  useEffect(() => {
    if (dataArrayFontesDeRecurso) {
      setItens(dataArrayFontesDeRecurso);
    }
  }, [dataArrayFontesDeRecurso]);

  const reverseMask = (value) => {
    let noDots = value.replace(/\./g, "");
    return noDots;
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = itens.filter((item) => {
      return (
        item.titulo.toLowerCase().includes(value.toLowerCase()) ||
        reverseMask(item.conta.toString()).includes(value) ||
        item.conta.toString().includes(value)
      );
    });
    setItensFiltrados(filteredItems);
    setCurrentPage(0);
  };

  // const deleteData = () => {
  //   api
  //     .delete(
  //       `tipoDePrograma/custom/${currentClient.clienteId}/delete/${dataDelete.codigo}`
  //     )
  //     .then(() => {
  //       toast.success("Tipo de Programa deletado com sucesso!");
  //       fetchData();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       if (error.response.status === 400) {
  //         toast.error(error.response.data.message);
  //       } else {
  //         toast.error("Algo deu errado. Por favor, tente mais tarde.");
  //       }
  //     });
  // };

  // function openModal() {
  //   setModalOpened(true);
  // }

  // function closeModal() {
  //   setModalOpened(false);
  // }

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      {/* <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={deleteData}
      /> */}
      <HeaderOptions
        withRow={true}
        TituloPagina={"Fonte de Recursos"}
        SubTituloPagina={"Consulta"}
        ToPag={"/planejamento/cadastro/fonte-de-recursos"}
        DashboardPage={"/planejamento/cadastro/fonte-de-recursos/dashboard"}
        PaginaRelatorio={"/planejamento/cadastro/fonte-de-recurso/relatorio"}
      />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loading ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Código</TituloTable>
              <TituloTable>Título</TituloTable>
              <TituloTable>Tipo de Vínculo</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.conta}</td>
                    <td style={{ whiteSpace: "nowrap" }} title={item.titulo}>
                      {item.titulo.length > 80
                        ? item.titulo.substring(0, 80) + "..."
                        : item.titulo}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>{item.funcao}</td>
                    <td>
                      <Link
                        to={`/planejamento/cadastro/fonte-de-recursos/visualizar/${item.conta}`}
                      >
                        <Icon
                          icon="bx:edit"
                          color="#5971C8"
                          height="22"
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.conta}</td>
                    <td style={{ whiteSpace: "nowrap" }} title={item.titulo}>
                      {item.titulo.length > 80
                        ? item.titulo.substring(0, 80) + "..."
                        : item.titulo}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>{item.funcao}</td>
                    <td>
                      <Link
                        to={`/planejamento/cadastro/fonte-de-recursos/visualizar/${item.conta}`}
                      >
                        <Icon
                          icon="bx:edit"
                          color="#5971C8"
                          height="22"
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        />
                      </Link>
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConsultaFonteRecursos;
