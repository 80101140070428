import { useContext } from "react";
import { CurrentYearContext } from "../contexts/YearContext";

export const useCurrentYear = () => {
  const context = useContext(CurrentYearContext);

  if (!context) {
    throw new Error(
      "CurrentYearContext deve ser usado dentro de um CurrentYearProvider"
    );
  }

  return context;
};
