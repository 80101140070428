import { useEffect, useState } from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form, Table } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { CCol } from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import ConjuntoButtonsForm from "../../../../components/ConjuntoButtonsForm/ConjuntoButtonsForm";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import {
  AutocompleteStyled,
  TextFieldStyled,
} from "../../../../styles/StyledComponents/FormsStyleds";
import { CNPJMaskCustom } from "../../../../utils/FormatacaoDeDados/CNPJMaskCustom";
import { CPFMaskCustom } from "../../../../utils/FormatacaoDeDados/CPFMasCustom";
import { FormatCnpjPaste } from "../../../../utils/FormatacaoDeDados/FormatCnpjPaste";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { InputFileStyled } from "../../../../styles/StyledComponents/InputFileStyled";
//import { CSSTransition } from "react-transition-group";
import "../../../../styles/CssAnimations/animations.css";
import CheckBoxesPermissoes from "./CheckBoxesPermissoes";
import {
  BodyTable,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import api from "../../../../utils/api";

export const Block = styled(Col)`
  align-items: center;
  justify-content: space-between;
  padding: 0 7.5px;
  box-sizing: border-box;
  margin-bottom: 15px;
`;

export const DivInternaBlock = styled.div`
  padding-bottom: 5px;
  background: #f7f7f7;
  height: 280px;
  border: 2px solid #e8e8e8;
  border-radius: 5px;
  box-sizing: border-box;
`;

const QuadroClientesUsuario = styled.div`
  display: ${({ selecionado }) => (selecionado ? "block" : "none")};
`;

const QuadroPermissoes = styled.div`
  display: ${({ selecionado }) => (selecionado ? "block" : "none")};
`;

export const LabelCheck = styled(Form.Check)`
  margin-left: 30px;
  color: #6c7585;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 200px;

  input[type="checkbox"] {
    background-color: #f7f7f7;
    border-radius: 2px;
    border: 2px solid #515c70;
  }

  input[type="checkbox"]:checked {
    background-color: green;
    border-color: green;
  }
`;

export const TituloPermissao = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #515c70;
  margin-left: 16px;
  margin-top: 6px;
`;

const OptionsStyle = styled.span`
  color: #515c70;
  line-height: 35px;
  font-size: 0.8em;
  cursor: pointer;
  margin-left: 20px;
  ${({ selecionado }) =>
    selecionado &&
    `
    font-weight: 600;
    border-bottom: 2px solid #515c70;
  `}
`;

const PaInformacaoClientes = styled.p`
  background-color: #e9ecef;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: #6c757d;

  svg {
    margin-right: 10px;
  }
`;

// const ButtonUserCliente = styled.button`
//   color: #515c70;
//   border-radius: 5px;
//   width: 100%;
//   padding: 10px;
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 21px;
//   background-color: white;
//   border: 1px solid rgba(95, 105, 123, 0.4);
// `;

// const DivInformacoesDetalhadas = styled.div`
//   padding: 10px;
//   color: #515c70;
//   border-radius: 5px;
//   background-color: white;
//   border: 1px solid rgba(95, 105, 123, 0.4);
// `;

function UsuariosPage() {
  //const [collapseButton, setCollapseButton] = useState(null);
  const [userId, setUserId] = useState(0);
  const [userClientes, setUserClientes] = useState([]);
  const [userCpf, setUserCpf] = useState("");
  const [userExist, setUserExist] = useState("");
  const [cpfFormData, setCpfFormData] = useState("");
  const [autorizacaoFormData, setAutorizacaoFormData] = useState("");
  const [arquivoAutorizacao, setArquivoAutorizacao] = useState("");
  const [emailUser, setEmailUser] = useState("");
  const [abaSelecionada, setAbaSelecionada] = useState(1);
  const [arquivoCpf, setArquivoCpf] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputValueCliente, setInputValueCliente] = useState("");
  const [options, setOptions] = useState([]);
  const [optionsCliente, setOptionsCliente] = useState([]);
  const [arraySistemas, setArraySistemas] = useState([]);
  const [arrayMenu, setArrayMenu] = useState([]);
  const [arrayAcoes, setArrayAcoes] = useState([]);
  const [valuesUser, setValuesUser] = useState({
    pessoaFisicaId: 0,
    email: "",
    cpfAnexo: "",
    fotoPerfil: "string",
    senha: "loginTeste2@",
    clientes: [
      {
        clienteId: 0,
        usuarioTipoId: 2,
        autorizacaoAnexo: "",
        permissoes: [],
      },
    ],
  });

  axios.defaults.headers.common["Authorization"] = null;

  const fetchCnpjs = async (query) => {
    try {
      const response = await api.get(`cliente/pesquisa/cnpj/${query}`);

      const data = response.data;

      if (data.length < 1) {
        throw new Error("0 cadastros");
      }

      if (data.length === 1 && query.length === 14) {
        setValuesUser({
          ...valuesUser,
          clientes: [
            {
              ...valuesUser.clientes[0],
              clienteId: data[0].id,
            },
          ],
        });
      }

      const formattedOptions = data.map((item) => ({
        cnpj: item.pessoaJuridica.cnpj,
        nomeFantasia: item.pessoaJuridica.nomeFantasia,
      }));
      setOptionsCliente(formattedOptions);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        toast.warning("Token expirado, faça login novamente!");
      }
      if (error.message === "0 cadastros" && query.length <= 5) {
        toast.error("Não foi possivel encontrar um cliente com esses dados.");
      }
    }
  };

  //Verifica se o usuario ja tem cadastro no sistema
  const verificaClientesUsuario = (cpf) => {
    api
      .get(`usuario/pesquisa/${cpf}`)
      .then((response) => setUserClientes(response.data.clientes))
      .catch((error) => {
        console.log(error);
      });
  };

  const verificaUsuario = async (cpf) => {
    console.log("verificando usuario");
    try {
      const resp = await api.get(`usuario/pesquisa/${cpf}`);
      setUserExist(true);
      setUserCpf(resp.data.cpf);
      setUserId(resp.data.userId);
      setUserClientes(resp.data.clientes);
      setEmailUser(resp.data.email);

      console.log(resp.data.clientes);

      setValuesUser((prevValues) => ({
        ...prevValues,
        email: resp.data.email,
      }));
    } catch (error) {
      setUserExist(false);
      if (error.response && error.response.status !== 404) {
        toast.error(
          "Algo deu errado ao pesquisar dados desse registro no sistema, por favor tente mais tarde"
        );
      }
    }
  };

  //Funcao que verifica se o CPF está cadastrado no sistema e seta as opcoes do autocomplete
  const fetchCfps = async (query) => {
    try {
      const response = await api.get(`cpf/filtro/${query}`);
      const data = response.data;

      //Seta as opcoes do autoComplete
      const formattedOptions = data.map((item) => ({
        cpf: item.cpf,
        nome: item.nomeCompleto,
      }));

      //Modifica o useState do email do usuario
      setEmailUser(data[0].Contato.email);

      setValuesUser((prevValues) => ({
        ...prevValues,
        pessoaFisicaId: data[0].pessoaId,
      }));

      if (data.length < 1) {
        throw new Error("0 cadastros");
      }

      setOptions(formattedOptions);
    } catch (error) {
      console.log(error);
      if (query.length <= 5 && error.response.status === 404) {
        toast.error("Essa Pessoa Jurídica não está cadastrada no sistema.");
      }
    }
  };

  //Faz verificacoes no input value e chama a funcao fetchCfps
  useEffect(() => {
    const cleanInput = inputValue.replace(/[^\w\s]/gi, "");
    const regexNumeros = /^[0-9]+$/;
    const regexLetras = /^[A-Za-z]+$/;
    if (
      cleanInput.length >= 5 &&
      (regexNumeros.test(cleanInput) || regexLetras.test(cleanInput))
    ) {
      fetchCfps(cleanInput);
    }
  }, [inputValue]);

  //Chama a funcao para verificar o CNPJ
  const callFetchCNPJ = (filter) => {
    if (
      filter.length >= 5 &&
      (/^[a-zA-Z]+$/.test(filter) || /^[0-9]+$/.test(filter))
    ) {
      fetchCnpjs(filter);
    }
  };

  //Oq irá acontecer quando o usuario clicar na opcao do autocomplete
  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      fetchCfps(newValue.cpf);
      verificaUsuario(newValue.cpf);

      setValuesUser({ ...valuesUser, email: emailUser });
    } else {
      console.log("erro no autoComplete");
    }
  };

  const handleAutocompleteChangeCliente = (event, newValue) => {
    if (newValue) {
      fetchCnpjs(newValue.cnpj);
    }
  };

  /*--------GERENCIAMENTO DE ARRAYS-----------*/
  const gerenciaSistemas = (valor, estadoCheck) => {
    if (estadoCheck) {
      setArraySistemas([...arraySistemas, valor]);
    } else {
      //Retorna em que posicao do array estara o item a ser excluido
      let indexExclusao;
      for (let i = 0; i < arraySistemas.length; i++) {
        if (arraySistemas[i] === valor) {
          indexExclusao = i;
        }
      }
      //exclui o item do array
      const arraySistemasCopia = [...arraySistemas];
      arraySistemasCopia.splice(indexExclusao, 1);
      setArraySistemas(arraySistemasCopia);
      console.log("--------------");
      console.log(arraySistemas);
      console.log("--------------");
    }
  };

  const gerenciaMenu = (valor, estadoCheck) => {
    if (estadoCheck) {
      setArrayMenu([...arrayMenu, valor]);
    } else {
      //Retorna em que posicao do array estara o item a ser excluido
      let indexExclusao;
      for (let i = 0; i < arrayMenu.length; i++) {
        if (arrayMenu[i] === valor) {
          indexExclusao = i;
        }
      }
      //exclui o item do array
      arrayMenu.splice(indexExclusao, 1);
    }
  };

  const gerenciaAcoes = (valor, estadoCheck) => {
    if (estadoCheck) {
      setArrayAcoes([...arrayAcoes, valor]);
    } else {
      //Retorna em que posicao do array estará o item a ser excluido
      let indexExclusao;
      for (let i = 0; i < arrayAcoes.length; i++) {
        if (arrayAcoes[i] === valor) {
          indexExclusao = i;
        }
      }
      //exclui o item do array
      arrayAcoes.splice(indexExclusao, 1);
    }
  };

  //UseEffects para setar os valores dos arrays

  //UseEffects para converter os arquivos de anexo usando o FormData()
  useEffect(() => {
    const MAX_SIZE = 5 * 1024 * 1024; // 5MB
    if (arquivoCpf && arquivoCpf.size > MAX_SIZE) {
      toast.error(
        "O arquivo do CPF é maior que o tamanho máximo permitido de 5MB"
      );
      return;
    }

    const formDataCpf = new FormData();
    formDataCpf.append("imagem", arquivoCpf);
    console.log(formDataCpf.get("imagem"));
    setCpfFormData(formDataCpf);
  }, [arquivoCpf]);

  useEffect(() => {
    const MAX_SIZE = 5 * 1024 * 1024; // 5MB
    if (arquivoAutorizacao && arquivoAutorizacao.size > MAX_SIZE) {
      toast.error(
        "O arquivo de Autorização é maior que o tamanho máximo permitido de 5MB"
      );
      return;
    }

    const formDataAutorizacao = new FormData();
    formDataAutorizacao.append("imagem", arquivoAutorizacao);
    console.log(formDataAutorizacao.get("imagem"));
    setAutorizacaoFormData(formDataAutorizacao);
  }, [arquivoAutorizacao]);
  //----------------------------------------------------------------//

  const uploadCpfAnexo = async () => {
    return new Promise((resolve, reject) => {
      api
        .post("upload", cpfFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data.imageUrl);
          setValuesUser((prevValues) => ({
            ...prevValues,
            cpfAnexo: response.data.imageUrl,
          }));
          resolve(response.data.imageUrl);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  const uploadAutorizacaoAnexo = async () => {
    return new Promise((resolve, reject) => {
      api
        .post("upload", autorizacaoFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setValuesUser((prevValues) => ({
            ...prevValues,
            clientes: [
              {
                ...prevValues.clientes[0],
                autorizacaoAnexo: response.data.imageUrl,
              },
            ],
          }));
          resolve(response.data.imageUrl);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  //Funcao de cadastro de usuario
  const CadastrarUsuario = async () => {
    try {
      if (!autorizacaoFormData.has("imagem")) {
        throw new Error("throw error");
      } else {
        console.log(valuesUser);
        if (
          valuesUser.pessoaFisicaId !== 0 &&
          valuesUser.email !== "" &&
          valuesUser.clientes[0].clienteId !== 0 &&
          valuesUser.clientes[0].permissoes.length > 0
        ) {
          const cpfLink = await uploadCpfAnexo();
          const autLink = await uploadAutorizacaoAnexo();
          const valuesUserComplete = {
            ...valuesUser,
            cpfAnexo: cpfLink,
            clientes: [
              {
                ...valuesUser.clientes[0],
                autorizacaoAnexo: autLink,
              },
            ],
          };

          console.log(valuesUserComplete);
          const resp = await api.post("usuario", valuesUserComplete);
          console.log(resp);
          limpaValoresUsuario();
          toast.success("Usuario cadastrado com sucesso!");
        } else {
          throw new Error("dados incompletos");
        }
      }
    } catch (error) {
      if (error.message === "throw error") {
        toast.error(
          "É obrigatorio anexar a autorizacao para associar um cliente a este usuario."
        );
      } else if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error.includes("obrigatórias")
      ) {
        toast.error("Por favor, preencha todas as informações obrigatórias.");
      } else if (error.message === "dados incompletos") {
        toast.error("Por favor, preencha todas as informações obrigatórias.");
      } else if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error.includes("deve conter")
      ) {
        toast.error(
          "A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número, um caractere especial e ter no mínimo 8 caracteres."
        );
      } else {
        toast.error("Ocorreu um erro ao cadastrar o Usuario.");
      }
    }
  };

  //Funcoes dos botoes (Adicionar,Excluir...)
  const AdicionarCliente = async () => {
    console.log(userId);
    try {
      if (!autorizacaoFormData.has("imagem")) {
        console.log("falta  imagem");
        throw new Error("throw error");
      } else {
        if (
          valuesUser.pessoaFisicaId !== 0 &&
          valuesUser.clientes[0].clienteId !== 0 &&
          valuesUser.clientes[0].permissoes.length > 0
        ) {
          const autLink = await uploadAutorizacaoAnexo();
          const clienteAddValues = {
            clientes: [
              {
                ...valuesUser.clientes[0],
                autorizacaoAnexo: autLink,
              },
            ],
          };

          console.log(clienteAddValues);

          await api.put(`usuario/${userId}/clientes`, clienteAddValues);

          verificaClientesUsuario(userCpf);
          toast.success("Cliente associado ao usuário com sucesso!");
        } else {
          throw new Error("dados incompletos");
        }
      }
    } catch (error) {
      if (error.message === "throw error") {
        toast.error(
          "É obrigatorio anexar a autorizacao para associar um cliente a este usuario."
        );
      } else if (error.message === "dados incompletos") {
        toast.error("Por favor preencha os dados necessarios.");
      } else {
        toast.error("Algo deu errado, por favor tente mais tarde.");
      }
    }
  };
  //-----------------------------------------

  //carousel---------------------------------
  const [acoesValues] = useState([
    {
      titulo: "Ações Gerenciador",
      valores: [
        { nome: "Adicionar", value: 1 },
        { nome: "Editar", value: 2 },
        { nome: "Excluir", value: 3 },
        { nome: "Consulta", value: 4 },
        { nome: "Relatório", value: 5 },
        { nome: "Gráfico", value: 6 },
      ],
    },
    {
      titulo: "Ações Planejamento",
      valores: [
        { nome: "Adicionar", value: 1 },
        { nome: "Editar", value: 2 },
        { nome: "Excluir", value: 3 },
        { nome: "Consulta", value: 4 },
        { nome: "Relatório", value: 5 },
        { nome: "Gráfico", value: 6 },
      ],
    },
    {
      titulo: "Ações Contabilidade",
      valores: [
        { nome: "Adicionar", value: 1 },
        { nome: "Editar", value: 2 },
        { nome: "Excluir", value: 3 },
        { nome: "Consulta", value: 4 },
        { nome: "Relatório", value: 5 },
        { nome: "Gráfico", value: 6 },
      ],
    },
    {
      titulo: "Ações Financeiro",
      valores: [
        { nome: "Adicionar", value: 1 },
        { nome: "Editar", value: 2 },
        { nome: "Excluir", value: 3 },
        { nome: "Consulta", value: 4 },
        { nome: "Relatório", value: 5 },
        { nome: "Gráfico", value: 6 },
      ],
    },
    {
      titulo: "Ações Gestor",
      valores: [
        { nome: "Adicionar", value: 1 },
        { nome: "Editar", value: 2 },
        { nome: "Excluir", value: 3 },
        { nome: "Consulta", value: 4 },
        { nome: "Relatório", value: 5 },
        { nome: "Gráfico", value: 6 },
      ],
    },
    {
      titulo: "Ações Folha de Pagamento",
      valores: [
        { nome: "Adicionar", value: 1 },
        { nome: "Editar", value: 2 },
        { nome: "Excluir", value: 3 },
        { nome: "Consulta", value: 4 },
        { nome: "Relatório", value: 5 },
        { nome: "Gráfico", value: 6 },
      ],
    },
  ]);

  //-----------------------------------------

  const limpaValoresUsuario = () => {
    setValuesUser((prevValues) => ({
      ...prevValues,
      pessoaFisicaId: 0,
      email: "",
      cpfAnexo: "",
      fotoPerfil: "string",
      senha: "loginTeste2@",
      clientes: [
        {
          ...prevValues.clientes[0],
          clienteId: 0,
          usuarioTipoId: 2,
          autorizacaoAnexo: "",
        },
      ],
    }));

    setInputValue("");
    setInputValueCliente("");
    setArquivoAutorizacao("");
    setArquivoCpf("");
    setUserExist(false);
  };

  //-----------------------------------------

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <ToastContainer />
      <HeaderCadastros
        withRow={true}
        ButtonSaveFunction={CadastrarUsuario}
        BotaoSaveAtivo={!userExist}
        NomePaginaTitulo={"Usuários"}
        PaginaSubtitulo={"Cadastro"}
        PaginaConsulta={"/acesso-interno/usuarios/consulta"}
      />
      <Row className="row_form mt-1" style={{ borderRadius: "0 0 5px 5px" }}>
        <CCol md={2}>
          <FloatingLabelInput
            style={{ color: "#515c70" }}
            type="text"
            placeholder="Código"
            label="Código"
            value={userExist ? userId : ""}
            disabled
          />
        </CCol>
        <CCol md={7}>
          <SearchIcon
            sx={{
              marginTop: "8px",
              marginLeft: "10px",
              position: "absolute",
              color: "#515C70",
            }}
          />
          {/* AutoComplete Usuario */}
          <AutocompleteStyled
            freeSolo
            id="cpf_autocomplete"
            disableClearable
            options={options}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              const cleanInputValue = newInputValue.replace(/[^\w\s]/gi, "");
              setInputValue(newInputValue);
              console.log(cleanInputValue);
            }}
            onChange={handleAutocompleteChange}
            getOptionLabel={(option) =>
              `${option.nome} - ${FormatCpfPaste(option.cpf) || ""}`
            }
            renderInput={(params) => (
              <TextFieldStyled
                {...params}
                label="Usuario"
                placeholder="CPF - Nome"
                inputProps={{
                  ...params.inputProps,
                  value:
                    inputValue.length >= 13
                      ? inputValue
                      : CPFMaskCustom(inputValue),
                  maxLength: 18,
                  type: "search",
                }}
                size="small"
              />
            )}
          />
        </CCol>
        <CCol className="col_g" md={3}>
          <FloatingLabelInput
            type="text"
            placeholder="Email"
            disabled={
              !(
                emailUser === "" ||
                emailUser === null ||
                emailUser === undefined
              )
            }
            label="Email"
            onChange={(e) =>
              setValuesUser({ ...valuesUser, email: e.target.value })
            }
            value={valuesUser.email}
          />
        </CCol>
        <div className="pt-3"></div>
        <CCol md={userExist ? 3 : 6}>
          <InputFileStyled
            type="file"
            id="fileAutorizacao"
            onChange={(e) => {
              if (e.target.files.length >= 1) {
                setArquivoAutorizacao(e.target.files[0]);
              }
            }}
          />
          <label
            style={
              userExist && arquivoAutorizacao === ""
                ? { fontSize: "12px" }
                : null
            }
            htmlFor="fileAutorizacao"
          >
            <Icon
              style={{ marginRight: "10px" }}
              icon="ic:outline-cloud-upload"
              color="#515c70"
              width="20"
            />
            {arquivoAutorizacao !== ""
              ? arquivoAutorizacao.name
              : "Autorização do Ordenador da Despesa"}
          </label>
        </CCol>

        <CCol
          md={6}
          style={userExist ? { display: "none" } : { display: "block" }}
        >
          <InputFileStyled
            type="file"
            id="fileCpf"
            onChange={(e) => {
              if (e.target.files.length >= 1) {
                setArquivoCpf(e.target.files[0]);
              }
            }}
          />
          <label htmlFor="fileCpf">
            <Icon
              style={{ marginRight: "10px" }}
              icon="ic:outline-cloud-upload"
              color="#515c70"
              width="20"
            />
            {arquivoCpf !== "" ? arquivoCpf.name : "CPF - Documento"}
          </label>
        </CCol>

        <div
          style={userExist ? { display: "none" } : { display: "block" }}
          className="pt-3"
        ></div>
        <CCol md={userExist ? 6 : 8}>
          <SearchIcon
            sx={{
              marginTop: "8px",
              marginLeft: "10px",
              position: "absolute",
              color: "#515C70",
            }}
          />
          {/*AutoComplete Cliente*/}
          <AutocompleteStyled
            freeSolo
            id="cnpj-autocomplete"
            disableClearable
            options={optionsCliente}
            inputValue={inputValueCliente}
            onInputChange={(event, newInputValue) => {
              const cleanInputValue = newInputValue.replace(/[^\w\s]/gi, "");
              setInputValueCliente(newInputValue);

              if (cleanInputValue.length >= 5) {
                callFetchCNPJ(cleanInputValue);
              }
            }}
            onChange={handleAutocompleteChangeCliente}
            getOptionLabel={(option) => {
              return ` ${option.nomeFantasia || ""} - ${
                FormatCnpjPaste(option.cnpj) || ""
              }`;
            }}
            renderInput={(params) => (
              <TextFieldStyled
                {...params}
                label="Cliente"
                placeholder="CNPJ"
                inputProps={{
                  ...params.inputProps,
                  value:
                    inputValueCliente.length >= 17
                      ? inputValueCliente
                      : CNPJMaskCustom(inputValueCliente),
                  maxLength: 18,
                  type: "search",
                }}
                size="small"
              />
            )}
          />
        </CCol>

        <CCol className="col_g" md={userExist ? 2 : 3}>
          <FloatingLabelInput
            tipo="input_select"
            defaultValue={""}
            options={[
              { value: "", label: "Tipo de Usuário" },
              { value: 2, label: "Externo" },
              { value: 1, label: "Administrador" },
            ]}
            onChange={(e) =>
              setValuesUser((prevValues) => ({
                ...prevValues,
                clientes: [
                  {
                    ...prevValues.clientes[0],
                    usuarioTipoId: parseInt(e.target.value),
                  },
                ],
              }))
            }
          />
        </CCol>
        <CCol>
          <ConjuntoButtonsForm
            ActiveAdd={userExist}
            FuncaoIconAdicionar={AdicionarCliente}
          />
        </CCol>
      </Row>

      <Row>
        <CCol
          className="d-flex align-items-center row_endereco_aba mt-1"
          md={12}
        >
          <OptionsStyle
            onClick={() => {
              setAbaSelecionada(1);
            }}
            selecionado={abaSelecionada === 1}
          >
            Permissões
          </OptionsStyle>

          <OptionsStyle
            onClick={() => {
              setAbaSelecionada(2);
            }}
            selecionado={abaSelecionada === 2}
          >
            Clientes do Usuario
          </OptionsStyle>
        </CCol>
      </Row>

      {/*Quadro de Permissoes */}
      <QuadroPermissoes selecionado={abaSelecionada === 1}>
        <Row>
          <Col
            className="d-flex align-items-center p-2 row_endereco_conteudo"
            md={12}
          >
            <span className="mx-4">Permissões</span>
          </Col>
        </Row>
        <CheckBoxesPermissoes
          gerenciaSistemas={gerenciaSistemas}
          gerenciaAcoes={gerenciaAcoes}
          gerenciaMenu={gerenciaMenu}
          arraySistemas={arraySistemas}
          valuesUser={valuesUser}
          setValuesUser={setValuesUser}
          acoesValues={acoesValues}
        />
      </QuadroPermissoes>

      {/*Quadro de Clientes do Usuario */}
      <QuadroClientesUsuario selecionado={abaSelecionada === 2}>
        <Row>
          <Col
            className="d-flex align-items-center p-2 row_endereco_conteudo"
            md={12}
          >
            <span className="mx-4">Clientes do usuario</span>
          </Col>
        </Row>
        <Row
          style={{ padding: "21px 21px 5px 21px" }}
          className=" d-flex row_form_2 mt-1"
        >
          {userExist !== true && (
            <PaInformacaoClientes>
              <Icon
                width={25}
                icon="fluent:error-circle-20-regular"
                className="icon_alert_modal"
              />
              {userExist === ""
                ? "Informe um CPF para consultar os clientes vinculados ao mesmo"
                : "Esse usuario ainda não está cadastrado no sistema"}
            </PaInformacaoClientes>
          )}

          {/* VOLTAR PRA CÁ QUANDO O RETORNO DE USUARIO ESTIVER PRONTO */}
          {userExist && (
            <div>
              {/* <p style={{ color: "#515c70", fontWeight: "600" }}>
                Total de clientes do usuário: {userClientes.length}
              </p> */}

              <Table responsive>
                <thead>
                  <tr>
                    <TituloTable>Código</TituloTable>
                    <TituloTable>Nome</TituloTable>
                    <TituloTable>CNPJ</TituloTable>
                    <TituloTable>Sistemas</TituloTable>
                    <TituloTableAction>Ação</TituloTableAction>
                  </tr>
                </thead>
                <BodyTable>
                  {userClientes.map((item, index) => (
                    <tr key={index}>
                      <td>{item.pessoaJuridicaId}</td>
                      <td>{item.razaoSocial}</td>
                      <td>{FormatCnpjPaste(item.cnpj)}</td>
                      <td>
                        {item.permissoes.sistemas.map((sist, i) => {
                          if (sist.sistema === "Gerenciador") {
                            return (
                              <Icon
                                icon="healthicons:g-negative"
                                color="#515c70"
                                height="19"
                                style={{
                                  borderRadius: "2px",
                                  marginRight: "7px",
                                }}
                              />
                            );
                          } else {
                            return <></>;
                          }
                        })}
                      </td>
                      <td>acoes</td>
                    </tr>
                  ))}
                </BodyTable>
              </Table>
            </div>
          )}
        </Row>
      </QuadroClientesUsuario>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default UsuariosPage;
