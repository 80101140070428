import React, { useRef } from "react";
// import { Form } from "react-bootstrap";
import styled from "styled-components";
import { FormatDateToNA } from "../../utils/FormatacaoDeDados/FormatDateToNA";
import { LabelStyled } from "../Inputs/MyAutoComplete";

export const StyledInputDate = styled.input`
  background: transparent;
  border: ${(props) => {
    if (props.$border) {
      return props.$border;
    } else if (props.$backgroundColor) {
      return `1px solid ${props.$backgroundColor}`;
    } else {
      return "1px solid #ccc";
    }
  }};
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : null};
  padding: 6px;
  padding-left: 10px;
  outline: none;
  width: 100%;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 500;
  color: #515c70;
  ${({ height }) => (height !== undefined ? `height: ${height};` : null)};
  ${({ radiusborder }) =>
    radiusborder ? `border-radius: ${radiusborder};` : "5px"};

  ::-webkit-calendar-picker-indicator {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='%23515C70' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M8 2v4m8-4v4'/%3E%3Crect width='18' height='18' x='3' y='4' rx='2'/%3E%3Cpath d='M3 10h18'/%3E%3C/g%3E%3C/svg%3E");
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    background-size: 24px;
    background-position: center;
  }

  :disabled {
    background-image: ${({ $disabledBackgroundColor }) => {
      if ($disabledBackgroundColor) {
        return `url("data:image/svg+xml,${encodeURIComponent(
          `<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><g fill='none' stroke='#fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'><path d='M8 2v4m8-4v4'/><rect width='18' height='18' x='3' y='4' rx='2'/><path d='M3 10h18'/></g></svg>`
        )}")`;
      } else {
        return `url("data:image/svg+xml,${encodeURIComponent(
          `<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><g fill='none' stroke='#515C70' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'><path d='M8 2v4m8-4v4'/><rect width='18' height='18' x='3' y='4' rx='2'/><path d='M3 10h18'/></g></svg>`
        )}")`;
      }
    }};
    border: ${(props) => {
      if (props.$border) {
        return props.$border;
      } else if (props.$backgroundColor) {
        return `1px solid ${props.$backgroundColor}`;
      } else if (props.$disabledBackgroundColor) {
        return `1px solid ${props.$disabledBackgroundColor}`;
      } else {
        return "1px solid #ccc";
      }
    }};
    background-repeat: no-repeat;
    background-color: ${({ $disabledBackgroundColor }) =>
      $disabledBackgroundColor ? $disabledBackgroundColor : "#e9ecef"};
    background-position: calc(100% - 9.5px) center;
    background-size: 24px;
  }
`;

const InputData = React.forwardRef((props, ref, labelActive) => {
  const inputRef = useRef(null);

  return (
    <div style={{ position: "relative" }}>
      {/* <button onClick={() => console.log(inputRef.current)}>console</button> */}
      <LabelStyled
        active={true}
        style={
          ref?.current?.value.length === 0 ||
          inputRef?.current?.value.length === 0
            ? { opacity: "0" }
            : { opacity: "1" }
        }
      >
        {props.label ? props.label : ""}
      </LabelStyled>
      <StyledInputDate
        ref={ref ? ref : inputRef}
        type="date"
        maxLength={10}
        value={props.externalValue ? FormatDateToNA(props.externalValue) : null}
        {...props}
      ></StyledInputDate>
    </div>
  );
});

export default InputData;
