import React from "react";
import { Row } from "react-bootstrap";
import { CCol } from "./CCol";

const RowTitulo = ({ children }) => {
  return (
    <Row className="mt-3">
      <CCol
        className="d-flex align-items-center p-2 header_body_conteudo"
        md={12}
      >
        <span
          style={{
            fontSize: "16.5px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {children}
        </span>
      </CCol>
    </Row>
  );
};

export default RowTitulo;
