import styled from "styled-components";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

const PTitulosSaldos = styled.p`
  margin: 0;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #515c70;
`;

export const SaldosAba = () => {
  return (
    <>
      <RowTituloAba style={{ marginTop: "15px" }}>
        <div>
          <span>Saldos</span>
        </div>
      </RowTituloAba>

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol>
          <PTitulosSaldos>Saldo do Empenho</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>Liquidação</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>Saldo a Liquidar</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>Pago</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
        <CCol>
          <PTitulosSaldos>Saldo a Pagar</PTitulosSaldos>
          <FloatingLabelInput
            disabled
            value="R$ 20.000,00"
            style={{ border: "none", borderRadius: "10px", textAlign: "end" }}
          />
        </CCol>
      </RowForm>
    </>
  );
};
