import React from "react";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useFetchData } from "../../../../hooks/useFetchData";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol } from "../../../../components/Grid/CCol";
import Dashboard from "../../../../components/ComponentesDeDashboard/Dashboard";

export const DashboardFuncaoSubfuncao = () => {
  const { data: dataFuncoes } = useFetchData("funcao/funcoes");
  const { data: dataSubfuncoes } = useFetchData("subfuncao/subfuncoes");
  const [quantidadeFuncoes, setQuantidadeFuncoes] = useState("0");
  const [quantidadeSubfuncoes, setQuantidadeSubfuncoes] = useState("0");
  const [box1, setBox1] = useState([]);
  const [box2, setBox2] = useState([]);
  const options = [
    { label: "Quantidade", value: null },
    { label: "Quantidade", value: 1 },
  ];

  useEffect(() => {
    if (dataFuncoes) {
      setQuantidadeFuncoes(
        dataFuncoes.length < 10
          ? "0" + (dataFuncoes.length - 1)
          : dataFuncoes.length - 1
      );
    }
  }, [dataFuncoes]);

  useEffect(() => {
    if (dataSubfuncoes) {
      setQuantidadeSubfuncoes(
        dataSubfuncoes.length < 10
          ? "0" + dataSubfuncoes.length
          : dataSubfuncoes.length
      );
    }
  }, [dataSubfuncoes]);

  useEffect(() => {
    if (quantidadeFuncoes > 0) {
      setBox1({
        boxTitle: "Quantidade",
        boxSubtitle: "Funções",
        boxValue: quantidadeFuncoes,
      });
    }
    if (quantidadeSubfuncoes > 0) {
      setBox2({
        boxTitle: "Quantidade",
        boxSubtitle: "SubFunções",
        boxValue: quantidadeSubfuncoes,
      });
    }
  }, [quantidadeFuncoes, quantidadeSubfuncoes]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Função - Subfunção"}
        PaginaSubtitulo={"Dashboard"}
        PaginaConsulta={"/planejamento/cadastro/funcao-subfuncao/consulta"}
        PaginaRelatorio={"/planejamento/cadastro/funcao-subfuncao/relatorio"}
        BotaoNovoAtivo={false}
        BotaoSaveAtivo={false}
      />
      <Row className="row_form_2 mt-1" style={{ padding: "20px 10px" }}>
        <CCol>
          <Dashboard box1={box1} box2={box2} options={options} />
        </CCol>
        <CCol>
          <Dashboard />
        </CCol>
      </Row>
      <div className="p-3"></div>
    </Container>
  );
};
