import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { formatValue } from "../utils/formatValue";

export const RenderPdfDemoRiscosFiscaisProvidencias = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData,
  customName
) => {
  const colNames = ["PASSIVOS CONTIGENTES", "PROVIDÊNCIAS"];
  const colNames2 = ["DEMAIS RISCOS FISCAIS PASSIVOS", "PROVIDÊNCIAS"];
  const styledCol = [
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
  ];
  const styledCol2 = [
    { flexBasis: "70%", flexShrink: 1, flexGrow: 1, padding: "2px 0" },
    {
      flexBasis: "30%",
      flexShrink: 1,
      flexGrow: 1,
      padding: "2px 0",
      textAlign: "right",
    },
    { flexBasis: "70%", flexShrink: 1, flexGrow: 1, padding: "2px 0" },
    {
      flexBasis: "30%",
      flexShrink: 1,
      flexGrow: 1,
      padding: "2px 0",
      textAlign: "right",
    },
  ];

  const titles = ["subtotal"];
  const normalizeString = (str) => {
    if (str) {
      const lowerStr = str.toLowerCase();
      const noAccents = lowerStr
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const trimmed = noAccents.trim();
      const singleSpaced = trimmed.replace(/\s+/g, " ");

      return singleSpaced.endsWith(".")
        ? singleSpaced.slice(0, -1)
        : singleSpaced;
    }
  };

  return (
    <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Demonstrativo de Riscos Ficais e Providências"}
        plusText={"Anexo de Riscos Ficais"}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
        customData={customData}
        customRelatorioType={customName}
        ignoreConsolidacao
      />
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
        fixed
      >
        <Text>{"ARF (LRF, art 4º, § 3º)"}</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={stylesPdf.colunas} fixed>
        {opcoes[0]?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames[index]}
                styledCol={styledCol[index]}
                hideBar
              />
            )
        )}
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View style={{ margin: "0 -3px" }}>
          <View
            style={[
              stylesPdf.colunas,
              {
                gap: 0,
                backgroundColor: "#a6a6a6",
                fontWeight: "bold",
              },
            ]}
          >
            <PdfInfos
              pdfInfo={"Descrição"}
              styledCol={[styledCol2[0], { textAlign: "center" }]}
            />
            <PdfInfos
              pdfInfo={"Valor"}
              styledCol={[styledCol2[1], { textAlign: "center" }]}
            />
            <PdfInfos
              pdfInfo={"Descrição"}
              styledCol={[styledCol2[2], { textAlign: "center" }]}
            />
            <PdfInfos
              pdfInfo={"Valor"}
              styledCol={[styledCol2[3], { textAlign: "center" }]}
            />
          </View>

          {dados?.data?.part1?.map((item, index) => {
            const isTitle = titles.includes(normalizeString(item.title1));
            return (
              <View
                key={index}
                style={[
                  stylesPdf.colunas,
                  {
                    gap: 0,
                    backgroundColor: isTitle ? "#a6a6a6" : "white",
                    fontWeight: isTitle ? "bold" : "normal",
                  },
                ]}
              >
                <PdfInfos pdfInfo={item.title1} styledCol={[styledCol2[0]]} />
                <PdfInfos
                  pdfInfo={formatValue(item.value1) || ""}
                  styledCol={[styledCol2[1]]}
                />
                <PdfInfos pdfInfo={item.title2} styledCol={[styledCol2[2]]} />
                <PdfInfos
                  pdfInfo={formatValue(item.value2) || ""}
                  styledCol={[styledCol2[3]]}
                />
              </View>
            );
          })}
        </View>
      </View>

      <View style={[stylesPdf.colunas, { marginTop: "10px" }]} fixed>
        {opcoes[0]?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames2[index]}
                styledCol={styledCol[index]}
                hideBar
              />
            )
        )}
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View style={{ margin: "0 -3px" }}>
          <View
            style={[
              stylesPdf.colunas,
              {
                gap: 0,
                backgroundColor: "#a6a6a6",
                fontWeight: "bold",
              },
            ]}
          >
            <PdfInfos
              pdfInfo={"Descrição"}
              styledCol={[styledCol2[0], { textAlign: "center" }]}
            />
            <PdfInfos
              pdfInfo={"Valor"}
              styledCol={[styledCol2[1], { textAlign: "center" }]}
            />
            <PdfInfos
              pdfInfo={"Descrição"}
              styledCol={[styledCol2[2], { textAlign: "center" }]}
            />
            <PdfInfos
              pdfInfo={"Valor"}
              styledCol={[styledCol2[3], { textAlign: "center" }]}
            />
          </View>

          {dados?.data?.part2?.map((item, index) => {
            const isTitle = titles.includes(normalizeString(item.title1));
            return (
              <View
                key={index}
                style={[
                  stylesPdf.colunas,
                  {
                    gap: 0,
                    backgroundColor: isTitle ? "#a6a6a6" : "white",
                    fontWeight: isTitle ? "bold" : "normal",
                  },
                ]}
              >
                <PdfInfos pdfInfo={item.title1} styledCol={[styledCol2[0]]} />
                <PdfInfos
                  pdfInfo={formatValue(item.value1) || ""}
                  styledCol={[styledCol2[1]]}
                />
                <PdfInfos pdfInfo={item.title2} styledCol={[styledCol2[2]]} />
                <PdfInfos
                  pdfInfo={formatValue(item.value2) || ""}
                  styledCol={[styledCol2[3]]}
                />
              </View>
            );
          })}
        </View>
        <View
          style={{ display: "flex", flexDirection: "row", marginTop: "7px" }}
        >
          <View
            style={[
              stylesPdf.divInferiorColuna,
              {
                fontWeight: "bold",
                fontSize: 10,
                textTransform: "uppercase",
                color: "white",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              },
            ]}
          >
            <Text>TOTAL</Text>
            <Text>{formatValue(dados.sum.total1)}</Text>
          </View>
          <View
            style={[
              stylesPdf.divInferiorColuna,
              {
                fontWeight: "bold",
                fontSize: 10,
                textTransform: "uppercase",
                color: "white",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              },
            ]}
          >
            <Text>TOTAL</Text>
            <Text>{formatValue(dados.sum.total2)}</Text>
          </View>
        </View>
        <Text>FONTE: {dados.fonte}</Text>
      </View>

      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
