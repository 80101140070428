const useSistemas = ({ setValuesCliente }) => {
  const arraySistemas = [
    { sistema: "Gerenciador", checked: false },
    { sistema: "Planejamento", checked: false },
    { sistema: "Contabilidade", checked: false },
    { sistema: "Pagamentos", checked: false },
    { sistema: "Gestor", checked: false },
    { sistema: "Folha", checked: false },
  ];

  const handleCheckSystems = (indexGestao, nomeModulo) => {
    setValuesCliente((prev) => {
      let newArrayGestoes = prev.gestao;
      const indexModulo = newArrayGestoes[indexGestao].modulos.findIndex(
        (modulo) => modulo.sistema === nomeModulo
      );

      newArrayGestoes[indexGestao].modulos[indexModulo].checked =
        !newArrayGestoes[indexGestao].modulos[indexModulo].checked;

      return {
        ...prev,
        gestao: newArrayGestoes,
      };
    });
  };

  return { arraySistemas, handleCheckSystems };
};

export default useSistemas;
