import React, { useEffect, useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { OpenedModalsContext } from "../../contexts/OpenedModalsContext";

const ButtonSaveModalAutoComplete = ({ SaveFunction, pageTitle }) => {
  const { openedModals } = useContext(OpenedModalsContext);
  const [hasPageTitle, setHasPageTitle] = useState(false);

  useEffect(() => {
    const lastOpenedModal = openedModals[openedModals.length - 1];
    const internHasPageTitle =
      lastOpenedModal && lastOpenedModal.tituloModal === pageTitle;
    if (internHasPageTitle) {
      setHasPageTitle(true);
    } else {
      setHasPageTitle(false);
    }
  }, [openedModals, pageTitle]);

  useEffect(() => {
    if (hasPageTitle) {
      const handleKeyDown = (event) => {
        if (event.altKey && (event.key === "s" || event.key === "S")) {
          SaveFunction();
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [SaveFunction, hasPageTitle]);

  return (
    <Button
      style={{
        position: "absolute",
        zIndex: "99",
        top: "-39px",
        right: "18px",
      }}
      onClick={SaveFunction}
      variant="primary"
    >
      Save
    </Button>
  );
};

export default ButtonSaveModalAutoComplete;
