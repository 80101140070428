import { getDemoEstimativa } from "./getDemoEstimativa";
import { getDemoDespesa } from "./getDemoDespesa";
import { getDemoSegCatEcon } from "./getDemoSegCatEcon";
import { getDemoDespesaFuncao } from "./getDemoDespesaFuncao";

export const getDataPpa = {
  getDemoEstimativa,
  getDemoDespesa,
  getDemoSegCatEcon,
  getDemoDespesaFuncao
};
