import React from "react";
import styled from "styled-components";

const ModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  display: ${({ $modalOpened }) => ($modalOpened ? "flex" : "none")};
`;

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 5px;
`;

const ModalComponent = ({ modalOpened, setModalOpened, children }) => {
  return (
    <ModalContainer $modalOpened={modalOpened}>
      <ModalContent>{children}</ModalContent>
    </ModalContainer>
  );
};

export default ModalComponent;
