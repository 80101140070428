import React from "react";
import { Row } from "react-bootstrap";
import { CCol, RowAninhada } from "../../../components/Grid/CCol";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";
import { PTitulosInputs } from "../../../styles/StyledComponents/ListaDeInputs";

const exampleValue = {
  exampleValue1: "example1",
  exampleValue2: "example2",
  exampleValue3: "example3",
  exampleValue4: "example4",
};

const Saldos = ({ data = exampleValue }) => {
  const entries = Object.entries(data);

  const chunkArray = (arr, chunkSize) => {
    let result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  const chunkedData = chunkArray(entries, 4);

  return (
    <Row className="row_form mt-1" style={{ justifyContent: "center" }}>
      {chunkedData.map((chunk, chunkIndex) => (
        <RowAninhada
          key={chunkIndex}
          style={chunkIndex > 0 ? { marginTop: "20px" } : {}}
        >
          {chunk.map(([key, value], index) => (
            <CCol md={3} key={index}>
              <PTitulosInputs>{key}</PTitulosInputs>
              <FloatingLabelInput
                disabled
                height={"30px"}
                value={value}
                style={{ color: "#515c70" }}
              />
            </CCol>
          ))}
        </RowAninhada>
      ))}
    </Row>
  );
};

export default Saldos;
