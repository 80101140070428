import React, { useContext, useEffect, useState } from "react";
import { ContainerToModal, RowToModal } from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  ButtonOptionsFirst,
  ButtonOptionsLast,
  ButtonOptionsPages,
  ColButtons,
} from "../../../../styles/StyledComponents/ButtonsSelectPage";

import ContaBancariaComponent from "./ContaBancariaComponent";
import Agencia from "./Agencia";
import api from "../../../../utils/api";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { toast, ToastContainer } from "react-toastify";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";

const ContaBancariaPag = ({ path }) => {
  //consts
  const { agenciaId: paramsIdAgencia } = useParams();
  const { contaBancariaId: paramsIdContaBancaria } = useParams();
  const dataAtual = ReturnDataAtual();
  const [abaPrincipalSelecionada, setAbaPrincipalSelecionada] = useState(
    path === "conta-bancaria" ? 0 : 1
  );
  const [abaSecundariaSelecionada, setAbaSecundariaSelecionada] = useState(0);

  //contexts
  const { currentClient } = useContext(CurrentClientContext);
  const { userAuthData } = useContext(AuthContext);
  const { currentYear } = useCurrentYear();

  //states pag conta bancaria
  const [valuesContaBancaria, setValuesContaBancaria] = useState({
    agenciaId: "",
    nome: "",
    data: dataAtual,
    numeroConta: "",
    digito: "",
    tipoDeContaId: 1,
    tipoDeVinculo: "00 - Demais",
    fontesDeRecursos: [],
    dataFechamento: "",
    contaCorrenteId: null,
    clienteId: currentClient.clienteId,
  });
  const [inputFonteValue, setInputFonteValue] = useState("");
  const [inputAgencia, setInputAgencia] = useState("");
  const [inputContaCorrente, setInputContaCorrente] = useState("");

  //States agencia
  const [agenciaValues, setAgenciaValues] = useState({
    codigo: "",
    nome: "",
    agencia: "",
    digito: "",
    cep: "",
    logradouro: "",
    bairro: "",
    cidade: "",
    estado: "",
    data: dataAtual,
  });
  const [inputSearchBancoValue, setInputSearchBancoValue] = useState("");

  const limparValoresAgencia = () => {
    setAgenciaValues({
      codigo: "",
      nome: "",
      agencia: "",
      digito: "",
      cep: "",
      logradouro: "",
      bairro: "",
      cidade: "",
      estado: "",
      data: dataAtual,
    });

    setInputSearchBancoValue("");
  };

  const limparValoresContaBancaria = () => {
    setValuesContaBancaria({
      agenciaId: "",
      nome: "",
      data: dataAtual,
      numeroConta: "",
      digito: "",
      tipoDeContaId: 1,
      tipoDeVinculo: "00 - Demais",
      fontesDeRecursos: [],
      dataFechamento: dataAtual,
    });

    setInputAgencia("");
    setInputContaCorrente("");
    setInputFonteValue("");
  };

  const handleCadastraAgencia = async () => {
    const { data, ...restante } = agenciaValues;
    const postValues = {
      ...restante,
      clienteId: currentClient.clienteId,
      userUpdate: userAuthData.userId,
    };

    console.log(postValues);

    try {
      await api.post("agenciaBancaria", postValues);
      toast.success("Agência cadastrada com sucesso.");
      limparValoresAgencia();
    } catch (error) {
      toast.error("Erro ao cadastrar a agência");
      console.log(error);
    }
  };

  const handleAtualizaAgencia = async () => {
    const putValues = {
      ...agenciaValues,
      clienteId: currentClient.clienteId,
      userUpdate: userAuthData.userId,
    };

    console.log(putValues);

    try {
      await api.put(`agenciaBancaria/${paramsIdAgencia}`, putValues);
      toast.success("Dados da agência atualizados com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Algo deu errado ao atualizar os dados.");
    }
  };

  const handleCadastraContaBancaria = async () => {
    const { data, ...rest } = valuesContaBancaria;
    const valuesToPost = {
      ...rest,
      fontesDeRecursos: valuesContaBancaria.fontesDeRecursos.map((item) => ({
        ...item,
        valor: parseFloat(item.valor),
      })),
    };

    console.log(valuesToPost);

    try {
      await api.post("contaBancaria", valuesToPost);
      limparValoresContaBancaria();
      toast.success("Conta cadastrada com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Algo deu errado ao cadastrar a conta");
    }
  };

  const handleAtualizaContaBancaria = async () => {
    const { data, ...rest } = valuesContaBancaria;
    const valuesToPut = {
      ...rest,
      userUpdate: userAuthData.userId,
    };

    console.log(valuesToPut);

    try {
      await api.put(
        `contaBancaria/${paramsIdContaBancaria}/${currentYear}`,
        valuesToPut
      );
      toast.success("Conta bancária atualizada com sucesso");
    } catch (error) {
      toast.error("Ocorreu um erro ao atualizar a conta bancaria");
    }
  };

  const fetchAgencia = async () => {
    try {
      const resp = await api.get(`agenciaBancaria/${paramsIdAgencia}`);
      const data = resp.data;

      const dataDeCriacao = new Date(data.dataCriacao);
      const dataDeCriacaoFormatada = `${dataDeCriacao.getFullYear()}-${String(
        dataDeCriacao.getMonth() + 1
      ).padStart(2, "0")}-${String(dataDeCriacao.getDate()).padStart(2, "0")}`;

      setAgenciaValues((prev) => ({
        ...prev,
        codigo: data.codigo,
        nome: data.nome,
        cep: data.cep,
        agencia: data.agencia,
        digito: data.digito,
        data: dataDeCriacaoFormatada,
        logradouro: data.logradouro,
        bairro: data.bairro,
        cidade: data.cidade,
        estado: data.estado,
      }));

      setInputSearchBancoValue(`${data.codigo} - ${data.nome}`);
      console.log(data);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao visualizar os dados desse cadastro.");
    }
  };

  const fetchContaBancaria = async () => {
    try {
      const resp = await api.get(
        `contaBancaria/${paramsIdContaBancaria}/${currentYear}`
      );
      const data = resp.data;
      console.log(data);
      setValuesContaBancaria((prev) => ({
        ...prev,
        agenciaId: data.agenciaId,
        data: data.dataCriacao.split("T")[0],
        dataFechamento: data.dataFechamento.split("T")[0],
        tipoDeContaId: data.tipoDeContaId,
        digito: data.digito,
        nome: data.nome,
        numeroConta: data.numeroConta,
        tipoDeVinculo: data.tipoDeVinculo,
        fontesDeRecursos: data.fontesDeRecursos.map((item) => ({
          fonteId: item.fonteInfo.id,
          valor: item.valor,
          data: "2024-11-06",
        })),
      }));
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (paramsIdAgencia) {
      fetchAgencia();
    }

    //eslint-disable-next-line
  }, [paramsIdAgencia]);

  useEffect(() => {
    console.log(paramsIdContaBancaria);
    if (paramsIdContaBancaria) {
      fetchContaBancaria();
    }
    //eslint-disable-next-line
  }, [paramsIdContaBancaria]);

  const handleSwitchReq = () => {
    if (abaPrincipalSelecionada === 1 && !paramsIdAgencia) {
      handleCadastraAgencia();
    } else if (abaPrincipalSelecionada === 1 && paramsIdAgencia) {
      handleAtualizaAgencia();
    } else if (abaPrincipalSelecionada === 0 && !paramsIdContaBancaria) {
      handleCadastraContaBancaria();
    } else if (abaPrincipalSelecionada === 0 && paramsIdContaBancaria) {
      handleAtualizaContaBancaria();
    }
  };

  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <ToastContainer />
      <HeaderCadastros
        withRow={false}
        NomePaginaTitulo={
          abaPrincipalSelecionada === 0 ? "Conta Bancária" : "Agência"
        }
        PaginaConsulta={
          abaPrincipalSelecionada === 0
            ? "/contabilidade/financeiro/conta-bancaria/consulta"
            : "/contabilidade/financeiro/agencias/consulta"
        }
        ButtonSaveFunction={handleSwitchReq}
        PaginaSubtitulo={"Cadastro"}
      />

      <RowToModal>
        <ColButtons md={12}>
          <ButtonOptionsPages>
            <ButtonOptionsFirst
              ativo={abaPrincipalSelecionada === 0}
              onClick={() => setAbaPrincipalSelecionada(0)}
            >
              Conta Bancária
            </ButtonOptionsFirst>
            <ButtonOptionsLast
              ativo={abaPrincipalSelecionada === 1}
              onClick={() => setAbaPrincipalSelecionada(1)}
            >
              Agência
            </ButtonOptionsLast>
          </ButtonOptionsPages>
        </ColButtons>
      </RowToModal>

      {abaPrincipalSelecionada === 0 && (
        <ContaBancariaComponent
          valuesContaBancaria={valuesContaBancaria}
          setValuesContaBancaria={setValuesContaBancaria}
          abaSecundariaSelecionada={abaSecundariaSelecionada}
          setAbaSecundariaSelecionada={setAbaSecundariaSelecionada}
          paramsId={paramsIdContaBancaria}
          inputFonteValue={inputFonteValue}
          setInputFonteValue={setInputFonteValue}
          inputAgencia={inputAgencia}
          setInputAgencia={setInputAgencia}
          inputContaCorrente={inputContaCorrente}
          setInputContaCorrente={setInputContaCorrente}
        />
      )}

      {abaPrincipalSelecionada === 1 && (
        <Agencia
          inputSearchBancoValue={inputSearchBancoValue}
          setInputSearchBancoValue={setInputSearchBancoValue}
          agenciaValues={agenciaValues}
          paramsId={paramsIdAgencia}
          setAgenciaValues={setAgenciaValues}
        />
      )}
    </ContainerToModal>
  );
};

export default ContaBancariaPag;
