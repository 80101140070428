import { useEffect, useState } from "react";
import { useFetchData } from "../../../../hooks/useFetchData";
import { FormatCnpjPaste } from "../../../../utils/FormatacaoDeDados/FormatCnpjPaste";
import { isDateInRange } from "../../../../utils/Gerais/isDateInRange";
import { returnDate } from "../../../../utils/Gerais/ReturnDate";

const useOptions = () => {
  const { data: listaDeClientes } = useFetchData("/cliente");
  const [optionsClientes, setOptionsClientes] = useState([]);
  const [optionsUsuarios, setOptionsUsuarios] = useState([]);
  const { data: listaCpfs } = useFetchData("/pessoas");

  const buscaSistemasAtivosNaGestaoAtualDoCliente = (gestoes) => {
    const dataAtual = returnDate();
    const gestaoAtual = gestoes.find((gestao) =>
      isDateInRange(dataAtual, gestao.dataInicial, gestao.dataFim)
    );

    console.log(gestaoAtual);

    if (!gestaoAtual) {
      return [];
    }

    return gestaoAtual.modulosSistema
      .filter((modulo) => modulo.status === 1)
      .map((modulo) => modulo.modulo);
  };

  useEffect(() => {
    if (listaDeClientes) {
      console.log(listaDeClientes);
      setOptionsClientes(
        listaDeClientes.map((item) => ({
          id: item.id,
          cnpj: FormatCnpjPaste(item.pessoaJuridica.cnpj),
          nome: item.pessoaJuridica.nomeFantasia,
          sistemasAtivos: buscaSistemasAtivosNaGestaoAtualDoCliente(
            item.gestao
          ),
        }))
      );
    }
  }, [listaDeClientes]);

  useEffect(() => {
    if (listaCpfs) {
      console.log(listaCpfs);
      const novoArray = listaCpfs
        .filter((item) => item.dados.nomeCompleto && item.dados.cpf)
        .map((item) => ({
          nome: item.dados.nomeCompleto,
          cpf: item.dados.cpf,
          id: item.dados.id,
        }));

      setOptionsUsuarios(novoArray);
    }
  }, [listaCpfs]);

  return { optionsClientes, optionsUsuarios, listaCpfs };
};

export default useOptions;
