const useImagemBrasao = (setValuesCliente) => {
  const adicionaImagemDoBrasao = (event) => {
    const selectedFile = event.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setValuesCliente((prev) => ({
        ...prev,
        brasao: reader.result.split(",")[1],
      }));
    };
    reader.readAsDataURL(selectedFile);
  };

  return { adicionaImagemDoBrasao };
};

export default useImagemBrasao;
