import { Row, Col, Table } from "react-bootstrap";
import React, { useContext, useState, useEffect } from "react";
import {
  BodyTable,
  // FilterContainer,
  // FilterLabel,
  // FilterSelect,
  // PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
// import InputSvg from "../../../../components/Grid/InputSvg";
import { Icon } from "@iconify/react";
// import outlineSearch from "@iconify/icons-ic/outline-search";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
// import Pagination from "../../../../components/PaginationComponent/Pagination";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import {
  ModalStyled,
  ButtonsDiv,
  DivIconModal,
  IconAlertModal,
} from "../../../../styles/StyledComponents/ModalStyled";
import { arrayAnos } from "./ArraysParametrosIndices";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import styled from "styled-components";
import api from "../../../../utils/api";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const TituloIndicesParametrosStyled = styled.h4`
  color: #515c70;
  margin-bottom: 0;
  margin-left: -4px;
  font-weight: 600;
`;

const IndicesLista = () => {
  //   Taxa de Juros (% anual)	Valor %	Valor %	Valor %	Valor %	Valor %	Valor %
  // Taxa de Câmbio (US$ - final do ano)	Valor R$	Valor R$	Valor R$	Valor R$	Valor R$	Valor R$
  // Inflação (% anual)
  Modal.setAppElement("#root");

  const { currentClient } = useContext(CurrentClientContext);
  const [taxaDeJuros, setTaxaDeJuros] = useState([]);
  const [taxaDeCambio, setTaxaDeCambio] = useState([]);
  const [inflacao, setInflacao] = useState([]);
  const [fontValue, setFontValue] = useState("");

  const [urlPathDelete, setUrlPathDelete] = useState("");

  // const [itens, setItens] = useState([]);
  // const [itensPorPagina, setItensPorPagina] = useState(10);
  // const [currentPage, setCurrentPage] = useState(0);
  // const [searchTerm, setSearchTerm] = useState("");
  // const [itensFiltrados, setItensFiltrados] = useState([]);
  const [loadingLista, setLoadingLista] = useState(true);

  const [modalOpened, setModalOpened] = useState(false);
  // const [dataDelete, setDataDelete] = useState({});

  // const startIndex = currentPage * itensPorPagina;
  // const endIndex = startIndex + itensPorPagina;
  //   const currentItens = itens.slice(startIndex, endIndex);
  //   const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  // const pages = Math.ceil(itens.length / itensPorPagina);
  // const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  // const handleSearch = (event) => {
  //   const { value } = event.target;
  //   setSearchTerm(value);
  //   const filteredItems = itens.filter((item) => {
  //     return item.nome.toLowerCase().includes(value.toLowerCase());
  //   });
  //   setItensFiltrados(filteredItems);
  //   setCurrentPage(0);
  // };

  const fetchData = () => {
    setLoadingLista(true);
    api
      .get(`indices/${currentClient.clienteId}`)
      .then((response) => {
        console.log(response.data);
        setFontValue(response.data.fonte);
        setTaxaDeJuros(
          response.data.indiceTaxaDeJurosPlanejamento.anos.filter((item) =>
            arrayAnos.includes(item.ano)
          )
        );
        setTaxaDeCambio(
          response.data.indiceTaxaDeCambioPlanejamento.anos.filter((item) =>
            arrayAnos.includes(item.ano)
          )
        );
        setInflacao(
          response.data.indiceInflacaoPlanejamento.anos.filter((item) =>
            arrayAnos.includes(item.ano)
          )
        );
        //setItens(response.data);
        setLoadingLista(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [currentClient]);

  function openModal() {
    setModalOpened(true);
  }

  function closeModal() {
    setModalOpened(false);
  }

  //   const confirmDeleteData = (id) => {
  //     setDataDelete({ id: id });
  //     openModal();
  //   };

  const deleteFuncao = () => {
    const urlPath = ["taxaDeJuros", "taxaDeCambio", "inflacao"];
    const anos = arrayAnos.join(",");

    api
      .delete(
        `indices/${urlPath[urlPathDelete]}/${currentClient.clienteId}/${anos}`
      )
      .then(() => {
        toast.success("Valores deletados com sucesso!");
        fetchData();
      })
      .catch((error) => {
        toast.error("Algo deu errado. Por favor, tente mais tarde.");
        console.log(error);
      });
  };

  const confirmDeleteData = (tipoParametro) => {
    setUrlPathDelete(tipoParametro);
    openModal();
    console.log(tipoParametro);
  };

  const anoIgual = (ano, tipoIndice) => {
    let index = tipoIndice.findIndex((item) => item.ano === ano);
    console.log("rodou");

    if (index !== -1) {
      return FormatValueToLocaleString(Number(tipoIndice[index].valor));
    } else {
      return "Valor";
    }
  };

  return (
    <div>
      <ToastContainer />
      <div>
        <ModalStyled
          isOpen={modalOpened}
          onRequestClose={closeModal}
          overlayClassName="modal-overlay"
          contentLabel="Example Modal"
        >
          <DivIconModal>
            <IconAlertModal
              width={100}
              icon="fluent:error-circle-20-regular"
              color="red"
              hexcolor="#ff0000"
            />
            <Icon />
          </DivIconModal>

          <h6>Tem certeza que deseja deletar permanentemente esses dados?</h6>
          <p>Continuar e deletar os dados</p>
          <ButtonsDiv>
            <button
              className="sim_button"
              onClick={() => {
                deleteFuncao();
                closeModal();
              }}
            >
              Sim
            </button>
            <button
              className="nao_button"
              onClick={() => {
                closeModal();
              }}
            >
              Não
            </button>
          </ButtonsDiv>
        </ModalStyled>
      </div>
      <Row
        className="row_form mt-1"
        style={{ borderRadius: "0px 0px 5px 5px" }}
      >
        {loadingLista ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={
            loadingLista
              ? { display: "none" }
              : { marginTop: "-10px", marginBottom: 0 }
          }
        >
          <thead>
            <tr>
              <TituloTable>Título</TituloTable>
              {arrayAnos.map((ano, index) => (
                <TituloTable key={index}>{ano}</TituloTable>
              ))}
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            <tr>
              <td>Taxa de Juros (% anual)</td>
              {arrayAnos.map((ano, index) => (
                <React.Fragment key={index}>
                  {index < 6 && (
                    <td>
                      {taxaDeJuros.length > 0
                        ? anoIgual(ano, taxaDeJuros) + " %"
                        : "Valor %"}
                    </td>
                  )}
                </React.Fragment>
              ))}
              <td>
                <ButtonsAcoesListas
                  IconDelete={false}
                  FuncaoIconDelete={() => confirmDeleteData(0)}
                  CaminhoPagUpdate={`/planejamento/cadastro/parametro-indices/1?query=taxaDeJuros`}
                />
              </td>
            </tr>
            <tr>
              <td>Taxa de Câmbio (US$ - final do ano)</td>
              {arrayAnos.map((ano, index) => (
                <React.Fragment key={index}>
                  {index < 6 && (
                    <td key={index}>
                      {taxaDeCambio.length > 0
                        ? anoIgual(ano, taxaDeCambio) === "Valor"
                          ? "Valor US$ "
                          : "US$  " + anoIgual(ano, taxaDeCambio)
                        : "Valor US$ "}
                    </td>
                  )}
                </React.Fragment>
              ))}
              <td>
                <ButtonsAcoesListas
                  IconDelete={false}
                  FuncaoIconDelete={() => confirmDeleteData(1)}
                  CaminhoPagUpdate={`/planejamento/cadastro/parametro-indices/1?query=taxaDeCambio`}
                />
              </td>
            </tr>
            <tr>
              <td>Inflação (% anual)</td>
              {arrayAnos.map((ano, index) => (
                <React.Fragment key={index}>
                  {index < 6 && (
                    <td>
                      {inflacao.length > 0
                        ? anoIgual(ano, inflacao) === "Valor"
                          ? "Valor %"
                          : "% " + anoIgual(ano, inflacao)
                        : "Valor %"}
                    </td>
                  )}
                </React.Fragment>
              ))}
              <td>
                <ButtonsAcoesListas
                  IconDelete={false}
                  FuncaoIconDelete={() => confirmDeleteData(2)}
                  CaminhoPagUpdate={`/planejamento/cadastro/parametro-indices/1?query=inflacao`}
                />
              </td>
            </tr>
          </BodyTable>
          <TituloTable
            style={{
              paddingLeft: "0.5rem",
              marginTop: "10px",
              display: "block",
            }}
          >
            Fonte: {fontValue}
          </TituloTable>
        </Table>
      </Row>
      {/* <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>

            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row> */}
      <div style={{ padding: "11px" }}></div>
    </div>
  );
};

export default IndicesLista;
