import { Form, Row } from "react-bootstrap";
import styled from "styled-components";

const corBackgroundTagSistema = (nomeSistema) => {
  switch (nomeSistema.toLowerCase()) {
    case "gerenciador":
      return "#515C70";
    case "planejamento":
      return "#F6991E";
    case "contabilidade":
      return "#da499a";
    case "pagamentos":
      return "#89c454";
    case "gestor":
      return "#2f817b";
    case "folha":
      return "#586dba";
    default:
      return "#000";
  }
};

const definePointerEventsAtivo = (pointerEventsAtivo) => {
  if (pointerEventsAtivo === undefined) {
    return "auto";
  }

  if (pointerEventsAtivo) {
    return "auto";
  }

  if (pointerEventsAtivo === false) {
    return "none";
  }
};

export const RowTituloAba = styled(Row)`
  background-color: white;
  border-radius: 5px 5px 0px 0px;
  margin-top: 12px;
  padding: 9px;

  & div span {
    color: #515c70;
    font-weight: 600;
  }
`;

export const RowSelecaoModulosInferior = styled(Row)`
  background-color: white;
  margin-top: 4px;
  border-radius: 0px 0px 5px 5px;
  padding: 0px 18px;
`;

export const TagModulo = styled.div`
  display: flex;
  width: 145px;
  transform: translateX(
    ${({ $translateX }) => ($translateX ? $translateX : "0px")}
  );
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  font-weight: 600;
  color: white;
  background-color: ${({ $nomeSistema, $ativo }) => {
    if ($nomeSistema && $ativo) {
      return corBackgroundTagSistema($nomeSistema);
    } else {
      return "#B1B0B6";
    }
  }};
  border-radius: 10px;
  margin-right: 7px;
  white-space: nowrap;
  transition: transform 0.2s;
  cursor: pointer;
`;

export const DivTagsModulos = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0px;
  overflow-x: hidden;
`;

// export const ColunaPrincipalOptionsSistema = styled(CCol)`
//   margin: 0px 8px;
//   background-color: #f2f2f2;
//   border-radius: 5px;
//   border: 2px solid #e8e8e8;
//   padding: 0 10px;
// `;

export const RowSelecaoSistemas = styled(Row)`
  margin: 0px;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 10px;
  border: 2px solid #e8e8e8;
`;

export const BoxOptionsSistemas = styled.div`
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  padding: 10px;
  margin-right: 10px;
`;

export const LabelCheckStyled = styled(Form.Check)`
  color: #6c7585;
  font-weight: 500;
  padding-left: 0px;
  font-size: 16px;
  line-height: 24px;
  pointer-events: ${({ $pointerEventsAtivo }) =>
    definePointerEventsAtivo($pointerEventsAtivo)};

  input[type="checkbox"] {
    background-color: #f7f7f7;
    cursor: pointer;
    border-radius: 2px;
    border: 2px solid #515c70;
    margin-left: 0px;
    margin-right: 7px;
  }

  input[type="checkbox"]:checked {
    background-color: green;
    border-color: green;
  }

  label {
    pointer-events: none;
    white-space: nowrap;
  }

  @media screen and (max-width: 1370px) {
    label {
      font-size: 13px;
    }
  }
`;

export const ContentModalAlterarSenha = styled.div``;
