import { getReceitasCatEconOrgao } from "./getReceitasCatEconOrgao";
import { getReceitaDespesaCEconomica } from "./getReceitaDespesaCEconomica";
import { getReceitaSegCEconomica } from "./getReceitaSegCEconomica";
import { getReceitasEsfera } from "./getReceitasEsfera";
import { getReceitaPorFonteRec } from "./getReceitaPorFonteRec";
import { getReceitasQdr } from "./getReceitasQdr";
import { getDespesaCatEconOrgao } from "./getDespesaCatEconOrgao";
import { getDespesaQdd } from "./getDespesaQdd";
import { getDespesaSegCEconomica } from "./getDespesaSegCEconomica";
import { getDespesaPorFonteRec } from "./getDespesaPorFonteRec";
import { getQuadroDetalhadoQDDAplicacao } from "./getQuadroDetalhadoQDDAplicacao";
import { getDespesaEsfera } from "./getDespesaEsfera";
import { getDespesaOrgaoUnidade } from "./getDespesaOrgaoUnidade";
import { getDespesaDemonstrativo } from "./getDespesaDemonstrativo";
import { getDespesaVinculado } from "./getDespesaVinculado";
import { getDespesaDemonstrativoUnidade } from "./getDespesaDemonstrativoUnidade";
import { getDespesaDemonstrativoAcao } from "./getDespesaDemonstrativoAcao";
import { getDespesaDemonstrativoPrograma } from "./getDespesaDemonstrativoPrograma";
import { getDespesaCompatibilidade } from "./getDespesaCompatibilidade";
import { getDespesaClassificacao } from "./getDespesaClassificacao";
import { getDespesaDemonstrativoOrgaoFuncao } from "./getDespesaDemonstrativoOrgaoFuncao";
import { getDespesaDemonstrativoRegionalizado } from "./getDespesaDemonstrativoRegionalizado";

export const getDataLoa = {
  //Receita
  getReceitasCatEconOrgao,
  getReceitaDespesaCEconomica,
  getReceitaSegCEconomica,
  getReceitasEsfera,
  getReceitaPorFonteRec,
  getReceitasQdr,
  getDespesaCatEconOrgao,
  getDespesaQdd,
  getDespesaSegCEconomica,
  getDespesaPorFonteRec,
  getQuadroDetalhadoQDDAplicacao,
  getDespesaEsfera,
  getDespesaOrgaoUnidade,
  getDespesaDemonstrativo,
  getDespesaVinculado,
  getDespesaDemonstrativoUnidade,
  getDespesaDemonstrativoAcao,
  getDespesaDemonstrativoPrograma,
  getDespesaCompatibilidade,
  getDespesaClassificacao,
  getDespesaDemonstrativoOrgaoFuncao,
  getDespesaDemonstrativoRegionalizado
};
