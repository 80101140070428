import { MakeADeepCopyArray } from "../../../../../utils/Gerais/MakeADeepCopyArray";

const useFormatData = (
  arraySistemas,
  arrayGestoes,
  valuesCliente,
  verificaSistemaAtivo
) => {
  const formatArraySistemas = (array) => {
    console.log("formatando array sistemas");
    let arrayFormatado = arraySistemas.map((item) => ({
      ...item,
      checked: verificaSistemaAtivo(array, item.sistema),
    }));

    console.log(arrayFormatado);

    return arrayFormatado;
  };

  const formatObjectToPost = () => {
    console.log("format values to post");

    let gestoesDoCliente = MakeADeepCopyArray(valuesCliente.gestao).map(
      (item) => {
        const modulosFormatados = item.modulos
          .filter((modulo) => modulo.checked)
          .map((modulo) => ({
            sistema: modulo.sistema,
          }));

        return {
          ...item,
          modulos: modulosFormatados,
        };
      }
    );

    console.log(gestoesDoCliente);

    return { ...valuesCliente, gestao: gestoesDoCliente };
  };

  return { formatObjectToPost, formatArraySistemas };
};

export default useFormatData;
