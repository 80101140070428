export const SplitArrayIntoChunks = (array, chunkSize) => {
  let chunksArray = [];

  if (!array || array.length === 0) {
    return chunksArray;
  }

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunksArray.push(chunk);
  }
  console.log(chunksArray);
  return chunksArray;
};
