import React, { useState } from "react";
import axios from "axios";
import { Container, Row } from "react-bootstrap";

import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import {
  ColButtons,
  ButtonOptionsFirst,
  ButtonOptionsPages,
  ButtonOptionsLast,
} from "../../../../styles/StyledComponents/ButtonsSelectPage";
// import FuncaoLista from "./FuncaoLista";
// import SubFuncaoLista from "./SubFuncaoLista";
import FuncaoListaPlanejamento from "./FuncaoListaPlanejamento";
import SubFuncaoListaPlanejamento from "./SubFuncaoListaPlanejamento";

function ConsultaFuncaoSubfuncaoPlanejamento() {
  axios.defaults.headers.common["Authorization"] = null;
  const [botaoAtivo, setBotaoAtivo] = useState(0);

  //   const deletUser = (id) => {
  //     axios
  //       .delete(`https://api.savest.com.br/usuario/${id}`)
  //       .then((data) => {
  //         toast.success("Usuario deletado com sucesso");
  //         fetchData();
  //       })
  //       .catch((error) => {
  //         toast.error("Algo deu errado. Por favor, tente mais tarde.");
  //       });
  //   };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        withRow={false}
        botaoNovo={false}
        TituloPagina={botaoAtivo === 0 ? "Função" : "Subfunção"}
        SubTituloPagina={"Consulta"}
        DashboardPage={"/planejamento/cadastro/funcao-subfuncao/dashboard"}
        PaginaRelatorio={"/planejamento/cadastro/funcao-subfuncao/relatorio"}
      />
      <Row>
        <ColButtons md={12}>
          <ButtonOptionsPages>
            <ButtonOptionsFirst
              ativo={botaoAtivo === 0}
              onClick={() => setBotaoAtivo(0)}
            >
              Função
            </ButtonOptionsFirst>
            <ButtonOptionsLast
              ativo={botaoAtivo === 1}
              onClick={() => setBotaoAtivo(1)}
            >
              Subfunção
            </ButtonOptionsLast>
          </ButtonOptionsPages>
        </ColButtons>
      </Row>
      {botaoAtivo === 0 && <FuncaoListaPlanejamento />}
      {botaoAtivo === 1 && <SubFuncaoListaPlanejamento />}
    </Container>
  );
}

export default ConsultaFuncaoSubfuncaoPlanejamento;
