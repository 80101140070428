import React, { useContext, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Icon } from "@iconify/react";
import { especificacoes, descricao } from "./Especificacoes";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { ToastContainer, toast } from "react-toastify";
import { TestButton } from "../../../../utils/TestButton";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import api from "../../../../utils/api";
import { useFetchData } from "../../../../hooks/useFetchData";

const IconAction = ({ icon, color, onClick, style }) => (
  <Icon
    icon={icon}
    color={color}
    width="28"
    style={{ cursor: "pointer", ...style }}
    onClick={onClick}
  />
);

const InfoEspecificacao = ({
  indexEspecificacao,
  providencia,
  indexProvidencia,
  handleChangeCampoEspecificacao,
  deleteProvidenciaEspecificacao,
  showDelete,
  options,
}) => {
  // const [editMode, setEditMode] = useState(false);

  // const handleEdit = () => {
  //   setEditMode(true);
  // };

  // const handleCancel = () => {
  //   setEditMode(false);
  // };

  // const handleSave = () => {
  //   setEditMode(false);
  // };

  return (
    <>
      {options.length > 0 && (
        <RowAninhada style={{ marginTop: "20px" }}>
          <PTitulosInputs style={{ marginLeft: "15px" }}>
            Descrição
          </PTitulosInputs>
          <CCol>
            <FloatingLabelInput
              new
              tipo="input_select"
              placeholder="Descrição"
              options={options !== false && options}
              onSelect={(e) =>
                handleChangeCampoEspecificacao(
                  indexEspecificacao,
                  indexProvidencia,
                  "descricao",
                  e.value
                )
              }
              height="30px"
              value={providencia.descricao}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            {/* {!editMode && (
              <Icon
                icon="bx:edit"
                height="26"
                color="#5971C8"
                style={{ cursor: "pointer", marginTop: "3px" }}
                onClick={handleEdit}
              />
            )}

            {editMode && (
              <>
                <Icon
                  icon="fluent:save-24-filled"
                  height="26"
                  color="#008CFF"
                  style={{ cursor: "pointer", marginTop: "3px" }}
                  onClick={handleSave}
                />
                <Icon
                  icon="ic:baseline-cancel"
                  height="26"
                  color="#F24E1E"
                  style={{
                    cursor: "pointer",
                    marginLeft: "2px",
                    marginTop: "3px",
                  }}
                  onClick={handleCancel}
                />
              </>
            )} */}
          </CColButtonsAcoes2Icons>
        </RowAninhada>
      )}

      <RowAninhada style={{ marginTop: "15px" }}>
        <PTitulosInputs style={{ marginLeft: "15px" }}>
          Providências
        </PTitulosInputs>
        <CCol>
          <FormControlListaDeInputs
            value={providencia.providencia}
            onChange={(e) =>
              handleChangeCampoEspecificacao(
                indexEspecificacao,
                indexProvidencia,
                "providencia",
                e.target.value
              )
            }
          />
        </CCol>
        <CCol md={2}>
          <InputFormatRealBrasileiro
            listaDeInputs={true}
            externalValue={providencia.valor}
            onChange={(e, valor) =>
              handleChangeCampoEspecificacao(
                indexEspecificacao,
                indexProvidencia,
                "valor",
                valor
              )
            }
            value={providencia.valor}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          {/* {!(options.length > 0) && (
            <>
              {!editMode && (
                <Icon
                  icon="bx:edit"
                  height="26"
                  color="#5971C8"
                  style={{ cursor: "pointer", marginTop: "3px" }}
                  onClick={handleEdit}
                />
              )}

              {editMode && (
                <>
                  <Icon
                    icon="fluent:save-24-filled"
                    height="26"
                    color="#008CFF"
                    style={{ cursor: "pointer", marginTop: "3px" }}
                    onClick={handleSave}
                  />
                  <Icon
                    icon="ic:baseline-cancel"
                    height="26"
                    color="#F24E1E"
                    style={{
                      cursor: "pointer",
                      marginLeft: "2px",
                      marginTop: "3px",
                    }}
                    onClick={handleCancel}
                  />
                </>
              )}
            </>
          )} */}
        </CColButtonsAcoes2Icons>
      </RowAninhada>
    </>
  );
};

const RiscosFiscais = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { data } = useFetchData(
    `/riscosFiscais/${currentClient.clienteId}`,
    true,
    null,
    true
  );
  const [arrayEspecificacoes, setArrayEspecificacoes] = useState([]);
  const [inputSelectEspecificacao, setInputSelectEspecificacao] = useState("");

  const especificacaoOrdem = [
    "Demandas Judiciais - PASSIVOS CONTINGENTES",
    "Dívidas em Processo de Reconhecimento - PASSIVOS CONTINGENTES",
    "Avais e Garantias Concedidas - PASSIVOS CONTINGENTES",
    "Assunção de Passivos - PASSIVOS CONTINGENTES",
    "Assistências Diversas - PASSIVOS CONTINGENTES",
    "Outros Passivos Contingentes - PASSIVOS CONTINGENTES",
    "Frustração de Arrecadação - DEMAIS RISCOS FISCAIS PASSIVOS",
    "Restituição de Tributos a Maior - DEMAIS RISCOS FISCAIS PASSIVOS",
    "Discrepância de Projeções - DEMAIS RISCOS FISCAIS PASSIVOS",
    "Outros Riscos Fiscais - DEMAIS RISCOS FISCAIS PASSIVOS",
  ];

  const ordenarEspecificacoes = () => {
    const novoArrayOrdenado = [...arrayEspecificacoes].sort((a, b) => {
      const indexA = especificacaoOrdem.indexOf(a.especificacao);
      const indexB = especificacaoOrdem.indexOf(b.especificacao);

      // Se ambos os índices estão na lista, retorna a diferença
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      // Se apenas o A está na lista, A deve vir antes de B
      if (indexA !== -1) return -1;

      // Se apenas o B está na lista, B deve vir antes de A
      if (indexB !== -1) return 1;

      // Se nenhum dos dois está na lista, mantém a ordem original
      return 0;
    });

    setArrayEspecificacoes(novoArrayOrdenado);
  };

  const estaOrdenado = () => {
    let lastIndex = -1; // Inicialize com um valor que não é um índice válido
    for (const item of arrayEspecificacoes) {
      const currentIndex = especificacaoOrdem.indexOf(item.especificacao);

      // Se o item não estiver na lista de especificações, ignore-o
      if (currentIndex === -1) continue;

      // Verifique se o índice atual é menor ou igual ao último índice
      if (currentIndex < lastIndex) {
        console.log(
          `Item: "${item.especificacao}", Expected Index: ${lastIndex}, Actual Index: ${currentIndex}, Is Correct: false`
        );
        return false; // A ordem não está correta
      }

      // Atualize o último índice para o índice atual
      lastIndex = currentIndex;

      console.log(
        `Item: "${item.especificacao}", Expected Index: ${lastIndex}, Actual Index: ${currentIndex}, Is Correct: true`
      );
    }

    console.log(`Array está ordenado: true`);
    return true; // Se passar por todos, a ordem está correta
  };

  useEffect(() => {
    if (!estaOrdenado()) {
      console.log("Não está");
      ordenarEspecificacoes();
    }
    // eslint-disable-next-line
  }, [arrayEspecificacoes]);

  const adicionaEspecificacao = async () => {
    if (!inputSelectEspecificacao) {
      toast.error("Selecione uma especificação válida para adicionar.");
      return;
    }

    await setArrayEspecificacoes((prev) => {
      if (
        prev.some((item) => item.especificacao === inputSelectEspecificacao)
      ) {
        toast.error(
          "Não é possível adicionar a mesma especificação mais de uma vez."
        );
        return prev;
      }

      return [
        {
          riscosFiscaisId: null,
          clienteId: currentClient.clienteId,
          especificacao: inputSelectEspecificacao,
          providencias: [
            {
              providenciaId: null,
              descricao: "",
              providencia: "",
              valor: "0,00",
            },
          ],
        },
        ...prev,
      ];
    });
    setInputSelectEspecificacao("");
  };

  const handleChangeCampoEspecificacao = (
    indexEspecificacao,
    indexProvidencia,
    nomeCampo,
    valor
  ) => {
    setArrayEspecificacoes((prev) =>
      prev.map((especificacao, i) =>
        i === indexEspecificacao
          ? {
              ...especificacao,
              providencias: especificacao.providencias.map((providencia, j) =>
                j === indexProvidencia
                  ? { ...providencia, [nomeCampo]: valor }
                  : providencia
              ),
            }
          : especificacao
      )
    );
  };

  const adicionaProvidenciaEspecificacao = (indexEspecificacao) => {
    setArrayEspecificacoes((prev) =>
      prev.map((especificacao, i) =>
        i === indexEspecificacao
          ? {
              ...especificacao,
              providencias: [
                ...especificacao.providencias,
                {
                  descricao: "",
                  providencia: "",
                  valor: "0,00",
                },
              ],
            }
          : especificacao
      )
    );
  };

  const deleteProvidenciaEspecificacao = (
    indexEspecificacao,
    indexProvidencia
  ) => {
    setArrayEspecificacoes((prev) =>
      prev.map((especificacao, i) =>
        i === indexEspecificacao
          ? {
              ...especificacao,
              providencias: especificacao.providencias.filter(
                (_, j) => j !== indexProvidencia
              ),
            }
          : especificacao
      )
    );
  };

  const deleteEspecificacao = (indexEspecificacao) => {
    setArrayEspecificacoes((prev) =>
      prev
        .map((especificacao, i) => {
          if (i === indexEspecificacao) {
            if (especificacao.providencias.length > 1) {
              return {
                ...especificacao,
                providencias: especificacao.providencias.slice(0, -1),
              };
            } else {
              return null;
            }
          }
          return especificacao;
        })
        .filter(Boolean)
    );
  };

  const postData = async () => {
    try {
      console.log("valuesToPost: ", arrayEspecificacoes);
      await api.put(
        `/riscosFiscais/${currentClient.clienteId}`,
        arrayEspecificacoes
      );
      toast.success("Riscos Fiscais Atualizados com Sucesso!");
    } catch (error) {
      toast.error(
        error.response.data.message ||
          "Por um erro desconhecido, não foi possível atualizar os Riscos Fiscais!"
      );
      console.log(error);
    }
  };

  useEffect(() => {
    setArrayEspecificacoes([]);
    if (data && data.length > 0) {
      let letsUpdate = [];

      data.map((item, index) => {
        const exists = letsUpdate.some(
          (especificacao) => especificacao.especificacao === item.especificacao
        );

        if (!exists) {
          letsUpdate.push({
            riscosFiscaisId: item.riscosFiscaisId,
            clienteId: currentClient.clienteId,
            especificacao: item.especificacao,
            providencias: item.providencias,
          });
        }
        return null;
      });
      setArrayEspecificacoes(letsUpdate);
    }
    // eslint-disable-next-line
  }, [data]);

  // useEffect(() => {
  //   if (data && data.length > 0) {
  //     let letsUpdate = [...arrayEspecificacoes];

  //     data.forEach((item) => {
  //       const existingItemIndex = letsUpdate.findIndex(
  //         (especificacao) => especificacao.especificacao === item.especificacao
  //       );

  //       if (existingItemIndex === -1) {
  //         letsUpdate.push({
  //           clienteId: currentClient.clienteId,
  //           especificacao: item.especificacao,
  //           providencias: [
  //             {
  //               descricao: item.descricao,
  //               providencia: item.providencia,
  //               valor: item.valor,
  //             },
  //           ],
  //         });
  //       } else {
  //         letsUpdate[existingItemIndex].providencias.push({
  //           descricao: item.descricao,
  //           providencia: item.providencia,
  //           valor: item.valor,
  //         });
  //       }
  //     });

  //     setArrayEspecificacoes(letsUpdate);
  //   }
  //   // eslint-disable-next-line
  // }, [data, currentClient]);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo="LDO"
        PaginaSubtitulo="Riscos Fiscais"
        ButtonSaveFunction={() => postData()}
      />
      <ToastContainer />
      <Row
        className={`mt-1 ${
          arrayEspecificacoes.length > 0 ? "row_form" : "row_form_2"
        }`}
      >
        <CCol>
          <FloatingLabelInput
            new
            tipo="input_select"
            placeholder="Especificações"
            label="Especificações"
            options={[
              { label: "Especificações", value: null },
              ...especificacoes,
            ]}
            onSelect={(option) => setInputSelectEspecificacao(option.value)}
            value={inputSelectEspecificacao}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAction
            icon="ic:baseline-add-box"
            color="#105200"
            onClick={adicionaEspecificacao}
            style={{ marginTop: "-2px" }}
          />
        </CColButtonsAcoes2Icons>
      </Row>

      {arrayEspecificacoes.map((especificacao, indexEspecificacao) => (
        <Row
          key={indexEspecificacao}
          className={`mt-1 ${
            arrayEspecificacoes.length - 1 === indexEspecificacao
              ? "row_form_2"
              : "row_form"
          }`}
        >
          <RowAninhada>
            <CCol>
              <PTitulosInputs>Especificação</PTitulosInputs>
              <FloatingLabelInput
                style={{ border: "none" }}
                disabled
                value={especificacao.especificacao}
              />
            </CCol>
            <CColButtonsAcoes2Icons md={1}>
              <PTitulosInputs>Ação</PTitulosInputs>
              <IconAction
                icon="ic:baseline-add-box"
                color="#105200"
                onClick={() =>
                  adicionaProvidenciaEspecificacao(indexEspecificacao)
                }
                style={{ marginTop: "4px" }}
              />
              <IconAction
                icon="ic:baseline-delete"
                color="#E79900"
                onClick={() => deleteEspecificacao(indexEspecificacao)}
                style={{ marginTop: "4px" }}
              />
            </CColButtonsAcoes2Icons>
          </RowAninhada>

          {especificacao.providencias.map((providencia, indexProvidencia) => (
            <InfoEspecificacao
              key={indexProvidencia}
              providencia={providencia}
              indexProvidencia={indexProvidencia}
              indexEspecificacao={indexEspecificacao}
              handleChangeCampoEspecificacao={handleChangeCampoEspecificacao}
              deleteProvidenciaEspecificacao={deleteProvidenciaEspecificacao}
              options={descricao[especificacao.especificacao] || false}
            />
          ))}
        </Row>
      ))}
      <TestButton onClick={() => console.log(arrayEspecificacoes)}>
        Console
      </TestButton>
      <TestButton onClick={() => console.log(data)}>Data</TestButton>
      <TestButton onClick={() => setArrayEspecificacoes([])}>
        Limpar Valores
      </TestButton>
      <TestButton onClick={() => ordenarEspecificacoes()}>
        Organizar Tudo
      </TestButton>
    </Container>
  );
};

export default RiscosFiscais;
