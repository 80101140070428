import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Container } from "react-bootstrap";
import { Icon } from "@iconify/react";
import "../../styles/Css/TarefasPrazos/tarefas_e_prazos_mensal.css";
import { NavLink } from "react-router-dom";

const DataArrowsDivMensal = styled.div`
  padding: 2px;
  transform: translateX(-45px);

  span {
    font-size: 15px;
  }
`;

const NavLinkStyledHojeMensal = styled(NavLink)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  width: 80px;
  padding-top: 3px;
  padding-bottom: 3px;
  background: transparent;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-left: 9px;
  color: #515c70;

  :hover {
    background-color: #ced4da;
    color: #515c70;
  }
`;

const IconArrowsData = styled(Icon)`
  margin: 0px 10px 0px 10px;
  transform: translateY(-1px);
`;

const ButtonArrowsData = styled.button`
  border: none;
  background: transparent;
`;

const ButtonDataSelect = styled.button`
  width: 45px;
  height: 45px;
  border-color: transparent;
  border-radius: 5px;
`;

const ColStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DivDropsTasks = styled.div`
  padding-left: 5px;
  padding-right: 5px;
`;

const TituloTasks = styled.h6`
  color: #515c70;
  margin-bottom: 15px;
  font-size: 18px;
`;

const ButtonCategoriaTask = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 13px 10px 13px 10px;
  border-radius: 10px;
  border: transparent;
  color: #515c70;
  margin-bottom: 10px;
  font-size: 18px;

  svg {
    margin-right: 10px;
  }
`;

const TarefasPrazosMensal = () => {
  const ArrayButtonsQuant = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
  ];

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      {/*Row do Titulo Da Página*/}
      <Row>
        <Col md={12}>
          <div className="d-flex space_between titulo_conteudo">
            <span className="cor_letra_primaria fw-bolder">
              Tarefas e Prazos
            </span>

            <div className="header_conteudo">
              <Button variant="success">Adicionar</Button>
            </div>
          </div>
        </Col>
      </Row>

      {/*Row do Cabeçalho do Conteudo*/}
      <Row>
        <Col
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <NavLinkStyledHojeMensal to="/planejamento/tarefas-prazos-hoje">
            Mensal
          </NavLinkStyledHojeMensal>
          <DataArrowsDivMensal>
            <ButtonArrowsData>
              <IconArrowsData
                icon="material-symbols:arrow-back-ios-new-rounded"
                color="#515c70"
              />
            </ButtonArrowsData>
            <span>JANEIRO 2023</span>
            <ButtonArrowsData>
              <IconArrowsData
                icon="material-symbols:arrow-back-ios-new-rounded"
                color="#515c70"
                rotate={2}
              />
            </ButtonArrowsData>
          </DataArrowsDivMensal>
          <div className="header_body_nav"></div>
        </Col>
      </Row>

      {/*Row seletor de data */}
      <Row>
        <ColStyled
          className="d-flex align-items-center mt-1 p-2 div_seletores_data"
          md={12}
        >
          <ButtonArrowsData>
            <IconArrowsData
              icon="material-symbols:arrow-back-ios-new-rounded"
              color="#515c70"
              width={30}
            />
          </ButtonArrowsData>

          {ArrayButtonsQuant.map((item) => (
            <ButtonDataSelect>{item}</ButtonDataSelect>
          ))}

          <ButtonArrowsData>
            <IconArrowsData
              icon="material-symbols:arrow-back-ios-new-rounded"
              color="#515c70"
              rotate={2}
              width={30}
            />
          </ButtonArrowsData>
        </ColStyled>
      </Row>

      {/*Form da Página*/}
      <Row style={{ borderRadius: "0 0 5px 5px" }} className="row_form mt-1">
        <Col md={12}>
          <DivDropsTasks>
            <TituloTasks>Diagnóstico</TituloTasks>
            <ButtonCategoriaTask>
              <Icon
                icon="material-symbols:arrow-back-ios-new-rounded"
                color="#515c70"
                rotate={3}
                width={20}
              />
              Financeiro
            </ButtonCategoriaTask>
            <ButtonCategoriaTask>
              <Icon
                icon="material-symbols:arrow-back-ios-new-rounded"
                color="#515c70"
                rotate={3}
                width={20}
              />
              Conciliação bancária
            </ButtonCategoriaTask>
            <ButtonCategoriaTask>
              <Icon
                icon="material-symbols:arrow-back-ios-new-rounded"
                color="#515c70"
                rotate={3}
                width={20}
              />
              Conferência Empenhos
            </ButtonCategoriaTask>
            <ButtonCategoriaTask>
              <Icon
                icon="material-symbols:arrow-back-ios-new-rounded"
                color="#515c70"
                rotate={3}
                width={20}
              />
              Atualização Orçamentária
            </ButtonCategoriaTask>
            <TituloTasks style={{ marginTop: "15px" }}>Exportações</TituloTasks>
            <ButtonCategoriaTask>
              <Icon
                icon="material-symbols:arrow-back-ios-new-rounded"
                color="#515c70"
                rotate={3}
                width={20}
              />
              Exportação TCE
            </ButtonCategoriaTask>
            <ButtonCategoriaTask>
              <Icon
                icon="material-symbols:arrow-back-ios-new-rounded"
                color="#515c70"
                rotate={3}
                width={20}
              />
              Protocolo de Envio
            </ButtonCategoriaTask>
            <TituloTasks style={{ marginTop: "15px" }}>Siconfi</TituloTasks>
            <ButtonCategoriaTask>
              <Icon
                icon="material-symbols:arrow-back-ios-new-rounded"
                color="#515c70"
                rotate={3}
                width={20}
              />
              Exportação Siconfi
            </ButtonCategoriaTask>
            <ButtonCategoriaTask>
              <Icon
                icon="material-symbols:arrow-back-ios-new-rounded"
                color="#515c70"
                rotate={3}
                width={20}
              />
              Protocolo de Envio
            </ButtonCategoriaTask>
          </DivDropsTasks>
        </Col>
      </Row>
    </Container>
  );
};

export default TarefasPrazosMensal;
