import React from "react";
import { Row } from "react-bootstrap";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
import { InputDateRelatorio } from "../../../../components/Inputs/InputDateRelatorio";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

const EvolucaoObra = () => {
  return (
    <Row className={"row_form mt-1"}>
      <RowAninhada>
        <CCol md={3}>
          <InputDateRelatorio
            label={"Data Início"}
            placeholder={"Data Início"}
          />
        </CCol>
        <CCol md={4}>
          <InputDateRelatorio
            label={"Previsão de Conclusão"}
            placeholder={"Previsão de Conclusão"}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            new={true}
            holdSelect
            style={{
              color: "#515c70",
            }}
            tipo="input_select"
            label="Situação Atual"
            placeholder="Situação Atual"
            options={[{ label: "Situação Atual", value: "Situação Atual" }]}
          />
        </CCol>
      </RowAninhada>
    </Row>
  );
};

export default EvolucaoObra;
