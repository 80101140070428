import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Container } from "react-bootstrap";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { useGenerateRelatorio } from "../../../../../hooks/useGenerateRelatorio";
import { CurrentYearContext } from "../../../../../contexts/YearContext";
import { CurrentClientContext } from "../../../../../contexts/CurrentClientContext";
import { AuthContext } from "../../../../../contexts/AuthContext";
import HeaderCadastros from "../../../../../components/HeaderCadastros/HeaderCadastros";
import PagRelatorioComponente from "../../../../../components/ComponenteDeRelatorio/PagRelatorioComponente";
import { PdfFuncaoSubfuncao } from "./PdfFuncaoSubfuncao";
import VisualizacaoRelatorioFuncaoSubFuncao from "./VisualizacaoRelatorioFuncaoSubfuncao";
import { DivPrintOnly } from "../../../../../styles/StyledComponents/StylesRelatorios";
import GlobalStyles from "../../../../../styles/StyledComponents/GlobalStyles";
import {
  getSelectedWithValues,
  hasAnySelected,
} from "../../../../../utils/RelatorioDocx/FuncoesRelatorioDocx";
import { generateDocxBlob } from "../../../../../utils/RelatorioDocx/DocxRelatorio";
import {
  processarDadosRelatorio,
  getSelected,
} from "../../../../../utils/RelatorioDocx/FuncoesRelatorioDocx";
import { isBase64 } from "../../../../../utils/Gerais/isBase64";

const RelatorioFuncaoSubFuncao = () => {
  const relatorioName = "Relatório Função - Subfunção";

  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useContext(CurrentYearContext);
  const { userAuthData } = useContext(AuthContext);
  const [selectBoxesOptionsAbaPrincipal, setSelectBoxesOptionsAbaPrincipal] =
    useState([]);
  const [selectBoxesOptionsAbaOptions, setSelectBoxesOptionsAbaOptions] =
    useState([
      {
        opcao: "Exibir",
        checkBoxes: [
          { label: "Código", value: "valor", selected: true },
          { label: "Tipo de Vínculo", value: "nome", selected: true },
          { label: "Informação", value: "descricao", selected: true },
        ],
      },
      {
        opcao: "Exibir",
        checkBoxes: [
          { label: "Código", value: "valor", selected: true },
          { label: "Função", value: "nome", selected: true },
          { label: "Informação", value: "descricao", selected: true },
        ],
      },
    ]);
  const { data: dataFuncao } = useFetchData("/funcao/funcoes");
  const { data: dataSubFuncao } = useFetchData("/subfuncao/subfuncoes");

  const {
    dadosRelatorio,
    dataClientInfos,
    handleGeneratePdf,
    handleGenerateXls,
    handlePrint,
    pdfData,
  } = useGenerateRelatorio(
    selectBoxesOptionsAbaOptions,
    selectBoxesOptionsAbaPrincipal,
    currentClient,
    relatorioName
  );

  const handleGenerateDocx = async () => {
    const dadosRelatorio = getAllDataRelatorio();
    const title = "Função/Subfunção";
    const pdfContent = {
      dadosRelatorio: [
        processarDadosRelatorio(
          dadosRelatorio.funcao,
          selectBoxesOptionsAbaOptions,
          0
        ),
        processarDadosRelatorio(
          dadosRelatorio.subfuncao,
          selectBoxesOptionsAbaOptions,
          1
        ),
      ],
      columnWidth: [
        getSelectedWithValues(selectBoxesOptionsAbaOptions[0], [10, 30, 60]),
        getSelectedWithValues(selectBoxesOptionsAbaOptions[1], [10, 30, 60]),
      ],
      opcoes: [
        getSelected(selectBoxesOptionsAbaOptions, 0),
        getSelected(selectBoxesOptionsAbaOptions, 1),
      ],
      userData: {
        currentClient,
        userAuthData,
      },
      dateRelatorio: { year: currentYear },
    };

    if (
      hasAnySelected(selectBoxesOptionsAbaOptions) &&
      hasAnySelected(selectBoxesOptionsAbaPrincipal)
    ) {
      toast.promise(
        generateDocxBlob({ pdfContent }, title, dataClientInfos[0].brasao, 20, [
          "Função",
          "Subfunção",
        ]).then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = title + ".docx";
          link.click();
          URL.revokeObjectURL(url);
        }),
        {
          pending: "Gerando o documento...",
          success: "Documento gerado com sucesso!",
          error: "Falha ao gerar o documento.",
        }
      );
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  const getDadosRelatorio = (option, data) => {
    const selectBox = selectBoxesOptionsAbaPrincipal?.find(
      (item) => item.opcao === option
    );

    const selectBoxSelected = selectBox?.checkBoxes.some(
      (item) => item.selected
    );

    if (!selectBoxSelected) return [];

    const itensSelecionados = selectBox.checkBoxes
      .filter((item) => item.selected)
      .map((checkBox) => checkBox.label.replace(/[^0-9]/g, ""));

    const dadosSelecionados = data?.filter((item) =>
      itensSelecionados.includes(item.valor)
    );

    console.log("dadosSelecionados: ", dadosSelecionados);
    return dadosSelecionados;
  };

  const getAllDataRelatorio = () => {
    const dataRelatorioFuncao = getDadosRelatorio("Função", dataFuncao);
    const dataRelatorioSubfuncao = getDadosRelatorio(
      "Subfunção",
      dataSubFuncao
    );
    const dadosRelatorio = {
      funcao: dataRelatorioFuncao,
      subfuncao: dataRelatorioSubfuncao,
    };
    return dadosRelatorio;
  };

  const handleGenerateDataXls = () => {
    const dadosRelatorio = getAllDataRelatorio();

    const dataTable1 = dadosRelatorio.funcao
      ?.map((item) => {
        const selectedFields = {};
        selectBoxesOptionsAbaOptions[0]?.checkBoxes?.forEach(
          ({ label, value, selected }) => {
            const actualItem = item[value];
            if (selected && !isBase64(actualItem)[0]) {
              selectedFields[label] = actualItem;
            }
          }
        );
        return Object.keys(selectedFields).length > 0 ? selectedFields : null;
      })
      .filter((item) => item);
    console.log("DATAAA: ", dataTable1);

    const dataTable2 = dadosRelatorio.subfuncao
      ?.map((item) => {
        const selectedFields = {};
        selectBoxesOptionsAbaOptions[1]?.checkBoxes?.forEach(
          ({ label, value, selected }) => {
            const actualItem = item[value];
            if (selected && !isBase64(actualItem)[0]) {
              selectedFields[label] = actualItem;
            }
          }
        );
        return Object.keys(selectedFields).length > 0 ? selectedFields : null;
      })
      .filter((item) => item);
    console.log("DATAAA: ", dataTable2);
    const relatorioCompleto = [dataTable1, dataTable2].filter(
      (data) => data.length > 0
    );
    if (relatorioCompleto.length !== 0) {
      handleGenerateXls(relatorioCompleto, [
        "Relatório - Função",
        "Relatório - Subfunção",
      ]);
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  useEffect(() => {
    if (
      dataFuncao &&
      !selectBoxesOptionsAbaPrincipal?.some((item) => item.opcao === "Função")
    ) {
      console.log(dataFuncao);

      setSelectBoxesOptionsAbaPrincipal((prev) => [
        ...prev,
        {
          opcao: "Função",
          checkBoxes: dataFuncao
            .filter((item) => item.valor !== "00")
            .map((item) => ({
              label: `${item.valor} - ${
                item.nome.length > 40
                  ? `${item.nome.substring(0, 40)}...`
                  : item.nome
              }`,
              value: item.id,
              selected: false,
            })),
        },
      ]);
    }
    if (
      dataSubFuncao &&
      !selectBoxesOptionsAbaPrincipal?.some(
        (item) => item.opcao === "Subfunção"
      )
    ) {
      console.log(dataSubFuncao);

      setSelectBoxesOptionsAbaPrincipal((prev) => [
        ...prev,
        {
          opcao: "Subfunção",
          checkBoxes: dataSubFuncao.map((item) => ({
            label: `${item.valor} - ${
              item.nome.length > 40
                ? `${item.nome.substring(0, 40)}...`
                : item.nome
            }`,
            value: item.id,
            selected: false,
          })),
        },
      ]);
    }
  }, [dataFuncao, dataSubFuncao, selectBoxesOptionsAbaPrincipal]);

  return (
    <>
      {/* {navigateRelatorio && Object.keys(dadosRelatorio).length > 0 && (
        <VizualizacaoRelatorio
          dadosRelatorio={dadosRelatorio}
          setNavigateRelatorio={setNavigateRelatorio}
        />
      )} */}
      <GlobalStyles orientation={"landscape"} />
      {dadosRelatorio && (
        <DivPrintOnly>
          <VisualizacaoRelatorioFuncaoSubFuncao
            dadosRelatorio={dadosRelatorio}
            dataClientInfos={dataClientInfos}
            numeroDaPagina={1}
            totalDePaginas={2}
          />
        </DivPrintOnly>
      )}

      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina hide_on_print"
      >
        <HeaderCadastros
          PaginaConsulta={"/planejamento/cadastro/funcao-subfuncao/consulta"}
          NomePaginaTitulo={"Função - Subfunção"}
          PaginaSubtitulo={"Relatório"}
          BotaoNovoAtivo={false}
          BotaoSaveAtivo={false}
          OpcoesDeArquivo={true}
          DashboardPage={"/planejamento/cadastro/funcao-subfuncao/dashboard"}
          ButtonVisualizar={() => handlePrint(getAllDataRelatorio)}
          ButtonPDF={(action) =>
            handleGeneratePdf(PdfFuncaoSubfuncao, getAllDataRelatorio, action)
          }
          ButtonDocx={handleGenerateDocx}
          PdfData={pdfData}
          ButtonXls={handleGenerateDataXls}
        />
        <ToastContainer />
        {/* <PdfFuncaoSubfuncao pdfContent={PdfData.pdfContent}/> */}
        <PagRelatorioComponente
          topoEsquerdoOptions={[
            { label: "Relatório", value: null },
            { label: "Relatório", value: 1 },
          ]}
          principalOptions={[
            {
              label: "Função",
              value: "Função",
            },
            {
              label: "Subfunção",
              value: "Subfunção",
            },
          ]}
          labelsCheckAbaPrincipal={selectBoxesOptionsAbaPrincipal}
          setLabelsCheckAbaPrincipal={setSelectBoxesOptionsAbaPrincipal}
          labelsCheckAbaOpcoes={selectBoxesOptionsAbaOptions}
          setLabelsCheckAbaOpcoes={setSelectBoxesOptionsAbaOptions}
          hasAssinatura={false}
          hasFavorite={false}
        />
      </Container>
    </>
  );
};

export default RelatorioFuncaoSubFuncao;
