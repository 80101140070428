import React from "react";
import "../SideBarSistemas/style.css";
import IconModulosHeader from "../SvgComponents/IconModulosHeader";

function IconSistemas() {
  return (
    <div>
      <IconModulosHeader />
    </div>
  );
}

export default IconSistemas;
