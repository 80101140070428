import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowForm,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { useFetchData } from "../../../../hooks/useFetchData";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { MotivoAba } from "./MotivoAba";
import { FonteDeRecursosAba } from "./FonteDeRecursosAba";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";

const AnulacaoDeReceita = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();

  const { data: NatRecList } = useFetchData(
    `naturezaDaReceita/por-cliente/${currentClient.clienteId}/${currentYear}/filtro?codigo=`
  );

  const [optionsNatRec, setOptionsNatRec] = useState([]);
  const [inputAutocompleteNatRec, setInputAutocompletNatRec] = useState("");
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Motivos",
    numeroAba: 0,
  });

  useEffect(() => {
    if (NatRecList) {
      setOptionsNatRec(
        NatRecList.map((item) => ({
          id: item.naturezaDaReceita.id,
          codigo: item.naturezaDaReceita.nr,
          titulo: item.naturezaDaReceita.especificacao,
        }))
      );
    }
  }, [NatRecList]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        PaginaSubtitulo={"Cadastro"}
        NomePaginaTitulo={"Anulação de Receita"}
      />
      <RowForm className="mt-1">
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            label="Número"
            placeholder="Número"
            maxLength="7"
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            placeholder="Tipo de Anulação"
            options={[
              {
                label: "1 - Anulação - Remove o Registro Contábil",
                value: "1 - Anulaçao",
              },
              {
                label: "2 - Restituição - Dedução da Receita Orçamentária",
                value: "2 - Restituição",
              },
            ]}
          />
        </CCol>
        <CCol md={2}>
          <InputData />
        </CCol>
      </RowForm>

      <RowForm className="mt-1">
        <CCol md={1}>
          <PTitulosInputs>Guia</PTitulosInputs>
          <FormControlListaDeInputs />
        </CCol>
        <CCol>
          <PTitulosInputs>Natureza da Receita</PTitulosInputs>
          <MyAutoComplete
            inputValue={inputAutocompleteNatRec}
            setInputValue={setInputAutocompletNatRec}
            options={optionsNatRec}
            labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
          />
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Saldo da Guia</PTitulosInputs>
          <FormControlListaDeInputs />
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Valor Anulação</PTitulosInputs>
          <FormControlListaDeInputs />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar />
        </CColButtonsAcoes2Icons>

        <RowAninhada style={{ marginTop: "16px" }}>
          <CCol md={1}></CCol>
          <CCol></CCol>
          <CCol
            md={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ margin: 0, padding: 0, fontWeight: 600 }}>
              Total Anulação
            </p>
          </CCol>
          <CCol md={2}>
            <FormControlListaDeInputs
              disabled
              style={{ border: "none", borderRadius: 20 }}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
        </RowAninhada>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={["Motivo", "Fonte de Recursos"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />
      {abaSelecionada.nomeAba === "Motivo" && <MotivoAba />}
      {abaSelecionada.nomeAba === "Fonte de Recursos" && <FonteDeRecursosAba />}

      <RowForm style={{ marginTop: "16px" }} $borderRadiusType={3}>
        <CCol>
          <MyAutoComplete
            labelInput="Caixa e Equivalente de Caixa"
            options={optionsNatRec}
            labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            label="Doc Financeiro"
            placeholder="Doc Financeiro"
          />
        </CCol>
      </RowForm>
    </Container>
  );
};

export default AnulacaoDeReceita;
