export const processarDadosRelatorio = (
  dadosRelatorio,
  selectBoxesOptionsAbaOptions,
  aba = 0
) => {
  console.log("DadosRelatórios de entrada da aba ", aba, ": ", dadosRelatorio);
  const valoresSelecionados = selectBoxesOptionsAbaOptions[aba].checkBoxes
    .filter((checkBox) => checkBox.selected)
    .reduce(
      (acc, checkBox) => {
        const [primeiraParte, segundaParte] = checkBox.value.split(".", 2);
        const [extraPrimeiraParte, extraSegundaParte] = (
          checkBox.extra || ""
        ).split(".", 2);

        acc.primeiraParte.push(primeiraParte || "");
        acc.segundaParte.push(segundaParte || "");
        acc.extraPrimeiraParte.push(extraPrimeiraParte || "");
        acc.extraSegundaParte.push(extraSegundaParte || "");

        return acc;
      },
      {
        primeiraParte: [],
        segundaParte: [],
        extraPrimeiraParte: [],
        extraSegundaParte: [],
      }
    );

  console.log("Primeiras Partes: ", valoresSelecionados.primeiraParte);
  console.log("Segundas Partes: ", valoresSelecionados.segundaParte);
  console.log(
    "Extra Primeiras Partes: ",
    valoresSelecionados.extraPrimeiraParte
  );
  console.log("Extra Segundas Partes: ", valoresSelecionados.extraSegundaParte);

  return dadosRelatorio.map((item) => {
    const novoObjeto = {};

    valoresSelecionados.primeiraParte.forEach((valor, index) => {
      if (item.hasOwnProperty(valor)) {
        const segundaParte = valoresSelecionados.segundaParte[index];
        const extraPrimeiraParte =
          valoresSelecionados.extraPrimeiraParte[index];
        const extraSegundaParte = valoresSelecionados.extraSegundaParte[index];

        let valorFinal = item[valor] || "";
        let extraValorFinal = "";

        if (extraPrimeiraParte) {
          if (typeof item[extraPrimeiraParte] === "object") {
            extraValorFinal = JSON.stringify(item[extraPrimeiraParte]);
            if (
              extraSegundaParte &&
              typeof item[extraPrimeiraParte] === "object"
            ) {
              extraValorFinal =
                item[extraPrimeiraParte][extraSegundaParte] || "";
            }
          } else {
            extraValorFinal = item[extraPrimeiraParte] || "";
          }
        }

        if (segundaParte && typeof item[valor] === "object") {
          valorFinal = item[valor][segundaParte] || "";
        }

        let chave = segundaParte || valor;
        if (extraPrimeiraParte) {
          valorFinal = `${valorFinal} - ${extraValorFinal}`;
        }
        let contador = 1;
        while (novoObjeto.hasOwnProperty(chave)) {
          chave = `${segundaParte || valor}_${contador}`;
          contador++;
        }

        novoObjeto[chave] = valorFinal;
      }
    });

    return novoObjeto;
  });
};

export const getSelected = (opcoes, aba = 0) => {
  return opcoes[aba].checkBoxes
    .filter((checkBox) => checkBox.selected)
    .map((checkBox) => checkBox.label);
};

export const getSelectedWithValues = (opcoes, valores) => {
  const selectedValues = opcoes.checkBoxes
    .map((checkBox, index) => (checkBox.selected ? valores[index] : null))
    .filter((value) => value !== null);
  const sum = selectedValues.reduce((acc, curr) => acc + curr, 0);
  const adjustedValues = selectedValues.map((value) => (value / sum) * 100);
  console.log("Tamanhos: ", adjustedValues);
  return adjustedValues;
};

export const hasAnySelected = (opcoes) => {
  return opcoes.some((aba) =>
    aba.checkBoxes.some((checkBox) => checkBox.selected)
  );
};
