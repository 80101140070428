import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { PTitulosInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import { Icon } from "@iconify/react/dist/iconify.js";
import styled from "styled-components";
import { TestButton } from "../../../../utils/TestButton";

const exampleValue = [
  {
    "Exercício 2021": [
      { input1: "0" },
      { input2: "0" },
      { input3: "0" },
      { input4: "0" },
    ],
  },
  {
    "Exercício 2022": [
      { input1: "0" },
      { input2: "0" },
      { input3: "0" },
      { input4: "0" },
    ],
  },
];

const OverflowRow = styled(Row)`
  max-height: 105px;
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  overflow: auto;
`;

const exampleTitles = ["Teste1", "Teste2", "Teste3", "Teste4"];

const Saldos = ({
  data = exampleValue,
  titles = exampleTitles,
  nameBefore,
}) => {
  const [inputs, setInputs] = useState(exampleValue);
  useEffect(() => {
    setInputs(data);
  }, [data]);

  const handleInputChange = (index, objIndex, newValue) => {
    const updatedInputs = [...inputs];
    updatedInputs[index][Object.keys(updatedInputs[index])[0]][objIndex][
      Object.keys(
        updatedInputs[index][Object.keys(updatedInputs[index])[0]][objIndex]
      )[0]
    ] = newValue;

    setInputs(updatedInputs);
  };

  return (
    <OverflowRow className="row_form mt-1">
      {inputs.map((value, index) => (
        <RowAninhada
          key={index}
          style={{
            justifyContent: "center",
            marginTop: index > 0 ? "15px" : "0",
          }}
        >
          <CCol
            md={2}
            style={{
              marginTop: index === 0 ? "12px" : "2px",
              color: "#515C70",
              fontWeight: "500",
              width: "160px",
            }}
          >
            {nameBefore && nameBefore}
            {Object.keys(value)[0]}
          </CCol>
          {Object.entries(value[Object.keys(value)[0]]).map(
            ([key, objValue], objIndex) => (
              <CCol key={objIndex}>
                {index === 0 && (
                  <PTitulosInputs>{titles[objIndex]}</PTitulosInputs>
                )}
                <FloatingLabelInput
                  height={"30px"}
                  value={Object.values(objValue)[0]}
                  style={{ color: "#515c70" }}
                  onChange={(e) =>
                    handleInputChange(index, objIndex, e.target.value)
                  }
                />
              </CCol>
            )
          )}
          <CColButtonsAcoes2Icons md={1}>
            {index === 0 && <PTitulosInputs>Ação</PTitulosInputs>}
            <Icon
              icon="mdi:equal-box"
              color="#689bf6"
              height="28"
              style={{
                cursor: "pointer",
                marginLeft: "0px",
                marginTop: "1px",
              }}
            />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
      ))}
      <TestButton onClick={() => console.log(data)}>Print Saldo</TestButton>
    </OverflowRow>
  );
};

export default Saldos;
