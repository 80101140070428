import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Container } from "react-bootstrap";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { useGenerateRelatorio } from "../../../../../hooks/useGenerateRelatorio";
import { CurrentClientContext } from "../../../../../contexts/CurrentClientContext";
import { DivPrintOnly } from "../../../../../styles/StyledComponents/StylesRelatorios";
import GlobalStyles from "../../../../../styles/StyledComponents/GlobalStyles";
import HeaderCadastros from "../../../../../components/HeaderCadastros/HeaderCadastros";
import PagRelatorioComponente from "../../../../../components/ComponenteDeRelatorio/PagRelatorioComponente";
import { PdfFonteDeRecurso } from "./PdfFonteDeRecurso";
import VizualizacaoRelatorioFRecurso from "./VizualizacaoRelatorioFRecurso";
import { isBase64 } from "../../../../../utils/Gerais/isBase64";
import { useCurrentYear } from "../../../../../hooks/useCurrentYear";

const RelatorioFonteDeRecurso = () => {
  const { currentYear } = useCurrentYear();
  const relatorioName = "Relatório Fonte de Recurso";
  const columnWidth = [6, 6, 6, 33.5, 33.5, 15];
  const { currentClient } = useContext(CurrentClientContext);
  const [vinculos, setVinculos] = useState([]);
  const [selectBoxesOptionsAbaOptions, setSelectBoxesOptionsAbaOptions] =
    useState([
      {
        opcao: "Exibir", //"Vínculos",
        checkBoxes: [
          { label: "E", value: "e", selected: true },
          { label: "FR", value: "fr", selected: true },
          { label: "CO", value: "co", selected: true },
          { label: "Título", value: "titulo", selected: true },
          { label: "Função", value: "descricao", selected: true },
          { label: "Vínculo", value: "funcao", selected: true },
        ],
      },
    ]);
  const [selectBoxesOptionsAbaPrincipal, setSelectBoxesOptionsAbaPrincipal] =
    useState([]);
  const { data: dataFonteDeRecursos } = useFetchData(
    `/fonteDeRecurso/por-ano/${currentYear}`
  );

  const {
    dadosRelatorio,
    dataClientInfos,
    handleGenerateDocx,
    handleGeneratePdf,
    handleGenerateXls,
    handlePrint,
    pdfData,
  } = useGenerateRelatorio(
    selectBoxesOptionsAbaOptions,
    selectBoxesOptionsAbaPrincipal,
    currentClient,
    relatorioName,
    columnWidth
  );

  const getDadosRelatorio = () => {
    const selectBox = selectBoxesOptionsAbaPrincipal?.find(
      (item) => item.opcao === "Vinculos"
    );

    const selectBoxSelected = selectBox?.checkBoxes.some(
      (item) => item.selected
    );

    if (!selectBoxSelected) return [];

    const itensSelecionados = selectBox.checkBoxes
      .filter((item) => item.selected)
      .map((checkBox) => checkBox.value);

    const dadosSelecionados = dataFonteDeRecursos?.filter((item) =>
      itensSelecionados.includes(item.funcaoId)
    );

    return dadosSelecionados;
  };

  const handleGenerateDataXls = () => {
    const dadosRelatorio = getDadosRelatorio();

    const dataTable = dadosRelatorio
      ?.map((item) => {
        const selectedFields = {};
        selectBoxesOptionsAbaOptions[0]?.checkBoxes?.forEach(
          ({ label, value, selected }) => {
            const actualItem = item[value];
            if (selected && !isBase64(actualItem)[0]) {
              selectedFields[label] = actualItem;
            }
          }
        );

        return Object.keys(selectedFields).length > 0 ? selectedFields : null;
      })
      .filter((item) => item);
    if (dataTable.length !== 0) {
      handleGenerateXls(dataTable);
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  useEffect(() => {
    if (dataFonteDeRecursos) {
      const uniqueFuncoes = dataFonteDeRecursos.reduce((acc, current) => {
        const exists = acc.find(
          (item) =>
            item.funcao === current.funcao && item.funcaoId === current.funcaoId
        );
        if (!exists) {
          acc.push({ funcao: current.funcao, funcaoId: current.funcaoId });
        }
        return acc;
      }, []);
      setVinculos(uniqueFuncoes);
      console.log("uniqueFuncoes: ", uniqueFuncoes);
    }
  }, [dataFonteDeRecursos]);

  useEffect(() => {
    if (
      vinculos.length > 0 &&
      !selectBoxesOptionsAbaPrincipal.some((item) => item.opcao === "Vinculos")
    ) {
      console.log(
        "Atualizando selectBoxesOptionsAbaPrincipal com vinculos: ",
        vinculos
      );

      setSelectBoxesOptionsAbaPrincipal((prev) => [
        ...prev,
        {
          opcao: "Vinculos",
          checkBoxes: vinculos.map((item, index) => ({
            label:
              item.funcao.length > 40
                ? `${item.funcao
                    .split("-")
                    .slice(1)
                    .join("-")
                    .trim()
                    .substring(0, 40)}...`
                : item.funcao.split("-").slice(1).join("-").trim(),
            value: item.funcaoId,
            selected: false,
          })),
        },
      ]);
    }
  }, [vinculos, selectBoxesOptionsAbaPrincipal]);

  return (
    <>
      <GlobalStyles orientation={"portrait"} />
      <DivPrintOnly>
        <VizualizacaoRelatorioFRecurso
          dadosRelatorio={dadosRelatorio}
          dataClientInfos={dataClientInfos}
          numeroDaPagina={1}
          totalDePaginas={2}
        />
      </DivPrintOnly>

      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina hide_on_print"
      >
        <HeaderCadastros
          PaginaConsulta={"/planejamento/cadastro/fonte-de-recursos/consulta"}
          NomePaginaTitulo={"Fonte de Recurso"}
          PaginaSubtitulo={"Relatório"}
          BotaoNovoAtivo={false}
          BotaoSaveAtivo={false}
          OpcoesDeArquivo={true}
          DashboardPage={"/planejamento/cadastro/fonte-de-recursos/dashboard"}
          ButtonVisualizar={() => handlePrint(getDadosRelatorio)}
          ButtonPDF={(action) =>
            handleGeneratePdf(PdfFonteDeRecurso, getDadosRelatorio, action)
          }
          ButtonDocx={() => handleGenerateDocx(getDadosRelatorio)}
          PdfData={pdfData}
          ButtonXls={handleGenerateDataXls}
        />
        <ToastContainer />

        <PagRelatorioComponente
          topoEsquerdoOptions={[
            { label: "Relatório", value: null },
            { label: "Relatório", value: 1 },
          ]}
          principalOptions={[
            {
              label: "Vinculos",
              value: "Vinculos",
            },
          ]}
          labelsCheckAbaPrincipal={selectBoxesOptionsAbaPrincipal}
          setLabelsCheckAbaPrincipal={setSelectBoxesOptionsAbaPrincipal}
          labelsCheckAbaOpcoes={selectBoxesOptionsAbaOptions}
          setLabelsCheckAbaOpcoes={setSelectBoxesOptionsAbaOptions}
          hasAssinatura={false}
          hasFavorite={false}
        />
      </Container>
    </>
  );
};

export default RelatorioFonteDeRecurso;
