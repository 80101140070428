import { Page, View, Document } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import React from "react";

const ITEMS_PER_PAGE = 20;

const paginateData = (data, pageNumber) => {
  const startIndex = (pageNumber - 1) * ITEMS_PER_PAGE;
  return data.slice(startIndex, startIndex + ITEMS_PER_PAGE);
};

export const PdfNatDesp = ({ pdfContent }) => {
  const {
    dadosRelatorio,
    opcoes,
    relatorioName,
    userData: { currentClient, userAuthData, dataClientInfos },
    currentYear,
  } = pdfContent;

  if (!dadosRelatorio) return null;

  const colNames = ["Código", "Nome", "Nível", "Descrição", "Valorizável"];
  const styledCol = [
    { flexBasis: "120px", flexShrink: 0, flexGrow: 1 },
    { flexBasis: "250px", flexShrink: 0, flexGrow: 1 },
    { flexBasis: "200px", flexShrink: 0, flexGrow: 1 },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "180px", flexShrink: 0, flexGrow: 1 },
  ];

  const totalPages = Math.ceil(dadosRelatorio.length / ITEMS_PER_PAGE);

  const pages = Array.from({ length: totalPages }, (_, index) =>
    paginateData(dadosRelatorio, index + 1)
  );

  return (
    <Document title={relatorioName}>
      {pages.map((pageData, pageIndex) => (
        <Page
          key={pageIndex}
          orientation="portrait"
          size={"A4"}
          style={stylesPdf.page}
        >
          <PdfHeader
            currentClient={currentClient}
            nameRelatorio={"Natureza da Despesa"}
            anoRelatorio={currentYear}
            dataClientInfos={dataClientInfos}
          />
          <View style={[stylesPdf.colunas, { marginTop: "8px" }]}>
            {opcoes[0]?.checkBoxes?.map(
              (each, index) =>
                each.selected && (
                  <PdfColunas
                    key={index}
                    colName={colNames[index]}
                    styledCol={styledCol[index]}
                  />
                )
            )}
          </View>
          <View style={stylesPdf.divRelatorio}>
            <View>
              {pageData.map((item, index) => (
                <React.Fragment key={index}>
                  <View style={stylesPdf.colunas}>
                    {opcoes[0]?.checkBoxes[0].selected && (
                      <PdfInfos
                        pdfInfo={item.codigo ? item.codigo : ""}
                        styledCol={styledCol[0]}
                      />
                    )}
                    {opcoes[0]?.checkBoxes[1].selected && (
                      <PdfInfos
                        pdfInfo={item.nome ? item.nome : ""}
                        styledCol={styledCol[1]}
                      />
                    )}
                    {opcoes[0]?.checkBoxes[2].selected && (
                      <PdfInfos
                        pdfInfo={item.nivel ? item.nivel : ""}
                        styledCol={styledCol[2]}
                      />
                    )}
                    {opcoes[0]?.checkBoxes[3].selected && (
                      <PdfInfos
                        pdfInfo={item.descricao ? item.descricao : ""}
                        styledCol={styledCol[3]}
                      />
                    )}
                    {opcoes[0]?.checkBoxes[4].selected && (
                      <PdfInfos
                        pdfInfo={item.valorizavel ? item.valorizavel : ""}
                        styledCol={styledCol[4]}
                      />
                    )}
                  </View>
                  <View
                    style={{
                      height: "1px",
                      width: "100%",
                      backgroundColor: "#a6a6a6",
                    }}
                  ></View>
                </React.Fragment>
              ))}
              <View style={stylesPdf.divInferiorColuna}></View>
            </View>
          </View>
          <PdfRodape
            currentClient={currentClient}
            userAuthData={userAuthData}
          />
        </Page>
      ))}
    </Document>
  );
};
