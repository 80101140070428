import React from "react";
import Modal from "react-modal";
import { Icon } from "@iconify/react";
import popupsTutorial from "../../assets/popupsTutorial.png";
import styled from "styled-components";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "500px",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
};

const CustomIcon = styled(Icon)`
  background-color: #515c70;
  border-radius: 50%;
  transition: all 0.2s ease-out;
  color: #fff;

  &:hover {
    background-color: #818da5;
  }
`;

const PopupInstructionsModal = ({ isOpen, onRequestClose }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={customStyles}
    ariaHideApp={false}
  >
    <button
      onClick={onRequestClose}
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        background: "none",
        border: "none",
        cursor: "pointer",
      }}
      aria-label="Fechar"
    >
      <CustomIcon icon="iconoir:cancel" height={28} />
    </button>
    <h2>Como Ativar Popups</h2>
    <p>Para permitir pop-ups no seu navegador, siga estas etapas:</p>
    <ol>
      <li>
        Você verá na barra de pesquisa do seu navegador, um ícone como o
        mostrado na imagem abaixo.
      </li>
      <li>Clique no ícone.</li>
      <li>Marque a opção para sempre permitir pop-ups desta página.</li>
    </ol>
    <img src={popupsTutorial} alt="Marque a caixa para permitir o acesso" />
  </Modal>
);

export default PopupInstructionsModal;
