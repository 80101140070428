import { Container } from "react-bootstrap";
import GlobalStyles from "../../../../styles/StyledComponents/GlobalStyles";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { PagRelatorio } from "./components/PagRelatorio";
import { useContext, useEffect, useState } from "react";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { AllRelatorios } from "./PDF/AllRelatorios";
import { ToastContainer, toast } from "react-toastify";
import { useGenerateRelatorio } from "../../../../hooks/useGenerateRelatorio";
import { CurrentCityContext } from "../../../../contexts/CurrentCityContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { getDataLoa } from "./utils/Loa";
import { getDataLdo } from "./utils/Ldo";
import { hasAnySelected } from "../../../../utils/RelatorioDocx/FuncoesRelatorioDocx";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import { LOA, LDO, SBAO ,PPA} from "./RelatoriosInformacoes";
import { TestButton } from "../../../../utils/TestButton";
import PopupInstructionsModal from "../../../../utils/Loading/HelpModal";
import { getDataPpa } from "./utils/Ppa";

let reportIds = [];

export const Relatorios = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { currentCity } = useContext(CurrentCityContext);
  const { currentYear } = useContext(CurrentYearContext);
  const { userAuthData } = useContext(AuthContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectBoxesOptionsAbaPrincipal, setSelectBoxesOptionsAbaPrincipal] =
    useState([]);
  const [selectBoxesOptionsAbaOptions, setSelectBoxesOptionsAbaOptions] =
    useState(SBAO);

  const [entidadesGestorasDoCliente, setEntidadesGestorasDoCliente] = useState(
    []
  );
  const [dataLoa, setDataLoa] = useState([]);
  const [isUnidadeGestora, setIsUnidadeGestora] = useState(false);
  const [selected, setSelected] = useState("");

  const { handleGeneratePdf, pdfData } = useGenerateRelatorio(
    selectBoxesOptionsAbaOptions,
    selectBoxesOptionsAbaPrincipal,
    currentClient,
    false,
    false,
    setModalIsOpen
  );
  const [canUseConsolidacao, setCanUseConsolidacao] = useState(false);

  const [allData, setAllData] = useState({
    receitaDespesaCEconomica: null,
    receitaSegCEconomica: null,
    receitasCatEconOrgao: null,
    receitasEsferas: null,
    receitaPorFonteRec: null,
    receitaQdr: null,
    despesaSegCEconomica: null,
    despesaCatEconOrgao: null,
    despesaPorEsfera: null,
    despesaPorFonteRec: null,
    despesaQdd: null,
    qddAplicacao: null,
    despesaOrgaoUnidade: null,
    despesaDemonstrativo: null,
    despesaVinculado: null,
    despesaDemonstrativoUnidade: null,
    demoRiscosFiscaisProvidencias: null,
    despesaDemonstrativoAcao: null,
    despesaDemonstrativoPrograma: null,
    despesaCompatibilidade: null,
    despesaClassificacao: null,
    despesaDemonstrativoOrgaoFuncao: null,
    despesaDemonstrativoRegionalizado: null,
    ldoDemoMetasAnuais: null,
    ldoDemoMetasFiscais: null,
    ldoDemoAvaliacao: null,
    ldoEvolucaoPatrimonio: null,
    ldoDemoOrigemAplicacao: null,
    ldoDemoAvaliacaoSituacao: null,
    ldoDemoEstimativa: null,
    ldoDemoMargenExpansao: null,
    ldoAnexoPrioridade: null,
    ldoAnexoEstimativa: null,
    ldoAnexoCompatibilidade: null,
    ppaAnexoDemoEstimativa: null,
    ppaAnexoDemoDespesa: null,
    ppaAnexoDemoSegCatEcon: null,
    ppaAnexoDemoDespesaFuncao: null,
  });

  useEffect(() => {
    const ldoName = "LDO - Lei de Diretrizes Orçamentárias";
    if (
      currentClient.nomeFantasia ===
        currentClient.unidadeGestora.nomeFantasia &&
      selected === ldoName
    ) {
      setEntidadesGestorasDoCliente((prevEntidades) =>
        prevEntidades.map((entidade) => ({
          ...entidade,
          checkBoxes: entidade.checkBoxes.map((item) => ({
            ...item,
            selected: true,
          })),
        }))
      );
    } else {
      setEntidadesGestorasDoCliente((prevEntidades) =>
        prevEntidades.map((entidade) => ({
          ...entidade,
          checkBoxes: entidade.checkBoxes.map((item) => ({
            ...item,
            selected: item.dados.nomeFantasia === currentClient.nomeFantasia,
          })),
        }))
      );
    }
  }, [selected, currentClient]);

  useEffect(() => {
    setDataLoa(LOA);
  }, []);

  useEffect(() => {
    if (
      currentClient.nomeFantasia === currentClient.unidadeGestora.nomeFantasia
    ) {
      setIsUnidadeGestora(true);
    } else {
      setIsUnidadeGestora(false);
    }
    if (
      currentClient.nomeFantasia === currentClient.unidadeGestora.nomeFantasia
    ) {
      setCanUseConsolidacao(true);
    } else {
      setCanUseConsolidacao(false);
    }
    if (currentCity && Object.keys(userAuthData).length > 0) {
      const entidades = userAuthData.clientes.filter(
        (item) =>
          item.endereco.municipio === currentCity.municipio &&
          item.endereco.estado === currentCity.estado
      );
      const formattedEntidades = [
        {
          opcao: "selecionado",
          checkBoxes:
            currentClient.nomeFantasia ===
            currentClient.unidadeGestora.nomeFantasia
              ? entidades.map((item) => ({
                  dados: item,
                  selected:
                    item.nomeFantasia === currentClient.nomeFantasia
                      ? true
                      : false,
                }))
              : entidades
                  .filter(
                    (item) => item.nomeFantasia === currentClient.nomeFantasia
                  )
                  .map((item) => ({
                    dados: item,
                    selected:
                      item.nomeFantasia === currentClient.nomeFantasia
                        ? true
                        : false,
                  })),
        },
      ];

      setEntidadesGestorasDoCliente(formattedEntidades);
    }
  }, [currentCity, userAuthData, currentClient]);

  useEffect(() => {
    const loaName = "LOA - Lei Orçamentária Anual";
    const ldoName = "LDO - Lei de Diretrizes Orçamentárias";
    const ppaName = "PPA - Plano Plurianual";
    const endReports = [1, 6, 12];

    setSelectBoxesOptionsAbaPrincipal((prev) => {
      const newOptions = [...prev];

      if (LOA && !newOptions.find((item) => item.opcao === loaName)) {
        newOptions.push({
          opcao: loaName,
          checkBoxes: LOA.map((item) => ({
            label: `${item.titulo}`,
            value: item.id,
            typeRelatorio: item.typeRelatorio,
            selected: false,
            style: endReports.includes(item.id)
              ? { borderBottom: "2px solid #f2f3f8", margin: "-3px 0" }
              : { borderBottom: "none", margin: "-3px 0" },
          })),
        });
      }

      if (LDO && !newOptions.find((item) => item.opcao === ldoName)) {
        newOptions.push({
          opcao: ldoName,
          checkBoxes: LDO.map((item) => ({
            label: `${item.titulo}`,
            value: item.id,
            typeRelatorio: item.typeRelatorio,
            selected: false,
            style: endReports.includes(item.id)
              ? { borderBottom: "2px solid #f2f3f8", margin: "-3px 0" }
              : { borderBottom: "none", margin: "-3px 0" },
          })),
        });
      }
      if (PPA && !newOptions.find((item) => item.opcao === ppaName)) {
        newOptions.push({
          opcao: ppaName,
          checkBoxes: PPA.map((item) => ({
            label: `${item.titulo}`,
            value: item.id,
            typeRelatorio: item.typeRelatorio,
            selected: false,
            style: endReports.includes(item.id)
              ? { borderBottom: "2px solid #f2f3f8", margin: "-3px 0" }
              : { borderBottom: "none", margin: "-3px 0" },
          })),
        });
      }
      return newOptions;
    });
  }, []);

  useEffect(() => {
    let selecteds = [];
    selectBoxesOptionsAbaPrincipal.forEach((opcao) => {
      opcao.checkBoxes.forEach((item) => {
        if (item.selected) {
          selecteds.push(item.value);
        }
      });
    });
    reportIds = selecteds;
  }, [selectBoxesOptionsAbaPrincipal]);

  const getEntidadeGestora = (consolidado = true) => {
    const obj = { ids: [], name: null, consolidacao: false };
    const entidadesSelecionadas =
      entidadesGestorasDoCliente[0].checkBoxes.filter(
        (value) => value.selected
      );
    if (
      entidadesGestorasDoCliente[0].checkBoxes.length > 1 &&
      entidadesGestorasDoCliente[0].checkBoxes.every(
        (value) => value.selected
      ) &&
      consolidado
    ) {
      obj.consolidacao = true;
    }

    obj.ids = entidadesSelecionadas.map((value) => value.dados.clienteId);

    if (entidadesSelecionadas.length > 1) {
      obj.name = entidadesSelecionadas[0].dados.unidadeGestora.nomeFantasia;
    } else {
      obj.name = entidadesSelecionadas[0].dados.nomeFantasia;
    }
    return obj;
  };

  const getDadosAllRelatoriosUnique = async () => {
    const newAllData = { ...allData };
    const clientes = getEntidadeGestora();

    await Promise.all([
      ReceitaDespesaCEconomica(newAllData, clientes),
      ReceitaSegCEconomica(newAllData, clientes),
      ReceitaCatEconOrgao(newAllData, clientes),
      ReceitaEsfera(newAllData, clientes),
      ReceitaPorFonteRec(newAllData, clientes),
      ReceitaQdr(newAllData, clientes),
      DespesaSegCEconomica(newAllData, clientes),
      DespesaCatEconOrgao(newAllData, clientes),
      DespesaPorEsfera(newAllData, clientes),
      despesaPorFonteRec(newAllData, clientes),
      DespesaQdd(newAllData, clientes),
      quadroDetalhadoQDDAplicacao(newAllData, clientes),
      despesaOrgaoUnidade(newAllData, clientes),
      despesaDemonstrativo(newAllData, clientes),
      despesaVinculado(newAllData, clientes),
      despesaDemonstrativoUnidade(newAllData, clientes),
      demoRiscosFiscaisProvidencias(newAllData, clientes),
      despesaDemonstrativoAcao(newAllData, clientes),
      despesaDemonstrativoPrograma(newAllData, clientes),
      despesaCompatibilidade(newAllData, clientes),
      despesaClassificacao(newAllData, clientes),
      despesaDemonstrativoOrgaoFuncao(newAllData),
      despesaDemonstrativoRegionalizado(newAllData, clientes),
      ldoDemoMetasAnuais(newAllData, clientes),
      ldoDemoMetasFiscais(newAllData, clientes),
      ldoDemoAvaliacao(newAllData, clientes),
      ldoEvolucaoPatrimonio(newAllData, clientes),
      ldoDemoOrigemAplicacao(newAllData, clientes),
      ldoDemoAvaliacaoSituacao(newAllData, clientes),
      ldoDemoEstimativa(newAllData, clientes),
      ldoDemoMargenExpansao(newAllData, clientes),
      ldoAnexoPrioridade(newAllData, clientes),
      ldoAnexoEstimativa(newAllData, clientes),
      ldoAnexoCompatibilidade(newAllData, clientes),
      ppaAnexoDemoEstimativa(newAllData, clientes),
      ppaAnexoDemoDespesa(newAllData, clientes),
      ppaAnexoDemoSegCatEcon(newAllData, clientes),
      ppaAnexoDemoDespesaFuncao(newAllData, clientes),
    ]);

    setAllData(newAllData);

    const getUniqueName = (reportIds, dataLoa) => {
      return reportIds
        .map((id) => {
          const found = dataLoa.find((item) => item.id === id);
          return found ? found.titulo : null;
        })
        .filter(Boolean);
    };

    return {
      reportIds,
      data: newAllData,
      uniqueName: getUniqueName(reportIds, dataLoa),
      customData: clientes,
    };
  };

  const ReceitaDespesaCEconomica = async (newAllData, clientes) => {
    if (!reportIds.includes(1)) {
      newAllData.receitaDespesaCEconomica = null;
      return;
    }
    const result = await getDataLoa.getReceitaDespesaCEconomica(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.receitaDespesaCEconomica = result;
  };

  const ReceitaSegCEconomica = async (newAllData, clientes) => {
    if (!reportIds.includes(2)) {
      newAllData.receitaSegCEconomica = null;
      return;
    }
    const result = await getDataLoa.getReceitaSegCEconomica(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.receitaSegCEconomica = result;
  };

  const ReceitaCatEconOrgao = async (newAllData, clientes) => {
    console.log(clientes);

    if (!reportIds.includes(3)) {
      newAllData.receitasCatEconOrgao = null;
      return;
    }
    const result = await getDataLoa.getReceitasCatEconOrgao(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.receitasCatEconOrgao = result;
  };

  const ReceitaEsfera = async (newAllData, clientes) => {
    if (!reportIds.includes(4)) {
      newAllData.receitasCatEconOrgao = null;
      return;
    }
    const result = await getDataLoa.getReceitasEsfera(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.receitasEsferas = result;
  };

  const ReceitaPorFonteRec = async (newAllData, clientes) => {
    if (!reportIds.includes(5)) {
      newAllData.receitaPorFonteRec = null;
      return;
    }
    const result = await getDataLoa.getReceitaPorFonteRec(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.receitaPorFonteRec = result;
  };
  const ReceitaQdr = async (newAllData, clientes) => {
    if (!reportIds.includes(6)) {
      newAllData.receitaQdr = null;
      return;
    }
    const result = await getDataLoa.getReceitasQdr(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.receitaQdr = result;
  };
  const DespesaSegCEconomica = async (newAllData, clientes) => {
    if (!reportIds.includes(7)) {
      newAllData.despesaSegCEconomica = null;
      return;
    }
    const result = await getDataLoa.getDespesaSegCEconomica(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      currentYear,
      entidadesGestorasDoCliente
    );
    newAllData.despesaSegCEconomica = result;
  };
  const DespesaCatEconOrgao = async (newAllData, clientes) => {
    if (!reportIds.includes(8)) {
      newAllData.despesaCatEconOrgao = null;
      return;
    }

    const result = await getDataLoa.getDespesaCatEconOrgao(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.despesaCatEconOrgao = result;
  };
  const DespesaPorEsfera = async (newAllData, clientes) => {
    if (!reportIds.includes(9)) {
      newAllData.despesaPorEsfera = null;
      return;
    }
    const result = await getDataLoa.getDespesaEsfera(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      currentYear,
      entidadesGestorasDoCliente
    );
    newAllData.despesaPorEsfera = result;
  };
  const despesaPorFonteRec = async (newAllData, clientes) => {
    if (!reportIds.includes(10)) {
      newAllData.despesaPorFonteRec = null;
      return;
    }
    const result = await getDataLoa.getDespesaPorFonteRec(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      currentYear,
      entidadesGestorasDoCliente
    );
    newAllData.despesaPorFonteRec = result;
  };
  const DespesaQdd = async (newAllData, clientes) => {
    if (!reportIds.includes(11)) {
      newAllData.despesaQdd = null;
      return;
    }
    const result = await getDataLoa.getDespesaQdd(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      currentYear,
      entidadesGestorasDoCliente
    );
    newAllData.despesaQdd = result;
  };
  const quadroDetalhadoQDDAplicacao = async (newAllData, clientes) => {
    if (!reportIds.includes(12)) {
      newAllData.despesaSegCEconomica = null;
      return;
    }
    const result = await getDataLoa.getQuadroDetalhadoQDDAplicacao(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      currentYear,
      entidadesGestorasDoCliente
    );
    newAllData.qddAplicacao = result;
  };

  const despesaOrgaoUnidade = async (newAllData, clientes) => {
    if (!reportIds.includes(13)) {
      newAllData.despesaOrgaoUnidade = null;
      return;
    }
    const result = await getDataLoa.getDespesaOrgaoUnidade(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.despesaOrgaoUnidade = result;
  };
  const despesaDemonstrativo = async (newAllData, clientes) => {
    if (!reportIds.includes(14)) {
      newAllData.despesaDemonstrativo = null;
      return;
    }
    const result = await getDataLoa.getDespesaDemonstrativo(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.despesaDemonstrativo = result;
  };
  const despesaVinculado = async (newAllData, clientes) => {
    if (!reportIds.includes(15)) {
      newAllData.despesaVinculado = null;
      return;
    }
    const result = await getDataLoa.getDespesaVinculado(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.despesaVinculado = result;
  };
  const despesaDemonstrativoUnidade = async (newAllData, clientes) => {
    if (!reportIds.includes(16)) {
      newAllData.despesaDemonstrativoUnidade = null;
      return;
    }
    const result = await getDataLoa.getDespesaDemonstrativoUnidade(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.despesaDemonstrativoUnidade = result;
  };
  const despesaDemonstrativoAcao = async (newAllData, clientes) => {
    if (!reportIds.includes(17)) {
      newAllData.despesaDemonstrativoAcao = null;
      return;
    }
    const result = await getDataLoa.getDespesaDemonstrativoAcao(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.despesaDemonstrativoAcao = result;
  };
  const despesaDemonstrativoPrograma = async (newAllData, clientes) => {
    if (!reportIds.includes(18)) {
      newAllData.despesaDemonstrativoPrograma = null;
      return;
    }
    const result = await getDataLoa.getDespesaDemonstrativoPrograma(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.despesaDemonstrativoPrograma = result;
  };
  const despesaCompatibilidade = async (newAllData, clientes) => {
    if (!reportIds.includes(19)) {
      newAllData.despesaCompatibilidade = null;
      return;
    }
    const result = await getDataLoa.getDespesaCompatibilidade(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.despesaCompatibilidade = result;
  };
  const despesaClassificacao = async (newAllData, clientes) => {
    if (!reportIds.includes(20)) {
      newAllData.despesaClassificacao = null;
      return;
    }
    const result = await getDataLoa.getDespesaClassificacao(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.despesaClassificacao = result;
  };
  const despesaDemonstrativoOrgaoFuncao = async (newAllData) => {
    const clientes = getEntidadeGestora(false);
    if (!reportIds.includes(21)) {
      newAllData.despesaDemonstrativoOrgaoFuncao = null;
      return;
    }
    const result = await getDataLoa.getDespesaDemonstrativoOrgaoFuncao(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.despesaDemonstrativoOrgaoFuncao = result;
  };

  const despesaDemonstrativoRegionalizado = async (newAllData, clientes) => {
    if (!reportIds.includes(22)) {
      newAllData.despesaDemonstrativoRegionalizado = null;
      return;
    }
    const result = await getDataLoa.getDespesaDemonstrativoRegionalizado(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.despesaDemonstrativoRegionalizado = result;
  };
  const demoRiscosFiscaisProvidencias = async (newAllData, clientes) => {
    if (!reportIds.includes(23)) {
      newAllData.demoRiscosFiscaisProvidencias = null;
      return;
    }
    const result = await getDataLdo.getDemoRiscosFiscaisProvidencias(
      /*clientes.ids.length > 0 ? clientes : */ [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.demoRiscosFiscaisProvidencias = result;
  };
  const ldoDemoMetasAnuais= async (newAllData, clientes) => {
    if (!reportIds.includes(24)) {
      newAllData.ldoDemoMetasAnuais = null;
      return;
    }
    const result = await getDataLdo.getDemoMetasAnuais(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ldoDemoMetasAnuais = result;
  };

  const ldoDemoAvaliacao = async (newAllData, clientes) => {
    if (!reportIds.includes(25)) {
      newAllData.ldoDemoAvaliacao = null;
      return;
    }
    const result = await getDataLdo.getDemoAvaliacao(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ldoDemoAvaliacao = result;
  };
  const ldoDemoMetasFiscais = async (newAllData, clientes) => {
    if (!reportIds.includes(26)) {
      newAllData.ldoDemoMetasFiscais = null;
      return;
    }
    const result = await getDataLdo.getDemoMetasFiscais(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ldoDemoMetasFiscais = result;
  };
  const ldoEvolucaoPatrimonio = async (newAllData, clientes) => {
    if (!reportIds.includes(27)) {
      newAllData.ldoEvolucaoPatrimonio = null;
      return;
    }
    const result = await getDataLdo.getEvolucaoPatrimonio(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ldoEvolucaoPatrimonio = result;
  };
  const ldoDemoOrigemAplicacao = async (newAllData, clientes) => {
    if (!reportIds.includes(28)) {
      newAllData.ldoDemoOrigemAplicacao = null;
      return;
    }
    const result = await getDataLdo.getDemoOrigemAplicacao(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ldoDemoOrigemAplicacao = result;
  };
  const ldoDemoAvaliacaoSituacao = async (newAllData, clientes) => {
    if (!reportIds.includes(29)) {
      newAllData.ldoDemoAvaliacaoSituacao = null;
      return;
    }
    const result = await getDataLdo.getDemoAvaliacaoSituacao(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ldoDemoAvaliacaoSituacao = result;
  };
  const ldoDemoEstimativa = async (newAllData, clientes) => {
    if (!reportIds.includes(30)) {
      newAllData.ldoDemoEstimativa = null;
      return;
    }
    const result = await getDataLdo.getDemoEstimativa(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ldoDemoEstimativa = result;
  };
  const ldoDemoMargenExpansao = async (newAllData, clientes) => {
    if (!reportIds.includes(31)) {
      newAllData.ldoDemoMargenExpansao = null;
      return;
    }
    const result = await getDataLdo.getDemoMargenExpansao(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ldoDemoMargenExpansao = result;
  };
  
  const ldoAnexoPrioridade = async (newAllData, clientes) => {
    if (!reportIds.includes(32)) {
      newAllData.ldoAnexoPrioridade = null;
      return;
    }
    const result = await getDataLdo.getAnexoPrioridade(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ldoAnexoPrioridade = result;
  };
  const ldoAnexoCompatibilidade = async (newAllData, clientes) => {
    if (!reportIds.includes(33)) {
      newAllData.ldoAnexoCompatibilidade = null;
      return;
    }
    const result = await getDataLdo.getAnexoCompatibilidade(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ldoAnexoCompatibilidade = result;
  };

  const ldoAnexoEstimativa = async (newAllData, clientes) => {
    if (!reportIds.includes(34)) {
      newAllData.ldoAnexoEstimativa = null;
      return;
    }
    const result = await getDataLdo.getAnexoEstimativa(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ldoAnexoEstimativa = result;
  };
  const ppaAnexoDemoEstimativa = async (newAllData, clientes) => {
    if (!reportIds.includes(35)) {
      newAllData.ppaAnexoDemoEstimativa = null;
      return;
    }
    const result = await getDataPpa.getDemoEstimativa(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ppaAnexoDemoEstimativa = result;
  };
  const ppaAnexoDemoDespesa = async (newAllData, clientes) => {
    if (!reportIds.includes(36)) {
      newAllData.ppaAnexoDemoDespesa = null;
      return;
    }
    const result = await getDataPpa.getDemoDespesa(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ppaAnexoDemoDespesa = result;
  };
  const ppaAnexoDemoSegCatEcon = async (newAllData, clientes) => {
    if (!reportIds.includes(37)) {
      newAllData.ppaAnexoDemoSegCatEcon = null;
      return;
    }
    const result = await getDataPpa.getDemoSegCatEcon(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ppaAnexoDemoSegCatEcon = result;
  };
  const ppaAnexoDemoDespesaFuncao = async (newAllData, clientes) => {
    if (!reportIds.includes(38)) {
      newAllData.ppaAnexoDemoDespesaFuncao = null;
      return;
    }
    const result = await getDataPpa.getDemoDespesaFuncao(
      clientes.ids.length > 0 ? clientes : [currentClient.clienteId],
      entidadesGestorasDoCliente,
      currentYear
    );
    newAllData.ppaAnexoDemoDespesaFuncao = result;
  };

  const handleGeneratePDFs = async (action) => {
    if (
      hasAnySelected(selectBoxesOptionsAbaPrincipal) &&
      hasAnySelected(selectBoxesOptionsAbaOptions)
    ) {
      if (reportIds.length > 0) {
        const allIds = [...reportIds];
        reportIds = [];

        for (const id of allIds) {
          reportIds.push(id);
          const data = await getDadosAllRelatoriosUnique();
          const index = Object.keys(data.data)[id - 1];
          if (data?.data[index]?.error) {
            toast.warn(
              `Não foi possível gerar o relatório ${data.data[index].name}, verifique se existem dados para esse relatório`
            );
          } else {
            await handleGeneratePdf(AllRelatorios, data, action);
          }
          reportIds.pop();
        }
        reportIds = [...allIds];
      }
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  return (
    <>
      <GlobalStyles orientation={"portrait"} />
      <PopupInstructionsModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
      />

      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina hide_on_print"
      >
        <HeaderCadastros
          hideNavButtons
          NomePaginaTitulo={"Impressos"}
          PaginaSubtitulo={"Relatórios"}
          OpcoesDeArquivo={true}
          ButtonPDF={(action) => {
            handleGeneratePDFs(action);
          }}
          PdfData={pdfData}
        />
        <ToastContainer />
        <PagRelatorio
          activeMonth={true}
          entidadesGestorasDoCliente={entidadesGestorasDoCliente}
          setEntidadesGestorasDoCliente={setEntidadesGestorasDoCliente}
          principalOptions={
            isUnidadeGestora
              ? [
                  {
                    label: "LOA - Lei Orçamentária Anual",
                    value: "LOA - Lei Orçamentária Anual",
                  },
                  {
                    label: "LDO - Lei de Diretrizes Orçamentárias",
                    value: "LDO - Lei de Diretrizes Orçamentárias",
                  },
                  {
                    label: "PPA - Plano Plurianual",
                    value: "PPA - Plano Plurianual",
                  },
                ]
              : [
                  {
                    label: "LOA - Lei Orçamentária Anual",
                    value: "LOA - Lei Orçamentária Anual",
                  },
                ]
          }
          labelsCheckAbaPrincipal={selectBoxesOptionsAbaPrincipal}
          setLabelsCheckAbaPrincipal={setSelectBoxesOptionsAbaPrincipal}
          labelsCheckAbaOpcoes={selectBoxesOptionsAbaOptions}
          setLabelsCheckAbaOpcoes={setSelectBoxesOptionsAbaOptions}
          canUseConsolidacao={canUseConsolidacao}
          setSelected={setSelected}
        />
        <TestButton onClick={() => console.log(selectBoxesOptionsAbaPrincipal)}>
          optionsAbaPrincipal
        </TestButton>
        <TestButton onClick={() => console.log(reportIds)}>
          reportIDS
        </TestButton>
        <TestButton onClick={() => console.log(currentClient)}>
          currentCliente
        </TestButton>
        <TestButton onClick={() => console.log(entidadesGestorasDoCliente)}>
          entidadesGestorasDoCliente
        </TestButton>
        <div className="p-3"></div>
      </Container>
    </>
  );
};

// const getDadosAllRelatorios = async () => {
//   const newAllData = { ...allData };

//   await Promise.all([
//     ReceitaSegCEconomica(newAllData),
//     ReceitaDespesaCEconomica(newAllData),
//     ReceitaCatEconOrgao(newAllData),
//     ReceitaEsfera(newAllData),
//     ReceitaPorFonteRec(newAllData),
//   ]);

//   setAllData(newAllData);

//   return {
//     reportIds,
//     data: newAllData,
//   };
// };
