import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfDespesaDemonstrativoUnidade = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  customData
) => {
  const styledCol = [
    { flexBasis: "80px", flexShrink: 1, flexGrow: 1,textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1,textAlign: "center" },
    {
      flexBasis: "80px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const optionDespesaDemonstrativo = opcoes.find(
    (option) => option.opcao === "Quadro Detalhado da Receita - QDR"
  );

  const renderReceitas = (unidade) => {
    return (
      <View
        style={[
          stylesPdf.colunas,
          {
            gap: 0,
          },
        ]}
      >
        {optionDespesaDemonstrativo?.checkBoxes[0].selected && (
          <PdfInfos
            pdfInfo={unidade.codigo || ""}
            styledCol={[
              styledCol[0],
              {
                padding: "2px 0",
                textAlign: "left",
              },
            ]}
          />
        )}
        {optionDespesaDemonstrativo?.checkBoxes[1].selected && (
          <PdfInfos
            pdfInfo={unidade.especificacao || ""}
            styledCol={[styledCol[1], { padding: "2px 0",textAlign: "left" }]}
          />
        )}

        {optionDespesaDemonstrativo?.checkBoxes[2].selected && (
          <PdfInfos
            pdfInfo={FormatValueToLocaleString(unidade.total)}
            styledCol={[
              styledCol[2],
              {
                padding: "2px 0",
                textAlign: "right",
                flexBasis: 200
              },
            ]}
          />
        )}
      </View>
    );
  };

  const renderTotal = (total) => {
    return (
      <>
        <View
          wrap={false}
          style={[
            stylesPdf.divInferiorColuna,
            {
              fontWeight: "bold",
              fontSize: 10,
              textTransform: "uppercase",
              color: "white",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 10,
              margin: 0,
            },
          ]}
        >
          <Text>TOTAL</Text>
          <Text>{total}</Text>
        </View>
        <View break />
      </>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={[stylesPdf.page]}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Demonstrativo da Despesa por Unidade Orçamentária"}
        anoRelatorio={currentYear}
        plusText={"vazio"}
        dataClientInfos={dataClientInfos}
        customData={customData}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {optionDespesaDemonstrativo?.checkBoxes?.map((option, index) => (
          <PdfColunas
            key={index}
            colName={option.label.toUpperCase()}
            styledCol={styledCol[index]}
            hideBar
          />
        ))}
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View style={{ margin: "0 -3px" }}>
          {dados.data.map((unidade, unidadeIndex) => (
            <React.Fragment key={unidadeIndex}>
              {renderReceitas(unidade)}
            </React.Fragment>
          ))}
          {renderTotal(FormatValueToLocaleString(dados.sum))}
        </View>
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
