import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { useState } from "react";
import { MotivosAba } from "./MotivosAba";
import SaldosAba from "./SaldosAba";

const AnulacaoDaDespesa = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Motivo",
    numeroAba: 0,
  });

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Anulação da Despesa"}
        PaginaSubtitulo={"Cadastro"}
      />

      <RowForm className="mt-1">
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Tipo  de Anulação - Lliquidação - Anulação"
          />
        </CCol>
        <CCol md={2}>
          <InputData />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Liquidação"
            style={{ height: "37px" }}
          />
        </CCol>

        <CCol md={2}>
          <FloatingLabelInput
            disabled
            label="Anulação"
            placeholder="Anulação"
          />
        </CCol>

        <CCol md={2}>
          <InputFormatRealBrasileiro />
        </CCol>
      </RowForm>

      <RowInfosAcao>
        <CCol md={7}>
          <p>
            Unidade Orçamentária:{" "}
            <span>02.050 - Secretaria Municipal de Educação</span>
          </p>
          <p>
            Programa:{" "}
            <span>
              2025 - Manutenção dos Serviços da Secretaria Municipal de Educação
            </span>
          </p>
          <p>
            Elemento de Despesa:{" "}
            <span>
              3.3.90.39 - OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA
            </span>
          </p>
          <p>
            Fonte de Recursos:{" "}
            <span>500 - Recursos não Vinculados de Impostos</span>
          </p>
        </CCol>
        <CCol>
          <p>
            Função: <span>08 - Assistência Social</span>
          </p>
          <p>
            Subfunção: <span>244 - Assistência Comunitária</span>
          </p>
          <p>
            Exercício - CNP: <span>1 - Recursos do Exercício Corrente</span>
          </p>
        </CCol>
      </RowInfosAcao>

      <RowSelecaoAbasInferior
        abas={["Motivo", "Produto e Aquisição", "Saldos"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Motivo" && <MotivosAba />}
      {abaSelecionada.nomeAba === "Saldos" && <SaldosAba />}
    </Container>
  );
};

export default AnulacaoDaDespesa;
