import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useRef, useState } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

const ContainerHoverOptions = styled.div`
  position: relative;

  button {
    background-color: ${({ hovered }) => (hovered ? "#F4F4F4" : "transparent")};
  }
`;

const HoverOptionsContainer = styled.div`
  position: absolute;
  right: ${({ $right }) => ($right ? `-${$right.toString()}px` : "-200px")};
  min-width: 200px;
  top: 0;
  opacity: 0;
  transition: opacity 0.1s;
  pointer-events: none;
  z-index: 99;

  ${({ hovered }) => {
    if (hovered) {
      return css`
        opacity: 1;
        pointer-events: all;
      `;
    }
  }}
`;

const DivTituloHoverOptions = styled.div`
  width: calc(100% + 10px);
  margin-left: -10px;
  height: 37px;
  background-color: ${({ active }) => (active ? "#515c70" : "#F4F4F4")};
  position: relative;
  color: ${({ active }) => (active ? "#fff" : "#515c70")};
  border-radius: 0px 8px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s;

  span {
    font-weight: ${({ active }) => (active ? "600" : "500")};
  }
`;

const DivLinkHoverOptions = styled.div`
  background: #fafafa;
  box-shadow: 0px 0px 5px #bebebe;
  padding: 42px 5px 5px 5px;
  margin-top: -37px;
  max-height: 200px;
  border-radius: 0px 8px 0px 0px;

  a {
    font-size: 14px;
    padding-left: 0;
    width: 100%;
  }

  a.active {
    background-color: transparent;
    color: #515c70;
    font-weight: 600;
  }

  a.active svg {
    color: #515c70;
  }

  a:hover {
    background-color: transparent;
    font-weight: 600;
  }
`;

const ButtonDropOrHover = ({
  checkSidebar,
  tituloButton = "Link",
  navOptions = [{ titulo: "", path: "" }],
  iconSrc = "",
  smallerType = false,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const arrayPaths = navOptions.map((item) => item.path);
  const location = useLocation();
  const hoverContainerRef = useRef();

  return (
    <>
      {!checkSidebar && (
        <>
          <button
            onClick={() => setIsClicked((prev) => !prev)}
            style={
              smallerType && !checkSidebar ? { fontSize: "14px" } : undefined
            }
            className={arrayPaths.includes(location.pathname) && "active"}
          >
            <Icon
              icon={iconSrc}
              width={smallerType && !checkSidebar ? 20 : 25}
              style={{ transform: "translateX(-1px)" }}
            />{" "}
            <span style={{ marginLeft: "3px" }}>
              <p
                style={
                  smallerType ? { fontSize: "16px" } : { fontSize: "18px" }
                }
              >
                {tituloButton}
              </p>{" "}
              <Icon
                className={isClicked && "active"}
                rotate={2}
                width={13}
                style={
                  smallerType && !checkSidebar
                    ? { marginLeft: "5px" }
                    : undefined
                }
                icon="material-symbols:arrow-back-ios-rounded"
              />
            </span>
          </button>
          <Collapse in={isClicked}>
            <ul>
              {navOptions.map((item) => (
                <li
                  style={
                    smallerType && !checkSidebar
                      ? {
                          // paddingLeft: "16px",
                          fontSize: "14px",
                        }
                      : undefined
                  }
                >
                  <NavLink to={item.path}>
                    <Icon width={24} icon="bi:dot" />
                    <span>{item.titulo}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </Collapse>
        </>
      )}

      {checkSidebar && (
        <ContainerHoverOptions
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          hovered={isHovered}
        >
          <button
            className={arrayPaths.includes(location.pathname) && "active"}
            onClick={() => {
              console.log(
                hoverContainerRef.current.getBoundingClientRect().width
              );
            }}
          >
            {" "}
            <Icon
              icon={iconSrc}
              width={25}
              style={{ transform: "translateX(-1px)" }}
            />
          </button>

          <HoverOptionsContainer
            hovered={isHovered}
            ref={hoverContainerRef}
            $right={
              hoverContainerRef && hoverContainerRef.current
                ? hoverContainerRef.current.getBoundingClientRect().width
                : 200
            }
          >
            <DivTituloHoverOptions
              active={arrayPaths.includes(location.pathname)}
            >
              <span>{tituloButton}</span>
            </DivTituloHoverOptions>
            <DivLinkHoverOptions>
              {navOptions.map((item) => (
                <NavLink to={item.path}>
                  <span>
                    <Icon width={24} icon="bi:dot" />
                    {item.titulo}
                  </span>
                </NavLink>
              ))}
            </DivLinkHoverOptions>
          </HoverOptionsContainer>
        </ContainerHoverOptions>
      )}
    </>
  );
};

export default ButtonDropOrHover;
