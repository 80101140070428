import { Document, Packer, PageOrientation, SectionType } from "docx";
import defaultImg64 from "../../../../../../utils/RelatorioDocx/defaultImg64";
import { DocxHeader } from "../../../../../../utils/RelatorioDocx/DocxHeader";
import { DocxFooter } from "../../../../../../utils/RelatorioDocx/DocxFooter";
import { DocxTableParametrosIndices } from "./DocxTableParametrosIndices";

export const generateDocxBlob = async (
  { pdfContent },
  title,
  logo,
  pageSize = 20,
  double = false
) => {
  const {
    dadosRelatorio,
    opcoes,
    columnWidth,
    userData: { currentClient, userAuthData },
    dateRelatorio: { year },
  } = pdfContent;
  const font = "Roboto";
  console.log("dadosRelatorio: ", dadosRelatorio);
  console.log("opções: ", opcoes);

  if (!dadosRelatorio) return null;

  const doc = new Document({
    sections: [
      {
        properties: {
          type: SectionType.CONTINUOUS,
          page: {
            size: {
              orientation: PageOrientation.PORTRAIT,
            },
            margin: {
              top: 720,
              right: 720,
              bottom: 720,
              left: 720,
            },
          },
        },
        headers: {
          default: DocxHeader({
            logo: logo || defaultImg64,
            municipio: currentClient.endereco.municipio,
            estado: currentClient.endereco.estado,
            ano: year,
            title,
            font,
          }),
        },
        children: [
          DocxTableParametrosIndices({
            columns: opcoes[0],
            data: dadosRelatorio,
            font,
            subTitle: double,
            columnWidth: columnWidth[0],
          }),
        ],
        footers: {
          default: DocxFooter({
            userAuthData,
            currentClient,
            font,
          }),
        },
      },
    ],
  });

  const blob = await Packer.toBlob(doc);
  return new Blob([blob], {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });
};
