import { CCol } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import { FormatDateToBR } from "../../../../utils/FormatacaoDeDados/FormatDateToBR";

function FormCnpj({ values, setValues, paramsValor }) {
  //Gereciamento de datas
  // const dataParaVizualizacaoFormatada = useMemo(() => {
  //   if (values.dataCriacao !== "" && paramsValor) {
  //     return FormatDateToNA(values.dataCriacao.substring(0, 10));
  //   }

  //   if (!paramsValor) {
  //     return FormatDateToNA(dataAtual);
  //   }
  // }, [values, paramsValor, dataAtual]);
  //---------------------
  return (
    <>
      <CCol>
        <FloatingLabelInput
          style={{ color: "#515c70" }}
          value={values.nomeFantasia}
          placeholder="Nome Fantasia"
          label="Nome Fantasia"
          type="text"
          onChange={(e) => {
            setValues({
              ...values,
              nomeFantasia: e.target.value,
            });
          }}
        />
      </CCol>
      <CCol md={2}>
        <InputData
          disabled={paramsValor ? true : false}
          externalValue={values.dataCriacao.substring(0, 10)}
          onInput={(e) => {
            console.log("teste");
            setValues({
              ...values,
              dataCriacao: FormatDateToBR(e.target.value),
            });
          }}
        />
      </CCol>
      <div className="pt-3"></div>
      <CCol md={3}>
        <FloatingLabelInput
          id="codigo"
          type="text"
          name="municipio"
          disabled
          value={values.Endereco.municipio}
          placeholder="Municipio"
          label="Municipio"
          onChange={(e) =>
            setValues({
              ...values,
              Endereco: {
                ...values.Endereco,
                municipio: e.target.value,
              },
            })
          }
        />
      </CCol>
      <CCol md={3}>
        <FloatingLabelInput
          style={{ color: "#515c70" }}
          disabled
          value={values.naturezaJuridica}
          title={values.naturezaJuridica}
          placeholder="Natureza Juridica"
          label="Natureza Juridica"
          type="text"
        />
      </CCol>
      <CCol md={6}>
        <FloatingLabelInput
          style={{ color: "#515c70" }}
          disabled
          value={values.cnae}
          placeholder="Função"
          label="Função"
          type="text"
        />
      </CCol>
    </>
  );
}

export default FormCnpj;
