import React from "react";
import { Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { useFetchData } from "../../../../hooks/useFetchData";
import { useEffect } from "react";
import { useState } from "react";
import { CCol } from "../../../../components/Grid/CCol";
import Dashbord from "../../../../components/ComponentesDeDashboard/Dashboard";

const DashboardObjetivosDoMilenio = () => {
  const { data: objetivosDoMilenioData } = useFetchData("objetivoDoMilenio");
  const [quantidadeObjetivos, setQuantidadeObjetivos] = useState("0");
  const [box1, setBox1] = useState({});

  const options = [
    { label: "Quantidade", value: null },
    { label: "Quantidade", value: 1 },
  ];

  useEffect(() => {
    if (objetivosDoMilenioData) {
      setQuantidadeObjetivos(
        objetivosDoMilenioData.length < 10
          ? "0" + objetivosDoMilenioData.length
          : objetivosDoMilenioData.length
      );
    }
  }, [objetivosDoMilenioData]);

  useEffect(() => {
    if (quantidadeObjetivos > 0) {
      setBox1({
        boxTitle: "Quantidade",
        boxSubtitle: "Objetivo",
        boxValue: quantidadeObjetivos,
      });
    }
  }, [quantidadeObjetivos]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Objetivos da ONU"}
        PaginaSubtitulo={"Dashboard"}
        PaginaConsulta={"/planejamento/cadastro/objetivos-da-onu/consulta"}
        PaginaRelatorio={"/planejamento/cadastro/objetivos-da-onu/relatorio"}
        BotaoNovoAtivo={false}
        BotaoSaveAtivo={false}
      />
      <Row className="row_form_2 mt-1" style={{ padding: "20px 10px" }}>
        <CCol>
          <Dashbord box1={box1} options={options} />
        </CCol>
        <CCol>
          <Dashbord />
        </CCol>
      </Row>
      <div className="p-3"></div>
    </Container>
  );
};

export default DashboardObjetivosDoMilenio;
