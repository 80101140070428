import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { CCol } from "../../../components/Grid/CCol";
import { RowAninhada } from "../../../components/Grid/CCol";
import pagamentoStatus from "../../../assets/Notificacoes/pagamentos_status_2.png";
import { Icon } from "@iconify/react/dist/iconify.js";

const Notificacoes = () => {
  const [notifications, setNotifications] = useState([]);

  const deleteFunction = (index) => {
    setNotifications((itens) =>
      itens.filter((_, itemIndex) => itemIndex !== index)
    );
  };

  useEffect(() => {
    setNotifications([
      {
        type: 1,
        status: 1,
        titulo: "10 Conciliações Bancárias Pendentes",
        descricao:
          "Não conseguimos detectar o fechamentos das contas  e o prazo é hoje 24:00",
        box: "Conciliação Bancária",
      },
      {
        type: 2,
      },
      {
        type: 1,
        status: 2,
        titulo: "Prazo final da LDO",
        descricao: "15 de Abril de 2024 é o prazo final de envio",
        box: null,
      },
    ]);
  }, []);

  return (
    <>
      {notifications.length > 0 && (
        <Row
          className="row_form mt-1"
          style={{
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "25px",
          }}
        >
          {notifications.map((item, index) =>
            item.type === 1 ? (
              <RowAninhada
                key={index}
                style={{
                  backgroundColor: "#F7F7F7",
                  borderRadius: "5px",
                  marginBottom:
                    index !== notifications.length - 1 ? "20px" : "10px",
                  boxShadow: "2px 5px 5px rgba(0, 0, 0, 0.25)",
                  position: "relative",
                }}
              >
                <CCol
                  md={1}
                  style={{
                    padding: 0,
                    borderRadius: "5px",
                    width: "3%",
                  }}
                >
                  <div
                    style={{
                      height: "100px",
                      width: "10px",
                      backgroundColor:
                        item.status === 1
                          ? "#FE464B"
                          : item.status === 2
                          ? "#E79900"
                          : "#28A745",
                      borderRadius: "5px 0 0 5px",
                    }}
                  />
                </CCol>
                <CCol style={{ padding: "10px 0" }}>
                  <div className="cor_letra_primaria fw-bold">
                    {item.titulo}
                  </div>
                  <div className="cor_letra_primaria">{item.descricao}</div>
                  {item.box && (
                    <div
                      className="cor_letra_primaria"
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#C6D9FC",
                        width: "fit-content",
                        padding: "3px 30px",
                        fontSize: "15px",
                        textAlign: "center",
                        marginTop: "2px",
                      }}
                    >
                      {item.box}
                    </div>
                  )}
                  <Icon
                    icon="ic:baseline-cancel"
                    height="26"
                    color="#F24E1E"
                    onClick={
                      deleteFunction
                        ? () => deleteFunction(index)
                        : () => console.log("close: ", index)
                    }
                    style={{
                      cursor: "pointer",
                      marginLeft: "2px",
                      marginRight: "-5px",
                      marginTop: "3px",
                      position: "absolute",
                      top: "3px",
                      right: "10px",
                    }}
                  />
                </CCol>
              </RowAninhada>
            ) : item.type === 2 ? (
              <RowAninhada
                key={index}
                style={{
                  backgroundColor: "#F7F7F7",
                  marginBottom:
                    index !== notifications.length - 1 ? "20px" : "10px",
                  boxShadow: "2px 5px 5px rgba(0, 0, 0, 0.25)",
                  borderRadius: "5px",
                  position: "relative",
                }}
              >
                <CCol style={{ padding: 0, borderRadius: "5px" }}>
                  <img
                    src={pagamentoStatus}
                    style={{
                      width: "100%",
                      height: "auto",
                      display: "block",
                      borderRadius: "5px",
                    }}
                    alt="Status do Pagamento"
                  />
                  <Icon
                    icon="ic:baseline-cancel"
                    height="26"
                    color="#F24E1E"
                    onClick={
                      deleteFunction
                        ? () => deleteFunction(index)
                        : () => console.log("close: ", index)
                    }
                    style={{
                      cursor: "pointer",
                      marginLeft: "2px",
                      marginRight: "-5px",
                      marginTop: "3px",
                      position: "absolute",
                      top: "3px",
                      right: "10px",
                    }}
                  />
                </CCol>
              </RowAninhada>
            ) : null
          )}
        </Row>
      )}
    </>
  );
};

export default Notificacoes;
