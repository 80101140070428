import React, { useState } from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import styled from "styled-components";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import { CollapseRow } from "../../../../components/CollapseRow/CollapseRow";
import { AnulacaoCollapse } from "./AnulacaoCollapse";

const CColNumeroAndEVB = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const ContentInfos = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    white-space: nowrap;
    font-size: 12px;
  }

  @media (max-width: 1077px) {
    flex-wrap: wrap;
    gap: 5px;
  }
`;

const AtualizacaoOrcamentaria = () => {
  const [collapseAnulacao, setCollapseAnulacao] = useState(false);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Atualização Orçamentária"}
        PaginaSubtitulo={"Cadastro"}
      />

      <RowForm className="mt-1">
        <CColNumeroAndEVB md={1}>
          <FloatingLabelInput placeholder="Número" label="Número" />
        </CColNumeroAndEVB>
        <CCol>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Documento"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Tipo Alteração"
          />
        </CCol>
        <CCol md={2}>
          <InputData />
        </CCol>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol md={10}>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="Ficha"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol>
          <InputFormatRealBrasileiro />
        </CCol>
      </RowForm>

      <RowInfosAcao contentStyle={{ overflowX: "hidden" }}>
        <ContentInfos>
          <p>
            Nº Lei: <span>1015/2022</span>
          </p>

          <p>
            Data Lei: <span>01/01/2022</span>
          </p>

          <p>
            Tipo de Crédito: <span>Orçamentário</span>
          </p>

          <p>
            Destinação:{" "}
            <span>
              Estima receita e fixa despesa para o orçamento vigente de Ita...
            </span>
          </p>
        </ContentInfos>
      </RowInfosAcao>

      <RowInfosAcao>
        <CCol md={8}>
          <p>
            Unidade Orçamentária:{" "}
            <span>02.050 - Secretaria Municipal de Educação</span>
          </p>
          <p>
            Programa:{" "}
            <span>
              2025 - Manutenção dos Serviços da Secretaria Municipal de Educação
            </span>
          </p>
          <p>
            Elemento de Despesa:{" "}
            <span>
              3.3.90.39 - OUTROS SERVIÇOS DE TERCEIROS - PESSOA JURÍDICA
            </span>
          </p>
          <p>
            Fonte de Recursos:{" "}
            <span>
              1.500.1001 - Identificação das despesas com manutenção e
              desenvolvimento do ensino
            </span>
          </p>
        </CCol>
        <CCol>
          <p>
            Função: <span>08 - Assistência Social</span>
          </p>
          <p>
            Subfunção: <span>244 - Assistência Comunitária</span>
          </p>
        </CCol>
      </RowInfosAcao>

      <RowInfosAcao
        $borderRadiusType={2}
        contentStyle={{ overflowX: "hidden" }}
      >
        <ContentInfos>
          <p>
            Dotação Inicial: <span>R$ 150.000,00</span>
          </p>

          <p>
            Utilizado: <span>R$ 150.000,00</span>
          </p>

          <p>
            Suplementação: <span>R$ 150.000,00</span>
          </p>

          <p>
            Anulação: <span>R$ 150.000,00</span>
          </p>

          <p>
            Saldo: <span>R$ 150.000,00</span>
          </p>
        </ContentInfos>
      </RowInfosAcao>

      <CollapseRow
        onClick={() => setCollapseAnulacao((prev) => !prev)}
        style={{ marginTop: "15px" }}
        collapse={collapseAnulacao}
        title={"Anulação"}
      />

      {collapseAnulacao && <AnulacaoCollapse />}

      <div className="p-3"></div>
    </Container>
  );
};

export default AtualizacaoOrcamentaria;
