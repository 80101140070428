//Menu Gerenciador - Sistema 1----------

export const menusGerenciador = [
  { titulo: "Dashboard", value: 1 },
  { titulo: "Clientes", value: 2 },
  { titulo: "Usuários", value: 3 },
  { titulo: "Financeiro", value: 4 },
  { titulo: "Suporte", value: 5 },
  { titulo: "Relatórios", value: 6 },
  { titulo: "Cadastros", value: 7 },
  { titulo: "Importar", value: 8 },
  { titulo: "Exportar", value: 9 },
];

export const acoesMenusGerenciador = [
  {
    titulo: "Dashboard",
    acoes: [
      {
        nomeAcao: "Visualizar",
        value: 1,
        menuPertecente: 1,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Imprimir",
        value: 2,
        menuPertecente: 1,
        sistemaPertencente: 1,
      },
    ],
  },

  {
    titulo: "Clientes",
    acoes: [
      {
        nomeAcao: "Visualizar",
        value: 1,
        menuPertecente: 2,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Relatorios / Imprimir",
        value: 2,
        menuPertecente: 2,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Gráficos",
        value: 3,
        menuPertecente: 2,
        sistemaPertencente: 1,
      },
      { nomeAcao: "Novo", value: 4, menuPertecente: 2, sistemaPertencente: 1 },
      { nomeAcao: "Save", value: 5, menuPertecente: 2, sistemaPertencente: 1 },
      {
        nomeAcao: "Editar",
        value: 6,
        menuPertecente: 2,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Excluir",
        value: 7,
        menuPertecente: 2,
        sistemaPertencente: 1,
      },
    ],
  },

  {
    titulo: "Usuários",
    acoes: [
      {
        nomeAcao: "Visualizar",
        value: 1,
        menuPertecente: 3,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Relatorios / Imprimir",
        value: 2,
        menuPertecente: 3,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Gráficos",
        value: 3,
        menuPertecente: 3,
        sistemaPertencente: 1,
      },
      { nomeAcao: "Novo", value: 4, menuPertecente: 3, sistemaPertencente: 1 },
      { nomeAcao: "Save", value: 5, menuPertecente: 3, sistemaPertencente: 1 },
      {
        nomeAcao: "Editar",
        value: 6,
        menuPertecente: 3,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Excluir",
        value: 7,
        menuPertecente: 3,
        sistemaPertencente: 1,
      },
    ],
  },

  {
    titulo: "Financeiro",
    acoes: [
      {
        nomeAcao: "Visualizar",
        value: 1,
        menuPertecente: 4,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Relatorios / Imprimir",
        value: 2,
        menuPertecente: 4,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Gráficos",
        value: 3,
        menuPertecente: 4,
        sistemaPertencente: 1,
      },
      { nomeAcao: "Novo", value: 4, menuPertecente: 4, sistemaPertencente: 1 },
      { nomeAcao: "Save", value: 5, menuPertecente: 4, sistemaPertencente: 1 },
      {
        nomeAcao: "Editar",
        value: 6,
        menuPertecente: 4,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Excluir",
        value: 7,
        menuPertecente: 4,
        sistemaPertencente: 1,
      },
    ],
  },

  {
    titulo: "Suporte",
    acoes: [
      {
        nomeAcao: "Visualizar",
        value: 1,
        menuPertecente: 5,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Relatorios / Imprimir",
        value: 2,
        menuPertecente: 5,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Gráficos",
        value: 3,
        menuPertecente: 5,
        sistemaPertencente: 1,
      },
      { nomeAcao: "Novo", value: 4, menuPertecente: 5, sistemaPertencente: 1 },
      { nomeAcao: "Save", value: 5, menuPertecente: 5, sistemaPertencente: 1 },
      {
        nomeAcao: "Editar",
        value: 6,
        menuPertecente: 5,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Excluir",
        value: 7,
        menuPertecente: 5,
        sistemaPertencente: 1,
      },
    ],
  },

  {
    titulo: "Relatórios",
    acoes: [
      {
        nomeAcao: "Visualizar",
        value: 1,
        menuPertecente: 6,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Imprimir",
        value: 2,
        menuPertecente: 6,
        sistemaPertencente: 1,
      },
    ],
  },

  {
    titulo: "Cadastros",
    acoes: [
      {
        nomeAcao: "Visualizar",
        value: 1,
        menuPertecente: 7,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Relatorios / Imprimir",
        value: 2,
        menuPertecente: 7,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Gráficos",
        value: 3,
        menuPertecente: 7,
        sistemaPertencente: 1,
      },
      { nomeAcao: "Novo", value: 4, menuPertecente: 7, sistemaPertencente: 1 },
      { nomeAcao: "Save", value: 5, menuPertecente: 7, sistemaPertencente: 1 },
      {
        nomeAcao: "Editar",
        value: 6,
        menuPertecente: 7,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Excluir",
        value: 7,
        menuPertecente: 7,
        sistemaPertencente: 1,
      },
    ],
  },

  {
    titulo: "Importar",
    acoes: [
      {
        nomeAcao: "Upload",
        value: 1,
        menuPertecente: 8,
        sistemaPertencente: 1,
      },
      { nomeAcao: "Save", value: 2, menuPertecente: 8, sistemaPertencente: 1 },
      {
        nomeAcao: "Editar",
        value: 3,
        menuPertecente: 8,
        sistemaPertencente: 1,
      },
    ],
  },

  {
    titulo: "Exportar",
    acoes: [
      {
        nomeAcao: "Download",
        value: 1,
        menuPertecente: 9,
        sistemaPertencente: 1,
      },
      { nomeAcao: "Novo", value: 2, menuPertecente: 9, sistemaPertencente: 1 },
      { nomeAcao: "Save", value: 3, menuPertecente: 9, sistemaPertencente: 1 },
      {
        nomeAcao: "Editar",
        value: 4,
        menuPertecente: 9,
        sistemaPertencente: 1,
      },
      {
        nomeAcao: "Excluir",
        value: 5,
        menuPertecente: 9,
        sistemaPertencente: 1,
      },
    ],
  },
];

//-------------------------------------
